import {
  AppBar,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import React, { Component } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import StarScoreAssessment from './StarScoreAssessment';
import StudentAttendance from './Attendance';
import authService from '../api-authorization/AuthorizeService';
import { stylesAttendance } from './styleAttendance';
import { withStyles } from '@material-ui/core/styles';

class AttendanceDialog extends Component {
  constructor(...args) {
    super(...args);
    this.attendanceForm = React.createRef();
    this.studentAssessment = React.createRef();
    this.state = {
      open: false,
      statusInfo: {
        class: '',
        lessonDate: '',
        startTimeLocal: '',
        endTimeLocal: '',
        saving: false,
        lastSaved: null
      }
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  openAttendanceDialog(dlgType, logId, classId, classLessonId, pageType) {
    this.setState({ dlgType, logId, classId, classLessonId, pageType }, () =>
      this.openDialog()
    );
  }

  updateStatus = statusInfo => {
    this.setState({ statusInfo });
  };
  setSavingStatus = saving => {
    this.setState({ saving });
  };

  saveStudentAttendance = () => {
    if (this.state.dlgType === 'attendance') {
      this.attendanceForm.saveStudentAttendance();
    } else {
      this.studentAssessment.saveAllStudentAssessments();
    }
  };

  addStudentAbsenceNotify = async () => {
    const token = await authService.getAccessToken();
    const { logId } = this.state;
    fetch(`api/TeacherLessonLog/AddStudentAbsenceNotify/${logId}`, {
      method: 'POST',
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
  };

  openDialog = () => {
    this.setState({ open: true });
  };
  closeDialog = () => {
    this.setState({ open: false });
    this.addStudentAbsenceNotify();
    if (this.props.exitAction) this.props.exitAction();
  };

  render() {
    const { classes } = this.props;
    const {
      classId,
      classLessonId,
      logId,
      statusInfo,
      dlgType,
      pageType,
      saving
    } = this.state;

    return (
      <Dialog
        open={this.state.open}
        onClose={this.closeDialog}
        classes={{ paper: classes.paperDlg }}
      >
        <AppBar position="sticky" color="default">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.closeDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {dlgType === 'attendance' ? 'Attendance' : 'Assessment'}
            </Typography>
            <Box className={classes.statusBarInfo}>
              <Typography gutterBottom className={classes.label}>
                <span className={classes.labelBold}>Class:</span>
                {' ' + statusInfo.class}
              </Typography>
            </Box>
            <Box className={classes.statusBarInfo}>
              <Typography gutterBottom className={classes.label}>
                <span className={classes.labelBold}>Date:</span>
                {' ' + statusInfo.lessonDate}
              </Typography>
            </Box>
            <Box className={classes.statusBarInfo}>
              <Typography gutterBottom className={classes.label}>
                <span className={classes.labelBold}>Time:</span>
                {' ' +
                  statusInfo.startTimeLocal +
                  ' - ' +
                  statusInfo.endTimeLocal}
              </Typography>
            </Box>
            <Box className={classes.statusBarInfo}>
              <Typography gutterBottom className={classes.label}>
                {statusInfo.lastSaved
                  ? 'Last saved: ' +
                    statusInfo.lastSaved.toLocaleDateString('vi-VN') +
                    ' ' +
                    statusInfo.lastSaved.toLocaleTimeString('vi-VN').slice(0, 5)
                  : 'Report date: ' + new Date().toLocaleDateString('vi-VN')}
              </Typography>
            </Box>
            <Grid
              item
              xs
              container
              alignItems="stretch"
              className={classes.statusBarInfo}
            ></Grid>
            {dlgType !== 'attendance' && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => this.saveStudentAttendance()}
                disabled={saving}
              >
                {'Submit'}
              </Button>
            )}
          </Toolbar>
        </AppBar>
        {dlgType === 'attendance' ? (
          <StudentAttendance
            onRef={actualChild => (this.attendanceForm = actualChild)}
            classId={classId}
            logId={logId}
            pageType={pageType}
            updateStatus={this.updateStatus}
            setSavingStatus={this.setSavingStatus}
          />
        ) : (
          <StarScoreAssessment
            onRef={actualChild => (this.studentAssessment = actualChild)}
            classId={classId}
            classLessonId={classLessonId}
            logId={logId}
            updateStatus={this.updateStatus}
            setSavingStatus={this.setSavingStatus}
          />
        )}
      </Dialog>
    );
  }
}

AttendanceDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesAttendance)(AttendanceDialog);
