import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import CommonDataTable from '../ui/table/CommonDataTable';
import CommonSearchToolbar from '../ui/table/CommonSearchToolbar';
import { DialogType } from '../ui/popup/Dialogs';
import Paper from '@material-ui/core/Paper';
import { StudentPaths } from './StudentConstans';
import { MessageType } from '../ui/popup/Messages';
const stylesListStudent = () => ({});

class ListStudentComp extends Component {
  static displayName = ListStudentComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = { students: [], loading: true };
  }

  componentDidMount() {
    this.populateStudentData();
  }

  populateStudentData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/Student', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ students: data, loading: false });
  };

  refreshStudent = async () => {
    await this.populateStudentData();
    this.setChildData('');
  };

  handleChangeSearchTerm = filterText => {
    this.setChildData(filterText);
  };

  setChildData = filterText => {
    const { students: data } = this.state;
    const searchTerm = filterText.toLowerCase();
    let filteredRows = data.filter(e => {
      const itemText = (
        e.studentName +
        e.englishName +
        e.fatherName +
        e.motherName
      ).toLowerCase();
      return itemText.indexOf(searchTerm) > -1;
    });
    this.child.setData(filteredRows);
  };

  render() {
    const cols = [
      { name: 'studentName', header: 'Name', align: 'left' },
      { name: 'englishName', header: 'English Name' },
      { name: 'birthdayFormat', header: 'Birthday', align: 'left' },
      { name: 'fatherName', header: 'Father Name', align: 'left' },
      { name: 'fatherPhone', header: 'Father Phone', align: 'left' },
      { name: 'motherName', header: 'Mother Name', align: 'left' },
      { name: 'motherPhone', header: 'Mother Phone', align: 'left' },
      { name: 'beginDateDisplay', header: 'Begin Date', align: 'left' },
      { name: 'address', header: 'Address', align: 'left' }
    ];
    const actions = [
      {
        name: 'subList',
        tooltip: 'View schedule',
        url: `${StudentPaths.ViewScheduleStudent}`,
        icon: 'event+_available'
      },
      {
        name: 'detail',
        tooltip: 'Learning outcomes',
        url: `${StudentPaths.ViewLearningOutcome}`,
        icon: 'how_to_reg'
      },
      {
        name: 'edit',
        tooltip: 'Edit',
        url: `${StudentPaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'apiAction',
        tooltip: 'Suspend',
        url: `${StudentPaths.Suspend}`,
        icon: 'pause_circle_outline_icon',
        msgType: MessageType.Suspend,
        dlgType: DialogType.Suspend,
        actionBtnText: 'Suspend',
        method: 'post'
      },
      {
        name: 'delete',
        tooltip: 'Delete',
        url: `${StudentPaths.Delete}`,
        icon: 'delete_icon'
      }
    ];

    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Paper>
            <CommonSearchToolbar handleChange={this.handleChangeSearchTerm} />
            <CommonDataTable
              onRef={actualChild => (this.child = actualChild)}
              rows={this.state.students}
              cols={cols}
              actions={actions}
              eventReloadData={this.refreshStudent}
              isPaging={true}
            />
          </Paper>
        )}
      </Fragment>
    );
  }
}

ListStudentComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListStudent = withStyles(stylesListStudent)(ListStudentComp);
