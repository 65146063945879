import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AdminPage } from '../ui/page/AdminPage';
import { StoryActions } from './StoryConstants';
import ListStory from './ListStory';
import { Loading } from '../ui/Loading';
import authService from '../api-authorization/AuthorizeService';

const stylesStory = theme => ({
  root: {}
});

class Stories extends Component {
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      classId: this.props.match.params.classId,
      classInfo: {},
      loading: true,
      content: null,
      pageTitle: ''
    };
    switch (this.props.action) {
      case StoryActions.Home:
        this.state.pageTitle = 'News Feed';
        this.state.content = (
          <ListStory action={this.props.action} classId={this.state.classId} />
        );
        break;
      case undefined:
      default:
    }
  }

  componentDidMount() {
    this.populateCommonInfo();
  }

  populateCommonInfo = async () => {
    const [token] = await Promise.all([authService.getAccessToken()]);
    const { classId } = this.state;

    const [respClass] = await Promise.all([
      fetch(`api/ClassCourses/${classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [classInfo] = await Promise.all([respClass.json()]);
    this.setState({ classInfo, loading: false });
  };

  render() {
    const { classes } = this.props;
    const { classInfo, content, loading } = this.state;
    const pageTitle = `News Feed - Class ${classInfo.name}`;

    return loading ? (
      <Loading />
    ) : (
      <AdminPage title={pageTitle} content={content} className={classes.root} />
    );
  }
}

Stories.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesStory)(Stories);
