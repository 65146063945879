import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../../common/route/PropsRoute';
import { Lessons } from './Lessons';
import { LessonPlanPaths, LessonPlanActions } from './LessonPlanConstants';

export default class LessonPlanRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={LessonPlanPaths.ListRoute}
          component={Lessons}
          action={LessonPlanActions.List}
        />
        <PropsRoute
          path={LessonPlanPaths.EditRoute}
          component={Lessons}
          action={LessonPlanActions.Edit}
        />
        <PropsRoute
          path={LessonPlanPaths.CreateRoute}
          component={Lessons}
          action={LessonPlanActions.Create}
        />
        <PropsRoute
          path={LessonPlanPaths.LessonContentRoute}
          component={Lessons}
          action={LessonPlanActions.LessonContent}
        />
      </Fragment>
    );
  }
}
