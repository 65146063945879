import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import { Paper } from '@material-ui/core';
import { Loading } from '../../ui/Loading';
import { AdminPage } from '../../ui/page/AdminPage';
import { stylesAcademicInfo } from '../../academic-manage/stylesAcademicInfo';
import TeacherInfo from './TeacherInfo';
import TeacherClasses from './TeacherClasses';
import StudentStatistics from './StudentStatistics';

class TeacherReports extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      teacherId: this.props.match.params.teacherId,
      loading: true
    };
  }

  componentDidMount() {
    this.populateClassData();
  }

  populateClassData = async () => {
    const token = await authService.getAccessToken();
    const { teacherId } = this.state;

    const [respTeacherInfo, respTeacherClasses] = await Promise.all([
      fetch(`api/TeacherReport/GetTeacherInfo/${teacherId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/TeacherReport/GetTeacherClasses/${teacherId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [teacherInfo, teacherClasses] = await Promise.all([
      respTeacherInfo.json(),
      respTeacherClasses.json()
    ]);

    this.setState({
      loading: false,
      teacherInfo,
      teacherClasses
    });
  };

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return <AdminPage title="Teacher Report" content={contents} />;
  }

  renderDatagrid() {
    const { classes } = this.props;
    const { teacherInfo, teacherClasses } = this.state;

    return (
      <Fragment>
        <TeacherInfo teacherInfo={teacherInfo} />

        <Paper className={classes.reportMainFrame}>
          <TeacherClasses teacherClasses={teacherClasses} />
          <div className={classes.reportBlockSpacing} />
          <StudentStatistics
            teacherInfo={teacherInfo}
            teacherClasses={teacherClasses}
          />
        </Paper>
      </Fragment>
    );
  }
}

const stylesClassReports = theme => ({
  ...stylesAcademicInfo(theme),
  reportMainFrame: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 0)
  },
  reportBlockSpacing: {
    marginBottom: theme.spacing(2)
  }
});

TeacherReports.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesClassReports)(TeacherReports);
