export const stylesAssessment = theme => ({
  paper: {
    color: '#707070',
    width: '100%',
    maxWidth: 1400,
    margin: 'auto',
    flexDirection: 'column',
    display: 'flex'
  },
  root: {
    flexGrow: 0,
    flexWrap: 'unset'
  },
  contentCol: {
    width: '100%',
    flexGrow: 0
  },
  presencePage: {
    // height: 'calc(100vh - 54px)',
    height: '100%',
    flexWrap: 'unset'
  },
  headerBar: {
    minHeight: 54,
    height: 54
  },
  bottomBar: {
    top: 'auto',
    bottom: 0
  },
  grow: {
    flexGrow: 1
  },
  navCol: {
    height: '100%',
    borderBottom: '1px #707070 solid',
    padding: theme.spacing(0.25)
  },
  box: {
    borderBottom: '2px #707070 solid',
    '&:last-child': {
      marginBottom: theme.spacing(4)
    }
  },
  heading: {
    fontSize: 30,
    letterSpacing: 0,
    textAlign: 'center',
    margin: theme.spacing(4, 0, 2.5, 0)
  },
  headingBold: {
    fontWeight: 800
  },
  subTitle: {
    fontSize: 16,
    letterSpacing: 0,
    textAlign: 'center',
    margin: theme.spacing(0, 0, 3, 0)
  },
  subTitleBold: {
    fontWeight: 'bold'
  },
  classList: {
    padding: theme.spacing(0)
  },
  classText: {
    textAlign: 'center',
    width: '100%'
  },
  classLabel: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  classTitle: {
    fontSize: 24,
    marginTop: theme.spacing(1.5)
  },
  classDetail: {
    margin: theme.spacing(1.5, 0)
  },
  classInfo: {
    marginLeft: theme.spacing(4),
    '&:first-child': {
      marginLeft: 0
    }
  },
  label: {
    fontSize: 14,
    color: '#707070',
    padding: theme.spacing(1)
  },
  labelBold: {
    fontWeight: 'bold'
  },
  boxClassInfo: {
    margin: theme.spacing(4, 0),
    textAlign: 'right'
  },
  button: {
    margin: theme.spacing(0, 4),
    borderRadius: theme.spacing(2.25),
    minWidth: 100
  }
});
