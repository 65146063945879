import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../../common/route/PropsRoute';
import { ReserveActions, ReservePaths } from './ReserveConstants';
import { ReserveInfo } from './ReserveInfo';

export default class ReserveRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={ReservePaths.InfoRoute}
          component={ReserveInfo}
          action={ReserveActions.Info}
        />
      </Fragment>
    );
  }
}
