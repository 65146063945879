import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Checkbox,
  List,
  ListItem,
  IconButton,
  TextField,
  AppBar,
  Toolbar
} from '@material-ui/core';
import { Loading } from '../ui/Loading';
import { Dialogs, DialogType } from '../ui/popup/Dialogs';
import {
  MessageBars,
  MessageBarVariants,
  MessageType,
  MessageBarText
} from '../ui/popup/Messages';

import './style.css';
import { DateTimeUtils } from '../common/DateTimeUtils';

const stylesPresenceStudent = theme => ({
  paper: {
    color: '#707070',
    width: '100%',
    maxWidth: 1400,
    minHeight: '100vh',
    margin: 'auto',
    flexDirection: 'column',
    display: 'flex'
  },
  root: {
    flexGrow: 1,
    flexWrap: 'unset'
  },
  contentCol: {
    flexGrow: 1
  },
  presencePage: {
    // height: 'calc(100vh - 54px)',
    flexWrap: 'unset'
  },
  headerBar: {
    minHeight: 54,
    height: 54
  },
  bottomBar: {
    top: 'auto',
    bottom: 0
  },
  navCol: {
    height: '100%',
    borderBottom: '1px #707070 solid',
    padding: theme.spacing(0.25)
  },
  box: {
    borderBottom: '2px #707070 solid',
    '&:last-child': {
      marginBottom: theme.spacing(4)
    }
  },
  heading: {
    fontSize: 30,
    letterSpacing: 0,
    textAlign: 'center',
    margin: theme.spacing(4, 0, 2.5, 0)
  },
  headingBold: {
    fontWeight: 800
  },
  subTitle: {
    fontSize: 16,
    letterSpacing: 0,
    textAlign: 'center',
    margin: theme.spacing(0, 0, 3, 0)
  },
  subTitleBold: {
    fontWeight: 'bold'
  },
  classList: {
    padding: theme.spacing(0)
  },
  classText: {
    textAlign: 'center',
    width: '100%'
  },
  classLabel: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  classTitle: {
    fontSize: 24,
    marginTop: theme.spacing(1.5)
  },
  classDetail: {
    margin: theme.spacing(1.5, 0)
  },
  classInfo: {
    marginLeft: theme.spacing(4),
    '&:first-child': {
      marginLeft: 0
    }
  },
  studentName: {
    lineHeight: '34px'
  },
  label: {
    fontSize: 14,
    color: '#707070',
    padding: theme.spacing(1)
  },
  labelBold: {
    fontWeight: 'bold'
  },
  boxClassInfo: {
    margin: theme.spacing(4, 0),
    textAlign: 'right'
  },
  button: {
    margin: theme.spacing(0, 4),
    borderRadius: theme.spacing(2.25),
    minWidth: 100
  },
  checkBox: {
    width: '20%',
    textAlign: 'right'
  },
  checkPresence: {
    marginTop: 62
  },
  checkLabel: {
    cursor: 'pointer'
  },
  noteCol: {
    width: '25%',
    minWidth: 150
  },
  noteField: {
    margin: theme.spacing(6.25, 0, 0, 2)
  },
  noteText: {
    width: '100%'
  }
});

class PresenceStudentComp extends Component {
  static displayName = PresenceStudentComp.name;

  constructor(...args) {
    super(...args);
    this.confirmDialog = React.createRef();
    this.state = {
      campusId: this.props.match.params.campusId,
      currentClassId: null,
      isListingClass: true,
      classList: [],
      currentClass: {},
      lastSaved: null,
      loading: true
    };
    this.messageBar = React.createRef();
    this.confirmDialog = React.createRef();
  }

  componentDidMount() {
    this.populateClassListData();
  }

  populateClassListData = async () => {
    const token = await authService.getAccessToken();
    const [respCampus, respClassList] = await Promise.all([
      fetch(`api/Campus/${this.state.campusId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/ClassRooms/ClassStudentsOfCampus/${this.state.campusId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [currentCampus, classList] = await Promise.all([
      respCampus.json(),
      respClassList.json()
    ]);

    this.setState({ currentCampus, classList, loading: false });
  };

  handleCloseMessageBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  showConfirmDialog = () => {
    this.confirmDialog.showDialogWithCmds(
      DialogType.SaveScoreBoard,
      this.confirmDialogActions()
    );
  };
  closeConfirmDialog = () => {
    this.confirmDialog.closeDialog();
  };

  populatePresenceStudent = async (classId, classLessonId) => {
    const token = await authService.getAccessToken();
    const headers = !token ? {} : { Authorization: `Bearer ${token}` };

    const [
      respCurrentClass,
      respClassStudents,
      respRatingStar
    ] = await Promise.all([
      fetch(`api/ClassLesson/GetClassLessonById/${classLessonId}`, {
        headers: headers
      }),
      fetch(`api/ClassStudents/GetStudentByClass/${classId}`, {
        headers: headers
      }),
      fetch(`api/TeacherLessonLog/GetStudentLogDataOfClass/${classLessonId}`, {
        headers: headers
      })
    ]);
    const [
      currentClass,
      currentClassStudents,
      currentRatingStar
    ] = await Promise.all([
      respCurrentClass.json(),
      respClassStudents.json(),
      respRatingStar.json()
    ]);
    this.setState(
      {
        classId,
        classLessonId,
        currentClass,
        currentClassStudents,
        currentRatingStar
      },
      this.populateStudentRating
    );
  };

  populateStudentRating = () => {
    const { currentRatingStar, currentClassStudents } = this.state;
    const ratingMap = [];
    if (currentRatingStar && currentRatingStar.length > 0) {
      currentRatingStar.forEach(student => {
        student.note = student.note ?? '';
        ratingMap[student.studentInfoId] = student;
      });
    } else {
      currentClassStudents.forEach(student => {
        ratingMap[student.subId] = { present: false, note: '' };
      });
    }
    this.setState({ ratingMap, loading: false });
  };

  showPresenceStudent = (classId, classLessonId) => {
    this.setState({ isListingClass: false, loading: true });
    this.populatePresenceStudent(classId, classLessonId);
  };

  showClassList = () => {
    this.setState({ isListingClass: true, lastSaved: null });
  };

  changePresenceStudent = (studentId, attrName) => event => {
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;

    const { ratingMap } = this.state;
    ratingMap[studentId][attrName] = attrValue;
    this.setState({ ratingMap });
  };

  saveLessonLog = async (classLessonId, headers) => {
    const lessonLogNew = {
      classLessonId: classLessonId,
      logDateTime: DateTimeUtils.toDateTimeJSON(new Date())
    };
    const response = await fetch(`api/TeacherLessonLog/AddTodayLessonLog`, {
      method: 'POST',
      body: JSON.stringify(lessonLogNew),
      headers: headers
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(response.status);
    }
  };

  addRatingStar = async (studentId, logId, headers) => {
    const studentLessonLogNew = {
      logId: logId,
      studentInfoId: studentId
    };
    const response = await fetch(
      `api/TeacherLessonLog/AddTodayStudentLessonLog`,
      {
        method: 'POST',
        body: JSON.stringify(studentLessonLogNew),
        headers: headers
      }
    );
    return response.ok ? await response.json() : null;
  };

  saveRatingStar = async (studentId, logId, headers) => {
    const { ratingMap } = this.state;

    let studentLessonLog = await this.addRatingStar(studentId, logId, headers);
    studentLessonLog.present = ratingMap[studentId].present;
    studentLessonLog.note = ratingMap[studentId].note;

    fetch(`api/TeacherLessonLog/SaveStudentLessonLog/${studentLessonLog.id}`, {
      method: 'PUT',
      body: JSON.stringify(studentLessonLog),
      headers: headers
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.status);
      }
    });
    // .catch(error => {
    //   console.error('Error:', error);
    // });

    return studentLessonLog;
  };

  savePresenceStudent = async () => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    const { classLessonId, currentClassStudents } = this.state;

    try {
      const lessonLog = await this.saveLessonLog(classLessonId, headers);

      currentClassStudents.forEach(student => {
        this.saveRatingStar(student.subId, lessonLog.id, headers);
      });

      this.addStudentAbsenceNotify(lessonLog.id, token);
      this.setState({ lastSaved: new Date() });
      this.messageBar.showMessage(
        MessageBarVariants.Success,
        MessageType.Submit,
        MessageBarText.Success[MessageType.Submit]
      );
    } catch (error) {
      console.error(error);
      this.messageBar.showMessage(
        MessageBarVariants.Error,
        MessageType.Submit,
        MessageBarText.Error[MessageType.Submit]
      );
    }
    //this.closeConfirmDialog();
  };

  addStudentAbsenceNotify = async (logId, token) => {
    fetch(`api/TeacherLessonLog/AddStudentAbsenceNotify/${logId}`, {
      method: 'POST',
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
  };

  messageBarComp() {
    return (
      <MessageBars
        onRef={actualChild => (this.messageBar = actualChild)}
        variant={MessageBarVariants.Error}
        type={MessageType.Approve}
        message={'Cannot preview!'}
        handleClose={this.handleCloseMessageBar}
      />
    );
  }
  confirmDialog() {
    return (
      <Dialogs onRef={actualChild => (this.confirmDialog = actualChild)} />
    );
  }
  confirmDialogActions() {
    return (
      <Fragment>
        <Button
          onClick={this.savePresenceStudent}
          variant="contained"
          color="secondary"
          className={this.props.classes.button}
        >
          Yes
        </Button>
        <Button
          onClick={this.closeConfirmDialog}
          variant="contained"
          color="primary"
          className={this.props.classes.button}
        >
          No
        </Button>
      </Fragment>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      isListingClass,
      currentCampus,
      classList,
      currentClass,
      currentClassStudents,
      ratingMap,
      lastSaved,
      loading
    } = this.state;

    const d = new Date();
    let content;

    if (loading) {
      content = <Loading />;
    } else {
      content = (
        <Paper className={classes.paper}>
          {!isListingClass && (
            <AppBar position="sticky" color="inherit">
              <Toolbar className={classes.headerBar}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="back-to-list"
                  onClick={this.showClassList}
                >
                  <svg width="24px" height="24px" viewBox="0 0 24 24">
                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
                  </svg>
                </IconButton>
              </Toolbar>
            </AppBar>
          )}
          <Grid container direction="column" className={classes.root}>
            <Grid item className={classes.contentCol}>
              {isListingClass ? (
                <Grid item xs container direction="column">
                  <Grid item xs className={classes.box}>
                    <Typography></Typography>
                    <Typography gutterBottom className={classes.heading}>
                      Select Class of Campus{' '}
                      <span className={classes.headingBold}>
                        {currentCampus.name +
                          ' (' +
                          currentCampus.address +
                          ')'}
                      </span>
                    </Typography>
                    <Typography gutterBottom className={classes.subTitle}>
                      <span className={classes.subTitleBold}>Date:</span>{' '}
                      {' ' +
                        d
                          .getDate()
                          .toString()
                          .padStart(2, '0') +
                        '/' +
                        (d.getMonth() + 1).toString().padStart(2, '0') +
                        '/' +
                        d
                          .getFullYear()
                          .toString()
                          .padStart(2, '0')}
                    </Typography>
                    <Typography></Typography>
                  </Grid>
                  <Grid item>
                    <List component="nav" className={classes.classList}>
                      {classList.map(cls => (
                        <ListItem
                          key={cls.classId}
                          button
                          onClick={() =>
                            this.showPresenceStudent(
                              cls.classId,
                              cls.classLessonId
                            )
                          }
                          className={classes.box}
                        >
                          <div className={classes.classText}>
                            <Typography></Typography>
                            <Typography className={classes.classLabel}>
                              Class
                            </Typography>
                            <Typography className={classes.classTitle}>
                              {cls.className}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.classDetail}
                            >
                              <span className={classes.classInfo}>
                                {'Time: ' + cls.startTime + ' - ' + cls.endTime}
                              </span>
                              <span className={classes.classInfo}>
                                {'Number of students: ' + cls.studentCount}
                              </span>
                              <span className={classes.classInfo}>
                                {'Demo: ' + cls.demoStudent}
                              </span>
                            </Typography>
                            <Typography></Typography>
                          </div>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  xs
                  container
                  direction="column"
                  className={classes.presencePage}
                >
                  <Grid item xs={12}>
                    {currentClassStudents.map(student => (
                      <div className="row-student" key={student.subId}>
                        <div className={classes.checkBox}>
                          <div className={classes.checkPresence}>
                            <Checkbox
                              id={'check-presence-' + student.subId}
                              checked={ratingMap[student.subId].present}
                              onChange={this.changePresenceStudent(
                                student.subId,
                                'present'
                              )}
                            />
                          </div>
                        </div>
                        <label
                          htmlFor={'check-presence-' + student.subId}
                          className={classes.checkLabel}
                        >
                          <div className="left-side id-tab">
                            <div className="name-id">
                              <Typography
                                variant="h1"
                                className={classes.studentName}
                              >
                                {`${student.englishName} - ${student.studentName}`}
                              </Typography>
                            </div>
                          </div>
                        </label>
                        <div className={classes.noteCol}>
                          <div className={classes.noteField}>
                            <TextField
                              variant="outlined"
                              value={ratingMap[student.subId].note}
                              onChange={this.changePresenceStudent(
                                student.subId,
                                'note'
                              )}
                              className={classes.noteText}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {!isListingClass && (
            <AppBar
              position="sticky"
              color="inherit"
              className={classes.bottomBar}
            >
              <Grid item xs container alignItems="stretch">
                <Grid item xs={2}>
                  <div className={classes.boxClassInfo}>
                    <Typography gutterBottom className={classes.label}>
                      <span className={classes.labelBold}>Class:</span>
                      {' ' + currentClass.classCourse.name}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.boxClassInfo}>
                    <Typography gutterBottom className={classes.label}>
                      <span className={classes.labelBold}>Time:</span>
                      {' ' +
                        currentClass.startTimeLocal +
                        ' - ' +
                        currentClass.endTimeLocal}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.boxClassInfo}>
                    <Typography gutterBottom className={classes.label}>
                      {lastSaved
                        ? 'Last saved: ' +
                          lastSaved.toLocaleDateString('vi-VN') +
                          ' ' +
                          lastSaved.toLocaleTimeString('vi-VN').slice(0, 5)
                        : 'Date: ' + new Date().toLocaleDateString('vi-VN')}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.boxClassInfo}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() => this.savePresenceStudent()}
                    >
                      {'Submit'}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </AppBar>
          )}
        </Paper>
      );
    }

    return (
      <Fragment>
        {content}
        {this.messageBarComp()}
        {/* {this.confirmDialog()} */}
      </Fragment>
    );
  }
}

PresenceStudentComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const PresenceStudent = withStyles(stylesPresenceStudent)(
  PresenceStudentComp
);
