export const stylesNormalTable = theme => ({
  container: {
    borderRadius: 15,
    border: '0.5px solid #ddd'
  },
  table: {
    minWidth: 100
  },
  header: {
    borderBottom: '0.5px solid #ddd'
  },
  row: {
    '&:not(:last-child)': {
      borderBottom: '0.5px solid #ddd'
    }
  },
  rootPagination: {
    padding: theme.spacing(0.5),
    borderTop: '0.5px solid #ddd',
    borderBottom: 0
  },
  caption: {
    fontSize: 12
  }
});
