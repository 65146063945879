import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import authService from '../../../api-authorization/AuthorizeService';
import { Loading } from '../../../ui/Loading';
import { stylesCharting } from '../stylesCharting';
import { stylesAcademicInfo } from '../../../academic-manage/stylesAcademicInfo';
import { BarChartView } from './BarChartView';
import { SquareChartView } from './SquareChartView';
import { NumberValueView } from './NumberValueView';
import { PaymentInfoView } from './PaymentInfoView';

class StudentStatistics extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.populateStudentData();
  }

  populateStudentData = async () => {
    const { classInfo } = this.props;
    const token = await authService.getAccessToken();
    const [
      respStudentAttending,
      respAttendingStatistics,
      respAttendingPerSession,
      respStudentPaymentStatistics
    ] = await Promise.all([
      fetch(`api/StudentReport/GetStudentAttending/${classInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/StudentReport/GetAttendingStatistics/${classInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/StudentReport/GetAttendingPerSession/${classInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/StudentReport/GetStudentPaymentStatistics/${classInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [
      dataSudentAttending,
      dataAttendingStatistics,
      dataAttendingPerSession,
      dataStudentPaymentStatistics
    ] = await Promise.all([
      respStudentAttending.json(),
      respAttendingStatistics.json(),
      respAttendingPerSession.json(),
      respStudentPaymentStatistics.json()
    ]);

    const chartStudentAttending = dataSudentAttending.map(item => {
      return { key: item.englishName, data: item.attendingRatio };
    });

    const chartAttendingPerSession = dataAttendingPerSession
      .map(item => {
        return { key: item.lessonDateText, data: item.attendingCount };
      })
      .reduce((result, item) => {
        if (!result.find(i => i.key === item.key)) result.push(item);
        return result;
      }, []);

    const chartStudentPaymentStatistics = dataStudentPaymentStatistics
      .reduce((result, item) => {
        if (item.remainSessions < 10) result.push(item);
        return result;
      }, [])
      .sort(function(a, b) {
        return a.remainSessions - b.remainSessions;
      })
      .map(item => {
        return { key: item.englishName, data: item.remainSessions };
      });

    const totalPayment = dataStudentPaymentStatistics
      .map(item => item.totalPayment)
      .reduce((prev, curr) => prev + curr);
    const remainPayment = dataStudentPaymentStatistics
      .map(item => item.remainPayment)
      .reduce((prev, curr) => prev + curr);

    const {
      dataAttendingUpper,
      dataAttendingLower
    } = this.calcStudentAttending(chartStudentAttending);

    this.setState({
      chartStudentAttending,
      dataAttendingStatistics,
      chartAttendingPerSession,
      chartStudentPaymentStatistics,
      totalPayment,
      remainPayment,
      dataAttendingUpper,
      dataAttendingLower,
      loading: false
    });
  };

  calcStudentAttending(chartStudentAttending) {
    const totalStds = chartStudentAttending.length;
    const attendingUpper = chartStudentAttending.filter(x => x.data > 50)
      .length;
    let dataAttendingUpper = [
      {
        key: 'Attending',
        data: attendingUpper
      },
      {
        key: '',
        data: totalStds - attendingUpper
      }
    ];
    const attendingLower = chartStudentAttending.filter(x => x.data < 50)
      .length;
    let dataAttendingLower = [
      {
        key: 'Attending',
        data: attendingLower
      },
      {
        key: '',
        data: totalStds - attendingLower
      }
    ];
    return { dataAttendingUpper, dataAttendingLower };
  }

  updateChartUpper = percentage => {
    const { chartStudentAttending } = this.state;
    const totalStds = chartStudentAttending.length;
    const attendingUpper = chartStudentAttending.filter(
      x => x.data > percentage
    ).length;
    let dataAttendingUpper = [
      {
        key: 'Attending',
        data: attendingUpper
      },
      {
        key: '',
        data: totalStds - attendingUpper
      }
    ];
    this.setState({ dataAttendingUpper });
  };
  updateChartLower = percentage => {
    const { chartStudentAttending } = this.state;
    const totalStds = chartStudentAttending.length;
    const attendingLower = chartStudentAttending.filter(
      x => x.data < percentage
    ).length;
    let dataAttendingLower = [
      {
        key: 'Attending',
        data: attendingLower
      },
      {
        key: '',
        data: totalStds - attendingLower
      }
    ];
    this.setState({ dataAttendingLower });
  };

  render() {
    const { loading } = this.state;
    let content = loading ? <Loading /> : this.renderUI();

    return <Fragment>{content}</Fragment>;
  }

  renderUI() {
    const { classes } = this.props;
    const {
      totalPayment,
      remainPayment,
      chartStudentAttending,
      dataAttendingStatistics,
      chartAttendingPerSession,
      chartStudentPaymentStatistics,
      dataAttendingUpper,
      dataAttendingLower
    } = this.state;

    return (
      <Fragment>
        <Grid container className={classes.stdHeadline}>
          <div className={classes.stdSubtitle}>
            <Typography variant="h3" className={classes.sectionTitle}>
              {'Thông tin thống kê & báo cáo của lớp học về học sinh'}
            </Typography>
          </div>
          <div className={classes.stdSubtitleHr}>
            <span className={clsx(classes.stdLineCell)}></span>
          </div>
        </Grid>

        <Grid container className={classes.stdBox} spacing={2}>
          <Grid item xs={5} container direction="column" justify="flex-start">
            <div className={classes.chartBoxTopPad}>
              <div>
                <Grid container>
                  <Grid item xs={6}>
                    <SquareChartView
                      title="Max NoS Attending"
                      subtitle="Sĩ số học sinh đi học tối đa"
                      criteriaLabel="Số học sinh đi học trên"
                      chartData={dataAttendingUpper}
                      updateChartData={this.updateChartUpper}
                      numberValue={dataAttendingStatistics.maxAttendingCount}
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.chartBoxLeftPad}>
                    <SquareChartView
                      title="Min NoS Attending"
                      subtitle="Sĩ số học sinh đi học tối thiểu"
                      criteriaLabel="Số học sinh đi học dưới"
                      chartData={dataAttendingLower}
                      updateChartData={this.updateChartLower}
                      numberValue={dataAttendingStatistics.minAttendingCount}
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.chartBoxTopPad}>
                <Grid container>
                  <Grid item xs={6}>
                    <NumberValueView
                      title="NoS Terminated"
                      subtitle="Số học sinh nghỉ học"
                      numberValue={dataAttendingStatistics.terminateCount}
                      numberColor={classes.chartLabelRed}
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.chartBoxLeftPad}>
                    <NumberValueView
                      title="NoS Changed Class"
                      subtitle="Số học sinh chuyển lớp"
                      numberValue={dataAttendingStatistics.movedOutCount}
                      numberColor={classes.chartLabelOrange}
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.chartBoxTopPad}>
                <Grid container>
                  <Grid item xs={6}>
                    <NumberValueView
                      title="NoS New Register"
                      subtitle="Số học sinh đăng ký mới"
                      numberValue={dataAttendingStatistics.newRegisterCount}
                      numberColor={classes.chartLabelGreen}
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.chartBoxLeftPad}>
                    <NumberValueView
                      title="NoS Transfered"
                      subtitle="Số học sinh được chuyển đến"
                      numberValue={dataAttendingStatistics.movedInCount}
                      numberColor={classes.chartLabelOrange}
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.chartBoxTopPad}>
                <Grid item xs={12}>
                  <PaymentInfoView
                    title="Total Payments of class"
                    subtitle="Tổng học phí hiện tại của lớp"
                    totalPayment={totalPayment}
                    remainPayment={remainPayment}
                    classes={classes}
                  />
                </Grid>
              </div>
            </div>
          </Grid>

          <Grid item xs={7} container direction="column" justify="flex-start">
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <BarChartView
                  title="Percentage of Attendance's Students"
                  subtitle="Tỷ lệ đi học của từng học sinh"
                  chartData={chartStudentAttending}
                  classes={classes}
                />
              </Grid>
            </div>
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <BarChartView
                  title="Number of Students Attending"
                  subtitle="Sĩ số học sinh đi học theo từng buổi"
                  chartData={chartAttendingPerSession}
                  classes={classes}
                />
              </Grid>
            </div>
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                {chartStudentPaymentStatistics.length > 0 && (
                  <BarChartView
                    title="Students are almost out of payments"
                    subtitle="Danh sách học sinh gần hết học phí"
                    chartData={chartStudentPaymentStatistics}
                    classes={classes}
                  />
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const stylesStudentStatistics = theme => ({
  ...stylesCharting(theme),
  ...stylesAcademicInfo(theme)
});

StudentStatistics.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesStudentStatistics)(StudentStatistics);
