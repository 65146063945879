import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { AcademicManage } from './AcademicManage';
import AcademicInfo from './AcademicInfo';
import { AcademicPaths, AcademicActions } from './AcademicConstants';

export default class AcademicRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={AcademicPaths.Search}
          component={AcademicManage}
          action={AcademicActions.Search}
        />
        <PropsRoute
          path={AcademicPaths.InfoRoute}
          component={AcademicInfo}
          action={AcademicActions.Info}
        />
      </Fragment>
    );
  }
}
