import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import PagingTable from '../ui/table/PagingTable';
import MaterialSelectTable from './MaterialSelectTable';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';

const stylesListMaterialSelect = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    overflowX: 'auto'
  },
  cell: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
});

class ListMaterialSelectComp extends Component {
  static displayName = ListMaterialSelectComp.name;

  constructor(...args) {
    super(...args);
    this.state = {
      data: [],
      lstSelected: [],
      materialId: this.props.materialId,
      category: this.props.category,
      lessonPlanUnitId: this.props.lessonPlanUnitId,
      loading: true,
      isDisableFilter: this.props.isDisableFilter,
      isUnitPlan: this.props.isUnitPlan
    };
  }
  componentDidMount() {
    this.populateInitData();
  }
  async populateInitData() {
    const [token] = await Promise.all([authService.getAccessToken()]);
    const response = await fetch(
      `api/Materials/GetMaterialInTeacherUnit/${this.state.materialId}/${this.state.lessonPlanUnitId}/${this.state.isUnitPlan}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({ data: data, loading: false });
  }

  callbackValueRadioBtn = e => {
    this.props.callbackFromParentGetValRadioBtn(e);
  };

  render() {
    const { data } = this.state;
    const cols = [
      { name: 'name', header: 'Material name', align: 'left' },
      { name: 'description', header: 'Description', align: 'left' },
      { name: 'materialFormatText', header: 'Material format', align: 'left' },
      {
        name: 'materialCategories',
        header: 'Material categories',
        align: 'left'
      }
    ];
    const content = this.state.loading ? (
      <Loading />
    ) : (
      <MaterialSelectTable
        rows={data}
        cols={cols}
        isShowRadioButton="true"
        category={this.state.category}
        callbackGetValueRadioBtn={this.callbackValueRadioBtn}
        isDisableFilter={this.state.isDisableFilter}
      />
      // <PagingTable
      //   rows={data}
      //   cols={cols}
      //   isShowRadioButton="true"
      //   callbackGetValueRadioBtbn={this.callbackValueRadioBtn}
      // />
    );
    return <Fragment>{content}</Fragment>;
  }
}

ListMaterialSelectComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListMaterialSelect = withStyles(stylesListMaterialSelect)(
  ListMaterialSelectComp
);
