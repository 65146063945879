export const UnitSearchAction = {
  List: 'list'
};

const prefix = '/unit-search';

export const UnitSearchPaths = {
  UnitSearchPrefix: prefix,
  List: `${prefix}/${UnitSearchAction.List}`
};
