import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LinkIcon from '@material-ui/icons/Link';

export const homeListItems = (
  <div>
    <ListItem button component={Link} to="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem
      button
      component="a"
      href={`${process.env.REACT_APP_TMS_WEBSITE}`}
      underline="none"
      target="_blank"
    >
      <ListItemIcon>
        <LinkIcon />
      </ListItemIcon>
      <ListItemText primary="Trang điểm danh" />
    </ListItem>
  </div>
);
