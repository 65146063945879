export const stylesAttendance = theme => ({
  title: {
    margin: theme.spacing(0, 4, 0, 2)
  },
  statusBarInfo: {
    margin: theme.spacing(0, 0, 0, 3)
  },
  paperDlg: {
    width: 1500,
    maxWidth: 1500,
    minHeight: 550,
    padding: theme.spacing(0, 0.5, 0.5, 0.5)
  },
  paper: {
    color: '#707070',
    width: 1460,
    margin: 'auto',
    flexDirection: 'column',
    display: 'flex'
  },
  root: {
    flexGrow: 1,
    flexWrap: 'unset'
  },
  contentCol: {
    flexGrow: 1
  },
  presencePage: {
    // height: 'calc(100vh - 54px)',
    flexWrap: 'unset'
  },
  headerBar: {
    minHeight: 54,
    height: 54
  },
  bottomBar: {
    top: 'auto',
    bottom: 0
  },
  navCol: {
    height: '100%',
    borderBottom: '1px #707070 solid',
    padding: theme.spacing(0.25)
  },
  box: {
    borderBottom: '2px #707070 solid',
    '&:last-child': {
      marginBottom: theme.spacing(4)
    }
  },
  heading: {
    fontSize: 30,
    letterSpacing: 0,
    textAlign: 'center',
    margin: theme.spacing(4, 0, 2.5, 0)
  },
  headingBold: {
    fontWeight: 800
  },
  subTitle: {
    fontSize: 16,
    letterSpacing: 0,
    textAlign: 'center',
    margin: theme.spacing(0, 0, 3, 0)
  },
  subTitleBold: {
    fontWeight: 'bold'
  },
  classList: {
    padding: theme.spacing(0)
  },
  classText: {
    textAlign: 'center',
    width: '100%'
  },
  classLabel: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  classTitle: {
    fontSize: 24,
    marginTop: theme.spacing(1.5)
  },
  classDetail: {
    margin: theme.spacing(1.5, 0)
  },
  classInfo: {
    marginLeft: theme.spacing(4),
    '&:first-child': {
      marginLeft: 0
    }
  },
  studentName: {
    lineHeight: '34px'
  },
  label: {
    fontSize: 14,
    color: '#707070',
    padding: theme.spacing(1)
  },
  labelBold: {
    fontWeight: 'bold'
  },
  boxClassInfo: {
    textAlign: 'right'
  },
  button: {
    margin: theme.spacing(0, 2),
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.spacing(2.25),
    minWidth: 100,
    fontSize: 12
  },
  checkBox: {
    width: '14%',
    textAlign: 'right'
  },
  checkPresence: {
    marginTop: 15
  },
  checkLabel: {
    cursor: 'pointer'
  },
  noteCol: {
    width: '50%',
    minWidth: 200
  },
  noteField: {
    margin: '28px 0'
  },
  noteText: {
    width: '100%'
  }
});
