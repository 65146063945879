import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { AssessmentPaths } from './AssessmentConstants';

const stylesAssessmentList = theme => ({
  paper: {
    color: '#707070',
    width: '100%',
    maxWidth: 1400,
    minHeight: '100vh',
    margin: 'auto'
  },
  root: {
    flexGrow: 1
  },
  contentCol: {
    width: '100%',
    flexGrow: 1
  },
  navCol: {
    height: '100%',
    borderBottom: '1px #707070 solid',
    padding: theme.spacing(0.25)
  },
  box: {
    borderBottom: '2px #707070 solid',
    '&:last-child': {
      marginBottom: theme.spacing(4)
    }
  },
  heading: {
    fontSize: 30,
    letterSpacing: 0,
    textAlign: 'center',
    margin: theme.spacing(4, 0, 2.5, 0)
  },
  headingBold: {
    fontWeight: 800
  },
  subTitle: {
    fontSize: 16,
    letterSpacing: 0,
    textAlign: 'center',
    margin: theme.spacing(0, 0, 3, 0)
  },
  subTitleBold: {
    fontWeight: 'bold'
  },
  classList: {
    padding: theme.spacing(0)
  },
  classText: {
    textAlign: 'center',
    width: '100%'
  },
  classLabel: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  classTitle: {
    fontSize: 24,
    marginTop: theme.spacing(1.5)
  },
  classDetail: {
    margin: theme.spacing(1.5, 0)
  },
  classInfo: {
    marginLeft: theme.spacing(4),
    '&:first-child': {
      marginLeft: 0
    }
  },
  fab: {
    margin: 'auto 0'
  }
});

class AssessmentListComp extends Component {
  static displayName = AssessmentListComp.name;
  constructor(...args) {
    super(...args);
    this.state = {
      isListingCampus: true,
      roomList: [],
      redirect: null,
      loading: true
    };
  }

  componentDidMount() {
    this.populateRoomsData();
  }

  populateRoomsData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/ClassRooms/GetClassRoom/0', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    //const campus = [...new Set(data.map(item => { return item.campus.name + ' (' + item.campus.address + ')'; }))];
    const campus = new Map();
    const roomMap = new Map();
    data.forEach(item => {
      const key = item.campus.name;
      const campusObj = campus.get(key);
      if (!campusObj) {
        campus.set(key, item.campus);
      }
      const collection = roomMap.get(key);
      if (!collection) {
        roomMap.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    const campusList = Array.from(campus.values());
    this.setState({ campusList, campus, roomMap, loading: false });
  };

  showRoomList = campusName => {
    const { campus, roomMap } = this.state;
    const roomList = roomMap.get(campusName);
    const currentCampus = campus.get(campusName);
    this.setState({ currentCampus, roomList, isListingCampus: false });
  };

  showCampusList = () => {
    this.setState({ isListingCampus: true });
  };

  showRoomClassList = roomId => {
    this.redirectTo(`${AssessmentPaths.Class}/${roomId}`);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  render() {
    const { classes } = this.props;
    const {
      isListingCampus,
      campusList,
      currentCampus,
      roomList,
      loading
    } = this.state;

    const d = new Date();
    let content = <Loading />;

    if (!loading) {
      content = (
        <Paper className={classes.paper}>
          <Grid container direction="column" className={classes.root}>
            {!isListingCampus && (
              <Grid item className={classes.navCol}>
                <IconButton
                  aria-label="back-to-list"
                  onClick={this.showCampusList}
                  className={classes.fab}
                >
                  <svg width="24px" height="24px" viewBox="0 0 24 24">
                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
                  </svg>
                </IconButton>
              </Grid>
            )}
            <Grid item className={classes.contentCol}>
              {isListingCampus ? (
                <Grid item xs container direction="column">
                  <Grid item xs className={classes.box}>
                    <Typography></Typography>
                    <Typography gutterBottom className={classes.heading}>
                      Select Campus of{' '}
                      <span className={classes.headingBold}>Gee-O English</span>
                    </Typography>
                    <Typography gutterBottom className={classes.subTitle}>
                      <span className={classes.subTitleBold}>Date:</span>{' '}
                      {' ' +
                        d
                          .getDate()
                          .toString()
                          .padStart(2, '0') +
                        '/' +
                        (d.getMonth() + 1).toString().padStart(2, '0') +
                        '/' +
                        d
                          .getFullYear()
                          .toString()
                          .padStart(2, '0')}
                    </Typography>
                    <Typography></Typography>
                  </Grid>
                  <Grid item>
                    <List component="nav" className={classes.classList}>
                      {campusList.map(campus => (
                        <ListItem
                          button
                          key={campus.id}
                          onClick={() => this.showRoomList(campus.name)}
                          className={classes.box}
                        >
                          <div className={classes.classText}>
                            <Typography></Typography>
                            <Typography className={classes.classLabel}>
                              Campus
                            </Typography>
                            <Typography className={classes.classTitle}>
                              {campus.name + ' (' + campus.address + ')'}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.classDetail}
                            >
                              <span className={classes.classInfo}>
                                {'Time: '}
                              </span>
                              <span className={classes.classInfo}>
                                {'Number of students: '}
                              </span>
                              <span className={classes.classInfo}>
                                {'Demo: '}
                              </span>
                            </Typography>
                            <Typography></Typography>
                          </div>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs container direction="column">
                  <Grid item xs className={classes.box}>
                    <Typography></Typography>
                    <Typography gutterBottom className={classes.heading}>
                      Select Room of Campus{' '}
                      <span className={classes.headingBold}>
                        {currentCampus.name +
                          ' (' +
                          currentCampus.address +
                          ')'}
                      </span>
                    </Typography>
                    <Typography gutterBottom className={classes.subTitle}>
                      <span className={classes.subTitleBold}>Date:</span>{' '}
                      {' ' +
                        d
                          .getDate()
                          .toString()
                          .padStart(2, '0') +
                        '/' +
                        (d.getMonth() + 1).toString().padStart(2, '0') +
                        '/' +
                        d
                          .getFullYear()
                          .toString()
                          .padStart(2, '0')}
                    </Typography>
                    <Typography></Typography>
                  </Grid>
                  <Grid item>
                    <List component="nav" className={classes.classList}>
                      {roomList.map(room => (
                        <ListItem
                          button
                          key={room.id}
                          onClick={() => this.showRoomClassList(room.id)}
                          className={classes.box}
                        >
                          <div className={classes.classText}>
                            <Typography></Typography>
                            <Typography className={classes.classLabel}>
                              Room
                            </Typography>
                            <Typography className={classes.classTitle}>
                              {room.name + ' ' + room.campus.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.classDetail}
                            >
                              <span className={classes.classInfo}>
                                {'Time: '}
                              </span>
                              <span className={classes.classInfo}>
                                {'Number of students: '}
                              </span>
                              <span className={classes.classInfo}>
                                {'Demo: '}
                              </span>
                            </Typography>
                            <Typography></Typography>
                          </div>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      );
    }

    return (
      <Fragment>
        {content}
        {this.state.redirect}
      </Fragment>
    );
  }
}

AssessmentListComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const AssessmentList = withStyles(stylesAssessmentList)(
  AssessmentListComp
);
