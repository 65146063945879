import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { ListClassCourse } from './ListClassCourse';
import { EditClassCourse } from './EditClassCourse';
import { ClassCourseActions, ClassCoursePaths } from './ClassCourseConstants';
import { AdminPage } from '../ui/page/AdminPage';

const stylesClassCourse = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class ClassCourseComp extends Component {
  static displayName = ClassCourseComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      classId: this.props.match.params.classId,
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case ClassCourseActions.List:
        this.state.pageTitle = 'Classes';
        this.state.content = <ListClassCourse />;
        break;
      case ClassCourseActions.Create:
        this.state.pageTitle = 'Create Class';
        this.state.content = (
          <EditClassCourse
            onRef={actualChild => (this.child = actualChild)}
            action={this.state.action}
          />
        );
        break;
      case ClassCourseActions.Edit:
        this.state.pageTitle = 'Edit Class';
        this.state.content = (
          <EditClassCourse
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            classId={this.state.classId}
          />
        );
        break;
      case undefined:
      default:
    }
  }
  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === 'list' ? (
        <Fab
          aria-label="Add"
          color="primary"
          className={classes.fab}
          onClick={() => this.redirectTo(ClassCoursePaths.Create)}
        >
          <AddIcon />
        </Fab>
      ) : (
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.handleChildSubmit}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleChildBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      );

    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

ClassCourseComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ClassCourse = withStyles(stylesClassCourse)(ClassCourseComp);
