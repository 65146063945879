import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { Schedule } from './Schedule';
import { SchedulePaths, ScheduleActions } from './ScheduleConstans';

export default class ScheduleRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={SchedulePaths.List}
          component={Schedule}
          action={ScheduleActions.List}
        />
        <PropsRoute
          path={SchedulePaths.EditRoute}
          component={Schedule}
          action={ScheduleActions.Edit}
        />
        <PropsRoute
          path={SchedulePaths.Create}
          component={Schedule}
          action={ScheduleActions.Create}
        />
        <PropsRoute
          path={SchedulePaths.ViewCalendarRoute}
          component={Schedule}
          action={ScheduleActions.ViewCalendar}
        />
        <PropsRoute
          path={SchedulePaths.ViewAllClassCalendar}
          component={Schedule}
          action={ScheduleActions.ViewAllClassCalendar}
        />
      </Fragment>
    );
  }
}
