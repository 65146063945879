import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  TextField,
  Tooltip
} from '@material-ui/core';
import { DialogType, Dialogs } from '../../ui/popup/Dialogs';
import React, { Component, Fragment } from 'react';

import { AcademicPaths } from '../AcademicConstants';
import AddIcon from '@material-ui/icons/Add';
import { AdminPage } from '../../ui/page/AdminPage';
import ClassSelect from '../../student-course/ClassSelect';
import { Loading } from '../../ui/Loading';
import PropTypes from 'prop-types';
import StudentSelect from '../../student-course/StudentSelect';
import authService from '../../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { stylesReserveInfo } from './stylesReserveInfo';
import { withStyles } from '@material-ui/core/styles';

class ReserveInfoComp extends Component {
  static displayName = ReserveInfoComp.name;

  constructor(...args) {
    super(...args);
    this.confirmDialog = React.createRef();
    this.dlgCommands = (
      <Fragment>
        <Button onClick={this.doReserveCommand} color="secondary">
          Accept
        </Button>
        <Button onClick={this.closeConfirmDialog} color="primary">
          Cancel
        </Button>
      </Fragment>
    );
    this.state = {
      studentId: this.props?.match.params.studentId,
      student: {},
      stdClass: {},
      selectedClass: {},
      reserveType: 'changeClass',
      studentType: '1',
      openDialog: false,
      loading: true,
      dialogContentType: '',
      pageTitle: 'Reserve'
    };
  }

  componentDidMount() {
    if (this.state.studentId !== '0') {
      this.populateStudentData();
    } else {
      this.setState({
        loading: false
      });
    }
  }

  populateStudentData = async () => {
    const token = await authService.getAccessToken();
    const { studentId } = this.state;

    const [respStudent, respStdClass] = await Promise.all([
      fetch(`api/Student/${studentId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      // fetch(`api/Student/GetClass/${studentId}`, {
      //   headers: !token ? {} : { Authorization: `Bearer ${token}` }
      // })
      fetch(`api/Student/GetRegularClass/${studentId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [student, stdClass] = await Promise.all([
      respStudent.json(),
      respStdClass.json()
    ]);

    this.setState({
      student,
      stdClass,
      loading: false
    });
  };

  nextPath(path) {
    this.props.history.push(path);
  }
  handleClick = dialogContentType => {
    this.setState({ dialogContentType }, () => {
      this.openDialog();
    });
  };
  openDialog = () => {
    this.setState({ openDialog: true });
  };
  closeDialog = () => {
    this.setState({ openDialog: false, lstSelectedDropdown: [] });
  };
  getSelectedObj = selectedValue => {
    if (this.state.dialogContentType === 'SelectClass') {
      this.setState({
        dlgSelectedValue: selectedValue,
        classId: selectedValue
      });
    } else {
      this.setState({
        dlgSelectedValue: selectedValue,
        studentId: selectedValue
      });
    }
  };
  updateSelectedObj = async () => {
    const { dialogContentType, classId } = this.state;
    const token = await authService.getAccessToken();

    switch (dialogContentType) {
      case 'SelectStudent':
        // const responseStudent = await fetch(`api/Student/${studentId}`, {
        //   headers: !token ? {} : { Authorization: `Bearer ${token}` }
        // });
        // const student = await responseStudent.json();
        // this.setState({ student });
        this.populateStudentData();
        break;
      case 'SelectClass':
        const responseClass = await fetch(`api/ClassCourses/${classId}`, {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        });
        const selectedClass = await responseClass.json();
        this.setState({ selectedClass });
        break;
      default:
        break;
    }
    this.closeDialog();
  };

  showConfirmDialog = () => {
    const { student, selectedClass, reserveType } = this.state;
    if (!student.id) {
      return;
    }
    if (reserveType === 'changeClass' && !selectedClass.id) {
      return;
    }
    this.confirmDialog.showDialogWithCmds(DialogType.Command, this.dlgCommands);
  };
  closeConfirmDialog = () => {
    this.confirmDialog.closeDialog();
  };
  doReserveCommand = async () => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const {
      student,
      stdClass,
      selectedClass,
      reserveType,
      studentType,
      suspendMonths,
      suspendReason
    } = this.state;
    let bodyData = {};
    switch (reserveType) {
      case 'changeClass':
        bodyData = {
          currentClassId: stdClass.classId,
          changeClassId: selectedClass.id,
          studentType
        };
        break;
      case 'suspend':
        bodyData = { suspendMonths, suspendReason };
        break;
      case 'terminate':
        bodyData = { reason: suspendReason };
        break;
      default:
        break;
    }
    await fetch(`api/Student/${reserveType}Student/${student.id}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(bodyData)
    });
    this.closeConfirmDialog();
    this.nextPath(`${AcademicPaths.Info}/${student.id}`);
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  renderReserveForm() {
    const { classes } = this.props;
    const {
      student,
      stdClass,
      selectedClass,
      reserveType,
      studentType,
      suspendMonths,
      suspendReason
    } = this.state;
    const paramStudentId = this.props.match.params.studentId;

    return (
      <Grid container>
        <Grid item container xs={6}>
          <Grid container spacing={2} className={classes.pageCol}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs>
                  <TextField
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      readOnly: true
                    }}
                    className={classes.margin}
                    label="Student"
                    value={student.studentName || ''}
                    placeholder="Student Name"
                  />
                </Grid>
                <Grid item>
                  {paramStudentId !== '0' ? (
                    <span className={classes.emptyCol} />
                  ) : (
                    <Tooltip title="Select student" aria-label="add">
                      <Fab
                        aria-label="Select student"
                        size="small"
                        color="primary"
                        className={classes.fabSelectClass}
                        onClick={() => this.handleClick('SelectStudent')}
                      >
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {reserveType === 'changeClass' && stdClass.className && (
              <Grid item xs={12}>
                <Box className={classes.marginLeft}>
                  <span className={classes.label}>Current class:</span>
                  <span> {stdClass.className}</span>
                </Box>
              </Grid>
            )}
            {reserveType === 'changeClass' && (
              <Fragment>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs>
                      <TextField
                        style={{ margin: 8 }}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          readOnly: true
                        }}
                        className={classes.margin}
                        label="New Class"
                        value={selectedClass.name || ''}
                        placeholder="New Class"
                      />
                    </Grid>
                    <Grid item>
                      <Tooltip title="Select class" aria-label="add">
                        <Fab
                          aria-label="Select class"
                          size="small"
                          color="primary"
                          className={classes.fabSelectClass}
                          onClick={() => this.handleClick('SelectClass')}
                        >
                          <AddIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                  >
                    <InputLabel htmlFor="student-type">Student Type</InputLabel>
                    <Select
                      value={studentType}
                      onChange={this.handleChange('studentType')}
                    >
                      <MenuItem value="1">Regular</MenuItem>
                      <MenuItem value="3">Demo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Fragment>
            )}
            {reserveType === 'suspend' && (
              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs>
                    <TextField
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      type="number"
                      label="Months"
                      placeholder="Months"
                      value={suspendMonths || ''}
                      onChange={this.handleChange('suspendMonths')}
                    />
                  </Grid>
                  <Grid item>
                    <span className={classes.emptyCol} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          <Grid container className={classes.pageCol}>
            <Grid item xs className={classes.typeCol}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Radio
                      checked={reserveType === 'changeClass'}
                      onChange={this.handleChange('reserveType')}
                      value="changeClass"
                    />
                  }
                  label="Change Class"
                />
              </FormControl>
            </Grid>
            <Grid item xs className={classes.typeCol}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Radio
                      checked={reserveType === 'suspend'}
                      onChange={this.handleChange('reserveType')}
                      value="suspend"
                    />
                  }
                  label="Suspend"
                />
              </FormControl>
            </Grid>
            <Grid item xs className={classes.typeCol}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Radio
                      checked={reserveType === 'terminate'}
                      onChange={this.handleChange('reserveType')}
                      value="terminate"
                    />
                  }
                  label="Terminate"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {reserveType && reserveType !== 'changeClass' && (
          <Grid item xs={12} className={classes.reason}>
            <TextField
              style={{ margin: 8 }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              multiline
              rows="6"
              className={classes.margin}
              label="Reason"
              placeholder="Reason"
              value={suspendReason || ''}
              onChange={this.handleChange('suspendReason')}
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.command}>
          <FormControl className={clsx(classes.formControl, classes.textField)}>
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="Search"
              className={classes.extendedFab}
              onClick={this.showConfirmDialog}
            >
              Accept
            </Fab>
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  renderContent() {
    const { loading } = this.state;

    return (
      <Fragment>
        {loading ? <Loading /> : <Paper>{this.renderReserveForm()}</Paper>}
      </Fragment>
    );
  }

  render() {
    const { pageTitle, openDialog, dialogContentType } = this.state;
    return (
      <Fragment>
        <AdminPage title={pageTitle} content={this.renderContent()} />
        <Dialog
          open={openDialog}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth
        >
          <DialogContent>
            {dialogContentType === 'SelectStudent' ? (
              <StudentSelect callbackGetValueRadioBtn={this.getSelectedObj} />
            ) : dialogContentType === 'SelectClass' ? (
              <ClassSelect callbackGetValueRadioBtn={this.getSelectedObj} />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={this.updateSelectedObj} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialogs onRef={actualChild => (this.confirmDialog = actualChild)} />
      </Fragment>
    );
  }
}

ReserveInfoComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ReserveInfo = withStyles(stylesReserveInfo)(ReserveInfoComp);
