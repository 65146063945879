import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import StudentDetail from '../../academic-manage/views/StudentDetail';
import { stylesAcademicInfo } from '../../academic-manage/stylesAcademicInfo';

class StudentInfo extends Component {
  render() {
    const { student, stdClassList, classes } = this.props;

    return (
      <Paper className={classes.root}>
        <Grid container className={classes.section}>
          <Grid item className={classes.sectionHeader}>
            <h4 className={classes.sectionTitle}>Student's Information</h4>
          </Grid>
        </Grid>
        <StudentDetail student={student} stdClassList={stdClassList} />
      </Paper>
    );
  }
}

StudentInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesAcademicInfo)(StudentInfo);
