import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import Grid from '@material-ui/core/Grid';
import { Loading } from '../ui/Loading';
import CommonDataTable from '../ui/table/CommonDataTable';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import { StudentPaths } from './StudentConstans';
import { Redirect } from 'react-router-dom';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Typography from '@material-ui/core/Typography';

const stylesListStudent = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    overflowX: 'auto'
  },
  cell: {
    padding: theme.spacing(1, 0, 1, 2)
  },
  fab: {
    margin: theme.spacing(2, 1, 1, 1)
  }
});

class ListScheduleStudentComp extends Component {
  static displayName = ListScheduleStudentComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      data: [],
      loading: true,
      filterText: '',
      studentId: this.props.studentId,
      student: null,
      redirect: null
    };
  }

  componentDidMount() {
    this.populateStudentData();
  }

  populateStudentData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch(
      `api/Student/GetScheduleStudent/${this.state.studentId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();

    const responseStudent = await fetch(
      `api/Student/GetInfoStudent/${this.state.studentId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const dataStudent = await responseStudent.json();

    this.setState({ data: data, student: dataStudent, loading: false });
    this.setChildData();
  };

  refreshStudent = async () => {
    await this.populateStudentData();
  };

  backToList = () => {
    this.redirectTo(StudentPaths.List);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  setChildData = () => {
    const { data } = this.state;
    this.child.setData(data);
  };
  render() {
    const { classes } = this.props;
    const { student } = this.state;
    const colsInfo = [
      { name: 'studentName', header: 'Name', xs: 3 },
      { name: 'englishName', header: 'English Name', xs: 3 },
      { name: 'birthdayFormat', header: 'Birthday', xs: 2 },
      { name: 'fatherName', header: 'Father', xs: 2 },
      { name: 'motherName', header: 'Mother', xs: 2 }
    ];
    const cols = [
      { name: 'class', header: 'Class', align: 'left' },
      { name: 'classRoom', header: 'Class Room', align: 'left' },
      { name: 'teacher', header: 'Teacher', align: 'left' },
      { name: 'schedule', header: 'Schedule', align: 'left' },
      { name: 'startTimeLocal', header: 'Start Time', align: 'left' },
      { name: 'endTimeLocal', header: 'End Time', align: 'left' },
      { name: 'learningProccess', header: 'Progress (Days)', align: 'left' }
    ];
    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <Paper className={classes.root}>
              <Grid container>
                {colsInfo.map((col, idx) => (
                  <Grid item xs={col.xs} className={classes.cell} key={idx}>
                    <Typography color="textSecondary" variant="caption">
                      {col.header}
                    </Typography>
                    <Typography color="textPrimary" variant="body2">
                      {col.isObj ? student[col.name].name : student[col.name]}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Paper>
            <Paper>
              <CommonDataTable
                onRef={actualChild => (this.child = actualChild)}
                rows={this.state.data}
                cols={cols}
                actions={null}
                eventReloadData={this.refreshStudent}
              />
            </Paper>
            <Fab
              aria-label="Cancel"
              className={classes.fab}
              onClick={this.backToList}
            >
              <CloseOutlinedIcon color="action" />
            </Fab>
          </Fragment>
        )}
        {this.state.redirect}
      </Fragment>
    );
  }
}

ListScheduleStudentComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListScheduleStudent = withStyles(stylesListStudent)(
  ListScheduleStudentComp
);
