import {
  MessageBarText,
  MessageBarVariants,
  MessageType
} from '../../ui/popup/Messages';
import React, { Component, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DropDragDataTable from '../../ui/table/DropDragDataTable';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { LessonPlanInfo } from '../lessons/LessonPlanInfo';
import { LessonPlanPaths } from '../lessons/LessonPlanConstants';
import { ListMaterialSelect } from '../../teacher-lesson/ListMaterialSelect';
import { Loading } from '../../ui/Loading';
import { MessageBars } from './../../ui/popup/Messages';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Toolbar } from '@material-ui/core';
import { UnitPlanInfo } from '../../common/UnitPlanInfo';
import { UnitPlanPaths } from './UnitPlanConstants';
import WarningIcon from '@material-ui/icons/Warning';
import amber from '@material-ui/core/colors/amber';
import authService from '../../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { fetchHelper } from './../../../helpers/fetch-helper';
import { withStyles } from '@material-ui/core/styles';

const stylesListUnitPlan = theme => ({
  warning: {
    backgroundColor: amber[700],
    marginTop: '15px'
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  boldTxt: {
    fontFamily: 'RalewaySemiBold',
    marginLeft: '5px'
  },
  messageContent: {
    marginLeft: '10px'
  },
  containerSelCate: {
    paddingLeft: '20%',
    paddingRight: '20%'
  },
  toolBar: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
});

class ListUnitPlanComp extends Component {
  static displayName = ListUnitPlanComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.messageBar = React.createRef();
    this.state = {
      unitPlans: [],
      lessonId: this.props.lessonId,
      lessonPlan: {},
      loading: true,
      loadingLesson: true,
      redirect: null,
      rowSelected: {},
      openDialog: false,
      selecteds: [],
      cats: {
        math: true,
        science: true,
        music: true,
        phonics: true,
        sightwords: true,
        english: true,
        softSkills: true,
        activityBook: true,
        studentBook: true,
        teacherPlanner: true,
        m0: true,
        m1: true,
        m2: true,
        m3: true,
        a: true,
        m: true,
        e: true,
        v: true,
        c: true,
        j: true,
        s: true
      },
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Success}
          type={MessageType.Upload}
          message={MessageBarText.Success.upload}
          handleClose={this.handleCloseMsgBar}
        />
      )
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateLessonData();
    this.populateUnitPlansData();
  }

  async populateLessonData() {
    const token = await authService.getAccessToken();
    const { lessonId } = this.state;
    const response = await fetch(`api/LessonPlans/${lessonId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    var lessonTimeTemp = 0;
    if (data.level.lessonTime != null) {
      lessonTimeTemp = data.level.lessonTime;
    }
    this.setState({
      lessonPlan: data,
      loadingLesson: false,
      lessonTime: lessonTimeTemp
    });
  }

  populateUnitPlansData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch(
      `api/UnitPlans/GetByLesson/${this.state.lessonId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    var timeTemp = 0;
    if (data.length > 0) {
      timeTemp = data
        .map(item => item.time)
        .reduce((prev, next) => prev + next);
    }
    const regexp = /(?:\r\n|\r|\n)/g;
    const br = '<br/>';
    for (let item in data) {
      let text = data[item].description;
      data[item].description = text ? text.replace(regexp, br) : '';
      text = data[item].materials;
      data[item].materials = text ? text.replace(regexp, br) : '';
      text = data[item].teacherActivities;
      data[item].teacherActivities = text ? text.replace(regexp, br) : '';
      text = data[item].learningOutcome;
      data[item].learningOutcome = text ? text.replace(regexp, br) : '';
      text = data[item].note;
      data[item].note = text ? text.replace(regexp, br) : '';

      data[item].strCategory = '';
      if (data[item].material) {
        data[item].strCategory = data[item].material.name;
      } else {
        data[item].strCategory = data[item].lessonPlanUnitCategories;
      }
    }
    this.setState({ unitPlans: data, loading: false, totalUnitTime: timeTemp });
    this.child.setData(this.state.unitPlans);
  };

  showMessageBar = (msgVariant, msgType, msgText) => {
    this.messageBar.showMessage(msgVariant, msgType, msgText);
  };
  handleCloseMsgBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  refreshUnitPlans = async () => {
    await this.populateUnitPlansData();
    // this.child.updateChildData(this.state.unitPlans);
    this.child.setData(this.state.unitPlans);
  };

  backToList() {
    const { lessonPlan } = this.state;
    this.redirectTo(`${LessonPlanPaths.List}/${lessonPlan.level.id}`);
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  closeDialog = () => {
    this.setState({ openDialog: false });
  };

  showPopup = (row, nameAct) => {
    this.setState({ openDialog: true, nameAction: nameAct, rowSelected: row });
    if (nameAct === 'selectCategory') {
      let categoryTemp = JSON.parse(row.category);
      if (categoryTemp !== null) {
        this.setState({
          cats: categoryTemp
        });
      } else {
        this.setState({
          cats: {
            math: false,
            science: false,
            music: false,
            phonics: false,
            sightwords: false,
            english: false,
            softSkills: false,
            activityBook: false,
            studentBook: false,
            teacherPlanner: false,
            m0: false,
            m1: false,
            m2: false,
            m3: false,
            a: false,
            m: false,
            e: false,
            v: false,
            c: false,
            j: false,
            s: false
          }
        });
      }
      this.setState({ rowSelected: row });
    }
  };

  callbackFromParentGetValRadioBtn = e => {
    var row = this.state.rowSelected;
    row.materialId = e;
    row.category = null;
    this.setState({ rowSelected: row });
  };

  handleChangeCats = name => event => {
    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;
    var catsTemp = this.state.cats;
    for (var key in catsTemp) {
      if (key === attrName) {
        catsTemp[key] = attrValue;
      }
    }
    let strCategory = JSON.stringify(catsTemp);
    let row = this.state.rowSelected;
    row.category = strCategory;
    row.materialId = null;
    this.setState({ cats: catsTemp, rowSelected: row });
  };
  handleSelectAll = setAll => {
    let cats = {
      math: setAll,
      science: setAll,
      music: setAll,
      phonics: setAll,
      sightwords: setAll,
      english: setAll,
      softSkills: setAll,
      activityBook: setAll,
      studentBook: setAll,
      teacherPlanner: setAll,
      m0: setAll,
      m1: setAll,
      m2: setAll,
      m3: setAll,
      a: setAll,
      m: setAll,
      e: setAll,
      v: setAll,
      c: setAll,
      j: setAll,
      s: setAll
    };
    let strCategory = JSON.stringify(cats);
    let rowSelected = this.state.rowSelected;
    rowSelected.category = strCategory;
    rowSelected.materialId = null;
    this.setState({ cats, rowSelected });
  };

  saveDialog = async () => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    var row = this.state.rowSelected;
    if (this.state.nameAction === 'selectCategory') {
      row.category = JSON.stringify(this.state.cats);
      row.materialId = null;
    }
    fetch(`api/UnitPlans/${row.id}`, {
      method: 'PUT',
      body: JSON.stringify(row),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.refreshUnitPlans();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    this.setState({ rowSelected: {} });
    this.closeDialog();
  };

  callBackGetListSort = (data, objUpdate) => {
    const { lessonId, token } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    var lstSortOrderPlanUnit = [];
    data.forEach((x, index) => {
      let objUpdate = {
        id: x.id,
        sortOrder: ++index
      };
      lstSortOrderPlanUnit.push(objUpdate);
    });
    fetch(`api/UnitPlans/UpdateSortorderUnitPlan/${lessonId}`, {
      method: 'POST',
      body: JSON.stringify(lstSortOrderPlanUnit),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.refreshUnitPlans();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  handleSelect = async selecteds => {
    this.setState({ selecteds });
  };

  deleteSelecteds = async () => {
    const { selecteds, unitPlans } = this.state;
    if (selecteds.length === 0) return;
    fetchHelper.post(`api/UnitPlans/ids`, selecteds).then(() => {
      const lpus = unitPlans.filter(x => !selecteds.includes(x.id));
      this.setState({ unitPlans: lpus, selecteds: [] });
      this.child.setData(lpus);
      this.showMessageBar(
        MessageBarVariants.Success,
        MessageType.Delete,
        MessageBarText.Success.delete
      );
    });
  };

  renderDatagrid() {
    const cols = [
      { name: 'sortOrder', header: '#', align: 'center', width: 50 },
      { name: 'time', header: 'Time (mins)', align: 'center', width: 65 },
      {
        name: 'procedures',
        header: 'Procedures',
        align: 'left',
        shorteningContent: true,
        width: 120,
        indexCut: 40
      },
      {
        name: 'description',
        header: 'Description',
        align: 'left',
        shorteningContent: true,
        width: 130,
        indexCut: 30
      },
      {
        name: 'materials',
        header: 'Materials',
        align: 'left',
        shorteningContent: true,
        indexCut: 30
      },
      {
        name: 'teacherActivities',
        header: "Teachers' activities",
        align: 'left',
        shorteningContent: true,
        indexCut: 40
      },
      {
        name: 'learningOutcome',
        header: "Students' learning outcome",
        align: 'left',
        shorteningContent: true,
        width: 190,
        indexCut: 40
      },
      {
        name: 'note',
        header: 'Note',
        align: 'left',
        width: 150,
        shorteningContent: true,
        indexCut: 30
      },
      {
        name: 'strCategory',
        header: 'Library Material',
        align: 'left',
        width: 150
      }
    ];
    const actions = [
      {
        name: 'selectCategory',
        tooltip: 'Set categories',
        icon: 'category'
      },
      {
        name: 'editMaterial',
        tooltip: 'Set material',
        icon: 'assignment'
      },
      {
        name: 'edit',
        tooltip: 'Edit procedure',
        url: `${UnitPlanPaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'delete',
        tooltip: 'Delete procedure',
        url: `${UnitPlanPaths.Delete}`,
        icon: 'delete_icon'
      }
    ];
    const { lessonTime, totalUnitTime, nameAction, cats } = this.state;
    const { classes } = this.props;
    return (
      <Fragment>
        <DropDragDataTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.unitPlans}
          cols={cols}
          actions={actions}
          isShowPopup={true}
          isShowCheckbox={true}
          callbackFromParent={this.handleSelect}
          callbackGetIdShowPopup={this.showPopup}
          eventReloadData={this.refreshUnitPlans}
          callBackGetListSort={this.callBackGetListSort}
        />
        {lessonTime !== totalUnitTime ? (
          <SnackbarContent
            className={clsx(classes['warning'], '')}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <WarningIcon />
                <span className={classes.messageContent}>
                  Total time of units is not equal to the lesson time in plan:{' '}
                  <span className={classes.boldTxt}>
                    {' '}
                    {totalUnitTime} / {lessonTime}
                  </span>{' '}
                  (mins).
                </span>
              </span>
            }
          />
        ) : (
          ''
        )}
        <Dialog
          open={this.state.openDialog}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          maxWidth={nameAction === 'editMaterial' ? 'lg' : 'md'}
          fullWidth
        >
          <DialogContent>
            {nameAction === 'editMaterial' ? (
              <Fragment>
                <UnitPlanInfo dataUnit={this.state.rowSelected} />
                <ListMaterialSelect
                  materialId={this.state.rowSelected.materialId}
                  category={this.state.rowSelected.category}
                  lessonPlanUnitId={this.state.rowSelected.id}
                  callbackFromParentGetValRadioBtn={
                    this.callbackFromParentGetValRadioBtn
                  }
                  isUnitPlan={true}
                />
              </Fragment>
            ) : (
              <Fragment>
                <UnitPlanInfo dataUnit={this.state.rowSelected} />
                <Grid container className={classes.containerSelCate}>
                  <Grid item xs={12}>
                    <div className={classes.toolBar}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={() => {
                          this.handleSelectAll(true);
                        }}
                      >
                        All
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => {
                          this.handleSelectAll(false);
                        }}
                      >
                        None
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.math}
                            onChange={this.handleChangeCats('math')}
                            value="math"
                          />
                        }
                        label="Math"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.science}
                            onChange={this.handleChangeCats('science')}
                            value="science"
                          />
                        }
                        label="Science"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.music}
                            onChange={this.handleChangeCats('music')}
                            value="music"
                          />
                        }
                        label="Music"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.phonics}
                            onChange={this.handleChangeCats('phonics')}
                            value="phonics"
                          />
                        }
                        label="Phonics"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.sightwords}
                            onChange={this.handleChangeCats('sightwords')}
                            value="sightwords"
                          />
                        }
                        label="Sightwords"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.english}
                            onChange={this.handleChangeCats('english')}
                            value="english"
                          />
                        }
                        label="English"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.softSkills}
                            onChange={this.handleChangeCats('softSkills')}
                            value="softSkills"
                          />
                        }
                        label="Soft skills"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.activityBook}
                            onChange={this.handleChangeCats('activityBook')}
                            value="activityBook"
                          />
                        }
                        label="Activity book"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.studentBook}
                            onChange={this.handleChangeCats('studentBook')}
                            value="studentBook"
                          />
                        }
                        label="Student's book"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.teacherPlanner}
                            onChange={this.handleChangeCats('teacherPlanner')}
                            value="teacherPlanner"
                          />
                        }
                        label="Teacher planner"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.m0}
                            onChange={this.handleChangeCats('m0')}
                            value="m0"
                          />
                        }
                        label="M0"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.m1}
                            onChange={this.handleChangeCats('m1')}
                            value="m1"
                          />
                        }
                        label="M1"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.a}
                            onChange={this.handleChangeCats('a')}
                            value="a"
                          />
                        }
                        label="A"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.m}
                            onChange={this.handleChangeCats('m')}
                            value="m"
                          />
                        }
                        label="M"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.e}
                            onChange={this.handleChangeCats('e')}
                            value="e"
                          />
                        }
                        label="E"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.v}
                            onChange={this.handleChangeCats('v')}
                            value="v"
                          />
                        }
                        label="V"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.m2}
                            onChange={this.handleChangeCats('m2')}
                            value="m2"
                          />
                        }
                        label="M2"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.m3}
                            onChange={this.handleChangeCats('m3')}
                            value="m3"
                          />
                        }
                        label="M3"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.c}
                            onChange={this.handleChangeCats('c')}
                            value="c"
                          />
                        }
                        label="C"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.j}
                            onChange={this.handleChangeCats('j')}
                            value="j"
                          />
                        }
                        label="J"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.s}
                            onChange={this.handleChangeCats('s')}
                            value="s"
                          />
                        }
                        label="S"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={this.saveDialog} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }

  renderToolbar() {
    return (
      <>
        <Toolbar>
          <Grid
            xs={12}
            style={{
              width: '100%',
              height: 'auto',
              padding: 8,
              display: 'flex',
              justifyContent: 'end'
            }}
          >
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              aria-label="add"
              style={{
                width: 130,
                height: 36,
                marginLeft: 16,
                marginRight: 8,
                backgroundColor: '#a93a3a'
              }}
              onClick={this.deleteSelecteds}
            >
              DELETE
            </Fab>
          </Grid>
        </Toolbar>
      </>
    );
  }

  render() {
    const { lessonPlan } = this.state;
    let lessonPlanInfo = this.state.loadingLesson ? (
      <Loading />
    ) : (
      <>
        {this.renderToolbar()}
        <LessonPlanInfo lessonPlan={lessonPlan} />
      </>
    );
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();
    return (
      <Fragment>
        {lessonPlanInfo}
        {contents}
        {this.state.messageBar}
        {this.state.redirect}
      </Fragment>
    );
  }
}

ListUnitPlanComp.propTypes = {
  classes: PropTypes.object.isRequired,
  lessonId: PropTypes.string
};

export const ListUnitPlan = withStyles(stylesListUnitPlan)(ListUnitPlanComp);
