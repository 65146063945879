import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import CustomizedTable from '../ui/table/CustomizedTable';
import { Loading } from '../ui/Loading';
import ClassCourseInfo from '../class-course/ClassCourseInfo';
import {
  TeacherLessonPaths,
  TeacherLessonActions
} from './TeacherLessonConstants';
import { MyClassPaths } from '../my-class/MyClassConstants';
import { ClassReportPaths } from '../report/class/ClassReportConstants';

const stylesViewTeacherLesson = () => ({});

class ViewTeacherLessonComp extends Component {
  static displayName = ViewTeacherLessonComp.name;

  constructor(...args) {
    super(...args);
    this.state = {
      classLessonData: [],
      classCourse: {},
      loading: true
    };
  }

  componentDidMount() {
    this.populateDataInit();
  }

  async populateDataInit() {
    const token = await authService.getAccessToken();
    // await fetch(
    //   `api/ClassLesson/BuildSheduleClassLessons/${this.props.classId}`,
    //   {
    //     method: 'POST',
    //     headers: !token ? {} : { Authorization: `Bearer ${token}` }
    //   }
    // );

    const [respClassCourse, respClassLessonData] = await Promise.all([
      fetch(`api/ClassCourses/${this.props.classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(
        `api/TeacherLessonUnit/GetClassLessonByTeacher/${this.props.classId}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      )
    ]);
    const [dataClassCourse, dataClassLesson] = await Promise.all([
      respClassCourse.json(),
      respClassLessonData.json()
    ]);
    this.setState({
      classCourse: dataClassCourse,
      classLessonData: dataClassLesson,
      loading: false
    });
    //this.child.setData(this.state.classLessonData);
  }

  showLessonPreview = async rowData => {
    window.open(`${MyClassPaths.LetsLearn}/${rowData.id}/0/1/0`);
  };

  showPopup = (row, nameAct) => {
    console.log(row, nameAct);
  };

  refreshLessonPlans = async () => {
    await this.populateDataInit();
    this.child.updateChildData(this.state.lessonPlans);
    //this.child.setData(this.state.classLessonData);
  };

  renderDatagrid() {
    const cols = [
      { name: 'lesson', header: 'Lesson', align: 'left', width: '180' },
      { name: 'subject', header: 'Subject', align: 'left', width: '200' },
      { name: 'content', header: 'Content', align: 'left', width: '300' },
      { name: 'tb', header: 'Tb', align: 'left', width: '180' }
    ];
    const actions = [
      {
        name: 'multi-route',
        tooltip: 'Set material and note',
        url:
          this.props.action === TeacherLessonActions.ViewLesson
            ? `${TeacherLessonPaths.ViewUnitPlan}`
            : `${TeacherLessonPaths.ViewUnitAdmin}`,
        icon: 'list_alt_icon'
      },
      {
        name: 'detail',
        tooltip: 'Lesson report',
        url: `${ClassReportPaths.ViewLessonReport}`,
        icon: 'pageview'
      },
      {
        name: 'callback',
        callBackFn: this.showLessonPreview,
        tooltip: 'Preview lesson',
        icon: 'play_arrow'
      }
    ];
    return (
      <Fragment>
        <ClassCourseInfo classCourse={this.state.classCourse} teachers={true} />
        <CustomizedTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.classLessonData}
          cols={cols}
          actions={actions}
          //callbackParentAction={this.showLessonPreview}
        />
      </Fragment>
    );
  }
  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return <Fragment>{contents}</Fragment>;
  }
}

ViewTeacherLessonComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ViewTeacherLesson = withStyles(stylesViewTeacherLesson)(
  ViewTeacherLessonComp
);
