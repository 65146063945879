import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import SearchIcon from '@material-ui/icons/Search';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 1, 0, 2)
  },
  highlight: {},
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    display: 'flex',
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(grey[500], 0.15),
    '&:hover': {
      backgroundColor: fade(grey[500], 0.25)
    },
    marginLeft: 0,
    marginTop: 6,
    marginRight: theme.spacing(1),
    width: '100%',
    height: 35,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200
      }
    }
  }
}));

export function MyClassFilterToolbar(props) {
  const classes = useToolbarStyles();
  const { filterText, handleChange } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: false
      })}
    >
      <div className={classes.title} />
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={filterText}
            onChange={handleChange('filterText')}
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ 'aria-label': 'Search' }}
          />
        </div>
      </div>
    </Toolbar>
  );
}
