import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import { Button } from '@material-ui/core';
//import authService from '../api-authorization/AuthorizeService';
import Iframe from '../common/Iframe';
import { Loading } from '../ui/Loading';

const stylesPreviewMaterial = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  dialogContent: {
    height: '75vh',
    padding: theme.spacing(0, 2, 1, 2)
  }
});

class PreviewMaterial extends Component {
  static displayName = PreviewMaterial.name;

  constructor(...args) {
    super(...args);
    this.state = {
      material: {},
      lessonUnit: {},
      open: false,
      loading: true
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  populateMaterialData = async () => {
    //const token = await authService.getAccessToken();
    const { material } = this.state;
    const lessonUnit = {
      materialId: material.id,
      materialFileName: material.fileName,
      materialUrl: material.url,
      procedures: ''
    };
    this.setState({
      lessonUnit,
      loading: false
    });
  };

  openMaterialPreview(material) {
    this.setState({ material, loading: true }, this.populateMaterialData);
    this.openDialog();
  }

  openDialog = () => {
    this.setState({ open: true });
  };

  closeDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { open, lessonUnit } = this.state;

    return (
      <Fragment>
        <Dialog open={open} onClose={this.closeDialog} maxWidth="lg" fullWidth>
          <DialogTitle>{'Material Preview'}</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {this.state.loading ? (
              <Loading />
            ) : (
              <Fragment>
                <Iframe lessonUnit={lessonUnit} title={lessonUnit.procedures} />
              </Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

PreviewMaterial.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesPreviewMaterial)(PreviewMaterial);
