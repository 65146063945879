import { Bar, BarChart, BarSeries } from 'reaviz';
import { Grid, Paper, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import { PropTypes } from 'prop-types';
import clsx from 'clsx';

export class BarChartView extends Component {
  render() {
    const { title, subtitle, chartData, classes } = this.props;
    return (
      <Paper className={classes.chartBox}>
        {title && (
          <Typography variant="h3" className={classes.heading}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="body2" className={classes.subtitle}>
            {subtitle}
          </Typography>
        )}
        <Grid item xs={12} style={{ overflowY: 'auto' }}>
          <div
            className={clsx(classes.chartWrap, classes.chartWrapPadding)}
            style={
              chartData.length > 12
                ? {
                    width: 72 * chartData.length,
                    paddingBottom: 10
                  }
                : { width: '100%', paddingBottom: 10 }
            }
          >
            {chartData?.length > 0 && (
              <BarChart
                data={chartData}
                series={
                  <BarSeries
                    colorScheme={_data => {
                      return _data?.value === 1 ? '#42a5f6' : '#f2711c';
                    }}
                    bar={<Bar gradient={false} width={40} />}
                    padding={0.8}
                  />
                }
                className={classes.barChart}
              />
            )}
          </div>
        </Grid>
      </Paper>
    );
  }
}

BarChartView.propTypes = {
  classes: PropTypes.object.isRequired,
  chartData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};
