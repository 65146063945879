import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { Grid, Paper, Typography, List, ListItem } from '@material-ui/core';
import { AssessmentPaths } from './AssessmentConstants';

const stylesPresenceList = theme => ({
  paper: {
    color: '#707070',
    width: '100%',
    maxWidth: 1400,
    minHeight: '100vh',
    margin: 'auto'
  },
  root: {
    flexGrow: 1
  },
  contentCol: {
    width: '100%',
    flexGrow: 1
  },
  navCol: {
    height: '100%',
    padding: theme.spacing(0.25)
  },
  box: {
    borderBottom: '2px #707070 solid',
    '&:last-child': {
      marginBottom: theme.spacing(4)
    }
  },
  heading: {
    fontSize: 30,
    letterSpacing: 0,
    textAlign: 'center',
    margin: theme.spacing(4, 0, 2.5, 0)
  },
  headingBold: {
    fontWeight: 800
  },
  subTitle: {
    fontSize: 16,
    letterSpacing: 0,
    textAlign: 'center',
    margin: theme.spacing(0, 0, 3, 0)
  },
  subTitleBold: {
    fontWeight: 'bold'
  },
  classList: {
    padding: theme.spacing(0)
  },
  classText: {
    textAlign: 'center',
    width: '100%'
  },
  classLabel: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  classTitle: {
    fontSize: 24,
    marginTop: theme.spacing(1.5)
  },
  classDetail: {
    margin: theme.spacing(1.5, 0)
  },
  classInfo: {
    marginLeft: theme.spacing(4),
    '&:first-child': {
      marginLeft: 0
    }
  },
  fab: {
    margin: 'auto 0'
  }
});

class PresenceListComp extends Component {
  static displayName = PresenceListComp.name;
  constructor(...args) {
    super(...args);
    this.state = {
      redirect: null,
      loading: true
    };
  }

  componentDidMount() {
    this.populateRoomsData();
  }

  populateRoomsData = async () => {
    const token = await authService.getAccessToken();
    const [respCampus] = await Promise.all([
      fetch(`api/Campus/GetCampusList/0`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [campusList] = await Promise.all([respCampus.json()]);

    this.setState({ campusList, loading: false });
  };

  showClassList = campusId => {
    const { classMap, campusMap } = this.state;
    const classList = classMap[campusId];
    const currentCampus = campusMap[campusId];
    this.setState({ currentCampus, classList, isListingCampus: false });
  };

  showCampusList = () => {
    this.setState({ isListingCampus: true });
  };

  showPresenceStudent = campusId => {
    this.redirectTo(`${AssessmentPaths.PresenceStudent}/${campusId}`);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  render() {
    const { classes } = this.props;
    const { campusList, loading } = this.state;
    const d = new Date();
    let content = <Loading />;

    if (!loading) {
      content = (
        <Paper className={classes.paper}>
          <Grid container direction="column" className={classes.root}>
            <Grid item xs className={classes.box}>
              <Typography></Typography>
              <Typography gutterBottom className={classes.heading}>
                Select Campus of{' '}
                <span className={classes.headingBold}>Gee-O English</span>
              </Typography>
              <Typography gutterBottom className={classes.subTitle}>
                <span className={classes.subTitleBold}>Date:</span>{' '}
                {' ' +
                  d
                    .getDate()
                    .toString()
                    .padStart(2, '0') +
                  '/' +
                  (d.getMonth() + 1).toString().padStart(2, '0') +
                  '/' +
                  d
                    .getFullYear()
                    .toString()
                    .padStart(2, '0')}
              </Typography>
              <Typography></Typography>
            </Grid>
            <Grid item>
              <List component="nav" className={classes.classList}>
                {campusList.map(campus => (
                  <ListItem
                    button
                    key={campus.id}
                    onClick={() => this.showPresenceStudent(campus.id)}
                    className={classes.box}
                  >
                    <div className={classes.classText}>
                      <Typography></Typography>
                      <Typography className={classes.classLabel}>
                        Campus
                      </Typography>
                      <Typography className={classes.classTitle}>
                        {campus.name + ' (' + campus.address + ')'}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.classDetail}
                      >
                        <span className={classes.classInfo}>{'Time: '}</span>
                        <span className={classes.classInfo}>
                          {'Number of students: '}
                        </span>
                        <span className={classes.classInfo}>{'Demo: '}</span>
                      </Typography>
                      <Typography></Typography>
                    </div>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
          {this.state.redirect}
        </Paper>
      );
    }

    return <Fragment>{content}</Fragment>;
  }
}

PresenceListComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const PresenceList = withStyles(stylesPresenceList)(PresenceListComp);
