import {
  CatchUpScheduleActions,
  CatchUpSchedulePaths
} from './CatchUpScheduleContans';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import React, { Component, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import DateFnsUtils from '@date-io/date-fns';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import ScheduleClassLessonSelect from './ScheduleClassLessonSelect';
import Select from '@material-ui/core/Select';
import SelectClass from './SelectClass';
import StudentSelect from './StudentSelect';
import TextField from '@material-ui/core/TextField';
import authService from '../api-authorization/AuthorizeService';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

//import Checkbox from '@material-ui/core/Checkbox';

//import FormControlLabel from '@material-ui/core/FormControlLabel';

const stylesComp = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  tbClass: {
    display: 'none'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  },
  dropdownTypeCatchUp: {
    marginBottom: '25px'
  },
  groupSchedule: {
    marginTop: '20px'
  },
  fab: {
    margin: '12px 8px 8px 8px'
  },
  padding10: {
    padding: '10px'
  },
  formControl: {
    marginLeft: '15px'
    // margin: theme.spacing(3, 3, 3, 5)
  }
});

class EditCatchUpScheduleComp extends Component {
  static displayName = EditCatchUpScheduleComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.form = React.createRef();
    this.state = {
      teacherId: '',
      filterText: '',
      openDialog: false,
      selectedId: '',
      isMySchedule: this.props.isMySchedule,
      catchUpScheduleId: this.props.catchUpScheduleId,
      classLessonId: this.props.classLessonId,
      method: 'PUT',
      redirect: null,
      catchUpSchedules: {
        name: '',
        catchUpType: 1,
        teacherId: '',
        studentId: '',
        classLessonId: '',
        classLessonTemp: '',
        studentTemp: '',
        catchUpTime: 0,
        isChangeClass: false,
        newClassId: '',
        linkOnline: '',
        startDate: new Date(),
        startTime: new Date()
      },
      studentStr: '',
      scheduleCatchUp: '',
      anotherClass: '',
      actionColName: '',
      newClass: {}
    };
    switch (this.props.action) {
      case CatchUpScheduleActions.Create:
        this.state.method = 'POST';
        this.state.action = `api/CatchUpSchedules`;
        this.state.loading = false;
        break;
      case CatchUpScheduleActions.Edit:
        this.state.action = `api/CatchUpSchedules/${this.state.catchUpScheduleId}`;
        this.getcatchUpSchedule();
        break;
      case CatchUpScheduleActions.CreateCatchUpLesson:
        this.state.method = 'POST';
        this.state.action = `api/CatchUpSchedules`;
        this.state.loading = false;
        break;
      case CatchUpScheduleActions.CreateCatchUpStudent:
        this.state.method = 'POST';
        this.state.action = `api/CatchUpSchedules`;
        this.state.loading = false;
        this.state.actionColName = 'student';
        this.state.selectedId = this.props.studentId;
        this.saveDataSelected();
        break;
      case undefined:
      default:
    }
  }
  componentDidMount() {
    this.populateDataInit();
  }

  handleChange = name => event => {
    const attrName = name;
    const target = event.target;
    var attrValue = target.type === 'checkbox' ? target.checked : target.value;
    if (attrName === 'catchUpType') {
      attrValue = parseInt(target.value);
    }
    this.setState(prevState => ({
      catchUpSchedules: {
        ...prevState.catchUpSchedules,
        [attrName]: attrValue
      }
    }));
  };

  getcatchUpSchedule = async () => {
    const { catchUpScheduleId } = this.state;
    const token = await authService.getAccessToken();
    const response = await fetch(`api/CatchUpSchedules/${catchUpScheduleId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const catchUpSchedule = await response.json();
    if (catchUpSchedule.studentId) {
      this.getStudent(catchUpSchedule.studentId);
    }
    if (catchUpSchedule.classLessonId) {
      this.getScheduleLesson(catchUpSchedule.classLessonId);
    }
    if (catchUpSchedule.newClassId) {
      this.getNewClassCourseSchedule(catchUpSchedule.newClassId);
    }
    this.setState({ catchUpSchedules: catchUpSchedule });
  };

  populateDataInit = async () => {
    const currentUser = await authService.getUser();
    var catchUpSchedules = this.state.catchUpSchedules;
    catchUpSchedules.teacherId = currentUser.sub;
    this.setState({
      teacherId: currentUser.sub,
      catchUpSchedules: catchUpSchedules
    });
  };

  submitData = async () => {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    this.handleSubmit();
  };

  handleChangeClassLesson = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(
      {
        [attrName]: attrValue
      },
      this.setChildData
    );
  };

  openDialog = () => {
    this.setState({ openDialog: true });
  };

  closeDialog = () => {
    this.setState({ openDialog: false, selectedId: '' });
  };

  backToList = () => {
    this.redirectTo(`${CatchUpSchedulePaths.List}`);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  handleClick = async e => {
    this.setState({ actionColName: e });
    this.openDialog();
  };

  handleDateChange = name => date => {
    const attrName = name;
    const attrValue = date;
    this.setState(prevState => ({
      catchUpSchedules: {
        ...prevState.catchUpSchedules,
        [attrName]: attrValue
      }
    }));
  };

  handleSubmit = () => {
    //e.preventDefault();
    const { catchUpSchedules, method, action, token } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    if (catchUpSchedules.startTime instanceof Date) {
      catchUpSchedules.startTime = catchUpSchedules.startTime.toLocaleTimeString();
    }
    fetch(action, {
      method: method,
      body: JSON.stringify(catchUpSchedules),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  getStudent = async id => {
    const token = await authService.getAccessToken();
    const response = await fetch(`api/Student/${id}`, {
      headers: !token ? {} : { Authorization: `Bearer ${id}` }
    });
    const studentStr = await response.json();
    this.setState(prevState => ({
      catchUpSchedules: {
        ...prevState.catchUpSchedules,
        studentId: id
      },
      studentStr: `${studentStr.englishName}`
    }));
  };

  getScheduleLesson = async id => {
    const token = await authService.getAccessToken();
    const response = await fetch(`api/ClassLesson/GetClassLessonById/${id}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const scheduleCatchUp = await response.json();
    this.setState(prevState => ({
      catchUpSchedules: {
        ...prevState.catchUpSchedules,
        classLessonId: id
      },
      scheduleCatchUp: `${scheduleCatchUp.lesson.level.name}-${scheduleCatchUp.classCourse.name}-${scheduleCatchUp.lesson.lesson}`
    }));
  };

  saveDataSelected = async () => {
    const { selectedId, actionColName } = this.state;
    if (actionColName === 'student') {
      this.getStudent(selectedId);
    } else if (actionColName === 'scheduleCatchUp') {
      this.getScheduleLesson(selectedId);
    } else if (actionColName === 'anotherClass') {
      if (this.state.catchUpSchedules.newClassId === '') {
        this.getNewClassCourseSchedule(selectedId);
      }
    }
    this.closeDialog();
  };

  callbackGetValueRadioBtn = e => {
    this.setState({ selectedId: e });
  };

  getNewClassCourseSchedule = async id => {
    const token = await authService.getAccessToken();

    const response = await fetch(
      `api/ClassCourses/GetNewClassCourseSchedule/${id}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const newClassObj = await response.json();
    this.setState(prevState => ({
      catchUpSchedules: {
        ...prevState.catchUpSchedules,
        newClassId: id
      },
      anotherClass: `${newClassObj.level} - ${newClassObj.class} - ${newClassObj.teacher} - ${newClassObj.schedule}`
    }));
  };

  callbackGetValueNewClass = e => {
    this.setState({ newClass: e });
  };

  renderForm = () => {
    const { classes } = this.props;
    const { catchUpSchedules } = this.state;
    const cols = [
      { name: 'name', header: 'Name', align: 'left' },
      { name: 'catchUpType', header: 'Catch-Up Type', align: 'right' },
      { name: 'student', header: 'Student', align: 'right' },
      { name: 'scheduleCatchUp', header: 'Schedule Catch-Up', align: 'right' },
      { name: 'anotherClass', header: 'Another Class', align: 'right' },
      { name: 'catchUpTime', header: 'Catch Up Time', align: 'right' },
      { name: 'linkOnline', header: 'Link', align: 'right' }
    ];
    return (
      <Fragment>
        <Paper className={classes.root}>
          <form
            ref={this.form}
            className={classes.container}
            onSubmit={this.handleSubmit}
          >
            <Grid container>
              <Grid item xs={6} className={classes.cell}>
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="name"
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      rows="6"
                      className={classes.margin}
                      label={cols[0].header}
                      name={cols[0].name}
                      value={catchUpSchedules[cols[0].name]}
                      onChange={this.handleChange(cols[0].name)}
                    />
                  </ListItem>
                  {/* <ListItem>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={catchUpSchedules.isChangeClass}
                            onChange={this.handleChange('isChangeClass')}
                            value="isChangeClass"
                          />
                        }
                        label="Change Class"
                      />
                    </FormControl>
                  </ListItem> */}
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="student"
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      rows="6"
                      InputProps={{
                        readOnly: true
                      }}
                      className={classes.margin}
                      label={cols[2].header}
                      name={cols[2].name}
                      value={this.state.studentStr || ''}
                      onClick={() => this.handleClick('student')}
                      placeholder=""
                    />
                  </ListItem>
                  {catchUpSchedules.catchUpType === 3 ? (
                    <ListItem className={classes.listItem}>
                      <TextField
                        id="class"
                        style={{ margin: 8 }}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        rows="6"
                        InputProps={{
                          readOnly: true
                        }}
                        className={classes.margin}
                        label={cols[4].header}
                        name={cols[4].name}
                        value={this.state.anotherClass || ''}
                        onClick={() => this.handleClick('anotherClass')}
                        placeholder=""
                      />
                    </ListItem>
                  ) : catchUpSchedules.catchUpType === 1 ? (
                    <ListItem className={classes.listItem}>
                      <TextField
                        id="name"
                        style={{ margin: 8 }}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        rows="6"
                        className={classes.margin}
                        label={cols[5].header}
                        name={cols[5].name}
                        value={catchUpSchedules[cols[5].name]}
                        onChange={this.handleChange(cols[5].name)}
                      />
                    </ListItem>
                  ) : catchUpSchedules.catchUpType === 4 ? (
                    <ListItem className={classes.listItem}>
                      <TextField
                        id="name"
                        style={{ margin: 8 }}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        rows="6"
                        className={classes.margin}
                        label={cols[6].header}
                        name={cols[6].name}
                        value={catchUpSchedules[cols[6].name]}
                        onChange={this.handleChange(cols[6].name)}
                      />
                    </ListItem>
                  ) : (
                    ''
                  )}
                </List>
              </Grid>
              <Grid item xs={6}>
                <ListItem className={classes.listItem}>
                  <FormControl
                    className={classNames(
                      classes.margin,
                      classes.textField,
                      classes.dropdownTypeCatchUp
                    )}
                  >
                    <InputLabel htmlFor="catchUpType">
                      {cols[1].header}
                    </InputLabel>
                    <Select
                      id="catchUpType"
                      name={cols[1].name}
                      value={
                        catchUpSchedules[cols[1].name] === ''
                          ? 1
                          : catchUpSchedules[cols[1].name]
                      }
                      onChange={this.handleChange(cols[1].name)}
                    >
                      <MenuItem value="1">
                        30 minutes before class started
                      </MenuItem>
                      <MenuItem value="2">A time in this week</MenuItem>
                      <MenuItem value="3">In another class</MenuItem>
                      <MenuItem value="4">Online</MenuItem>
                    </Select>
                  </FormControl>
                </ListItem>
                {catchUpSchedules.catchUpType !== 2 &&
                catchUpSchedules.catchUpType !== 3 &&
                catchUpSchedules.catchUpType !== 4 ? (
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="student"
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      rows="6"
                      InputProps={{
                        readOnly: true
                      }}
                      className={classes.margin}
                      label={cols[3].header}
                      name={cols[3].name}
                      value={this.state.scheduleCatchUp || ''}
                      onClick={() => this.handleClick('scheduleCatchUp')}
                      placeholder=""
                    />
                  </ListItem>
                ) : (
                  ''
                )}
                {catchUpSchedules.catchUpType === 2 && (
                  <ListItem className={classes.listItem}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid
                        container
                        className={classes.grid}
                        justify="space-around"
                      >
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label="Start date"
                          format="dd/MM/yyyy"
                          value={catchUpSchedules.startDate}
                          onChange={this.handleDateChange('startDate')}
                          KeyboardButtonProps={{
                            'aria-label': 'change start date'
                          }}
                        />
                        <KeyboardTimePicker
                          margin="normal"
                          id="mui-pickers-starttime"
                          label="Start time"
                          minutesStep={5}
                          value={catchUpSchedules.startTime}
                          onChange={this.handleDateChange('startTime')}
                          KeyboardButtonProps={{
                            'aria-label': 'change start time'
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </ListItem>
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Dialog
          open={this.state.openDialog}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth
        >
          <DialogContent>
            {this.state.actionColName === 'scheduleCatchUp' ? (
              <ScheduleClassLessonSelect
                catchUpId={this.state.catchUpId}
                actionColName={this.state.actionColName}
                callbackGetValueRadioBtn={this.callbackGetValueRadioBtn}
                catchUp={this.state.catchUpCurrent}
              />
            ) : (
              ''
            )}
            {this.state.actionColName === 'student' ? (
              <StudentSelect
                catchUpId={this.state.catchUpId}
                actionColName={this.state.actionColName}
                callbackGetValueRadioBtn={this.callbackGetValueRadioBtn}
                catchUp={this.state.catchUpCurrent}
              />
            ) : (
              ''
            )}

            {this.state.actionColName === 'anotherClass' ? (
              <SelectClass
                catchUpId={this.state.catchUpId}
                actionColName={this.state.actionColName}
                callbackGetValueRadioBtn={this.callbackGetValueRadioBtn}
                callbackGetValueNewClass={this.callbackGetValueNewClass}
                classLessonId={catchUpSchedules.classLessonId}
              />
            ) : (
              ''
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={this.saveDataSelected} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  };

  render() {
    let contents = this.renderForm();
    const { classes } = this.props;
    return (
      <Fragment>
        {contents}
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.submitData}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.backToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
        {this.state.redirect}
      </Fragment>
    );
  }
}

EditCatchUpScheduleComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const EditCatchUpSchedule = withStyles(stylesComp)(
  EditCatchUpScheduleComp
);
