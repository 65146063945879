import { Grid, Typography } from '@material-ui/core';
import React, { Component, Fragment } from 'react';

import { ExamChartView } from './ExamChartView';
import { ExamResultUtils } from '../../../common/ExamResultUtils';
import { ExamResultsView } from './ExamResultsView';
import { Loading } from '../../../ui/Loading';
import { NumberUtils } from '../../../common/NumberUtils';
import PropTypes from 'prop-types';
import { ReportClassView } from './ReportClassView';
import authService from '../../../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { stylesAcademicInfo } from '../../../academic-manage/stylesAcademicInfo';
import { stylesCharting } from '../stylesCharting';
import { withStyles } from '@material-ui/core/styles';

class ExamResultReports extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.populateStudentData();
  }

  populateStudentData = async () => {
    const { classInfo } = this.props;
    const token = await authService.getAccessToken();
    const [
      respStudentExamResults,
      respClassLessonStatistics
    ] = await Promise.all([
      fetch(`api/StudentReport/GetStudentExamResults/${classInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/LessonReport/GetClassLessonStatistics/${classInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [examResults, lessonStatistics] = await Promise.all([
      respStudentExamResults.json(),
      respClassLessonStatistics.json()
    ]);

    const reportClassActivities = lessonStatistics.lessonTimeData.map(
      lesson => {
        return {
          time: lesson.lessonDateTimeText,
          activity: "Let's Learn Lesson " + lesson.lesson,
          status: lesson.status
        };
      }
    );
    const reportClassActivitiesCols = [
      {
        name: 'time',
        header: 'Time',
        align: 'left'
      },
      {
        name: 'activity',
        header: 'Require Activities',
        align: 'left'
      },
      {
        name: 'status',
        header: 'Status',
        align: 'center'
      }
    ];

    const chartDataExamResults = [];
    const dataExamResults = [];
    const students = [];
    const studentNames = [];
    const academics = [];
    const tableCols = [
      {
        name: 'name',
        header: "Student's Name",
        align: 'left'
      }
    ];

    for (var i = 0; i < examResults.length; i++) {
      const examResult = examResults[i].examResult;

      if (students.length === 0) {
        examResult.forEach(item => {
          students.push(item.englishName);
          studentNames.push(item.studentName + ' - ' + item.englishName);
        });
      }
      const chartData = [];
      const studentDataExamResults = [];

      examResult.forEach((itemStudent, idxStudent) => {
        studentDataExamResults[idxStudent] = [];

        const examForm = itemStudent.examResultJson
          ? JSON.parse(itemStudent.examResultJson)
          : {};

        if (examForm.academic) {
          examForm.academic.forEach(itemRes => {
            if (!academics.find(i => i.name === itemRes.name)) {
              const res = { name: itemRes.name, shortName: itemRes.name[0] };
              academics.push(res);
              tableCols.push({
                name: res.name,
                header: res.shortName,
                align: 'center'
              });
            }

            studentDataExamResults[idxStudent].push(itemRes);

            if (!chartData[itemRes.name]) {
              chartData[itemRes.name] = [];
            }
            chartData[itemRes.name].push(itemRes.score);
          });
        }
      });
      chartDataExamResults[i] = chartData;
      dataExamResults[i] = studentDataExamResults;
    }
    tableCols.push({ name: 'total', header: 'Total', align: 'center' });
    tableCols.push({ name: 'result', header: 'Result', align: 'center' });

    const examResultChartSeries = [];
    for (var idx1 = 0; idx1 < examResults.length; idx1++) {
      this.buildExamResultChartSeries(
        academics,
        chartDataExamResults,
        examResultChartSeries,
        idx1
      );
    }

    const tableDatas = [];
    for (var idx2 = 0; idx2 < examResults.length; idx2++) {
      this.buildTableData(
        studentNames,
        academics,
        dataExamResults,
        tableDatas,
        idx2
      );
    }

    this.setState({
      examResults,
      students,
      studentNames,
      academics,
      tableCols,
      tableDatas,
      examResultChartSeries,
      reportClassActivities,
      reportClassActivitiesCols,
      loading: false
    });
  };

  buildExamResultChartSeries(
    academics,
    classExamResults,
    examResultChartSeries,
    examTypeIdx
  ) {
    if (examResultChartSeries[examTypeIdx]) return;

    const chartSeries = [];
    academics.forEach(item => {
      chartSeries.push({
        name: item.name,
        data: classExamResults[examTypeIdx][item.name]
      });
    });
    examResultChartSeries[examTypeIdx] = chartSeries;
  }

  buildTableData(
    studentNames,
    academics,
    dataExamResults,
    tableDatas,
    examTypeIdx
  ) {
    if (tableDatas[examTypeIdx]) return;

    const tableData = [];
    studentNames.forEach((std, stdIdx) => {
      const row = { name: std, total: '', result: '' };
      let sumScore = 0;
      let sumWeight = 0;
      academics.forEach((item, resIdx) => {
        const res = dataExamResults[examTypeIdx][stdIdx][resIdx];
        row[item.name] = res && res?.score > 0 ? res.score : '';
        sumScore += parseFloat(res?.score) ?? 0;
        sumWeight += parseFloat(res?.weight) ?? 0;
      });
      const resTotal = (sumScore / sumWeight) * 100;
      row.total = resTotal > 0 ? resTotal.toFixed(2) : '';
      row.result =
        resTotal > 0 ? ExamResultUtils.calcExamResult(row.total) : '';

      tableData.push(row);
    });
    tableDatas[examTypeIdx] = tableData;
  }

  randomChartSeries() {
    const { students } = this.state;
    const chartSerie = students.map(() => {
      return NumberUtils.getRandomInt(100);
    });

    return chartSerie;
  }

  render() {
    const { loading } = this.state;
    let content = loading ? <Loading /> : this.renderUI();

    return <Fragment>{content}</Fragment>;
  }

  renderUI() {
    const { classes } = this.props;
    const {
      students,
      tableDatas,
      tableCols,
      examResultChartSeries,
      reportClassActivities,
      reportClassActivitiesCols
    } = this.state;

    const chartOptions = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: students
      }
    };

    const scoreChartOptions = {
      ...chartOptions,
      chart: {
        ...chartOptions.chart,
        stacked: true
      },
      stroke: {
        show: false
      },
      colors: ['#f2da29', '#f2a129', '#f25f28', '#6fa1f3']
    };

    const percentChartOptions = {
      ...chartOptions,
      stroke: {
        show: true,
        width: 5,
        colors: ['transparent']
      },
      colors: ['#f2a129', '#6fa1f3']
    };

    const randomChartSeries = [
      [
        { name: 'X', data: this.randomChartSeries() },
        { name: 'Y', data: this.randomChartSeries() }
      ],
      [
        { name: 'X', data: this.randomChartSeries() },
        { name: 'Y', data: this.randomChartSeries() }
      ],
      [
        { name: 'X', data: this.randomChartSeries() },
        { name: 'Y', data: this.randomChartSeries() }
      ],
      [
        { name: 'X', data: this.randomChartSeries() },
        { name: 'Y', data: this.randomChartSeries() }
      ]
    ];

    return (
      <Fragment>
        <Grid container className={classes.stdHeadline}>
          <div className={classes.stdSubtitle}>
            <Typography variant="h3" className={classes.sectionTitle}>
              {
                'Thông tin thống kê & báo cáo của lớp học về kiểm tra hàng tháng'
              }
            </Typography>
          </div>
          <div className={classes.stdSubtitleHr}>
            <span className={clsx(classes.stdLineCell)}></span>
          </div>
        </Grid>

        <Grid container className={classes.stdBox} spacing={2}>
          <Grid item xs={5} container direction="column" justify="flex-start">
            <div className={clsx(classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <ExamResultsView
                  title="Exam results of Class"
                  subtitle="Điểm kiểm tra của lớp"
                  tableDatas={tableDatas}
                  tableCols={tableCols}
                  classes={classes}
                />
              </Grid>
            </div>
            <div className={clsx(classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <ReportClassView
                  title="Report Class Require Activities"
                  subtitle="Báo cáo những hoạt động yêu cầu của lớp trên phần mềm"
                  tableData={reportClassActivities}
                  tableCols={reportClassActivitiesCols}
                  classes={classes}
                />
              </Grid>
            </div>
          </Grid>

          <Grid item xs={7} container direction="column" justify="flex-start">
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <ExamChartView
                  title="Exam Results Chart"
                  subtitle="Biểu đồ kết quả phân loại học sinh"
                  chartOptions={scoreChartOptions}
                  chartSeriesData={examResultChartSeries}
                  numCategories={12}
                  barWidth={70}
                  classes={classes}
                />
              </Grid>
            </div>
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <ExamChartView
                  title="Exam Results Chart"
                  subtitle="Thời gian giảng dạy của các bài"
                  chartOptions={percentChartOptions}
                  chartSeriesData={randomChartSeries}
                  numCategories={9}
                  barWidth={90}
                  classes={classes}
                />
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const stylesExamResultReports = theme => ({
  ...stylesCharting(theme),
  ...stylesAcademicInfo(theme)
});

ExamResultReports.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesExamResultReports)(ExamResultReports);
