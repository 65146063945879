import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import authService from '../api-authorization/AuthorizeService';
import CommonDataTable from '../ui/table/CommonDataTable';
import CommonSearchToolbar from '../ui/table/CommonSearchToolbar';
import { Loading } from '../ui/Loading';
import { TeacherPaths } from './TeacherConstans';

const stylesListTeacher = () => ({});

class ListTeacherComp extends Component {
  static displayName = ListTeacherComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = { teachers: [], loading: true };
  }

  componentDidMount() {
    this.populateTeacherData();
  }

  populateTeacherData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/AspNetUsers/teacher', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ teachers: data, loading: false });
  };

  refreshTeacher = async () => {
    await this.populateTeacherData();
    this.child.setData(this.state.teachers);
  };

  handleChangeSearchTerm = filterText => {
    this.setChildData(filterText);
  };

  setChildData = filterText => {
    const { teachers: data } = this.state;
    const searchTerm = filterText.toLowerCase();
    let filteredRows = data.filter(e => {
      const itemText = (e.fullName + e.email + e.phoneNumber).toLowerCase();
      return itemText.indexOf(searchTerm) > -1;
    });
    this.child.setData(filteredRows);
  };

  render() {
    const cols = [
      { name: 'fullName', header: 'Name', align: 'left' },
      { name: 'englishName', header: 'English Name', align: 'left' },
      { name: 'email', header: 'Email', align: 'left' },
      { name: 'phoneNumber', header: 'Phone', align: 'left' },
      { name: 'elAccount', header: 'EL Account', align: 'left' }
    ];
    const actions = [
      {
        name: 'route',
        tooltip: 'Teacher reports',
        url: `${TeacherPaths.Reports}`,
        icon: 'bar_chart'
      },
      {
        name: 'detail',
        tooltip: 'Report',
        url: `${TeacherPaths.ViewReport}`,
        icon: 'assignment_turned_in'
      },
      {
        name: 'edit',
        tooltip: 'Edit',
        url: `${TeacherPaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'delete',
        tooltip: 'Delete',
        url: `${TeacherPaths.Delete}`,
        icon: 'delete_icon'
      }
    ];

    return this.state.loading ? (
      <Loading />
    ) : (
      <Paper>
        <CommonSearchToolbar handleChange={this.handleChangeSearchTerm} />
        <CommonDataTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.teachers}
          cols={cols}
          actions={actions}
          eventReloadData={this.refreshTeacher}
          isPaging={true}
        />
      </Paper>
    );
  }
}

ListTeacherComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListTeacher = withStyles(stylesListTeacher)(ListTeacherComp);
