import React, { Component } from 'react';

import { Button } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { Keys } from '../../common/constants/GeeOConstants';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { grey } from '@material-ui/core/colors';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';

const useToolbarStyles = theme => ({
  root: {
    padding: theme.spacing(0, 1, 0, 2)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    display: 'flex',
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(grey[500], 0.15),
    '&:hover': {
      backgroundColor: fade(grey[500], 0.25)
    },
    marginLeft: 0,
    marginTop: 6,
    marginRight: theme.spacing(1),
    width: '100%',
    height: 35,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  clearButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    backgroundColor: 'transparent'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200
      }
    }
  },
  noOutline: {
    outline: 'none',
    margin: theme.spacing(1)
  },
  smallLabel: {
    fontSize: '14px'
  },
  toolBar: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
});

const WAIT_INTERVAL = 800;

class MaterialFilterToolbar extends Component {
  constructor(...args) {
    super(...args);
    this.timer = null;
    this.state = {
      open: false,
      anchorEl: null,
      filterText: '',
      clearButtonStyle: { display: 'none' },
      all: true,
      cats: {
        math: false,
        science: false,
        music: false,
        phonics: false,
        sightwords: false,
        english: false,
        softSkills: false,
        activityBook: false,
        studentBook: false,
        teacherPlanner: false,
        m0: false,
        m1: false,
        m2: false,
        m3: false,
        a: false,
        m: false,
        e: false,
        v: false,
        c: false,
        j: false,
        s: false,
        u: false
      }
    };
  }

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleChange = name => event => {
    clearTimeout(this.timer);

    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;
    this.setState(
      {
        [attrName]: attrValue
      },
      this.setTrigger
    );
  };

  handleChangeCats = name => event => {
    clearTimeout(this.timer);

    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;
    this.setState(
      prevState => ({
        all: attrValue
          ? false
          : Object.values(prevState.cats).reduce(
              (acc, cur) => (cur === true ? ++acc : acc),
              0
            ) > 1
          ? false
          : true,
        cats: {
          ...prevState.cats,
          [attrName]: attrValue
        }
      }),
      () => {
        this.setTrigger();
        //this.props.callBackForCategories(this.state.cats);
      }
    );
  };

  handleSelectAll = setAll => {
    clearTimeout(this.timer);

    this.setState(
      {
        all: !setAll,
        cats: {
          math: setAll,
          science: setAll,
          music: setAll,
          phonics: setAll,
          sightwords: setAll,
          english: setAll,
          softSkills: setAll,
          activityBook: setAll,
          studentBook: setAll,
          teacherPlanner: setAll,
          m0: setAll,
          m1: setAll,
          m2: setAll,
          m3: setAll,
          a: setAll,
          m: setAll,
          e: setAll,
          v: setAll,
          c: setAll,
          j: setAll,
          s: setAll,
          u: setAll
        }
      },
      this.setTrigger
    );
  };

  handleKeyDown(e) {
    if (e.keyCode === Keys.ENTER_KEY) {
      this.triggerChange();
    }
  }

  setTrigger() {
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
  }
  triggerChange = () => {
    const { filterText, cats, all } = this.state;
    this.props.setTableData(filterText, cats, all);
  };

  changeClearButtonStyle = clearButtonStyle => {
    this.setState({ clearButtonStyle });
  };

  clearFilterText = () => {
    if (this.state.filterText === '') return;
    clearTimeout(this.timer);
    this.setState({ filterText: '' }, this.setTrigger);
  };

  render() {
    const { classes, isDisableFilter } = this.props;
    const { clearButtonStyle, filterText, cats, open, anchorEl } = this.state;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: false
        })}
      >
        <div className={classes.title} />
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <div
            className={classes.search}
            onMouseEnter={e => {
              this.changeClearButtonStyle({ display: 'block' });
            }}
            onMouseLeave={e => {
              this.changeClearButtonStyle({ display: 'none' });
            }}
          >
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              value={filterText}
              onChange={this.handleChange('filterText')}
              onKeyDown={e => this.handleKeyDown(e)}
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'Search' }}
            />
            <IconButton
              size="small"
              className={classes.clearButton}
              style={clearButtonStyle}
              onClick={this.clearFilterText}
              disableFocusRipple
            >
              <ClearIcon />
            </IconButton>
          </div>
          {!isDisableFilter ? (
            <span>
              <Tooltip title="Category filter">
                <IconButton aria-label="Filter list" onClick={this.handleClick}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={this.handleClose}
                PaperProps={{
                  style: {
                    width: 450,
                    paddingLeft: 20,
                    paddingRight: 20
                  }
                }}
              >
                <Typography variant="button" className={classes.noOutline}>
                  Material Categories
                </Typography>
                <div className={classes.toolBar}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      this.handleSelectAll(true);
                    }}
                  >
                    All
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => {
                      this.handleSelectAll(false);
                    }}
                  >
                    None
                  </Button>
                </div>
                <Grid container className={classes.noOutline}>
                  <Grid item xs={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.math}
                            onChange={this.handleChangeCats('math')}
                            value="math"
                            // icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            // checkedIcon={<CheckBoxIcon fontSize="small" />}
                          />
                        }
                        label={<span className={classes.smallLabel}>Math</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.science}
                            onChange={this.handleChangeCats('science')}
                            value="science"
                          />
                        }
                        label={
                          <span className={classes.smallLabel}>Science</span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.music}
                            onChange={this.handleChangeCats('music')}
                            value="music"
                          />
                        }
                        label={
                          <span className={classes.smallLabel}>Music</span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.phonics}
                            onChange={this.handleChangeCats('phonics')}
                            value="phonics"
                          />
                        }
                        label={
                          <span className={classes.smallLabel}>Phonics</span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.sightwords}
                            onChange={this.handleChangeCats('sightwords')}
                            value="sightwords"
                          />
                        }
                        label={
                          <span className={classes.smallLabel}>Sightwords</span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.english}
                            onChange={this.handleChangeCats('english')}
                            value="english"
                          />
                        }
                        label={
                          <span className={classes.smallLabel}>English</span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.softSkills}
                            onChange={this.handleChangeCats('softSkills')}
                            value="softSkills"
                          />
                        }
                        label={
                          <span className={classes.smallLabel}>
                            Soft skills
                          </span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.activityBook}
                            onChange={this.handleChangeCats('activityBook')}
                            value="activityBook"
                          />
                        }
                        label={
                          <span className={classes.smallLabel}>
                            Activity book
                          </span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.studentBook}
                            onChange={this.handleChangeCats('studentBook')}
                            value="studentBook"
                          />
                        }
                        label={
                          <span className={classes.smallLabel}>
                            Student's book
                          </span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.teacherPlanner}
                            onChange={this.handleChangeCats('teacherPlanner')}
                            value="teacherPlanner"
                          />
                        }
                        label={
                          <span className={classes.smallLabel}>
                            Teacher planner
                          </span>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.m0}
                            onChange={this.handleChangeCats('m0')}
                            value="m0"
                          />
                        }
                        label={<span className={classes.smallLabel}>M0</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.m1}
                            onChange={this.handleChangeCats('m1')}
                            value="m1"
                          />
                        }
                        label={<span className={classes.smallLabel}>M1</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.a}
                            onChange={this.handleChangeCats('a')}
                            value="a"
                          />
                        }
                        label={<span className={classes.smallLabel}>A</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.m}
                            onChange={this.handleChangeCats('m')}
                            value="m"
                          />
                        }
                        label={<span className={classes.smallLabel}>M</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.e}
                            onChange={this.handleChangeCats('e')}
                            value="e"
                          />
                        }
                        label={<span className={classes.smallLabel}>E</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.v}
                            onChange={this.handleChangeCats('v')}
                            value="v"
                          />
                        }
                        label={<span className={classes.smallLabel}>V</span>}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={2}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.m2}
                            onChange={this.handleChangeCats('m2')}
                            value="m2"
                          />
                        }
                        label={<span className={classes.smallLabel}>M2</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.m3}
                            onChange={this.handleChangeCats('m3')}
                            value="m3"
                          />
                        }
                        label={<span className={classes.smallLabel}>M3</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.c}
                            onChange={this.handleChangeCats('c')}
                            value="c"
                          />
                        }
                        label={<span className={classes.smallLabel}>C</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.j}
                            onChange={this.handleChangeCats('j')}
                            value="j"
                          />
                        }
                        label={<span className={classes.smallLabel}>J</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.s}
                            onChange={this.handleChangeCats('s')}
                            value="s"
                          />
                        }
                        label={<span className={classes.smallLabel}>S</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cats.u}
                            onChange={this.handleChangeCats('u')}
                            value="u"
                          />
                        }
                        label={<span className={classes.smallLabel}>U</span>}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Menu>{' '}
            </span>
          ) : (
            ''
          )}
        </div>
      </Toolbar>
    );
  }
}

MaterialFilterToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useToolbarStyles)(MaterialFilterToolbar);
