import React, { Component } from 'react';
import clsx from 'clsx';
import { Paper, Typography } from '@material-ui/core';

export class NumberView extends Component {
  render() {
    const { title, subtitle, numberValue, numberColor, classes } = this.props;

    return (
      <Paper className={clsx(classes.chartBox, classes.chartBoxEqualHeight)}>
        <div className={classes.center}>
          <Typography variant="h3" className={clsx(classes.heading)}>
            {title}
          </Typography>
          <Typography variant="body2" className={clsx(classes.subtitle)}>
            {subtitle}
          </Typography>
        </div>

        <div className={classes.center} style={{ marginTop: 20, height: 110 }}>
          <div style={{ display: 'flex' }}>
            <div className={classes.chartHeadingBox} />
            <div className={classes.chartNumberBox}>
              <Typography
                variant="h1"
                className={clsx(classes.chartLargeNumber, numberColor)}
              >
                {numberValue}
              </Typography>
              <hr
                className={clsx(
                  classes.chartNumberLine,
                  classes.chartLargeNumberLine,
                  numberColor
                )}
              />
            </div>
            <div className={classes.chartHeadingBox} />
          </div>
        </div>
      </Paper>
    );
  }
}
