import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { ListMaterial } from './ListMaterial';
import { EditMaterial } from './EditMaterial';
import MyApprovedList from './MyApprovedList';
import NeedApproval from './NeedApproval';
import { MaterialPaths, MaterialActions } from './MaterialConstants';
import { AdminPage } from '../ui/page/AdminPage';

const stylesMaterials = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class MaterialsComp extends Component {
  static displayName = MaterialsComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      materialId: this.props.match.params.materialId,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case MaterialActions.List:
      case MaterialActions.ListByTeacher:
        this.state.pageTitle =
          this.props.action === MaterialActions.ListByTeacher
            ? 'My Materials'
            : 'Materials';
        this.state.content = <ListMaterial action={this.props.action} />;
        this.state.myApprovedList =
          this.props.action === MaterialActions.ListByTeacher ? (
            <MyApprovedList action={this.props.action} />
          ) : null;
        break;
      case MaterialActions.Create:
      case MaterialActions.CreateByTeacher:
        this.state.pageTitle = 'Create Material';
        this.state.content = (
          <EditMaterial
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
          />
        );
        break;
      case MaterialActions.Edit:
      case MaterialActions.EditByTeacher:
        this.state.pageTitle = 'Edit Material';
        this.state.content = (
          <EditMaterial
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            materialId={this.state.materialId}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  render() {
    const { classes, action } = this.props;
    const actions =
      action === MaterialActions.List ||
      action === MaterialActions.ListByTeacher ? (
        <Fab
          aria-label="Add"
          color="primary"
          className={classes.fab}
          onClick={() =>
            this.redirectTo(
              action === MaterialActions.ListByTeacher
                ? `${MaterialPaths.CreateByTeacher}`
                : `${MaterialPaths.Create}`
            )
          }
        >
          <AddIcon />
        </Fab>
      ) : (
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.handleChildSubmit}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleChildBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      );

    return (
      <Fragment>
        {this.props.action !== MaterialActions.NeedApproval && (
          <AdminPage
            title={this.state.pageTitle}
            content={this.state.content}
            actions={actions}
          />
        )}
        {this.props.action === MaterialActions.ListByTeacher && (
          <AdminPage
            title="Approved List"
            content={this.state.myApprovedList}
            style={{ marginTop: 25 }}
          />
        )}
        {this.props.action === MaterialActions.NeedApproval && <NeedApproval />}
        {this.state.redirect}
      </Fragment>
    );
  }
}

MaterialsComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Materials = withStyles(stylesMaterials)(MaterialsComp);
