import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { red, green } from '@material-ui/core/colors';

const stylesRunStatusBar = theme => ({
  show: {
    display: 'inline-block',
    margin: theme.spacing(1)
  },
  hide: {
    display: 'none'
  },
  statusColorGood: {
    color: green[500]
  },
  statusColorTimeOut: {
    color: red[500]
  },
  statusColorNotStart: {},
  dummy: {
    margin: theme.spacing(1)
  }
});

class RunStatusBar extends Component {
  static displayName = RunStatusBar.name;

  constructor(...args) {
    super(...args);
    this.state = {
      runState: '',
      isShow: false,
      timeInSeconds: Number(this.props.timeInMinutes) * 60,
      timeElapsed: 0
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  updateRunStatus(runState) {
    this.setState({ runState });
  }

  setStatusDisplay(isShow) {
    this.setState({ isShow });
  }

  updateElapsedTime(timeElapsed) {
    this.setState({ timeElapsed, isShow: true });
  }

  render() {
    const { classes } = this.props;
    const { isShow } = this.state;
    let minutes = ~~(this.state.timeElapsed / 60);
    let formattedMinutes = String(minutes).padStart(2, '0');
    let seconds = this.state.timeElapsed % 60;
    let formattedSeconds = String(seconds).padStart(2, '0');
    const timeElapsedDisplay = `${formattedMinutes}:${formattedSeconds}`;
    const statusColor =
      this.state.timeElapsed > this.state.timeInSeconds
        ? classes.statusColorTimeOut
        : classes.statusColorGood;
    const isShowClass = isShow ? classes.show : classes.hide;

    return (
      <div className={isShowClass}>
        <Typography variant="body2" className={statusColor}>
          {timeElapsedDisplay}
        </Typography>
      </div>
    );
  }
}

RunStatusBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesRunStatusBar)(RunStatusBar);
