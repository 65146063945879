import React, { Component } from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Divider,
  Fab,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CalendarToday from '@material-ui/icons/CalendarToday';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import clsx from 'clsx';
import { TeacherLessonPaths } from '../teacher-lesson/TeacherLessonConstants';
import { RouteUtils } from '../common/RouteUtils';
import { stylesDashboard } from './stylesDashboard';
import { NormalTooltip } from '../ui/ButtonStyles';

class UpComingLessons extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.populateMyClassData();
  }

  async populateMyClassData() {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const response = await fetch(
      `api/MyClasses/GetMyUpComingClass/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    let upComingLessons = await response.json();

    this.setState({ upComingLessons, loading: false });
  }

  viewWorksheet = row => {
    let viewUrl = `/api/LessonPlans/GetForView/${row.lessonPlan.id}/${encodeURI(
      row.lessonPlan.worksheetFileName
    )}`;
    window.open(viewUrl, '_blank');
  };

  render() {
    const { classes, parentComponent } = this.props;
    const { upComingLessons, loading } = this.state;
    const cols = [
      { name: 'class', header: 'Class', align: 'left' },
      { name: 'beginDateFormat', header: 'Date', align: 'left' },
      { name: 'startTimeLocal', header: 'Start', align: 'left' },
      { name: 'endTimeLocal', header: 'End', align: 'left' },
      { name: 'lesson', header: 'Lesson', align: 'left' },
      { name: 'subject', header: 'Subject', align: 'left' },
      { name: 'content', header: 'Content', align: 'left' }
    ];

    return (
      <Fragment>
        {loading ? (
          <Loading />
        ) : (
          <Paper>
            <div className={classes.toolbar}>
              <Typography
                className={clsx(
                  classes.boldText,
                  classes.margin,
                  classes.title
                )}
              >
                Up Coming Lessons
              </Typography>
              <Fab
                variant="extended"
                size="small"
                aria-label="Teaching Schedule"
                className={clsx(
                  classes.extendedFab,
                  classes.yellowBtn,
                  classes.boldText
                )}
                style={{
                  height: 32,
                  paddingLeft: 16,
                  paddingRight: 20,
                  marginRight: 8
                }}
                onClick={() =>
                  RouteUtils.nextPath(
                    parentComponent,
                    TeacherLessonPaths.ViewAllClassCalendar
                  )
                }
              >
                <CalendarToday className={classes.extendedIcon} />
                Calendar
              </Fab>
            </div>
            <Divider />
            <TableContainer>
              <Table className={classes.table} aria-label="Let's Learn">
                <TableHead>
                  <TableRow>
                    {cols.map(col => (
                      <TableCell
                        key={col.name}
                        align={col.align}
                        className={clsx(classes.tableCell, classes.boldText)}
                      >
                        {col.header}
                      </TableCell>
                    ))}
                    <TableCell
                      align="center"
                      className={clsx(classes.tableCell, classes.boldText)}
                    >
                      &nbsp;
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {upComingLessons.map((row, idx) => (
                    <TableRow key={idx}>
                      {cols.map(col => (
                        <TableCell
                          key={col.name}
                          align={col.align}
                          className={classes.tableCell}
                        >
                          {row[col.name]}
                        </TableCell>
                      ))}
                      <TableCell align="center" className={classes.tableCell}>
                        {row.lessonPlan.worksheetFileName && (
                          <NormalTooltip title="View/print worksheet">
                            <IconButton
                              size="small"
                              color="secondary"
                              onClick={() => this.viewWorksheet(row)}
                            >
                              <DescriptionOutlinedIcon
                                fontSize="inherit"
                                className={classes.actionIcon}
                              />
                            </IconButton>
                          </NormalTooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Fragment>
    );
  }
}

UpComingLessons.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesDashboard)(UpComingLessons);
