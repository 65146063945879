import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ListLessonAndTeacher } from './ListLessonAndTeacher';
import { LessonAndTeacherActions } from './LessonAndTeacherConstants';
import { AdminPage } from '../../ui/page/AdminPage';

const stylesLessonAndTeacher = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class LessonAndTeacherComp extends Component {
  static displayName = LessonAndTeacherComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      classId: this.props.match.params.classId,
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case LessonAndTeacherActions.List:
        this.state.pageTitle = 'Class: Teachers and Lessons';
        this.state.content = (
          <ListLessonAndTeacher classId={this.state.classId} />
        );
        break;
      case LessonAndTeacherActions.Edit:
        this.state.pageTitle = 'Edit';
        break;
      case undefined:
      default:
    }
  }
  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  render() {
    const actions = null;
    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

LessonAndTeacherComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const LessonAndTeacher = withStyles(stylesLessonAndTeacher)(
  LessonAndTeacherComp
);
