import React from 'react';
import { Component } from 'react';
import { Route } from 'react-router-dom';

export default class AppRoute extends Component {
  render() {
    const { component: Component, layout: Layout, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  }
}
