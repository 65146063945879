import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import CustomizedTable from '../../ui/table/CustomizedTable';
import { Loading } from '../../ui/Loading';
import CommonSearchToolbar from '../../ui/table/CommonSearchToolbar';
import { UserPaths } from './UserConstants';

const stylesListUser = () => ({});

class ListUserComp extends Component {
  static displayName = ListUserComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = { data: [], loading: true };
  }

  componentDidMount() {
    this.populateUsers();
  }

  populateUsers = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/AspNetUsers', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ data: data, loading: false });
  };

  refreshUsers = async () => {
    await this.populateUsers();
    this.child.updateChildData(this.state.data);
  };

  onSearchChanged = filterText => {
    const { data } = this.state;
    let filteredRows = data.filter(item => {
      return (
        (item.email || item.firstName || item.lastName)
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) > -1
      );
    });
    this.child.updateChildData(filteredRows);
  };

  renderDatagrid() {
    const cols = [
      { name: 'role', header: 'Role', align: 'left' },
      { name: 'email', header: 'Email', align: 'left' },
      { name: 'employeeCode', header: 'Employee Code', align: 'left' },
      { name: 'firstName', header: 'First Name', align: 'left' },
      { name: 'lastName', header: 'Last Name', align: 'left' },
      { name: 'phoneNumber', header: 'Phone', align: 'left' }
    ];
    const actions = [
      {
        name: 'edit',
        tooltip: 'Edit User',
        url: `${UserPaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'delete',
        tooltip: 'Delete User',
        url: `${UserPaths.Delete}`,
        icon: 'delete_icon'
      }
    ];

    return (
      <Fragment>
        <CommonSearchToolbar handleChange={this.onSearchChanged} />
        <CustomizedTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.data}
          cols={cols}
          actions={actions}
          eventReloadData={this.refreshUsers}
          isPaging={true}
        />
      </Fragment>
    );
  }

  render() {
    return this.state.loading ? <Loading /> : this.renderDatagrid();
  }
}

ListUserComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListUser = withStyles(stylesListUser)(ListUserComp);
