import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import CustomizedTable from '../../ui/table/CustomizedTable';
import { Loading } from '../../ui/Loading';
import { RolePaths } from './RoleConstants';

const stylesListRole = () => ({});

class ListRoleComp extends Component {
  static displayName = ListRoleComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = { data: [], loading: true };
  }

  componentDidMount() {
    this.populateRoles();
  }

  populateRoles = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/AspNetRoles', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ data: data, loading: false });
  };

  refreshRoles = async () => {
    await this.populateRoles();
    this.child.updateChildData(this.state.data);
  };

  renderDatagrid() {
    const cols = [
      { name: 'name', header: 'name', align: 'left' },
      { name: 'concurrencyStamp', header: 'Concurrency Stamp', align: 'left' }
    ];
    const actions = [
      {
        name: 'edit',
        tooltip: 'Edit Role',
        url: `${RolePaths.Edit}`,
        icon: 'edit_icon'
      }
    ];

    return (
      <CustomizedTable
        onRef={actualChild => (this.child = actualChild)}
        rows={this.state.data}
        cols={cols}
        actions={actions}
        eventReloadData={this.refreshRoles}
        isPaging={true}
      />
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return <Fragment>{contents}</Fragment>;
  }
}

ListRoleComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListRole = withStyles(stylesListRole)(ListRoleComp);
