import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableRow,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import authService from '../../api-authorization/AuthorizeService';
import {
  CenterTableCell,
  CustomTableCell,
  stylesExamResult
} from '../../my-class/views/UIResources';
import { Loading } from '../../ui/Loading';
import StudentInfo from '../../student/info/StudentInfo';
import { examTypes } from '../../academic-manage/views/stylesExamResults';
import starIcon from '../../../images/flat_star_icon.jpg';
import { ExamResultUtils } from '../../common/ExamResultUtils';

const scoreTexts = [
  '',
  'Need to improve (Cần cải thiện)',
  'Fair (Khá)',
  'Fairly-Good (Khá tốt)',
  'Good (Tốt)'
];

class ViewExamResult extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      classInfo: {
        ...this.props.classInfo,
        className: this.props.classInfo.name
      },
      studentInfo: this.props.studentInfo,
      loading: true
    };
  }

  componentDidMount() {
    this.populateExamResult();
  }

  async populateExamResult() {
    const token = await authService.getAccessToken();
    const { classInfo, studentInfo } = this.props;

    const [respExamResult] = await Promise.all([
      fetch(
        `api/AcademicManage/GetExamResults/${studentInfo.subId}/${classInfo.id}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      )
    ]);
    const [examResults] = await Promise.all([respExamResult.json()]);

    let examResultsExpanded = examResults.map((item, idx) => {
      return idx === 0 ? true : false;
    });

    this.setState({ examResults, examResultsExpanded, loading: false });
  }

  calcResult = examResult => {
    examResult.examResultForm = JSON.parse(examResult.examResultJson);
    let sumScore = 0.0;
    let sumWeight = 0.0;
    examResult.examResultForm.academic.forEach(e => {
      sumScore += parseFloat(e.score);
      sumWeight += parseFloat(e.weight);
    });
    const result = (sumScore / sumWeight) * 100;
    examResult.total = result.toFixed(2);
    examResult.final = ExamResultUtils.calcExamResult(examResult.total);

    return examResult;
  };

  handleExpandClick = idx => {
    this.setState(prevState => ({
      examResultsExpanded: {
        ...prevState.examResultsExpanded,
        [idx]: !prevState.examResultsExpanded[idx]
      }
    }));
  };

  drawStars(n) {
    var elements = [];
    for (var i = 0; i < n; i++) {
      elements.push(<img src={starIcon} alt="star" width="24" key={i} />);
    }
    return elements;
  }

  render() {
    const { classes } = this.props;
    const {
      examResults,
      examResultsExpanded,
      classInfo,
      studentInfo,
      loading
    } = this.state;
    const stdClassList = [classInfo];

    return (
      <Fragment>
        <StudentInfo student={studentInfo} stdClassList={stdClassList} />
        {loading ? (
          <Loading />
        ) : (
          examResults.map((examResult, idx) => {
            examResult = this.calcResult(examResult);
            return (
              <Card className={classes.root} key={idx}>
                <CardActions disableSpacing>
                  <Typography variant="h2" className={classes.titleText}>
                    {examTypes[examResult.examType]}
                  </Typography>
                  <div className={classes.floatRightBar}>
                    <Typography variant="h2" className={classes.titleText}>
                      {examResult.final}
                    </Typography>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: examResultsExpanded[idx]
                      })}
                      onClick={() => {
                        this.handleExpandClick(idx);
                      }}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </div>
                </CardActions>
                <Collapse
                  in={examResultsExpanded[idx]}
                  timeout="auto"
                  unmountOnExit
                >
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <CenterTableCell colSpan={2}>
                                <Typography
                                  className={clsx(
                                    classes.headingText,
                                    classes.blueText
                                  )}
                                >
                                  OVERALL PERFORMANCE
                                </Typography>
                                <Typography
                                  className={clsx(
                                    classes.headingText,
                                    classes.blueText
                                  )}
                                >
                                  (Đánh giá chung)
                                </Typography>
                              </CenterTableCell>
                            </TableRow>
                            <TableRow>
                              <CenterTableCell>
                                <Typography className={classes.headingText}>
                                  Skills
                                </Typography>
                                <Typography className={classes.headingText}>
                                  (Các kỹ năng)
                                </Typography>
                              </CenterTableCell>
                              <CenterTableCell>
                                <Typography className={classes.headingText}>
                                  Evaluation
                                </Typography>
                                <Typography className={classes.headingText}>
                                  (Đánh giá)
                                </Typography>
                              </CenterTableCell>
                            </TableRow>
                            {examResult.examResultForm.overall.map(
                              (criteria, idx) => (
                                <TableRow key={idx}>
                                  <CustomTableCell>
                                    <Typography className={classes.italicText}>
                                      {criteria.name}
                                    </Typography>
                                    <Typography className={classes.italicText}>
                                      ({criteria.altName})
                                    </Typography>
                                  </CustomTableCell>
                                  <CenterTableCell>
                                    <Typography className={classes.italicText}>
                                      {scoreTexts[criteria.evaluation]}
                                    </Typography>
                                    {this.drawStars(criteria.evaluation)}
                                  </CenterTableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid item xs={4}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <CenterTableCell colSpan={2}>
                                <Typography
                                  className={clsx(
                                    classes.headingText,
                                    classes.blueText
                                  )}
                                >
                                  ACADEMIC PERFORMANCE
                                </Typography>
                                <Typography
                                  className={clsx(
                                    classes.headingText,
                                    classes.blueText
                                  )}
                                >
                                  (Học thuật)
                                </Typography>
                              </CenterTableCell>
                            </TableRow>
                            <TableRow>
                              <CenterTableCell>
                                <Typography className={classes.headingText}>
                                  Linguistic Aspects
                                </Typography>
                                <Typography className={classes.headingText}>
                                  (Ngôn ngữ học)
                                </Typography>
                              </CenterTableCell>
                              <CenterTableCell>
                                <Typography className={classes.headingText}>
                                  Score
                                </Typography>
                                <Typography className={classes.headingText}>
                                  (Điểm)
                                </Typography>
                              </CenterTableCell>
                            </TableRow>
                            {examResult.examResultForm.academic.map(
                              (criteria, idx) => (
                                <TableRow key={idx}>
                                  <CustomTableCell>
                                    <Typography className={classes.italicText}>
                                      {criteria.name}
                                    </Typography>
                                  </CustomTableCell>
                                  <CenterTableCell width="40%">
                                    <Typography className={classes.headingText}>
                                      {criteria.score} / {criteria.weight}
                                    </Typography>
                                  </CenterTableCell>
                                </TableRow>
                              )
                            )}
                            <TableRow>
                              <CenterTableCell>
                                <Typography className={classes.headingText}>
                                  TOTAL
                                </Typography>
                                <Typography className={classes.headingText}>
                                  (Tổng)
                                </Typography>
                              </CenterTableCell>
                              <CenterTableCell>
                                <Typography className={classes.headingText}>
                                  {examResult.total}%
                                </Typography>
                              </CenterTableCell>
                            </TableRow>
                            <TableRow>
                              <CenterTableCell>
                                <Typography
                                  className={clsx(
                                    classes.headingText,
                                    classes.redText
                                  )}
                                >
                                  RESULT
                                </Typography>
                                <Typography
                                  className={clsx(
                                    classes.headingText,
                                    classes.redText
                                  )}
                                >
                                  (Kết quả)
                                </Typography>
                              </CenterTableCell>
                              <CenterTableCell>
                                <Typography
                                  className={clsx(
                                    classes.headingText,
                                    classes.redText
                                  )}
                                >
                                  {examResult.final}
                                </Typography>
                              </CenterTableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid item xs={4}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <CenterTableCell>
                                <Typography
                                  className={clsx(
                                    classes.headingText,
                                    classes.blueText
                                  )}
                                >
                                  TEACHER'S COMMENT
                                </Typography>
                                <Typography
                                  className={clsx(
                                    classes.headingText,
                                    classes.blueText
                                  )}
                                >
                                  (Nhận xét của giáo viên)
                                </Typography>
                              </CenterTableCell>
                            </TableRow>
                            <TableRow>
                              <CustomTableCell>
                                <Typography
                                  className={clsx(
                                    classes.textAreaBox,
                                    classes.normalText
                                  )}
                                >
                                  {examResult.teacherComment}
                                </Typography>
                              </CustomTableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
            );
          })
        )}
      </Fragment>
    );
  }
}

ViewExamResult.propTypes = {
  classes: PropTypes.object.isRequired
};

const stylesViewExamResult = theme => ({
  ...stylesExamResult(theme),
  root: {
    width: '100%',
    margin: theme.spacing(2, 0, 0, 0)
  },
  floatRightBar: {
    marginLeft: 'auto'
  },
  titleText: {
    margin: theme.spacing(0, 2),
    display: 'inline-block',
    fontSize: 17
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
});

export default withStyles(stylesViewExamResult)(ViewExamResult);
