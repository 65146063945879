import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';

export default function StudentParents(classes, parents) {
  return (
    <Fragment>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.stdHeadline}
      >
        <Grid item xs className={classes.stdSubtitle}>
          <Typography
            variant="h3"
            className={clsx(classes.stdCell, classes.sectionTitle)}
          >
            Parent Information
          </Typography>
        </Grid>
        <Grid item xs>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item xs={12}>
              <span
                className={clsx(classes.stdLineCell, classes.stdMiddleLine)}
              ></span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="flex-start"
        spacing={1}
        className={classes.stdBox}
      >
        <Grid item container spacing={1} xs={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Father Name:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Phone Number:
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={3}>
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>
              {parents[0] && parents[0].name}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>
              {parents[0] && parents[0].phoneNumber}
            </span>
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Email:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Address:
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={5}>
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>
              {parents[0] && parents[0].email}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>
              {parents[0] && parents[0].address}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.stdBoxSeparator}>
        <Grid item xs={10}>
          <span
            className={clsx(classes.stdLineCell, classes.stdMiddleLine)}
          ></span>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="flex-start"
        spacing={1}
        className={classes.stdBox}
      >
        <Grid item container spacing={1} xs={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Mother Name:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Phone Number:
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={3}>
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>
              {parents[1] && parents[1].name}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>
              {parents[1] && parents[1].phoneNumber}
            </span>
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Email:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Address:
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={5}>
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>
              {parents[1] && parents[1].email}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>
              {parents[1] && parents[1].address}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
