import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Search from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';

const stylesUnitSearch = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2, 3)
  },
  commands: {
    margin: theme.spacing(1.5, 1.5, 3)
  },
  formControl: {
    margin: theme.spacing(1, 2),
    width: 300
  }
});

class ActionUnitSearchComp extends Component {
  static displayName = ActionUnitSearchComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      loading: true,
      dataClass: [],
      dataLevel: [],
      dataCampus: [],
      objFilter: {
        levelId: '',
        campusId: '',
        classId: '',
        calendar: null
      }
    };
  }
  componentDidMount() {
    this.populateInitData();
  }

  populateInitData = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);

    const responseDataLevel = await fetch('api/StudyLevels', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const dataLevel = await responseDataLevel.json();
    const responseCampus = await fetch(
      `api/Campus/GetCampusList/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const dataCampus = await responseCampus.json();

    const responseClass = await fetch(
      `api/ClassCourses/GetClassCourseDropDown/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const dataClass = await responseClass.json();

    this.setState({
      loading: false,
      dataLevel: dataLevel,
      dataCampus: dataCampus,
      dataClass: dataClass
    });
  };

  handleChange = name => event => {
    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;
    this.setState(prevState => ({
      objFilter: {
        ...prevState.objFilter,
        [attrName]: attrValue
      }
    }));
    if (name === 'levelId') {
      this.setState(prevState => ({
        objFilter: {
          ...prevState.objFilter,
          classId: ''
        }
      }));
    }
  };

  search = async () => {
    this.props.callbackObjFilter(this.state.objFilter);
  };

  handleDateChange = name => date => {
    const attrName = name;
    const attrValue = date;
    this.setState(prevState => ({
      objFilter: {
        ...prevState.objFilter,
        [attrName]: attrValue
      }
    }));
  };

  render() {
    const { classes } = this.props;
    const { objFilter, dataClass, dataLevel, dataCampus } = this.state;
    const colsFilter = [
      { name: 'levelId', header: 'Level', placeholder: 'Level', adornment: '' },
      {
        name: 'campusId',
        header: 'Campus',
        placeholder: 'Campus',
        adornment: ''
      },
      { name: 'classId', header: 'Class', placeholder: 'Class', adornment: '' }
    ];

    let tempDataClass = [];
    if (objFilter.levelId !== null) {
      tempDataClass = dataClass.filter(e => e.levelId === objFilter.levelId);
    }

    return this.state.loading ? (
      <Loading />
    ) : (
      <Fragment>
        <Paper className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="level-id">
                  {colsFilter[0].header}
                </InputLabel>
                <Select
                  id="level-id"
                  name={colsFilter[0].name}
                  value={objFilter[colsFilter[0].name]}
                  onChange={this.handleChange(colsFilter[0].name)}
                >
                  <MenuItem value="">All</MenuItem>
                  {dataLevel.map(row => {
                    return (
                      <MenuItem value={row.id} key={row.id}>
                        {row.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="campus-id">
                  {colsFilter[1].header}
                </InputLabel>
                <Select
                  id="campus-id"
                  name={colsFilter[1].name}
                  value={objFilter[colsFilter[1].name]}
                  onChange={this.handleChange(colsFilter[1].name)}
                >
                  <MenuItem value="">All</MenuItem>
                  {dataCampus.map(row => {
                    return (
                      <MenuItem value={row.id} key={row.id}>
                        {row.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={3}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="class-id">
                  {colsFilter[2].header}
                </InputLabel>
                <Select
                  id="class-id"
                  name={colsFilter[2].name}
                  value={objFilter[colsFilter[2].name]}
                  onChange={this.handleChange(colsFilter[2].name)}
                >
                  <MenuItem value="">All</MenuItem>
                  {tempDataClass.map(row => {
                    return (
                      <MenuItem value={row.id} key={row.id}>
                        {row.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.formControl}
                  id="date-picker-unitsearch"
                  label="Calendar"
                  format="dd/MM/yyyy"
                  value={objFilter.calendar}
                  onChange={this.handleDateChange('calendar')}
                  KeyboardButtonProps={{
                    'aria-label': 'select date'
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Paper>
        <div className={classes.commands}>
          <Fab
            variant="extended"
            size="medium"
            color="secondary"
            onClick={this.search}
          >
            <Search /> Search
          </Fab>
        </div>
      </Fragment>
    );
  }
}

ActionUnitSearchComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ActionUnitSearch = withStyles(stylesUnitSearch)(
  ActionUnitSearchComp
);
