export const ReportActions = {
  Class: 'class',
  Preview: 'preview',
  Run: 'run',
  Create: 'create',
  Delete: 'delete'
};

const prefix = '/report';

export const ReportPaths = {
  ReportPrefix: prefix,
  Class: `${prefix}/${ReportActions.Class}`,
  Preview: `${prefix}/${ReportActions.Preview}`,
  Run: `${prefix}/${ReportActions.Run}`,
  Create: `${prefix}/${ReportActions.Create}`,
  Delete: `${prefix}/${ReportActions.Delete}`
};
