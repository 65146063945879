export class HtmlUtils {
  static createMarkup(innerHtml) {
    return { __html: innerHtml };
  }

  static newlineToHtml(text) {
    const regexp = /(?:\r\n|\r|\n)/g;
    const br = '<br/>';
    return text ? text.replace(regexp, br) : '';
  }

  static expandFullScreen = e => {
    const elem = e.target;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };
}
