import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import authService from '../../api-authorization/AuthorizeService';
import { Loading } from '../../ui/Loading';
import { stylesCharting } from '../../class-course/reports/stylesCharting';
import { stylesAcademicInfo } from '../../academic-manage/stylesAcademicInfo';
import { TeacherPieChartView } from './TeacherPieChartView';
import { ClassLessonStatistics } from './ClassLessonStatistics';
import { NumberView } from './NumberView';
import { TeacherLessonChart } from './TeacherLessonChart';
import { TeacherChartView } from './TeacherChartView';

class StudentStatistics extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.populateTeacherData();
  }

  populateTeacherData = async () => {
    const { teacherInfo } = this.props;
    const token = await authService.getAccessToken();

    const [
      respTeacherData,
      respStudentResults,
      respClassesStatistics
    ] = await Promise.all([
      fetch(`api/TeacherReport/GetTeacherStatistics/${teacherInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/TeacherReport/GetStudentResults/${teacherInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/TeacherReport/GetClassLessonStatistics/${teacherInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [teacherData, studentResults, classesStatistics] = await Promise.all([
      respTeacherData.json(),
      respStudentResults.json(),
      respClassesStatistics.json()
    ]);

    const renewRatioData = [
      {
        key: 'Renew Ratio',
        data: teacherData.renewStudentsRatio
      },
      {
        key: '',
        data: 100 - teacherData.renewStudentsRatio
      }
    ];

    const chart1Categories = [
      'Thứ 2',
      'Thứ 3',
      'Thứ 4',
      'Thứ 5',
      'Thứ 6',
      'Thứ 7',
      'CN'
    ];
    const chart1SeriesData = Object.values(teacherData.attendingInWeek);

    const chart2Categories = Object.keys(teacherData.renewByMonth);
    const chart2SeriesData = Object.values(teacherData.renewByMonth);

    const classCategories = studentResults.classNames;
    const averageSeriesData = studentResults.averageRatio;
    const fairSeriesData = studentResults.fairRatio;
    const goodSeriesData = studentResults.goodRatio;
    const exellentSeriesData = studentResults.exellentRatio;

    const classesStatisticsCategories = classesStatistics.classNames;
    const classesStatisticsLateSeriesData = classesStatistics.lateRatio;
    const classesStatisticsEarlySeriesData = classesStatistics.earlyRatio;

    this.setState({
      teacherData,
      renewRatioData,
      chart1Categories,
      chart1SeriesData,
      chart2Categories,
      chart2SeriesData,
      classCategories,
      averageSeriesData,
      fairSeriesData,
      goodSeriesData,
      exellentSeriesData,
      classesStatisticsCategories,
      classesStatisticsLateSeriesData,
      classesStatisticsEarlySeriesData,
      loading: false
    });
  };

  render() {
    const { loading } = this.state;
    let content = loading ? <Loading /> : this.renderUI();

    return <Fragment>{content}</Fragment>;
  }

  renderUI() {
    const { teacherClasses, classes } = this.props;
    const {
      teacherData,
      renewRatioData,
      chart1Categories,
      chart1SeriesData,
      chart2Categories,
      chart2SeriesData,
      classCategories,
      averageSeriesData,
      fairSeriesData,
      goodSeriesData,
      exellentSeriesData,
      classesStatisticsCategories,
      classesStatisticsLateSeriesData,
      classesStatisticsEarlySeriesData
    } = this.state;

    const chartOptions = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      }
    };

    const chart1Options = {
      ...chartOptions,
      xaxis: {
        categories: chart1Categories
      },
      stroke: {
        show: false
      },
      colors: ['#6fa1f3']
    };
    const chart2Options = {
      ...chart1Options,
      xaxis: {
        categories: chart2Categories
      }
    };
    const chart1Series = [
      {
        name: 'Students Attending',
        data: chart1SeriesData
      }
    ];
    const chart2Series = [
      {
        name: 'Students Renew',
        data: chart2SeriesData
      }
    ];

    const classResultChartOptions = {
      ...chartOptions,
      chart: {
        ...chartOptions.chart,
        stacked: true
      },
      xaxis: {
        categories: classCategories
      },
      stroke: {
        show: false
      },
      colors: ['#f2da29', '#f2a129', '#f25f28', '#6fa1f3']
    };
    const classResultChartSeries = [
      {
        name: 'HS loại Average',
        data: averageSeriesData
      },
      {
        name: 'HS loại Fair',
        data: fairSeriesData
      },
      {
        name: 'HS loại Good',
        data: goodSeriesData
      },
      {
        name: 'HS loại Exellent',
        data: exellentSeriesData
      }
    ];

    const classesStatisticsChartOptions = {
      ...chartOptions,
      xaxis: {
        categories: classesStatisticsCategories
      },
      stroke: {
        show: true,
        width: 5,
        colors: ['transparent']
      },
      colors: ['#f2a129', '#6fa1f3']
    };
    const classesStatisticsChartSeries = [
      {
        name: '% Buổi dạy trễ tiến độ',
        data: classesStatisticsLateSeriesData
      },
      {
        name: '% Buổi dạy sớm tiến độ',
        data: classesStatisticsEarlySeriesData
      }
    ];

    return (
      <Fragment>
        <Grid container className={classes.stdHeadline}>
          <div className={classes.stdSubtitle}>
            <Typography variant="h3" className={classes.sectionTitle}>
              {'Thông tin thống kê & báo cáo của lớp học về học sinh'}
            </Typography>
          </div>
          <div className={classes.stdSubtitleHr}>
            <span className={clsx(classes.stdLineCell)}></span>
          </div>
        </Grid>

        <Grid container className={classes.stdBox} spacing={2}>
          <Grid item xs={5} container direction="column" justify="flex-start">
            <div style={{ maxWidth: '100%' }}>
              <div className={clsx(classes.chartBoxTopPad)}>
                <Grid container>
                  <Grid item xs={6}>
                    <TeacherPieChartView
                      title="Total NoS"
                      subtitle="Tổng số học sinh"
                      chartTitle="Tỷ lệ phần trăm số học sinh renew"
                      chartData={renewRatioData}
                      numberValue={teacherData.currentStudents}
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.chartBoxLeftPad}>
                    <ClassLessonStatistics
                      title1="Total Classes"
                      subtitle1="Tổng số lớp đã dạy"
                      title2="Total Lessons"
                      subtitle2="Tổng số buổi đã dạy"
                      numberValue1={teacherData.totalClasses}
                      numberValue2={teacherData.totalLessons}
                      numberTitle="Số buổi dạy demo"
                      numberValue={teacherData.demoSessions}
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={clsx(classes.chartBoxTopPad)}>
                <Grid container>
                  <Grid item xs={6}>
                    <NumberView
                      title="Catch-up Sessions"
                      subtitle="Số buổi dạy catch-up"
                      numberValue={teacherData.catchUpSessions}
                      numberColor={classes.chartLabelLightBlue}
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.chartBoxLeftPad}>
                    <NumberView
                      title="Renew Students"
                      subtitle="Số học sinh renew"
                      numberValue={teacherData.renewStudents}
                      numberColor={classes.chartLabelOrange}
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={clsx(classes.chartBoxTopPad)}>
                <Grid container>
                  <Grid item xs={12}>
                    <TeacherLessonChart
                      title="Lesson Time progress"
                      subtitle="Thời gian giảng dạy của giáo viên với từng lớp"
                      classList={teacherClasses}
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

          <Grid item xs={7} container direction="column" justify="flex-start">
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <TeacherChartView
                  title="Number of Students Attending"
                  subtitle="Sĩ số học sinh giáo viên phụ trách"
                  chartOptions={chart1Options}
                  chartSeries={chart1Series}
                  classes={classes}
                />
              </Grid>
            </div>
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <TeacherChartView
                  title="Number of Students Renew"
                  subtitle="Biểu đồ số học sinh renew theo tháng"
                  chartOptions={chart2Options}
                  chartSeries={chart2Series}
                  classes={classes}
                />
              </Grid>
            </div>
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <TeacherChartView
                  title="Percentage Results of Student with Class is over"
                  subtitle="Biểu đồ phân loại học sinh của lớp đã kết thúc của giáo viên"
                  chartOptions={classResultChartOptions}
                  chartSeries={classResultChartSeries}
                  classes={classes}
                />
              </Grid>
            </div>
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <TeacherChartView
                  title="Percentage Lesson Progress Late/Early in Let's Learn"
                  subtitle="Biểu đồ tỷ lệ hiệu suất giảng dạy theo tiến độ bài giảng trong Let's Learn"
                  chartOptions={classesStatisticsChartOptions}
                  chartSeries={classesStatisticsChartSeries}
                  classes={classes}
                />
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const stylesLessonStatistics = theme => ({
  ...stylesCharting(theme),
  ...stylesAcademicInfo(theme)
});

StudentStatistics.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesLessonStatistics)(StudentStatistics);
