import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import authService from '../../../api-authorization/AuthorizeService';
import { Loading } from '../../../ui/Loading';
import { stylesCharting } from '../stylesCharting';
import { stylesAcademicInfo } from '../../../academic-manage/stylesAcademicInfo';
import { LessonSquareChartView } from './LessonSquareChartView';
import { LessonChartView } from './LessonChartView';

class LessonStatistics extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.populateStudentData();
  }

  populateStudentData = async () => {
    const { classInfo } = this.props;
    const token = await authService.getAccessToken();
    const [respClassLessonStatistics] = await Promise.all([
      fetch(`api/LessonReport/GetClassLessonStatistics/${classInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [lessonStatistics] = await Promise.all([
      respClassLessonStatistics.json()
    ]);

    const lessonStandardTimes = [];
    const lessonStandardTimesLow15m = [];
    const lessonStandardTimesHight15m = [];
    const lessonDurations = [];
    const lessonDates = [];
    lessonStatistics.lessonTimeData.forEach(element => {
      lessonDates.push(element.lessonDateText);
      lessonDurations.push(element.lessonDuration);
      lessonStandardTimes.push(element.standardLessonTime);
      lessonStandardTimesLow15m.push(element.standardLessonTime - 15);
      lessonStandardTimesHight15m.push(element.standardLessonTime + 15);
    });

    this.setState({
      lessonStatistics,
      lessonDates,
      lessonDurations,
      lessonStandardTimes,
      lessonStandardTimesLow15m,
      lessonStandardTimesHight15m,
      loading: false
    });
  };

  render() {
    const { loading } = this.state;
    let content = loading ? <Loading /> : this.renderUI();

    return <Fragment>{content}</Fragment>;
  }

  renderUI() {
    const { classes } = this.props;
    const {
      lessonStatistics,
      lessonDates,
      lessonDurations,
      lessonStandardTimes,
      lessonStandardTimesLow15m,
      lessonStandardTimesHight15m
    } = this.state;

    let dataLateRatio = [
      {
        key: 'Late Ratio',
        data: lessonStatistics.lateRatio
      },
      {
        key: '',
        data: 100 - lessonStatistics.lateRatio
      }
    ];
    let dataEarlyRatio = [
      {
        key: 'Early Ratio',
        data: lessonStatistics.earlyRatio
      },
      {
        key: '',
        data: 100 - lessonStatistics.earlyRatio
      }
    ];
    const chartOptions = {
      chart: {
        id: 'lesson-time-step',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      xaxis: {
        categories: lessonDates
      },
      yaxis: {
        //range: 40,
        //min: 60,
        //forceNiceScale: true
      },
      stroke: {
        width: 2,
        curve: 'stepline'
      },
      colors: ['#ea0f0f', '#f2da29', '#e05a5a', '#c0c0c0']
      // responsive: [
      //   {
      //     breakpoint: 1000,
      //     options: {}
      //   }
      // ]
    };
    const chartSeries = [
      {
        name: 'Lesson Standard Time +15m',
        data: lessonStandardTimesHight15m
      },
      {
        name: 'Lesson Standard Time',
        data: lessonStandardTimes
      },
      {
        name: 'Lesson Standard Time -15m',
        data: lessonStandardTimesLow15m
      },
      {
        name: 'Lesson Duration',
        data: lessonDurations
      }
    ];

    return (
      <Fragment>
        <Grid container className={classes.stdHeadline}>
          <div className={classes.stdSubtitle}>
            <Typography variant="h3" className={classes.sectionTitle}>
              {'Thông tin thống kê & báo cáo của lớp học về Lesson Plan'}
            </Typography>
          </div>
          <div className={classes.stdSubtitleHr}>
            <span className={clsx(classes.stdLineCell)}></span>
          </div>
        </Grid>

        <Grid container className={classes.stdBox} spacing={2}>
          <Grid item xs={5} container direction="column" justify="flex-start">
            <Grid
              container
              className={clsx(
                classes.chartBoxTopPad,
                classes.chartBoxEqualHeight
              )}
            >
              <Grid item xs={6}>
                <LessonSquareChartView
                  title="NoL Late"
                  subtitle="Số bài học bị trễ tiến độ"
                  chartTitle="Tỷ lệ phần trăm số bài học dạy trễ tiến độ"
                  chartData={dataLateRatio}
                  numberValue={lessonStatistics.numberOfLate}
                  classes={classes}
                />
              </Grid>
              <Grid item xs={6} className={classes.chartBoxLeftPad}>
                <LessonSquareChartView
                  title="NoL Early"
                  subtitle="Số bài học sớm tiến độ"
                  chartTitle="Tỷ lệ phần trăm số bài học dạy sớm tiến độ"
                  chartData={dataEarlyRatio}
                  numberValue={lessonStatistics.numberOfEarly}
                  classes={classes}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={7} container direction="column" justify="flex-start">
            <Grid item xs={12}>
              <div
                className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}
              >
                <LessonChartView
                  title="Lesson Time progress"
                  subtitle="Thời gian giảng dạy của các bài"
                  chartOptions={chartOptions}
                  chartSeries={chartSeries}
                  classes={classes}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const stylesLessonStatistics = theme => ({
  ...stylesCharting(theme),
  ...stylesAcademicInfo(theme)
});

LessonStatistics.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesLessonStatistics)(LessonStatistics);
