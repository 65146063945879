export const TaskActions = {
  List: 'list',
  ListAll: 'list-all',
  Edit: 'edit',
  Create: 'create',
  AcadManagerCreate: 'create-assign',
  AcadManagerEdit: 'edit-assign',
  Delete: 'delete'
};

const prefix = '/task';

export const TaskPaths = {
  TaskPrefix: prefix,
  List: `${prefix}/${TaskActions.List}`,
  ListAll: `${prefix}/${TaskActions.ListAll}`,
  EditRoute: `${prefix}/${TaskActions.Edit}/:taskId`,
  Edit: `${prefix}/${TaskActions.Edit}`,
  Create: `${prefix}/${TaskActions.Create}`,
  Delete: `TaskSys`,
  EditAssignRoute: `${prefix}/${TaskActions.AcadManagerEdit}/:taskId`,
  EditAssign: `${prefix}/${TaskActions.AcadManagerEdit}`,
  CreateAssign: `${prefix}/${TaskActions.AcadManagerCreate}`
};
