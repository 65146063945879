import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { ListClassData } from './ListClassData';
import { EditClassData } from './EditClassData';
import { ClassDataActions } from './ClassDataConstants';
import { AdminPage } from '../../ui/page/AdminPage';
import ListClassSuspend from './ListClassSuspend';

const stylesClassData = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class ClassDataComp extends Component {
  static displayName = ClassDataComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.mainList = React.createRef();
    this.state = {
      classId: this.props.match.params.classId,
      studentInfoId: this.props.match.params.studentInfoId,
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case ClassDataActions.List:
        this.state.pageTitle = 'Class Student List';
        this.state.content = (
          <ListClassData
            classId={this.state.classId}
            onRef={actualChild => (this.mainList = actualChild)}
          />
        );
        break;
      case ClassDataActions.ListSuspended:
        this.state.pageTitle = 'Suspended/Terminated Student List';
        this.state.content = (
          <ListClassSuspend
            classId={this.state.classId}
            onRef={actualChild => (this.mainList = actualChild)}
          />
        );
        break;
      case ClassDataActions.Create:
        this.state.pageTitle = 'Create Student Info';
        this.state.content = (
          <EditClassData
            onRef={actualChild => (this.child = actualChild)}
            action={this.state.action}
            classId={this.state.classId}
          />
        );
        break;
      case ClassDataActions.Edit:
        this.state.pageTitle = 'Edit Student Info';
        this.state.content = (
          <EditClassData
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            studentInfoId={this.state.studentInfoId}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleAddStudent = () => {
    this.mainList.addStudent();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  handleBackToParentList = () => {
    this.mainList.backToList();
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === 'list' ? (
        <Fragment>
          <Fab
            aria-label="Add"
            color="primary"
            className={classes.fab}
            onClick={this.handleAddStudent}
          >
            <AddIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleBackToParentList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      ) : this.props.action === 'list-suspended' ? (
        <Fragment>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleBackToParentList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      ) : (
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.handleChildSubmit}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleChildBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      );

    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

ClassDataComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ClassData = withStyles(stylesClassData)(ClassDataComp);
