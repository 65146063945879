import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { NormalTooltip } from '../ui/ButtonStyles';
import {
  Grid,
  List,
  ListItem,
  IconButton,
  Paper,
  Typography,
  Button,
  AppBar,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import './style.css';

import { Dialogs, DialogType } from '../ui/popup/Dialogs';
import { StudentRatingStar } from './StudentRatingStar';
import { StudentAssessment } from './StudentAssessment';
// import { StudentPresenceDialog } from './StudentPresenceDialog';
import { StudentPresenceBox } from './StudentPresenceBox';

const stylesAssessmentRoom = theme => ({
  paper: {
    color: '#707070',
    width: '100%',
    maxWidth: 1400,
    minHeight: '100vh',
    margin: 'auto',
    flexDirection: 'column',
    display: 'flex'
  },
  root: {
    flexGrow: 0,
    flexWrap: 'unset'
  },
  contentCol: {
    width: '100%',
    flexGrow: 0
  },
  presencePage: {
    // height: 'calc(100vh - 54px)',
    flexWrap: 'unset'
  },
  headerBar: {
    minHeight: 54,
    height: 54
  },
  bottomBar: {
    top: 'auto',
    bottom: 0
  },
  grow: {
    flexGrow: 1
  },
  navCol: {
    height: '100%',
    borderBottom: '1px #707070 solid',
    padding: theme.spacing(0.25)
  },
  box: {
    borderBottom: '2px #707070 solid',
    '&:last-child': {
      marginBottom: theme.spacing(4)
    }
  },
  heading: {
    fontSize: 30,
    letterSpacing: 0,
    textAlign: 'center',
    margin: theme.spacing(4, 0, 2.5, 0)
  },
  headingBold: {
    fontWeight: 800
  },
  subTitle: {
    fontSize: 16,
    letterSpacing: 0,
    textAlign: 'center',
    margin: theme.spacing(0, 0, 3, 0)
  },
  subTitleBold: {
    fontWeight: 'bold'
  },
  classList: {
    padding: theme.spacing(0)
  },
  classText: {
    textAlign: 'center',
    width: '100%'
  },
  classLabel: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  classTitle: {
    fontSize: 24,
    marginTop: theme.spacing(1.5)
  },
  classDetail: {
    margin: theme.spacing(1.5, 0)
  },
  classInfo: {
    marginLeft: theme.spacing(4),
    '&:first-child': {
      marginLeft: 0
    }
  },
  label: {
    fontSize: 14,
    color: '#707070',
    padding: theme.spacing(1)
  },
  labelBold: {
    fontWeight: 'bold'
  },
  boxClassInfo: {
    margin: theme.spacing(4, 0),
    textAlign: 'right'
  },
  button: {
    margin: theme.spacing(0, 4),
    borderRadius: theme.spacing(2.25),
    minWidth: 100
  }
});

class AssessmentRoomComp extends Component {
  static displayName = AssessmentRoomComp.name;
  constructor(...args) {
    super(...args);
    this.confirmDialog = React.createRef();
    this.studentRatingStar = React.createRef();
    this.studentAssessment = React.createRef();
    this.studentPresenceBoxRef = React.createRef();
    this.studentPresenceDialog = React.createRef();
    this.timerRefreshPresence = null;
    this.state = {
      roomId: this.props.match.params.roomId,
      currentClassId: null,
      currentClass: [],
      isListingClass: true,
      classList: [],
      openDialog: false,
      loading: true,
      confirmDialogActions: (
        <Fragment>
          <Button
            onClick={this.saveAllStudentAssessments}
            variant="contained"
            color="secondary"
            className={this.props.classes.button}
          >
            Yes
          </Button>
          <Button
            onClick={this.closeConfirmDialog}
            variant="contained"
            color="primary"
            className={this.props.classes.button}
          >
            No
          </Button>
        </Fragment>
      ),
      confirmDialog: (
        <Dialogs onRef={actualChild => (this.confirmDialog = actualChild)} />
      )
    };
  }

  componentDidMount() {
    this.populateClassStudentsInRoom();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  startTimer = () => {
    if (!this.timerRefreshPresence) {
      this.timerRefreshPresence = setInterval(this.refreshPresence, 10000);
    }
  };

  stopTimer = () => {
    if (this.timerRefreshPresence) {
      clearInterval(this.timerRefreshPresence);
      this.timerRefreshPresence = null;
    }
  };

  refreshPresence = async () => {
    const { isSaving, currentClassId } = this.state;
    if (isSaving) return;

    const token = await authService.getAccessToken();
    const [respClassStudents] = await Promise.all([
      fetch(`api/ClassStudents/GetStudentPresenceByClass/${currentClassId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [currentClassStudents] = await Promise.all([
      respClassStudents.json()
    ]);

    this.setState(
      {
        currentClassStudents,
        isListingClass: false,
        loading: false
      },
      this.updateChilds(currentClassStudents)
    );
  };

  changeStudentPresence = studentPresence => {
    this.setState({ studentPresence });
  };
  openStudentPresenceDialog = async () => {
    await this.getStudentByClass();
    this.setState({ openDialog: true });
  };
  closeStudentPresenceDialog = () => {
    this.setState({ studentPresenceBox: null, openDialog: false });
  };

  showConfirmDialog = () => {
    this.confirmDialog.showDialogWithCmds(
      DialogType.SaveScoreBoard,
      this.state.confirmDialogActions
    );
  };
  closeConfirmDialog = () => {
    this.confirmDialog.closeDialog();
    // window.close();
  };

  updateChilds = currentClassStudents => {
    //this.studentRatingStar.populateStudentRating();
    this.studentAssessment.udpateStudentRating(currentClassStudents);
  };

  populateClassStudentsInRoom = async () => {
    const token = await authService.getAccessToken();
    const [respRoom, respClassList, respAssess] = await Promise.all([
      fetch(`api/ClassRooms/${this.state.roomId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/ClassRooms/ClassStudentsInRoom/${this.state.roomId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/StudentAssessment/GetAssessmentCriteria`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [classRoom, classList, studentAssessments] = await Promise.all([
      respRoom.json(),
      respClassList.json(),
      respAssess.json()
    ]);

    this.setState({ classRoom, classList, studentAssessments });
    if (classList.length === 1) {
      this.getClassStudentList(classList[0].classId, classList[0]);
    } else {
      this.setState({ loading: false });
    }
  };

  getStudentByClass = async () => {
    const { currentClassId, currentClass } = this.state;
    const token = await authService.getAccessToken();
    const headers = !token ? {} : { Authorization: `Bearer ${token}` };
    const [respRatingStar, respClassStudentPresence] = await Promise.all([
      fetch(
        `api/TeacherLessonLog/GetStudentLogDataOfClass/${currentClass.classLessonId}`,
        {
          headers: headers
        }
      ),
      fetch(`api/ClassStudents/GetStudentByClass/${currentClassId}`, {
        headers: headers
      })
    ]);
    const [currentRatingStar, currentClassStudentPresence] = await Promise.all([
      respRatingStar.json(),
      respClassStudentPresence.json()
    ]);
    this.setState({ currentRatingStar, currentClassStudentPresence });
  };

  getClassStudentList = async (currentClassId, currentClass) => {
    const token = await authService.getAccessToken();
    const headers = !token ? {} : { Authorization: `Bearer ${token}` };
    const [
      respClassStudents,
      respRatingStar,
      respStudentAssessment,
      respClassStudentPresence
    ] = await Promise.all([
      fetch(`api/ClassStudents/GetStudentPresenceByClass/${currentClassId}`, {
        headers: headers
      }),
      fetch(
        `api/TeacherLessonLog/GetStudentLogDataOfClass/${currentClass.classLessonId}`,
        {
          headers: headers
        }
      ),
      fetch(
        `api/StudentAssessment/GetStudentAssessmentOfClass/${currentClass.classLessonId}`,
        {
          headers: headers
        }
      ),
      fetch(`api/ClassStudents/GetStudentByClass/${currentClassId}`, {
        headers: headers
      })
    ]);
    const [
      currentClassStudents,
      currentRatingStar,
      currentStudentAssessment,
      currentClassStudentPresence
    ] = await Promise.all([
      respClassStudents.json(),
      respRatingStar.json(),
      respStudentAssessment.json(),
      respClassStudentPresence.json()
    ]);

    this.setState(
      {
        currentClassId,
        currentClass,
        currentClassStudents,
        currentRatingStar,
        currentStudentAssessment,
        currentClassStudentPresence,
        isListingClass: false,
        loading: false
      },
      this.startTimer
    );
  };

  showClassAssessment = (currentClassId, currentClass) => {
    this.setState({ loading: true });
    this.getClassStudentList(currentClassId, currentClass);
  };

  showClassList = () => {
    this.stopTimer();
    this.setState({ isListingClass: true });
  };

  slideNext = () => {
    this.state.swiper.slideNext();
  };
  slidePrev = () => {
    this.state.swiper.slidePrev();
  };

  saveLessonLog = async (classLessonId, headers) => {
    const lessonLogNew = {
      classLessonId: classLessonId,
      logDateTime: new Date()
    };
    const response = await fetch(`api/TeacherLessonLog/AddTodayLessonLog`, {
      method: 'POST',
      body: JSON.stringify(lessonLogNew),
      headers: headers
    });
    return response.ok ? await response.json() : null;
  };

  addRatingStar = async (studentId, logId, headers) => {
    const studentLessonLogNew = {
      logId: logId,
      studentInfoId: studentId
    };
    const response = await fetch(
      `api/TeacherLessonLog/AddTodayStudentLessonLog`,
      {
        method: 'POST',
        body: JSON.stringify(studentLessonLogNew),
        headers: headers
      }
    );
    return response.ok ? await response.json() : null;
  };

  saveRatingStar = async (studentId, logId, ratingStarMap, headers) => {
    let studentLessonLog = await this.addRatingStar(studentId, logId, headers);
    studentLessonLog.starScore = ratingStarMap[studentId];

    fetch(`api/TeacherLessonLog/SaveStudentLessonLog/${studentLessonLog.id}`, {
      method: 'PUT',
      body: JSON.stringify(studentLessonLog),
      headers: headers
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });

    return studentLessonLog;
  };

  addStudentAssessment = async (studentLessonLogId, criteriaId, headers) => {
    const studentAssessmentNew = {
      studentLessonLogId,
      assessmentCriteriaId: criteriaId
    };
    const response = await fetch(`api/StudentAssessment/AddStudentAssessment`, {
      method: 'POST',
      body: JSON.stringify(studentAssessmentNew),
      headers: headers
    });
    return response.ok ? await response.json() : null;
  };

  saveStudentAssessment = async (
    studentId,
    studentLessonLogId,
    criteriaId,
    criteriaNumber,
    studentAssessmentMap,
    headers
  ) => {
    let studentAssessment = await this.addStudentAssessment(
      studentLessonLogId,
      criteriaId,
      headers
    );
    studentAssessment.score = studentAssessmentMap[studentId]
      ? studentAssessmentMap[studentId][criteriaNumber]
      : 0;

    fetch(
      `api/StudentAssessment/SaveStudentAssessment/${studentAssessment.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(studentAssessment),
        headers: headers
      }
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  saveAllStudentAssessments = async () => {
    this.setState({ isSaving: true });
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    const {
      currentClass,
      currentClassStudents,
      studentAssessments
    } = this.state;

    const lessonLog = await this.saveLessonLog(
      currentClass.classLessonId,
      headers
    );

    const ratingStarMap = this.studentRatingStar.getRatingMap();
    const studentAssessmentMap = this.studentAssessment.getRatingMap();

    currentClassStudents.forEach(student => {
      (async () => {
        const studentLessonLog = await this.saveRatingStar(
          student.id,
          lessonLog.id,
          ratingStarMap,
          headers
        );
        studentAssessments.forEach(criteria => {
          this.saveStudentAssessment(
            student.id,
            studentLessonLog.id,
            criteria.id,
            criteria.criteriaNumber,
            studentAssessmentMap,
            headers
          );
        });
      })();
    });
    this.setState({ isSaving: false });
    this.closeConfirmDialog();
  };

  saveClassStudentPresence = async () => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    const { currentClass, currentClassStudentPresence } = this.state;

    try {
      const lessonLog = await this.saveLessonLog(
        currentClass.classLessonId,
        headers
      );

      await Promise.all(
        currentClassStudentPresence.map(async student => {
          await this.saveStudentPresence(student.subId, lessonLog.id, headers);
        })
      );

      this.stopTimer();
      this.refreshPresence();
      this.closeStudentPresenceDialog();
      this.startTimer();
    } catch (error) {
      console.error(error);
    }
  };

  saveStudentPresence = async (studentId, logId, headers) => {
    const { studentPresence } = this.state;
    const studentPresenceData = studentPresence[studentId];

    let studentLessonLog = await this.addRatingStar(studentId, logId, headers);
    studentLessonLog.present = studentPresenceData.present;
    studentLessonLog.note = studentPresenceData.note;

    fetch(`api/TeacherLessonLog/SaveStudentLessonLog/${studentLessonLog.id}`, {
      method: 'PUT',
      body: JSON.stringify(studentLessonLog),
      headers: headers
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  render() {
    const { classes } = this.props;
    const {
      currentClass,
      currentClassStudentPresence,
      currentClassStudents,
      currentRatingStar,
      currentStudentAssessment,
      studentAssessments,
      classRoom,
      classList,
      isListingClass,
      loading
    } = this.state;
    const d = new Date();

    let content;

    if (loading) {
      content = <Loading />;
    } else {
      content = (
        <Paper className={classes.paper}>
          {!isListingClass && (
            <AppBar position="sticky" color="inherit">
              <Toolbar className={classes.headerBar}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="back-to-list"
                  onClick={this.showClassList}
                >
                  <svg width="24px" height="24px" viewBox="0 0 24 24">
                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
                  </svg>
                </IconButton>
                <div className={classes.grow} />
                <NormalTooltip title={'Update student presence'}>
                  <IconButton
                    aria-label="more actions"
                    edge="end"
                    color="inherit"
                    onClick={this.openStudentPresenceDialog}
                    disabled={currentClassStudents.length === 0}
                  >
                    <ListAltIcon />
                  </IconButton>
                </NormalTooltip>
              </Toolbar>
            </AppBar>
          )}
          {isListingClass ? (
            <Grid item xs container direction="column" className={classes.root}>
              <Grid item xs className={classes.box}>
                <Typography></Typography>
                <Typography gutterBottom className={classes.heading}>
                  Select Class of Room{' '}
                  <span className={classes.headingBold}>
                    {classRoom.name + ' ' + classRoom.campus.name}
                  </span>
                </Typography>
                <Typography gutterBottom className={classes.subTitle}>
                  <span className={classes.subTitleBold}>Date:</span>{' '}
                  {' ' +
                    d
                      .getDate()
                      .toString()
                      .padStart(2, '0') +
                    '/' +
                    (d.getMonth() + 1).toString().padStart(2, '0') +
                    '/' +
                    d
                      .getFullYear()
                      .toString()
                      .padStart(2, '0')}
                </Typography>
                <Typography></Typography>
              </Grid>
              <Grid item>
                <List component="nav" className={classes.classList}>
                  {classList.map(cls => (
                    <ListItem
                      button
                      key={cls.classId}
                      onClick={() => this.showClassAssessment(cls.classId, cls)}
                      className={classes.box}
                    >
                      <div className={classes.classText}>
                        <Typography></Typography>
                        <Typography className={classes.classLabel}>
                          Class
                        </Typography>
                        <Typography className={classes.classTitle}>
                          {cls.className}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.classDetail}
                        >
                          <span className={classes.classInfo}>
                            {'Time: ' + cls.startTime + ' - ' + cls.endTime}
                          </span>
                          <span className={classes.classInfo}>
                            {'Number of students: ' + cls.studentCount}
                          </span>
                          <span className={classes.classInfo}>
                            {'Demo: ' + cls.demoStudent}
                          </span>
                        </Typography>
                        <Typography></Typography>
                      </div>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs
              container
              direction="column"
              className={classes.presencePage}
            >
              <Grid item xs={12}>
                <Swiper
                  // onSlideChange={() => console.log('slide change')}
                  onSwiper={swiper => this.setState({ swiper })}
                >
                  <SwiperSlide>
                    <StudentRatingStar
                      onRef={actualChild =>
                        (this.studentRatingStar = actualChild)
                      }
                      currentClass={currentClass}
                      currentClassStudents={currentClassStudents}
                      currentRatingStar={currentRatingStar}
                      toAssessmentStudent={this.slideNext}
                      saveStudentAssessment={this.saveAllStudentAssessments}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <StudentAssessment
                      onRef={actualChild =>
                        (this.studentAssessment = actualChild)
                      }
                      currentClass={currentClass}
                      currentClassStudents={currentClassStudents}
                      currentStudentAssessment={currentStudentAssessment}
                      studentAssessments={studentAssessments}
                      toRatingStarStudent={this.slidePrev}
                      saveStudentAssessment={this.saveAllStudentAssessments}
                    />
                  </SwiperSlide>
                </Swiper>
              </Grid>
            </Grid>
          )}
          {!isListingClass && (
            <AppBar
              position="sticky"
              color="inherit"
              className={classes.bottomBar}
            >
              <Grid item xs container alignItems="stretch">
                <Grid item xs={2}>
                  <div className={classes.boxClassInfo}>
                    <Typography gutterBottom className={classes.label}>
                      <span className={classes.labelBold}>Class:</span>
                      {' ' + currentClass.className}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.boxClassInfo}>
                    <Typography gutterBottom className={classes.label}>
                      <span className={classes.labelBold}>Time:</span>
                      {' ' +
                        currentClass.startTime +
                        ' - ' +
                        currentClass.endTime}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div className={classes.boxClassInfo}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() => this.showConfirmDialog()}
                    >
                      {'Submit & Close'}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </AppBar>
          )}
        </Paper>
      );
    }

    return (
      <Fragment>
        {content}
        {this.state.confirmDialog}
        <Dialog
          open={this.state.openDialog}
          onClose={this.closeStudentPresenceDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>{`Update Student Presence - ${currentClass.className} (${currentClass.startTime} - ${currentClass.endTime})`}</DialogTitle>
          <DialogContent>
            <StudentPresenceBox
              currentClassStudents={currentClassStudentPresence}
              currentRatingStar={currentRatingStar}
              changeStudentPresence={this.changeStudentPresence}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeStudentPresenceDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={this.saveClassStudentPresence} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

AssessmentRoomComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const AssessmentRoom = withStyles(stylesAssessmentRoom)(
  AssessmentRoomComp
);
