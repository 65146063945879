import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import authService from '../../../../api-authorization/AuthorizeService';
import { Loading } from '../../../../ui/Loading';
import { stylesCharting } from '../../../../class-course/reports/stylesCharting';
import { stylesAcademicInfo } from '../../../../academic-manage/stylesAcademicInfo';
import { InfoBoxView } from './InfoBoxView';
import { ClassChartView } from './ClassChartView';
import { LevelClassChartView } from './LevelClassChartView';
import { StudentsChartView } from './StudentsChartView';
import { NumberUtils } from '../../../../common/NumberUtils';

class AcadStatistics extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.populateCampusData();
  }

  populateCampusData = async () => {
    const { campusInfo } = this.props;
    const token = await authService.getAccessToken();
    const [
      respCampusStudentData,
      respCampusClassData,
      respCampusLevelData,
      respLevelClassStatistic,
      respStudentsStatistic
    ] = await Promise.all([
      fetch(`api/CampusReport/GetCampusStudentStatistic/${campusInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/CampusReport/GetCampusClassStatistic/${campusInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/CampusReport/GetCampusLevelStatistic/${campusInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/CampusReport/GetLevelClassStatistic/${campusInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/CampusReport/GetStudentsStatistic/${campusInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [
      campusStudentData,
      campusClassData,
      campusLevelData,
      levelClassStatistic,
      studentsStatistic
    ] = await Promise.all([
      respCampusStudentData.json(),
      respCampusClassData.json(),
      respCampusLevelData.json(),
      respLevelClassStatistic.json(),
      respStudentsStatistic.json()
    ]);

    const {
      studentStatistics,
      classStatistics,
      levelStatistics,
      classStudentsNummber
    } = this.prepareData(campusStudentData, campusClassData, campusLevelData);

    const monthCategory = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
      return `Tháng ${month}`;
    });
    const regularSeries = [];
    const demoSeries = [];
    const demoToRegularSeries = [];
    let totalSuspendStds = 0;
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach(month => {
      totalSuspendStds +=
        studentsStatistic.suspendData[month] +
        studentsStatistic.terminateData[month];
      regularSeries.push(
        campusStudentData.totalStudents -
          campusStudentData.totalDemoStudents -
          totalSuspendStds
      );
      demoSeries.push(campusStudentData.totalDemoStudents);
      demoToRegularSeries.push(
        NumberUtils.getRandomInt(campusStudentData.totalRegularStudents)
      );
    });

    this.setState({
      campusStudentData,
      studentStatistics,
      campusClassData,
      classStatistics,
      campusLevelData,
      levelStatistics,
      classStudentsNummber,
      monthCategory,
      levelClassStatistic,
      studentsStatistic,
      regularSeries,
      demoSeries,
      demoToRegularSeries,
      loading: false
    });
  };

  prepareData(campusStudentData, campusClassData, campusLevelData) {
    const studentStatistics = [
      {
        title: 'Tổng số học sinh chính thức',
        number: campusStudentData.totalRegularStudents
      },
      {
        title: 'Tổng số học sinh demo',
        number: campusStudentData.totalDemoStudents
      },
      {
        title: 'Tổng số học sinh suspend',
        number: campusStudentData.totalSuspendStudents
      },
      {
        title: 'Tổng số học sinh đã nghỉ',
        number: campusStudentData.totalTerminateStudents
      },
      { title: 'Tổng số học sinh đã chuyển đến', number: 0 },
      { title: 'Tổng số học sinh đã chuyển đi', number: 0 }
    ];
    const classStatistics = [
      {
        title: 'Tổng số lớp học đã hoàn thành',
        number: campusClassData.totalFinishedClasses
      },
      {
        title: 'Tổng số buổi học',
        number: campusClassData.totalLessons
      },
      {
        title: 'Tổng số buổi học lớp demo',
        number: campusClassData.totalDemoSessions
      },
      {
        title: 'Tổng số buổi catch-up',
        number: campusClassData.totalCatchUpSessions
      }
    ];
    const levelStatistics = campusLevelData.map(item => {
      return {
        title: `Tổng số lớp học level ${item.level}`,
        number: item.numberOfClasses
      };
    });
    const classStudentsNummber = campusClassData.classStudents.map(item => {
      return { key: item.class, data: item.numberOfStudents };
    });

    return {
      studentStatistics,
      classStatistics,
      levelStatistics,
      classStudentsNummber
    };
  }

  render() {
    const { loading } = this.state;
    let content = loading ? <Loading /> : this.renderUI();

    return <Fragment>{content}</Fragment>;
  }

  renderUI() {
    const { classes } = this.props;
    const {
      campusStudentData,
      studentStatistics,
      campusClassData,
      classStatistics,
      classStudentsNummber,
      levelStatistics,
      monthCategory,
      levelClassStatistic,
      studentsStatistic,
      regularSeries,
      demoSeries,
      demoToRegularSeries
    } = this.state;

    const chartOptions = {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: monthCategory
      },
      stroke: {
        show: false
      },
      colors: ['#f2d844', '#f19f3a', '#f15d31', '#f02a85', '#8537ed', '#6fa3f0']
    };
    const studentChartOptions = {
      chart: {
        type: 'line',
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: monthCategory
      },
      stroke: {
        width: [0, 0, 0, 0, 3]
      },
      colors: ['#e61d14', '#f2a129', '#f2da29', '#6fa1f3', '#8cc630']
    };
    const studentChartSeries = [
      {
        name: 'HS drop',
        type: 'column',
        data: studentsStatistic.terminateData
      },
      {
        name: 'HS suspend',
        type: 'column',
        data: studentsStatistic.suspendData
      },
      {
        name: 'HS demo',
        type: 'column',
        data: demoSeries
      },
      {
        name: 'HS regular',
        type: 'column',
        data: regularSeries
      },
      {
        name: 'HS Demo to Regular',
        type: 'line',
        data: demoToRegularSeries
      }
    ];

    return (
      <Fragment>
        <Grid container className={classes.stdHeadline}>
          <div className={classes.stdSubtitle}>
            <Typography variant="h3" className={classes.sectionTitle}>
              {'Thông tin học vụ tại chi nhánh'}
            </Typography>
          </div>
          <div className={classes.stdSubtitleHr}>
            <span className={clsx(classes.stdLineCell)}></span>
          </div>
        </Grid>

        <Grid container className={classes.stdBox} spacing={2}>
          <Grid item xs={3} container direction="column" justify="flex-start">
            <div className={classes.chartBoxTopPad}>
              <InfoBoxView
                title="Number of Students"
                subtitle="Tổng số học sinh"
                numberValue={campusStudentData.totalStudents}
                infoData={studentStatistics}
                classes={classes}
              />
            </div>
            <div className={classes.chartBoxTopPad}>
              <InfoBoxView
                title="Number of Classes"
                subtitle="Tổng số lớp học đang dạy"
                numberValue={campusClassData.totalClasses}
                infoData={classStatistics}
                classes={classes}
              />
            </div>
            <div className={classes.chartBoxTopPad}>
              <InfoBoxView
                title="Number of Levels"
                subtitle="Tổng số chương trình giảng dạy"
                numberValue={levelStatistics.length}
                infoData={levelStatistics}
                classes={classes}
              />
            </div>
          </Grid>

          <Grid item xs={9} container direction="column" justify="flex-start">
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <StudentsChartView
                  title="Total Students in Branch"
                  subtitle="Tổng số học sinh học tại chi nhánh"
                  chartOptions={studentChartOptions}
                  chartSeries={studentChartSeries}
                  classes={classes}
                />
              </Grid>
            </div>
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <ClassChartView
                  title="Number of Students of Classes"
                  subtitle="Sĩ số học sinh của từng lớp học"
                  chartData={classStudentsNummber}
                  classes={classes}
                />
              </Grid>
            </div>
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <LevelClassChartView
                  title="Number of New Classes"
                  subtitle="Số lớp khai giảng"
                  chartOptions={chartOptions}
                  chartSeries={levelClassStatistic}
                  classes={classes}
                />
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const stylesStudentStatistics = theme => ({
  ...stylesCharting(theme),
  ...stylesAcademicInfo(theme)
});

AcadStatistics.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesStudentStatistics)(AcadStatistics);
