import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import io from 'socket.io-client';
import Peer from 'simple-peer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVideoSlash,
  faVideo,
  faMicrophone,
  faMicrophoneSlash,
  faDesktop
} from '@fortawesome/free-solid-svg-icons';
import {
  CssBaseline,
  Drawer,
  Button,
  IconButton,
  Grid,
  Box,
  List,
  ListItem
} from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import LetsLearnContent from '../my-class/lets-learn/LetsLearnContent';
import { HubConnectionBuilder } from '@microsoft/signalr';
import authService from '../api-authorization/AuthorizeService';
import {
  CameraButton,
  ScreenButton,
  StyledVideo,
  useStylesLiveClass
} from './styleLiveClass';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import BallotIcon from '@material-ui/icons/Ballot';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import MenuIcon from '@material-ui/icons/Menu';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import clsx from 'clsx';
import { ControlButton } from '../common/ui/CustomButtons';
import VideoViewer from './VideoViewer';
import { StreamUtils } from '../common/utils/StreamUtils';
import { HtmlUtils } from '../common/utils/HtmlUtils';

const videoConstraints = {
  width: { min: 200, ideal: 200, max: 300 },
  height: { min: 150, ideal: 150, max: 150 }
};
// const screenConstraints = {
//     height: 100,
//     width: 100
// };

const Room = props => {
  const classes = useStylesLiveClass();
  const handleFS = useFullScreenHandle();

  const [screenShare, setScreenShare] = useState(false);
  const [teachScreenShare, setTeacherScreenShare] = useState(false);
  const [peerUserVideoViews, setPeerUserVideoViews] = useState([]);
  const [userVideoAudio, setUserVideoAudio] = useState({
    localUser: { video: true, audio: true }
  });

  const socketRef = useRef();
  const userVideo = useRef();
  const userStream = useRef();
  const streamCanvas = useRef();
  const screenShareVideo = useRef();
  const screenShareStream = useRef();
  const screenTrackRef = useRef();
  const peersRef = useRef([]);
  const teacherUser = useRef({});
  const teacherScreen = useRef();

  const roomID = props.roomId;
  const classLessonId = props.classLessonId;
  const userRole = props.userRole;
  const userId = props.userId;
  const userName = props.userName;

  const [contentDisplay, setContentDisplay] = useState([
    true,
    false,
    false,
    false
  ]);

  const [usersInRoom, setUsersInRoom] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(true);

  const liveclass = useRef();
  const usersInRoomRef = useRef();
  let letsLearnContent = React.createRef();
  usersInRoomRef.current = usersInRoom;

  function prepareCamera() {
    const stream = userStream.current;

    socketRef.current.on('all users', users => {
      console.log('get all users in room...');
      const updatePeerUsers = [];
      users.forEach(joinedUser => {
        let { socketId, video, audio } = joinedUser;
        const peerUser = peerUserVideoViews.find(
          i => i.userId === joinedUser.userId && !i.removed
        );
        if (peerUser) {
          updatePeerUsers.push(peerUser);
          return;
        }
        var streamShareScreen =
          joinedUser.userRole === 'teacher'
            ? StreamUtils.makeCanvasStream(streamCanvas.current)
            : new MediaStream();
        var streamStudentShare =
          joinedUser.userRole === 'teacher' && userRole === 'student'
            ? StreamUtils.makeCanvasStream(streamCanvas.current)
            : new MediaStream();
        const peer = createPeer(
          socketId,
          socketRef.current.id,
          stream,
          streamShareScreen,
          streamStudentShare
        );
        const peerRef = {
          peerID: socketId,
          peer,
          userRole: joinedUser.userRole,
          userId: joinedUser.userId,
          userName: joinedUser.userName
        };
        if (joinedUser.userRole === 'teacher') {
          console.log('teacher user', peerRef);
          teacherUser.current = peerRef;
        }

        const newPeerUser = createUserVideoView(peerRef);
        peersRef.current.push(peerRef);

        let removedUser = peerUserVideoViews.find(
          i => i.userId === joinedUser.userId && i.removed
        );
        if (removedUser) removedUser = newPeerUser;
        else updatePeerUsers.push(newPeerUser);

        setUserVideoAudio(preList => {
          return { ...preList, [peer.userName]: { video, audio } };
        });
      });
      setPeerUserVideoViews(updatePeerUsers);

      if (userRole === 'teacher') {
        letsLearnContent.setLesson(classLessonId, 1);
      }
    });

    socketRef.current.on('user joined', payload => {
      console.log('user joined: ' + payload.userName);
      var streamShareScreen =
        userRole === 'teacher'
          ? screenShare
            ? screenShareStream.current
            : StreamUtils.makeCanvasStream(streamCanvas.current)
          : new MediaStream();
      var streamStudentShare =
        userRole === 'teacher'
          ? StreamUtils.makeCanvasStream(streamCanvas.current)
          : new MediaStream();
      const peer = addPeer(
        payload.signal,
        payload.callerID,
        stream,
        streamShareScreen,
        streamStudentShare
      );
      const peerRef = {
        peerID: payload.callerID,
        peer,
        userRole: payload.userRole,
        userId: payload.userId,
        userName: payload.userName
      };
      peersRef.current.push(peerRef);
      const peerUserVideo = createUserVideoView(peerRef);
      setPeerUserVideoViews(peerUsers => [...peerUsers, peerUserVideo]);
    });

    socketRef.current.on('receiving returned signal', payload => {
      const item = peersRef.current.find(p => p.peerID === payload.id);
      item.peer.signal(payload.signal);
    });

    socketRef.current.on('FE-toggle-camera', ({ userId, switchTarget }) => {
      console.log('FE-toggle-camera' + userId);
      const peerIdx = findPeer(userId);

      setUserVideoAudio(preList => {
        let video = preList[peerIdx.userName].video;
        let audio = preList[peerIdx.userName].audio;

        if (switchTarget === 'video') {
          video = !video;
        } else {
          audio = !audio;
        }

        return { ...preList, [peerIdx.userName]: { video, audio } };
      });
    });

    socketRef.current.on('FE-start-share-screen', ({ userId }) => {
      setTeacherScreenShare(true);
      if (userRole !== 'teacher')
        teacherScreen.current.setAttribute(
          'style',
          'width: calc(100% - 10px); height: calc(100% - 58px);'
        );
    });

    socketRef.current.on('FE-stop-share-screen', ({ userId }) => {
      setTeacherScreenShare(false);
    });

    socketRef.current.emit('join room', {
      roomID,
      userRole,
      userId,
      userName
    });
  }

  useEffect(() => {
    socketRef.current = io.connect('/');

    navigator.mediaDevices
      .getUserMedia({ video: videoConstraints, audio: true })
      .then(stream => {
        userVideo.current.srcObject = stream;
        userStream.current = stream;
        prepareCamera();
      })
      .catch(err => {
        console.log('Cannot get camera stream...', err);
        const mediaStream = new MediaStream();
        userVideo.current.srcObject = mediaStream;
        userStream.current = mediaStream;
        prepareCamera();
      });
    // eslint-disable-next-line
  }, []);

  const filterRemainUsers = useCallback(leavedUser => {
    peersRef.current = peersRef.current.filter(
      p => p.userId !== leavedUser.userId
    );
    console.log('peersRef.current: ', peersRef.current);

    setPeerUserVideoViews(userVideoViews =>
      userVideoViews.map(p => {
        if (p.userId === leavedUser.userId) p.removed = true;
        return p;
      })
    );
  }, []);

  useEffect(() => {
    socketRef.current.on('user leaved', leavedUser => {
      filterRemainUsers(leavedUser);
    });
  }, [filterRemainUsers]);

  useEffect(() => {
    liveclass.current = new HubConnectionBuilder()
      .withUrl('hubs/liveclass')
      .withAutomaticReconnect()
      .build();

    liveclass.current
      .start()
      .then(() => {
        liveclass.current.invoke('LoginAsync', classLessonId, userRole, userId);

        liveclass.current.on('GetOtherUsersInClass', users => {
          setUsersInRoom(users);
        });

        liveclass.current.on('UserJoined', joinedUser => {
          userJoined(joinedUser);
        });

        liveclass.current.on('UserLeaved', leavedUser => {
          userLeaved(leavedUser);
        });

        liveclass.current.on('CallSelfLearning', () => {
          console.log('CallSelfLearning');
          changeControlView(1);
          handleSelfLearning();
        });

        liveclass.current.on('CallRemoteLearning', () => {
          console.log('CallRemoteLearning');
        });
      })
      .catch(error => console.log(error));
    // eslint-disable-next-line
  }, []);

  const userJoined = joinedUser => {
    setUsersInRoom(users => [...users, joinedUser]);
    // console.log('UserJoined', usersInRoomRef.current);
  };

  const userLeaved = leavedUser => {
    // console.log('UserLeaved', leavedUser);
    const updateUsers = [...usersInRoomRef.current];
    //console.log('usersInRoom', usersInRoomRef.current);
    const removeUser = updateUsers.find(i => i.userId === leavedUser.userId);
    if (removeUser) {
      updateUsers.splice(updateUsers.indexOf(removeUser), 1);
      setUsersInRoom(updateUsers);
      //console.log('updateUsers', updateUsers);
    }
  };

  function createUserVideoView(peerRef) {
    const userVideoEle = peerRef ? (
      <VideoViewer peer={peerRef.peer} streamIdx={0} style={{ width: 200 }} />
    ) : (
      <Box className={classes.emptyVideoBox} />
    );
    const studentSelfLearningShare = peerRef ? (
      <VideoViewer peer={peerRef.peer} streamIdx={2} style={{ height: 300 }} />
    ) : (
      <Box className={classes.emptyVideoBox} />
    );
    return {
      userId: peerRef.userId,
      removed: false,
      userVideoView: (
        <Fragment>
          {userVideoEle}
          <Box style={{ textAlign: 'center' }}>
            {peerRef.userRole} - {peerRef.userId}
          </Box>
        </Fragment>
      ),
      studentSelfLearningView: <Fragment>{studentSelfLearningShare}</Fragment>
    };
  }

  function findPeer(id) {
    return peersRef.current.find(p => p.peerID === id);
  }

  function createPeer(
    userToSignal,
    callerID,
    streamCamera,
    streamTeacherShare,
    streamStudentShare
  ) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      streams: [streamCamera, streamTeacherShare, streamStudentShare]
    });

    peer.on('error', err => console.log('error', err));
    peer.on('signal', signal => {
      socketRef.current.emit('sending signal', {
        userToSignal,
        callerID,
        signal,
        userRole,
        userId,
        userName
      });
    });

    return peer;
  }

  function addPeer(
    incomingSignal,
    callerID,
    streamCamera,
    streamTeacherShare,
    streamStudentShare
  ) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      streams: [streamCamera, streamTeacherShare, streamStudentShare]
    });

    peer.on('signal', signal => {
      socketRef.current.emit('returning signal', { signal, callerID });
    });

    peer.signal(incomingSignal);

    return peer;
  }

  const handleShareScreen = () => {
    if (!screenShare) {
      navigator.mediaDevices
        .getDisplayMedia({ cursor: true, audio: false })
        .then(stream => {
          const screenTrack = stream.getTracks()[0];
          peersRef.current.forEach(({ peer, userName }) => {
            if (peer.readable) {
              // replaceTrack (oldTrack, newTrack, oldStream);
              const tracks = peer.streams[1].getVideoTracks();
              if (tracks.length > 0) {
                peer.replaceTrack(tracks[0], screenTrack, peer.streams[1]);
              } else {
                peer.addTrack(screenTrack, peer.streams[1]);
              }
            } else {
              console.log(
                'start share screen: peer.readable=false',
                peer,
                userName
              );
            }
          });
          screenShareVideo.current.srcObject = stream;
          screenShareStream.current = stream;
          screenTrackRef.current = screenTrack;
          setScreenShare(true);
          screenShareVideo.current.removeAttribute('hidden');
          socketRef.current.emit('BE-start-share-screen', {
            roomID,
            userRole,
            userId,
            userName
          });

          screenTrack.onended = () => {
            peersRef.current.forEach(({ peer, userName }) => {
              if (peer.readable) {
                const tracks = peer.streams[1].getVideoTracks();
                if (tracks.length > 0) {
                  // tracks[0].dispatchEvent(new Event("stop"));
                  // tracks[0].stop();
                }
              } else {
                console.log(
                  'stop share screen: peer.readable=false',
                  peer,
                  userName
                );
              }
            });
            screenTrack.stop();
            setScreenShare(false);
            screenShareVideo.current.setAttribute('hidden', 'true');
            socketRef.current.emit('BE-stop-share-screen', {
              roomID,
              userRole,
              userId,
              userName
            });
          };
        })
        .catch(e => {
          console.log('error:' + e);
        });
    } else {
      console.log('share screen onended');
      screenTrackRef.current.onended();
    }
  };

  const handleSelfLearning = () => {
    navigator.mediaDevices
      .getDisplayMedia({ cursor: true, audio: false })
      .then(stream => {
        const screenTrack = stream.getTracks()[0];
        console.log('teacherUser', teacherUser.current);
        const { peer, userName } = teacherUser.current;
        if (peer.readable) {
          const tracks = peer.streams[2].getVideoTracks();
          if (tracks.length > 0) {
            peer.replaceTrack(tracks[0], screenTrack, peer.streams[2]);
          } else {
            peer.addTrack(screenTrack, peer.streams[2]);
          }
        } else {
          console.log('start self learning', peer, userName);
        }

        screenTrack.onended = () => {
          screenTrack.stop();
        };
      })
      .catch(e => {
        console.log('error:' + e);
      });
  };

  // eslint-disable-next-line
  const clickCameraDevice = event => {
    if (
      event &&
      event.target &&
      event.target.dataset &&
      event.target.dataset.value
    ) {
      const deviceId = event.target.dataset.value;
      const enabledAudio = userVideo.current.srcObject.getAudioTracks()[0]
        .enabled;

      navigator.mediaDevices
        .getUserMedia({ video: { deviceId }, audio: enabledAudio })
        .then(stream => {
          const newStreamTrack = stream
            .getTracks()
            .find(track => track.kind === 'video');
          const oldStreamTrack = userStream.current
            .getTracks()
            .find(track => track.kind === 'video');

          userStream.current.removeTrack(oldStreamTrack);
          userStream.current.addTrack(newStreamTrack);

          peersRef.current.forEach(({ peer }) => {
            // replaceTrack (oldTrack, newTrack, oldStream);
            peer.replaceTrack(
              oldStreamTrack,
              newStreamTrack,
              userStream.current
            );
          });
        });
    }
  };

  const toggleCameraAudio = e => {
    const target = e.target.getAttribute('data-switch');

    setUserVideoAudio(preList => {
      let videoSwitch = preList['localUser'].video;
      let audioSwitch = preList['localUser'].audio;

      if (target === 'video') {
        const userVideoTrack = userVideo.current.srcObject.getVideoTracks()[0];
        videoSwitch = !videoSwitch;
        userVideoTrack.enabled = videoSwitch;
      } else {
        const userAudioTrack = userVideo.current.srcObject.getAudioTracks()[0];
        audioSwitch = !audioSwitch;

        if (userAudioTrack) {
          userAudioTrack.enabled = audioSwitch;
        } else {
          userStream.current.getAudioTracks()[0].enabled = audioSwitch;
        }
      }

      return {
        ...preList,
        localUser: { video: videoSwitch, audio: audioSwitch }
      };
    });

    socketRef.current.emit('BE-toggle-camera-audio', {
      roomID,
      switchTarget: target
    });
  };

  const handleFinish = () => {
    // const { classLessonId, lessonLog, classId } = this.state;
    // this.classStudentReportDialog.openClassStudentReport(
    //   classLessonId,
    //   lessonLog,
    //   classId
    // );
  };

  const startSelfLearning = async () => {
    console.log('startSelfLearning', usersInRoom);
    const studentsToCall = usersInRoom.reduce((result, userInfo) => {
      if (userInfo.userRole === 'student') result.push(userInfo.userId);
      return result;
    }, []);
    console.log('==== studentsToCall', studentsToCall);
    if (studentsToCall.length === 0) return;

    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const msgSelfLearning = {
      classLessonId,
      userRole,
      userId,
      userName,
      recipients: studentsToCall
    };
    fetch(`LiveClass/CallSelfLearning`, {
      method: 'POST',
      body: JSON.stringify(msgSelfLearning),
      headers: headers
    });
  };

  const buttonPanel = (
    <Box style={{ display: 'flex' }}>
      <ScreenButton onClick={handleShareScreen} data-switch="video">
        <div>
          <FontAwesomeIcon icon={faDesktop} />
        </div>
        Share screen
      </ScreenButton>
      <CameraButton onClick={toggleCameraAudio} data-switch="video">
        <div>
          {userVideoAudio['localUser'].video ? (
            <FontAwesomeIcon icon={faVideo} />
          ) : (
            <FontAwesomeIcon icon={faVideoSlash} />
          )}
        </div>
        Camera
      </CameraButton>
      <CameraButton onClick={toggleCameraAudio} data-switch="audio">
        <div>
          {userVideoAudio['localUser'].audio ? (
            <FontAwesomeIcon icon={faMicrophone} />
          ) : (
            <FontAwesomeIcon icon={faMicrophoneSlash} />
          )}
        </div>
        Audio
      </CameraButton>
    </Box>
  );

  const fsButton = (
    <Button
      className={classes.fsButton}
      edge="end"
      color="inherit"
      onClick={handleFS.active ? handleFS.exit : handleFS.enter}
    >
      {handleFS.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
    </Button>
  );

  const letLearnView = (
    <Box
      className={classes.displayContent}
      style={contentDisplay[0] ? {} : { display: 'none' }}
    >
      {userRole === 'teacher' ? (
        <FullScreen handle={handleFS} className={classes.displayContent}>
          <div className={classes.floatButtons}>{fsButton}</div>
          <LetsLearnContent
            onRef={actualChild => (letsLearnContent = actualChild)}
            handleFinish={handleFinish}
            className={classes.displayContent}
          />
        </FullScreen>
      ) : (
        teacherUser.current.peer && (
          <Box
            style={
              teachScreenShare
                ? { width: '100%', height: '100%', textAlign: 'center' }
                : { display: 'none' }
            }
          >
            <VideoViewer
              peer={teacherUser.current.peer}
              streamIdx={1}
              onClick={HtmlUtils.expandFullScreen}
              ref={teacherScreen}
              style={{
                width: 'calc(100% - 10px)',
                height: 'calc(100% - 58px)',
                backgroundColor: '#fff'
              }}
            />
          </Box>
        )
      )}
    </Box>
  );

  const selfLearningView = (
    <Box
      className={classes.displayContent}
      style={contentDisplay[1] ? {} : { display: 'none' }}
    >
      {userRole === 'teacher' ? (
        <Fragment>
          <Box style={{ display: 'flex' }}>
            <Button onClick={startSelfLearning} size="small">
              Self Learning
            </Button>
          </Box>
          <Grid container>
            {peerUserVideoViews.map((peerUser, index) => {
              return (
                <Grid item xs key={index}>
                  <Box
                    style={
                      peerUser.removed
                        ? { display: 'none' }
                        : { alignItems: 'center', textAlign: 'center' }
                    }
                  >
                    {peerUser.studentSelfLearningView}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Fragment>
      ) : (
        <iframe
          src="https://www.sciencekids.co.nz/gamesactivities/lightshadows.html"
          width={'100%'}
          height={'100%'}
          title="Self Learning"
        />
      )}
    </Box>
  );

  const chatView = (
    <Box
      className={classes.displayContent}
      style={contentDisplay[2] ? {} : { display: 'none' }}
    >
      Chat window here...
    </Box>
  );

  const videoControlView = (
    <Box
      className={classes.displayContent}
      style={contentDisplay[3] ? {} : { display: 'none' }}
    >
      {buttonPanel}
      {userRole === 'teacher' ? (
        <StyledVideo
          onClick={HtmlUtils.expandFullScreen}
          muted
          ref={screenShareVideo}
          autoPlay
          playsInline
          style={{
            width: 'calc(100% - 10px)',
            height: 'calc(100% - 58px)',
            backgroundColor: '#fff'
          }}
          hidden
        />
      ) : (
        <div />
      )}
      <canvas
        ref={streamCanvas}
        width="160"
        height="120"
        style={{ width: 100, height: 100 }}
        hidden
      ></canvas>
    </Box>
  );

  const changeControlView = ctrlAction => {
    if (contentDisplay[ctrlAction]) return;
    const ctrlViewUpdate = [false, false, false, false];
    ctrlViewUpdate[ctrlAction] = true;
    setContentDisplay(ctrlViewUpdate);
  };

  const controlButtonPanel = (
    <List>
      <ListItem disableGutters>
        <ControlButton
          btnIcon={<DesktopWindowsIcon />}
          btnText="Live Class"
          btnHandle={() => {
            changeControlView(0);
          }}
        />
      </ListItem>
      <ListItem disableGutters>
        <ControlButton
          btnIcon={<BallotIcon />}
          btnText="Self Learning"
          btnHandle={() => {
            changeControlView(1);
          }}
        />
      </ListItem>
      <ListItem disableGutters>
        <ControlButton
          btnIcon={<ChatBubbleOutlineIcon />}
          btnText="Chat"
          btnHandle={() => {
            changeControlView(2);
          }}
        />
      </ListItem>
      <ListItem disableGutters>
        <ControlButton
          btnIcon={<VideoCallIcon />}
          btnText="Video"
          btnHandle={() => {
            changeControlView(3);
          }}
        />
      </ListItem>
    </List>
  );

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerPaperClose]: !drawerOpen
          })
        }}
      >
        <StyledVideo muted ref={userVideo} autoPlay playsInline />
        {peerUserVideoViews.map((peerUser, index) => {
          return (
            <Box
              key={index}
              className={classes.emptyVideoHolder}
              style={peerUser.removed ? { display: 'none' } : {}}
            >
              {peerUser.userVideoView}
            </Box>
          );
        })}
      </Drawer>
      <Box
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen
        })}
      >
        <Box className={classes.controlBar}>
          <div className={classes.controlBarHeader}>
            <IconButton onClick={handleDrawer}>
              <MenuIcon />
            </IconButton>
          </div>
          {controlButtonPanel}
        </Box>
        <Box className={classes.mainView}>
          {letLearnView}
          {selfLearningView}
          {chatView}
          {videoControlView}
        </Box>
      </Box>
    </div>
  );
};

export default Room;
