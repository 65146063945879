export const stylesEditMaterial = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'start'
  },
  margin: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(0, 3, 3, 5)
  },
  legend: {
    marginBottom: theme.spacing(1)
  },
  textField: {
    width: 300
  },
  longTextField: {
    width: '100%'
  },
  checkField: {
    marginTop: 'auto',
    marginLeft: theme.spacing(2)
  },
  btnUpload: {
    backgroundColor: '#50d282',
    color: 'white',
    '&:hover': {
      backgroundColor: '#50d282'
    }
  },
  inputFile: {
    marginLeft: theme.spacing(1),
    flex: 'auto'
  },
  inputGroup: {
    width: '100%',
    whiteSpace: 'nowrap',
    display: 'inline-flex'
  },
  styleSvg: {
    fill: 'white'
  },
  txtUpload: {
    marginLeft: '5px'
  }
});
