import { ActionIconButton, NormalTooltip } from '../ButtonStyles';
import {
  Button,
  Checkbox,
  FormControl,
  Icon,
  MenuItem,
  Radio,
  Select,
  Typography
} from '@material-ui/core';
import { DialogType, Dialogs } from '../popup/Dialogs';
import {
  MessageBarText,
  MessageBarVariants,
  MessageBars,
  MessageType
} from '../popup/Messages';
import React, { Component, Fragment } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import CollapseContent from '../table/CollapseContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HtmlUtils } from '../../common/utils/HtmlUtils';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SortUtils } from '../../common/utils/SortUtils';
import TablePaginationActions from './TablePaginationActions';
import UploadFileBtn from '../../common/UploadFile';
import authService from '../../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

const CustomTableCell = withStyles(theme => ({
  head: {
    fontFamily: 'RalewayBold',
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'uppercase',
    backgroundColor: 'rgba(224, 224, 224, 0.5)',
    color: theme.palette.text.primary,
    padding: theme.spacing(1.75, 2.5),
    paddingLeft: 10,
    paddingRight: 10,
    borderRight: '0.5px solid #ddd',
    '&:nth-child(1)': {
      borderLeft: '0.5px solid #ddd'
    }
  },
  body: {
    fontFamily: 'RalewayMedium',
    fontWeight: 'normal',
    fontSize: 14,
    color: theme.palette.text.secondary,
    maxWidth: 250,
    minHeight: 41,
    padding: theme.spacing(1, 2.5),
    paddingLeft: 10,
    paddingRight: 10,
    borderRight: '0.5px solid #ddd',
    '&:nth-child(1)': {
      borderLeft: '0.5px solid #ddd'
    }
  }
}))(TableCell);

const stylesCommonDataTable = theme => ({
  table: {
    // minWidth: 700
  },
  header: {
    borderTop: '0.5px solid #ddd'
  },
  actionCol: {
    width: 100,
    minWidth: 100
  },
  actionPanel: {
    display: 'inline-block',
    whiteSpace: 'nowrap'
  },
  actionIcon: {
    fontSize: 20
  },
  actionIconFa: {
    margin: '0 1.15px',
    height: 20
  },
  viewMore: {
    fontSize: 12,
    textDecoration: 'underline',
    cursor: 'pointer',
    paddingLeft: '1px',
    color: '#005cc5'
  },
  tooltip: {
    fontSize: 30,
    backgroundColor: 'green'
  },
  textField: {
    width: '100%'
  },
  select: {
    fontSize: 14
  },
  selectIcon: {
    right: -5
  },
  rootPagination: {
    borderLeft: '0.5px solid #ddd',
    borderRight: '0.5px solid #ddd'
  },
  caption: {
    fontSize: 12
  }
});

class CommonDataTable extends Component {
  constructor(...args) {
    super(...args);
    this.messageBar = React.createRef();
    this.confirmDialog = React.createRef();
    const { rows } = this.props;
    this.state = {
      selectedItems: [],
      selectAll: 0,
      filteredRows: rows ? rows : [],
      data: rows,
      apiUrl: '',
      selectedId: '',
      dataSelectDropdown: [],
      rowsPerPage: this.props.rowsPerPage ? this.props.rowsPerPage : 20,
      page: 0,
      order: '',
      orderBy: '',
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Success}
          type={MessageType.Upload}
          message={MessageBarText.Success}
          handleClose={this.handleCloseMsgBar}
        />
      ),
      closeConfirmDialog: (
        <Button onClick={this.closeConfirmDialog} color="primary">
          Cancel
        </Button>
      ),
      confirmDialog: (
        <Dialogs onRef={actualChild => (this.confirmDialog = actualChild)} />
      )
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    var resizeTable = document.getElementById('GeeO-Resizable-Table');
    this.resizableGrid(resizeTable);
    // var tables = document.getElementsByTagName('table');
    // for (var i = 0; i < tables.length; i++) {
    //   this.resizableGrid(tables[i]);
    // }
  }

  setData = filteredRows => {
    this.setState({ filteredRows });
  };

  handleCloseMsgBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  onChangeCheckbox = id => event => {
    const { selectedItems } = this.state;
    if (event.target.checked) {
      selectedItems.push(id);
    } else {
      var indexRemove = selectedItems.indexOf(id);
      if (indexRemove > -1) {
        selectedItems.splice(indexRemove, 1);
      }
    }
    this.setState({ selectedItems });
    if (this.props.callbackFromParent) {
      this.props.callbackFromParent(selectedItems);
    }
  };
  onChangeRadioButton = event => {
    const radioSelectedItem = event.target.value;
    this.setState({ radioSelectedItem });
    if (this.props.callbackFromParent) {
      this.props.callbackFromParent([radioSelectedItem]);
    }
  };
  getSelectedItems = () => {
    const { radioSelectedItem, selectedItems } = this.state;
    if (radioSelectedItem) {
      return [radioSelectedItem];
    }
    return selectedItems;
  };

  showPopup = (row, nameAct, el) => {
    this.props.callbackGetIdShowPopup(row, nameAct, el);
  };

  showConfirmDialog = () => {
    this.confirmDialog.showDialogWithCmds(
      this.state.dlgType,
      this.state.actionBtns
    );
  };
  closeConfirmDialog = () => {
    this.confirmDialog.closeDialog();
  };
  handleApiAction = (row, actionInfo) => {
    switch (actionInfo.name) {
      case 'delete':
        actionInfo.method = 'DELETE';
        actionInfo.msgType = MessageType.Delete;
        actionInfo.dlgType = DialogType.Delete;
        actionInfo.actionBtnText = 'Delete';
        break;
      case 'apiAction':
        break;
      case undefined:
      default:
    }
    const commands = (
      <Fragment>
        {this.state.closeConfirmDialog}
        <Button onClick={this.doApiAction} color="primary">
          {actionInfo.actionBtnText}
        </Button>
      </Fragment>
    );
    this.setState(
      {
        apiUrl: actionInfo.url,
        method: actionInfo.method,
        selectedId: actionInfo.selectedId ?? row.id,
        dlgType: actionInfo.dlgType,
        msgType: actionInfo.msgType,
        actionBtns: commands
      },
      this.showConfirmDialog
    );
  };
  doApiAction = async () => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    fetch(`api/${this.state.apiUrl}/${this.state.selectedId}`, {
      method: this.state.method,
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.messageBar.showMessage(
            MessageBarVariants.Success,
            this.state.msgType,
            MessageBarText.Success[this.state.msgType]
          );
          this.props.eventReloadData();
        } else throw new Error(response.status);
      })
      .catch(error => {
        this.messageBar.showMessage(
          MessageBarVariants.Error,
          this.state.msgType,
          MessageBarText.Error[this.state.msgType]
        );
        console.error('Error:', error);
      });
    this.closeConfirmDialog();
  };

  resizableGrid(table) {
    var row = table.getElementsByTagName('tr')[0],
      cols = row ? row.children : undefined;
    if (!cols) return;
    table.style.tableLayout = 'auto';
    for (var i = 0; i < cols.length; i++) {
      var div = createDiv();
      cols[i].appendChild(div);
      cols[i].style.position = 'relative';
      var colWidth = this.props.cols;
      var colActionWidth = this.props.actions;
      if (colWidth.length > i) {
        if (colWidth[i]['width'] !== undefined) {
          cols[i].style.width = colWidth[i]['width'] + 'px';
        }
      } else {
        cols[i].style.width = cols[i].offsetWidth + 'px';
      }
      if (colActionWidth) {
        cols[cols.length - 1].style.width =
          colActionWidth.length < 2
            ? '100px'
            : colActionWidth.length * 40 + 30 + 'px';
      }
      setListeners(div);
    }

    function setListeners(div) {
      var pageX, curCol, nxtCol, curColWidth, nxtColWidth;
      div.addEventListener('mousedown', function(e) {
        //table.style.tableLayout = 'fixed';
        //div.style.height = row.offsetHeight+'px';
        let styleDivVerical = document.querySelectorAll('.divVertical');
        for (let i = 0; i < styleDivVerical.length; i++) {
          //styleDivVerical[i].style.height = row.offsetHeight + 'px';
          styleDivVerical[i].style.height = '100%';
        }

        curCol = e.target.parentElement;
        nxtCol = curCol.nextElementSibling;
        pageX = e.pageX;

        var padding = paddingDiff(curCol);

        curColWidth = curCol.offsetWidth - padding;
        if (nxtCol) {
          nxtColWidth = nxtCol.offsetWidth;
          //nxtColWidth = nxtCol.offsetWidth - padding;
        }
      });

      div.addEventListener('mouseover', function(e) {
        e.target.style.borderRight = '3px solid #ddd';
      });

      div.addEventListener('mouseout', function(e) {
        e.target.style.borderRight = '';
      });

      document.addEventListener('mousemove', function(e) {
        if (curCol) {
          var diffX = e.pageX - pageX;

          if (nxtCol) {
            // nxtCol.style.width = (nxtColWidth - (diffX))+'px';
            nxtCol.style.width = nxtColWidth + 'px';
          }

          curCol.style.width = curColWidth + diffX + 'px';
        }
      });

      document.addEventListener('mouseup', function(e) {
        curCol = undefined;
        nxtCol = undefined;
        pageX = undefined;
        nxtColWidth = undefined;
        curColWidth = undefined;
      });
    }

    function createDiv() {
      var div = document.createElement('div');
      div.style.zIndex = 1;
      div.style.opacity = 0;
      div.style.top = 0;
      div.style.right = '-2.5px';
      div.style.width = '5px';
      div.style.marginRight = '1px';
      div.style.position = 'absolute';
      div.style.cursor = 'col-resize';
      div.style.userSelect = 'none';
      //div.style.height = height + 'px';
      div.style.height = '100%';
      div.classList.add('divVertical');
      return div;
    }

    function paddingDiff(col) {
      if (getStyleVal(col, 'box-sizing') === 'border-box') {
        return 0;
      }

      var padLeft = getStyleVal(col, 'padding-left');
      var padRight = getStyleVal(col, 'padding-right');
      return parseInt(padLeft, 10) + parseInt(padRight, 10);
    }

    function getStyleVal(elm, css) {
      return window.getComputedStyle(elm, null).getPropertyValue(css);
    }
  }

  onChangeDropdown = id => event => {
    var val = event.target.value;
    if (val !== '') {
      var dataSelectDropdown = this.state.dataSelectDropdown;
      var data = this.state.filteredRows;
      var isCheck = false;
      dataSelectDropdown.forEach(e => {
        if (e.id === id) {
          e.valDropdown = val;
          isCheck = true;
        }
      });
      data.forEach(e => {
        if (e.id === id) {
          e.valDropdown = val;
        }
      });
      if (!isCheck) {
        dataSelectDropdown.push({ id: id, valDropdown: val });
      }
      this.setState({ dataSelectDropdown: dataSelectDropdown, row: data });
      this.props.callbackFromParentDrowdown(dataSelectDropdown);
    }
  };

  onSelectDropdown = id => event => {
    return event.target.value;
  };

  handleChangePage = (e, page) => {
    this.setState({ page: page });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: Number(event.target.value) });
  };

  createSortHandler = property => event => {
    this.handleRequestSort(event, property);
  };
  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  onCheckedAllCheckboxChange = event => {
    const { checked } = event.target;
    let selectedItems = [];
    if (checked) {
      selectedItems = this.props.rows.map(x => x.id);
    }
    this.setState({ selectedItems });
    this.props.callbackFromParent(selectedItems);
  };

  render() {
    const {
      classes,
      cols,
      actions,
      isPaging,
      isShowCheckbox,
      isShowRadioButton,
      isShowDropdown,
      dataDropdown,
      eventDropDown
    } = this.props;
    const {
      selectedItems,
      radioSelectedItem,
      order,
      orderBy,
      rowsPerPage,
      page,
      filteredRows
    } = this.state;
    const isShowPopup = this.props.isShowPopup !== undefined ? true : false;
    const sortedRows = SortUtils.stableSort(
      filteredRows,
      SortUtils.getComparator(order, orderBy)
    );
    const rows =
      isPaging && sortedRows.length > rowsPerPage
        ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : sortedRows;
    const emptyRows =
      isPaging && filteredRows.length > rowsPerPage
        ? rowsPerPage -
          Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage)
        : 0;

    return (
      <Fragment>
        {this.state.messageBar}
        {this.state.confirmDialog}
        <Table
          className={clsx(classes.table, 'GeeO-Resizable-Table')}
          id="GeeO-Resizable-Table"
        >
          <TableHead>
            <TableRow className={classes.header}>
              {isShowCheckbox && (
                <CustomTableCell style={{ width: 80 }} align={'center'}>
                  <Checkbox onChange={this.onCheckedAllCheckboxChange} />
                </CustomTableCell>
              )}
              {isShowRadioButton && (
                <CustomTableCell style={{ width: 80 }}></CustomTableCell>
              )}
              {cols.map((col, idx) => (
                <CustomTableCell
                  component="th"
                  scope="row"
                  style={{ width: col.width }}
                  align={col.align}
                  sortDirection={orderBy === col.name ? order : false}
                  key={idx}
                >
                  <TableSortLabel
                    active={orderBy === col.name}
                    direction={orderBy === col.name ? order : 'asc'}
                    onClick={this.createSortHandler(col.name)}
                  >
                    {col.header}
                  </TableSortLabel>
                </CustomTableCell>
              ))}
              {isShowDropdown && (
                <CustomTableCell
                  component="th"
                  scope="row"
                  style={{ width: eventDropDown.width }}
                  align={eventDropDown.align}
                  key={eventDropDown.name}
                >
                  {eventDropDown.name}
                </CustomTableCell>
              )}

              {actions && (
                <CustomTableCell className={classes.actionCol} align="center">
                  <div className={classes.actionPanel}>&nbsp;</div>
                </CustomTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow className={classes.row} key={idx}>
                {isShowCheckbox ? (
                  <CustomTableCell align="center" style={{ width: 80 }}>
                    <Checkbox
                      id={row.id}
                      checked={selectedItems.includes(row.id)}
                      onChange={this.onChangeCheckbox(row.id)}
                    />
                  </CustomTableCell>
                ) : isShowRadioButton ? (
                  <CustomTableCell align="center" style={{ width: 80 }}>
                    <Radio
                      checked={radioSelectedItem === row.id}
                      value={row.id}
                      onChange={this.onChangeRadioButton}
                    />
                  </CustomTableCell>
                ) : null}

                {cols.map((col, idx) => (
                  <CustomTableCell
                    align={col.align}
                    key={idx}
                    style={{ width: col.width }}
                  >
                    {col.isBool ? (
                      row[col.name] === true && <CheckIcon />
                    ) : (
                      <Typography
                        variant="body2"
                        noWrap={col.noWrap === undefined ? undefined : true}
                      >
                        {col.obj && row[col.name] !== null ? (
                          <span
                            dangerouslySetInnerHTML={HtmlUtils.createMarkup(
                              row[col.name][col.objName]
                            )}
                          />
                        ) : col.shorteningContent === true ? (
                          <CollapseContent
                            content={row[col.name]}
                            index={col.indexCut}
                          />
                        ) : (
                          <span
                            dangerouslySetInnerHTML={HtmlUtils.createMarkup(
                              row[col.name]
                            )}
                          />
                        )}
                      </Typography>
                    )}
                  </CustomTableCell>
                ))}

                {isShowDropdown && (
                  <CustomTableCell align="center">
                    <FormControl
                      className={clsx(classes.margin, classes.textField)}
                    >
                      <Select
                        id="dropdown"
                        name="dropdown"
                        value={row.valDropdown ? row.valDropdown : 1}
                        onChange={this.onChangeDropdown(row.id)}
                      >
                        {dataDropdown
                          ? dataDropdown.map(item => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </CustomTableCell>
                )}
                {actions && (
                  <CustomTableCell className={classes.actionCol} align="center">
                    <div className={classes.actionPanel}>
                      {actions.map((col, idx) =>
                        col.name !== 'attach' ? (
                          <NormalTooltip title={col.tooltip} key={idx}>
                            {isShowPopup === true && col.url === undefined ? (
                              <ActionIconButton
                                onClick={el =>
                                  this.showPopup(row, col.name, el)
                                }
                              >
                                <Icon className={classes.actionIcon}>
                                  {col.icon}
                                </Icon>
                              </ActionIconButton>
                            ) : col.name === 'delete' ||
                              col.name === 'apiAction' ? (
                              <ActionIconButton
                                color="inherit"
                                onClick={() => this.handleApiAction(row, col)}
                              >
                                <Icon className={classes.actionIcon}>
                                  {col.icon}
                                </Icon>
                              </ActionIconButton>
                            ) : col.name === 'callback' ? (
                              <ActionIconButton
                                onClick={el => col.callBackFn(row, el)}
                              >
                                {col.icon_fa ? (
                                  <FontAwesomeIcon
                                    className={classes.actionIconFa}
                                    icon={col.icon_fa}
                                    size="xs"
                                  />
                                ) : (
                                  <Icon className={classes.actionIcon}>
                                    {col.icon}
                                  </Icon>
                                )}
                              </ActionIconButton>
                            ) : (
                              <ActionIconButton
                                color="inherit"
                                component={Link}
                                to={
                                  col.name === 'detail' ||
                                  col.name === 'subList' ||
                                  col.name === 'edit' ||
                                  col.name === 'exam' ||
                                  col.name === 'route' ||
                                  col.name === 'view-unit'
                                    ? col.url +
                                      '/' +
                                      (row.subId != null ? row.subId : row.id)
                                    : col.name === 'multi-route' ||
                                      col.name === 'multi-route1'
                                    ? col.url + '/' + row.id + '/' + row.routeId
                                    : col.url
                                }
                              >
                                {col.icon_fa ? (
                                  <FontAwesomeIcon
                                    className={classes.actionIconFa}
                                    icon={col.icon_fa}
                                    size="xs"
                                  />
                                ) : col.icon_mui ? (
                                  col.icon_mui
                                ) : (
                                  <Icon className={classes.actionIcon}>
                                    {col.icon}
                                  </Icon>
                                )}
                              </ActionIconButton>
                            )}
                          </NormalTooltip>
                        ) : (
                          <UploadFileBtn
                            key={idx}
                            info={col}
                            selectObj={row}
                            callBackFn={col.callBackFn}
                          />
                        )
                      )}
                    </div>
                  </CustomTableCell>
                )}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 57 * emptyRows }}>
                <CustomTableCell colSpan={cols.length + 1} />
              </TableRow>
            )}
          </TableBody>
          {isPaging && filteredRows.length > rowsPerPage && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 40]}
                  colSpan={cols.length + 1}
                  count={filteredRows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="Rows/Page"
                  classes={{
                    root: classes.rootPagination,
                    caption: classes.caption
                  }}
                  SelectProps={{
                    native: true
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </Fragment>
    );
  }
}

CommonDataTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export { CustomTableCell };
export default withStyles(stylesCommonDataTable)(CommonDataTable);
