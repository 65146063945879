import React, { Component, Fragment } from 'react';
import {
  TeacherLessonActions,
  TeacherLessonPaths
} from './TeacherLessonConstants';
import { green, grey, red } from '@material-ui/core/colors';

import Button from '@material-ui/core/Button';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomizedTable from '../ui/table/CustomizedTable';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Loading } from '../ui/Loading';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import authService from '../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';

const stylesListTeacherClass = theme => ({
  root: {
    float: 'right',
    minHeight: 54,
    paddingRight: 16
  },
  highlight: {
    float: 'right'
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    display: 'flex',
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  viewClass: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(grey[500], 0.15),
    '&:hover': {
      backgroundColor: fade(grey[500], 0.25)
    },
    marginLeft: 0,
    marginTop: 6,
    marginRight: theme.spacing(1),
    width: '100%',
    height: 35,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  actionBtn: {
    width: '130px !important',
    height: '36px !important'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200
      }
    }
  },
  noOutline: {
    outline: 'none',
    margin: theme.spacing(1)
  },
  card: {
    width: 210
  }
});

class ListTeacherClassComp extends Component {
  static displayName = ListTeacherClassComp.name;

  constructor(...args) {
    super(...args);
    this.pickClassId = '';
    this.selectedClassToCopy = [];
    this.state = {
      classLessons: [],
      classesToCopy: [],
      lessonCopyStatus: null,
      loading: true,
      redirect: null,
      anchorEl: null,
      isMyLessons: this.props.isMyLessons
    };
  }

  componentDidMount() {
    this.populateDataInit();
  }

  async populateDataInit() {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const response = await fetch(
      `api/TeacherLessonUnit/GetClassByTeacher/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const classLessons = await response.json();
    if (classLessons.status === 404) {
      this.setState({ classLessons: [], loading: false });
    } else {
      this.setState({ classLessons, loading: false });
    }
  }

  async getClassesToCopy(classInfo) {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const teacherId =
      this.props.action === TeacherLessonActions.List ? loggedInUser.sub : '0';
    const params = {
      teacherId,
      classId: classInfo.id,
      level: classInfo.level
    };
    this.pickClassId = classInfo.id;
    var url = require('url');
    const querystring = url.format({ query: params });
    const response = await fetch(
      `api/TeacherLessonUnit/GetClassesToCopy${querystring}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    if (response.ok) {
      const classesToCopy = await response.json();
      this.setState({ classesToCopy });
    } else {
      this.setState({ classesToCopy: [] });
    }
  }

  viewAllClassCalender = () => {
    this.redirectTo(TeacherLessonPaths.ViewAllClassCalendar);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  showCopyLesson = (row, actName, el) => {
    this.selectedClassToCopy = [];
    this.getClassesToCopy(row);
    this.setState({ anchorEl: el.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null, lessonCopyStatus: null });
  };
  handleChangeClass = event => {
    const target = event.target;
    if (target.type === 'checkbox' && target.checked) {
      this.selectedClassToCopy.push(target.value);
    } else {
      this.selectedClassToCopy.splice(
        this.selectedClassToCopy.indexOf(target.value),
        1
      );
    }
  };
  doCopyClassLessons = async () => {
    this.setState({ lessonCopyStatus: 'progress' });
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    let payload = {
      teacherId: loggedInUser.sub,
      srcClassId: this.pickClassId,
      destClassIds: this.selectedClassToCopy
    };
    fetch(`api/TeacherLessonUnit/CopyLessonsToClasses`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.setState({ lessonCopyStatus: 'ok' });
        } else this.setState({ lessonCopyStatus: 'error' });
      })
      .catch(error => {
        this.setState({ lessonCopyStatus: 'error' });
        console.error('Error:', error);
      });
  };

  renderDatagrid() {
    const { classes } = this.props;
    const {
      classesToCopy,
      lessonCopyStatus,
      anchorEl,
      classLessons,
      isMyLessons
    } = this.state;

    const colsTeacher = [
      { name: 'level', header: 'Level', align: 'left' },
      { name: 'class', header: 'Class', align: 'left' },
      { name: 'schedule', header: 'Schedule', align: 'left' },
      { name: 'startTimeLocal', header: 'Start time', align: 'left' },
      { name: 'endTimeLocal', header: 'End time', align: 'left' }
    ];
    const colsAll = [{ name: 'teacher', header: 'Teachers', align: 'left' }];
    const cols =
      this.props.action === TeacherLessonActions.List
        ? colsTeacher
        : colsTeacher.concat(colsAll);
    var actions = [
      {
        name: 'subList',
        tooltip: 'List all lessons of this class',
        url:
          this.props.action === TeacherLessonActions.List
            ? `${TeacherLessonPaths.ViewLesson}`
            : `${TeacherLessonPaths.ViewLessonAdmin}`,
        icon: 'list_alt_icon'
      }
    ];
    var actionsMyLesson = [
      {
        name: 'detail',
        tooltip: `Calendar`,
        url: `${TeacherLessonPaths.ViewCalendar}`,
        icon: 'calendar_today'
      },
      {
        name: 'lessonAndTeacher',
        tooltip: `Schedule`,
        url: `${TeacherLessonPaths.ViewMySchedule}`,
        icon: 'schedule'
      },
      {
        name: 'copyToClasses',
        tooltip: `Copy lessons to...`,
        icon: 'file_copy'
      }
    ];
    if (isMyLessons) {
      actions = actionsMyLesson.concat(actions);
    }
    const open = Boolean(anchorEl);

    return (
      <Fragment>
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: false
          })}
        >
          <div className={classes.actions}>
            {/* <Button color="primary" onClick={this.viewAllClassCalender} startIcon={<CalendarToday />} className={classes.button}>
                    View All
                  </Button> */}
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              aria-label="add"
              className={classes.actionBtn}
              onClick={this.viewAllClassCalender}
            >
              <CalendarToday className={classes.extendedIcon} />
              View All
            </Fab>
          </div>
        </Toolbar>
        <CustomizedTable
          onRef={actualChild => (this.child = actualChild)}
          rows={classLessons}
          cols={cols}
          actions={actions}
          isShowPopup={true}
          callbackGetIdShowPopup={this.showCopyLesson}
          isPaging={true}
        />
        <Popover
          id="popoverSelectClassToCopy"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <Card className={classes.card} variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Copy lessons to...
              </Typography>
              {classesToCopy.map(row => (
                <FormControlLabel
                  key={row.id}
                  control={
                    <Checkbox
                      value={row.id}
                      onChange={this.handleChangeClass}
                    />
                  }
                  label={row.class}
                />
              ))}
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={this.doCopyClassLessons}
              >
                Copy
              </Button>
              {lessonCopyStatus === 'progress' ? (
                <CircularProgress size={24} />
              ) : lessonCopyStatus === 'ok' ? (
                <DoneIcon style={{ color: green[500] }} />
              ) : lessonCopyStatus === 'error' ? (
                <ErrorIcon style={{ color: red[500] }} />
              ) : null}
            </CardActions>
          </Card>
        </Popover>
        {this.state.redirect}
      </Fragment>
    );
  }
  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return <Fragment>{contents}</Fragment>;
  }
}

ListTeacherClassComp.propTypes = {
  classes: PropTypes.object.isRequired,
  isMyLessons: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired
};

export const ListTeacherClass = withStyles(stylesListTeacherClass)(
  ListTeacherClassComp
);
