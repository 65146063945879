import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button, Comment, Form, Header } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { Dialogs, DialogType } from '../ui/popup/Dialogs';
import AvatarPlaceholder from '../../images/avatar-1577909_960_720.webp';

const stylesLessonComment = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3, 2, 0.25, 2)
  },
  comments: {
    maxWidth: 900,
    width: 900
  }
});

class LessonCommentComp extends Component {
  static displayName = LessonCommentComp.name;

  constructor(...args) {
    super(...args);
    this.confirmDialog = React.createRef();
    this.commentsEnd = React.createRef();
    this.state = {
      comments: [],
      userId: '',
      commentText: '',
      loading: true,
      classLessonId: this.props.classLessonId,
      title: 'Lesson Comments',
      confirmDialogActions: (
        <Fragment>
          <Button onClick={this.closeConfirmDialog} primary>
            Cancel
          </Button>
          <Button onClick={this.doDeleteAction} primary>
            Delete
          </Button>
        </Fragment>
      ),
      confirmDialog: (
        <Dialogs onRef={actualChild => (this.confirmDialog = actualChild)} />
      )
    };
  }

  componentDidMount() {
    this.populateLessonComments();
  }

  populateLessonComments = async () => {
    this.setState({ loading: true });
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const response = await fetch(
      `api/LessonComment/GetLessonComments/${this.props.classLessonId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const comments = await response.json();
    this.setState({
      commentText: '',
      comments,
      userId: loggedInUser.sub,
      loading: false
    });
  };

  handleSaveComment = async () => {
    const token = await authService.getAccessToken();
    this.saveComment(token);
  };
  saveComment = token => {
    const { userId, classLessonId, commentText } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    let newComment = { classLessonId, userId, comment: commentText };
    fetch(`api/LessonComment/Create`, {
      method: 'POST',
      body: JSON.stringify(newComment),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.populateLessonComments();
          this.scrollToBottom();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  deleteComment = deleteId => {
    this.setState({ deleteId }, this.showConfirmDialog);
  };
  refreshComment = () => {
    this.populateLessonComments();
    this.scrollToBottom();
  };
  scrollToBottom = () => {
    this.commentsEnd.scrollIntoView({ behavior: 'smooth' });
  };

  handleChangeText = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState({ [attrName]: attrValue });
  };

  showConfirmDialog = () => {
    this.confirmDialog.showDialogWithCmds(
      DialogType.Delete,
      this.state.confirmDialogActions
    );
  };
  closeConfirmDialog = () => {
    this.confirmDialog.closeDialog();
  };
  doDeleteAction = async () => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    fetch(`api/LessonComment/Delete/${this.state.deleteId}`, {
      method: 'DELETE',
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.populateLessonComments();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    this.closeConfirmDialog();
  };

  renderContent() {
    const { classes } = this.props;
    const { comments, userId, title, commentText } = this.state;
    return (
      <Paper className={classes.root}>
        <Comment.Group className={classes.comments}>
          <Header as="h3" dividing>
            {title}
          </Header>

          {comments.map((row, idx) => (
            <Comment key={row.id}>
              <Comment.Avatar src={AvatarPlaceholder} />
              <Comment.Content>
                <Comment.Author as="a">{row.user.fullName}</Comment.Author>
                <Comment.Metadata>
                  <div>({row.user.role.name})</div>
                  <div>{row.dateTimeFormatted}</div>
                </Comment.Metadata>
                <Comment.Text>{row.comment}</Comment.Text>
                <Comment.Actions>
                  {row.user.id === userId && (
                    <Comment.Action onClick={() => this.deleteComment(row.id)}>
                      Delete
                    </Comment.Action>
                  )}
                </Comment.Actions>
              </Comment.Content>
            </Comment>
          ))}

          <Form reply>
            <Form.TextArea
              value={commentText}
              onChange={this.handleChangeText('commentText')}
            />
            <Button
              content="Add Comment"
              labelPosition="left"
              icon="edit"
              primary
              onClick={this.handleSaveComment}
            />
            <Button
              content="Refresh"
              labelPosition="left"
              icon="refresh"
              primary
              onClick={this.refreshComment}
            />
          </Form>
        </Comment.Group>
        <div
          style={{ float: 'left', clear: 'both' }}
          ref={el => {
            this.commentsEnd = el;
          }}
        ></div>
      </Paper>
    );
  }
  render() {
    let contents = this.state.loading ? <Loading /> : this.renderContent();
    return (
      <Fragment>
        {contents}
        {this.state.confirmDialog}
      </Fragment>
    );
  }
}

LessonCommentComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const LessonComment = withStyles(stylesLessonComment)(LessonCommentComp);
