import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Loading } from '../ui/Loading';
import CardHeader from '@material-ui/core/CardHeader';
import Fab from '@material-ui/core/Fab';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SaveIcon from '@material-ui/icons/Save';
import { TeacherPaths, TeacherActions } from './TeacherConstans';
import CommonDataTable from '../ui/table/CommonDataTable';
import { MenuItem, Select } from '@material-ui/core';

const stylesEditTeacher = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  },
  groupSchedule: {
    marginTop: '20px'
  },
  fab: {
    margin: '12px 8px 8px 8px'
  },
  padding10: {
    padding: '10px'
  }
});

class EditTeacherComp extends Component {
  static displayName = EditTeacherComp.name;
  constructor(...args) {
    super(...args);
    this.teacherForm = React.createRef();
    this.child = React.createRef();
    this.state = {
      action: this.props.action,
      teacherId: this.props.teacherId,
      method: 'PUT',
      teacher: {
        firstName: '',
        lastName: '',
        email: '',
        elAccount: '',
        englishName: '',
        phoneNumber: '',
        teachingHours: '',
        isTeacher: true
      },
      redirect: null,
      loading: true,
      dataSchedule: []
    };

    switch (this.props.action) {
      case TeacherActions.Create:
        this.state.method = 'POST';
        this.state.action = `api/AspNetUsers`;
        this.state.loading = false;
        break;
      case TeacherActions.Edit:
        this.state.action = `api/AspNetUsers/${this.state.teacherId}`;
        this.populateTeacherData();
        break;
      case undefined:
      default:
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  populateTeacherData = async () => {
    const token = await authService.getAccessToken();
    const { teacherId } = this.state;

    const [respTeacher, respTeacherSchedules] = await Promise.all([
      fetch(`api/AspNetUsers/${teacherId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/AspNetUsers/teaching-schedule/${teacherId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [teacher, dataSchedule] = await Promise.all([
      respTeacher.json(),
      respTeacherSchedules.json()
    ]);

    this.setState({
      teacher,
      dataSchedule,
      loading: false
    });
  };

  backToList = () => {
    this.redirectTo(TeacherPaths.List);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { teacher, method, action, token } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    fetch(action, {
      method: method,
      body: JSON.stringify(teacher),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  submitData = async () => {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    ReactDOM.findDOMNode(this.teacherForm.current).dispatchEvent(
      new Event('submit')
    );
  };

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      teacher: {
        ...prevState.teacher,
        [attrName]: attrValue
      }
    }));
  };

  renderForm() {
    const { classes } = this.props;
    const { teacher, dataSchedule } = this.state;
    const cols = [
      {
        name: 'firstName',
        header: 'First Name',
        placeholder: 'First Name',
        adornment: ''
      },
      {
        name: 'lastName',
        header: 'Last Name',
        placeholder: 'Last Name',
        adornment: ''
      },
      {
        name: 'email',
        header: 'Email',
        placeholder: 'Email',
        adornment: ''
      },
      {
        name: 'phoneNumber',
        header: 'Phone',
        placeholder: 'Phone',
        adornment: ''
      },
      {
        name: 'teachingHours',
        header: 'Teaching Hours',
        placeholder: 'Teaching Hours',
        adornment: ''
      }
    ];
    const colsSchedule = [
      { name: 'level', header: 'Level', align: 'left' },
      { name: 'classCourseName', header: 'Class', align: 'left' },
      { name: 'startDateText', header: 'Start Date', align: 'left' },
      { name: 'endDateText', header: 'End Date', align: 'left' },
      { name: 'schedule', header: 'Schedule', align: 'left' },
      {
        name: 'startTimeLocal',
        header: 'Start Time',
        align: 'left'
      },
      { name: 'endTimeLocal', header: 'End Time', align: 'left' }
    ];

    return (
      <Fragment>
        <Paper className={classes.root}>
          <form
            ref={this.teacherForm}
            className={classes.container}
            onSubmit={this.handleSubmit}
          >
            <Grid container>
              <Grid item xs={6} className={classes.cell}>
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <FormControl
                      className={classNames(classes.margin, classes.textField)}
                    >
                      <InputLabel>Title</InputLabel>
                      <Select
                        name="titleName"
                        value={teacher['titleName']}
                        onChange={this.handleChange('titleName')}
                      >
                        <MenuItem value="Mrs.">Mrs.</MenuItem>
                        <MenuItem value="Ms.">Ms.</MenuItem>
                        <MenuItem value="Mr.">Mr.</MenuItem>
                      </Select>
                    </FormControl>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="english-name"
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      className={classes.margin}
                      label="English Name"
                      placeholder="English Name"
                      name="englishName"
                      value={teacher.englishName}
                      onChange={this.handleChange('englishName')}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="first-name"
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      className={classes.margin}
                      label={cols[0].header}
                      placeholder={cols[0].placeholder}
                      name={cols[0].name}
                      value={teacher[cols[0].name]}
                      onChange={this.handleChange(cols[0].name)}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="lastname"
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      className={classes.margin}
                      label={cols[1].header}
                      placeholder={cols[1].placeholder}
                      name={cols[1].name}
                      value={teacher[cols[1].name]}
                      onChange={this.handleChange(cols[1].name)}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} className={classes.cell}>
                <List>
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="teacher-email"
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      rows="6"
                      className={classes.margin}
                      label={cols[2].header}
                      placeholder={cols[2].placeholder}
                      name={cols[2].name}
                      value={teacher[cols[2].name]}
                      onChange={this.handleChange(cols[2].name)}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <FormControl
                      className={classNames(classes.margin, classes.textField)}
                    >
                      <InputLabel htmlFor="lesson-tb">
                        {cols[3].header}
                      </InputLabel>
                      <Input
                        id="phoneNumber"
                        name={cols[3].name}
                        value={teacher[cols[3].name]}
                        onChange={this.handleChange(cols[3].name)}
                        endAdornment={
                          <InputAdornment position="end">
                            {cols[3].adornment}
                          </InputAdornment>
                        }
                        inputProps={{
                          'aria-label': cols[4].header
                        }}
                      />
                    </FormControl>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="teacher-elaccount"
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      className={classes.margin}
                      label="EL Account"
                      placeholder="EL Account"
                      name="elAccount"
                      value={teacher.elAccount}
                      onChange={this.handleChange('elAccount')}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </form>
        </Paper>
        {this.props.action === TeacherActions.Edit ? (
          <Fragment>
            <Fab
              aria-label="Save"
              color="primary"
              className={classes.fab}
              onClick={this.submitData}
            >
              <SaveIcon />
            </Fab>
            <Fab
              aria-label="Cancel"
              className={classes.fab}
              onClick={this.backToList}
            >
              <CloseOutlinedIcon color="action" />
            </Fab>
          </Fragment>
        ) : (
          ''
        )}
        {this.props.action === TeacherActions.Edit ? (
          <Paper className={classes.groupSchedule}>
            <CardHeader title="Schedule" />
            <CommonDataTable
              onRef={actualChild => (this.child = actualChild)}
              rows={dataSchedule}
              cols={colsSchedule}
              eventReloadData={this.refreshTeacher}
            />
          </Paper>
        ) : (
          ''
        )}
        {this.state.redirect}
      </Fragment>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderForm();
    return <Fragment>{contents}</Fragment>;
  }
}

EditTeacherComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const EditTeacher = withStyles(stylesEditTeacher)(EditTeacherComp);
