import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { ScheduleInfo } from './ScheduleInfo';
import { PagingTable } from '../ui/table/PagingTable';

const stylesList = () => ({});

class ScheduleTbClassRoomSelectComp extends Component {
  static displayName = ScheduleTbClassRoomSelectComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      data: [],
      parentId: this.props.parentId,
      actionColName: this.props.actionColName,
      loading: true,
      schedule: this.props.schedule
    };
  }

  componentDidMount() {
    this.populateData();
  }

  populateData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/ClassRooms/GetClassRoom/0', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({
      data: data,
      actionColName: 'selectClassRoom',
      loading: false
    });
    this.setChildDataSelect();
  };

  setChildDataSelect = () => {
    const { data } = this.state;
    this.child.setData(data);
  };

  callbackGetValueRadioBtn = e => {
    this.props.callbackGetValueRadioBtn(e);
  };

  render() {
    const cols = [
      { name: 'name', header: 'Name', align: 'left' },
      { name: 'roomNumber', header: 'Room Number', align: 'left' },
      {
        name: 'campus',
        header: 'Campus',
        align: 'left',
        obj: true,
        objName: 'name'
      },
      {
        name: 'roomType',
        header: 'Room Type',
        align: 'left',
        obj: true,
        objName: 'name'
      }
    ];
    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <ScheduleInfo schedule={this.state.schedule} />
            <PagingTable
              onRef={actualChild => (this.child = actualChild)}
              cols={cols}
              isShowRadioButton="true"
              callbackGetValueRadioBtn={this.callbackGetValueRadioBtn}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

ScheduleTbClassRoomSelectComp.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(stylesList)(ScheduleTbClassRoomSelectComp);
