export const CatchUpScheduleActions = {
  List: 'list',
  ListStudentCatchUp: 'list-student-cath-up',
  Edit: 'edit',
  Create: 'create',
  CreateCatchUpLesson: 'create-catch-up-lesson',
  Delete: 'delete',
  CreateCatchUpStudent: 'create-catch-up-student'
};

const prefix = '/catch-up';

export const CatchUpSchedulePaths = {
  CatchUpSchedulePrefix: prefix,
  List: `${prefix}/${CatchUpScheduleActions.List}`,
  ListStudentCatchUp: `${prefix}/${CatchUpScheduleActions.ListStudentCatchUp}`,
  EditRoute: `${prefix}/${CatchUpScheduleActions.Edit}/:catchUpScheduleId`,
  Edit: `${prefix}/${CatchUpScheduleActions.Edit}`,
  Create: `${prefix}/${CatchUpScheduleActions.Create}`,
  CreateCatchUpLesson: `${prefix}/${CatchUpScheduleActions.CreateCatchUpLesson}`,
  CreateCatchUpLessonRoute: `${prefix}/${CatchUpScheduleActions.CreateCatchUpLesson}/:classLessonId`,
  Delete: `CatchUpSchedules`,
  CreateCatchUpStudent: `${prefix}/${CatchUpScheduleActions.CreateCatchUpStudent}`,
  CreateCatchUpStudentRoute: `${prefix}/${CatchUpScheduleActions.CreateCatchUpStudent}/:studentId`
};
