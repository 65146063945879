import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Divider,
  Button,
  Paper,
  DialogActions,
  Grid,
  InputBase,
  Tooltip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import authService from '../api-authorization/AuthorizeService';
import { format } from 'date-fns';

class PostStory extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      story: {
        text: ''
      },
      open: false
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  openPostStory(classId) {
    this.setState(prevState => ({
      story: {
        ...prevState.story,
        classId: classId
      }
    }));
    this.openDialog();
  }

  openDialog = () => {
    this.setState({ open: true });
  };

  closeDialog = () => {
    this.setState({ open: false });
  };

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;

    this.setState(prevState => ({
      story: {
        ...prevState.story,
        [attrName]: attrValue
      }
    }));
  };

  handleChangeFile = e => {
    const files = e.target.files;

    this.setState({ uploadFiles: files });

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = function(e) {
      this.setState({ storyPhoto: [reader.result] });
    }.bind(this);
  };

  postStory = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);

    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    const { story, uploadFiles } = this.state;
    const uploadFileName =
      format(new Date(), 'yyyyMMddHHmmss.') +
      uploadFiles[0].name.split('.').pop();
    story.caption = story.text;
    story.imageName = uploadFileName;
    //story.imageName = uploadFiles[0].name = format(new Date(), 'yyyyMMddHHmmss') + '.' + uploadFiles[0].name.split('.').pop();
    story.authorId = loggedInUser.sub;
    story.createdBy = loggedInUser.sub;

    const errMsg = 'An error occurred while posting story!';

    fetch('api/Story', {
      method: 'POST',
      body: JSON.stringify(story),
      headers: headers
    })
      .then(async response => {
        if (response.ok) {
          const newStory = await response.json();
          const data = new FormData();
          data.append('files', uploadFiles[0], uploadFileName);
          fetch(`api/Story/UploadFile/${newStory.id}`, {
            method: 'POST',
            body: data,
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
          })
            .then(response => {
              if (response.ok) {
                this.setState(prevState => ({
                  story: {
                    ...prevState.story,
                    text: ''
                  },
                  storyPhoto: null
                }));
                this.props.refreshStories();
                this.closeDialog();
              } else {
                this.props.showErrorMessage(errMsg);
                throw new Error(response.status);
              }
            })
            .catch(error => {
              this.props.showErrorMessage(errMsg);
              console.log(error);
            });
        } else {
          this.props.showErrorMessage(errMsg);
          throw new Error(response.status);
        }
      })
      .catch(error => {
        this.props.showErrorMessage(errMsg);
        console.error('Error:', error);
      });
  };

  render() {
    const { classes } = this.props;
    const { open, storyPhoto, story } = this.state;

    return (
      <Fragment>
        <Dialog
          open={open}
          onClose={this.closeDialog}
          maxWidth="sm"
          fullWidth
          className={classes.root}
        >
          <DialogTitle>
            <Typography className={classes.dialogTitleText}>
              {'Post Story'}
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={this.closeDialog}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent className={classes.dialogContent}>
            <InputBase
              fullWidth
              multiline
              className={classes.margin}
              placeholder={'Story text here...'}
              name={'storyText'}
              value={story.text}
              onChange={this.handleChange('text')}
            />
            <Paper variant="outlined" className={classes.storyPhotoBox}>
              {storyPhoto && (
                <img
                  src={storyPhoto}
                  alt="GeeO story"
                  className={classes.storyPhoto}
                />
              )}
            </Paper>
            <Paper variant="outlined" className={classes.storyTools}>
              <Grid container justify="flex-start" alignItems="center">
                <Grid item xs={3}>
                  <Typography className={classes.postToolLabel}>
                    Add to story
                  </Typography>
                </Grid>
                <Grid item xs={9} sm container>
                  <Grid item xs>
                    <InputBase
                      accept="image/*"
                      className={classes.input}
                      id="icon-button-file"
                      type="file"
                      onChange={this.handleChangeFile}
                    />
                    <label htmlFor="icon-button-file">
                      <Tooltip title="Photo" aria-label="photo">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <InsertPhotoIcon />
                        </IconButton>
                      </Tooltip>
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.btnPost}
              onClick={this.postStory}
            >
              Post
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const stylesPostStory = theme => ({
  root: {
    minHeight: '30vh'
  },
  input: {
    display: 'none'
  },
  dialogTitleText: {
    fontFamily: 'RalewayBold'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  dialogActions: {
    padding: theme.spacing(0, 2, 2, 2)
  },
  storyTools: {
    padding: theme.spacing(1, 2),
    borderRadius: 10,
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  storyToolsLabel: {
    display: 'inline-flex'
  },
  storyToolBar: {
    display: 'flex'
  },
  postToolLabel: {
    fontFamily: 'RalewayBold',
    fontSize: 14
  },
  storyPhotoBox: {
    marginTop: theme.spacing(2),
    border: 0
  },
  storyPhoto: {
    borderRadius: 10,
    width: '100%'
  },
  btnPost: {
    marginTop: theme.spacing(1),
    width: '100%'
  }
});

PostStory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesPostStory)(PostStory);
