export const MaterialActions = {
  NeedApproval: 'need-approval',
  List: 'list',
  ListByTeacher: 'by-teacher',
  Edit: 'edit',
  EditByTeacher: 'edit-by-teacher',
  Create: 'create',
  CreateByTeacher: 'create-by-teacher',
  Delete: 'delete'
};

const prefix = '/material';

export const MaterialPaths = {
  MaterialPrefix: prefix,
  List: `${prefix}/${MaterialActions.List}`,
  ListByTeacher: `${prefix}/${MaterialActions.ListByTeacher}`,
  NeedApproval: `${prefix}/${MaterialActions.NeedApproval}`,
  EditRoute: `${prefix}/${MaterialActions.Edit}/:materialId`,
  Edit: `${prefix}/${MaterialActions.Edit}`,
  EditByTeacherRoute: `${prefix}/${MaterialActions.EditByTeacher}/:materialId`,
  EditByTeacher: `${prefix}/${MaterialActions.EditByTeacher}`,
  Create: `${prefix}/${MaterialActions.Create}`,
  CreateByTeacher: `${prefix}/${MaterialActions.CreateByTeacher}`,
  Approve: `Materials/Approve`,
  Delete: `Materials`
};
