import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { StudentCourse } from './StudentCourse';
import {
  StudentCoursePaths,
  StudentCourseActions
} from './StudentCourseConstants';

export default class StudentCourseRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={StudentCoursePaths.List}
          component={StudentCourse}
          action={StudentCourseActions.List}
        />
        <PropsRoute
          path={StudentCoursePaths.EditRoute}
          component={StudentCourse}
          action={StudentCourseActions.Edit}
        />
        <PropsRoute
          path={StudentCoursePaths.CreateRoute}
          component={StudentCourse}
          action={StudentCourseActions.Create}
        />
      </Fragment>
    );
  }
}
