import { makeStyles } from '@material-ui/styles';

const useStylesSearchBox = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    verticalAlign: 'bottom'
  }
}));

export { useStylesSearchBox };
