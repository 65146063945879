import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ReorderIcon from '@material-ui/icons/Reorder';
import authService from '../../api-authorization/AuthorizeService';
import { ListLessonPlan } from './ListLessonPlan';
import { EditLessonPlan } from './EditLessonPlan';
import { LessonContent } from './LessonContent';
import { LessonPlanPaths, LessonPlanActions } from './LessonPlanConstants';
import { NormalTooltip } from '../../ui/ButtonStyles';
import { UnitPlanPaths } from '../unit//UnitPlanConstants';
import { AdminPage } from '../../ui/page/AdminPage';
import { DialogType, Dialogs } from '../../ui/popup/Dialogs';
import {
  MessageBars,
  MessageBarVariants,
  MessageType
} from '../../ui/popup/Messages';

const stylesLessons = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class LessonsComp extends Component {
  static displayName = LessonsComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.mainList = React.createRef();
    this.confirmDialog = React.createRef();
    this.messageBar = React.createRef();
    this.state = {
      levelId: this.props.match.params.levelId,
      lessonId: this.props.match.params.lessonId,
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null,
      closeConfirmDialog: (
        <Button onClick={this.closeConfirmDialog} color="primary">
          Cancel
        </Button>
      ),
      confirmDialog: (
        <Dialogs onRef={actualChild => (this.confirmDialog = actualChild)} />
      ),
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Error}
          type={MessageType.Approve}
          message={'Cannot preview!'}
          handleClose={this.handleCloseMessageBar}
        />
      )
    };
    switch (this.props.action) {
      case LessonPlanActions.List:
        this.state.pageTitle = 'Overall';
        this.state.content = (
          <ListLessonPlan
            levelId={this.state.levelId}
            onRef={actualChild => (this.mainList = actualChild)}
          />
        );
        break;
      case LessonPlanActions.Create:
        this.state.pageTitle = 'Create Lesson Plan';
        this.state.content = (
          <EditLessonPlan
            onRef={actualChild => (this.child = actualChild)}
            action={this.state.action}
            levelId={this.state.levelId}
          />
        );
        break;
      case LessonPlanActions.Edit:
        this.state.pageTitle = 'Edit Lesson Plan';
        this.state.content = (
          <EditLessonPlan
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            lessonId={this.state.lessonId}
          />
        );
        break;
      case LessonPlanActions.LessonContent:
        this.state.pageTitle = 'Lesson Content';
        this.state.content = (
          <LessonContent
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            lessonId={this.state.lessonId}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  handleBackToParentList = () => {
    this.mainList.backToList();
  };

  handleCloseMessageBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };
  closeConfirmDialog = () => {
    this.confirmDialog.closeDialog();
  };

  showConfirmDialog = flag => {
    const commands = (
      <Fragment>
        {this.state.closeConfirmDialog}
        <Button onClick={this.reorderLessonPlan} color="primary">
          Reorder
        </Button>
      </Fragment>
    );
    this.confirmDialog.showDialogWithCmds(
      DialogType.ReorderLessonPlan,
      commands
    );
  };

  reorderLessonPlan = async () => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    fetch(`api/ClassLesson/ResetOrderClassLessons/${this.state.levelId}`, {
      method: 'POST',
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.messageBar.showMessage(
            MessageBarVariants.Success,
            MessageType.Message,
            'Reorder lesson plan successfully!'
          );
          this.mainList.refreshLessonPlans();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    this.closeConfirmDialog();
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === 'list' ? (
        <Fragment>
          <Fab
            aria-label="Add"
            color="primary"
            className={classes.fab}
            onClick={() =>
              this.redirectTo(`${LessonPlanPaths.Create}/${this.state.levelId}`)
            }
          >
            <AddIcon />
          </Fab>
          <NormalTooltip title="Reorder lesson plans">
            <Fab
              aria-label="Reorder"
              color="secondary"
              className={classes.fab}
              onClick={() => this.showConfirmDialog(false)}
            >
              <ReorderIcon />
            </Fab>
          </NormalTooltip>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleBackToParentList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      ) : (
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.handleChildSubmit}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleChildBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
          {this.props.action === 'edit' && (
            <NormalTooltip title="Lesson plan">
              <Fab
                aria-label="Lesson plan"
                color="secondary"
                className={classes.fab}
                onClick={() =>
                  this.redirectTo(
                    `${UnitPlanPaths.List}/${this.state.lessonId}`
                  )
                }
              >
                <ListAltIcon />
              </Fab>
            </NormalTooltip>
          )}
        </Fragment>
      );

    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
        {this.state.confirmDialog}
        {this.state.messageBar}
      </Fragment>
    );
  }
}

LessonsComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Lessons = withStyles(stylesLessons)(LessonsComp);
