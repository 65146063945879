export const ClassRoomActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete'
};

const prefix = '/class-rooms';

export const ClassRoomPaths = {
  ClassRoomPrefix: prefix,
  List: `${prefix}/${ClassRoomActions.List}`,
  EditRoute: `${prefix}/${ClassRoomActions.Edit}/:classRoomId`,
  Edit: `${prefix}/${ClassRoomActions.Edit}`,
  Create: `${prefix}/${ClassRoomActions.Create}`,
  Delete: `ClassRooms`
};
