import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Toolbar, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';

const useToolbarStyles = theme => ({
  root: {
    padding: theme.spacing(0),
    minHeight: 50
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    display: 'flex',
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(grey[500], 0.15),
    '&:hover': {
      backgroundColor: fade(grey[500], 0.25)
    },
    width: '100%',
    height: 35,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200
      }
    }
  },
  noOutline: {
    outline: 'none',
    margin: theme.spacing(1)
  }
});

const ClassDataSearchToolbar = props => {
  const { title, filterText, handleChange, classes } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: false
      })}
    >
      <div className={classes.title}>
        {title && <Typography variant="h6">{title}</Typography>}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={filterText}
            onChange={handleChange('filterText')}
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ 'aria-label': 'Search' }}
          />
        </div>
      </div>
    </Toolbar>
  );
};

ClassDataSearchToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useToolbarStyles)(ClassDataSearchToolbar);
