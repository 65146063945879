import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialTable from 'material-table';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';

const stylesMyClassStudentLog = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  dialogContent: {
    padding: theme.spacing(2, 2, 1, 2)
  }
});

class MyClassStudentLog extends Component {
  static displayName = MyClassStudentLog.name;

  constructor(...args) {
    super(...args);
    this.state = {
      open: false,
      classLessonId: null,
      lessonLog: null,
      classId: null,
      savingLessonLogData: false,
      loading: true,
      studentLogData: null,
      data: [],
      title: 'My Class Report'
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  populateMyLessonLogData = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    if (this.state.studentLogData === null) {
      await this.initClassStudentLogData(token);
    }
    const response = await fetch(
      `api/TeacherLessonLog/GetMyClassStudentLog/${loggedInUser.sub}/${this.state.classLessonId}/${this.state.classId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({ studentLogData: data, loading: false });
  };

  initClassStudentLogData = async token => {
    const { classLessonId, lessonLog } = this.state;
    await fetch(
      `api/TeacherLessonLog/InitStudentLog/${classLessonId}/${lessonLog.id}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
  };

  saveLessonLogData = async lessonLogDataNew => {
    if (this.state.savingLessonLogData) {
      return;
    }
    this.setState({ savingLessonLogData: true });
    const token = await authService.getAccessToken();
    //save lesson log
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const formBody = JSON.stringify(lessonLogDataNew);
    fetch(`api/TeacherLessonLog/SaveStudentLessonLog/${lessonLogDataNew.id}`, {
      method: 'PUT',
      body: formBody,
      headers: headers
    });
    this.setState({ savingLessonLogData: false });
  };

  addStudentAbsenceNotify = async () => {
    const token = await authService.getAccessToken();
    const { lessonLog } = this.state;
    fetch(`api/TeacherLessonLog/AddStudentAbsenceNotify/${lessonLog.id}`, {
      method: 'POST',
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
  };

  openClassStudentReport(classLessonId, lessonLog, classId) {
    this.setState(
      {
        classLessonId,
        lessonLog,
        loading: true,
        studentLogData: null,
        classId: classId
      },
      this.populateMyLessonLogData
    );
    this.openDialog();
  }

  setOpen = openState => {
    this.setState({ open: openState });
  };

  openDialog = () => {
    this.setState({ open: true });
  };

  closeDialog = () => {
    this.setState({ open: false });
    this.addStudentAbsenceNotify();
    if (this.props.handleParentAction) this.props.handleParentAction();
  };

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        open={this.state.open}
        onClose={this.closeDialog}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent className={classes.dialogContent}>
          {this.state.loading ? (
            <Loading />
          ) : (
            <MaterialTable
              title={this.state.title}
              columns={[
                { title: '#', field: 'sortOrder', editable: 'never' },
                { title: 'Name', field: 'studentName', editable: 'never' },
                {
                  title: 'English Name',
                  field: 'englishName',
                  editable: 'never'
                },
                {
                  title: 'Present',
                  field: 'present',
                  render: rowData => (
                    <Checkbox checked={rowData.present} disabled />
                  ),
                  editComponent: props => (
                    <Checkbox
                      checked={props.value}
                      onChange={e => props.onChange(e.target.checked)}
                    />
                  )
                },
                { title: 'Star Score', field: 'starScore', type: 'numeric' },
                { title: 'Note', field: 'note' }
              ]}
              data={this.state.studentLogData}
              options={{
                filtering: false,
                actionsColumnIndex: -1,
                actionsCellStyle: {
                  color: 'rgba(0, 0, 0, 0.38)',
                  fontSize: 16
                }
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      const studentLogData = [...this.state.studentLogData];
                      studentLogData.forEach(e => {
                        if (e.id === oldData.id) {
                          studentLogData[studentLogData.indexOf(e)] = newData;
                        }
                      });
                      this.setState({ ...this.state, studentLogData });
                      this.saveLessonLogData(newData);
                    }, 600);
                  })
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

MyClassStudentLog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesMyClassStudentLog)(MyClassStudentLog);
