import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../../common/route/PropsRoute';
import { User } from './User';
import { UserPaths, UserActions } from './UserConstants';

export default class UserRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={UserPaths.List}
          component={User}
          action={UserActions.List}
        />
        <PropsRoute
          path={UserPaths.EditRoute}
          component={User}
          action={UserActions.Edit}
        />
        <PropsRoute
          path={UserPaths.Create}
          component={User}
          action={UserActions.Create}
        />
      </Fragment>
    );
  }
}
