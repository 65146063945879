import React, { Component, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DropDragDataTable from '../ui/table/DropDragDataTable';
import ListItem from '@material-ui/core/ListItem';
import { ListMaterialSelect } from './ListMaterialSelect';
import { Loading } from '../ui/Loading';
import MyClassLessonInfo from '../my-class/MyClassLessonInfo';
import PropTypes from 'prop-types';
import { TeacherLessonActions } from './TeacherLessonConstants';
import TextField from '@material-ui/core/TextField';
import { UnitPlanInfo } from '../common/UnitPlanInfo';
import authService from '../api-authorization/AuthorizeService';
import { withStyles } from '@material-ui/core/styles';

const stylesViewTeacherUnitPlan = theme => ({
  dialogContent: {
    padding: theme.spacing(2)
  }
});

class ViewTeacherUnitPlanComp extends Component {
  static displayName = ViewTeacherUnitPlanComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      Data: [],
      loading: true,
      classLessonId: this.props.classLessonId,
      openDialog: false,
      isEditNote: false,
      unitPlanId: '',
      unitPlanTeacherId: 'NA',
      nameAction: '',
      rowSelected: {},
      classLesson: {},
      isShowBtnSave: true
    };
  }

  componentDidMount() {
    this.populateDataInit();
  }

  async populateDataInit() {
    const token = await authService.getAccessToken();
    const response = await fetch(
      `api/TeacherLessonUnit/unit-plan/${this.props.classLessonId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    const responseClassLeson = await fetch(
      `api/MyClasses/GetMyClassLesson/${this.props.classLessonId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const classLesson = await responseClassLeson.json();
    for (let item in data) {
      if (data[item].description)
        data[item].description = data[item].description.replace(
          /(?:\r\n|\r|\n)/g,
          '<br/>'
        );
      if (data[item].materials)
        data[item].materials = data[item].materials.replace(
          /(?:\r\n|\r|\n)/g,
          '<br/>'
        );
      if (data[item].teacherActivities)
        data[item].teacherActivities = data[item].teacherActivities.replace(
          /(?:\r\n|\r|\n)/g,
          '<br/>'
        );
      if (data[item].learningOutcome)
        data[item].learningOutcome = data[item].learningOutcome.replace(
          /(?:\r\n|\r|\n)/g,
          '<br/>'
        );
      if (data[item].note)
        data[item].note = data[item].note.replace(/(?:\r\n|\r|\n)/g, '<br/>');
    }
    this.setState({ Data: data, loading: false, classLesson });
    this.child.setData(this.state.Data);
  }
  reloadDataInit() {
    this.setState({ loading: true }, this.populateDataInit);
  }

  openDialog = isEditNote => event => {
    this.setState({ openDialog: true, isEditNote: isEditNote });
  };
  closeDialog = () => {
    this.setState({ openDialog: false });
  };
  showPopup = (row, nameAct) => {
    let isShowBtnSave =
      row.materialId === 'NA' || row.hasTeacherLessonUnit ? true : false;
    this.setState({
      openDialog: true,
      nameAction: nameAct,
      rowSelected: row,
      isShowBtnSave: isShowBtnSave
    });
  };
  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      rowSelected: {
        ...prevState.rowSelected,
        [attrName]: attrValue
      }
    }));
  };
  callbackFromParentGetValRadioBtn = e => {
    var row = this.state.rowSelected;
    row.materialId = e;
    this.setState({ rowSelected: row });
  };

  viewWorksheet = () => {
    const { classLesson } = this.state;
    let viewUrl = `/api/LessonPlans/GetForView/${
      classLesson.lessonPlan.id
    }/${encodeURI(classLesson.lessonPlan.worksheetFileName)}`;
    window.open(viewUrl, '_blank');
  };

  // callBackValueCategoty = e => {
  //   let strCategory = JSON.stringify(e);
  //   var row = this.state.rowSelected;
  //   row.category = strCategory;
  //   this.setState({ rowSelected: row });
  // };

  saveDialog = async () => {
    const currentUser = await authService.getUser();
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    var row = this.state.rowSelected;
    row.nameAct = this.state.nameAction;
    row.teacherId = currentUser.sub;
    row.classLessonId = this.state.classLessonId;
    fetch('api/TeacherLessonUnit/unit-plan/save', {
      method: 'POST',
      body: JSON.stringify(row),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.reloadDataInit();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    this.setState({ rowSelected: {} });
    this.closeDialog();
  };

  callBackGetListSort = (data, objUpdate) => {
    const { token } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    var lstSortOrderLessonPlan = [];
    data.forEach((x, index) => {
      let objUpdate = {
        id: x.unitPlanId,
        sortOrder: ++index
      };
      lstSortOrderLessonPlan.push(objUpdate);
    });
    fetch(`api/UnitPlans/UpdateCreatedTimeByClass`, {
      method: 'POST',
      body: JSON.stringify(lstSortOrderLessonPlan),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          //this.refreshUnitPlans();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  renderDatagrid() {
    const cols = [
      {
        name: 'time',
        header: 'Time (mins)',
        align: 'center',
        width: '60'
      },
      {
        name: 'procedures',
        header: 'Procedures',
        align: 'left',
        shorteningContent: true,
        width: '120',
        indexCut: 65
      },
      {
        name: 'description',
        header: 'Description',
        align: 'left',
        shorteningContent: true,
        width: '200',
        indexCut: 30
      },
      {
        name: 'materials',
        header: 'Materials',
        align: 'left',
        shorteningContent: true,
        width: '140',
        indexCut: 65
      },
      {
        name: 'teacherActivities',
        header: "Teachers' activities",
        align: 'left',
        shorteningContent: true,
        width: '200',
        indexCut: 65
      },
      {
        name: 'learningOutcome',
        header: "Students' learning outcome",
        align: 'left',
        shorteningContent: true,
        width: '200',
        indexCut: 65
      },
      {
        name: 'note',
        header: 'Note',
        align: 'left',
        shorteningContent: true,
        width: '150',
        indexCut: 65
      },
      {
        name: 'materialsTeacher',
        header: 'Library Material',
        width: '150',
        align: 'left'
      }
    ];
    const actionsEdit = [
      {
        name: 'editMaterial',
        tooltip: 'Set material',
        icon: 'assignment'
      },
      {
        name: 'editNote',
        tooltip: 'Set note',
        icon: 'note'
      },
      {
        name: 'editTime',
        tooltip: 'Set Time',
        icon: 'av_timer'
      }
    ];
    const actions =
      this.props.action === TeacherLessonActions.ViewUnitPlan
        ? actionsEdit
        : null;
    return (
      <Fragment>
        <MyClassLessonInfo
          classLesson={this.state.classLesson}
          reportDateTime={false}
          teachers={true}
        />
        <DropDragDataTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.unitPlans}
          cols={cols}
          actions={actions}
          actHeadCmd={
            this.state.classLesson.lessonPlan.worksheetFileName
              ? {
                  cmd: this.viewWorksheet,
                  icon: <DescriptionOutlinedIcon />,
                  color: 'secondary',
                  tooltip: 'View Worksheet'
                }
              : null
          }
          isShowPopup="true"
          callbackGetIdShowPopup={this.showPopup}
          callBackGetListSort={this.callBackGetListSort}
        />
      </Fragment>
    );
  }
  render() {
    const { classes } = this.props;
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();
    return (
      <Fragment>
        {contents}
        <Dialog
          open={this.state.openDialog}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth
        >
          <DialogContent>
            <UnitPlanInfo dataUnit={this.state.rowSelected} />
            {this.state.nameAction === 'editNote' ? (
              <ListItem className={classes.listItem}>
                <TextField
                  id="note"
                  fullWidth
                  multiline
                  style={{ minWidth: 700 }}
                  rows="8"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  className={classes.margin}
                  label="Note"
                  placeholder="Note"
                  name="editNote"
                  value={this.state.rowSelected.note}
                  onChange={this.handleChange('note')}
                />
              </ListItem>
            ) : null}
            {this.state.nameAction === 'editMaterial' ? (
              <ListMaterialSelect
                materialId={this.state.rowSelected.materialId}
                category={this.state.rowSelected.category}
                lessonPlanUnitId={this.state.rowSelected.unitPlanId}
                callbackFromParentGetValRadioBtn={
                  this.callbackFromParentGetValRadioBtn
                }
                isDisableFilter={true}
                isUnitPlan={false}
              />
            ) : null}
            {this.state.nameAction === 'editTime' ? (
              <ListItem className={classes.listItem}>
                <TextField
                  id="time"
                  fullWidth
                  style={{ minWidth: 700 }}
                  rows="8"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  type="number"
                  className={classes.margin}
                  label="Time"
                  placeholder="Time"
                  name="time"
                  value={this.state.rowSelected.realTime}
                  onChange={this.handleChange('time')}
                />
              </ListItem>
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
            {this.state.isShowBtnSave ||
            this.state.nameAction === 'editTime' ? (
              <Button onClick={this.saveDialog} color="primary">
                Save
              </Button>
            ) : (
              ''
            )}
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

ViewTeacherUnitPlanComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ViewTeacherUnitPlan = withStyles(stylesViewTeacherUnitPlan)(
  ViewTeacherUnitPlanComp
);
