import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AdminPage } from '../ui/page/AdminPage';
import { ListProposal } from './ListProposal';
import AssignStudent from './AssignStudent';
import SelectStudentProposal from './SelectStudentProposal';
import { ProposalActions } from './ProposalConstants';

class ProposalComp extends Component {
  static displayName = ProposalComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      classId: this.props.match.params.classId,
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case ProposalActions.List:
        this.state.pageTitle = 'Class Proposal';
        this.state.content = <ListProposal />;
        break;
      case ProposalActions.Edit:
        this.state.pageTitle = 'Class Proposal - New Student / Payment';
        this.state.content = (
          <AssignStudent
            classId={this.state.classId}
            onRef={actualChild => (this.child = actualChild)}
          />
        );
        break;
      case ProposalActions.SelectStudent:
        this.state.pageTitle = 'Class Proposal - Select Student';
        this.state.content = (
          <SelectStudentProposal
            onRef={actualChild => (this.child = actualChild)}
            classId={this.state.classId}
            parentEl={this}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  render() {
    return (
      <Fragment>
        <AdminPage title={this.state.pageTitle} content={this.state.content} />
        {this.state.redirect}
      </Fragment>
    );
  }
}

const stylesProposal = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

ProposalComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Proposal = withStyles(stylesProposal)(ProposalComp);
