import React, { Component, Fragment } from 'react';

import CommonSearchToolbar from '../../ui/table/CommonSearchToolbar';
import CustomizedTable from '../../ui/table/CustomizedTable';
import { Loading } from '../../ui/Loading';
import PropTypes from 'prop-types';
import { fetchHelper } from '../../../helpers/fetch-helper';
import { withStyles } from '@material-ui/core/styles';

const stylesLessonAndTeacher = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    overflowX: 'auto'
  },
  cell: {
    padding: theme.spacing(1, 0, 1, 2)
  }
});

class LessonAndTeacherSelectComp extends Component {
  static displayName = LessonAndTeacherSelectComp.name;

  constructor(...args) {
    super(...args);
    this.state = {
      data: [],
      lstSelected: [],
      isTeacher: this.props.isTeacher,
      lstLessontId: this.props.lstLessontId,
      lstTeacherId: this.props.lstTeacherId,
      classId: this.props.classId,
      loading: true
    };
  }
  componentDidMount() {
    this.populateLessonAndTeacherData();
  }

  async populateLessonAndTeacherData() {
    const data = this.state.isTeacher
      ? await fetchHelper.post(
          'api/ClassTeacher/list-filter',
          this.state.lstTeacherId
        )
      : await fetchHelper.post(
          `api/ClassLesson/list-filter/${this.state.classId}`,
          this.state.lstLessontId
        );
    this.setState({ data, loading: false });
  }

  callbackValueCheckboxTabl = e => {
    this.props.callbackFromListLessonTeacher(e);
  };

  handleChangeSearchTerm = filterText => {
    this.setChildData(filterText);
  };

  setChildData = filterText => {
    const { data } = this.state;
    const searchTerm = filterText.toLowerCase();
    let filteredRows = data.filter(
      item =>
        item.userName.toLowerCase().indexOf(searchTerm) > -1 ||
        item.firstName.toLowerCase().indexOf(searchTerm) > -1 ||
        item.lastName.toLowerCase().indexOf(searchTerm) > -1
    );
    this.child1.child.setData(filteredRows);
  };

  render() {
    const { data } = this.state;
    const colsLesson = [
      {
        name: 'level',
        header: 'Level',
        align: 'left',
        obj: true,
        objName: 'name'
      },
      {
        name: 'lesson',
        header: 'Lesson',
        align: 'left'
      },
      { name: 'subject', header: 'Subject', align: 'left' },
      { name: 'content', header: 'Content', align: 'left' },
      { name: 'tb', header: 'Tb', align: 'left' }
    ];
    const colsTeacher = [
      {
        name: 'userName',
        header: 'User Name',
        align: 'left'
      },
      { name: 'employeeCode', header: 'Employee Code', align: 'left' },
      { name: 'firstName', header: 'First Name', align: 'left' },
      { name: 'lastName', header: 'Last Name', align: 'left' },
      { name: 'phoneNumber', header: 'Phone', align: 'left' }
    ];
    let contents = this.state.loading ? (
      <Loading />
    ) : this.state.isTeacher ? (
      <>
        <CommonSearchToolbar handleChange={this.handleChangeSearchTerm} />
        <CustomizedTable
          onRef={actualChild => (this.child1 = actualChild)}
          rows={data}
          cols={colsTeacher}
          isShowCheckbox={true}
          callbackFromParent={this.callbackValueCheckboxTabl}
          isPaging={true}
        />
      </>
    ) : (
      <CustomizedTable
        onRef={actualChild => (this.child2 = actualChild)}
        rows={data}
        cols={colsLesson}
        isShowCheckbox={true}
        callbackFromParent={this.callbackValueCheckboxTabl}
        isPaging={true}
      />
    );
    return <Fragment>{contents}</Fragment>;
  }
}

LessonAndTeacherSelectComp.propTypes = {
  classes: PropTypes.object.isRequired,
  callbackFromListLessonTeacher: PropTypes.func,
  isTeacher: PropTypes.bool,
  lstLessontId: PropTypes.array,
  lstTeacherId: PropTypes.array,
  classId: PropTypes.string
};

export const LessonAndTeacherSelect = withStyles(stylesLessonAndTeacher)(
  LessonAndTeacherSelectComp
);
