import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../../common/route/PropsRoute';
import { Role } from './Role';
import { RolePaths, RoleActions } from './RoleConstants';

export default class RoleRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={RolePaths.List}
          component={Role}
          action={RoleActions.List}
        />
        <PropsRoute
          path={RolePaths.EditRoute}
          component={Role}
          action={RoleActions.Edit}
        />
        <PropsRoute
          path={RolePaths.Create}
          component={Role}
          action={RoleActions.Create}
        />
      </Fragment>
    );
  }
}
