import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AdminPage } from '../ui/page/AdminPage';
import { SettingActions } from './SettingConstants';
import ClassLessonSettings from './views/ClassLessonSettings';
import Holidays from './views/Holidays';
import ResetUsersPwd from './views/ResetUsersPwd';

const stylesSettings = () => ({});

class Settings extends Component {
  render() {
    let pageTitle = '';
    let content = null;
    switch (this.props.action) {
      case SettingActions.List:
        pageTitle = 'Settings';
        content = <ClassLessonSettings />;
        break;
      case SettingActions.Holidays:
        pageTitle = 'Holidays & Days Off';
        content = <Holidays />;
        break;
      case SettingActions.ResetUsersPwd:
        pageTitle = 'Reset Users Password';
        content = <ResetUsersPwd />;
        break;
      default:
    }
    return <AdminPage title={pageTitle} content={content} />;
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesSettings)(Settings);
