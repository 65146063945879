import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../../common/route/PropsRoute';
import { LessonAndTeacher } from './LessonAndTeacher';
import {
  LessonAndTeacherPaths,
  LessonAndTeacherActions
} from './LessonAndTeacherConstants';

export default class LessonAndTeacherRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={LessonAndTeacherPaths.ListRoute}
          component={LessonAndTeacher}
          action={LessonAndTeacherActions.List}
        />
      </Fragment>
    );
  }
}
