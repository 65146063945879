export const stylesAcademicInfo = theme => ({
  root: {
    width: '100%'
  },
  cardActions: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2)
    }
  },
  section: {
    padding: theme.spacing(0.25, 2),
    borderBottom: '1px #ddd solid',
    height: 53
  },
  sectionHeader: {
    margin: 'auto 0'
  },
  sectionTitle: {
    fontSize: 17
  },
  sectionToolBar: {
    padding: theme.spacing(0.75, 1)
  },
  actionButton: {
    margin: theme.spacing(0.5)
  },
  extendedFab: {
    fontSize: 12
  },
  extFabLabel: {
    marginRight: theme.spacing(1)
  },
  actionIconFa: {
    margin: theme.spacing(0, 1),
    height: 20
  },
  yellowBtn: {
    backgroundColor: '#efe934',
    padding: theme.spacing(0, 1.25, 0, 1),
    '&:hover': {
      backgroundColor: '#efe934'
    }
  },
  blueBtn: {
    backgroundColor: '#34c3ef',
    padding: theme.spacing(0, 1.25, 0, 1),
    '&:hover': {
      backgroundColor: '#34c3ef'
    }
  },
  sectionMarginTop: {
    marginTop: '8px'
  },
  stdAttentionReportBox: {
    height: 330,
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 0, 0.5, 0)
  },
  chartView: {
    height: '100%',
    padding: theme.spacing(1, 0, 5, 0)
  },
  chartNormalView: {
    width: '100%'
  },
  chartWideView: {
    width: 2200
  },
  barChart: {
    height: '95%',
    overflow: 'visible !important'
  },
  stdReportBox: {
    margin: theme.spacing(0, 2)
  },
  stdBox: {
    padding: theme.spacing(1.5, 4)
  },
  stdBoxSeparator: {
    padding: theme.spacing(0, 4)
  },
  stdHeadline: {
    padding: theme.spacing(0, 2),
    display: 'flex'
  },
  stdSubtitle: {
    flexGrow: 0
  },
  stdSubtitleHr: {
    flexGrow: 1,
    maxWidth: '100%',
    flexBasis: 0,
    paddingLeft: theme.spacing(1),
    marginTop: '11px'
  },
  stdCellFlex: {
    display: 'flex'
  },
  stdFloatRight: {
    marginLeft: 'auto'
  },
  stdCell: {
    display: 'block',
    minWidth: 180,
    minHeight: 20
  },
  stdCellFixCenter: {
    minWidth: 100,
    textAlign: 'center',
    margin: theme.spacing(0.25, 0)
  },
  stdLineCell: {
    display: 'block',
    borderBottom: '1px #ddd solid',
    height: 1
  },
  stdMiddleLine: {
    marginTop: '3px'
  },
  stdBaseLine: {
    marginTop: '6px'
  },
  stdBoxLabel: {
    fontSize: 14,
    display: 'inline-block',
    marginRight: theme.spacing(1)
  },
  stdBoxLabelMargin: {
    marginRight: theme.spacing(3)
  },
  stdBoxRedText: {
    color: theme.palette.primary.light
  },
  paymentList: {
    maxHeight: 125,
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  box: {
    margin: 2,
    padding: '2px 0px 4px 6px',
    backgroundColor: '#f5f5f5',
    minHeight: '28px'
  },
  button: {
    margin: '2px 0px 0px 3px',
    padding: 5
  },
  reportClassPadding: {
    paddingRight: theme.spacing(3)
  },
  reportClassSelect: {
    marginTop: '-6px',
    padding: theme.spacing(0, 0.75)
  }
});
