  import React, {useState, useEffect} from 'react';
  import PropTypes from 'prop-types';
  import {
    Select,
    MenuItem,
    FormControl,
    FormControlLabel
  } from '@material-ui/core';

  const WorkLogResponsible = props => {
    const { handleChange, options, teacher, workLog, disabled } = props;
    // const [selectedEffortType, setSelectedEffortType] = useState(workLog?.effortType || '');
    
    // useEffect(() => {
    //   if (options.length > 0 && workLog.effortType) {
    //     // Set the default value if options are available
    //     setSelectedEffortType(workLog.effortType);
    //   }
    // }, [options]);

    
    const onChange = e => {
      const value = e.target.value;
      handleChange({
        ...workLog,
        [e.target.name]: value,
        score: options.find(x => x.value === value)?.score
      });
    };

    return (
      <FormControl>
        <FormControlLabel
          labelPlacement="start"
          style={{  width: 500, justifyContent: 'space-between' }}
          control={
            <Select
  style={{ minWidth: 230 }}
  name="effortType" 
  value={workLog?.effortType}
  onChange={onChange}
  disabled={disabled}
>
  {options
    .filter(x => 
      teacher?.isPrimary === undefined 
        ? true 
        : teacher.isPrimary && !['co_teacher'].includes(workLog?.effortType)
          ? !['co_teacher'].includes(x.value)
          : !['main_full', 'main_teacher'].includes(x.value)
    )
    ?.map(e => (
      <MenuItem value={e.value} key={e.value}>
        {e.label}
      </MenuItem>
    ))}
</Select>

          }
          label={teacher?.alias || teacher?.fullName}
        />
      </FormControl>
    );
  };

  WorkLogResponsible.propTypes = {
    workLog: PropTypes.object,
    options: PropTypes.array,
    handleChange: PropTypes.func,
    teacher: PropTypes.object,
    disabled: PropTypes.bool
  };

  export default WorkLogResponsible;
