import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import CommonDataTable from '../../ui/table/CommonDataTable';
import { Loading } from '../../ui/Loading';
import { ClassCoursePaths } from '../ClassCourseConstants';
import ClassCourseInfo from '../ClassCourseInfo';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab
} from '@material-ui/core';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import ClassDataSearchToolbar from './ClassDataSearchToolbar';
import { StudentPaths } from '../../student/StudentConstans';
import ViewExamResultDialog from './ViewExamResultDialog';
import ChangeStudentTypePopup from './ChangeStudentTypePopup';
import { AcademicPaths } from '../../academic-manage/AcademicConstants';

const stylesListClassData = () => ({});

class ListClassDataComp extends Component {
  constructor(...args) {
    super(...args);
    this.examResultDialog = React.createRef();
    this.changeStudentTypePopup = React.createRef();
    this.child = React.createRef();
    this.child1 = React.createRef();
    this.state = {
      classData: [],
      classCourse: {},
      classId: this.props.classId,
      redirect: null,
      loading: true,
      classStudent: [],
      openDialog: false,
      filterText: ''
    };
    this._isMounted = false;
    this.selectedStudents = [];
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.onRef(this);
    this.populateClassData();
  }

  populateClassData = async () => {
    const token = await authService.getAccessToken();
    const { classId } = this.state;
    const [respClassCourse, respClassData] = await Promise.all([
      fetch(`api/ClassCourses/${classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/ClassStudents/GetStudentByClass/${classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [dataClassCourse, classData] = await Promise.all([
      respClassCourse.json(),
      respClassData.json()
    ]);
    classData.forEach((element, idx) => {
      element.sortOrder = idx + 1;
    });
    this.setState({
      classCourse: dataClassCourse,
      classData,
      loading: false
    });
  };

  refreshClassData = async () => {
    await this.populateClassData();
    this.child.setData(this.state.classData);
  };

  backToList() {
    const { classCourse } = this.state;
    this.redirectTo(`${ClassCoursePaths.List}/${classCourse.level.id}`);
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  openDialog = () => {
    this.setState({ openDialog: true });
  };

  closeDialog = () => {
    this.setState({ openDialog: false });
  };

  addStudent = async () => {
    this.openDialog();
    this.populateClassStudent();
  };

  populateClassStudent = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch(`api/ClassStudents/GetFreeStudents`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const classStudent = await response.json();
    this.setState({ classStudent });
    this.child1.setData(classStudent);
  };

  handleSelectData = async selectedStudents => {
    //update selected students from dialog
    this.selectedStudents = selectedStudents;
  };

  saveDataSelected = async () => {
    const token = authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    await fetch(`api/ClassStudents/AddStudent/` + this.state.classId, {
      method: 'POST',
      body: JSON.stringify(this.selectedStudents),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.refreshClassData();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    this.closeDialog();
  };

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(
      {
        [attrName]: attrValue
      },
      this.setChildDataPopup
    );
  };

  setChildDataPopup = () => {
    const { filterText, classStudent } = this.state;
    const searchTerm = filterText.toLowerCase();
    const filteredRows = classStudent.filter(e => {
      const itemText = (
        e.studentName +
        e.englishName +
        e.fatherName +
        e.motherName +
        e.studentTypeText
      ).toLowerCase();
      return itemText.indexOf(searchTerm) > -1;
    });
    this.child1.setData(filteredRows);
  };

  showExamResults = studentInfo => {
    this.examResultDialog.openExamResult(this.state.classCourse, studentInfo);
  };

  showChangeStudentType = (studentInfo, el) => {
    this.changeStudentTypePopup.popup(studentInfo, el);
  };

  suspendAllStudent = async () => {
    const { classData } = this.state;
    const studentType = 4;
    try {
      const token = authService.getAccessToken();
      await Promise.all(
        classData.map(async student => {
          await fetch(
            `api/ClassStudents/UpdateStudentType/${student.id}/${studentType}`,
            {
              method: 'PUT',
              headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
          );
        })
      );
    } catch (error) {
      console.error('Error:', error);
    } finally {
      this.refreshClassData();
    }
  };

  renderDatagrid() {
    const cols = [
      { name: 'sortOrder', header: '#', width: '60', align: 'center' },
      {
        name: 'studentName',
        header: 'Name',
        align: 'left'
      },
      {
        name: 'englishName',
        header: 'English name',
        align: 'left'
      },
      { name: 'fatherName', header: 'Father Name', align: 'left' },
      { name: 'fatherPhone', header: 'Father Phone', align: 'left' },
      { name: 'motherName', header: 'Mother Name', align: 'left' },
      { name: 'motherPhone', header: 'Mother Phone', align: 'left' },
      { name: 'startTimeFormat', header: 'Start Time', align: 'left' },
      { name: 'endTimeFormat', header: 'End Time', align: 'left' },
      { name: 'classType', header: 'Type', align: 'left' }
    ];
    const actions = [
      {
        name: 'detail',
        tooltip: 'View info',
        url: `${AcademicPaths.Info}`,
        icon_fa: faAddressCard
      },
      {
        name: 'callback',
        tooltip: 'Exam results',
        callBackFn: this.showExamResults,
        icon: 'assignment_turned_in'
      },
      {
        name: 'callback',
        tooltip: 'Change type',
        callBackFn: this.showChangeStudentType,
        icon: 'how_to_reg'
      },
      {
        name: 'detail',
        tooltip: 'Learning outcomes',
        url: `${StudentPaths.ViewLearningOutcome}`,
        icon: 'folder_open'
      },
      {
        name: 'delete',
        tooltip: 'Remove student',
        url: `ClassStudents`,
        icon: 'delete_icon'
      }
    ];

    const colsPopupStudent = [
      { name: 'studentName', header: 'Name', align: 'left' },
      { name: 'englishName', header: 'English Name', align: 'left' },
      { name: 'birthdayFormat', header: 'Birthday', align: 'left' },
      { name: 'fatherName', header: 'Father Name', align: 'left' },
      { name: 'motherName', header: 'Mother Name', align: 'left' },
      { name: 'studentTypeText', header: 'Type', align: 'left' }
    ];

    return (
      <Fragment>
        <div style={{ display: 'flex', justifyContent: 'end', margin: 16 }}>
          <Fab
            variant="extended"
            size="medium"
            color="secondary"
            aria-label="add"
            style={{
              width: 130,
              height: 36,
              marginLeft: 16,
              marginRight: 8
            }}
            onClick={this.suspendAllStudent}
          >
            Suspend All
          </Fab>
        </div>
        <ClassCourseInfo classCourse={this.state.classCourse} />
        <CommonDataTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.classData}
          cols={cols}
          actions={actions}
          eventReloadData={this.refreshClassData}
          isPaging={true}
        />

        <Dialog
          open={this.state.openDialog}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth
        >
          <DialogContent>
            <ClassDataSearchToolbar
              title="Add student to class"
              filterText={this.state.filterText}
              handleChange={this.handleChange}
            />
            <CommonDataTable
              onRef={actualChild => (this.child1 = actualChild)}
              rows={this.state.classStudent}
              cols={colsPopupStudent}
              isPaging={true}
              rowsPerPage={10}
              actions={null}
              isShowCheckbox={true}
              callbackFromParent={this.handleSelectData}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.saveDataSelected} color="secondary">
              Save
            </Button>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return (
      <Fragment>
        {contents}
        <ViewExamResultDialog
          onRef={actualChild => (this.examResultDialog = actualChild)}
        />
        <ChangeStudentTypePopup
          onRef={actualChild => (this.changeStudentTypePopup = actualChild)}
          refreshList={this.refreshClassData}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

ListClassDataComp.propTypes = {
  classes: PropTypes.object.isRequired,
  classId: PropTypes.string.isRequired,
  onRef: PropTypes.func
};

export const ListClassData = withStyles(stylesListClassData)(ListClassDataComp);
