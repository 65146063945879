import { Fab, Toolbar } from '@material-ui/core';
import {
  MessageBarText,
  MessageBarVariants,
  MessageBars,
  MessageType
} from '../../ui/popup/Messages';
import React, { Component, Fragment } from 'react';
import { mdiApplicationImport, mdiFileImportOutline } from '@mdi/js';

import CustomizedTable from '../../ui/table/CustomizedTable';
import Icon from '@mdi/react';
import { LessonPlanPaths } from '../lessons/LessonPlanConstants';
import { Loading } from '../../ui/Loading';
import { NormalTooltip } from '../../ui/ButtonStyles';
import PropTypes from 'prop-types';
import { Roles } from '../../common/constants/Roles';
import { StudyLevelPaths } from './StudyLevelConstants';
import authService from '../../api-authorization/AuthorizeService';
import { withStyles } from '@material-ui/core/styles';

const stylesListStudyLevel = theme => ({
  toolbar: {
    float: 'right',
    minHeight: 54,
    paddingRight: 8
  },
  actions: {
    display: 'flex',
    color: theme.palette.text.secondary
  },
  btnIcon: {
    marginRight: theme.spacing(0.5)
  },
  btnAction: {
    height: '36px !important',
    margin: theme.spacing(0, 1)
  }
});

class ListStudyLevelComp extends Component {
  static displayName = ListStudyLevelComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.messageBar = React.createRef();
    this.state = {
      studyLevels: [],
      user: null,
      loading: true,
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Success}
          type={MessageType.Import}
          message={MessageBarText.Success}
          handleClose={this.handleCloseMsgBar}
        />
      )
    };
  }

  componentDidMount() {
    this.getUserRole();
    this.populateStudyLevelData();
  }

  getUserRole = async () => {
    const token = await authService.getAccessToken();
    const loggedInUser = await authService.getUser();
    const response = await fetch(
      `api/AspNetUsers/GetUserRole/${loggedInUser?.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const user = await response.json();
    this.setState({ user });
  };

  populateStudyLevelData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/StudyLevels', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ studyLevels: data, loading: false }, () => {
      this.child.updateChildData(data);
    });
  };

  refreshStudyLevels = async () => {
    await this.populateStudyLevelData();
  };

  handleUpload = name => event => {
    const files = event.target.files;
    this.doUpload(name, files, event.target);
  };
  doUpload = async (name, files, fileInput) => {
    const token = await authService.getAccessToken();
    const actionUrl = `api/LessonPlans/UploadImportFile/${name}`;
    const data = new FormData();
    data.append('files', files[0]);

    fetch(actionUrl, {
      method: 'POST',
      body: data,
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        if (response.ok) {
          this.showMessageBar(
            MessageBarVariants.Success,
            MessageType.Import,
            MessageBarText.Success[MessageType.Import]
          );
          fileInput.value = null;
        } else throw new Error(response.status);
      })
      .catch(error => {
        this.showMessageBar(
          MessageBarVariants.Error,
          MessageType.Import,
          MessageBarText.Error[MessageType.Import]
        );
        console.log(error);
      });
  };

  showMessageBar = (msgVariant, msgType, msgText) => {
    this.messageBar.showMessage(msgVariant, msgType, msgText);
  };
  handleCloseMsgBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  render() {
    const { classes } = this.props;
    const cols = [
      { name: 'name', header: 'Level Name', align: 'left', width: 250 },
      {
        name: 'numberOfLesson',
        header: 'Number of lessons',
        align: 'right',
        width: 300
      },
      {
        name: 'lessonTime',
        header: 'Lesson time',
        align: 'right',
        width: 300
      },
      { name: 'description', header: 'Description', align: 'left' }
    ];
    const actions = [
      {
        name: 'subList',
        tooltip: 'Lesson plans',
        url: `${LessonPlanPaths.List}`,
        icon: 'list_alt_icon'
      },
      {
        name: 'edit',
        tooltip: 'Edit level',
        url: `${StudyLevelPaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'delete',
        tooltip: 'Delete level',
        url: `${StudyLevelPaths.Delete}`,
        icon: 'delete_icon'
      }
    ];

    if (
      this.state.user?.role?.name === Roles.Admin ||
      this.state.user?.role?.name === Roles.Planner
    ) {
      actions.splice(1, 0, {
        name: 'exam',
        tooltip: 'Score frame',
        url: `${StudyLevelPaths.Exam}`,
        icon: 'assignment_turned_in'
      });
    }

    return this.state.loading ? (
      <Loading />
    ) : (
      <Fragment>
        <Toolbar className={classes.toolbar}>
          <div className={classes.actions}>
            <NormalTooltip title="Import Overall">
              <Fab
                component="label"
                variant="extended"
                size="medium"
                color="secondary"
                className={classes.btnAction}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  accept=".xlsx"
                  onChange={this.handleUpload('Overall')}
                />
                <Icon
                  path={mdiApplicationImport}
                  size={1}
                  className={classes.btnIcon}
                />
                Overall
              </Fab>
            </NormalTooltip>
            <NormalTooltip title="Import Lesson Plan">
              <Fab
                component="label"
                variant="extended"
                size="medium"
                color="secondary"
                className={classes.btnAction}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  accept=".xlsx"
                  onChange={this.handleUpload('LessonPlan')}
                />
                <Icon
                  path={mdiFileImportOutline}
                  size={1}
                  className={classes.btnIcon}
                />
                Lesson Plan
              </Fab>
            </NormalTooltip>
          </div>
        </Toolbar>
        <CustomizedTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.studyLevels}
          cols={cols}
          actions={actions}
          eventReloadData={this.refreshStudyLevels}
          isPaging={true}
        />
        {this.state.messageBar}
      </Fragment>
    );
  }
}

ListStudyLevelComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListStudyLevel = withStyles(stylesListStudyLevel)(
  ListStudyLevelComp
);
