import React, { Component, Fragment } from 'react';
import { SingleLessonActions, SingleLessonPaths } from './SingleLessonConstans';

import AddIcon from '@material-ui/icons/Add';
import { AdminPage } from '../ui/page/AdminPage';
import { EditSingleLesson } from './EditSingleLesson';
import { Fab } from '@material-ui/core';
import { ListSingleLesson } from './ListSingleLesson';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';

const stylesSingleLesson = theme => ({
  fab: {
    margin: theme.spacing(1)
  }
});

class SingleLessonComp extends Component {
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case SingleLessonActions.List:
        this.state.pageTitle = 'Single Lesson';
        this.state.content = <ListSingleLesson />;
        break;

      case SingleLessonActions.Create:
        this.state.pageTitle = 'Create Single Lesson';
        this.state.content = (
          <EditSingleLesson
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
          />
        );
        break;

      case SingleLessonActions.Edit:
        this.state.pageTitle = 'Edit Single Lesson';
        this.state.content = (
          <EditSingleLesson
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            singleLessonId={this.props.match.params.singleLessonId}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  handleChildBackToList = () => {
    this.child.backToList();
  };

  backToList = () => {
    this.redirectTo(SingleLessonPaths.List);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action == 'list' ? (
        <Fragment>
          <Fab
            aria-label="Add"
            color="primary"
            className={classes.fab}
            onClick={() => this.redirectTo(`${SingleLessonPaths.Create}`)}
          >
            <AddIcon />
          </Fab>
        </Fragment>
      ) : (
        ''
      );
    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

SingleLessonComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const SingleLesson = withStyles(stylesSingleLesson)(SingleLessonComp);
