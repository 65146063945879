import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Loading } from '../../ui/Loading';
import { StudyLevelPaths, StudyLevelActions } from './StudyLevelConstants';

const stylesEditStudyLevel = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  }
});

class EditStudyLevelComp extends Component {
  static displayName = EditStudyLevelComp.name;

  constructor(...args) {
    super(...args);
    this.levelForm = React.createRef();
    this.state = {
      action: this.props.action,
      levelId: this.props.levelId,
      method: 'PUT',
      studyLevel: {},
      redirect: null,
      loading: true
    };
    switch (this.props.action) {
      case StudyLevelActions.Create:
        this.state.studyLevel = {
          name: '',
          numberOfLessonStr: '',
          lessonTimeStr: '',
          description: ''
        };
        this.state.method = 'POST';
        this.state.action = `api/StudyLevels`;
        this.state.loading = false;
        break;
      case StudyLevelActions.Edit:
        this.state.action = `api/StudyLevels/${this.state.levelId}`;
        this.populateStudyLevelData();
        break;
      case undefined:
      default:
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  async populateStudyLevelData() {
    const token = await authService.getAccessToken();
    const { levelId } = this.state;
    const response = await fetch(`api/StudyLevels/${levelId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    data.numberOfLessonStr = data.numberOfLesson
      ? data.numberOfLesson.toString()
      : '';
    data.lessonTimeStr = data.lessonTime ? data.lessonTime.toString() : '';
    this.setState({ studyLevel: data, loading: false });
  }

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      studyLevel: {
        ...prevState.studyLevel,
        [attrName]: attrValue
      }
    }));
  };

  backToList() {
    this.redirectTo(StudyLevelPaths.List);
  }

  async submitData() {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    ReactDOM.findDOMNode(this.levelForm.current).dispatchEvent(
      new Event('submit')
    );
  }

  handleSubmit = e => {
    e.preventDefault();

    const { studyLevel, method, action, token } = this.state;
    studyLevel.numberOfLesson = parseInt(studyLevel.numberOfLessonStr, 10);
    studyLevel.lessonTime = parseInt(studyLevel.lessonTimeStr, 10);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    fetch(action, {
      method: method,
      body: JSON.stringify(studyLevel),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  renderForm() {
    const { classes } = this.props;
    const { studyLevel } = this.state;
    const cols = [
      {
        name: 'name',
        header: 'Level name',
        placeholder: 'Level name',
        adornment: ''
      },
      {
        name: 'numberOfLessonStr',
        header: 'Number of lessons',
        placeholder: 'Number of lessons',
        adornment: 'lessons'
      },
      {
        name: 'lessonTimeStr',
        header: 'Lesson time',
        placeholder: 'Lesson time',
        adornment: 'mins'
      },
      {
        name: 'description',
        header: 'Description',
        placeholder: 'Description',
        adornment: ''
      }
    ];

    return (
      <Paper className={classes.root}>
        <form
          ref={this.levelForm}
          className={classes.container}
          onSubmit={this.handleSubmit}
        >
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <TextField
                id="level-name"
                style={{ margin: 8 }}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                className={classes.margin}
                label={cols[0].header}
                placeholder={cols[0].placeholder}
                name={cols[0].name}
                value={studyLevel[cols[0].name]}
                onChange={this.handleChange(cols[0].name)}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <FormControl
                className={classNames(classes.margin, classes.textField)}
              >
                <InputLabel htmlFor="number-of-lesson">
                  {cols[1].header}
                </InputLabel>
                <Input
                  id="number-of-lesson"
                  name={cols[1].name}
                  value={studyLevel[cols[1].name]}
                  onChange={this.handleChange(cols[1].name)}
                  endAdornment={
                    <InputAdornment position="end">
                      {cols[1].adornment}
                    </InputAdornment>
                  }
                  inputProps={{
                    'aria-label': cols[1].header
                  }}
                />
              </FormControl>
            </ListItem>
            <ListItem className={classes.listItem}>
              <FormControl
                className={classNames(classes.margin, classes.textField)}
              >
                <InputLabel htmlFor="lesson-time">{cols[2].header}</InputLabel>
                <Input
                  id="lesson-time"
                  name={cols[2].name}
                  value={studyLevel[cols[2].name]}
                  onChange={this.handleChange(cols[2].name)}
                  endAdornment={
                    <InputAdornment position="end">
                      {cols[2].adornment}
                    </InputAdornment>
                  }
                  inputProps={{
                    'aria-label': cols[2].header
                  }}
                />
              </FormControl>
            </ListItem>
            <ListItem className={classes.listItem}>
              <TextField
                id="level-description"
                fullWidth
                multiline
                rows="5"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                className={classes.margin}
                label={cols[3].header}
                placeholder={cols[3].placeholder}
                name={cols[3].name}
                value={studyLevel[cols[3].name]}
                onChange={this.handleChange(cols[3].name)}
              />
            </ListItem>
          </List>
        </form>
        {this.state.redirect}
      </Paper>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderForm();

    return <Fragment>{contents}</Fragment>;
  }
}

EditStudyLevelComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const EditStudyLevel = withStyles(stylesEditStudyLevel)(
  EditStudyLevelComp
);
