import React, { forwardRef, useEffect, useRef } from 'react';
import { StyledVideo } from './styleLiveClass';

const VideoViewer = forwardRef((props, ref) => {
  if (!ref) ref = useRef();

  useEffect(() => {
    props.peer.on('error', err => {
      console.log(
        `video stream error, stream index: ${props.streamIdx}`,
        props.peer
      );
    });
    props.peer.on('stream', stream => {
      ref.current.srcObject = props.peer._remoteStreams[props.streamIdx];
      const tracks = props.peer.streams[props.streamIdx].getVideoTracks();
      if (tracks.length > 0) {
        tracks[0].addEventListener('stop', () => {
          console.log('track stoped', props.peer);
        });
      }
    });
    props.peer.on('track', (track, stream) => {
      //if (!track.enabled) setActive(false);
    });
    ref.current.addEventListener('stop', () => console.log('inactive!'));
    // eslint-disable-next-line
  }, [props.peer._remoteStreams]);

  return (
    <StyledVideo
      onClick={props.onClick}
      ref={ref}
      autoPlay
      playsInline
      style={props.style ? props.style : {}}
    />
  );
});

export default VideoViewer;
