import React, { Component } from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import clsx from 'clsx';
import { stylesDashboard } from './stylesDashboard';

class Tasks extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.populateMyTasks();
  }

  async populateMyTasks() {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const response = await fetch(
      `api/TaskSys/GetTaskSysAssigned/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    let myTasks = await response.json();

    this.setState({ myTasks, loading: false });
  }

  render() {
    const { classes } = this.props;
    const { myTasks, loading } = this.state;
    const cols = [
      { name: 'createDateFormat', header: 'Date Time', align: 'left' },
      { name: 'name', header: 'Title', align: 'left' },
      { name: 'note', header: 'Content', align: 'left' },
      { name: 'manager', header: 'Assigner', align: 'left' }
    ];

    return (
      <Fragment>
        {loading ? (
          <Loading />
        ) : (
          <Paper>
            <div className={classes.toolbar}>
              <Typography
                className={clsx(
                  classes.boldText,
                  classes.margin,
                  classes.title
                )}
              >
                Tasks
              </Typography>
            </div>
            <Divider />
            <TableContainer>
              <Table className={classes.table} aria-label="Let's Learn">
                <TableHead>
                  <TableRow>
                    {cols.map(col => (
                      <TableCell
                        key={col.name}
                        align={col.align}
                        className={clsx(classes.tableCell, classes.boldText)}
                      >
                        {col.header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {myTasks.map(row => (
                    <TableRow key={row.classId}>
                      {cols.map(col => (
                        <TableCell
                          key={col.name}
                          align={col.align}
                          className={classes.tableCell}
                        >
                          {row[col.name]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Fragment>
    );
  }
}

Tasks.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesDashboard)(Tasks);
