import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Loading } from '../../ui/Loading';
import { RolePaths, RoleActions } from './RoleConstants';

const stylesEditRole = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  },
  longTextField: {
    width: '100%'
  }
});

class EditRoleComp extends Component {
  static displayName = EditRoleComp.name;

  constructor(...args) {
    super(...args);
    this.roleForm = React.createRef();
    this.state = {
      action: this.props.action,
      roleId: this.props.roleId,
      method: 'PUT',
      roleObj: {},
      redirect: null,
      loading: true
    };
    switch (this.props.action) {
      case RoleActions.Create:
        this.state.roleObj = {
          name: ''
        };
        this.state.method = 'POST';
        this.state.action = `api/AspNetRoles`;
        this.state.loading = false;
        break;
      case RoleActions.Edit:
        this.state.action = `api/AspNetRoles/${this.state.roleId}`;
        this.populateDataInit();
        break;
      case undefined:
      default:
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  async populateDataInit() {
    const token = await authService.getAccessToken();
    const { roleId } = this.state;
    const response = await fetch(`api/AspNetRoles/${roleId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ roleObj: data, loading: false });
  }

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      roleObj: {
        ...prevState.roleObj,
        [attrName]: attrValue
      }
    }));
  };

  backToList() {
    this.redirectTo(RolePaths.List);
  }

  async submitData() {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    ReactDOM.findDOMNode(this.roleForm.current).dispatchEvent(
      new Event('submit')
    );
  }

  handleSubmit = e => {
    e.preventDefault();

    const { roleObj, method, action, token } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    fetch(action, {
      method: method,
      body: JSON.stringify(roleObj),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  renderForm() {
    const { classes } = this.props;
    const { roleObj } = this.state;
    const cols = [
      {
        name: 'name',
        header: 'Name',
        placeholder: 'Name',
        adornment: ''
      }
    ];

    return (
      <Paper className={classes.root}>
        <form
          ref={this.roleForm}
          className={classes.container}
          onSubmit={this.handleSubmit}
        >
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <TextField
                id="name"
                fullWidth
                type="name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                className={classes.margin}
                label={cols[0].header}
                placeholder={cols[0].placeholder}
                name={cols[0].name}
                value={roleObj[cols[0].name]}
                onChange={this.handleChange(cols[0].name)}
              />
            </ListItem>
          </List>
        </form>
        {this.state.redirect}
      </Paper>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderForm();

    return <Fragment>{contents}</Fragment>;
  }
}

EditRoleComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const EditRole = withStyles(stylesEditRole)(EditRoleComp);
