export const TeacherActions = {
  List: 'list',
  ViewReport: 'report',
  Edit: 'edit',
  Create: 'create',
  Reports: 'reports',
  Delete: 'delete'
};

const prefix = '/teachers';

export const TeacherPaths = {
  TeacherPrefix: prefix,
  List: `${prefix}/${TeacherActions.List}`,
  ViewReport: `${prefix}/${TeacherActions.ViewReport}`,
  ViewReportRoute: `${prefix}/${TeacherActions.ViewReport}/:teacherId`,
  EditRoute: `${prefix}/${TeacherActions.Edit}/:teacherId`,
  Edit: `${prefix}/${TeacherActions.Edit}`,
  Create: `${prefix}/${TeacherActions.Create}`,
  Reports: `${prefix}/${TeacherActions.Reports}`,
  ReportsRoute: `${prefix}/${TeacherActions.Reports}/:teacherId`,
  Delete: `AspNetUsers`
};
