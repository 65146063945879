import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../../common/route/PropsRoute';
import { ClassReport } from './ClassReport';
import { ClassReportPaths, ClassReportActions } from './ClassReportConstants';

export default class ClassReportRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={ClassReportPaths.List}
          component={ClassReport}
          action={ClassReportActions.List}
        />
        <PropsRoute
          path={ClassReportPaths.MyList}
          component={ClassReport}
          action={ClassReportActions.MyList}
        />
        <PropsRoute
          path={ClassReportPaths.ViewRoute}
          component={ClassReport}
          action={ClassReportActions.View}
        />
        <PropsRoute
          path={ClassReportPaths.ViewMyReportRoute}
          component={ClassReport}
          action={ClassReportActions.ViewMyReport}
        />
        <PropsRoute
          path={ClassReportPaths.ViewLessonReportRoute}
          component={ClassReport}
          action={ClassReportActions.ViewLessonReport}
        />
      </Fragment>
    );
  }
}
