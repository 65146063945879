import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { ClassCourse } from './ClassCourse';
import { ClassCoursePaths, ClassCourseActions } from './ClassCourseConstants';
import ClassReports from './reports/ClassReports';

export default class ClassCourseRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={ClassCoursePaths.List}
          component={ClassCourse}
          action={ClassCourseActions.List}
        />
        <PropsRoute
          path={ClassCoursePaths.EditRoute}
          component={ClassCourse}
          action={ClassCourseActions.Edit}
        />
        <PropsRoute
          path={ClassCoursePaths.Create}
          component={ClassCourse}
          action={ClassCourseActions.Create}
        />
        <PropsRoute
          path={ClassCoursePaths.ReportsRoute}
          component={ClassReports}
        />
      </Fragment>
    );
  }
}
