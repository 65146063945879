import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../../common/route/PropsRoute';
import { UnitPlan } from './UnitPlan';
import { UnitPlanPaths, UnitPlanActions } from './UnitPlanConstants';

export default class UnitPlanRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={UnitPlanPaths.ListRoute}
          component={UnitPlan}
          action={UnitPlanActions.List}
        />
        <PropsRoute
          path={UnitPlanPaths.EditRoute}
          component={UnitPlan}
          action={UnitPlanActions.Edit}
        />
        <PropsRoute
          path={UnitPlanPaths.CreateRoute}
          component={UnitPlan}
          action={UnitPlanActions.Create}
        />
      </Fragment>
    );
  }
}
