import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { ListSchedule } from './ListSchedule';
import { ScheduleActions, SchedulePaths } from './ScheduleConstans';
import { AdminPage } from '../ui/page/AdminPage';
import { EditSchedule } from './EditSchedule';
import { CalenderSchedule } from './CalendarScheduler';

const styles = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class ScheduleComp extends Component {
  static displayName = ScheduleComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      scheduleId: this.props.match.params.scheduleId,
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case ScheduleActions.List:
        this.state.pageTitle = 'Schedule';
        this.state.content = <ListSchedule />;
        break;
      case ScheduleActions.Create:
        this.state.pageTitle = 'Create Schedule';
        this.state.content = (
          <EditSchedule
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            scheduleId={this.state.scheduleId}
          />
        );
        break;
      case ScheduleActions.Edit:
        this.state.pageTitle = 'Edit Schedule';
        this.state.content = (
          <EditSchedule
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            scheduleId={this.state.scheduleId}
          />
        );
        break;
      case ScheduleActions.ViewCalendar:
        this.state.pageTitle = 'Calendar';
        this.state.content = (
          <CalenderSchedule
            onRef={actualChild => (this.child = actualChild)}
            scheduleId={this.state.scheduleId}
          />
        );
        break;
      case ScheduleActions.ViewAllClassCalendar:
        this.state.pageTitle = 'Calendar - All Classes';
        this.state.content = (
          <CalenderSchedule
            onRef={actualChild => (this.child = actualChild)}
            scheduleId={this.state.scheduleId}
            viewAllClass={true}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === 'list' ? (
        <Fab
          aria-label="Add"
          color="primary"
          className={classes.fab}
          onClick={() => this.redirectTo(SchedulePaths.Create)}
        >
          <AddIcon />
        </Fab>
      ) : this.props.action === 'calendar' ||
        this.props.action === 'all-calendar' ? (
        <Fragment>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleChildBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      ) : (
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.handleChildSubmit}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleChildBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      );
    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

ScheduleComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Schedule = withStyles(styles)(ScheduleComp);
