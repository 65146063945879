import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { AssessmentPaths } from './AssessmentConstants';
import { AssessmentList } from './AssessmentList';
import { AssessmentRoom } from './AssessmentRoom';
import { PresenceList } from './PresenceList';
import { PresenceStudent } from './PresenceStudent';

export default class AssessmentRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute path={AssessmentPaths.List} component={AssessmentList} />
        <PropsRoute
          path={AssessmentPaths.ClassRoute}
          component={AssessmentRoom}
        />
        <PropsRoute path={AssessmentPaths.Presence} component={PresenceList} />
        <PropsRoute
          path={AssessmentPaths.PresenceStudentRoute}
          component={PresenceStudent}
        />
      </Fragment>
    );
  }
}
