import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, ListItem } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import { stylesHolidays } from './stylesHolidays';

class HolidayListItem extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      btnStyle: { display: 'none' }
    };
  }

  changeButtonStyle = btnStyle => {
    this.setState({ btnStyle });
  };

  render() {
    const { classes, itemValue, handleCustomButton } = this.props;
    const { btnStyle } = this.state;

    return (
      <ListItem
        className={classes.listItem}
        onMouseEnter={e => {
          this.changeButtonStyle({ display: 'block' });
        }}
        onMouseLeave={e => {
          this.changeButtonStyle({ display: 'none' });
        }}
      >
        {itemValue}
        <IconButton
          size="small"
          color="primary"
          className={classes.button}
          style={btnStyle}
          onClick={() => handleCustomButton(itemValue)}
        >
          <RemoveIcon />
        </IconButton>
      </ListItem>
    );
  }
}

HolidayListItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesHolidays)(HolidayListItem);
