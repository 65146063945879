import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

const controlBarWidth = 80;
const drawerWidth = 210;

const useStylesLiveClass = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 0
  },
  drawerPaper: {
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '5px'
  },
  drawerHeaderClose: {
    justifyContent: 'center'
  },
  controlBar: {
    width: controlBarWidth,
    height: `100vh`,
    flexShrink: 0,
    backgroundColor: '#FAFAFB'
  },
  controlBarHeader: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: `100vh`,
    marginTop: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0 - drawerWidth,
    overflow: 'hidden'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  mainView: {
    width: '100%',
    height: '100%'
  },
  displayContent: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%'
  },
  fsButton: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.5),
    minWidth: 0
  },
  floatButtons: {
    position: 'absolute',
    right: 1,
    top: 3
  },
  emptyVideoHolder: {
    width: 210
  },
  emptyVideoBox: {
    width: 200,
    height: 150,
    margin: 5,
    borderRadius: 12,
    backgroundColor: '#d9d9df'
  }
}));

const StyledVideo = styled.video`
  width: auto;
  height: 150px;
  margin: 5px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #d3d4d5;
  :hover {
    > i {
      display: block;
    }
  }
`;

const CameraButton = styled.div`
  position: relative;
  width: 75px;
  border: none;
  font-size: 0.9375rem;
  padding: 5px;
  text-align: center;

  :hover {
    background-color: #77b7dd;
    cursor: pointer;
    border-radius: 15px;
  }

  * {
    pointer-events: none;
  }

  .fa-microphone-slash {
    color: #ee2560;
  }

  .fa-video-slash {
    color: #ee2560;
  }
`;

const ScreenButton = styled.div`
  width: auto;
  border: none;
  font-size: 0.9375rem;
  padding: 5px;
  text-align: center;

  :hover {
    background-color: #77b7dd;
    cursor: pointer;
    border-radius: 15px;
  }

  .sharing {
    color: #ee2560;
  }
`;

export { useStylesLiveClass, StyledVideo, CameraButton, ScreenButton };
