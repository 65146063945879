import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import {
  AddBoxOutlined,
  RemoveCircleOutlineOutlined
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PropTypes } from 'prop-types';
import authService from '../../api-authorization/AuthorizeService';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: 16,
    '& .MuiAccordionSummary-root': {
      backgroundColor: '#85c221'
    },
    '& .MuiAccordionDetails-root': {
      border: '1px solid #85c221',
      paddingTop: 24
    }
  },
  heading: {
    color: '#fff',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    minHeight: 45
  },
  btnDelete: {
    position: 'absolute',
    right: 24,
    '& .MuiButtonBase-root': {
      background: 'transparent',
      border: '1px solid #e61e14',
      color: '#e61e14'
    }
  },
  btnPlus: {
    '& .MuiIconButton-label': {
      color: '#85c221'
    }
  },
  btnMinus: {
    '& .MuiIconButton-label': {
      color: '#e61e14'
    }
  }
}));

const ExamAccordionCmp = ({ template, setExamTemplates }) => {
  const classes = useStyles();
  const [skills, setSkills] = useState([
    ...JSON.parse(template?.examFormJson)?.overall
  ]);
  const [linguistics, setLinguistics] = useState([
    ...JSON.parse(template?.examFormJson)?.academic
  ]);
  const [examTemplate, setExamTemplate] = useState({
    examName: template?.examName,
    examType: template?.examType
  });

  useEffect(() => {
    return () => {
      setSkills([]);
      setLinguistics([]);
      setExamTemplate({});
    };
  }, []);

  useEffect(() => {
    const jsonTemplate = {
      id: template?.id,
      examType: examTemplate?.examType,
      examName: examTemplate?.examName,
      examFormJson: JSON.stringify({
        examName: examTemplate?.examName,
        examType: examTemplate?.examType,
        overall: [...skills],
        academic: [...linguistics]
      })
    };

    setExamTemplates(current => {
      const _current = current.map(item => {
        if (item.id === template?.id) {
          item = jsonTemplate;
        }
        return item;
      });
      return [..._current];
    });
  }, [skills, linguistics, examTemplate]);

  const onSkillChange = (e, index) => {
    e.preventDefault();
    const { name, value } = e.target;
    const list = [...skills];
    list[index][name] = value;
    setSkills(list);
  };

  const onSkillRemove = index => {
    const list = [...skills];
    list.splice(index, 1);
    setSkills(list);
  };

  const onSkillAdd = () => {
    setSkills([...skills, { name: '', altName: '', evaluation: '0' }]);
  };

  const onLinguisticChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...linguistics];
    list[index][name] = value;
    setLinguistics(list);
  };

  const onLinguisticRemove = index => {
    const list = [...linguistics];
    list.splice(index, 1);
    setLinguistics(list);
  };

  const onLinguisticAdd = () => {
    setLinguistics([...linguistics, { name: '', weight: '0', score: '0' }]);
  };

  const onTemplateChange = e => {
    const { name, value } = e.target;
    const tmp = { ...examTemplate };
    tmp[name] = value;
    setExamTemplate(tmp);
  };

  const onDeleteExamTemplate = async id => {
    const token = await authService.getAccessToken();
    fetch(`api/StudyLevels/exam/${id}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
      method: 'DELETE'
    }).then(() => {
      setExamTemplates(current => [
        ...current.filter(template => template?.id !== id)
      ]);
    });
  };

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            {examTemplate?.examName || 'New Template'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} xs={12}>
            <div className={classes.btnDelete}>
              <Button
                className={classes.button}
                startIcon={<DeleteSweepIcon color="error" />}
                onClick={() => onDeleteExamTemplate(template?.id)}
              >
                Delete
              </Button>
            </div>
            <Grid item xs={6}>
              <FormGroup>
                <FormControl variant="outlined">
                  <Typography variant="p" gutterBottom>
                    Template Name
                  </Typography>
                  <TextField
                    id="template-name"
                    name="examName"
                    type="text"
                    variant="outlined"
                    size="small"
                    value={examTemplate?.examName}
                    onChange={e => onTemplateChange(e)}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={2}>
              <FormGroup>
                <FormControl variant="outlined">
                  <Typography variant="p" gutterBottom>
                    Template Type
                  </Typography>
                  <TextField
                    id="template-type"
                    name="examType"
                    InputProps={{
                      sx: {
                        '& input': {
                          textAlign: 'right'
                        }
                      }
                    }}
                    type="number"
                    variant="outlined"
                    size="small"
                    value={examTemplate?.examType}
                    onChange={e => onTemplateChange(e)}
                  />
                </FormControl>
              </FormGroup>
            </Grid>

            <Grid item xs={5}>
              <div className={classes.inline}>
                <Typography variant="h6" gutterBottom>
                  OVERALL PERFORMANCE
                </Typography>
              </div>
              <div className={classes.inline}>
                <Typography variant="p" gutterBottom>
                  Skills (Các kỹ năng)
                </Typography>
                <IconButton
                  aria-label="add"
                  className={classes.btnPlus}
                  onClick={onSkillAdd}
                >
                  <AddBoxOutlined />
                </IconButton>
              </div>
              <FormGroup>
                {skills.map((item, index) => (
                  <FormControl variant="outlined" key={index}>
                    <Grid item xs={12} className={classes.inline}>
                      <TextField
                        id={`skill-${index}`}
                        name="name"
                        type="text"
                        variant="outlined"
                        size="small"
                        value={item.name}
                        onChange={e => onSkillChange(e, index)}
                      />
                      <TextField
                        id={`altSkill-${index}`}
                        type="text"
                        name="altName"
                        variant="outlined"
                        size="small"
                        value={item.altName}
                        onChange={e => onSkillChange(e, index)}
                      />
                      <IconButton
                        className={classes.btnMinus}
                        onClick={() => onSkillRemove(index)}
                      >
                        <RemoveCircleOutlineOutlined />
                      </IconButton>
                    </Grid>
                  </FormControl>
                ))}
              </FormGroup>
            </Grid>
            <Grid item xs={7}>
              <div>
                <Typography variant="h6" gutterBottom>
                  ACADEMIC PERFORMANCE
                </Typography>
              </div>
              <Grid container xs={12}>
                <Grid item xs={5}>
                  <div className={classes.inline}>
                    <Typography variant="p" gutterBottom>
                      Linguistic Aspects (Ngôn ngữ học)
                    </Typography>
                    <IconButton
                      aria-label="add"
                      onClick={onLinguisticAdd}
                      className={classes.btnPlus}
                    >
                      <AddBoxOutlined />
                    </IconButton>
                  </div>
                  <FormGroup>
                    {linguistics.map((item, index) => (
                      <FormControl variant="outlined" key={index}>
                        <Grid item xs={12} className={classes.inline}>
                          <TextField
                            id={`linguistic-${index}`}
                            name="name"
                            type="text"
                            variant="outlined"
                            size="small"
                            value={item.name}
                            onChange={e => onLinguisticChange(e, index)}
                          />
                          <IconButton
                            onClick={() => onLinguisticRemove(index)}
                            className={classes.btnMinus}
                          >
                            <RemoveCircleOutlineOutlined />
                          </IconButton>
                        </Grid>
                      </FormControl>
                    ))}
                  </FormGroup>
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.inline}>
                    <Typography variant="p" gutterBottom>
                      Score (Điểm)
                    </Typography>
                  </div>
                  <FormGroup>
                    {linguistics.map((item, index) => (
                      <FormControl variant="outlined" key={index}>
                        <Grid item xs={12} className={classes.inline}>
                          <TextField
                            id={`score-${index}`}
                            name="weight"
                            InputProps={{
                              sx: {
                                '& input': {
                                  textAlign: 'right'
                                }
                              }
                            }}
                            type="number"
                            variant="outlined"
                            size="small"
                            value={item.weight}
                            onChange={e => onLinguisticChange(e, index)}
                          />
                        </Grid>
                      </FormControl>
                    ))}
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

ExamAccordionCmp.propTypes = {
  template: PropTypes.func.isRequired,
  setExamTemplates: PropTypes.func.isRequired
};

const ExamAccordion = ExamAccordionCmp;
export default ExamAccordion;
