import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';

import { ActionIconButton, NormalTooltip } from '../ButtonStyles';
import TablePaginationActions from './TablePaginationActions';
import { CustomTableCell } from './CommonDataTable';

const stylesPagingTable = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  tableBody: {
    height: 160
  },
  actionCol: {
    minWidth: 140
  },
  actionIcon: {
    fontSize: 20
  },
  select: {
    fontSize: 14
  },
  selectIcon: {
    right: -5
  },
  caption: {
    fontSize: 14
  }
});

class PagingTableComp extends Component {
  static displayName = PagingTableComp.name;

  constructor(...args) {
    super(...args);
    this.state = {
      selected: [],
      selectAll: 0,
      filteredRows: [],
      dataSelectDropdown: [],
      rowsPerPage: 6,
      page: 0,
      selectedValueRadio: ''
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  setData(filteredRows) {
    this.setState({ filteredRows });
  }

  onChangeCheckbox = id => event => {
    var lstSelected = this.state.selected;
    if (event.target.checked) {
      lstSelected.push(id);
    } else {
      var indexRemove = lstSelected.indexOf(id);
      if (indexRemove > -1) {
        lstSelected.splice(indexRemove, 1);
      }
    }
    this.props.callbackFromParent(lstSelected);
  };
  onChangeRadioButton = event => {
    this.setState({ selectedValueRadio: event.target.value });
    this.props.callbackGetValueRadioBtn(event.target.value);

    if (this.props.callbackGetValueObjRadioBtn) {
      var data = this.state.filteredRows;
      var objFilter = data.find(x => x.id === event.target.value);
      this.props.callbackGetValueObjRadioBtn(objFilter);
    }
  };

  onChangeDropdown = id => event => {
    var val = event.target.value;
    if (val !== '') {
      var dataSelectDropdown = this.state.dataSelectDropdown;
      var data = this.state.filteredRows;
      var isCheck = false;
      dataSelectDropdown.forEach(e => {
        if (e.id === id) {
          e.val = val;
          isCheck = true;
        }
      });
      data.forEach(e => {
        if (e.id === id) {
          e.valDropdown = val;
        }
      });
      if (!isCheck) {
        dataSelectDropdown.push({ id: id, valDropdown: val });
      }
      this.setState({ dataSelectDropdown: dataSelectDropdown, row: data });
      this.props.callbackFromParentDrowdown(dataSelectDropdown);
    }
  };

  handleChangePage = (e, page) => {
    this.setState({ page: page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: Number(event.target.value) });
  };

  render() {
    const {
      classes,
      cols,
      actions,
      isShowRadioButton,
      isShowDropdown,
      dataDropdown,
      eventDropDown
    } = this.props;
    const { rowsPerPage, filteredRows, page } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage);

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {isShowRadioButton ? <CustomTableCell /> : null}

            {cols.map(col => (
              <CustomTableCell
                component="th"
                scope="row"
                align={col.align}
                key={col.name}
              >
                {col.header}
              </CustomTableCell>
            ))}

            {isShowDropdown ? (
              <CustomTableCell
                component="th"
                scope="row"
                width={eventDropDown.width}
                align={eventDropDown.align}
                key={eventDropDown.name}
              >
                {eventDropDown.name}
              </CustomTableCell>
            ) : null}

            {actions && (
              <CustomTableCell className={classes.actionCol} align="right">
                &nbsp;
              </CustomTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {filteredRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(row => (
              <TableRow className={classes.row} key={row.id}>
                {isShowRadioButton ? (
                  <CustomTableCell align="center">
                    <Radio
                      checked={this.state.selectedValueRadio === row.id}
                      value={row.id}
                      name="radio-button"
                      aria-label="A"
                      onChange={this.onChangeRadioButton}
                    />
                  </CustomTableCell>
                ) : null}

                {cols.map(col => (
                  <CustomTableCell align={col.align} key={row.id + col.name}>
                    <Typography
                      variant="body2"
                      noWrap={col.noWrap === undefined ? undefined : true}
                    >
                      {col.obj && row[col.name] !== null
                        ? row[col.name][col.objName]
                        : row[col.name]}
                    </Typography>
                  </CustomTableCell>
                ))}
                {isShowDropdown ? (
                  <CustomTableCell align="center">
                    <FormControl
                      className={classNames(classes.margin, classes.textField)}
                    >
                      <Select
                        id="dropdown"
                        name="dropdown"
                        value={row.valDropdown ? row.valDropdown : 1}
                        onChange={this.onChangeDropdown(row.id)}
                      >
                        {dataDropdown
                          ? dataDropdown.map(item => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </CustomTableCell>
                ) : null}
                {actions && (
                  <CustomTableCell align="right">
                    {actions.map(col =>
                      col.name !== 'attach' ? (
                        <NormalTooltip
                          title={col.tooltip}
                          key={row.id + col.name}
                        >
                          <ActionIconButton
                            component={Link}
                            to={
                              col.name === 'edit' ||
                              col.name === 'delete' ||
                              col.name === 'unitPlan' ||
                              col.name === 'lessonAndTeacher'
                                ? col.url + '/' + row.id
                                : col.url
                            }
                          >
                            <Icon className={classes.actionIcon}>
                              {col.icon}
                            </Icon>
                          </ActionIconButton>
                        </NormalTooltip>
                      ) : (
                        <Fragment key={row.id + col.name}>
                          {col.fileInput}
                          <label htmlFor={col.inputId}>
                            <NormalTooltip title={col.tooltip}>
                              <ActionIconButton component="span">
                                <Icon className={classes.actionIcon}>
                                  {col.icon}
                                </Icon>
                              </ActionIconButton>
                            </NormalTooltip>
                          </label>
                        </Fragment>
                      )
                    )}
                  </CustomTableCell>
                )}
              </TableRow>
            ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 59 * emptyRows }}>
              <CustomTableCell colSpan={cols.length + 1} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[6, 12, 24]}
              colSpan={cols.length + 1}
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Rows/Page"
              classes={{
                root: classes.root,
                select: classes.select,
                selectIcon: classes.selectIcon,
                caption: classes.caption
              }}
              SelectProps={{
                native: true
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    );
  }
}

PagingTableComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const PagingTable = withStyles(stylesPagingTable)(PagingTableComp);
