import React, { Component } from 'react';
import clsx from 'clsx';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Bar, BarChart, BarSeries } from 'reaviz';

export class ClassChartView extends Component {
  render() {
    const { title, subtitle, chartData, classes } = this.props;

    return (
      <Paper className={classes.chartBox}>
        <Typography variant="h3" className={classes.heading}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          {subtitle}
        </Typography>
        <Grid item xs={12} style={{ overflowY: 'auto' }}>
          <div
            className={clsx(classes.chartWrap, classes.chartWrapPadding)}
            style={
              chartData.length > 25
                ? {
                    width: 50 * chartData.length,
                    paddingBottom: 40
                  }
                : { width: '100%', paddingBottom: 40 }
            }
          >
            <BarChart
              data={chartData}
              series={
                <BarSeries
                  bar={<Bar gradient={false} width={25} />}
                  colorScheme={['#42a5f6']}
                  padding={0.8}
                />
              }
              className={classes.barChart}
            />
          </div>
        </Grid>
      </Paper>
    );
  }
}
