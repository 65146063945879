import React, { Component, Fragment } from 'react';
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import {
  MessageBarText,
  MessageBarVariants,
  MessageBars,
  MessageType
} from '../ui/popup/Messages';
import CustomizedTable from '../ui/table/CustomizedTable';
import { Loading } from '../ui/Loading';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { SingleLessonPaths } from './SingleLessonConstans';
import { fetchHelper } from '../../helpers/fetch-helper';
import authService from '../api-authorization/AuthorizeService';
import { Roles } from './../common/constants/Roles';

const stylesList = theme => ({
  fab: {
    margin: '12px 8px 8px 8px'
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  }
});

class ListSingleLessonComp extends Component {
  static displayName = ListSingleLessonComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.messageBar = React.createRef();
    this.state = {
      data: [],
      loading: false,
      redirect: null,
      isArchived: false,
      userRole: null,
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Success}
          type={MessageType.Archived}
          message={MessageBarText.Success.archived}
          handleClose={this.handleCloseMsgBar}
        />
      )
    };
  }

  componentDidMount = async () => {
    await this.populateDataInit();
  };

  populateDataInit = async () => {
    const res = await fetchHelper.get(`api/SingleLessons`);
    const user = await authService.getUser();
    const data =
      user.role === Roles.Teacher.toLowerCase()
        ? res?.body.filter(item => item.teacherIds.includes(user.sub))
        : res?.body;
    this.setState({ data, loading: false, userRole: user.role });
    this.setChildData(this.state.data.filter(item => !item.isArchived));
  };

  setChildData = data => {
    this.child.updateChildData(data);
  };

  eventReloadData = () => {
    this.populateDataInit();
  };

  backToList = () => {
    this.redirectTo(`${SingleLessonPaths.List}`);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  showMessageBar = (msgVariant, msgType, msgText) => {
    this.messageBar.showMessage(msgVariant, msgType, msgText);
  };

  handleCloseMsgBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  /**
   * Handles the change event when the archived checkbox is toggled.
   * If the checkbox is checked, filters the data to include only archived items and updates the child data.
   * If the checkbox is unchecked, updates the child data with the original data.
   * @param {Event} e - The change event object.
   */
  onArchivedChanged = e => {
    this.setChildData(
      e.target.checked
        ? this.state.data.filter(item => item.isArchived)
        : this.state.data.filter(item => !item.isArchived)
    );
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  showError = error => {
    this.showMessageBar(MessageBarVariants.Error, MessageType.Error, error);
  };

  showSuscess = message => {
    this.showMessageBar(
      MessageBarVariants.Success,
      MessageType.Success,
      message
    );
  };

  approve = async rowData => {
    try {
      if (rowData.teacherIds.length === 0) {
        this.showError('No teacher assigned to this lesson.');
        return;
      }
      // Write worklog
      await Promise.all(
        rowData.teacherIds.map(teacherId => {
          return this.createWorklog({
            teacherId: teacherId,
            subsForTeacherId: '',
            singleLessonId: rowData.id,
            effortType: 'single_lesson'
          });
        })
      );

      // update Archive
      await this.archived(rowData);
      this.showSuscess(MessageBarText.Success.approve);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  archived = async rowData => {
    return await fetchHelper.put(
      `api/SingleLessons/${rowData.id}/archive`,
      true
    );
  };

  updateArchived = async rowData => {
    if (rowData.isArchived) {
      this.showError('This lesson has been archived.');
      return;
    } else {
      const res = await this.archived(rowData);
      if (res.body && res.status === 200) {
        this.showSuscess('Archived successfully');
        this.populateDataInit();
      } else {
        console.error('Failed to update archive');
      }
    }
  };

  createWorklog = async obj => {
    const rs = await fetchHelper.post(`api/worklog`, obj);
    if (rs.status === 200) {
      return rs.body;
    }
    throw new Error(rs?.errorMessage);
  };

  renderDatagrid = () => {
    const cols = [
      { name: 'name', header: 'Name', align: 'right' },
      { name: 'typeFormat', header: 'Type', align: 'right' },
      { name: 'startTimeStr', header: 'Start Time', align: 'right' },
      { name: 'endTimeStr', header: 'End Time', align: 'right' },
      { name: 'numOfStudent', header: 'Number Of Students', align: 'right' }
    ];

    let actions = [
      {
        id: 'approve',
        name: 'callback',
        callBackFn: this.approve,
        tooltip: 'Approve',
        icon: 'check_box_icon'
      },
      {
        name: 'edit',
        tooltip: 'Edit',
        url: `${SingleLessonPaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'callback',
        callBackFn: this.updateArchived,
        tooltip: 'Archive',
        icon: 'archive'
      },
      {
        name: 'delete',
        tooltip: 'Delete',
        url: `${SingleLessonPaths.Delete}`,
        icon: 'delete_icon'
      }
    ];

    actions =
      this.state.userRole === Roles.Teacher.toLowerCase()
        ? actions
        : actions.filter(action => action?.id !== 'approve');

    return (
      <Fragment>
        <CustomizedTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.data}
          cols={cols}
          actions={actions}
          eventReloadData={this.eventReloadData}
          isPaging={true}
        />
        {this.state.redirect}
      </Fragment>
    );
  };

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();
    return (
      <Fragment>
        <FormControlLabel
          control={<Checkbox name="archive-checkbox" color="primary" />}
          label="Archived"
          style={{ float: 'right' }}
          onChange={this.onArchivedChanged}
        />
        {contents}
        {this.state.messageBar}
      </Fragment>
    );
  }
}

ListSingleLessonComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListSingleLesson = withStyles(stylesList)(ListSingleLessonComp);
