import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Loading } from '../ui/Loading';
import authService from '../api-authorization/AuthorizeService';
import Room from './Room';

const stylesVideo = theme => ({
  root: {}
});

class LiveClass extends Component {
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      userRole: this.props.match.params.role,
      userId: this.props.match.params.userId,
      roomId: this.props.match.params.roomId,
      userName: '',
      loading: true
    };
  }

  componentDidMount() {
    this.populateCommonInfo();
  }

  populateCommonInfo = async () => {
    const { userRole, userId, userName } = this.state;
    let userNameJoin = '';

    if (userRole === 'teacher') {
      const isAuthenticated = await authService.isAuthenticated();
      if (isAuthenticated) {
        const loginUser = await authService.getUser();
        console.log(loginUser);
        userNameJoin = loginUser.name;
      }
    }
    if (userRole === 'student') {
      // const response = await fetch(`https://localhost:5006/api/CheckLoggedInStudent/${userId}`);
      // const data = await response.json();
      // console.log(data);
      userNameJoin = userId;
    }
    this.setState({ loading: false, userName: userNameJoin ?? userName });
  };

  render() {
    //const { classes } = this.props;
    const { roomId, userRole, userId, userName, loading } = this.state;
    const content = (
      <Room
        roomId={roomId}
        classLessonId={'6c0a34f1-c3bd-4dd1-8480-3843c59424d2'}
        userRole={userRole}
        userId={userId}
        userName={userName}
      />
    );

    return loading ? <Loading /> : content;
  }
}

LiveClass.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesVideo)(LiveClass);
