import React, { Component, Fragment } from 'react';
import { StudyLevelActions, StudyLevelPaths } from './StudyLevelConstants';

import { PropsRoute } from '../../common/route/PropsRoute';
import { StudyLevels } from './StudyLevels';

export default class StudyLevelRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={StudyLevelPaths.List}
          component={StudyLevels}
          action={StudyLevelActions.List}
        />
        <PropsRoute
          path={StudyLevelPaths.EditRoute}
          component={StudyLevels}
          action={StudyLevelActions.Edit}
        />
        <PropsRoute
          path={StudyLevelPaths.Create}
          component={StudyLevels}
          action={StudyLevelActions.Create}
        />
        <PropsRoute
          path={StudyLevelPaths.ExamRoute}
          component={StudyLevels}
          action={StudyLevelActions.Exam}
        />
      </Fragment>
    );
  }
}
