import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import authService from '../api-authorization/AuthorizeService';
import CommonDataTable from '../ui/table/CommonDataTable';
import CommonSearchToolbar from '../ui/table/CommonSearchToolbar';
import { Loading } from '../ui/Loading';
import { ClassCoursePaths } from './ClassCourseConstants';
import SendLessonContent from './SendLessonContent';
import { LessonAndTeacherPaths } from './lessonAndTeacher/LessonAndTeacherConstants';
import { ClassDataPaths } from './class-data/ClassDataConstants';
import ListIcon from '@material-ui/icons/List';

const stylesListClassCourse = () => ({});

class ListClassCourseComp extends Component {
  static displayName = ListClassCourseComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.sendLessonContentDialog = React.createRef();
    this.state = {
      classesData: [],
      containsEndClass: 0,
      loading: true,
      sendLessonContentDialog: (
        <SendLessonContent
          onRef={actualChild => (this.sendLessonContentDialog = actualChild)}
          handleParentAction={this.showMessage}
        />
      )
    };
  }

  componentDidMount() {
    this.populateClassCourseData();
  }

  populateClassCourseData = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const { containsEndClass } = this.state;
    const response = await fetch(
      `api/ClassCourses/GetClassList/${loggedInUser.sub}/${containsEndClass}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({ classesData: data, loading: false });
  };

  refreshClassCourses = async () => {
    await this.populateClassCourseData();
    this.child.setData(this.state.classesData);
  };

  showSendEmail = async rowData => {
    this.sendLessonContentDialog.openSendEmail(rowData);
  };

  handleFilters = withEndClass => {
    this.setState(
      { loading: true, containsEndClass: withEndClass ? 1 : 0 },
      this.populateClassCourseData
    );
  };

  handleChangeSearchTerm = filterText => {
    this.setChildData(filterText);
  };

  setChildData = filterText => {
    const { classesData: data } = this.state;
    const searchTerm = filterText.toLowerCase();
    let filteredRows = data.filter(item => {
      const itemText = item.class.toLowerCase();
      return itemText.indexOf(searchTerm) > -1;
    });
    this.child.setData(filteredRows);
  };

  renderDatagrid() {
    const cols = [
      {
        name: 'level',
        header: 'Level',
        align: 'left'
      },
      {
        name: 'class',
        header: 'Name',
        align: 'left'
      },
      { name: 'description', header: 'Description', align: 'left' },
      { name: 'startDate', header: 'Start Date', align: 'left' },
      { name: 'endDate', header: 'End Date', align: 'left' },
      {
        name: 'schedule',
        header: 'Schedule',
        align: 'left'
      },
      { name: 'startTimeLocal', header: 'Start Time', align: 'left' },
      { name: 'endTimeLocal', header: 'End Time', align: 'left' },
      { name: 'teacher', header: 'Teachers', align: 'left' },
      { name: 'teachingProccess', header: 'Progress (Days)', align: 'center' }
    ];
    const actions = [
      {
        name: 'route',
        tooltip: 'Class reports',
        url: `${ClassCoursePaths.Reports}`,
        icon: 'bar_chart'
      },
      {
        name: 'route',
        tooltip: 'Assign teachers and lessons',
        url: `${LessonAndTeacherPaths.List}`,
        icon: 'assignment'
      },
      {
        name: 'subList',
        tooltip: 'Class student list',
        url: `${ClassDataPaths.List}`,
        icon: 'list_alt_icon'
      },
      {
        name: 'subList',
        tooltip: 'Suspended/Terminated student list',
        url: `${ClassDataPaths.ListSuspended}`,
        icon_mui: <ListIcon />
      },
      {
        name: 'callback',
        callBackFn: this.showSendEmail,
        tooltip: 'Send lesson content',
        icon: 'send'
      },
      {
        name: 'edit',
        tooltip: 'Edit class',
        url: `${ClassCoursePaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'delete',
        tooltip: 'Delete class',
        url: `${ClassCoursePaths.Delete}`,
        icon: 'delete_icon'
      }
    ];

    return (
      <Paper>
        <CommonSearchToolbar
          handleChange={this.handleChangeSearchTerm}
          checkCondition="Finished Classes"
          handleFilters={this.handleFilters}
        />
        {this.state.loading ? (
          <Loading />
        ) : (
          <CommonDataTable
            onRef={actualChild => (this.child = actualChild)}
            rows={this.state.classesData}
            cols={cols}
            actions={actions}
            eventReloadData={this.refreshClassCourses}
            isPaging={true}
          />
        )}
      </Paper>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderDatagrid()}
        {this.state.sendLessonContentDialog}
      </Fragment>
    );
  }
}

ListClassCourseComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListClassCourse = withStyles(stylesListClassCourse)(
  ListClassCourseComp
);
