import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { stylesAcademicInfo } from '../../../academic-manage/stylesAcademicInfo';
import clsx from 'clsx';

class CampusSummary extends Component {
  render() {
    const { classes, campusInfo } = this.props;

    return (
      <Fragment>
        <Grid container className={classes.stdHeadline}>
          <div className={classes.stdSubtitle}>
            <Typography variant="h3" className={classes.sectionTitle}>
              {'Thông tin chi nhánh'}
            </Typography>
          </div>
          <div className={classes.stdSubtitleHr}>
            <span className={clsx(classes.stdLineCell)}></span>
          </div>
        </Grid>

        <Grid
          container
          alignItems="flex-start"
          spacing={1}
          className={classes.stdBox}
        >
          <Grid item xs={2}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Địa chỉ chi nhánh:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="body2"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              {campusInfo.address}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Số điện thoại hotline:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="body2"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              {'0946.314.242'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Tổng số phòng học:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="body2"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              {campusInfo.numberOfRooms}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Tổng số nhân viên:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="body2"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              {campusInfo.numberOfStaffs}
            </Typography>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const stylesClassReports = theme => ({
  ...stylesAcademicInfo(theme),
  root: {
    marginTop: theme.spacing(2)
  }
});

CampusSummary.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesClassReports)(CampusSummary);
