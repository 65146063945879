import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import defaultTheme from '../../../ui/theme/defaultTheme.json';
import { AppEmptyLayout } from './AppEmptyLayout';

const themeMain = createMuiTheme(defaultTheme);

export class EmptyLayout extends Component {
  displayName = EmptyLayout.name;

  render() {
    return (
      <MuiThemeProvider theme={themeMain}>
        <AppEmptyLayout>{this.props.children}</AppEmptyLayout>
      </MuiThemeProvider>
    );
  }
}
