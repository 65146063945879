export class StreamUtils {
  static makeCanvasStream = streamCanvas => {
    let ctx = streamCanvas.getContext('2d', { willReadFrequently: true });
    ctx.fillRect(0, 0, streamCanvas.width, streamCanvas.height);
    let p = ctx.getImageData(0, 0, streamCanvas.width, streamCanvas.height);
    requestAnimationFrame(function draw() {
      for (var i = 0; i < p.data.length; i++) {
        p.data[i++] = p.data[i++] = p.data[i++] = Math.random() * 255;
      }
      ctx.putImageData(p, 0, 0);
      requestAnimationFrame(draw);
    });
    return streamCanvas.captureStream(30);
  };
}
