import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField
} from '@material-ui/core';
import authService from '../../api-authorization/AuthorizeService';

const stylesAcademicAnnounce = theme => ({
  dialogContent: {
    padding: theme.spacing(2)
  },
  formControl: {
    width: '100%'
  }
});

class AcademicAnnounce extends Component {
  static displayName = AcademicAnnounce.name;

  constructor(...args) {
    super(...args);
    this.state = {
      open: false,
      classId: null,
      title: 'Academic Announcement'
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  saveAcademicAnnounce = async () => {
    const token = await authService.getAccessToken();
    const loggedInUser = await authService.getUser();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const { classId, announcement } = this.state;
    const formBody = JSON.stringify({
      classId,
      content: announcement,
      createdBy: loggedInUser.sub
    });
    fetch(`api/AcademicManage/CreateAcadAnnounce`, {
      method: 'POST',
      body: formBody,
      headers: headers
    });
  };

  openAcademicAnnouncement(classId) {
    this.setState({ classId });
    this.openDialog();
  }
  sendAcademicAnnouncement = async () => {
    await this.saveAcademicAnnounce();
    this.closeDialog();
  };

  openDialog = () => {
    this.setState({ open: true });
  };
  closeDialog = () => {
    this.setState({ open: false });
    if (this.props.handleParentAction) this.props.handleParentAction();
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { classes } = this.props;
    const { title, announcement } = this.state;

    return (
      <Dialog
        open={this.state.open}
        onClose={this.closeDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FormControl className={classes.formControl}>
            <TextField
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              multiline
              rows="15"
              label="Announcement"
              placeholder="Announcement"
              name="announcement"
              value={announcement}
              onChange={this.handleChange}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.sendAcademicAnnouncement} color="secondary">
            Send
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AcademicAnnounce.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesAcademicAnnounce)(AcademicAnnounce);
