import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { SettingPaths, SettingActions } from './SettingConstants';
import Settings from './Settings';

export default class SettingRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={SettingPaths.List}
          component={Settings}
          action={SettingActions.List}
        />
        <PropsRoute
          path={SettingPaths.Holidays}
          component={Settings}
          action={SettingActions.Holidays}
        />
        <PropsRoute
          path={SettingPaths.ResetUsersPwd}
          component={Settings}
          action={SettingActions.ResetUsersPwd}
        />
      </Fragment>
    );
  }
}
