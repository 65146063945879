import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import CustomizedTable from '../ui/table/CustomizedTable';
import { Loading } from '../ui/Loading';
import { Redirect } from 'react-router-dom';
import { TeacherPaths } from './TeacherConstans';
import { TeacherInfo } from './TeacherInfo';

const stylesListTeacher = () => ({});

class ListReportTeacherComp extends Component {
  static displayName = ListReportTeacherComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();

    this.state = {
      data: [],
      loading: true,
      teacherId: this.props.teacherId,
      teacherDetail: null,
      redirect: null
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateTeacherData();
  }

  populateTeacherData = async () => {
    const token = await authService.getAccessToken();
    const { teacherId } = this.state;
    const response = await fetch(
      `api/AspNetUsers/GetReportByTeacher/${teacherId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();

    const responseDetail = await fetch(`api/AspNetUsers/${teacherId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const dataDetail = await responseDetail.json();
    this.setState({ data: data, teacherDetail: dataDetail, loading: false });
  };

  refreshTeacher = async () => {
    await this.populateTeacherData();
    this.child.updateChildData(this.state.data);
  };

  backToList = () => {
    this.redirectTo(TeacherPaths.List);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  render() {
    const cols = [
      { name: 'class', header: 'Class', align: 'left' },
      { name: 'teachingHours', header: 'Teaching Hours', align: 'right' },
      {
        name: 'teachingHoursCatchUp',
        header: 'Teaching Hours Catc-hUp',
        align: 'right'
      }
    ];

    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <TeacherInfo data={this.state.teacherDetail} />
            <CustomizedTable
              onRef={actualChild => (this.child = actualChild)}
              rows={this.state.data}
              cols={cols}
              actions={null}
              eventReloadData={this.refreshTeacher}
            />
          </Fragment>
        )}
        {this.state.redirect}
      </Fragment>
    );
  }
}

ListReportTeacherComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListReportTeacher = withStyles(stylesListTeacher)(
  ListReportTeacherComp
);
