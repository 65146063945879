import { AdminRoles, Roles } from '../common/constants/Roles';
import {
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select
} from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { faArchive, faDonate } from '@fortawesome/free-solid-svg-icons';

import { AcademicPaths } from './AcademicConstants';
import { AdminPage } from '../ui/page/AdminPage';
import CommonDataTable from '../ui/table/CommonDataTable';
import { Keys } from '../common/constants/GeeOConstants';
import { Loading } from '../ui/Loading';
import PropTypes from 'prop-types';
import { ReservePaths } from './reserve/ReserveConstants';
import { StudentCoursePaths } from '../student-course/StudentCourseConstants';
import authService from '../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { withStyles } from '@material-ui/core/styles';

const stylesAcademicManage = theme => ({
  dataList: {
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    verticalAlign: 'bottom'
  },
  extendedFab: {}
});

class AcademicManageComp extends Component {
  static displayName = AcademicManageComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      searchConditions: {
        namePhone: '',
        campus: '',
        level: '',
        class: '',
        studentType: 0,
        suspended: false
      },
      dataCampus: [],
      dataLevel: [],
      dataClass: [],
      dataAllClass: null,
      dataStudentType: [
        { id: 1, name: 'Regular' },
        { id: 2, name: 'Catch Up' },
        { id: 3, name: 'Demo' }
      ],
      loading: true,
      loadingFilter: false,
      loadingList: false,
      listCols: cols,
      listActions: actions,
      pageTitle: 'Academic Information'
    };
  }

  componentDidMount() {
    this.populateDataFilter();
  }

  populateDataFilter = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);

    const [respLevel, respCampus, respClass, respUser] = await Promise.all([
      fetch(`api/StudyLevels`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/Campus/GetCampusList/${loggedInUser.sub}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/ClassCourses/GetClassCourseDropDown/${loggedInUser.sub}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/AspNetUsers/GetUserRole/${loggedInUser.sub}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [dataLevel, dataCampus, dataClass, dataUser] = await Promise.all([
      respLevel.json(),
      respCampus.json(),
      respClass.json(),
      respUser.json()
    ]);

    this.setState({
      dataLevel,
      dataCampus,
      dataClass,
      dataAllClass: dataClass,
      dataUser,
      loading: false
    });
  };

  handleKeyDown = e => {
    if (e.keyCode === Keys.ENTER_KEY) {
      this.populateStudentData();
    }
  };

  handleChange = name => event => {
    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;

    this.setState(
      prevState => ({
        searchConditions: {
          ...prevState.searchConditions,
          [attrName]: attrValue
        }
      }),
      () => {
        if (['campus', 'level'].includes(attrName)) {
          this.updateClassList();
        }
      }
    );
  };

  updateClassList = async () => {
    this.setState({ loadingFilter: true });
    const { searchConditions, dataAllClass } = this.state;

    if (searchConditions.campus === '' && searchConditions.level === '') {
      this.setState({ dataClass: dataAllClass, loadingFilter: false });
      return;
    }

    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    const [respClass] = await Promise.all([
      fetch(`api/ClassCourses/GetClassesWithFilter/${loggedInUser.sub}`, {
        method: 'POST',
        body: JSON.stringify(searchConditions),
        headers: headers
      })
    ]);
    const [dataClass] = await Promise.all([respClass.json()]);

    this.setState({ dataClass, loadingFilter: false });
  };

  populateStudentData = async () => {
    const { searchConditions, dataUser } = this.state;
    this.setState({ loadingList: true });
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const response = await fetch(
      `api/AcademicManage/GetListWithFilter/${loggedInUser.sub}`,
      {
        method: 'POST',
        body: JSON.stringify(searchConditions),
        headers: headers
      }
    );
    const dataList = await response.json();
    const listCols = searchConditions.suspended ? colsSuspend : cols;
    const listActions =
      searchConditions.suspended || dataUser?.role?.name === Roles.Teacher
        ? actsSuspend
        : actions;
    this.setState({ dataList, listCols, listActions, loadingList: false });
  };

  renderContent() {
    const { classes } = this.props;
    const {
      searchConditions,
      dataCampus,
      dataLevel,
      dataClass,
      dataUser,
      dataStudentType,
      loadingFilter,
      loadingList,
      dataList,
      listCols,
      listActions
    } = this.state;

    return (
      <Fragment>
        <Paper>
          <FormControl
            className={clsx(classes.formControl, classes.longTextField)}
          >
            <InputLabel htmlFor="name-phone">Name/Phone</InputLabel>
            <Input
              id="name-phone"
              name="namePhone"
              value={searchConditions['namePhone']}
              onChange={this.handleChange('namePhone')}
              onKeyDown={e => this.handleKeyDown(e)}
              inputProps={{
                'aria-label': 'Name/Phone'
              }}
            />
          </FormControl>
          <FormControl className={clsx(classes.formControl, classes.textField)}>
            <InputLabel htmlFor="campus-id">Campus</InputLabel>
            <Select
              id="campus-id"
              name="campus"
              value={searchConditions['campus']}
              onChange={this.handleChange('campus')}
            >
              <MenuItem value="" key="">
                All
              </MenuItem>
              {dataCampus.map(row => {
                return (
                  <MenuItem value={row.id} key={row.id}>
                    {row.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className={clsx(classes.formControl, classes.textField)}>
            <InputLabel htmlFor="level-id">Level</InputLabel>
            <Select
              id="level-id"
              name="level"
              value={searchConditions['level']}
              onChange={this.handleChange('level')}
            >
              <MenuItem value="" key="">
                All
              </MenuItem>
              {dataLevel.map(row => {
                return (
                  <MenuItem value={row.id} key={row.id}>
                    {row.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className={clsx(classes.formControl, classes.textField)}>
            <InputLabel htmlFor="class-id">Class</InputLabel>
            <Select
              id="class-id"
              name="class"
              value={searchConditions['class']}
              onChange={this.handleChange('class')}
            >
              <MenuItem value="" key="">
                All
              </MenuItem>
              {loadingFilter
                ? null
                : dataClass.map(row => {
                    return (
                      <MenuItem value={row.id} key={row.id}>
                        {row.name}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
          <FormControl className={clsx(classes.formControl, classes.textField)}>
            <InputLabel htmlFor="student-type">Type</InputLabel>
            <Select
              id="student-type"
              name="studentType"
              value={searchConditions['studentType']}
              onChange={this.handleChange('studentType')}
            >
              <MenuItem value="0" key="">
                All
              </MenuItem>
              {dataStudentType.map(row => {
                return (
                  <MenuItem value={row.id} key={row.id}>
                    {row.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {AdminRoles.indexOf(dataUser.role.name) > -1 && (
            <FormControl
              className={clsx(classes.formControl, classes.textField)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={searchConditions['suspended']}
                    onChange={this.handleChange('suspended')}
                  />
                }
                label={'Suspended/Terminated'}
              />
            </FormControl>
          )}
          <FormControl className={clsx(classes.formControl, classes.textField)}>
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="Search"
              className={classes.extendedFab}
              onClick={this.populateStudentData}
            >
              List All
            </Fab>
          </FormControl>
        </Paper>
        <Paper className={classes.dataList}>
          {loadingList ? (
            <Loading />
          ) : dataList ? (
            <CommonDataTable
              onRef={actualChild => (this.child = actualChild)}
              rows={dataList}
              cols={listCols}
              actions={listActions}
              isPaging={true}
            />
          ) : null}
        </Paper>
      </Fragment>
    );
  }

  render() {
    const { pageTitle, loading } = this.state;
    let content = loading ? <Loading /> : this.renderContent();

    return (
      <Fragment>
        <AdminPage title={pageTitle} content={content} />
      </Fragment>
    );
  }
}

const cols = [
  { name: 'studentName', header: 'Name', align: 'left' },
  { name: 'englishName', header: 'English Name' },
  { name: 'birthdayFormat', header: 'Birthday', align: 'left' },
  { name: 'fatherName', header: 'Father Name', align: 'left' },
  { name: 'fatherPhone', header: 'Father Phone', align: 'left' },
  { name: 'motherName', header: 'Mother Name', align: 'left' },
  { name: 'motherPhone', header: 'Mother Phone', align: 'left' },
  { name: 'address', header: 'Address', align: 'left' },
  { name: 'beginDateDisplay', header: 'Begin Date', align: 'left' }
];
const colsSuspend = [
  ...cols,
  { name: 'suspendDateFormat', header: 'Suspend  Date', align: 'left' },
  { name: 'terminateDateFormat', header: 'Terminate Date', align: 'left' }
];
const actsSuspend = [
  {
    name: 'detail',
    tooltip: 'View info',
    url: `${AcademicPaths.Info}`,
    icon_fa: faAddressCard
  }
];
const actions = [
  ...actsSuspend,
  {
    name: 'multi-route',
    tooltip: 'Renew',
    url: `${StudentCoursePaths.Create}`,
    icon_fa: faDonate
  },
  {
    name: 'edit',
    tooltip: 'Reserve',
    url: `${ReservePaths.Info}`,
    icon_fa: faArchive
  }
];

AcademicManageComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const AcademicManage = withStyles(stylesAcademicManage)(
  AcademicManageComp
);
