import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { PagingTable } from '../ui/table/PagingTable';
// import { CatchUpInfo } from './CatchUpInfo';

const stylesList = () => ({});

class ScheduleClassLessonSelectComp extends Component {
  static displayName = ScheduleClassLessonSelectComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      data: [],
      parentId: this.props.parentId,
      actionColName: this.props.actionColName,
      loading: true,
      catchUp: this.props.catchUp
    };
  }

  componentDidMount() {
    this.populateData();
  }

  populateData = async () => {
    const token = await authService.getAccessToken();
    const currentUser = await authService.getUser();
    const response = await fetch(
      `api/ClassLesson/GetClassLessonByTeacher/${currentUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({
      data: data,
      actionColName: 'selectClassLesson',
      loading: false
    });
    this.setChildDataSelect();
  };

  setChildDataSelect = () => {
    const { data } = this.state;
    this.child.setData(data);
  };

  callbackGetValueRadioBtn = e => {
    this.props.callbackGetValueRadioBtn(e);
  };

  render() {
    const cols = [
      { name: 'level', header: 'Level', align: 'right' },
      { name: 'class', header: 'Class', align: 'right' },
      { name: 'lesson', header: 'Lesson', align: 'right' },
      { name: 'beginDateFormat', header: 'Date', align: 'right' }
    ];
    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            {/* <CatchUpInfo catchUp={this.state.catchUp} /> */}
            <PagingTable
              onRef={actualChild => (this.child = actualChild)}
              cols={cols}
              isShowRadioButton="true"
              callbackGetValueRadioBtn={this.callbackGetValueRadioBtn}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

ScheduleClassLessonSelectComp.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(stylesList)(ScheduleClassLessonSelectComp);
