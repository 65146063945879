import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { Task } from './Task';
import { TaskPaths, TaskActions } from './TaskConstans';

export default class TaskRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={TaskPaths.List}
          component={Task}
          action={TaskActions.List}
        />
        <PropsRoute
          path={TaskPaths.ListAll}
          component={Task}
          action={TaskActions.ListAll}
        />
        <PropsRoute
          path={TaskPaths.EditRoute}
          component={Task}
          action={TaskActions.Edit}
        />
        <PropsRoute
          path={TaskPaths.Create}
          component={Task}
          action={TaskActions.Create}
        />
        <PropsRoute
          path={TaskPaths.CreateAssign}
          component={Task}
          action={TaskActions.AcadManagerCreate}
        />
        <PropsRoute
          path={TaskPaths.EditAssignRoute}
          component={Task}
          action={TaskActions.AcadManagerEdit}
        />
      </Fragment>
    );
  }
}
