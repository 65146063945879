import React, { Component } from 'react';
import clsx from 'clsx';
import { Grid, Paper, Typography } from '@material-ui/core';

export class RoomInfoView extends Component {
  render() {
    const { title, subtitle, roomInfo, classes } = this.props;

    return (
      <Paper className={clsx(classes.chartBox, classes.chartBoxEqualHeight)}>
        <Grid container>
          <div className={classes.chartHeadingBox}>
            <Typography variant="h3" className={clsx(classes.heading)}>
              {title}
            </Typography>
            <Typography variant="body2" className={clsx(classes.subtitle)}>
              {subtitle}
            </Typography>
          </div>
          <div className={classes.chartNumberBox}>
            <Typography
              variant="h1"
              className={clsx(classes.chartSmallText)}
              style={{ fontSize: 32, marginTop: '-1px !important' }}
            >
              {roomInfo.roomName}
            </Typography>
          </div>
        </Grid>

        <Grid container style={{ marginTop: 16 }}>
          <Grid container style={{ marginTop: 16 }} item xs={12}>
            <Grid item xs={9}>
              <Typography variant="h3" className={clsx(classes.subtitle)}>
                {'Tổng tần suất sử dụng'}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="h1"
                className={clsx(classes.infoNumber, classes.right)}
              >
                {roomInfo.totalUsedTime / 60 + 'h'}
              </Typography>
            </Grid>
          </Grid>
          {roomInfo.roomTimes.map((number, idx) => (
            <Grid container key={idx} style={{ marginTop: 16 }} item xs={12}>
              <Grid item xs={9}>
                <Typography variant="h3" className={clsx(classes.subtitle)}>
                  {'Tần suất sử dụng vào ' +
                    (idx < 6 ? 'thứ ' + (idx + 2) : 'chủ nhật')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="h1"
                  className={clsx(classes.infoNumber, classes.right)}
                >
                  {number / 60 + 'h'}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }
}
