import React, { Component } from 'react';

export class Loading extends Component {
  render() {
    return (
      <div style={{ padding: 16 }}>
        <p>
          <em>Loading...</em>
        </p>
      </div>
    );
  }
}
