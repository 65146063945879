import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import CommonDataTable from '../ui/table/CommonDataTable';
import CommonSearchToolbar from '../ui/table/CommonSearchToolbar';
import { Loading } from '../ui/Loading';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import { Redirect } from 'react-router-dom';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CalendarToday from '@material-ui/icons/CalendarToday';
import DialogContent from '@material-ui/core/DialogContent';
import { SchedulePaths } from './ScheduleConstans';
import ScheduleTbClassRoomSelect from './ScheduleTbClassRoomSelect';
import ScheduleTbClassCourseSelect from './ScheduleTbClassCourseSelect';
import {
  MessageBars,
  MessageBarVariants,
  MessageType
} from '../ui/popup/Messages';
import { grey } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles/colorManipulator';

const stylesList = theme => ({
  root: {
    float: 'right'
  },
  highlight: {
    float: 'right'
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    display: 'flex'
  },
  title: {
    flex: '0 0 auto'
  },
  viewClass: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(grey[500], 0.15),
    '&:hover': {
      backgroundColor: fade(grey[500], 0.25)
    },
    marginLeft: 0,
    marginTop: 6,
    marginRight: theme.spacing(1),
    width: '100%',
    height: 35,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200
      }
    }
  },
  button: {
    padding: theme.spacing(1)
  },
  margin: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  noOutline: {
    outline: 'none',
    margin: theme.spacing(1)
  }
});

class ListScheduleComp extends Component {
  static displayName = ListScheduleComp.name;

  constructor(...args) {
    super(...args);
    this.messageBar = React.createRef();
    this.child = React.createRef();
    this.state = {
      data: [],
      dataSelect: [],
      actionColName: '',
      loading: true,
      redirect: null,
      scheduleId: '',
      openDialog: false,
      selectedId: '',
      scheduleCurrent: null,
      containsEndClass: 0,
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Error}
          type={MessageType.Approve}
          message={'Class already exists!'}
          handleClose={this.handleCloseMessageBar}
        />
      )
    };
  }

  componentDidMount() {
    this.populateScheduleData();
  }

  openDialog = () => {
    this.setState({ openDialog: true });
  };

  closeDialog = () => {
    this.setState({ openDialog: false, selectedId: '' });
  };

  handleCloseMessageBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  populateScheduleData = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const { containsEndClass } = this.state;
    const response = await fetch(
      `api/Schedules/GetScheduleList/${loggedInUser.sub}/${containsEndClass}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({ data, loading: false });
  };

  handleFilters = withEndClass => {
    this.setState(
      { loading: true, containsEndClass: withEndClass ? 1 : 0 },
      this.populateScheduleData
    );
  };

  refreshData = async () => {
    await this.populateScheduleData();
    this.setChildData('');
  };

  callbackGetIdShowPopup = async (e, action) => {
    action === 'selectClassCourse'
      ? this.setState({
          actionColName: 'selectClassCourse',
          scheduleId: e.id,
          scheduleCurrent: e
        })
      : this.setState({
          actionColName: 'selectClassRoom',
          scheduleId: e.id,
          scheduleCurrent: e
        });
    this.openDialog();
  };

  callbackGetValueRadioBtn = e => {
    this.setState({ selectedId: e });
  };

  saveDataSelected = async () => {
    const { selectedId, actionColName, scheduleId } = this.state;
    const token = await authService.getAccessToken();

    if (actionColName === 'selectClassCourse') {
      const response = await fetch(
        `api/Schedules/UpdateScheduleInClassCourse/${scheduleId}/${selectedId}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      if (response.status === 409) {
        this.messageBar.showMessage(
          MessageBarVariants.Error,
          MessageType.Approve,
          'Class already exists!'
        );
      } else {
        await fetch(`api/ClassLesson/BuildSheduleClassLessons/${selectedId}`, {
          method: 'POST',
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        });
      }
    } else {
      await fetch(
        `api/Schedules/UpdateScheduleInClassRoom/${scheduleId}/${selectedId}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
    }
    this.refreshData();
    this.closeDialog();
  };

  viewAllClassCalender = () => {
    this.redirectTo(SchedulePaths.ViewAllClassCalendar);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  handleChangeSearchTerm = filterText => {
    this.setChildData(filterText);
  };

  setChildData = filterText => {
    const { data } = this.state;
    const searchTerm = filterText.toLowerCase();
    let filteredRows = data.filter(item => {
      const itemText = (item.name + item.classCourseFormat).toLowerCase();
      return itemText.indexOf(searchTerm) > -1;
    });
    this.child.setData(filteredRows);
  };

  render() {
    const { classes } = this.props;
    const cols = [
      { name: 'name', header: 'Name', align: 'left' },
      { name: 'startDateLocal', header: 'Start Date' },
      { name: 'endDateLocal', header: 'End Date' },
      { name: 'scheduleFormat', header: 'Schedule' },
      { name: 'startTimeLocal', header: 'Lesson Start Time' },
      { name: 'endTimeLocal', header: 'Lesson End Time' },
      { name: 'classRoomFormat', header: 'Room' },
      { name: 'classCourseFormat', header: 'Class' },
      { name: 'campusFormat', header: 'Campus' }
    ];
    const actions = [
      {
        name: 'detail',
        tooltip: `Calendar`,
        url: `${SchedulePaths.ViewCalendar}`,
        icon: 'calendar_today'
      },
      {
        name: 'selectClassCourse',
        tooltip: 'Select Class',
        icon: 'book'
      },
      {
        name: 'selectClassRoom',
        tooltip: 'Select Room',
        icon: 'room'
      },
      {
        name: 'edit',
        tooltip: 'Edit',
        url: `${SchedulePaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'delete',
        tooltip: 'Delete',
        url: `${SchedulePaths.Delete}`,
        icon: 'delete_icon'
      }
    ];

    return (
      <Fragment>
        <Paper>
          <CommonSearchToolbar
            handleChange={this.handleChangeSearchTerm}
            checkCondition="Finished Classes"
            handleFilters={this.handleFilters}
            customCommands={
              <Fab
                variant="extended"
                size="medium"
                color="secondary"
                aria-label="add"
                style={{
                  width: 130,
                  height: 36,
                  marginLeft: 16,
                  marginRight: 8
                }}
                onClick={this.viewAllClassCalender}
              >
                <CalendarToday className={classes.extendedIcon} />
                View All
              </Fab>
            }
          />
          {this.state.loading ? (
            <Loading />
          ) : (
            <CommonDataTable
              onRef={actualChild => (this.child = actualChild)}
              rows={this.state.data}
              cols={cols}
              actions={actions}
              eventReloadData={this.refreshData}
              isShowPopup={true}
              callbackGetIdShowPopup={this.callbackGetIdShowPopup}
              isPaging={true}
            />
          )}
        </Paper>
        <Dialog
          open={this.state.openDialog}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth
        >
          <DialogContent>
            {this.state.actionColName === 'selectClassRoom' ? (
              <ScheduleTbClassRoomSelect
                scheduleId={this.state.scheduleId}
                actionColName={this.state.actionColName}
                callbackGetValueRadioBtn={this.callbackGetValueRadioBtn}
                schedule={this.state.scheduleCurrent}
              />
            ) : (
              ''
            )}
            {this.state.actionColName === 'selectClassCourse' ? (
              <ScheduleTbClassCourseSelect
                scheduleId={this.state.scheduleId}
                actionColName={this.state.actionColName}
                callbackGetValueRadioBtn={this.callbackGetValueRadioBtn}
                schedule={this.state.scheduleCurrent}
              />
            ) : (
              ''
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={this.saveDataSelected} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.messageBar}
        {this.state.redirect}
      </Fragment>
    );
  }
}

ListScheduleComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListSchedule = withStyles(stylesList)(ListScheduleComp);
