import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import CommonDataTable from '../ui/table/CommonDataTable';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Loading } from '../ui/Loading';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import DateFnsUtils from '@date-io/date-fns';
import { ProposalPaths } from './ProposalConstants';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import clsx from 'clsx';

const stylesList = theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'end'
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  },
  legend: {
    marginBottom: theme.spacing(2)
  },
  longTextField: {
    width: '100%'
  },
  formControl: {
    margin: theme.spacing(3, 3, 1, 5)
  }
});

class ListProposalComp extends Component {
  static displayName = ListProposalComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      data: [],
      dataLevel: [],
      dataCampus: [],
      loading: true,
      objFilter: {
        levelId: '',
        learningProccess: '',
        numberOfPupils: '',
        campusId: '',
        room: '',
        startDate: null,
        endDate: null,
        schedule: '',
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
      }
    };
  }

  componentDidMount() {
    this.populateData();
  }

  populateData = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const response = await fetch(
      `api/Student/GetClassPropose/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({ data: data, loading: false });
    this.setChildData();
    this.populateDataFilter();
  };

  setChildData = () => {
    const { objFilter, data } = this.state;

    let filteredRows = data.filter(
      e =>
        (objFilter.campusId === '' || e.campusId === objFilter.campusId) &&
        (objFilter.levelId === '' || e.levelId === objFilter.levelId) &&
        (objFilter.room === '' ||
          (e.room && e.room.includes(objFilter.room)) ||
          e.address.includes(objFilter.room)) &&
        (objFilter.learningProccess === '' ||
          e.learningProccess === objFilter.learningProccess) &&
        (objFilter.numberOfPupils === '' ||
          e.numberOfPupils.toString() === objFilter.numberOfPupils)
    );

    filteredRows = filteredRows.filter(
      e =>
        (e.schedule &&
          e.schedule.monday === objFilter.monday &&
          e.schedule.monday === true) ||
        (e.schedule &&
          e.schedule.tuesday === objFilter.tuesday &&
          e.schedule.tuesday === true) ||
        (e.schedule &&
          e.schedule.wednesday === objFilter.wednesday &&
          e.schedule.wednesday === true) ||
        (e.schedule &&
          e.schedule.thursday === objFilter.thursday &&
          e.schedule.thursday === true) ||
        (e.schedule &&
          e.schedule.friday === objFilter.friday &&
          e.schedule.friday === true) ||
        (e.schedule &&
          e.schedule.saturday === objFilter.saturday &&
          e.schedule.saturday === true) ||
        (e.schedule &&
          e.schedule.sunday === objFilter.sunday &&
          e.schedule.sunday === true)
    );
    if (objFilter.startDate) {
      filteredRows = filteredRows.filter(
        e =>
          objFilter.startDate === '' ||
          (e.startDate &&
            new Date(e.startDate).setHours(0, 0) >=
              objFilter.startDate.setHours(0, 0))
      );
    }
    if (objFilter.endDate) {
      filteredRows = filteredRows.filter(
        e =>
          objFilter.endDate === '' ||
          (e.endDate &&
            new Date(e.endDate).setHours(0, 0) <=
              objFilter.endDate.setHours(0, 0))
      );
    }
    this.child.setData(filteredRows);
  };

  populateDataFilter = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const response = await fetch('api/StudyLevels', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();

    const responseCampus = await fetch(
      `api/Campus/GetCampusList/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const dataCampus = await responseCampus.json();

    this.setState({ dataLevel: data, dataCampus: dataCampus });
  };

  refreshData = async () => {
    await this.populateData();
    this.child.updateChildData(this.state.data);
  };

  handleChange = name => event => {
    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;
    this.setState(
      prevState => ({
        objFilter: {
          ...prevState.objFilter,
          [attrName]: attrValue
        }
      }),
      this.setChildData
    );
  };

  handleDateChange = name => date => {
    const attrName = name;
    const attrValue = date;
    this.setState(
      prevState => ({
        objFilter: {
          ...prevState.objFilter,
          [attrName]: attrValue
        }
      }),
      this.setChildData
    );
  };

  render() {
    const { classes } = this.props;
    const { dataLevel, objFilter, dataCampus } = this.state;
    const cols = [
      { name: 'level', header: 'Level', align: 'left' },
      { name: 'class', header: 'Class' },
      { name: 'scheduleFormat', header: 'Schedule', align: 'left' },
      { name: 'startDateLocal', header: 'Start Date', align: 'left' },
      { name: 'endDateLocal', header: 'End Date', align: 'left' },
      { name: 'campus', header: 'Campus', align: 'left' },
      { name: 'address', header: 'Address', align: 'left' },
      { name: 'room', header: 'Room', align: 'left' },
      { name: 'learningProccess', header: 'Progress (Days)', align: 'center' },
      { name: 'numberOfPupils', header: 'Number Of Pupils', align: 'center' }
    ];
    const actions = [
      {
        name: 'detail',
        tooltip: 'Add new student',
        url: `${ProposalPaths.Edit}`,
        icon: 'person_add'
      },
      {
        name: 'detail',
        tooltip: 'Select existing student',
        url: `${ProposalPaths.SelectStudent}`,
        icon: 'assignment_ind'
      }
    ];
    const colsFilter = [
      { name: 'levelId', header: 'Level', placeholder: 'Level', adornment: '' },
      {
        name: 'learningProccess',
        header: 'Progress (Days)',
        placeholder: 'Progress (Days)',
        adornment: ''
      },
      {
        name: 'numberOfPupils',
        header: 'Number Of Pupils',
        placeholder: 'Number Of Pupils',
        adornment: ''
      },
      {
        name: 'campusId',
        header: 'Campus',
        placeholder: 'Campus',
        adornment: ''
      },
      {
        name: 'room',
        header: 'Room - Address',
        placeholder: 'Room - Address',
        adornment: ''
      },
      {
        name: 'schedule',
        header: 'Schedule',
        placeholder: 'Schedule',
        adornment: ''
      }
    ];
    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <Paper>
              <Grid container>
                <Grid item xs={6} className={classes.cell}>
                  <List className={classes.list}>
                    <ListItem className={classes.listItem}>
                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                      >
                        <InputLabel htmlFor="level-id">
                          {colsFilter[0].header}
                        </InputLabel>
                        <Select
                          id="level-id"
                          name={colsFilter[0].name}
                          value={objFilter[colsFilter[0].name]}
                          onChange={this.handleChange(colsFilter[0].name)}
                        >
                          <MenuItem value="" key="">
                            All
                          </MenuItem>
                          {dataLevel.map(row => {
                            return (
                              <MenuItem value={row.id} key={row.id}>
                                {row.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                      >
                        <InputLabel htmlFor="campus-id">
                          {colsFilter[3].header}
                        </InputLabel>
                        <Select
                          id="campus-id"
                          name={colsFilter[3].name}
                          value={objFilter[colsFilter[3].name]}
                          onChange={this.handleChange(colsFilter[3].name)}
                        >
                          <MenuItem value="" key="">
                            All
                          </MenuItem>
                          {dataCampus.map(row => {
                            return (
                              <MenuItem value={row.id} key={row.id}>
                                {row.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <TextField
                        id="room-address"
                        style={{ margin: 8 }}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        rows="6"
                        className={classes.margin}
                        label={colsFilter[4].header}
                        placeholder={colsFilter[4].placeholder}
                        name={colsFilter[4].name}
                        value={objFilter[colsFilter[4].name]}
                        onChange={this.handleChange(colsFilter[4].name)}
                      />
                    </ListItem>

                    <ListItem className={classes.listItem}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid
                          container
                          className={classes.grid}
                          justify="space-around"
                        >
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-start"
                            label="Start Date"
                            format="dd/MM/yyyy"
                            value={objFilter.startDate}
                            onChange={this.handleDateChange('startDate')}
                            KeyboardButtonProps={{
                              'aria-label': 'change start date'
                            }}
                          />
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-end"
                            label="End Date"
                            format="dd/MM/yyyy"
                            value={objFilter.endDate}
                            onChange={this.handleDateChange('endDate')}
                            KeyboardButtonProps={{
                              'aria-label': 'change end date'
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} className={classes.cell}>
                  <List className={classes.list}>
                    <ListItem className={classes.listItem}>
                      <TextField
                        id="numberOfPupils"
                        style={{ margin: 8 }}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        type="number"
                        rows="6"
                        className={classes.margin}
                        label={colsFilter[2].header}
                        placeholder={colsFilter[2].placeholder}
                        name={colsFilter[2].name}
                        value={objFilter[colsFilter[2].name]}
                        onChange={this.handleChange(colsFilter[2].name)}
                      />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <TextField
                        id="progress"
                        style={{ margin: 8 }}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        type="number"
                        rows="6"
                        className={classes.margin}
                        label={colsFilter[1].header}
                        placeholder={colsFilter[1].placeholder}
                        name={colsFilter[1].name}
                        value={objFilter[colsFilter[1].name]}
                        onChange={this.handleChange(colsFilter[1].name)}
                      />
                    </ListItem>

                    <ListItem className={classes.listItem}>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel
                          component="legend"
                          className={classes.legend}
                        >
                          Schedule
                        </FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={objFilter.monday}
                                onChange={this.handleChange('monday')}
                                value="Monday"
                              />
                            }
                            label="Monday"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={objFilter.tuesday}
                                onChange={this.handleChange('tuesday')}
                                value="Tuesday"
                              />
                            }
                            label="Tuesday"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={objFilter.wednesday}
                                onChange={this.handleChange('wednesday')}
                                value="Wednesday"
                              />
                            }
                            label="Wednesday"
                          />
                        </FormGroup>
                      </FormControl>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel
                          component="legend"
                          className={classes.legend}
                        >
                          &nbsp;
                        </FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={objFilter.thursday}
                                onChange={this.handleChange('thursday')}
                                value="Thursday"
                              />
                            }
                            label="Thursday"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={objFilter.friday}
                                onChange={this.handleChange('friday')}
                                value="Friday"
                              />
                            }
                            label="Friday"
                          />
                        </FormGroup>
                      </FormControl>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel
                          component="legend"
                          className={classes.legend}
                        >
                          &nbsp;
                        </FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={objFilter.saturday}
                                onChange={this.handleChange('saturday')}
                                value="Saturday"
                              />
                            }
                            label="Saturday"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={objFilter.sunday}
                                onChange={this.handleChange('sunday')}
                                value="Sunday"
                              />
                            }
                            label="Sunday"
                          />
                        </FormGroup>
                      </FormControl>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.root}>
              <CommonDataTable
                onRef={actualChild => (this.child = actualChild)}
                rows={this.state.data}
                cols={cols}
                actions={actions}
                eventReloadData={this.refreshData}
                isPaging={true}
              />
            </Paper>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

ListProposalComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListProposal = withStyles(stylesList)(ListProposalComp);
