import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { PagingTable } from '../ui/table/PagingTable';
import CommonSearchToolbar from '../ui/table/CommonSearchToolbar';

const stylesList = () => ({});

class SelectClassComp extends Component {
  static displayName = SelectClassComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      data: [],
      classLessonId: this.props.classLessonId,
      loading: true,
      catchUp: this.props.catchUp
    };
  }

  componentDidMount() {
    this.populateData();
  }

  populateData = async () => {
    const token = await authService.getAccessToken();

    const response = await fetch(`api/ClassCourses/GetClassCourseSchedule`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({
      data: data,
      loading: false
    });
    this.setChildDataSelect(data);
  };

  setChildDataSelect = data => {
    this.child.setData(data);
  };

  callbackGetValueRadioBtn = e => {
    this.props.callbackGetValueRadioBtn(e);
    var newClass = this.state.data.filter(x => x.id === e);
    this.props.callbackGetValueNewClass(newClass);
  };

  onSearchChanged = filterText => {
    const { data } = this.state;
    let filteredRows = data.filter(item => {
      return (
        (item.class || item.level)
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) > -1
      );
    });
    this.setChildDataSelect(filteredRows);
  };

  render() {
    const cols = [
      { name: 'level', header: 'Level', align: 'right' },
      { name: 'class', header: 'Class', align: 'right' },
      { name: 'schedule', header: 'Schedule', align: 'right' },
      { name: 'startTimeLocal', header: 'Start Time', align: 'right' },
      { name: 'endTimeLocal', header: 'End Time', align: 'right' },
      { name: 'teacher', header: 'Teacher', align: 'right' }
    ];
    return (
      <Fragment>
        <CommonSearchToolbar handleChange={this.onSearchChanged} />
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <PagingTable
              onRef={actualChild => (this.child = actualChild)}
              cols={cols}
              isShowRadioButton="true"
              callbackGetValueRadioBtn={this.callbackGetValueRadioBtn}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

SelectClassComp.propTypes = {
  classes: PropTypes.object.isRequired,
  callbackGetValueRadioBtn: PropTypes.func,
  callbackGetValueNewClass: PropTypes.func,
  classLessonId: PropTypes.string,
  catchUp: PropTypes.any
};
export default withStyles(stylesList)(SelectClassComp);
