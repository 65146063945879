import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import {
  CardHeader,
  FormControl,
  Grid,
  Input,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@material-ui/core';
import {
  MessageBarVariants,
  MessageBars,
  MessageType
} from '../../ui/popup/Messages';
import React, { Component } from 'react';

import { Fragment } from 'react';
import { Loading } from '../../ui/Loading';
import PropTypes from 'prop-types';
import { StudentActions } from '../StudentConstans';
import authService from '../../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { fetchHelper } from '../../../helpers/fetch-helper';
import { stylesEditStudent } from '../styleEditStudent';
import { withStyles } from '@material-ui/core/styles';

const filter = createFilterOptions();
const IParent = {
  name: '',
  yob: '',
  job: '',
  relation: 0,
  email: '',
  phoneNumber: '',
  address: ''
};

class StudentParent extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      parents: [],
      currentParent: null,
      isAvailable: false,
      isEditMode: this.props?.action === StudentActions.Edit,
      loading: true
    };

    this.messageBar = React.createRef();
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateStudentParentData();
    this.getParents();
  }

  populateStudentParentData = async () => {
    const { action, studentId, relation } = this.props;
    let parent = {
      ...IParent,
      relation: relation
    };
    if (action === StudentActions.Edit) {
      const token = await authService.getAccessToken();
      const respParent = await fetch(
        `api/StudentParents/GetParentByStudent/${studentId}/${relation}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      if (respParent.status === 200) {
        parent = await respParent.json();
      }
    }
    this.setState({
      parent,
      currentParent: parent,
      loading: false
    });
  };

  getParents = async () => {
    fetchHelper.get(`api/Parents`).then(parents => {
      this.setState({ parents });
    });
  };

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      parent: {
        ...prevState.parent,
        [attrName]: attrValue
      }
    }));
  };

  isValid = () => {
    const {
      parent,
      currentParent,
      parents,
      isAvailable,
      isEditMode
    } = this.state;
    const isFather = this.props?.relation === 1;
    const prefix = isFather ? 'Father' : 'Mother';
    let message = '';
    let hasError = false;
    if (!parent.name && isFather) {
      message = `${prefix} name is required.` + '\n';
      hasError = true;
    } else if (!isAvailable && !parent.phoneNumber && isFather) {
      message += `${prefix} phone number is required.`;
      hasError = true;
    } else if (
      !isAvailable &&
      parent.phoneNumber &&
      parents.filter(
        x => x.phoneNumber === parent.phoneNumber && x.name !== parent.name
      )?.length > 0
    ) {
      if (isEditMode && parent.phoneNumber === currentParent.phoneNumber)
        return;
      message += `${prefix} phone number is used.`;
      hasError = true;
    }

    return { hasError, message };
  };

  saveData = async student => {
    const { action, studentId } = this.props;
    const { parent, currentParent } = this.state;
    // if (this.isValid()?.hasError) {
    //   return;
    // }

    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    let method = 'PUT';
    let url = null;

    if (action === StudentActions.Create && !currentParent.id) {
      method = 'POST';
      url = `api/Parents/CreateParent/${student.id}`;
    } else {
      url = `api/Parents/${currentParent.id}/${studentId}`;
    }
    parent.createdBy = loggedInUser.sub;
    parent.modifiedBy = loggedInUser.sub;

    fetch(url, {
      method,
      body: JSON.stringify(parent),
      headers: headers
    }).catch(error => {
      console.error('Error:', error);
    });
  };

  messageBarComp() {
    return (
      <MessageBars
        onRef={actualChild => (this.messageBar = actualChild)}
        variant={MessageBarVariants.Error}
        type={MessageType.Approve}
        message={'Cannot preview!'}
        handleClose={this.handleCloseMessageBar}
      />
    );
  }

  handleCloseMessageBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  renderContent() {
    const { classes, relation } = this.props;
    const { parent, currentParent, isAvailable, isEditMode } = this.state;

    return (
      <Paper className={classes.root}>
        <CardHeader title={parent.relation === 1 ? 'Father' : 'Mother'} />
        <Grid container>
          <Grid item xs={6} className={classes.cell}>
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                >
                  <InputLabel htmlFor="relation">Relation</InputLabel>
                  <Select
                    value={parent.relation}
                    onChange={this.handleChange('relation')}
                    disabled={true}
                  >
                    <MenuItem value="1">Father</MenuItem>
                    <MenuItem value="2">Mother</MenuItem>
                  </Select>
                </FormControl>
                <Autocomplete
                  id="name"
                  options={this.state.parents.filter(
                    x => x.relation === parent.relation
                  )}
                  getOptionLabel={option => {
                    if (option?.inputValue) {
                      return option?.inputValue;
                    }
                    return option?.name;
                  }}
                  style={{ width: 300 }}
                  renderInput={params => (
                    <TextField {...params} label="Name" variant="outlined" />
                  )}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                      const _p = isEditMode ? currentParent : IParent;
                      filtered.push({
                        ..._p,
                        id: isEditMode ? parent?.id : undefined,
                        relation: relation,
                        name: params.inputValue,
                        inputValue: `Add "${params.inputValue}"`
                      });
                    }

                    return filtered;
                  }}
                  value={parent}
                  onChange={(event, item) => {
                    if (isEditMode) {
                      item !== null &&
                        this.setState({
                          parent: { ...item, inputValue: item?.name },
                          isAvailable:
                            item?.id !== currentParent?.id && !item?.inputValue
                        });
                    } else {
                      item !== null
                        ? this.setState({
                            parent: { ...item, inputValue: item?.name },
                            isAvailable: item?.id
                          })
                        : this.setState({
                            parent: {
                              ...IParent,
                              relation: relation
                            },
                            isAvailable: false
                          });
                    }
                  }}
                  selectOnFocus
                  disableClearable={isEditMode}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                >
                  <InputLabel htmlFor="phone-number">Phone Number</InputLabel>
                  <Input
                    value={parent.phoneNumber || ''}
                    onChange={this.handleChange('phoneNumber')}
                    disabled={isAvailable}
                  />
                </FormControl>
                <TextField
                  style={{ margin: 8 }}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  className={classes.margin}
                  label="Email"
                  value={parent.email || ''}
                  onChange={this.handleChange('email')}
                  disabled={isAvailable}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6} className={classes.cell}>
            <List>
              <ListItem className={classes.listItem}>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                >
                  <InputLabel htmlFor="yob">Year of Birth</InputLabel>
                  <Input
                    type="number"
                    value={parent.yob || ''}
                    onChange={this.handleChange('yob')}
                    disabled={isAvailable}
                  />
                </FormControl>
                <TextField
                  style={{ margin: 8 }}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  className={classes.margin}
                  label="Job"
                  value={parent.job || ''}
                  onChange={this.handleChange('job')}
                  disabled={isAvailable}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <TextField
                  style={{ margin: 8 }}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  className={classes.margin}
                  label="Address"
                  value={parent.address || ''}
                  onChange={this.handleChange('address')}
                  disabled={isAvailable}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderContent();
    return (
      <Fragment>
        {contents} {this.messageBarComp()}
      </Fragment>
    );
  }
}
StudentParent.propTypes = {
  classes: PropTypes.object.isRequired,
  relation: PropTypes.any,
  action: PropTypes.any,
  studentId: PropTypes.string,
  onRef: PropTypes.any
};

export default withStyles(stylesEditStudent)(StudentParent);
