import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
// import IconButton from '@material-ui/core/IconButton';
// import MoreVertIcon from '@material-ui/icons/MoreVert';

const stylesAdminPage = theme => ({
  card: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(0, 2, 1, 2)
  },
  title: {
    color: theme.palette.primary.light,
    textTransform: 'uppercase',
    textRendering: 'optimizeLegibility',
    fontSmoothing: 'antialiased',
    textShadow: '0.25px 0.25px 1.25px'
  },
  actions: {
    display: 'flex',
    padding: theme.spacing(0, 1, 1, 1)
  },
  fab: {
    margin: theme.spacing(0, 1)
  }
});

class AdminPageComp extends Component {
  static displayName = AdminPageComp.name;

  render() {
    const {
      classes,
      title,
      content,
      subContent,
      actions,
      ...rest
    } = this.props;

    return (
      <div {...rest}>
        <Card className={classes.card}>
          <CardHeader
            classes={{
              root: classes.header,
              title: classes.title
            }}
            title={title}
          />
          <CardContent>{content}</CardContent>
          {actions && (
            <CardActions className={classes.actions}>{actions}</CardActions>
          )}
        </Card>
        {subContent && (
          <Card className={classes.card}>
            <CardContent>{subContent}</CardContent>
          </Card>
        )}
      </div>
    );
  }
}

AdminPageComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const AdminPage = withStyles(stylesAdminPage)(AdminPageComp);
