import React, { Component } from 'react';

import { AdminPage } from '../ui/page/AdminPage';
import { Grid } from '@material-ui/core';
import Notifications from './Notifications';

export class CommonDashboard extends Component {
  constructor(...args) {
    super(...args);
  }

  renderContent() {
    const { calendarComponent } = this.props;

    return (
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {calendarComponent}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Notifications />
        </Grid>
      </Grid>
    );
  }

  render() {
    const displayContent = this.renderContent();
    return <AdminPage title="Dashboard" content={displayContent} />;
  }
}
