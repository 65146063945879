import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'date-fns';
import authService from '../api-authorization/AuthorizeService';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import { Loading } from '../ui/Loading';
import CommonDataTable from '../ui/table/CommonDataTable';
import CommonSearchToolbar from '../ui/table/CommonSearchToolbar';

class SelectStudentDialog extends Component {
  constructor(...args) {
    super(...args);
    this.studentList = React.createRef();
    this.state = {
      freeStudents: [],
      filterText: '',
      open: false,
      loading: true
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateFreeStudents();
  }

  populateFreeStudents = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch(`api/ClassStudents/GetFreeStudents`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const freeStudents = await response.json();
    this.setState({ freeStudents, loading: false });
  };

  openSelectStudent(mainButtonText, mustSelectItem) {
    this.setState({ mainButtonText, mustSelectItem }, () => this.openDialog());
  }

  openDialog = () => {
    this.setState({ open: true });
  };
  closeDialog = () => {
    if (this.props.actionNoSelectedItem) {
      this.props.actionNoSelectedItem();
    }
    this.setState({ open: false });
  };

  setFilteredListData = filterText => {
    const { freeStudents } = this.state;

    const searchTerm = filterText.toLowerCase();
    const filteredRows = freeStudents.filter(e => {
      const itemText = (
        e.studentName +
        e.englishName +
        e.fatherName +
        e.motherName +
        e.studentTypeText
      ).toLowerCase();
      return itemText.indexOf(searchTerm) > -1;
    });
    this.studentList.setData(filteredRows);
  };

  setDropdownFiltered = filterText => {
    const { freeStudents } = this.state;

    const filteredRows = freeStudents.filter(e => {
      return !filterText || e.studentTypeText === filterText;
    });
    this.studentList.setData(filteredRows);
  };

  handleMainButton = () => {
    const { selectedItems } = this.state;
    if (this.props.handleReturnData) {
      this.props.handleReturnData(selectedItems);
    }
    this.setState({ open: false });
  };

  updateSelectedItems = selectedItems => {
    this.setState({ selectedItems });
  };

  render() {
    const { classes } = this.props;
    const {
      freeStudents,
      mainButtonText,
      mustSelectItem,
      selectedItems,
      open,
      loading
    } = this.state;

    const studentListCols = [
      { name: 'studentName', header: 'Name', align: 'left' },
      { name: 'englishName', header: 'English Name', align: 'left' },
      { name: 'birthdayFormat', header: 'Birthday', align: 'left' },
      { name: 'fatherName', header: 'Father Name', align: 'left' },
      { name: 'motherName', header: 'Mother Name', align: 'left' },
      { name: 'studentTypeText', header: 'Type', align: 'left' }
    ];

    return (
      <Dialog
        open={open}
        onClose={this.closeDialog}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={mustSelectItem}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {loading ? (
            <Loading />
          ) : (
            <Fragment>
              <CommonSearchToolbar
                title="Select student"
                customToolbarStyle={classes.customToolbarStyle}
                handleChange={this.setFilteredListData}
                dropdownOptions={[
                  { id: 1, name: 'Demo' },
                  { id: 2, name: 'Suspended' },
                  { id: 3, name: 'Terminated' }
                ]}
                handleDropdown={this.setDropdownFiltered}
              />
              <CommonDataTable
                onRef={actualChild => (this.studentList = actualChild)}
                rows={freeStudents}
                cols={studentListCols}
                isPaging={true}
                rowsPerPage={10}
                actions={null}
                isShowRadioButton={true}
                callbackFromParent={this.updateSelectedItems}
              />
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleMainButton}
            color="secondary"
            disabled={!selectedItems}
          >
            {mainButtonText}
          </Button>
          {!mustSelectItem && (
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const stylesEdit = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  customToolbarStyle: {
    minHeight: 50
  }
});

SelectStudentDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesEdit)(SelectStudentDialog);
