import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { Grid, Paper } from '@material-ui/core';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import {
  MessageBars,
  MessageBarVariants,
  MessageType,
  MessageBarText
} from '../ui/popup/Messages';

import { StudentRatingStar } from '../assessment/StudentRatingStar';
import { StudentAssessment } from '../assessment/StudentAssessment';
import { stylesAssessment } from './stylesAssessment';

class StarScoreAssessment extends Component {
  static displayName = StarScoreAssessment.name;
  constructor(...args) {
    super(...args);
    this.studentStarScore = React.createRef();
    this.studentAssessment = React.createRef();
    this.messageBar = React.createRef();
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.getClassStudentList();
  }

  getClassStudentList = async () => {
    const { logId, classId, classLessonId } = this.props;
    const token = await authService.getAccessToken();
    const headers = !token ? {} : { Authorization: `Bearer ${token}` };
    const [
      respLessonLog,
      respClassStudents,
      respRatingStar,
      respStudentAssessment,
      respClassStudentPresence,
      respAssess
    ] = await Promise.all([
      fetch(`api/TeacherLessonLog/GetClassLessonInfo/${logId}`, {
        headers: headers
      }),
      fetch(
        `api/ClassStudents/GetStudentPresenceByClassLesson/${classLessonId}`,
        {
          headers: headers
        }
      ),
      fetch(`api/TeacherLessonLog/GetStudentLogDataOfClass/${classLessonId}`, {
        headers: headers
      }),
      fetch(
        `api/StudentAssessment/GetStudentAssessmentOfClass/${classLessonId}`,
        {
          headers: headers
        }
      ),
      fetch(`api/ClassStudents/GetStudentByClass/${classId}`, {
        headers: headers
      }),
      fetch(`api/StudentAssessment/GetAssessmentCriteria`, {
        headers: headers
      })
    ]);
    const [
      lessonLog,
      currentClassStudents,
      currentRatingStar,
      currentStudentAssessment,
      currentClassStudentPresence,
      studentAssessments
    ] = await Promise.all([
      respLessonLog.json(),
      respClassStudents.json(),
      respRatingStar.json(),
      respStudentAssessment.json(),
      respClassStudentPresence.json(),
      respAssess.json()
    ]);

    this.setState({
      lessonLog,
      currentClassStudents,
      currentRatingStar,
      currentStudentAssessment,
      currentClassStudentPresence,
      studentAssessments,
      loading: false
    });
    this.props.updateStatus({
      ...lessonLog,
      lastSaved: new Date(lessonLog.logDateTime)
    });
  };

  slideNext = () => {
    this.state.swiper.slideNext();
  };
  slidePrev = () => {
    this.state.swiper.slidePrev();
  };

  addRatingStar = async (studentId, logId, headers) => {
    const studentLessonLogNew = {
      logId: logId,
      studentInfoId: studentId
    };
    const response = await fetch(
      `api/TeacherLessonLog/AddTodayStudentLessonLog`,
      {
        method: 'POST',
        body: JSON.stringify(studentLessonLogNew),
        headers: headers
      }
    );
    return response.ok ? await response.json() : null;
  };

  saveRatingStar = async (studentId, logId, ratingStarMap, headers) => {
    let studentLessonLog = await this.addRatingStar(studentId, logId, headers);
    studentLessonLog.starScore = ratingStarMap[studentId];

    fetch(`api/TeacherLessonLog/SaveStudentLessonLog/${studentLessonLog.id}`, {
      method: 'PUT',
      body: JSON.stringify(studentLessonLog),
      headers: headers
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });

    return studentLessonLog;
  };

  addStudentAssessment = async (studentLessonLogId, criteriaId, headers) => {
    const studentAssessmentNew = {
      studentLessonLogId,
      assessmentCriteriaId: criteriaId
    };
    const response = await fetch(`api/StudentAssessment/AddStudentAssessment`, {
      method: 'POST',
      body: JSON.stringify(studentAssessmentNew),
      headers: headers
    });
    return response.ok ? await response.json() : null;
  };

  saveStudentAssessment = async (
    studentId,
    studentLessonLogId,
    criteriaId,
    criteriaNumber,
    studentAssessmentMap,
    headers
  ) => {
    let studentAssessment = await this.addStudentAssessment(
      studentLessonLogId,
      criteriaId,
      headers
    );
    studentAssessment.score = studentAssessmentMap.find(
      i => i.studentId === studentId
    )[criteriaNumber];

    fetch(
      `api/StudentAssessment/SaveStudentAssessment/${studentAssessment.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(studentAssessment),
        headers: headers
      }
    )
      .then(response => {
        if (!response.ok) {
          this.messageBar.showMessage(
            MessageBarVariants.Error,
            MessageType.Submit,
            MessageBarText.Error[MessageType.Submit]
          );
          throw new Error(response.status);
        }
        const { lessonLog, currentClassStudents } = this.state;
        this.numProcessStudent++;
        if (this.numProcessStudent === currentClassStudents.length) {
          this.props.updateStatus({ ...lessonLog, lastSaved: new Date() });
          this.props.setSavingStatus(false);
          this.messageBar.showMessage(
            MessageBarVariants.Success,
            MessageType.Submit,
            MessageBarText.Success[MessageType.Submit]
          );
        }
      })
      .catch(error => {
        console.error('Error:', error);
        this.messageBar.showMessage(
          MessageBarVariants.Error,
          MessageType.Submit,
          MessageBarText.Error[MessageType.Submit]
        );
      });
  };

  saveAllStudentAssessments = async () => {
    this.props.setSavingStatus(true);
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    const { currentClassStudents, studentAssessments } = this.state;
    const { logId } = this.props;

    const ratingStarMap = this.studentStarScore.getRatingMap();
    const studentAssessmentMap = this.studentAssessment.getRatingMap();

    this.numProcessStudent = 0;
    currentClassStudents.forEach(student => {
      (async () => {
        const studentLessonLog = await this.saveRatingStar(
          student.id,
          logId,
          ratingStarMap,
          headers
        );
        studentAssessments.forEach(criteria => {
          this.saveStudentAssessment(
            student.id,
            studentLessonLog.id,
            criteria.id,
            criteria.criteriaNumber,
            studentAssessmentMap,
            headers
          );
        });
      })();
    });
  };

  render() {
    const { classes } = this.props;
    const {
      currentClass,
      currentClassStudents,
      currentRatingStar,
      currentStudentAssessment,
      studentAssessments,
      loading
    } = this.state;

    return (
      <Fragment>
        {loading ? (
          <Loading />
        ) : (
          <Paper className={classes.paper}>
            <Grid
              item
              xs
              container
              direction="column"
              className={classes.presencePage}
            >
              <Grid item xs={12}>
                <Swiper onSwiper={swiper => this.setState({ swiper })}>
                  <SwiperSlide key={1}>
                    <StudentRatingStar
                      onRef={actualChild =>
                        (this.studentStarScore = actualChild)
                      }
                      currentClass={currentClass}
                      currentClassStudents={currentClassStudents}
                      currentRatingStar={currentRatingStar}
                      toAssessmentStudent={this.slideNext}
                      saveStudentAssessment={this.saveAllStudentAssessments}
                    />
                  </SwiperSlide>
                  <SwiperSlide key={2}>
                    <StudentAssessment
                      onRef={actualChild =>
                        (this.studentAssessment = actualChild)
                      }
                      currentClass={currentClass}
                      currentClassStudents={currentClassStudents}
                      currentStudentAssessment={currentStudentAssessment}
                      studentAssessments={studentAssessments}
                      toRatingStarStudent={this.slidePrev}
                      saveStudentAssessment={this.saveAllStudentAssessments}
                    />
                  </SwiperSlide>
                </Swiper>
              </Grid>
            </Grid>
          </Paper>
        )}
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Error}
          type={MessageType.Approve}
          message={'Cannot preview!'}
          handleClose={this.handleCloseMessageBar}
        />
      </Fragment>
    );
  }
}

StarScoreAssessment.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesAssessment)(StarScoreAssessment);
