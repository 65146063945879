import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { PagingTable } from '../ui/table/PagingTable';
import CommonSearchToolbar from '../ui/table/CommonSearchToolbar';
import { ScheduleInfo } from './ScheduleInfo';

const stylesList = () => ({});

class ScheduleTbClassCourseSelectComp extends Component {
  static displayName = ScheduleTbClassCourseSelectComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      data: [],
      parentId: this.props.parentId,
      actionColName: this.props.actionColName,
      loading: true,
      schedule: this.props.schedule
    };
  }

  componentDidMount() {
    this.populateData();
  }

  populateData = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const response = await fetch(
      `api/ClassCourses/GetClassCourseInfo/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({
      data: data,
      actionColName: 'selectClassCourse',
      loading: false
    });
    this.setChildDataSelect();
  };

  setChildDataSelect = () => {
    const { data } = this.state;
    this.child.setData(data);
  };

  callbackGetValueRadioBtn = e => {
    this.props.callbackGetValueRadioBtn(e);
  };

  handleChangeSearchTerm = filterText => {
    this.setChildData(filterText);
  };

  setChildData = filterText => {
    const { data } = this.state;
    const searchTerm = filterText.toLowerCase();
    let filteredRows = data.filter(item => {
      const itemText = (item.class + item.teacher).toLowerCase();
      return itemText.indexOf(searchTerm) > -1;
    });
    this.child.setData(filteredRows);
  };

  render() {
    const cols = [
      { name: 'class', header: 'Name', align: 'left' },
      { name: 'teacher', header: 'Teachers', align: 'left' },
      {
        name: 'level',
        header: 'Level',
        align: 'left'
      }
    ];
    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <ScheduleInfo schedule={this.state.schedule} />
            <CommonSearchToolbar handleChange={this.handleChangeSearchTerm} />
            <PagingTable
              onRef={actualChild => (this.child = actualChild)}
              cols={cols}
              isShowRadioButton="true"
              callbackGetValueRadioBtn={this.callbackGetValueRadioBtn}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

ScheduleTbClassCourseSelectComp.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(stylesList)(ScheduleTbClassCourseSelectComp);
