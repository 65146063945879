import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import { Loading } from '../../ui/Loading';
import { ClassCoursePaths } from '../ClassCourseConstants';
import ClassCourseInfo from '../ClassCourseInfo';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { StudentPaths } from '../../student/StudentConstans';
import ViewExamResultDialog from './ViewExamResultDialog';
import { AcademicPaths } from '../../academic-manage/AcademicConstants';
import CommonDataTable from '../../ui/table/CommonDataTable';

class ListClassData extends Component {
  constructor(...args) {
    super(...args);
    this.examResultDialog = React.createRef();
    this.child = React.createRef();
    this.state = {
      classData: [],
      classCourse: {},
      classId: this.props.classId,
      redirect: null,
      loading: true,
      classStudent: [],
      filterText: ''
    };
    this._isMounted = false;
    this.selectedStudents = [];
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.onRef(this);
    this.populateClassData();
  }

  populateClassData = async () => {
    const token = await authService.getAccessToken();
    const { classId } = this.state;
    const [respClassCourse, respClassData] = await Promise.all([
      fetch(`api/ClassCourses/${classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/ClassStudents/GetSuspendStudentByClass/${classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [dataClassCourse, classData] = await Promise.all([
      respClassCourse.json(),
      respClassData.json()
    ]);
    classData.forEach((element, idx) => {
      element.sortOrder = idx + 1;
    });
    this.setState({
      classCourse: dataClassCourse,
      classData,
      loading: false
    });
  };

  backToList() {
    const { classCourse } = this.state;
    this.redirectTo(`${ClassCoursePaths.List}/${classCourse.level.id}`);
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  showExamResults = studentInfo => {
    this.examResultDialog.openExamResult(this.state.classCourse, studentInfo);
  };

  renderDatagrid() {
    const cols = [
      { name: 'sortOrder', header: '#', width: '60', align: 'center' },
      {
        name: 'studentName',
        header: 'Name',
        align: 'left'
      },
      {
        name: 'englishName',
        header: 'English name',
        align: 'left'
      },
      { name: 'fatherName', header: 'Father Name', align: 'left' },
      { name: 'fatherPhone', header: 'Father Phone', align: 'left' },
      { name: 'motherName', header: 'Mother Name', align: 'left' },
      { name: 'motherPhone', header: 'Mother Phone', align: 'left' },
      { name: 'startTimeFormat', header: 'Start Time', align: 'left' },
      { name: 'endTimeFormat', header: 'End Time', align: 'left' },
      { name: 'classType', header: 'Type', align: 'left' }
    ];
    const actions = [
      {
        name: 'detail',
        tooltip: 'View info',
        url: `${AcademicPaths.Info}`,
        icon_fa: faAddressCard
      },
      {
        name: 'callback',
        tooltip: 'Exam results',
        callBackFn: this.showExamResults,
        icon: 'assignment_turned_in'
      },
      {
        name: 'detail',
        tooltip: 'Learning outcomes',
        url: `${StudentPaths.ViewLearningOutcome}`,
        icon: 'folder_open'
      }
    ];

    return (
      <Fragment>
        <ClassCourseInfo classCourse={this.state.classCourse} />
        <CommonDataTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.classData}
          cols={cols}
          actions={actions}
          isPaging={true}
        />
      </Fragment>
    );
  }
  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return (
      <Fragment>
        {contents}
        <ViewExamResultDialog
          onRef={actualChild => (this.examResultDialog = actualChild)}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

ListClassData.propTypes = {
  classes: PropTypes.object.isRequired
};

const stylesListClassData = () => ({});

export default withStyles(stylesListClassData)(ListClassData);
