import { Fab, Paper } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { stylesCommon, stylesEdit } from '../../common/styles/CommonStyles';

import AddIcon from '@material-ui/icons/Add';
import { AdminPage } from '../../ui/page/AdminPage';
import { CampusPaths } from './CampusConstants';
import CommonDataTable from '../../ui/table/CommonDataTable';
import { Loading } from '../../ui/Loading';
import PropTypes from 'prop-types';
import { RouteUtils } from '../../common/RouteUtils';
import authService from '../../api-authorization/AuthorizeService';
import { withStyles } from '@material-ui/core/styles';

class CampusList extends Component {
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = { dataCampus: [], loading: true };
  }

  componentDidMount() {
    this.populateCampusList();
  }

  populateCampusList = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const [respCampus] = await Promise.all([
      fetch(`api/Campus/GetCampusList/${loggedInUser.sub}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    debugger;
    const [dataCampus] = await Promise.all([respCampus.json()]);
    this.setState({ dataCampus, loading: false });
  };

  renderDatagrid() {
    const cols = [
      { name: 'name', header: 'Short Name', align: 'left' },
      { name: 'address', header: 'Full Name', align: 'left' },
      { name: 'fullAddress', header: 'Address', align: 'left' },
      { name: 'website', header: 'Domain', align: 'left' },
      { name: 'location', header: 'Location', align: 'left' },
      { name: 'ipAddress', header: 'Ip', align: 'left' }
    ];
    const actions = [
      {
        name: 'route',
        tooltip: 'Branch reports',
        url: `${CampusPaths.Reports}`,
        icon: 'bar_chart'
      },
      {
        name: 'edit',
        tooltip: 'Edit branch',
        url: `${CampusPaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'delete',
        tooltip: 'Delete branch',
        url: `${CampusPaths.Delete}`,
        icon: 'delete_icon'
      }
    ];

    return (
      <Paper>
        <CommonDataTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.dataCampus}
          cols={cols}
          actions={actions}
          isPaging={true}
        />
      </Paper>
    );
  }

  render() {
    const { classes } = this.props;
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();
    const actions = (
      <Fragment>
        <Fab
          aria-label="Add"
          color="primary"
          className={classes.fab}
          onClick={() => RouteUtils.nextPath(this, CampusPaths.Create)}
        >
          <AddIcon />
        </Fab>
      </Fragment>
    );

    return <AdminPage title="Branch" content={contents} actions={actions} />;
  }
}

const stylesCampusList = theme => ({
  ...stylesCommon(theme),
  ...stylesEdit(theme)
});

CampusList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesCampusList)(CampusList);
