import React, { Component } from 'react';
import clsx from 'clsx';
import { Paper, Typography } from '@material-ui/core';

export class PaymentInfoView extends Component {
  render() {
    const { title, subtitle, numberValue, classes } = this.props;

    return (
      <Paper className={classes.chartBox}>
        <Typography
          variant="h3"
          className={clsx(classes.heading, classes.center)}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          className={clsx(classes.subtitle, classes.center)}
        >
          {subtitle}
        </Typography>
        <div
          className={classes.chartWrap}
          style={{ height: '90px', display: 'flex', marginTop: 30 }}
        >
          <div style={{ flexGrow: 1, maxWidth: '100%', flexBasis: 0 }} />
          <div style={{ flexGrow: 0, textAlign: 'center' }}>
            <Typography
              variant="h1"
              className={clsx(
                classes.chartLongNumber,
                classes.chartLabelOrange
              )}
            >
              {Number(numberValue).toLocaleString('vi-VN')}
            </Typography>
            <hr
              className={clsx(
                classes.chartNumberLine,
                classes.chartLabelOrange
              )}
              style={{ marginTop: -7 }}
            />
          </div>
          <div style={{ flexGrow: 1, maxWidth: '100%', flexBasis: 0 }} />
        </div>
      </Paper>
    );
  }
}
