import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import authService from '../api-authorization/AuthorizeService';
import { TeacherLessonPaths } from '../teacher-lesson/TeacherLessonConstants';
import { ActionUnitSearch } from './ActionSearch';
import CommonDataTable from '../ui/table/CommonDataTable';
import { Loading } from '../ui/Loading';

const stylesListUnitSearch = theme => ({
  root: {
    marginTop: theme.spacing(1)
  }
});

class ListUnitSearchComp extends Component {
  static displayName = ListUnitSearchComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      data: [],
      loading: false
    };
  }

  componentDidMount() {
    this.populateInitData();
  }

  populateInitData = async () => {};

  search = async e => {
    const token = await authService.getAccessToken();
    var tempData = [];
    var url = require('url');
    var objF = e;
    if (objF.calendar != null && objF.calendar instanceof Date) {
      objF.calendar = objF.calendar.toISOString();
    }
    if (
      objF.levelId !== '' ||
      objF.classId !== '' ||
      objF.campusId !== '' ||
      objF.calendar !== null
    ) {
      this.setState({ loading: true });
      const querystring = url.format({ query: objF });
      const response = await fetch(`api/UnitPlans/UnitSearch${querystring}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      });
      if (response.ok) {
        tempData = await response.json();
        this.setState({ loading: false, data: tempData });
      } else {
        this.setState({ loading: false, data: [] });
      }
    } else {
      this.setState({ data: [] });
    }
    this.child.setData(tempData);
  };

  refreshData = async () => {
    this.child.updateChildData(this.data);
  };

  renderDatagrid() {
    const { classes } = this.props;
    const cols = [
      { name: 'level', header: 'Level', align: 'left' },
      { name: 'class', header: 'Class', align: 'left' },
      { name: 'lesson', header: 'Lesson', align: 'left' },
      { name: 'subject', header: 'Subject', align: 'left' },
      { name: 'content', header: 'Content', align: 'left' },
      { name: 'calendarFormat', header: 'Calendar', align: 'left' },
      { name: 'campus', header: 'Campus', align: 'left' }
    ];
    const actions = [
      {
        name: 'multi-route',
        tooltip: 'Teacher lesson review',
        url: `${TeacherLessonPaths.ViewUnitAdmin}`,
        icon: 'list_alt_icon'
      }
    ];
    return (
      <Paper className={classes.root}>
        <CommonDataTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.data}
          cols={cols}
          actions={actions}
          eventReloadData={this.refreshData}
          isPaging={true}
        />
      </Paper>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();
    return (
      <Fragment>
        <ActionUnitSearch callbackObjFilter={this.search} />
        {contents}
      </Fragment>
    );
  }
}

ListUnitSearchComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListUnitSearch = withStyles(stylesListUnitSearch)(
  ListUnitSearchComp
);
