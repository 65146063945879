import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import { CalenderSchedule } from '../schedule/CalendarScheduler';
import { CommonDashboard } from './CommonDashboard';
import { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { Roles } from '../common/constants/Roles';
import { SaleDashboard } from './SaleDashboard';
import { TeacherDashboard } from './TeacherDashboard';
import { Views } from 'react-big-calendar';
import authService from '../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { stylesDashboard } from './stylesDashboard';
import { withStyles } from '@material-ui/styles';

class AdminPageComp extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isAuthenticated: false,
      displayComponent: null,
      calendarComponent: null
    };
  }

  componentDidMount() {
    this.populateState();
  }

  async populateState() {
    const [isAuthenticated, user, token] = await Promise.all([
      authService.isLoggedIn(),
      authService.getUser(),
      authService.getAccessToken()
    ]);

    this.setState({
      isAuthenticated,
      loggedInUser: user,
      userName: user && user.name
    });
    if (isAuthenticated) {
      this.populateUserInfo(user, token);
    }
  }

  async populateUserInfo(user, token) {
    const headers = !token ? {} : { Authorization: `Bearer ${token}` };
    const [respUserData] = await Promise.all([
      fetch(`api/AspNetUsers/GetUserRole/${user.sub}`, { headers })
    ]);
    const [userRole] = await Promise.all([respUserData.json()]);
    const { classes } = this.props;
    const calendarComponent = (
      <>
        <Paper className={classes.notifyAreaWrap}>
          <div className={classes.toolbar}>
            <Typography
              className={clsx(classes.boldText, classes.margin, classes.title)}
            >
              Calendar - All Classes
            </Typography>
          </div>
          <Divider />
          <Grid container className={classes.notifyContentWrap}>
            <CalenderSchedule
              onRef={actualChild => (this.child = actualChild)}
              scheduleId={this.state.scheduleId}
              viewAllClass={true}
              viewMode={Views.AGENDA}
              isMySchedule={userRole?.role?.name === Roles.Teacher}
            />
          </Grid>
        </Paper>
      </>
    );
    let displayComponent = (
      <CommonDashboard calendarComponent={calendarComponent} />
    );

    this.setState({ calendarComponent });

    switch (userRole.role.name) {
      case Roles.Teacher:
      case Roles.AcadManager:
        displayComponent = <TeacherDashboard parentComponent={this} />;
        break;
      case Roles.SaleExecutive:
      case Roles.SaleManager:
        displayComponent = <SaleDashboard parentComponent={this} />;
        break;
      case undefined:
      default:
    }
    this.setState({ userRole, displayComponent });
  }

  render() {
    const { displayComponent } = this.state;

    return <Fragment>{displayComponent}</Fragment>;
  }
}

AdminPageComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Dashboard = withStyles(stylesDashboard)(AdminPageComp);
