export const RoleActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete'
};

const prefix = '/role';

export const RolePaths = {
  RolePrefix: prefix,
  List: `${prefix}/${RoleActions.List}`,
  EditRoute: `${prefix}/${RoleActions.Edit}/:roleId`,
  Edit: `${prefix}/${RoleActions.Edit}`,
  Create: `${prefix}/${RoleActions.Create}`,
  Delete: `AspNetRoles`
};
