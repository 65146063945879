import React, { Component } from 'react';

import Box from '@material-ui/core/Box';
import { PropTypes } from 'prop-types';

export class TabPanel extends Component {
  render() {
    const { value, index, component, ...other } = this.props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ height: '100%' }}
      >
        {value === index && (
          <Box
            style={{
              padding: 0,
              paddingTop: 16,
              height: '100%',
              position: 'relative'
            }}
            sx={{ p: 3 }}
          >
            {component}
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  component: PropTypes.node
};
