export const AssessmentActions = {
  List: 'list',
  Presence: 'presence',
  PresenceStudent: 'presence-student',
  Class: 'class'
};

const prefix = '/assessment';

export const AssessmentPaths = {
  AssessmentPrefix: prefix,
  Presence: `${prefix}/${AssessmentActions.Presence}`,
  PresenceStudent: `${prefix}/${AssessmentActions.PresenceStudent}`,
  PresenceStudentRoute: `${prefix}/${AssessmentActions.PresenceStudent}/:campusId`,
  List: `${prefix}/${AssessmentActions.List}`,
  Class: `${prefix}/${AssessmentActions.Class}`,
  ClassRoute: `${prefix}/${AssessmentActions.Class}/:roomId`
};
