import React, { Component } from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import clsx from 'clsx';
import { stylesDashboard } from './stylesDashboard';
import { format, parseISO } from 'date-fns';

class Notifications extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.populateUserEvents();
  }

  async populateUserEvents() {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const headers = !token ? {} : { Authorization: `Bearer ${token}` };
    const response = await fetch(
      `api/ClassEvents/GetEventsPerUser/${loggedInUser.sub}`,
      { headers }
    );
    const userEvents = await response.json();

    this.setState({ userEvents, loading: false });
  }

  render() {
    const { classes } = this.props;
    const { userEvents, loading } = this.state;

    return (
      <Fragment>
        {loading ? (
          <Loading />
        ) : (
          <Paper className={classes.notifyAreaWrap}>
            <div className={classes.toolbar}>
              <Typography
                className={clsx(
                  classes.boldText,
                  classes.margin,
                  classes.title
                )}
              >
                Notifications
              </Typography>
            </div>
            <Divider />
            <Grid container className={classes.notifyContentWrap}>
              {userEvents.map((userEvent, idx) => {
                return (
                  <Grid key={idx} item xs={12}>
                    <Paper className={classes.notifyItemWrap}>
                      <Box
                        bgcolor="secondary.main"
                        className={classes.notifyIndicator}
                      />
                      <div className={classes.notifyItem}>
                        <Typography
                          variant="body2"
                          className={classes.notifyTitle}
                        >
                          {userEvent.title}
                        </Typography>
                        <Typography variant="body2">
                          {format(parseISO(userEvent.startTime), 'dd/MM')}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        )}
      </Fragment>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesDashboard)(Notifications);
