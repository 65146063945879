import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { PagingTable } from '../ui/table/PagingTable';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Loading } from '../ui/Loading';
import { TaskPaths, TaskActions } from './TaskConstans';

const stylesEdit = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  selectSection: {
    margin: theme.spacing(2, 5)
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  }
});

class EditTaskComp extends Component {
  static displayName = EditTaskComp.name;
  constructor(...args) {
    super(...args);
    this.form = React.createRef();
    this.child = React.createRef();
    this.state = {
      action: this.props.action,
      taskId: this.props.taskId,
      isTeacher: this.props.isTeacher,
      teacher: '',
      method: 'PUT',
      task: {
        name: '',
        note: '',
        userId: '',
        assignUserId: '',
        isTeacher: this.props.isTeacher
      },
      dataTeacher: [],
      redirect: null,
      loading: true
    };

    switch (this.props.action) {
      case TaskActions.Create:
      case TaskActions.AcadManagerCreate:
        this.state.method = 'POST';
        this.state.action = `api/TaskSys`;
        this.state.loading = false;
        break;
      case TaskActions.Edit:
      case TaskActions.AcadManagerEdit:
        this.state.action = `api/TaskSys/${this.state.taskId}`;
        this.populateDataEdit();
        break;
      case undefined:
      default:
    }
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateDataInit();
  }

  populateDataInit = async () => {
    var assignUserId =
      this.state.task.assignUserId === '' ? '0' : this.state.task.assignUserId;
    const token = await authService.getAccessToken();
    const response = await fetch(
      `api/AspNetUsers/GetTeacherAssignTaskSys/${assignUserId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({ dataTeacher: data, loading: false });
    if (!this.state.isTeacher) {
      this.setChildData();
    }
  };

  setChildData = () => {
    const { dataTeacher } = this.state;
    this.child.setData(dataTeacher);
  };

  callbackGetValueRadioBtn = async e => {
    const token = await authService.getAccessToken();
    const response = await fetch(`api/AspNetUsers/${e}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    var infoTeacher =
      data.fullName + (data.phoneNumber !== '' ? ' - ' + data.phoneNumber : '');
    var task = this.state.task;
    task.assignUserId = e;
    this.setState({
      teacher: infoTeacher,
      task: task,
      loading: false
    });
  };

  populateDataEdit = async () => {
    const token = await authService.getAccessToken();
    const { action } = this.state;
    const response = await fetch(action, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    data.assignUserFormat =
      data.assignUserFormat != null ? data.assignUserFormat : '';
    this.setState({
      task: data,
      teacher: data.assignUserFormat,
      loading: false
    });
  };

  backToList() {
    if (this.state.isTeacher) {
      this.redirectTo(TaskPaths.List);
    } else {
      this.redirectTo(TaskPaths.ListAll);
    }
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { task, method, action, token } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const currentUser = await authService.getUser();
    task.userId = currentUser.sub;
    fetch(action, {
      method: method,
      body: JSON.stringify(task),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  async submitData() {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    ReactDOM.findDOMNode(this.form.current).dispatchEvent(new Event('submit'));
  }

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      task: {
        ...prevState.task,
        [attrName]: attrValue
      }
    }));
  };

  renderForm() {
    const { classes } = this.props;
    const { task } = this.state;
    const cols = [
      {
        name: 'name',
        header: 'Title',
        placeholder: 'Title',
        adornment: ''
      },
      {
        name: 'note',
        header: 'Content',
        placeholder: 'Content',
        adornment: ''
      },
      {
        name: 'assignment',
        header: 'Assignment',
        placeholder: 'Assignment',
        adornment: ''
      }
    ];
    const colsTeacher = [
      { name: 'fullName', header: 'Name', align: 'left' },
      { name: 'email', header: 'Email', align: 'left' },
      { name: 'phoneNumber', header: 'Phone', align: 'left' }
    ];
    return (
      <Paper className={classes.root}>
        <form
          ref={this.form}
          className={classes.container}
          onSubmit={this.handleSubmit}
        >
          <Grid container>
            <Grid item xs={12} className={classes.cell}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="name"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    rows="6"
                    className={classes.margin}
                    label={cols[0].header}
                    placeholder={cols[0].placeholder}
                    name={cols[0].name}
                    value={task[cols[0].name]}
                    onChange={this.handleChange(cols[0].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="note"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    rows="6"
                    className={classes.margin}
                    label={cols[1].header}
                    placeholder={cols[1].placeholder}
                    name={cols[1].name}
                    value={task[cols[1].name]}
                    onChange={this.handleChange(cols[1].name)}
                  />
                </ListItem>
                {!this.state.isTeacher ? (
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="teacher"
                      disabled
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      rows="6"
                      className={classes.margin}
                      label={cols[2].header}
                      placeholder={cols[2].placeholder}
                      name={cols[2].name}
                      value={this.state.teacher}
                      onChange={this.handleChange(cols[2].name)}
                    />
                  </ListItem>
                ) : (
                  ''
                )}
              </List>
            </Grid>
          </Grid>
        </form>
        {!this.state.isTeacher ? (
          <Paper className={classes.selectSection}>
            <PagingTable
              onRef={actualChild => (this.child = actualChild)}
              cols={colsTeacher}
              isShowRadioButton={true}
              callbackGetValueRadioBtn={this.callbackGetValueRadioBtn}
            />
          </Paper>
        ) : (
          ''
        )}

        {this.state.redirect}
      </Paper>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderForm();
    return <Fragment>{contents}</Fragment>;
  }
}

EditTaskComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const EditTask = withStyles(stylesEdit)(EditTaskComp);
