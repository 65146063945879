import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { stylesAcademicInfo } from '../../academic-manage/stylesAcademicInfo';
import clsx from 'clsx';

class ClassSummary extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: true
    };
  }

  render() {
    const { classes, classInfo } = this.props;

    return (
      <Fragment>
        <Grid container className={classes.stdHeadline}>
          <div className={classes.stdSubtitle}>
            <Typography variant="h3" className={classes.sectionTitle}>
              {'Thông tin lớp học'}
            </Typography>
          </div>
          <div className={classes.stdSubtitleHr}>
            <span className={clsx(classes.stdLineCell)}></span>
          </div>
        </Grid>

        <Grid
          container
          alignItems="flex-start"
          spacing={1}
          className={classes.stdBox}
        >
          <Grid item xs={2}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Start date:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="body2"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              {classInfo.startDate}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              End date:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="body2"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              {classInfo.endDate}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Number of students:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="body2"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              {classInfo.numberOfStudents}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Notification:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="body2"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              {classInfo.notification}
            </Typography>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const stylesClassReports = theme => ({
  ...stylesAcademicInfo(theme),
  root: {
    marginTop: theme.spacing(2)
  }
});

ClassSummary.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesClassReports)(ClassSummary);
