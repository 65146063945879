import {
  CardHeader,
  Fab,
  FormControl,
  Grid,
  List,
  ListItem,
  Paper,
  TextField
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {
  MessageBarVariants,
  MessageBars,
  MessageType
} from '../ui/popup/Messages';
import React, { Component, Fragment } from 'react';

import { AcademicPaths } from '../academic-manage/AcademicConstants';
import { ClassDataPaths } from '../class-course/class-data/ClassDataConstants';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimeUtils } from '../common/DateTimeUtils';
import { Loading } from '../ui/Loading';
import PropTypes from 'prop-types';
import { ProposalPaths } from '../proposal/ProposalConstants';
import { Redirect } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import { StudentActions } from './StudentConstans';
import StudentParent from './parent/StudentParent';
import StudentPayment from './payment/StudentPayment';
import authService from '../api-authorization/AuthorizeService';
import { stylesEditStudent } from './styleEditStudent';
import { withStyles } from '@material-ui/core/styles';

class EditStudent extends Component {
  static displayName = EditStudent.name;
  constructor(...args) {
    super(...args);
    this.father = React.createRef();
    this.mother = React.createRef();
    this.studentPayment = React.createRef();
    this.state = {
      action: this.props.action,
      studentId: this.props.studentId,
      redirect: null,
      loading: true
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateStudentData();
  }

  populateStudentData = async () => {
    const { action, studentId } = this.props;
    let student = {
      studentName: '',
      englishName: '',
      traits: '',
      address: '',
      birthday: new Date(),
      beginDate: new Date()
    };
    if (action !== StudentActions.Create) {
      const token = await authService.getAccessToken();
      const respStudent = await fetch(`api/Student/${studentId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      });
      if (respStudent.status === 200) {
        student = await respStudent.json();
      }
    }
    this.setState({
      student,
      loading: false
    });
  };

  backToList = action => {
    const { classProposal, classId, studentId } = this.props;
    let url = `${AcademicPaths.Info}/${studentId}`;
    switch (action) {
      case 'save':
        url = classProposal ? `${ClassDataPaths.List}/${classId}` : url;
        break;
      case 'cancel':
        url = classProposal ? `${ProposalPaths.List}` : url;
        break;
      default:
        break;
    }
    this.redirectTo(url);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  isValidStudent = student => {
    if (!student?.studentName) {
      return { hasError: true, message: 'Student Name is required.' };
    }
    return { hasError: false, message: '' };
  };

  handleSubmit = async () => {
    const { action } = this.props;
    const { student } = this.state;

    const _studentError = this.isValidStudent(student);
    // const _fatherError = this.father.isValid();
    // const _motherError = this.mother.isValid();

    if (
      _studentError?.hasError
      //||
      // _fatherError?.hasError ||
      // _motherError?.hasError
    ) {
      this.messageBar.showMessage(
        MessageBarVariants.Error,
        MessageType.Submit,
        [
          _studentError?.hasError && _studentError?.message
          // _fatherError?.hasError && _fatherError?.message,
          // _motherError?.hasError && _motherError?.message
        ]
      );
      return;
    }

    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    const method = action === StudentActions.Create ? 'POST' : 'PUT';
    const url =
      action === StudentActions.Create
        ? `api/Student`
        : `api/Student/${student.id}`;

    student.birthday = DateTimeUtils.toDateTimeJSON(student.birthday);
    student.beginDate = DateTimeUtils.toDateTimeJSON(student.beginDate);
    student.createdBy = loggedInUser.sub;
    student.modifiedBy = loggedInUser.sub;

    const respStudent = await fetch(url, {
      method,
      body: JSON.stringify(student),
      headers: headers
    });
    if (respStudent.status === 201) {
      //new student created
      const newStudent = await respStudent.json();
      await this.saveParents(newStudent);
      await this.studentPayment.saveData(newStudent);
    } else {
      await this.saveParents(student);
    }
    this.backToList('save');
  };

  saveParents = async student => {
    await this.father.saveData(student);
    await this.mother.saveData(student);
  };

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      student: {
        ...prevState.student,
        [attrName]: attrValue
      }
    }));
  };

  handleDateChange = name => date => {
    const attrName = name;
    const attrValue = date;
    this.setState(prevState => ({
      student: {
        ...prevState.student,
        [attrName]: attrValue
      }
    }));
  };

  messageBarComp() {
    return (
      <MessageBars
        onRef={actualChild => (this.messageBar = actualChild)}
        variant={MessageBarVariants.Error}
        type={MessageType.Approve}
        message={'Cannot preview!'}
        handleClose={this.handleCloseMessageBar}
      />
    );
  }

  handleCloseMessageBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  renderForm() {
    const { classes, classProposal, classId } = this.props;
    const { action, studentId, student } = this.state;

    return (
      <Fragment>
        <Paper className={classProposal && classes.root}>
          {classProposal && <CardHeader title="Student" />}
          <Grid container>
            <Grid item xs={6} className={classes.cell}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <TextField
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label="Name"
                    value={student.studentName}
                    onChange={this.handleChange('studentName')}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label="English Name"
                    value={student.englishName}
                    onChange={this.handleChange('englishName')}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControl className={classes.dateControl}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.formControl}
                        format="dd/MM/yyyy"
                        label="Birthday"
                        value={student.birthday}
                        onChange={this.handleDateChange('birthday')}
                        KeyboardButtonProps={{
                          'aria-label': 'change birthday'
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label="EL Account"
                    value={student.elAccount}
                    onChange={this.handleChange('elAccount')}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6} className={classes.cell}>
              <List>
                <ListItem className={classes.listItem}>
                  <TextField
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label="Address"
                    value={student.address || ''}
                    onChange={this.handleChange('address')}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    multiline
                    rows="6"
                    className={classes.margin}
                    label="Personality Traits"
                    value={student.traits || ''}
                    onChange={this.handleChange('traits')}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControl className={classes.dateControl}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.formControl}
                        format="dd/MM/yyyy"
                        label="Begin Date"
                        value={student.beginDate}
                        onChange={this.handleDateChange('beginDate')}
                        KeyboardButtonProps={{
                          'aria-label': 'change begin date'
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Paper>
        {/* Parents */}
        <StudentParent
          action={action}
          studentId={studentId}
          relation={1}
          onRef={actualChild => (this.father = actualChild)}
        />
        <StudentParent
          action={action}
          studentId={studentId}
          relation={2}
          onRef={actualChild => (this.mother = actualChild)}
        />
        {/* Register Course */}
        {action === StudentActions.Create && (
          <StudentPayment
            classId={classId}
            onRef={actualChild => (this.studentPayment = actualChild)}
          />
        )}
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.handleSubmit}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={() => this.backToList('cancel')}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
        {this.state.redirect}
      </Fragment>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderForm();
    return (
      <Fragment>
        {contents} {this.messageBarComp()}
      </Fragment>
    );
  }
}

EditStudent.propTypes = {
  classes: PropTypes.object.isRequired,
  action: PropTypes.any,
  studentId: PropTypes.string,
  onRef: PropTypes.any,
  classProposal: PropTypes.any,
  classId: PropTypes.string
};

export default withStyles(stylesEditStudent)(EditStudent);
