import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../../common/route/PropsRoute';
import { ClassData } from './ClassData';
import { ClassDataPaths, ClassDataActions } from './ClassDataConstants';

export default class ClassDataRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={ClassDataPaths.ListRoute}
          component={ClassData}
          action={ClassDataActions.List}
        />
        <PropsRoute
          path={ClassDataPaths.ListSuspendedRoute}
          component={ClassData}
          action={ClassDataActions.ListSuspended}
        />
        <PropsRoute
          path={ClassDataPaths.EditRoute}
          component={ClassData}
          action={ClassDataActions.Edit}
        />
        <PropsRoute
          path={ClassDataPaths.CreateRoute}
          component={ClassData}
          action={ClassDataActions.Create}
        />
      </Fragment>
    );
  }
}
