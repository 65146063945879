export const StudentActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete',
  ViewLearningOutcome: 'view-learning-outcomes',
  ViewScheduleStudent: 'view-schedule-student',
  ViewPropose: 'view-propose',
  Suspend: 'SuspendStudent'
};

const prefix = '/students';

export const StudentPaths = {
  StudentPrefix: prefix,
  List: `${prefix}/${StudentActions.List}`,
  EditRoute: `${prefix}/${StudentActions.Edit}/:studentId`,
  Edit: `${prefix}/${StudentActions.Edit}`,
  Create: `${prefix}/${StudentActions.Create}`,
  Delete: `Students`,
  ViewLearningOutcomeRoute: `${prefix}/${StudentActions.ViewLearningOutcome}/:studentId`,
  ViewLearningOutcome: `${prefix}/${StudentActions.ViewLearningOutcome}`,
  ViewScheduleStudentRoute: `${prefix}/${StudentActions.ViewScheduleStudent}/:studentId`,
  ViewScheduleStudent: `${prefix}/${StudentActions.ViewScheduleStudent}`,
  ViewProposeRoute: `${prefix}/${StudentActions.ViewPropose}/:studentId`,
  ViewPropose: `${prefix}/${StudentActions.ViewPropose}`,
  Suspend: `student/${StudentActions.Suspend}`,
  SuspendRoute: `student/${StudentActions.Suspend}/:studentId`
};
