import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

const ActionIconButton = withStyles({
  root: {
    padding: '10px'
  },
  sizeSmall: {
    fontSize: 20
  },
  label: {
    textTransform: 'capitalize'
  }
})(IconButton);

const ActionIcon = withStyles({
  root: {
    fontSize: 20
  }
})(Icon);

const NormalTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.98)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip);

export { ActionIconButton, NormalTooltip, ActionIcon };
