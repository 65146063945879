import { IconButton, Paper, Typography } from '@material-ui/core';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

import { AddBoxOutlined } from '@material-ui/icons';
import ExamAccordion from './ExamAccordion';
import { Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { StudyLevelPaths } from './StudyLevelConstants';
import authService from '../../api-authorization/AuthorizeService';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
    minHeight: 650,
    maxHeight: 800
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  },
  btnPlus: {
    '& .MuiIconButton-label': {
      color: '#85c221'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '2rem'
    }
  }
});

const ExamStudyLevelComp = (props, ref) => {
  const inputRef = useRef();
  const history = useHistory();
  const [examTemplates, setExamTemplates] = useState([]);
  const [level, setLevel] = useState({});
  const skillsTemplate = [
    { name: 'Confidence', altName: 'Sự tự tin', evaluation: '0' },
    { name: 'Behavior', altName: 'Cách ứng xử', evaluation: '0' },
    { name: 'Concentration', altName: 'Sự tập trung', evaluation: '0' },
    { name: 'Progression', altName: 'Sự tiến bộ', evaluation: '0' },
    { name: 'Teamwork', altName: 'Làm việc nhóm', evaluation: '0' }
  ];
  const linguisticsTemplate = [
    { name: 'Math (M)', weight: '4', score: '0' },
    { name: 'Vocabulary (V)', weight: '21', score: '0' },
    { name: 'Phonics Foundation (P)', weight: '4', score: '0' },
    { name: 'Sight word (S)', weight: '3', score: '0' }
  ];

  useImperativeHandle(ref, () => ({
    backToList: () => {
      history.push(StudyLevelPaths.List);
    },
    submitData: async () => {
      const token = await authService.getAccessToken();
      const headers = {
        ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
        ...(!token ? {} : { Authorization: `Bearer ${token}` })
      };

      fetch(`api/StudyLevels/exam/${props?.levelId}`, {
        method: 'PUT',
        body: JSON.stringify(examTemplates),
        headers: headers
      })
        .then(response => {
          if (response.ok) {
            refresh();
          } else throw new Error(response.status);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }));

  useEffect(() => {
    const fetchData = async () => {
      const token = await authService.getAccessToken();
      const headers = {
        ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
        ...(!token ? {} : { Authorization: `Bearer ${token}` })
      };

      fetch(`api/StudyLevels/exam/${props?.levelId}`, {
        method: 'GET',
        headers: headers
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else throw new Error(response.status);
        })
        .then(data => {
          setExamTemplates(data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };
    fetchData();
    getStudyLevel();

    return () => {
      setExamTemplates([]);
      setLevel({});
    };
  }, []);

  const refresh = () => {
    history.push(StudyLevelPaths.Exam);
    history.push(`${StudyLevelPaths.Exam}/${props?.levelId}`);
  };

  const getStudyLevel = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch(`api/StudyLevels/${props?.levelId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    setLevel(data);
  };

  const onTemplateAdd = () => {
    setExamTemplates([
      ...examTemplates,
      {
        id: `${new Date().getTime()}`,
        examType: examTemplates.length + 1,
        examName: '',
        examFormJson: JSON.stringify({
          examName: '',
          examType: examTemplates.length + 1,
          overall: [...skillsTemplate],
          academic: [...linguisticsTemplate]
        })
      }
    ]);
  };

  const { classes } = props;

  return (
    <Paper className={classes.root} ref={inputRef}>
      <Grid container spacing={3} xs={12} className={classes.container}>
        <Grid item xs={12} className={classes.inline}>
          <Typography variant="h6">
            Level {level?.name} - {level?.numberOfLesson}
          </Typography>
          <IconButton
            aria-label="add"
            onClick={onTemplateAdd}
            className={classes.btnPlus}
          >
            <AddBoxOutlined />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {examTemplates.map(template => (
            <div key={template?.id}>
              <ExamAccordion
                template={template}
                setExamTemplates={setExamTemplates}
              />
            </div>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

ExamStudyLevelComp.propTypes = {
  classes: PropTypes.object.isRequired
};

const ExamStudyLevel = withStyles(styles)(forwardRef(ExamStudyLevelComp));
export default ExamStudyLevel;
