import React, { Component, Fragment } from 'react';

import ClassCourseInfo from '../../class-course/ClassCourseInfo';
import { DateTimeUtils } from '../../common/DateTimeUtils';
import { Loading } from '../../ui/Loading';
import MaterialTable from 'material-table';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import authService from '../../api-authorization/AuthorizeService';
import { format } from 'date-fns';
import { withStyles } from '@material-ui/core/styles';

const stylesListClassEvent = () => ({});

const initEventTime = new Date();
initEventTime.setHours(8, 0, 0, 0);

class ListClassEvent extends Component {
  constructor(...args) {
    super(...args);
    this.state = { classEvents: [], loading: true };
  }

  componentDidMount() {
    this.populateAllData();
  }

  async populateAllData() {
    const classEvents = await this.populateMyClassEvents();
    const classInfo = await this.populateMyClass();
    this.setState({ classEvents, classInfo, loading: false });
  }
  async refeshEvents() {
    this.setState({ loading: true });
    const classEvents = await this.populateMyClassEvents();
    this.setState({ classEvents, loading: false });
  }

  async populateMyClassEvents() {
    const token = await authService.getAccessToken();
    const respclassEventsData = await fetch(
      `api/ClassEvents/GetEvents/${this.props.classId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const classEventsData = await respclassEventsData.json();
    let classEvents = classEventsData.map(classEvent => {
      return {
        ...classEvent,
        startTime: new Date(classEvent.startTime),
        endTime: new Date(classEvent.endTime)
      };
    });
    return classEvents;
  }

  async populateMyClass() {
    const token = await authService.getAccessToken();
    const respClassInfo = await fetch(
      `api/ClassCourses/${this.props.classId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const classInfo = await respClassInfo.json();
    return classInfo;
  }

  saveClassEvent = async newEvent => {
    debugger;
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const { classId } = this.props;
    let startTime = DateTimeUtils.toDateTimeJSON(newEvent.startTime);
    let endTime = DateTimeUtils.toDateTimeJSON(newEvent.endTime);
    debugger;
    const formBody = JSON.stringify({
      ...newEvent,
      classId,
      startTime,
      endTime,
      createdBy: loggedInUser.sub
    });
    fetch(`api/ClassEvents/SaveClassEvent`, {
      method: 'POST',
      body: formBody,
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.refeshEvents();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  renderDatagrid() {
    const columns = [
      { title: 'Event Title', field: 'title' },
      { title: 'Content', field: 'content' },
      {
        title: 'Event Date',
        field: 'startTime',
        type: 'datetime',
        initialEditValue: initEventTime,
        render: rowData => format(rowData.startTime, 'dd/MM/yyyy HH:mm')
      },
      {
        title: 'End Date',
        field: 'endTime',
        type: 'datetime',
        initialEditValue: initEventTime,
        render: rowData => {
          console.log('end time: ', rowData.endTime);
          return format(rowData.endTime, 'dd/MM/yyyy HH:mm');
        }
      }
    ];

    const { classEvents, classInfo } = this.state;
    console.log('Invalid time value: ', classEvents);
    return (
      <Fragment>
        <Paper>
          <ClassCourseInfo classCourse={classInfo} />
        </Paper>
        <MaterialTable
          title=""
          columns={columns}
          data={classEvents}
          options={{
            actionsColumnIndex: -1,
            actionsCellStyle: {
              color: 'rgba(0, 0, 0, 0.38)',
              fontSize: 16
            }
          }}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  this.setState({ classEvents: [...classEvents, newData] });
                  this.saveClassEvent(newData);

                  resolve();
                }, 1000);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...classEvents];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  this.setState({ classEvents: [...dataUpdate] });
                  this.saveClassEvent(newData);

                  resolve();
                }, 1000);
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataDelete = [...classEvents];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  this.setState({ classEvents: [...dataDelete] });

                  resolve();
                }, 1000);
              })
          }}
        />
      </Fragment>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return <Fragment>{contents}</Fragment>;
  }
}

ListClassEvent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesListClassEvent)(ListClassEvent);
