export const EventActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete'
};

const prefix = '/event';

export const EventPaths = {
  EventPrefix: prefix,
  List: `${prefix}/${EventActions.List}`,
  ListRoute: `${prefix}/${EventActions.List}/:classId`,
  Create: `${prefix}/${EventActions.Create}`,
  Delete: `${prefix}/${EventActions.Delete}`
};
