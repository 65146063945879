export const LessonAndTeacherActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete'
};

const prefix = '/lesson-teacher';

export const LessonAndTeacherPaths = {
  LessonAndTeacherPathsPrefix: prefix,
  ListRoute: `${prefix}/${LessonAndTeacherActions.List}/:classId`,
  List: `${prefix}/${LessonAndTeacherActions.List}`,
  DeleteClassLesson: `ClassLesson`,
  DeleteClassTeacher: `ClassTeacher`
};
