export const stylesReserveInfo = theme => ({
  pageCol: {
    padding: theme.spacing(2)
  },
  typeCol: {
    textAlign: 'center',
    padding: theme.spacing(2, 0)
  },
  emptyCol: {
    display: 'block',
    width: 40,
    height: 40
  },
  reason: {
    padding: theme.spacing(0, 4, 0, 2)
  },
  command: {
    padding: theme.spacing(2, 3, 3)
  },
  margin: {
    margin: theme.spacing(1)
  },
  marginLeft: {
    margin: theme.spacing(0, 0, 0, 1)
  },
  textField: {
    width: 230
  },
  label: {
    fontWeight: 'bold'
  }
});
