import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { TeacherLesson } from './TeacherLesson';
import {
  TeacherLessonPaths,
  TeacherLessonActions
} from './TeacherLessonConstants';

export default class TeacherLessonRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={TeacherLessonPaths.List}
          component={TeacherLesson}
          action={TeacherLessonActions.List}
        />
        <PropsRoute
          path={TeacherLessonPaths.ListAll}
          component={TeacherLesson}
          action={TeacherLessonActions.ListAll}
        />
        <PropsRoute
          path={TeacherLessonPaths.ViewLessonRoute}
          component={TeacherLesson}
          action={TeacherLessonActions.ViewLesson}
        />
        <PropsRoute
          path={TeacherLessonPaths.ViewLessonAdminRoute}
          component={TeacherLesson}
          action={TeacherLessonActions.ViewLessonAdmin}
        />
        <PropsRoute
          path={TeacherLessonPaths.ViewUnitPlanRoute}
          component={TeacherLesson}
          action={TeacherLessonActions.ViewUnitPlan}
        />
        <PropsRoute
          path={TeacherLessonPaths.ViewUnitAdminRoute}
          component={TeacherLesson}
          action={TeacherLessonActions.ViewUnitAdmin}
        />
        <PropsRoute
          path={TeacherLessonPaths.ViewCalendarRoute}
          component={TeacherLesson}
          action={TeacherLessonActions.ViewCalendar}
        />
        <PropsRoute
          path={TeacherLessonPaths.ViewMyScheduleRoute}
          component={TeacherLesson}
          action={TeacherLessonActions.ViewMySchedule}
        />
        <PropsRoute
          path={TeacherLessonPaths.ViewAllClassCalendar}
          component={TeacherLesson}
          action={TeacherLessonActions.ViewAllClassCalendar}
        />
      </Fragment>
    );
  }
}
