import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { ListTeacher } from './ListTeacher';
import { ListReportTeacher } from './ReportTeacher';
import { TeacherActions, TeacherPaths } from './TeacherConstans';
import { AdminPage } from '../ui/page/AdminPage';
import { EditTeacher } from './EditTeacher';

const stylesTeacher = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class TeacherComp extends Component {
  static displayName = TeacherComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      teacherId: this.props.match.params.teacherId,
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case TeacherActions.List:
        this.state.pageTitle = 'Teachers';
        this.state.content = <ListTeacher />;
        break;
      case TeacherActions.ViewReport:
        this.state.pageTitle = 'Report';
        this.state.content = (
          <ListReportTeacher
            onRef={actualChild => (this.child = actualChild)}
            teacherId={this.state.teacherId}
          />
        );
        break;
      case TeacherActions.Create:
        this.state.pageTitle = 'Create Teacher';
        this.state.content = (
          <EditTeacher
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            teacherId={this.state.teacherId}
          />
        );
        break;
      case TeacherActions.Edit:
        this.state.pageTitle = 'Edit Teacher';
        this.state.content = (
          <EditTeacher
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            teacherId={this.state.teacherId}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === 'list' ? (
        <Fab
          aria-label="Add"
          color="primary"
          className={classes.fab}
          onClick={() => this.redirectTo(TeacherPaths.Create)}
        >
          <AddIcon />
        </Fab>
      ) : this.props.action === 'create' ? (
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.handleChildSubmit}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleChildBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      ) : this.props.action === 'report' ? (
        <Fragment>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleChildBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      ) : (
        ''
      );
    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

TeacherComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Teachers = withStyles(stylesTeacher)(TeacherComp);
