import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CollapseContent from '../ui/table/CollapseContent';

const stylesUnitPlanInfo = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1, 0, 2, 0),
    overflowX: 'auto',
    minWidth: '700px'
  },
  cell: {
    padding: theme.spacing(1, 0, 1, 2)
  }
});

class UnitPlanInfoComp extends Component {
  static displayName = UnitPlanInfoComp.name;

  constructor(...args) {
    super(...args);
    this.state = {
      data: this.props.dataUnit
    };
  }

  render() {
    const { classes } = this.props;
    const { data } = this.state;
    const cols = [
      { name: 'time', header: 'Time (mins)', align: 'center', xs: 1 },
      { name: 'procedures', header: 'Procedures', align: 'left', xs: 1 },
      { name: 'materials', header: 'Materials', align: 'left', xs: 2 },
      {
        name: 'teacherActivities',
        header: "Teachers' activities",
        align: 'left',
        xs: 3
      },
      {
        name: 'learningOutcome',
        header: "Students' learning outcome",
        align: 'left',
        xs: 3
      },
      { name: 'note', header: 'Note', align: 'left', xs: 2 }
    ];

    return (
      <Paper className={classes.root}>
        <Grid container>
          {cols.map((col, idx) => (
            <Grid item xs={col.xs} className={classes.cell} key={idx}>
              <Typography color="textSecondary" variant="caption">
                {col.header}
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {col.isObj ? (
                  <CollapseContent content={data[col.name].name} index={40} />
                ) : (
                  <CollapseContent content={data[col.name]} index={40} />
                )}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }
}

UnitPlanInfoComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const UnitPlanInfo = withStyles(stylesUnitPlanInfo)(UnitPlanInfoComp);
