import React, { Component } from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import TablePaginationActions from '../ui/table/TablePaginationActions';
import { stylesDashboard } from './stylesDashboard';

class NotReportedLessons extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      notReportedLessons: [],
      page: 0,
      rowsPerPage: 10,
      loading: true
    };
  }

  componentDidMount() {
    this.populateMyClassData();
  }

  async populateMyClassData() {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const response = await fetch(
      `api/MyClasses/GetNotReportedLessons/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    let notReportedLessons = await response.json();

    this.setState({ notReportedLessons, loading: false });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  render() {
    const { classes } = this.props;
    const { notReportedLessons, rowsPerPage, page, loading } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, notReportedLessons.length - page * rowsPerPage);

    const cols = [
      { name: 'class', header: 'Class', align: 'left' },
      { name: 'beginDateFormat', header: 'Date', align: 'left' },
      { name: 'startTimeLocal', header: 'Start', align: 'left' },
      { name: 'endTimeLocal', header: 'End', align: 'left' },
      { name: 'lesson', header: 'Lesson', align: 'left' },
      { name: 'subject', header: 'Subject', align: 'left' },
      { name: 'content', header: 'Content', align: 'left' }
    ];

    return (
      <Fragment>
        {loading ? (
          <Loading />
        ) : (
          <Paper>
            <div className={classes.toolbar}>
              <Typography
                className={clsx(
                  classes.boldText,
                  classes.margin,
                  classes.title
                )}
              >
                Not Reported Lessons
              </Typography>
            </div>
            <Divider />
            <TableContainer>
              <Table
                className={classes.table}
                aria-label="Not reported lessons"
              >
                <TableHead>
                  <TableRow>
                    {cols.map(col => (
                      <TableCell
                        key={col.name}
                        align={col.align}
                        className={clsx(classes.tableCell, classes.boldText)}
                      >
                        {col.header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notReportedLessons
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <TableRow key={row.id}>
                        {cols.map(col => (
                          <TableCell
                            key={col.name}
                            align={col.align}
                            className={classes.tableCell}
                          >
                            {row[col.name]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 38 * emptyRows }}>
                      <TableCell colSpan={cols.length} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={notReportedLessons.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        )}
      </Fragment>
    );
  }
}

NotReportedLessons.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesDashboard)(NotReportedLessons);
