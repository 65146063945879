import React, { Component, Fragment } from 'react';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import ClassCourseInfo from '../ClassCourseInfo';
import CustomizedTable from '../../ui/table/CustomizedTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import { LessonAndTeacherPaths } from './LessonAndTeacherConstants';
import { LessonAndTeacherSelect } from './LessonAndTeacherSelect';
import { Loading } from '../../ui/Loading';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { fetchHelper } from '../../../helpers/fetch-helper';
import { withStyles } from '@material-ui/core/styles';
import { SwapVertRounded } from '@material-ui/icons';

const stylesListLessonAndTeacher = () => ({
  marginTop: '15px;'
});

class ListLessonAndTeacherComp extends Component {
  static displayName = ListLessonAndTeacherComp.name;
  constructor(...args) {
    super(...args);
    this.state = {
      DataLesson: [],
      DataTeacher: [],
      loading: true,
      openDialog: false,
      classId: this.props.classId,
      lstSelected: [],
      lstLessonSelected: [],
      lstTeacherId: [],
      isTeacher: true,
      classCourse: {}
    };
  }

  openDialog = isTeacher => event => {
    this.setState({ openDialog: true, isTeacher: isTeacher });
  };

  closeDialog = () => {
    this.setState({ openDialog: false });
  };
  backToList() {
    this.reloadTbl();
  }
  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  saveDataSelected = () => {
    if (this.state.isTeacher) {
      fetchHelper
        .put('api/ClassTeacher/' + this.state.classId, this.state.lstSelected)
        .then(this.backToList())
        .catch(error => {
          console.error('Error:', error);
        });
    } else {
      fetchHelper
        .post('api/ClassLesson/' + this.state.classId, this.state.lstSelected)
        .then(this.backToList())
        .catch(error => {
          console.error('Error:', error);
        });
    }
    this.setState({ openDialog: false, lstSelected: [] });
  };

  swapPrimaryTeacher = () => {
    fetchHelper
      .get('api/ClassTeacher/swap-primary/' + this.state.classId)
      .then(this.reloadTbl())
      .catch(error => {
        console.error('Error:', error);
      });
  };

  componentDidMount() {
    this.populateLessonAndTeacherCompData();
  }

  populateLessonAndTeacherCompData = async () => {
    const { classId } = this.state;
    await fetchHelper.post(
      `api/ClassLesson/BuildSheduleClassLessons/${classId}`,
      {},
      false
    );

    const dataClasCourse = await fetchHelper.get(`api/ClassCourses/${classId}`);
    const dataLesson = await fetchHelper.get(`api/ClassLesson/${classId}`);
    var lstLessonId = dataLesson.map(e => e.lessonId);

    const dataTeacher = await fetchHelper.get(`api/ClassTeacher/${classId}`);
    var lstTeacherId = dataTeacher.map(e => e.teacherId);
    this.setState({
      DataLesson: dataLesson,
      DataTeacher: dataTeacher,
      classCourse: dataClasCourse,
      loading: false,
      lstLessonSelected: lstLessonId,
      lstTeacherId
    });
  };
  reloadTbl = async () => {
    await this.populateLessonAndTeacherCompData();
    this.child1.updateChildData(this.state.DataTeacher);
    this.child2.updateChildData(this.state.DataLesson);
  };
  callbackValueCheckboxTabl = e => {
    this.setState({ lstSelected: e });
  };

  renderDatagrid() {
    const { classCourse, DataTeacher } = this.state;
    const colsLesson = [
      {
        name: 'level',
        header: 'Level',
        align: 'left'
      },
      { name: 'lesson', header: 'Lesson', align: 'left' },
      { name: 'subject', header: 'Subject', align: 'left' },
      { name: 'content', header: 'Content', align: 'left' },
      { name: 'tb', header: 'Tb', align: 'left' }
    ];

    const colsTeacher = [
      { name: 'firstName', header: 'First Name', align: 'left' },
      { name: 'lastName', header: 'Last Name', align: 'left' },
      {
        name: 'userName',
        header: 'User Name',
        align: 'left'
      },
      { name: 'employeeCode', header: 'Employee Code', align: 'left' },
      { name: 'phoneNumber', header: 'Phone', align: 'left' },
      {
        name: 'isPrimary',
        header: 'Main Teacher',
        isBool: true,
        align: 'center'
      }
    ];
    const actionsClassLesson = [
      {
        name: 'delete',
        tooltip: 'Delete',
        url: `${LessonAndTeacherPaths.DeleteClassLesson}`,
        icon: 'delete_icon'
      }
    ];
    const actionsClassTeacher = [
      {
        name: 'delete',
        tooltip: 'Delete',
        url: `${LessonAndTeacherPaths.DeleteClassTeacher}`,
        icon: 'delete_icon'
      }
    ];
    return (
      <div>
        <ClassCourseInfo classCourse={classCourse} />
        <CustomizedTable
          onRef={actualChild => (this.child1 = actualChild)}
          rows={DataTeacher}
          cols={colsTeacher}
          actions={actionsClassTeacher}
          eventReloadData={this.reloadTbl}
          isPaging={true}
        />
        <Tooltip title="Swap Main Teacher" aria-label="Swap" placement="left">
          <Fab
            onClick={() => this.swapPrimaryTeacher()}
            aria-label="Swap"
            color="secondary"
            style={{
              marginTop: '20px',
              marginBottom: '15px',
              marginRight: '15px'
            }}
          >
            <SwapVertRounded />
          </Fab>
        </Tooltip>
        <Tooltip title="Add Teacher" aria-label="Add" placement="right">
          <Fab
            onClick={this.openDialog(true)}
            aria-label="Add"
            color="primary"
            style={{ marginTop: '20px', marginBottom: '15px' }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
        <CustomizedTable
          onRef={actualChild => (this.child2 = actualChild)}
          rows={this.state.DataLesson}
          cols={colsLesson}
          actions={actionsClassLesson}
          eventReloadData={this.reloadTbl}
          isPaging={true}
        />
        <Tooltip title="Add Lesson" aria-label="Add" placement="right">
          <Fab
            onClick={this.openDialog(false)}
            aria-label="Add"
            color="primary"
            style={{ marginTop: '20px', marginBottom: '15px' }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
        <Dialog
          open={this.state.openDialog}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth
        >
          <DialogContent>
            <LessonAndTeacherSelect
              callbackFromListLessonTeacher={this.callbackValueCheckboxTabl}
              lstLessontId={this.state.lstLessonSelected}
              isTeacher={this.state.isTeacher}
              lstTeacherId={this.state.lstTeacherId}
              classId={this.state.classId}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={this.saveDataSelected} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return <Fragment>{contents}</Fragment>;
  }
}

ListLessonAndTeacherComp.propTypes = {
  classes: PropTypes.object.isRequired,
  classId: PropTypes.string
};

export const ListLessonAndTeacher = withStyles(stylesListLessonAndTeacher)(
  ListLessonAndTeacherComp
);
