import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { Students } from './Student';
import { StudentPaths, StudentActions } from './StudentConstans';

export default class StudentRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={StudentPaths.List}
          component={Students}
          action={StudentActions.List}
        />
        <PropsRoute
          path={StudentPaths.EditRoute}
          component={Students}
          action={StudentActions.Edit}
        />
        <PropsRoute
          path={StudentPaths.Create}
          component={Students}
          action={StudentActions.Create}
        />
        <PropsRoute
          path={StudentPaths.ViewLearningOutcomeRoute}
          component={Students}
          action={StudentActions.ViewLearningOutcome}
        />
        <PropsRoute
          path={StudentPaths.ViewScheduleStudentRoute}
          component={Students}
          action={StudentActions.ViewScheduleStudent}
        />
        <PropsRoute
          path={StudentPaths.ViewProposeRoute}
          component={Students}
          action={StudentActions.ViewPropose}
        />
      </Fragment>
    );
  }
}
