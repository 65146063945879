export const SettingActions = {
  List: 'list',
  Holidays: 'holidays',
  ResetUsersPwd: 'resetalluserspwd'
};

const prefix = '/settings';

export const SettingPaths = {
  SettingPrefix: prefix,
  List: `${prefix}/${SettingActions.List}`,
  Holidays: `${prefix}/${SettingActions.Holidays}`,
  ResetUsersPwd: `${prefix}/${SettingActions.ResetUsersPwd}`
};
