import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { UnitPlanPaths, UnitPlanActions } from './UnitPlanConstants';
import { MyClassPaths } from '../../my-class/MyClassConstants';
import { ListUnitPlan } from './ListUnitPlan';
import { EditUnitPlan } from './EditUnitPlan';
import { AdminPage } from '../../ui/page/AdminPage';
import { NormalTooltip } from '../../ui/ButtonStyles';

const stylesUnitPlan = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class UnitPlanComp extends Component {
  static displayName = UnitPlanComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.mainList = React.createRef();
    this.state = {
      lessonId: this.props.match.params.lessonId,
      planId: this.props.match.params.planId,
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case UnitPlanActions.List:
        this.state.pageTitle = 'Lesson Plan';
        this.state.content = (
          <ListUnitPlan
            lessonId={this.state.lessonId}
            onRef={actualChild => (this.mainList = actualChild)}
          />
        );
        break;
      case UnitPlanActions.Create:
        this.state.pageTitle = 'Create Procedure';
        this.state.content = (
          <EditUnitPlan
            onRef={actualChild => (this.child = actualChild)}
            action={this.state.action}
            lessonId={this.state.lessonId}
          />
        );
        break;
      case UnitPlanActions.Edit:
        this.state.pageTitle = 'Edit Procedure';
        this.state.content = (
          <EditUnitPlan
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            planId={this.state.planId}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  handleBackToParentList = () => {
    this.mainList.backToList();
  };

  showLessonPreview = async () => {
    window.open(`${MyClassPaths.LetsLearn}/${this.state.lessonId}/0/1/1`);
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === 'list' ? (
        <Fragment>
          <Fab
            aria-label="Add"
            color="primary"
            className={classes.fab}
            onClick={() =>
              this.redirectTo(`${UnitPlanPaths.Create}/${this.state.lessonId}`)
            }
          >
            <AddIcon />
          </Fab>
          <NormalTooltip title="Preview lesson">
            <Fab
              aria-label="Preview lesson"
              color="secondary"
              className={classes.fab}
              onClick={() => this.showLessonPreview()}
            >
              <PlayArrowIcon />
            </Fab>
          </NormalTooltip>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleBackToParentList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      ) : (
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.handleChildSubmit}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleChildBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      );

    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

UnitPlanComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const UnitPlan = withStyles(stylesUnitPlan)(UnitPlanComp);
