import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const stylesLessonPlanInfo = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    overflowX: 'auto'
  },
  cell: {
    padding: theme.spacing(1, 0, 1, 2)
  }
});

class LessonPlanInfoComp extends Component {
  static displayName = LessonPlanInfoComp.name;

  constructor(...args) {
    super(...args);
    this.state = {
      lessonPlan: this.props.lessonPlan
    };
  }

  render() {
    const { classes } = this.props;
    const { lessonPlan } = this.state;
    const cols = [
      {
        name: 'level',
        header: 'Level',
        isObj: true,
        xs: 1
      },
      {
        name: 'lesson',
        header: 'Lesson',
        xs: 1
      },
      {
        name: 'subject',
        header: 'Subject',
        xs: 2
      },
      {
        name: 'content',
        header: 'Content',
        xs: 5
      },
      {
        name: 'tb',
        header: 'TB',
        xs: 3
      }
    ];

    return (
      <Paper className={classes.root}>
        <Grid container>
          {cols.map((col, idx) => (
            <Grid item xs={col.xs} className={classes.cell} key={idx}>
              <Typography color="textSecondary" variant="caption">
                {col.header}
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {col.isObj ? lessonPlan[col.name].name : lessonPlan[col.name]}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }
}

LessonPlanInfoComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const LessonPlanInfo = withStyles(stylesLessonPlanInfo)(
  LessonPlanInfoComp
);
