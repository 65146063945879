export const Roles = {
  Admin: 'Admin',
  BranchAdmin: 'Branch Admin',
  Planner: 'Planner',
  Teacher: 'Teacher',
  AcadManager: 'Acad Manager',
  SaleManager: 'Sale Manager',
  SaleExecutive: 'Sale Executive'
};

export const AdminRoles = [
  Roles.Admin,
  Roles.BranchAdmin,
  Roles.AcadManager,
  Roles.SaleManager
];
