import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Badge,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  Toolbar,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import LoginIcon from '@material-ui/icons/ExitToApp';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
// import MailIcon from '@material-ui/icons/Mail';
// import InboxIcon from '@material-ui/icons/MoveToInbox';

import authService from '../../api-authorization/AuthorizeService';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';
import { homeListItems } from './listItems';
import {
  Planning,
  Academic,
  Organization,
  Reports,
  Administration
} from './MainMenu';

import GeeOLogo from '../../../images/logo/logo-title.svg';
import {
  ProfileMenuItem,
  StyledMenu,
  StyledMenuItem,
  stylesMainLayout
} from './stylesMainLayout';
import { format, parseISO } from 'date-fns';

class AppMainLayoutClass extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      open: true,
      anchorEl: null,
      mobileMoreAnchorEl: null,
      isAuthenticated: false,
      userName: null,
      userEvents: []
    };
  }

  componentDidMount() {
    this.populateState();
  }

  async populateState() {
    const [isAuthenticated] = await Promise.all([authService.isLoggedIn()]);
    this.setState({
      isAuthenticated
    });
    if (isAuthenticated) {
      const [user, token] = await Promise.all([
        authService.getUser(),
        authService.getAccessToken()
      ]);
      this.setState({
        loggedInUser: user,
        userName: user && user.name
      });
      this.populateUserInfo(user, token);
    }
  }

  async populateUserInfo(user, token) {
    const [respUserData, respUserEvents] = await Promise.all([
      fetch(`api/AspNetUsers/GetUserRole/${user.sub}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/ClassEvents/GetEventsPerUser/${user.sub}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [userRole, userEvents] = await Promise.all([
      respUserData.json(),
      respUserEvents.json()
    ]);
    this.setState({ userRole, userEvents });
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleOpenNotifications = event => {
    this.setState({ anchorElNotifications: event.currentTarget });
  };
  handleCloseNotifications = () => {
    this.setState({ anchorElNotifications: null });
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };
  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleProfile = () => {
    const profilePath = `${ApplicationPaths.Profile}`;
    this.redirectTo(profilePath);
    //this.handleMenuClose();
  };

  handleLogin = loginPath => {
    window.location.replace(loginPath);
    //this.handleMenuClose();
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  render() {
    const { classes } = this.props;
    const {
      isAuthenticated,
      userRole,
      loggedInUser,
      userEvents,
      open,
      anchorEl,
      mobileMoreAnchorEl,
      anchorElNotifications
    } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isOpenNotifications = Boolean(anchorElNotifications);
    const loginPath = `${ApplicationPaths.Login}`;
    const profilePath = `${ApplicationPaths.Profile}`;
    const logoutPath = {
      pathname: `${ApplicationPaths.LogOut}`,
      state: { local: true }
    };

    const renderNotifications = (
      <StyledMenu
        anchorEl={anchorElNotifications}
        keepMounted
        open={isOpenNotifications}
        onClose={this.handleCloseNotifications}
        className={classes.notifyMenu}
      >
        {userEvents.map((userEvent, idx) => {
          return (
            <div key={idx}>
              <ProfileMenuItem
                component="div"
                disableTouchRipple={true}
                style={{ fontSize: 14 }}
              >
                <span style={{ paddingRight: 20 }}>{userEvent.title}</span>
                <span style={{ marginLeft: 'auto' }}>
                  {format(parseISO(userEvent.startTime), 'dd/MM')}
                </span>
              </ProfileMenuItem>
              {idx < userEvents.length - 1 && <Divider />}
            </div>
          );
        })}
      </StyledMenu>
    );

    const renderMenu = (
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <ProfileMenuItem component="div" disableTouchRipple={true}>
          <ListItemIcon className={classes.menuItemIcon}>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.profileUserName}>
              {userRole ? userRole.fullName : ''}
            </Typography>
            <Typography>
              {loggedInUser ? loggedInUser.name : 'Account'}
            </Typography>
            <Button
              component={Link}
              to={profilePath}
              variant="outlined"
              size="small"
              color="primary"
              className={classes.profileMenuButton}
            >
              Account
            </Button>
          </ListItemText>
        </ProfileMenuItem>
        <Divider />
        <StyledMenuItem component={Link} to={logoutPath}>
          <ListItemIcon className={classes.menuItemIcon}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText color="inherit" primary="Sign Out" />
        </StyledMenuItem>
      </StyledMenu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        {isAuthenticated ? (
          <div>
            <ProfileMenuItem component="div" disableTouchRipple={true}>
              <ListItemIcon className={classes.menuItemIcon}>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText>
                <Typography className={classes.profileUserName}>
                  {userRole ? userRole.fullName : ''}
                </Typography>
                <Typography>
                  {loggedInUser ? loggedInUser.name : 'Account'}
                </Typography>
                <Button
                  component={Link}
                  to={profilePath}
                  variant="outlined"
                  size="small"
                  color="primary"
                  className={classes.profileMenuButton}
                >
                  Account
                </Button>
              </ListItemText>
            </ProfileMenuItem>
            <Divider />
            <StyledMenuItem
              disabled={userEvents.length === 0}
              onClick={this.handleOpenNotifications}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <Badge
                  badgeContent={userEvents && userEvents.length}
                  color="primary"
                  classes={{ colorPrimary: classes.badgeColor }}
                >
                  <NotificationsIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText color="inherit" primary="Notifications" />
            </StyledMenuItem>
            <Divider />
            <StyledMenuItem component={Link} to={logoutPath}>
              <ListItemIcon className={classes.menuItemIcon}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText color="inherit" primary="Sign Out" />
            </StyledMenuItem>
          </div>
        ) : (
          <StyledMenuItem onClick={() => this.handleLogin(loginPath)}>
            <ListItemIcon className={classes.menuItemIcon}>
              <LoginIcon />
            </ListItemIcon>
            <ListItemText color="inherit" primary="Sign In" />
          </StyledMenuItem>
        )}
      </Menu>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar disableGutters={!open} className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={GeeOLogo}
              alt="LogoText"
              height="48"
              className={clsx(classes.headerLogo, open && classes.hide)}
            />
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {isAuthenticated ? (
                <Fragment>
                  <IconButton
                    color="inherit"
                    disabled={userEvents.length === 0}
                    onClick={this.handleOpenNotifications}
                  >
                    <Badge
                      badgeContent={userEvents && userEvents.length}
                      color="primary"
                      classes={{ colorPrimary: classes.badgeColor }}
                    >
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                  <Typography className={classes.loggedInUserName}>
                    {userRole ? userRole.fullName : ''}
                  </Typography>
                  <IconButton
                    aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleProfileMenuOpen}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </Fragment>
              ) : (
                <IconButton
                  color="inherit"
                  onClick={() => this.handleLogin(loginPath)}
                >
                  <LoginIcon />
                </IconButton>
              )}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
        {renderNotifications}
        <Drawer
          className={classes.drawer}
          variant="permanent"
          anchor="left"
          open={open}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerPaperClose]: !open
            })
          }}
        >
          <div className={classes.drawerHeader}>
            <img
              src={GeeOLogo}
              alt="LogoText"
              height="48"
              className={classes.headerLogo}
            />
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{homeListItems}</List>
          {Planning}
          {Academic}
          {Organization}
          {Reports}
          {Administration}
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open
          })}
        >
          {/* <div className={classes.drawerHeader} /> */}
          {/* <div className={classes.appBarSpacer} /> */}
          {this.props.children}
        </main>
      </div>
    );
  }
}

AppMainLayoutClass.propTypes = {
  classes: PropTypes.object.isRequired
};

export const AppMainLayout = withStyles(stylesMainLayout)(AppMainLayoutClass);
