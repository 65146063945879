import {
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import React, { Component, Fragment } from 'react';

import CommonDataTable from '../ui/table/CommonDataTable';
import Icon from '@mdi/react';
import { Loading } from '../ui/Loading';
import PropTypes from 'prop-types';
import { StudentCoursePaths } from './StudentCourseConstants';
import authService from '../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { downloadFileFromBuffer } from '../../helpers/file-helper';
import { faDonate } from '@fortawesome/free-solid-svg-icons';
import { fetchHelper } from '../../helpers/fetch-helper';
import { mdiFileExportOutline } from '@mdi/js';
import { withStyles } from '@material-ui/core/styles';

const stylesPaymentInfo = theme => ({
  dataList: {
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120
  },
  selectBox: {
    minWidth: 150
  },
  checkbox: {
    verticalAlign: 'bottom',
    marginBottom: '6px'
  },
  label: {
    margin: theme.spacing(4.5, 2, 2)
  },
  labelText: {
    minWidth: 160
  },
  labelSign: {
    width: 20,
    textAlign: 'center'
  },
  numberField: {
    margin: theme.spacing(4, 0, 2),
    width: 50
  },
  actions: {
    margin: theme.spacing(1.5, 4),
    minWidth: 120,
    display: 'inline-flex',
    flexDirection: 'row'
  },
  extendedFab: {
    margin: theme.spacing(1)
  }
});

class ListStudentCourseComp extends Component {
  static displayName = ListStudentCourseComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    const _searchConditions = {
      campus: '',
      level: '',
      class: '',
      studentType: '0',
      suspended: false,
      remainSessionLow: 1,
      remainSessionHight: 10,
      month: 0
    };
    const _studentType = [
      { id: 1, name: 'Regular' },
      { id: 2, name: 'Catch Up' },
      { id: 3, name: 'Demo' }
    ];

    this.state = {
      data: [],
      searchConditions: _searchConditions,
      dataCampus: [],
      dataLevel: [],
      dataClass: [],
      dataAllClass: null,
      dataStudentType: _studentType,
      loadingFilter: false,
      loadingList: false,
      loading: true,
      loggedInUser: null,
      suspendedMode: false
    };
  }

  componentDidMount() {
    this.setState({ loggedInUser: authService.getUser() });
    this.populateDataFilter();
  }

  populateDataFilter = async () => {
    const loggedInUser = await authService.getUser();
    this.setState({ loggedInUser });
    this.bindLevel();
    this.bindCampus();
    this.bindClass();
    this.setState({
      loading: false
    });
  };

  bindLevel = () => {
    fetchHelper.get(`api/StudyLevels`).then(resp => {
      this.setState({ dataLevel: resp });
    });
  };

  bindCampus = () => {
    const _sub = this.state?.loggedInUser?.sub;
    fetchHelper.get(`api/Campus/GetCampusList/${_sub}`).then(resp => {
      this.setState({ dataCampus: resp });
    });
  };

  bindClass = () => {
    const _sub = this.state?.loggedInUser?.sub;
    fetchHelper
      .get(`api/ClassCourses/GetClassCourseDropDown/${_sub}`)
      .then(resp => {
        const dataClass = resp.filter(item => item.name !== '');
        this.setState({ dataClass, dataAllClass: dataClass });
      });
  };

  populatePaymnetData = async () => {
    this.setState({ loadingList: true });
    const { searchConditions, loggedInUser } = this.state;
    const dataList = await fetchHelper.post(
      `api/StudentCourses/GetListWithFilter/${loggedInUser.sub}`,
      searchConditions
    );
    const suspendedMode = searchConditions.suspended;
    this.setState({ dataList, loadingList: false, suspendedMode });
  };

  exportPaymentData = async () => {
    const { searchConditions, loggedInUser } = this.state;
    const response = await fetchHelper.downloadFile(
      `api/StudentCourses/export/${loggedInUser.sub}`,
      searchConditions
    );
    downloadFileFromBuffer(response);
  };

  handleChange = name => event => {
    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;

    this.setState(
      prevState => ({
        searchConditions: {
          ...prevState.searchConditions,
          [attrName]: attrValue
        }
      }),
      () => {
        if (['campus', 'level'].includes(attrName)) {
          this.updateClassList();
        }
      }
    );
  };

  updateClassList = async () => {
    this.setState({ loadingFilter: true });
    const { searchConditions, dataAllClass } = this.state;

    if (searchConditions.campus === '' && searchConditions.level === '') {
      this.setState({ dataClass: dataAllClass, loadingFilter: false });
      return;
    }

    fetchHelper
      .post(
        `api/ClassCourses/GetClassesWithFilter/${this.state.loggedInUser?.sub}`,
        searchConditions
      )
      .then(resp => {
        this.setState({
          dataClass: resp.filter(item => item.name !== ''),
          loadingFilter: false
        });
      });
  };

  renderContent() {
    const { classes } = this.props;
    const {
      searchConditions,
      dataCampus,
      dataLevel,
      dataClass,
      dataStudentType,
      loadingFilter,
      loadingList,
      dataList
    } = this.state;

    const _cols = [
      { name: 'student', header: 'Student', align: 'left' },
      { name: 'englishName', header: 'English Name', align: 'left' },
      { name: 'classCourse', header: 'Class', align: 'left' },
      { name: 'startDateFormat', header: 'Start Date', align: 'left' },
      { name: 'endDateFormat', header: 'End Date', align: 'left' },
      { name: 'studentType', header: 'Type', align: 'left' },
      { name: 'remainSessions', header: 'Remain Sessions', align: 'center' }
    ];

    const cols = !this.state.suspendedMode
      ? _cols
      : _cols.filter(
          x =>
            x.name !== 'classCourse' &&
            x.name !== 'studentType' &&
            x.name !== 'endDateFormat'
        );

    const actions = [
      {
        name: 'multi-route',
        tooltip: 'Renew',
        url: `${StudentCoursePaths.Create}`,
        icon_fa: faDonate
      }
      // {
      //   name: 'edit',
      //   tooltip: 'Edit payment',
      //   url: `${StudentCoursePaths.Edit}`,
      //   icon: 'edit_icon'
      // },
      // {
      //   name: 'delete',
      //   tooltip: 'Delete',
      //   url: `${StudentCoursePaths.Delete}`,
      //   icon: 'delete_icon'
      // }
    ];

    return (
      <Fragment>
        <Paper>
          <FormControl className={clsx(classes.formControl, classes.textField)}>
            <InputLabel htmlFor="campus-id">Campus</InputLabel>
            <Select
              id="campus-id"
              name="campus"
              value={searchConditions['campus']}
              onChange={this.handleChange('campus')}
            >
              <MenuItem value="" key="">
                All
              </MenuItem>
              {dataCampus.map(row => {
                return (
                  <MenuItem value={row.id} key={row.id}>
                    {row.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {!searchConditions.suspended && (
            <>
              <FormControl
                className={clsx(classes.formControl, classes.textField)}
              >
                <InputLabel htmlFor="level-id">Level</InputLabel>
                <Select
                  id="level-id"
                  name="level"
                  value={searchConditions['level']}
                  onChange={this.handleChange('level')}
                >
                  <MenuItem value="" key="">
                    All
                  </MenuItem>
                  {dataLevel.map(row => {
                    return (
                      <MenuItem value={row.id} key={row.id}>
                        {row.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                className={clsx(classes.formControl, classes.textField)}
              >
                <InputLabel htmlFor="class-id">Class</InputLabel>
                <Select
                  id="class-id"
                  name="class"
                  value={searchConditions['class']}
                  onChange={this.handleChange('class')}
                >
                  <MenuItem value="" key="">
                    All
                  </MenuItem>
                  {loadingFilter
                    ? null
                    : dataClass.map(row => {
                        return (
                          <MenuItem value={row.id} key={row.id}>
                            {row.name}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </>
          )}
          <FormControl className={clsx(classes.formControl, classes.textField)}>
            <InputLabel htmlFor="student-type">Type</InputLabel>
            <Select
              id="student-type"
              name="studentType"
              value={searchConditions['studentType']}
              onChange={this.handleChange('studentType')}
            >
              <MenuItem value="0" key="">
                All
              </MenuItem>
              {!searchConditions.suspended
                ? dataStudentType.map(row => {
                    return (
                      <MenuItem value={row.id} key={row.id}>
                        {row.name}
                      </MenuItem>
                    );
                  })
                : [
                    { id: 4, name: 'Suspended' },
                    { id: 5, name: 'Terminated' }
                  ].map(row => {
                    return (
                      <MenuItem value={row.id} key={row.id}>
                        {row.name}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
          <FormControl
            className={clsx(
              classes.formControl,
              classes.textField,
              classes.checkbox
            )}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={searchConditions['suspended']}
                  onChange={this.handleChange('suspended')}
                />
              }
              label={'Sus/Ter'}
            />
          </FormControl>
          {!searchConditions.suspended && (
            <>
              <FormControl className={clsx(classes.formControl)}>
                <InputLabel htmlFor="month-id">Est End Date</InputLabel>
                <Select
                  id="month-id"
                  name="month"
                  value={searchConditions['month']}
                  onChange={this.handleChange('month')}
                >
                  <MenuItem value="0" key="">
                    All
                  </MenuItem>
                  {Array(12)
                    .fill()
                    .map((_, idx) => {
                      return (
                        <MenuItem value={idx + 1} key={idx + 1}>
                          {idx + 1}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </>
          )}
          <FormControl className={clsx(classes.label, classes.labelText)}>
            <Typography>Remaining Sessions</Typography>
          </FormControl>
          <FormControl className={classes.numberField}>
            <TextField
              type="number"
              name="remainSessionLow"
              value={searchConditions['remainSessionLow']}
              onChange={this.handleChange('remainSessionLow')}
            />
          </FormControl>
          <FormControl className={clsx(classes.label, classes.labelSign)}>
            <Typography> - </Typography>
          </FormControl>
          <FormControl className={classes.numberField}>
            <TextField
              type="number"
              name="remainSessionHight"
              value={searchConditions['remainSessionHight']}
              onChange={this.handleChange('remainSessionHight')}
            />
          </FormControl>
          <FormControl className={classes.actions}>
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="List All"
              className={classes.extendedFab}
              onClick={this.populatePaymnetData}
            >
              List All
            </Fab>
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              aria-label="Export"
              className={classes.extendedFab}
              onClick={this.exportPaymentData}
            >
              <Icon
                path={mdiFileExportOutline}
                size={1}
                className={classes.btnIcon}
              />
              Export
            </Fab>
          </FormControl>
        </Paper>
        {loadingList ? (
          <Paper className={classes.dataList}>
            <Loading />
          </Paper>
        ) : dataList ? (
          <Paper className={classes.dataList}>
            <CommonDataTable
              onRef={actualChild => (this.child = actualChild)}
              rows={dataList}
              cols={cols}
              actions={actions}
              isPaging={true}
            />
          </Paper>
        ) : null}
      </Fragment>
    );
  }

  render() {
    const { loading } = this.state;
    let contents = loading ? <Loading /> : this.renderContent();

    return <Fragment>{contents}</Fragment>;
  }
}

ListStudentCourseComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListStudentCourse = withStyles(stylesPaymentInfo)(
  ListStudentCourseComp
);
