import { Bar, BarChart, BarSeries } from 'reaviz';
import { Grid, Paper, Typography } from '@material-ui/core';
import React, { Component, Fragment } from 'react';

import { PropTypes } from 'prop-types';
import clsx from 'clsx';

export class BarChartViewStd extends Component {
  render() {
    const { title, subtitle, chartData, classes } = this.props;

    return (
      <Paper className={clsx(classes.chartBox, classes.chartBoxEqualHeight)}>
        <Typography variant="h3" className={classes.heading}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          {subtitle}
        </Typography>
        <Grid item xs={12} container>
          <Grid item xs={3} style={{ padding: '30px 40px 0 0' }}>
            <Grid
              item
              xs={12}
              container
              style={{
                padding: '0 20px 0 10px',
                border: '1px solid lightgray'
              }}
            >
              <Grid
                item
                xs={4}
                className={clsx(
                  classes.chartLabel,
                  classes.chartLabelHead,
                  classes.chartLabelPadding
                )}
              >
                Tuần
              </Grid>
              <Grid
                item
                xs={4}
                className={clsx(
                  classes.chartLabel,
                  classes.chartLabelBlue,
                  classes.chartLabelPadding
                )}
              >
                RV
              </Grid>
              <Grid
                item
                xs={4}
                className={clsx(
                  classes.chartLabel,
                  classes.chartLabelOrange,
                  classes.chartLabelPadding
                )}
              >
                RT
              </Grid>
            </Grid>
            <div
              style={{
                height: 220,
                overflowY: 'scroll',
                border: '1px solid lightgray'
              }}
            >
              <Grid item xs={12} container style={{ padding: '0 10px' }}>
                {chartData?.map((row, idx) => (
                  <Fragment key={idx}>
                    <Grid item xs={4} className={clsx(classes.chartLabel)}>
                      {row.weekNum}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      className={clsx(
                        classes.chartLabel,
                        classes.chartLabelBlue
                      )}
                    >
                      {row.data[0].data}%
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      className={clsx(
                        classes.chartLabel,
                        classes.chartLabelOrange
                      )}
                    >
                      {row.data[1].data}%
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={9} style={{ overflowY: 'auto' }}>
            <div
              className={clsx(classes.chartWrap, classes.chartWrapPadding)}
              style={
                chartData.length > 10
                  ? { width: 80 * chartData.length }
                  : { width: '100%' }
              }
            >
              <BarChart
                data={chartData}
                series={
                  <BarSeries
                    type="grouped"
                    bar={<Bar gradient={false} />}
                    colorScheme={['#42a5f6', '#ff7043']}
                    padding={0.8}
                  />
                }
                className={classes.barChart}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

BarChartViewStd.propTypes = {
  classes: PropTypes.object.isRequired,
  chartData: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string
};
