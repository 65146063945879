export const StoryActions = {
  Home: 'home'
};

const prefix = '/story';

export const StoryPaths = {
  StoryPrefix: prefix,
  Home: `${prefix}/${StoryActions.Home}`,
  HomeRoute: `${prefix}/${StoryActions.Home}/:classId`
};
