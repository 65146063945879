import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import authService from '../api-authorization/AuthorizeService';
import { Typography, Icon, Grid } from '@material-ui/core';
import { Loading } from '../ui/Loading';

import EmojiRating0 from '../../images/assessment/emoji-rating-0.svg';
import EmojiRating1 from '../../images/assessment/emoji-rating-1.svg';
import EmojiRating2 from '../../images/assessment/emoji-rating-2.svg';
import EmojiRating3 from '../../images/assessment/emoji-rating-3.svg';
import EmojiRating4 from '../../images/assessment/emoji-rating-4.svg';
import EmojiRating5 from '../../images/assessment/emoji-rating-5.svg';

const stylesStudentRatingStar = theme => ({
  root: {
    flexGrow: 1,
    alignItems: 'stretch'
  },
  contentCol: {
    flexGrow: 1
  },
  navCol: {},
  footer: {},
  studentName: {
    lineHeight: '34px'
  },
  label: {
    fontSize: 14,
    color: '#707070',
    padding: theme.spacing(1)
  },
  labelBold: {
    fontWeight: 'bold'
  },
  box: {
    margin: theme.spacing(4, 0),
    textAlign: 'right'
  },
  button: {
    margin: theme.spacing(0, 1.5),
    borderRadius: theme.spacing(2.25),
    minWidth: 100
  },
  emoji: {
    display: '-webkit-box',
    alignItems: 'center',
    '-webkit-box-orient': 'vertical',
    '-webkit-box-direction': 'normal',
    'flex-direction': 'column',
    '-webkit-box-align': 'center',
    '-webkit-transition': '0.3s',
    transition: '0.3s'
  },
  emojiSvg: {
    margin: '5px 0',
    width: 40,
    height: 40,
    flexShrink: 0
  },
  emojiRating1: {
    '-webkit-transform': 'translateY(-50px)',
    transform: 'translateY(-50px)'
  },
  emojiRating2: {
    '-webkit-transform': 'translateY(-100px)',
    transform: 'translateY(-100px)'
  },
  emojiRating4: {
    '-webkit-transform': 'translateY(-150px)',
    transform: 'translateY(-150px)'
  },
  emojiRating7: {
    '-webkit-transform': 'translateY(-200px)',
    transform: 'translateY(-200px)'
  },
  emojiRating9: {
    '-webkit-transform': 'translateY(-250px)',
    transform: 'translateY(-250px)'
  }
});

class StudentRatingStarComp extends Component {
  static displayName = StudentRatingStarComp.name;

  constructor(...args) {
    super(...args);
    this.state = { classRooms: [], loading: true };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateStudentRating();
  }

  populateClassRoomData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/ClassRooms/GetClassRoom/0', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ classRooms: data, loading: false });
  };

  populateStudentRating = () => {
    const { currentRatingStar, currentClassStudents } = this.props;
    const ratingMap = [];
    if (currentRatingStar && currentRatingStar.length > 0) {
      currentRatingStar.forEach(student => {
        ratingMap[student.studentInfoId] = student.starScore;
      });
    } else {
      currentClassStudents.forEach(student => {
        ratingMap[student.id] = 0;
      });
    }
    this.setState({ ratingMap, loading: false });
  };

  changeRatingStar = (studentId, ratingStar) => {
    const { ratingMap } = this.state;
    ratingMap[studentId] = ratingStar;
    this.setState({ ratingMap });
    // this.setState({ ratingMap }, this.props.saveStudentAssessment);
  };

  getRatingMap = () => {
    const { ratingMap } = this.state;
    return ratingMap;
  };

  render() {
    const { currentClassStudents, classes } = this.props;
    const { ratingMap } = this.state;

    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Grid container className={classes.root}>
            <Grid item className={classes.contentCol}>
              {currentClassStudents.map((student, idx) => (
                <div className="row-student" key={idx}>
                  <div className="left-side id-tab">
                    <div className="name-id">
                      <Typography variant="h1" className={classes.studentName}>
                        {`${student.englishName} - ${student.studentName}`}
                      </Typography>
                    </div>
                  </div>

                  <div className="sider1 right-side">
                    <form className="rating-form" action="#" method="post">
                      <fieldset className="form-group">
                        <div className="form-item">
                          <input
                            id={'rating-5-' + student.id}
                            type="radio"
                            value="5"
                            checked={ratingMap[student.id] === 5}
                            onChange={() =>
                              this.changeRatingStar(student.id, 5)
                            }
                          />
                          <label htmlFor={'rating-5-' + student.id}>
                            <span className="rating-star">
                              <i className="fa fa-star-o"></i>
                              <i className="fa fa-star"></i>
                            </span>
                          </label>

                          <input
                            id={'rating-4-' + student.id}
                            type="radio"
                            value="4"
                            checked={ratingMap[student.id] === 4}
                            onChange={() =>
                              this.changeRatingStar(student.id, 4)
                            }
                          />
                          <label htmlFor={'rating-4-' + student.id}>
                            <span className="rating-star">
                              <i className="fa fa-star-o"></i>
                              <i className="fa fa-star"></i>
                            </span>
                          </label>

                          <input
                            id={'rating-3-' + student.id}
                            type="radio"
                            value="3"
                            checked={ratingMap[student.id] === 3}
                            onChange={() =>
                              this.changeRatingStar(student.id, 3)
                            }
                          />
                          <label htmlFor={'rating-3-' + student.id}>
                            <span className="rating-star">
                              <i className="fa fa-star-o"></i>
                              <i className="fa fa-star"></i>
                            </span>
                          </label>

                          <input
                            id={'rating-2-' + student.id}
                            type="radio"
                            value="2"
                            checked={ratingMap[student.id] === 2}
                            onChange={() =>
                              this.changeRatingStar(student.id, 2)
                            }
                          />
                          <label htmlFor={'rating-2-' + student.id}>
                            <span className="rating-star">
                              <i className="fa fa-star-o"></i>
                              <i className="fa fa-star"></i>
                            </span>
                          </label>

                          <input
                            id={'rating-1-' + student.id}
                            type="radio"
                            value="1"
                            checked={ratingMap[student.id] === 1}
                            onChange={() =>
                              this.changeRatingStar(student.id, 1)
                            }
                          />
                          <label htmlFor={'rating-1-' + student.id}>
                            <span className="rating-star">
                              <i className="fa fa-star-o"></i>
                              <i className="fa fa-star"></i>
                            </span>
                          </label>

                          <div className="emoji-wrapper">
                            <div
                              className={classNames(
                                'emoji',
                                this.props.className,
                                {
                                  'rating-1-emoji': ratingMap[student.id] === 1,
                                  'rating-2-emoji': ratingMap[student.id] === 2,
                                  'rating-4-emoji': ratingMap[student.id] === 3,
                                  'rating-7-emoji': ratingMap[student.id] === 4,
                                  'rating-9-emoji': ratingMap[student.id] === 5
                                }
                              )}
                            >
                              <img
                                src={EmojiRating0}
                                alt="EmojiRating0"
                                className={classes.emojiSvg}
                              />
                              <img
                                src={EmojiRating1}
                                alt="EmojiRating1"
                                className={classes.emojiSvg}
                              />
                              <img
                                src={EmojiRating2}
                                alt="EmojiRating2"
                                className={classes.emojiSvg}
                              />
                              <img
                                src={EmojiRating3}
                                alt="EmojiRating3"
                                className={classes.emojiSvg}
                              />
                              <img
                                src={EmojiRating4}
                                alt="EmojiRating4"
                                className={classes.emojiSvg}
                              />
                              <img
                                src={EmojiRating5}
                                alt="EmojiRating5"
                                className={classes.emojiSvg}
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              ))}
            </Grid>
            <Grid item className={classes.navCol}>
              <div className="swap">
                <Icon
                  className="fa fa-angle-double-right"
                  onClick={() => this.props.toAssessmentStudent()}
                />
              </div>
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

StudentRatingStarComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const StudentRatingStar = withStyles(stylesStudentRatingStar)(
  StudentRatingStarComp
);
