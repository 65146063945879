import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import {
  MessageBars,
  MessageBarVariants,
  MessageType,
  MessageBarText
} from '../ui/popup/Messages';
import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SendIcon from '@material-ui/icons/Send';
import PostStory from './PostStory';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';

class ListStory extends Component {
  constructor(...args) {
    super(...args);
    this.messageBar = React.createRef();
    this.postStoryDialog = React.createRef();
    this.state = {
      stories: [],
      loading: true,
      postStoryDialog: (
        <PostStory
          onRef={actualChild => (this.postStoryDialog = actualChild)}
          refreshStories={this.refreshStories}
          showErrorMessage={this.showErrorMessage}
        />
      ),
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Success}
          type={MessageType.Upload}
          message={MessageBarText.Success.upload}
          handleClose={this.handleClose}
        />
      )
    };
  }

  componentDidMount() {
    this.populateStories();
  }

  populateStories = async () => {
    const [token] = await Promise.all([authService.getAccessToken()]);
    const { classId } = this.props;

    const [respStories] = await Promise.all([
      fetch(`api/Story/GetStories/${classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [stories] = await Promise.all([respStories.json()]);
    this.setState({ stories, loading: false });
  };

  refreshStories = async () => {
    this.setState({ loading: true }, this.populateStories);
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  openPostStory = async () => {
    this.postStoryDialog.openPostStory(this.props.classId);
  };

  postComment = async storyId => {
    const { commentText } = this.state;
    if (!commentText) return;

    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);

    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    const storyComment = {
      storyId,
      userId: loggedInUser.sub,
      text: commentText,
      createdBy: loggedInUser.sub
    };

    const errMsg = 'An error occurred while posting comment!';

    await fetch('api/Story/PostComment', {
      method: 'POST',
      body: JSON.stringify(storyComment),
      headers: headers
    })
      .then(async response => {
        if (response.ok) {
          this.setState({ commentText: '' });
          this.refreshStories();
        } else {
          this.showErrorMessage(errMsg);
          throw new Error(response.status);
        }
      })
      .catch(error => {
        this.showErrorMessage(errMsg);
        console.error('Error:', error);
      });
  };

  showErrorMessage = async msg => {
    this.messageBar.showMessage(
      MessageBarVariants.Error,
      MessageType.Message,
      msg
    );
  };

  handleChange = event => {
    this.setState({ commentText: event.target.value });
  };
  handleKeyDown = storyId => event => {
    if (event.key === 'Enter') {
      this.postComment(storyId);
    }
  };

  renderDatagrid() {
    const { classes } = this.props;
    const { stories } = this.state;

    return (
      <Fragment>
        {stories.map(story => (
          <Grid item key={story.id}>
            <Paper
              className={clsx(classes.storyFrameStyle, classes.storyFrame)}
            >
              <img
                src={`/api/Story/GetForView/${story.id}/${encodeURI(
                  story.imageName
                )}`}
                alt="GeeO story"
                className={classes.storyPhoto}
              />
              <Typography variant="body2" className={classes.storyTime}>
                {format(parseISO(story.createdDate), 'dd/MM/yy hh:mm a')}
              </Typography>
              <Box m={2}>
                <Typography className={classes.storyCation}>
                  {story.caption}
                </Typography>
              </Box>
              <Divider />
              <Box m={1}>
                {story.comments.map(comment => (
                  <Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    key={comment.id}
                    className={classes.commentBox}
                  >
                    <Grid item>
                      <AccountCircle
                        className={clsx(
                          classes.addIcon,
                          classes.commentAuthorIcon
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column">
                        <Grid item xs>
                          <Box
                            component="span"
                            className={classes.btnTitleText}
                          >
                            {comment.authorName}
                          </Box>
                          <Box component="span">&nbsp;·&nbsp;</Box>
                          <Box
                            component="span"
                            className={classes.btnSubtitleText}
                          >
                            {format(
                              parseISO(comment.createdDate),
                              'dd/MM/yy hh:mm a'
                            )}
                          </Box>
                        </Grid>
                        <Grid item className={classes.commentText}>
                          <Typography className={classes.btnSubtitleText}>
                            {comment.text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                <Grid
                  container
                  justify="flex-start"
                  alignItems="center"
                  className={classes.commentBox}
                >
                  <Grid item>
                    <AccountCircle
                      className={clsx(classes.addIcon, classes.profileIcon)}
                    />
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container>
                      <Grid item xs>
                        <InputBase
                          fullWidth
                          className={classes.commentInput}
                          placeholder={'Write comment...'}
                          name={'commentText'}
                          onChange={this.handleChange}
                          onKeyDown={this.handleKeyDown(story.id)}
                        />
                      </Grid>
                      <Grid item>
                        <Tooltip title="Post" aria-label="post comment">
                          <IconButton
                            color="primary"
                            aria-label="post comment"
                            component="span"
                            onClick={() => this.postComment(story.id)}
                          >
                            <SendIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Fragment>
    );
  }

  render() {
    const { classes } = this.props;

    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return (
      <Fragment>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Paper
              className={clsx(
                classes.storyFrameStyle,
                classes.createStoryFrame
              )}
            >
              <Button
                onClick={() => this.openPostStory()}
                className={classes.createStoryButton}
              >
                <Grid container justify="flex-start" alignItems="center">
                  <Grid item>
                    <Icon className={classes.addIcon}>add_circle</Icon>
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column">
                      <Grid item xs>
                        <Typography className={classes.btnTitleText}>
                          Create story
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.btnSubtitleText}>
                          Share photos or write something...
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Button>
            </Paper>
          </Grid>
          {contents}
        </Grid>
        {this.state.postStoryDialog}
        {this.state.messageBar}
      </Fragment>
    );
  }
}

const stylesListStory = theme => ({
  storyFrameStyle: {
    width: 700,
    maxWidth: 700
  },
  createStoryFrame: {
    padding: theme.spacing(1)
  },
  createStoryButton: {
    minWidth: 200,
    width: '100%',
    padding: theme.spacing(0.5, 1),
    textTransform: 'none',
    textAlign: 'left'
  },
  addIcon: {
    margin: theme.spacing(0.75, 1.5, 0, 0),
    fontSize: 35,
    color: theme.palette.text.hint
  },
  commentBox: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  profileIcon: {
    margin: theme.spacing(0, 1, 0, 0)
  },
  commentAuthorIcon: {
    margin: theme.spacing(0, 1, 0, 0)
  },
  commentInput: {
    margin: theme.spacing(0.75)
  },
  commentText: {
    margin: theme.spacing(0.5, 0)
  },
  btnTitleText: {
    fontFamily: 'RalewayBold',
    fontSize: 14
  },
  btnSubtitleText: {
    fontSize: 12
  },
  storyFrame: {
    padding: theme.spacing(1)
  },
  storyTime: {
    padding: theme.spacing(0, 2)
  },
  storyCation: {
    fontFamily: 'RalewayBold'
  },
  storyPhoto: {
    width: '100%'
  }
});

ListStory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesListStory)(ListStory);
