export const LessonPlanActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  LessonContent: 'lesson-content',
  Delete: 'delete'
};

const prefix = '/lessons';

export const LessonPlanPaths = {
  LessonPrefix: prefix,
  ListRoute: `${prefix}/${LessonPlanActions.List}/:levelId`,
  List: `${prefix}/${LessonPlanActions.List}`,
  EditRoute: `${prefix}/${LessonPlanActions.Edit}/:lessonId`,
  Edit: `${prefix}/${LessonPlanActions.Edit}`,
  CreateRoute: `${prefix}/${LessonPlanActions.Create}/:levelId`,
  Create: `${prefix}/${LessonPlanActions.Create}`,
  LessonContentRoute: `${prefix}/${LessonPlanActions.LessonContent}/:lessonId`,
  LessonContent: `${prefix}/${LessonPlanActions.LessonContent}`,
  Delete: `LessonPlans`
};
