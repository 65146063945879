import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../../common/route/PropsRoute';
import { ClassRooms } from './ClassRoom';
import { ClassRoomPaths, ClassRoomActions } from './ClassRoomConstants';

export default class ClassRoomRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={ClassRoomPaths.List}
          component={ClassRooms}
          action={ClassRoomActions.List}
        />
        <PropsRoute
          path={ClassRoomPaths.EditRoute}
          component={ClassRooms}
          action={ClassRoomActions.Edit}
        />
        <PropsRoute
          path={ClassRoomPaths.Create}
          component={ClassRooms}
          action={ClassRoomActions.Create}
        />
      </Fragment>
    );
  }
}
