export const ProposalActions = {
  List: 'list',
  Edit: 'edit',
  SelectStudent: 'select-student'
};

const prefix = '/proposal';

export const ProposalPaths = {
  ProposalPrefix: prefix,
  List: `${prefix}/${ProposalActions.List}`,
  Edit: `${prefix}/${ProposalActions.Edit}`,
  EditRoute: `${prefix}/${ProposalActions.Edit}/:classId`,
  SelectStudent: `${prefix}/${ProposalActions.SelectStudent}`,
  SelectStudentRoute: `${prefix}/${ProposalActions.SelectStudent}/:classId`
};
