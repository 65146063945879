export const LiveClassActions = {
  Home: 'home',
  Room: 'room'
};

const prefix = '/live-class';

export const LiveClassPaths = {
  LiveClassPrefix: prefix,
  Home: `${prefix}/${LiveClassActions.Home}`,
  RoomRoute: `${prefix}/${LiveClassActions.Room}/:role/:userId/:roomId`
};
