import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const stylesMyClassLessonInfo = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    overflowX: 'auto'
  },
  cell: {
    padding: theme.spacing(1, 0, 1, 2)
  },
  reportDateTime: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  reportBy: {
    margin: theme.spacing(0, 0, 0, 2)
  },
  teachers: {
    margin: theme.spacing(1, 0, 0, 2)
  }
});

class MyClassLessonInfo extends Component {
  static displayName = MyClassLessonInfo.name;

  constructor(...args) {
    super(...args);
    this.state = {
      classLesson: this.props.classLesson
    };
  }

  render() {
    const { classes, reportDateTime, teachers } = this.props;
    const { classLesson } = this.state;

    const cols = [
      {
        name: 'level',
        header: 'Level',
        xs: 1
      },
      {
        name: 'class',
        header: 'Class',
        xs: 2
      },
      { name: 'schedule', header: 'Schedule', xs: 1 },
      { name: 'startTimeLocal', header: 'Start time', xs: 1 },
      { name: 'endTimeLocal', header: 'End time', xs: 1 },
      {
        name: 'lesson',
        header: 'Lesson',
        xs: 1
      },
      {
        name: 'subject',
        header: 'Subject',
        xs: 2
      },
      {
        name: 'content',
        header: 'Content',
        xs: 2
      },
      {
        name: 'tb',
        header: 'TB',
        xs: 1
      }
    ];

    return (
      <Paper className={classes.root}>
        {reportDateTime && (
          <Fragment>
            <Typography
              color="textSecondary"
              variant="h5"
              className={classes.reportDateTime}
            >
              Report Date Time
            </Typography>
            <Typography
              color="textPrimary"
              variant="body2"
              className={classes.reportDateTime}
            >
              {classLesson.reportDateTime}
            </Typography>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="baseline"
            >
              <Typography
                color="textSecondary"
                variant="subtitle1"
                className={classes.reportBy}
              >
                Report By:
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
                className={classes.reportBy}
              >
                {classLesson.reportBy}
              </Typography>
            </Grid>
          </Fragment>
        )}
        {teachers && (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="baseline"
          >
            <Typography
              color="textSecondary"
              variant="subtitle1"
              className={reportDateTime ? classes.reportBy : classes.teachers}
            >
              Teachers:
            </Typography>
            <Typography
              color="textPrimary"
              variant="body2"
              className={reportDateTime ? classes.reportBy : classes.teachers}
            >
              {classLesson.teachers}
            </Typography>
          </Grid>
        )}
        <Grid container>
          {cols.map((col, idx) => (
            <Grid item xs={col.xs} className={classes.cell} key={idx}>
              <Typography color="textSecondary" variant="caption">
                {col.header}
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {classLesson[col.name]}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }
}

MyClassLessonInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesMyClassLessonInfo)(MyClassLessonInfo);
