import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import authService from '../api-authorization/AuthorizeService';
import { ActionIconButton, NormalTooltip } from '../ui/ButtonStyles';
import {
  MessageBarVariants,
  MessageBarText,
  MessageType
} from '../ui/popup/Messages';
import { Dialogs } from '../ui/popup/Dialogs';

const stylesUploadFileBtn = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  form: {
    display: 'inline-flex'
  },
  actionIcon: {
    fontSize: 20
  },
  menuItemIcon: {
    minWidth: 34
  },
  menuItemText: {
    fontSize: 12
  }
});

class UploadFileBtn extends Component {
  constructor(...args) {
    super(...args);
    this.uploadForm = React.createRef();
    this.confirmDialog = React.createRef();
    this.state = {
      anchorContextMenu: null,
      confirmDialogActions: (
        <Fragment>
          <Button onClick={this.closeConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={this.doConfirmAction} color="primary">
            Delete
          </Button>
        </Fragment>
      ),
      confirmDialog: (
        <Dialogs onRef={actualChild => (this.confirmDialog = actualChild)} />
      )
    };
  }

  async doUploadFile(data) {
    const token = await authService.getAccessToken();
    const { info, selectObj } = this.props;

    const action = `${info.url}/${selectObj.id}`;

    fetch(action, {
      method: 'POST',
      body: data,
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        if (response.ok) {
          this.props.callBackFn(
            true,
            MessageBarVariants.Success,
            MessageType.Upload,
            MessageBarText.Success[MessageType.Upload]
          );
        } else throw new Error(response.status);
      })
      .catch(error => {
        this.props.callBackFn(
          false,
          MessageBarVariants.Error,
          MessageType.Upload,
          MessageBarText.Error[MessageType.Upload]
        );
        console.log(error);
      });
  }

  handleSubmit = e => {
    e.preventDefault();

    const form = e.target;
    const data = new FormData(form);
    this.doUploadFile(data);
  };
  handleUploadFile = () => {
    ReactDOM.findDOMNode(this.uploadForm.current).dispatchEvent(
      new Event('submit')
    );
  };

  handleContextMenu = e => {
    e.preventDefault();
    const currentTarget = e.currentTarget;
    const { info } = this.props;
    if (info.contextMenu) {
      this.setState({ anchorContextMenu: currentTarget });
    }
  };
  handleClose = () => {
    this.setState({ anchorContextMenu: null });
  };

  handleCtxMenuCmd = menu => {
    this.setState({ selectMenu: menu });
    if (menu.confirmType) {
      this.confirmDialog.showDialogWithCmds(
        menu.confirmType,
        this.state.confirmDialogActions
      );
    } else {
      this.doConfirmAction();
    }
    this.handleClose();
  };

  closeConfirmDialog = () => {
    this.confirmDialog.closeDialog();
  };

  doConfirmAction = async () => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const { selectMenu } = this.state;
    const { selectObj } = this.props;
    fetch(`${selectMenu.menuCmdUrl}/${selectObj.id}`, {
      method: selectMenu.actMethod,
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.props.callBackFn(
            true,
            MessageBarVariants.Success,
            MessageBarText.Success[selectMenu.messageType]
          );
        } else throw new Error(response.status);
      })
      .catch(error => {
        this.props.callBackFn(
          false,
          MessageBarVariants.Error,
          MessageBarText.Error[selectMenu.messageType]
        );
        console.error('Error:', error);
      });
    this.closeConfirmDialog();
  };

  render() {
    const { classes, selectObj, info } = this.props;
    const { anchorContextMenu } = this.state;
    const open = Boolean(anchorContextMenu);
    const fileId = `nest-upload-file-${selectObj.id}`;

    return (
      <Fragment>
        <form
          id={selectObj.id}
          className={classes.form}
          ref={this.uploadForm}
          encType="multipart/form-data"
          onSubmit={this.handleSubmit}
        >
          {/* <label htmlFor={fileId} onContextMenu={this.handleContextMenu}> */}
          <InputLabel htmlFor={fileId} onContextMenu={this.handleContextMenu}>
            <NormalTooltip title={open ? '' : info.tooltip}>
              <ActionIconButton component="span">
                <Icon className={classes.actionIcon}>{info.icon}</Icon>
              </ActionIconButton>
            </NormalTooltip>
          </InputLabel>
          {/* </label> */}
          <input
            type="file"
            name="files"
            id={fileId}
            accept={info.fileExt}
            style={{ display: 'none' }}
            onChange={this.handleUploadFile}
          />
        </form>
        <Menu
          anchorEl={anchorContextMenu}
          open={open}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {info.contextMenu.map((menu, idx) => (
            <MenuItem
              key={idx}
              onClick={() => {
                this.handleCtxMenuCmd(menu);
              }}
              disabled={selectObj[menu.actFieldName] ? false : true}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2" className={classes.menuItemText}>
                {menu.menuItem}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
        {this.state.confirmDialog}
      </Fragment>
    );
  }
}
UploadFileBtn.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesUploadFileBtn)(UploadFileBtn);
