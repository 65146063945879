export const examTypes = [
  '',
  'Semester 1 - Middle Exam',
  'Semester 1 - Final Exam',
  'Semester 2 - Middle Exam',
  'Semester 2 - Final Exam'
];

export const stylesExamResults = theme => ({
  root: {
    maxWidth: '100%'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  floatRightBar: {
    marginLeft: 'auto'
  },
  titleText: {
    margin: theme.spacing(0, 2),
    display: 'inline-block'
  },
  inlineText: {
    margin: theme.spacing(0, 2, 0, 0),
    display: 'inline-block'
  }
});
