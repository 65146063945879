import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import CustomizedTable from '../../ui/table/CustomizedTable';
import { Loading } from '../../ui/Loading';
import { ClassRoomPaths } from './ClassRoomConstants';

const stylesListClassRoom = () => ({});

class ListClassRoomComp extends Component {
  static displayName = ListClassRoomComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = { classRooms: [], loading: true };
  }

  componentDidMount() {
    this.populateClassRoomData();
  }

  populateClassRoomData = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const response = await fetch(
      `api/ClassRooms/GetClassRoom/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({ classRooms: data, loading: false });
  };

  refreshClassRooms = async () => {
    await this.populateClassRoomData();
    this.child.updateChildData(this.state.classRooms);
  };

  render() {
    const cols = [
      { name: 'name', header: 'Room name', align: 'left' },
      { name: 'roomNumber', header: 'Room No.', align: 'right' },
      {
        name: 'campus',
        header: 'Branch',
        align: 'left',
        obj: true,
        objName: 'name'
      },
      {
        name: 'roomType',
        header: 'Type',
        align: 'left',
        obj: true,
        objName: 'name'
      }
    ];
    const actions = [
      {
        name: 'edit',
        tooltip: 'Edit',
        url: `${ClassRoomPaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'delete',
        tooltip: 'Delete',
        url: `${ClassRoomPaths.Delete}`,
        icon: 'delete_icon'
      }
    ];

    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <CustomizedTable
            onRef={actualChild => (this.child = actualChild)}
            rows={this.state.classRooms}
            cols={cols}
            actions={actions}
            eventReloadData={this.refreshClassRooms}
            isPaging={true}
          />
        )}
      </Fragment>
    );
  }
}

ListClassRoomComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListClassRoom = withStyles(stylesListClassRoom)(ListClassRoomComp);
