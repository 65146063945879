import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Checkbox, TextField } from '@material-ui/core';

const stylesStudentPresence = theme => ({
  presencePage: {
    flexWrap: 'unset'
  },
  studentName: {
    lineHeight: '34px'
  },
  checkBox: {
    width: '20%',
    textAlign: 'right'
  },
  checkPresence: {
    marginTop: 62
  },
  checkLabel: {
    cursor: 'pointer'
  },
  noteCol: {
    width: '25%',
    minWidth: 150
  },
  noteField: {
    margin: theme.spacing(6.25, 0, 0, 2)
  },
  noteText: {
    width: '100%'
  }
});

class StudentPresenceComp extends Component {
  static displayName = StudentPresenceComp.name;

  constructor(...args) {
    super(...args);
    this.state = {
      ratingMap: [],
      loading: true
    };
  }

  populateStudentRating = (currentRatingStar, currentClassStudents) => {
    const ratingMap = [];
    if (currentRatingStar && currentRatingStar.length > 0) {
      currentRatingStar.forEach(student => {
        student.note = student.note ?? '';
        ratingMap[student.studentInfoId] = student;
      });
    } else {
      currentClassStudents.forEach(student => {
        ratingMap[student.subId] = { present: false, note: '' };
      });
    }
    return ratingMap;
  };

  changeStudentPresence = (ratingMap, studentId, attrName) => event => {
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;

    ratingMap[studentId][attrName] = attrValue;
    this.props.changeStudentPresence(ratingMap);
  };

  render() {
    const { currentRatingStar, currentClassStudents, classes } = this.props;
    let ratingMap = this.populateStudentRating(
      currentRatingStar,
      currentClassStudents
    );

    return (
      <Grid
        item
        xs
        container
        direction="column"
        className={classes.presencePage}
      >
        <Grid item xs={12}>
          {currentClassStudents.map(student => (
            <div className="row-student" key={student.subId}>
              <div className={classes.checkBox}>
                <div className={classes.checkPresence}>
                  <Checkbox
                    id={'check-presence-' + student.subId}
                    checked={ratingMap[student.subId].present}
                    onChange={this.changeStudentPresence(
                      ratingMap,
                      student.subId,
                      'present'
                    )}
                  />
                </div>
              </div>
              <label
                htmlFor={'check-presence-' + student.subId}
                className={classes.checkLabel}
              >
                <div className="left-side id-tab">
                  <div className="name-id">
                    <Typography variant="h1" className={classes.studentName}>
                      {`${student.englishName} - ${student.studentName}`}
                    </Typography>
                  </div>
                </div>
              </label>
              <div className={classes.noteCol}>
                <div className={classes.noteField}>
                  <TextField
                    variant="outlined"
                    value={ratingMap[student.subId].note}
                    onChange={this.changeStudentPresence(
                      ratingMap,
                      student.subId,
                      'note'
                    )}
                    className={classes.noteText}
                  />
                </div>
              </div>
            </div>
          ))}
        </Grid>
      </Grid>
    );
  }
}

StudentPresenceComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const StudentPresenceBox = withStyles(stylesStudentPresence)(
  StudentPresenceComp
);
