import { fade } from '@material-ui/core/styles/colorManipulator';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { grey } from '@material-ui/core/colors';

export const commonToolbarStyles = theme => ({
  root: {
    padding: theme.spacing(0, 1),
    minHeight: 54
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    display: 'flex',
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(grey[500], 0.15),
    '&:hover': {
      backgroundColor: fade(grey[500], 0.25)
    },
    marginLeft: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    height: 35,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  clearButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    backgroundColor: 'transparent'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200
      }
    }
  },
  noOutline: {
    outline: 'none',
    margin: theme.spacing(1)
  },
  filters: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  checkCondition: {
    width: 165
  },
  dropdown: {
    width: 140
  }
});
