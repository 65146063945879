import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import MyClassPresentTable from './MyClassPresentTable';

const stylesListMyClass = () => ({});

class ListMyClassComp extends Component {
  static displayName = ListMyClassComp.name;

  constructor(...args) {
    super(...args);
    this.state = { myClasses: [], loading: true };
  }

  componentDidMount() {
    this.populateMyClassData();
  }

  async populateMyClassData() {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const getUrl = this.props.previewMode
      ? 'GetMyClasses'
      : 'GetMyUpComingClass';
    const response = await fetch(
      `api/MyClasses/${getUrl}/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    let data = await response.json();
    if (!this.props.previewMode) {
      const nowTime = new Date();
      // 30 mins before starting
      const timeSpan = 30;
      const startTimeTest = new Date(nowTime.getTime() + timeSpan * 60 * 1000);
      for (var i = 0; i < data.length; i++) {
        data[i].willStartSoon = false;
        data[i].ended = false;
        if (new Date(data[i].startTime) < startTimeTest) {
          data[i].willStartSoon = true;
        }
        if (new Date(data[i].endTime) < nowTime) {
          data[i].ended = true;
        }
      }
    }
    this.setState({ myClasses: data, loading: false });
  }

  renderDatagrid() {
    var cols = [];
    if (this.props.previewMode) {
      cols = [
        { name: 'level', header: 'Level', align: 'left' },
        { name: 'class', header: 'Class', align: 'left' },
        { name: 'startDateLocal', header: 'Start Date', align: 'left' },
        { name: 'endDateLocal', header: 'End Date', align: 'left' }
      ];
    } else {
      cols = [
        { name: 'level', header: 'Level', align: 'left' },
        { name: 'class', header: 'Class', align: 'left' },
        { name: 'beginDateFormat', header: 'Date', align: 'left' },
        { name: 'startTimeLocal', header: 'Start time', align: 'left' },
        { name: 'endTimeLocal', header: 'End time', align: 'left' },
        { name: 'lesson', header: 'Lesson', align: 'left' },
        { name: 'subject', header: 'Subject', align: 'left' },
        { name: 'content', header: 'Content', align: 'left' },
        { name: 'tb', header: 'TB', align: 'left' }
      ];
    }

    return (
      <MyClassPresentTable
        rows={this.state.myClasses}
        cols={cols}
        previewMode={this.props.previewMode}
      />
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return <Fragment>{contents}</Fragment>;
  }
}

ListMyClassComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListMyClass = withStyles(stylesListMyClass)(ListMyClassComp);
