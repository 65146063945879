import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { PagingTable } from '../ui/table/PagingTable';
import MaterialFilterToolbar from '../ui/table/MaterialFilterToolbar';

const stylesMaterialSelectTable = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  }
});

class MaterialSelectTable extends Component {
  constructor(...args) {
    super(...args);
    this.child = React.createRef();

    this.state = {
      isDisableFilter: this.props.isDisableFilter
    };
  }

  async componentDidMount() {
    this.child.setData(this.props.rows);
  }

  setTableData = (filterText, cats, all) => {
    const { rows } = this.props;

    let filteredRows = rows.filter(
      e =>
        filterText === '' ||
        (e.name && e.name.includes(filterText)) ||
        (e.description && e.description.includes(filterText))
    );
    filteredRows = filteredRows.filter(
      e =>
        all ||
        (cats.math === e.math &&
          cats.science === e.science &&
          cats.music === e.music &&
          cats.phonics === e.phonics &&
          cats.sightwords === e.sightwords &&
          cats.english === e.english &&
          cats.softSkills === e.softSkills &&
          cats.activityBook === e.activityBook &&
          cats.studentBook === e.studentBook &&
          cats.teacherPlanner === e.teacherPlanner &&
          cats.m0 === e.m0 &&
          cats.m1 === e.m1 &&
          cats.m2 === e.m2 &&
          cats.m3 === e.m3 &&
          cats.a === e.a &&
          cats.m === e.m &&
          cats.e === e.e &&
          cats.v === e.v &&
          cats.c === e.c &&
          cats.j === e.j &&
          cats.s === e.s)
    );
    this.child.setData(filteredRows);
  };

  render() {
    const {
      classes,
      cols,
      callbackFromParent,
      callbackGetValueRadioBtn
    } = this.props;
    return (
      <Paper className={classes.root}>
        <MaterialFilterToolbar
          setTableData={this.setTableData}
          isDisableFilter={this.state.isDisableFilter}
        />
        <PagingTable
          onRef={actualChild => (this.child = actualChild)}
          cols={cols}
          isShowRadioButton="true"
          callbackFromParent={callbackFromParent}
          callbackGetValueRadioBtn={callbackGetValueRadioBtn}
        />
      </Paper>
    );
  }
}

MaterialSelectTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesMaterialSelectTable)(MaterialSelectTable);
