import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import authService from '../../../../api-authorization/AuthorizeService';
import { Loading } from '../../../../ui/Loading';
import { stylesCharting } from '../../../../class-course/reports/stylesCharting';
import { stylesAcademicInfo } from '../../../../academic-manage/stylesAcademicInfo';
import { RoomInfoView } from './RoomInfoView';

class RoomStatistics extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.populateCampusData();
  }

  populateCampusData = async () => {
    const { campusInfo } = this.props;
    const token = await authService.getAccessToken();
    const [respCampusRoomData] = await Promise.all([
      fetch(`api/CampusReport/GetRoomStatistic/${campusInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [campusRoomData] = await Promise.all([respCampusRoomData.json()]);

    this.setState({
      campusRoomData,
      loading: false
    });
  };

  render() {
    const { loading } = this.state;
    let content = loading ? <Loading /> : this.renderUI();

    return <Fragment>{content}</Fragment>;
  }

  renderUI() {
    const { classes } = this.props;
    const { campusRoomData } = this.state;

    return (
      <Fragment>
        <Grid container className={classes.stdHeadline}>
          <div className={classes.stdSubtitle}>
            <Typography variant="h3" className={classes.sectionTitle}>
              {'Thông tin thống kê và báo cáo phòng học tại chi nhánh'}
            </Typography>
          </div>
          <div className={classes.stdSubtitleHr}>
            <span className={clsx(classes.stdLineCell)}></span>
          </div>
        </Grid>

        <Grid container className={classes.stdBox} spacing={2}>
          {campusRoomData.map((room, idx) => (
            <Grid
              item
              xs={3}
              container
              direction="column"
              justify="flex-start"
              key={idx}
            >
              <div className={classes.chartBoxTopPad}>
                <RoomInfoView
                  title="Room"
                  subtitle="Phòng học"
                  roomInfo={room}
                  classes={classes}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </Fragment>
    );
  }
}

const stylesStudentStatistics = theme => ({
  ...stylesCharting(theme),
  ...stylesAcademicInfo(theme)
});

RoomStatistics.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesStudentStatistics)(RoomStatistics);
