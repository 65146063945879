import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const stylesAppEmptyLayout = theme => ({
  root: {
    display: 'flex',
    height: '100%',
    margin: theme.spacing(0)
  }
});

export class AppEmptyLayoutClass extends Component {
  displayName = AppEmptyLayoutClass.name;

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        {this.props.children}
      </div>
    );
  }
}

AppEmptyLayoutClass.propTypes = {
  classes: PropTypes.object.isRequired
};

export const AppEmptyLayout = withStyles(stylesAppEmptyLayout)(
  AppEmptyLayoutClass
);
