import React, { Component, Fragment } from 'react';
import { StudyLevelActions, StudyLevelPaths } from './StudyLevelConstants';

import AddIcon from '@material-ui/icons/Add';
import { AdminPage } from '../../ui/page/AdminPage';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { EditStudyLevel } from './EditStudyLevel';
import ExamStudyLevel from './ExamStudyLevel';
import Fab from '@material-ui/core/Fab';
import { ListStudyLevel } from './ListStudyLevel';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/core/styles';

const stylesStudyLevel = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class StudyLevelComp extends Component {
  static displayName = StudyLevelComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      levelId: this.props?.match?.params?.levelId,
      action: this.props?.action,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props?.action) {
      case StudyLevelActions.List:
        this.state.pageTitle = 'Levels';
        this.state.content = <ListStudyLevel />;
        break;
      case StudyLevelActions.Create:
        this.state.pageTitle = 'Create Level';
        this.state.content = (
          <EditStudyLevel
            onRef={actualChild => (this.child = actualChild)}
            action={this.state.action}
          />
        );
        break;
      case StudyLevelActions.Edit:
        this.state.pageTitle = 'Edit Level';
        this.state.content = (
          <EditStudyLevel
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            levelId={this.state.levelId}
          />
        );
        break;
      case StudyLevelActions.Exam:
        this.state.pageTitle = 'Template Report For Level';
        this.state.content = (
          <ExamStudyLevel
            ref={actualChild => (this.child = actualChild)}
            action={this.props.action}
            levelId={this.state.levelId}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === 'list' ? (
        <Fab
          aria-label="Add"
          color="primary"
          className={classes.fab}
          onClick={() => this.redirectTo(StudyLevelPaths.Create)}
        >
          <AddIcon />
        </Fab>
      ) : (
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.handleChildSubmit}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleChildBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      );

    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

StudyLevelComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const StudyLevels = withStyles(stylesStudyLevel)(StudyLevelComp);
