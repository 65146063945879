import {
  Fab,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Icon from '@mdi/react';
import { Keys } from '../../common/constants/GeeOConstants';
import { Loading } from '../../ui/Loading';
import { NormalTooltip } from '../../ui/ButtonStyles';
import authService from '../../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { mdiFileImportOutline } from '@mdi/js';
import { useStylesSearchBox } from './styleSearchBox';

const ClassReportSearch = props => {
  const classes = useStylesSearchBox();
  const [loading, setLoading] = useState(true);
  const [loadingClass, setLoadingClass] = useState(false);
  const [dataCampus, setDataCampus] = useState([]);
  const [dataLevel, setDataLevel] = useState([]);
  const [dataClass, setDataClass] = useState([]);
  const [dataAllClass, setDataAllClass] = useState([]);
  const [filters, setFilters] = useState({
    searchText: '',
    campus: '',
    level: '',
    class: ''
  });

  const populateDataFilter = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);

    const [respLevel, respCampus, respClass] = await Promise.all([
      fetch(`api/StudyLevels`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/Campus/GetCampusList/${loggedInUser.sub}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/ClassCourses/GetClassCourseDropDown/${loggedInUser.sub}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [dataLevel, dataCampus, dataClass] = await Promise.all([
      respLevel.json(),
      respCampus.json(),
      respClass.json()
    ]);

    setDataCampus(dataCampus);
    setDataLevel(dataLevel);
    setDataClass(dataClass);
    setDataAllClass(dataClass);
    setLoading(false);
  };

  useEffect(() => {
    populateDataFilter();
  }, []);

  const handleChange = name => event => {
    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;

    setFilters(preList => {
      return { ...preList, [attrName]: attrValue };
    });
    if (['campus', 'level'].includes(attrName)) {
      setLoadingClass(true);
    }
  };

  const handleKeyDown = e => {
    if (e.keyCode === Keys.ENTER_KEY) {
      doSearchAction();
    }
  };

  const updateClassList = async () => {
    if (filters.campus === '' && filters.level === '') {
      setDataClass(dataAllClass);
      setLoadingClass(false);
      return;
    }

    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    const [respClass] = await Promise.all([
      fetch(`api/ClassCourses/GetClassesWithFilter/${loggedInUser.sub}`, {
        method: 'POST',
        body: JSON.stringify(filters),
        headers: headers
      })
    ]);
    const [classData] = await Promise.all([respClass.json()]);

    setDataClass(classData);
    setLoadingClass(false);
  };

  useEffect(() => {
    if (loadingClass) {
      updateClassList();
    }
    // eslint-disable-next-line
  }, [loadingClass]);

  const doSearchAction = async () => {
    if (props.searchAction) {
      props.searchAction(filters);
    }
  };

  const handleUpload = name => event => {
    const files = event.target.files;
    props.uploadImportFile(name, files, event.target);
  };

  return loading ? (
    <Loading />
  ) : (
    <Paper className={classes.root}>
      <FormControl className={clsx(classes.formControl, classes.longTextField)}>
        <InputLabel htmlFor="search-text">Search Text</InputLabel>
        <Input
          id="search-text"
          name="searchText"
          value={filters['searchText']}
          onChange={handleChange('searchText')}
          onKeyDown={e => handleKeyDown(e)}
          inputProps={{
            'aria-label': 'Search Text'
          }}
        />
      </FormControl>
      <FormControl className={clsx(classes.formControl, classes.textField)}>
        <InputLabel htmlFor="campus-id">Campus</InputLabel>
        <Select
          id="campus-id"
          name="campus"
          value={filters['campus']}
          onChange={handleChange('campus')}
        >
          <MenuItem value="" key="">
            All
          </MenuItem>
          {dataCampus.map(row => {
            return (
              <MenuItem value={row.id} key={row.id}>
                {row.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl className={clsx(classes.formControl, classes.textField)}>
        <InputLabel htmlFor="level-id">Level</InputLabel>
        <Select
          id="level-id"
          name="level"
          value={filters['level']}
          onChange={handleChange('level')}
        >
          <MenuItem value="" key="">
            All
          </MenuItem>
          {dataLevel.map(row => {
            return (
              <MenuItem value={row.id} key={row.id}>
                {row.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl className={clsx(classes.formControl, classes.textField)}>
        <InputLabel htmlFor="class-id">Class</InputLabel>
        <Select
          id="class-id"
          name="class"
          value={filters['class']}
          onChange={handleChange('class')}
        >
          <MenuItem value="" key="">
            All
          </MenuItem>
          {loadingClass
            ? null
            : dataClass.map(row => {
                return (
                  <MenuItem value={row.id} key={row.id}>
                    {row.name}
                  </MenuItem>
                );
              })}
        </Select>
      </FormControl>
      <FormControl className={clsx(classes.formControl, classes.textField)}>
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="Search"
          className={classes.extendedFab}
          onClick={doSearchAction}
        >
          Search
        </Fab>
      </FormControl>
      <FormControl className={clsx(classes.formControl)}>
        <NormalTooltip title="Import Student Attendance">
          <Fab
            component="label"
            variant="extended"
            size="medium"
            color="secondary"
            className={classes.btnAction}
          >
            <input
              type="file"
              style={{ display: 'none' }}
              accept=".xlsx"
              onChange={handleUpload('Attendance')}
            />
            <Icon
              path={mdiFileImportOutline}
              size={1}
              className={classes.btnIcon}
            />
            Student Attendance
          </Fab>
        </NormalTooltip>
      </FormControl>
    </Paper>
  );
};

export default ClassReportSearch;
