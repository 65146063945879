import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../../api-authorization/AuthorizeService';
import { Paper } from '@material-ui/core';
import { Loading } from '../../../ui/Loading';
import { AdminPage } from '../../../ui/page/AdminPage';
import { stylesAcademicInfo } from '../../../academic-manage/stylesAcademicInfo';
import CampusSummary from './CampusSummary';
import AcadStatistics from './acad/AcadStatistics';
import RoomStatistics from './room/RoomStatistics';
import PaymentStatistics from './payment/PaymentStatistics';

class CampusReports extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      campusId: this.props.match.params.campusId,
      loading: true
    };
  }

  componentDidMount() {
    this.populateCampusData();
  }

  populateCampusData = async () => {
    const token = await authService.getAccessToken();
    const { campusId } = this.state;

    const [respCampusInfo] = await Promise.all([
      fetch(`api/CampusReport/GetCampusInfo/${campusId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [campusInfo] = await Promise.all([respCampusInfo.json()]);

    this.setState({
      campusInfo,
      loading: false
    });
  };

  renderDatagrid() {
    const { classes } = this.props;
    const { campusInfo } = this.state;

    return (
      <Fragment>
        <Paper className={classes.reportMainFrame} style={{ marginTop: 0 }}>
          <CampusSummary campusInfo={campusInfo} />
          <div className={classes.reportBlockSpacing} />
          <AcadStatistics campusInfo={campusInfo} />
          <div className={classes.reportBlockSpacing} />
          <RoomStatistics campusInfo={campusInfo} />
          <div className={classes.reportBlockSpacing} />
          <PaymentStatistics campusInfo={campusInfo} />
        </Paper>
      </Fragment>
    );
  }

  render() {
    const { campusInfo } = this.state;
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();
    let title = this.state.loading ? '...' : `Branch: ${campusInfo.name}, HCM`;

    return <AdminPage title={title} content={contents} />;
  }
}

const stylesClassReports = theme => ({
  ...stylesAcademicInfo(theme),
  reportMainFrame: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 0)
  },
  reportBlockSpacing: {
    marginBottom: theme.spacing(2)
  }
});

CampusReports.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesClassReports)(CampusReports);
