import React, { Component } from 'react';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from '@material-ui/core/Snackbar';

export const MessageBarVariants = {
  Success: 'success',
  Warning: 'warning',
  Error: 'error',
  Info: 'info'
};

export const MessageType = {
  Message: 'message',
  Approve: 'approve',
  Upload: 'upload',
  Import: 'import',
  Submit: 'submit',
  Delete: 'delete',
  Suspend: 'Suspend'
};

export const MessageBarText = {
  Success: {
    approve: 'Approve successfully!',
    upload: 'Upload successfully!',
    import:
      'Please contact your administrator if there is any issues of importing.',
    submit: 'Submit successfully!',
    delete: 'Delete successfully!',
    Suspend: 'Suspend successfully!'
  },
  Error: {
    approve: 'Approve failed!',
    upload: 'Upload failed!',
    import: 'Import failed!',
    submit: 'Submit failed!',
    delete: 'Delete failed!',
    Suspend: 'Suspend failed!'
  }
};

export const MessageTitle = {
  success: {
    message: 'Success',
    approve: 'Success',
    upload: 'Success',
    import: 'The process of importing is done',
    submit: 'Success',
    delete: 'Success'
  },
  error: {
    message: 'Error',
    approve: 'Error',
    upload: 'Error',
    import: 'The process of importing is failed',
    submit: 'Error',
    delete: 'Error'
  }
};

export class MessageBars extends Component {
  constructor(...args) {
    super(...args);
    this._isMounted = false;
    this.state = {
      open: this.props.open,
      variant: this.props.variant,
      type: this.props.type,
      message: this.props.message
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setOpen = openState => {
    this.setState({ open: openState });
  };

  showMessage = (msgVariant, msgType, msgText) => {
    if (this._isMounted) {
      this.setState({
        variant: msgVariant,
        type: msgType,
        message: msgText,
        open: true
      });
    }
  };

  render() {
    const { variant, type, message } = this.state;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.props.handleClose}
      >
        <Alert onClose={this.props.handleClose} severity={variant}>
          {typeof message === 'string' ? (
            <>
              <AlertTitle>{MessageTitle[variant][type]}</AlertTitle>
              {message}
            </>
          ) : (
            Array.isArray(message) && (
              <>
                <AlertTitle>{MessageTitle[variant][type]}</AlertTitle>
                {message?.map((m, i) => m && <div key={i}>&#9679; {m}</div>)}
              </>
            )
          )}
        </Alert>
        {/* <MySnackbarContentWrapper
          onClose={this.props.handleClose}
          variant={variant}
          message={message}
        /> */}
      </Snackbar>
    );
  }
}
