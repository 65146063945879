import React, { Component } from 'react';
import clsx from 'clsx';
import { Grid, Paper, Typography } from '@material-ui/core';
import { PieArcSeries, PieChart } from 'reaviz';

export class TeacherPieChartView extends Component {
  render() {
    const {
      title,
      subtitle,
      chartTitle,
      chartData,
      numberValue,
      classes
    } = this.props;

    return (
      <Paper className={clsx(classes.chartBox, classes.chartBoxEqualHeight)}>
        <Grid container>
          <div className={classes.chartHeadingBox}>
            <Typography variant="h3" className={clsx(classes.heading)}>
              {title}
            </Typography>
            <Typography variant="body2" className={clsx(classes.subtitle)}>
              {subtitle}
            </Typography>
          </div>
          <div className={classes.chartNumberBox}>
            <Typography
              variant="h1"
              className={clsx(
                classes.chartSmallNumber,
                classes.chartLabelGreen
              )}
            >
              {numberValue}
            </Typography>
            <hr
              className={clsx(
                classes.chartNumberLine,
                classes.chartSmallNumberLine,
                classes.chartLabelGreen
              )}
            />
          </div>
        </Grid>

        <Grid container style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Typography variant="h3" className={clsx(classes.subtitle)}>
              {chartTitle}
            </Typography>
          </Grid>
        </Grid>

        <div
          className={classes.chartWrap}
          style={{ height: '200px', marginTop: 16 }}
        >
          <div
            style={{
              position: 'relative',
              height: '200px',
              width: '200px',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto'
            }}
          >
            <div style={{ position: 'absolute', top: 0, left: 0 }}>
              <PieChart
                disabled={true}
                width={200}
                height={200}
                data={chartData}
                series={
                  <PieArcSeries
                    doughnut={true}
                    label={null}
                    colorScheme={['#6fa1f3', '#ced5e1']}
                  />
                }
              />
            </div>
            <h2 style={{ margin: '0 5px', padding: 0 }}>
              {chartData[0].data}
              {'%'}
            </h2>
          </div>
        </div>
      </Paper>
    );
  }
}
