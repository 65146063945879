import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const WorkLogSubstitute = props => {
  const { handleChange, options, workLog, teachers, disabled } = props;

  useEffect(() => {
    setTimeout(() => {

      console.log("worklog: ", workLog);
    }, 
  3000
  )
  }, [workLog])

  const onChange = e => {
    const value = e.target.value;
    handleChange({
      ...workLog,
      [e.target.name]: value,
      score: options.find(x => x.value === value)?.score
    });
  };

  const onChangeTeacher = (e, value) => {
    const _workLog = {
      ...workLog,
      teacherId: value?.id || '',
      score: options.find((x) => x.value === value?.id)?.score,
    };
    handleChange(_workLog);
  };

  return (
      <FormControlLabel
        labelPlacement="start"
        style={{ gap: 24, width: 500, justifyContent: 'space-between', paddingLeft: '2rem' }}
        control={
          <FormControl fullWidth>
            <InputLabel id="shift-select-label">Shift</InputLabel>
            <Select
              labelId="shift-select-label"
              name="effortType"
              value={workLog?.effortType}
              onChange={onChange}
              disabled={disabled}
            >
              {options?.map(e => (
                <MenuItem value={e.value} key={e.value}>
                  {e.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        label={
          <Autocomplete
            style={{ width: 230 }}
            options={teachers || []}
            getOptionLabel={option =>
              option.englishName
                ? `${option.englishName} - ${option.fullName}`
                : option.fullName
            }
            value={teachers.find(x => x.id === workLog.teacherId) || null}
            onChange={onChangeTeacher}
            renderInput={params => (
              <TextField {...params} label={workLog.isExpat ? 'Teacher' : 'Subs Teacher' } margin="normal" />
            )}
            disabled= {disabled}
          />
        }
      />
  );
};

WorkLogSubstitute.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.array,
  teacher: PropTypes.object,
  workLog: PropTypes.object,
  teachers: PropTypes.array,
  disabled: PropTypes.bool
};

export default WorkLogSubstitute;
