import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { Materials } from './Materials';
import { MaterialPaths, MaterialActions } from './MaterialConstants';

export default class MaterialRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={MaterialPaths.List}
          component={Materials}
          action={MaterialActions.List}
        />
        <PropsRoute
          path={MaterialPaths.ListByTeacher}
          component={Materials}
          action={MaterialActions.ListByTeacher}
        />
        <PropsRoute
          path={MaterialPaths.NeedApproval}
          component={Materials}
          action={MaterialActions.NeedApproval}
        />
        <PropsRoute
          path={MaterialPaths.EditRoute}
          component={Materials}
          action={MaterialActions.Edit}
        />
        <PropsRoute
          path={MaterialPaths.EditByTeacherRoute}
          component={Materials}
          action={MaterialActions.EditByTeacher}
        />
        <PropsRoute
          path={MaterialPaths.Create}
          component={Materials}
          action={MaterialActions.Create}
        />
        <PropsRoute
          path={MaterialPaths.CreateByTeacher}
          component={Materials}
          action={MaterialActions.CreateByTeacher}
        />
      </Fragment>
    );
  }
}
