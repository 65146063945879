export const stylesHolidays = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  sectionTitle: {
    fontSize: 17,
    fontWeight: 'bold',
    display: 'inline-block',
    marginTop: 'auto'
  },
  titleText: {
    fontWeight: 'bold',
    marginTop: 'auto'
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: 'auto'
  },
  formControl: {
    marginLeft: theme.spacing(2),
    minWidth: 120
  },
  headControl: {
    marginLeft: theme.spacing(3),
    width: 180
  },
  inputDay: {
    width: 230
  },
  gridColumn: {
    height: 400,
    padding: theme.spacing(1)
  },
  scrollList: {
    height: '100%',
    overflowX: 'auto',
    paddingTop: 0
  },
  listItem: {
    padding: theme.spacing(0.5, 1),
    minHeight: 35
  },
  buttonBar: {
    paddingTop: theme.spacing(1)
  },
  header: {
    height: 40,
    display: 'flex'
  },
  headerRow: {
    display: 'flex'
  }
});
