import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';

import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import { StudentCoursePaths } from '../../student-course/StudentCourseConstants';
import clsx from 'clsx';

export default function PaymentInfo(
  classes,
  student,
  stdPaymentInfo,
  actionAllowedAccess
) {
  const isEditAllowed = item => {
    if (actionAllowedAccess.Payment) {
      return true;
    }
    const currentDate = new Date();
    return (
      new Date(item.startDate) >=
      currentDate.setDate(currentDate.getDate() - 30)
    );
  };

  return (
    <Fragment>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.stdHeadline}
      >
        <Grid item xs className={classes.stdSubtitle}>
          <Typography
            variant="h3"
            className={clsx(classes.stdCell, classes.sectionTitle)}
          >
            Payment Information
          </Typography>
        </Grid>
        <Grid item xs>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item xs={12}>
              <span
                className={clsx(classes.stdLineCell, classes.stdMiddleLine)}
              ></span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="flex-start"
        spacing={1}
        className={classes.stdBox}
      >
        <Grid item container spacing={2} xs={4}>
          <Grid item xs={7}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Last payment:
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <span className={clsx(classes.stdCell)}>
              {stdPaymentInfo.studentPaymentList[0] &&
                stdPaymentInfo.studentPaymentList[0].startDateFormat}
            </span>
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Total number of sessions studied:
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <span className={classes.stdCell}>
              {stdPaymentInfo.totalStudiedSessions}
            </span>
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Total number of sessions remaining:
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <span className={classes.stdCell}>
              {stdPaymentInfo.totalRemainSessions}
            </span>
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Total number of absences:
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <span className={classes.stdCell}>
              {stdPaymentInfo.totalAbsences}
            </span>
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Total number of consecutive absences:
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <span className={classes.stdCell}>
              {stdPaymentInfo.totalFrequentAbsences}
            </span>
          </Grid>
        </Grid>
        <Grid item container spacing={2} xs={8}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Payment list
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Grid container>
                {stdPaymentInfo.studentPaymentList.map((item, idx) => {
                  return (
                    <Fragment key={idx}>
                      <Grid item xs={4}>
                        <Box className={classes.box}>
                          {item.name}:{' '}
                          {item.amount
                            ? Number(item.amount).toLocaleString('vi-VN') + ' đ'
                            : '_'}
                        </Box>
                      </Grid>
                      <Grid item xs={1}>
                        <Box className={classes.box}>{item.numberSessions}</Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box className={classes.box}>
                          {item.startDateFormat}
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box className={classes.box}>
                          {item.transferTypeStr}
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        {isEditAllowed(item) && (
                          <IconButton
                            size="small"
                            color="default"
                            className={classes.button}
                            component={Link}
                            to={`${StudentCoursePaths.Edit}/${item.id}/${student.id}`}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        )}
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={5}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Ngày kết thúc học phí dự kiến:
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <span className={clsx(classes.stdCell)}>
              {stdPaymentInfo.endDateOfSession}
            </span>
          </Grid>
          <Grid item xs={5}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Ngày điểm danh cuối:
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <span className={clsx(classes.stdCell)}>
              {stdPaymentInfo.lastAttendanceDate}
            </span>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              {'Payment remaining: '}
              {stdPaymentInfo.paymentRemaining
                ? Number(stdPaymentInfo.paymentRemaining).toLocaleString(
                    'vi-VN'
                  ) + ' đ'
                : '_'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
