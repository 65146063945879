import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { MyClass } from './MyClass';
import { MyClassPaths, MyClassActions } from './MyClassConstants';

export default class MyClassRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={MyClassPaths.List}
          component={MyClass}
          action={MyClassActions.List}
          previewMode={false}
        />
        <PropsRoute
          path={MyClassPaths.Preview}
          component={MyClass}
          action={MyClassActions.Preview}
          previewMode={true}
        />
        <PropsRoute
          path={MyClassPaths.RunRoute}
          component={MyClass}
          action={MyClassActions.Run}
        />
        <PropsRoute
          path={MyClassPaths.Create}
          component={MyClass}
          action={MyClassActions.Create}
        />
        <PropsRoute
          path={MyClassPaths.StudentViewRoute}
          component={MyClass}
          action={MyClassActions.StudentView}
        />
        <PropsRoute
          path={MyClassPaths.ViewReportRoute}
          component={MyClass}
          action={MyClassActions.ViewReport}
        />
        <PropsRoute
          path={MyClassPaths.ViewReportDetailRoute}
          component={MyClass}
          action={MyClassActions.ViewReportDetail}
        />
      </Fragment>
    );
  }
}
