export const stylesCharting = theme => ({
  chartBoxRoot: {
    width: '100%'
  },
  chartBox: {
    padding: theme.spacing(2),
    margin: theme.spacing(0.5),
    borderRadius: 10
  },
  chartBoxEqualHeight: {
    height: '100%'
  },
  chartBoxLeftPad: {
    paddingLeft: theme.spacing(2)
  },
  chartBoxTopPad: {
    paddingTop: theme.spacing(2),
    maxWidth: '100%'
  },
  heading: {
    fontSize: 16,
    margin: theme.spacing(0, 0, 1.5, 0)
  },
  subtitle: {
    fontSize: 12
  },
  subtitleMargin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  chartWrap: {
    width: '100%',
    height: 250,
    overflow: 'auto',
    margin: 0,
    marginTop: theme.spacing(2)
  },
  chartWrapPadding: {
    padding: theme.spacing(2, 0, 0, 1)
  },
  chartLabel: {
    textAlign: 'center',
    fontSize: 12
  },
  chartLabelHead: {
    textDecoration: 'underline'
  },
  chartLabelPadding: {
    padding: '5px 0'
  },
  chartLabelBlue: {
    color: '#42a5f6'
  },
  chartLabelLightBlue: {
    color: '#6fa1f3'
  },
  chartLabelOrange: {
    color: '#ff7043'
  },
  chartLabelRed: {
    color: '#e61d14'
  },
  chartLabelGreen: {
    color: '#85c220'
  },
  infoNumber: {
    fontFamily: '"Segoe UI"',
    fontSize: 22,
    fontWeight: 600,
    marginTop: '-8px !important'
  },
  chartSmallerNumber: {
    fontFamily: '"Segoe UI"',
    fontSize: 30,
    fontWeight: 800,
    marginTop: '-8px !important'
  },
  chartSmallLongNumber: {
    fontFamily: '"Segoe UI"',
    fontSize: 24,
    fontWeight: 800,
    marginTop: '4px !important'
  },
  chartSmallText: {
    fontFamily: '"Segoe UI"',
    fontSize: 24,
    fontWeight: 800,
    marginTop: '-1px !important'
  },
  chartSmallNumber: {
    fontFamily: '"Segoe UI"',
    fontSize: 46,
    fontWeight: 800,
    marginTop: '-9px !important'
  },
  chartLongNumber: {
    fontFamily: '"Segoe UI"',
    fontSize: 42,
    fontWeight: 800,
    marginTop: '-9px !important'
  },
  chartMediumNumber: {
    fontFamily: '"Segoe UI"',
    fontSize: 48,
    fontWeight: 800,
    marginTop: '-4px !important'
  },
  chartLargeNumber: {
    fontFamily: '"Segoe UI"',
    fontSize: 86,
    fontWeight: 800,
    marginTop: '-11px !important'
  },
  chartNumberLine: {
    height: 1,
    borderBottom: '2px solid'
  },
  chartSmallNumberLine: {
    marginTop: -7
  },
  chartHeadingBox: {
    flexGrow: 1,
    maxWidth: '100%',
    flexBasis: 0
  },
  chartNumberBox: {
    flexGrow: 0,
    textAlign: 'right',
    position: 'relative'
  },
  chartLargeNumberLine: {
    borderBottom: '4px solid',
    margin: '0 auto',
    marginTop: -10
  },
  chartLargeNumberBox: {
    textAlign: 'center',
    position: 'relative'
  },
  chartSelect: {
    fontFamily: '"RalewayBold"',
    fontSize: 14
  },
  chartMenuItem: {
    fontSize: 14
  }
});
