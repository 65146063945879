export const MyClassActions = {
  List: 'list',
  Preview: 'preview',
  LetsLearn: 'lets-learn',
  Run: 'run',
  Create: 'create',
  Delete: 'delete',
  StudentView: 'student-view',
  ViewReport: 'report',
  ViewReportDetail: 'reportDetail'
};

const prefix = '/my-class';

export const MyClassPaths = {
  MyClassPrefix: prefix,
  List: `${prefix}/${MyClassActions.List}`,
  Preview: `${prefix}/${MyClassActions.Preview}`,
  RunRoute: `${prefix}/${MyClassActions.Run}/:classLessonId`,
  Run: `${prefix}/${MyClassActions.Run}`,
  LetsLearnRoute: `/${MyClassActions.LetsLearn}/:classLessonId/:classId/:previewMode/:previewLesson`,
  LetsLearn: `/${MyClassActions.LetsLearn}`,
  Create: `${prefix}/${MyClassActions.Create}`,
  Delete: `${prefix}/${MyClassActions.Delete}`,
  StudentViewRoute: `${prefix}/${MyClassActions.StudentView}/:classId`,
  StudentView: `${prefix}/${MyClassActions.StudentView}`,
  ViewReport: `${prefix}/${MyClassActions.ViewReport}`,
  ViewReportRoute: `${prefix}/${MyClassActions.ViewReport}/:classId`,
  ViewReportDetail: `${prefix}/${MyClassActions.ViewReportDetail}`,
  ViewReportDetailRoute: `${prefix}/${MyClassActions.ViewReportDetail}/:logId/:classLessonId`
};
