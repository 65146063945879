export const StudentCourseActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete'
};

const prefix = '/student-course';

export const StudentCoursePaths = {
  StudentCoursePrefix: prefix,
  List: `${prefix}/${StudentCourseActions.List}`,
  EditRoute: `${prefix}/${StudentCourseActions.Edit}/:studentCourseId/:studentId`,
  Edit: `${prefix}/${StudentCourseActions.Edit}`,
  Create: `${prefix}/${StudentCourseActions.Create}`,
  CreateRoute: `${prefix}/${StudentCourseActions.Create}/:studentId/:classId`,
  Delete: `StudentCourses`
};
