import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableFooter,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { HtmlUtils } from '../../../common/utils/HtmlUtils';
import { stylesNormalTable } from './StylesNormalTable';
import { NormalTableCell } from './NormalTableCell';
import TablePaginationActions from '../TablePaginationActions';

class NormalTable extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      rowsPerPage: this.props.rowsPerPage ? this.props.rowsPerPage : 10,
      page: 0
    };
  }

  handleChangePage = (e, page) => {
    this.setState({ page: page });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: Number(event.target.value) });
  };

  render() {
    const { classes, cols, data, isPaging } = this.props;
    const { rowsPerPage, page } = this.state;
    const rows =
      isPaging && data.length > rowsPerPage
        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : data;
    const emptyRows =
      isPaging && data.length > rowsPerPage
        ? rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)
        : 0;

    return (
      <TableContainer className={classes.container}>
        <Table className={clsx(classes.table)}>
          <TableHead>
            <TableRow className={classes.header}>
              {cols.map((col, idx) => (
                <NormalTableCell
                  component="th"
                  scope="row"
                  style={{ width: col.width }}
                  align={col.align}
                  key={idx}
                >
                  {col.header}
                </NormalTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow className={classes.row} key={idx}>
                {cols.map((col, idx) => (
                  <NormalTableCell
                    align={col.align}
                    key={idx}
                    style={{ width: col.width }}
                  >
                    {col.isBool ? (
                      row[col.name] === true && <CheckIcon />
                    ) : (
                      <Typography
                        variant="body2"
                        noWrap={col.noWrap ? true : undefined}
                      >
                        <span
                          dangerouslySetInnerHTML={HtmlUtils.createMarkup(
                            row[col.name]
                          )}
                        />
                      </Typography>
                    )}
                  </NormalTableCell>
                ))}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 41 * emptyRows }}>
                <NormalTableCell colSpan={cols.length} />
              </TableRow>
            )}
          </TableBody>
          {isPaging && data.length > rowsPerPage && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 40]}
                  colSpan={cols.length}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="Rows/Page"
                  classes={{
                    root: classes.rootPagination,
                    caption: classes.caption
                  }}
                  SelectProps={{
                    native: true
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    );
  }
}

NormalTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesNormalTable)(NormalTable);
