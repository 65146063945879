import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
//import CustomizedTable from '../ui/table/CustomizedTable';
import MaterialDataTable from './MaterialDataTable';
import { Loading } from '../ui/Loading';
import { MaterialActions } from './MaterialConstants';

const stylesMyApprovedList = () => ({});

class MyApprovedList extends Component {
  static displayName = MyApprovedList.name;

  constructor(...args) {
    super(...args);
    this.messageBar = React.createRef();
    this.state = {
      materials: null,
      loading: true
    };
  }

  componentDidMount() {
    this.populateMaterialData();
  }

  populateMaterialData = async () => {
    const loggedInUser = await authService.getUser();
    const token = await authService.getAccessToken();
    const byActionUrl =
      this.props.action === MaterialActions.NeedApproval
        ? 'GetForApproval'
        : this.props.action === MaterialActions.ListByTeacher
        ? `GetApprovedFor/${loggedInUser.sub}`
        : 'GetApproved';
    const apiUrl = `api/Materials/${byActionUrl}`;
    const response = await fetch(apiUrl, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ materials: data, loading: false });
  };

  renderDatagrid() {
    const cols = [
      { name: 'name', header: 'Material name', align: 'left', width: '200' },
      {
        name: 'description',
        header: 'Description',
        align: 'left',
        width: '200'
      },
      { name: 'materialFormatText', header: 'Material format', align: 'left' },
      {
        name: 'materialCategories',
        header: 'Material categories',
        align: 'left'
      },
      {
        name: 'url',
        header: 'Link',
        align: 'left',
        noWrap: true,
        width: '180'
      },
      {
        name: 'fileName',
        header: 'File Name',
        align: 'left',
        noWrap: true,
        width: '200'
      }
    ];

    return (
      <Fragment>
        {this.state.materials && (
          <MaterialDataTable
            onRef={actualChild => (this.child = actualChild)}
            rows={this.state.materials}
            cols={cols}
            isPaging={true}
          />
        )}
      </Fragment>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return <Fragment>{contents}</Fragment>;
  }
}

MyApprovedList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesMyApprovedList)(MyApprovedList);
