import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import TeacherReports from './reports/TeacherReports';
import { Teachers } from './Teacher';
import { TeacherPaths, TeacherActions } from './TeacherConstans';

export default class TeacherRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={TeacherPaths.List}
          component={Teachers}
          action={TeacherActions.List}
        />
        <PropsRoute
          path={TeacherPaths.ViewReportRoute}
          component={Teachers}
          action={TeacherActions.ViewReport}
        />
        <PropsRoute
          path={TeacherPaths.EditRoute}
          component={Teachers}
          action={TeacherActions.Edit}
        />
        <PropsRoute
          path={TeacherPaths.Create}
          component={Teachers}
          action={TeacherActions.Create}
        />
        <PropsRoute
          path={TeacherPaths.ReportsRoute}
          component={TeacherReports}
        />
      </Fragment>
    );
  }
}
