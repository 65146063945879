import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import CustomizedTable from '../ui/table/CustomizedTable';
import { Redirect } from 'react-router-dom';
import { Loading } from '../ui/Loading';
import { CatchUpSchedulePaths } from './CatchUpScheduleContans';

const stylesList = theme => ({
  fab: {
    margin: '12px 8px 8px 8px'
  }
});

class ListStudentCatchUpScheduleComp extends Component {
  static displayName = ListStudentCatchUpScheduleComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      Data: [],
      loading: true,
      redirect: null
    };
  }
  componentDidMount() {
    this.populateDataInit();
  }
  populateDataInit = async () => {
    const token = authService.getAccessToken();
    const currentUser = await authService.getUser();
    const response = await fetch(
      `api/CatchUpSchedules/GetStudentCatchUpByTeacherId/${currentUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({ Data: data, loading: false });
  };

  eventReloadData = () => {
    this.populateDataInit();
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  renderDatagrid() {
    const cols = [
      { name: 'studentName', header: 'Name', align: 'left' },
      { name: 'englishName', header: 'English Name', align: 'left' },
      { name: 'class', header: 'Class', align: 'left' },
      { name: 'fatherName', header: 'Father Name', align: 'left' },
      { name: 'fatherPhone', header: 'Father Phone', align: 'left' },
      { name: 'motherName', header: 'Mother Name', align: 'left' },
      { name: 'motherPhone', header: 'motherPhone', align: 'left' }
    ];

    const actions = [
      {
        name: 'edit',
        tooltip: 'Catch Up',
        url: `${CatchUpSchedulePaths.CreateCatchUpStudent}`,
        icon: 'restore'
      }
    ];

    return (
      <Fragment>
        <CustomizedTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.Data}
          cols={cols}
          actions={actions}
          eventReloadData={this.eventReloadData}
          isPaging={true}
        />

        {this.state.redirect}
      </Fragment>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();
    return <Fragment>{contents}</Fragment>;
  }
}

ListStudentCatchUpScheduleComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListStudentCatchUpSchedule = withStyles(stylesList)(
  ListStudentCatchUpScheduleComp
);
