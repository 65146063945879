import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Fab } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { AdminPage } from '../ui/page/AdminPage';
import { NormalTooltip } from '../ui/ButtonStyles';
import { DialogType, Dialogs } from '../ui/popup/Dialogs';
import {
  MessageBars,
  MessageBarVariants,
  MessageType
} from '../ui/popup/Messages';
import { MaterialPaths } from './MaterialConstants';
import MaterialDataTable from './MaterialDataTable';
import PreviewMaterial from './PreviewMaterial';

const stylesNeedApproval = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});
class NeedApproval extends Component {
  static displayName = NeedApproval.name;

  constructor(...args) {
    super(...args);
    this.confirmDialog = React.createRef();
    this.messageBar = React.createRef();
    this.previewMaterialDialog = React.createRef();
    this.state = {
      materials: null,
      loading: true,
      closeConfirmDialog: (
        <Button onClick={this.closeConfirmDialog} color="primary">
          Cancel
        </Button>
      ),
      confirmDialog: (
        <Dialogs onRef={actualChild => (this.confirmDialog = actualChild)} />
      ),
      previewMaterialDialog: (
        <PreviewMaterial
          onRef={actualChild => (this.previewMaterialDialog = actualChild)}
        />
      ),
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Error}
          type={MessageType.Approve}
          message={'Cannot preview!'}
          handleClose={this.handleCloseMessageBar}
        />
      )
    };
  }

  componentDidMount() {
    this.populateMaterialData();
  }

  closeConfirmDialog = () => {
    this.confirmDialog.closeDialog();
  };

  showConfirmDialog = isApprove => {
    const commands = (
      <Fragment>
        {this.state.closeConfirmDialog}
        <Button
          onClick={isApprove ? this.approveAll : this.deleteApproves}
          color="primary"
        >
          {isApprove ? 'Approve All' : 'Delete All'}
        </Button>
      </Fragment>
    );
    this.confirmDialog.showDialogWithCmds(
      isApprove ? DialogType.ApproveAllMaterial : DialogType.DeleteApproves,
      commands
    );
  };

  approveAll = async () => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    var ids = [];
    var data = this.state.needApprovalList;
    data.forEach(e => {
      ids.push(e.id);
    });
    fetch(`api/Materials/ApproveAll`, {
      method: 'POST',
      body: JSON.stringify(ids),
      headers: headers
    })
      .then(response => {
        this.populateMaterialData();
      })
      .catch(error => {
        console.error('Error:', error);
      });
    this.closeConfirmDialog();
  };

  deleteApproves = async () => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    var ids = [];
    var data = this.state.needApprovalList;
    data.forEach(e => {
      ids.push(e.id);
    });
    fetch(`api/Materials/DeleteApproves`, {
      method: 'POST',
      body: JSON.stringify(ids),
      headers: headers
    })
      .then(response => {
        this.populateMaterialData();
      })
      .catch(error => {
        console.error('Error:', error);
      });
    this.closeConfirmDialog();
  };

  populateMaterialData = async () => {
    this.setState({ loading: true });
    const token = await authService.getAccessToken();
    const [respNeedApproval, respApproved] = await Promise.all([
      fetch('api/Materials/GetForApproval', {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch('api/Materials/GetApproved', {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [dataNeedApproval, dataApproved] = await Promise.all([
      respNeedApproval.json(),
      respApproved.json()
    ]);
    this.setState({
      needApprovalList: dataNeedApproval,
      approvedList: dataApproved,
      loading: false
    });
  };

  previewMaterial = async rowData => {
    if (!rowData.url && !rowData.fileName) {
      this.messageBar.showMessage(
        MessageBarVariants.Error,
        MessageType.Approve,
        'Cannot view material without file or link!'
      );
      return;
    }
    this.previewMaterialDialog.openMaterialPreview(rowData);
  };

  handleCloseMessageBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  render() {
    const { classes } = this.props;
    const { needApprovalList, approvedList } = this.state;
    const cols = [
      {
        name: 'teacher',
        header: 'Owner',
        obj: true,
        objName: 'fullName',
        align: 'left',
        width: '180'
      },
      { name: 'name', header: 'Material name', align: 'left', width: '200' },
      {
        name: 'description',
        header: 'Description',
        align: 'left',
        width: '180'
      },
      {
        name: 'materialFormatText',
        header: 'Material format',
        align: 'left',
        width: '180'
      },
      {
        name: 'materialCategories',
        header: 'Material categories',
        align: 'left',
        width: '180'
      },
      {
        name: 'url',
        header: 'Link',
        align: 'left',
        noWrap: true,
        width: '200'
      },
      {
        name: 'fileName',
        header: 'File Name',
        align: 'left',
        noWrap: true,
        width: '200'
      }
    ];
    const actions = [
      {
        name: 'callback',
        callBackFn: this.previewMaterial,
        tooltip: 'Preview',
        icon: 'pageview'
      },
      {
        name: 'apiAction',
        tooltip: 'Approve',
        url: MaterialPaths.Approve,
        method: 'POST',
        dlgType: DialogType.ApproveMaterial,
        msgType: MessageType.Approve,
        actionBtnText: 'Approve',
        icon: 'check'
      },
      {
        name: 'delete',
        tooltip: 'Delete',
        url: `${MaterialPaths.Delete}`,
        icon: 'delete_icon'
      }
    ];
    const approveActions =
      needApprovalList && needApprovalList.length > 0 ? (
        <Fragment>
          <NormalTooltip title="Approve All">
            <Fab
              aria-label="Approve All"
              color="primary"
              className={classes.fab}
              onClick={() => this.showConfirmDialog(true)}
            >
              <DoneAllIcon />
            </Fab>
          </NormalTooltip>
          <NormalTooltip title="Delete All">
            <Fab
              aria-label="Delete All"
              color="primary"
              className={classes.fab}
              onClick={() => this.showConfirmDialog(false)}
            >
              <span className="material-icons">delete_sweep</span>
            </Fab>
          </NormalTooltip>
        </Fragment>
      ) : null;

    return (
      <Fragment>
        {this.state.confirmDialog}
        {this.state.previewMaterialDialog}
        {this.state.messageBar}
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <AdminPage
              title="Need Approval"
              content={
                <MaterialDataTable
                  onRef={actualChild => (this.child = actualChild)}
                  rows={needApprovalList}
                  cols={cols}
                  actions={actions}
                  eventReloadData={this.populateMaterialData}
                />
              }
              actions={approveActions}
            />
            <AdminPage
              title="Approved List"
              content={
                <MaterialDataTable
                  onRef={actualChild => (this.child = actualChild)}
                  rows={approvedList}
                  cols={cols}
                  isPaging={true}
                />
              }
              style={{ marginTop: 25 }}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

NeedApproval.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesNeedApproval)(NeedApproval);
