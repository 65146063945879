import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { MaterialPaths, MaterialActions } from './MaterialConstants';
import MaterialDataTable from './MaterialDataTable';
import {
  MessageBars,
  MessageBarVariants,
  MessageType,
  MessageBarText
} from '../ui/popup/Messages';
import PreviewMaterial from './PreviewMaterial';

const stylesListMaterial = () => ({});

class ListMaterialComp extends Component {
  static displayName = ListMaterialComp.name;

  constructor(...args) {
    super(...args);
    this.messageBar = React.createRef();
    this.child = React.createRef();
    this.state = {
      materials: [],
      loading: true,
      previewMaterialDialog: (
        <PreviewMaterial
          onRef={actualChild => (this.previewMaterialDialog = actualChild)}
        />
      ),
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Success}
          type={MessageType.Upload}
          message={MessageBarText.Success.upload}
          handleClose={this.handleClose}
        />
      )
    };
  }

  componentDidMount() {
    this.populateMaterialData();
  }

  populateMaterialData = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const byTeacherUrl =
      this.props.action === MaterialActions.ListByTeacher
        ? `/GetByTeacher/${loggedInUser.sub}`
        : '';
    const apiUrl = `api/Materials${byTeacherUrl}`;
    const response = await fetch(apiUrl, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ materials: data, loading: false });
  };

  refreshMaterials = async () => {
    await this.populateMaterialData();
    this.child.updateChildData(this.state.materials);
  };

  refreshList = (msgVariant, msgType, msgText) => {
    this.messageBar.showMessage(msgVariant, msgType, msgText);
    if (MessageBarVariants.Success === msgVariant) {
      this.refreshMaterials();
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  previewMaterial = async rowData => {
    if (!rowData.url && !rowData.fileName) {
      this.messageBar.showMessage(
        MessageBarVariants.Error,
        MessageType.Approve,
        'Cannot view material without file or link!'
      );
      return;
    }
    this.previewMaterialDialog.openMaterialPreview(rowData);
  };

  renderDatagrid() {
    const cols = [
      { name: 'name', header: 'Material name', align: 'left' },
      { name: 'description', header: 'Description', align: 'left' },
      { name: 'materialFormatText', header: 'Material format', align: 'left' },
      {
        name: 'materialCategories',
        header: 'Material categories',
        align: 'left'
      },
      { name: 'url', header: 'Link', align: 'left', noWrap: true },
      { name: 'fileName', header: 'File Name', align: 'left', noWrap: true }
    ];
    const actions = [
      // {
      //   name: 'attach',
      //   tooltip: 'Attach file',
      //   url: 'api/Materials/UploadFile',
      //   callBackFn: this.refreshList,
      //   icon: 'attach_file'
      // },
      {
        name: 'callback',
        callBackFn: this.previewMaterial,
        tooltip: 'Preview',
        icon: 'pageview'
      },
      {
        name: 'edit',
        tooltip: 'Edit material',
        url:
          this.props.action === MaterialActions.ListByTeacher
            ? `${MaterialPaths.EditByTeacher}`
            : `${MaterialPaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'delete',
        tooltip: 'Delete material',
        url: `${MaterialPaths.Delete}`,
        icon: 'delete_icon'
      }
    ];

    return (
      <MaterialDataTable
        onRef={actualChild => (this.child = actualChild)}
        rows={this.state.materials}
        cols={cols}
        actions={actions}
        eventReloadData={this.refreshMaterials}
        isPaging={true}
      />
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return (
      <Fragment>
        {contents}
        {this.state.previewMaterialDialog}
        {this.state.messageBar}
      </Fragment>
    );
  }
}

ListMaterialComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListMaterial = withStyles(stylesListMaterial)(ListMaterialComp);
