import authService from '../components/api-authorization/AuthorizeService';

export const fetchHelper = {
  get,
  post,
  put,
  delete: _delete,
  downloadFile
};

async function get(url) {
  const requestOptions = {
    method: 'GET',
    headers: await getHeaders()
  };
  return fetch(url, requestOptions).then(handleResponse);
}

async function post(url, body, isHandleResponse = true) {
  const requestOptions = {
    method: 'POST',
    headers: await getHeaders(),
    body: JSON.stringify(body)
  };

  return isHandleResponse
    ? fetch(url, requestOptions).then(handleResponse)
    : fetch(url, requestOptions);
}

async function put(url, body) {
  const requestOptions = {
    method: 'PUT',
    headers: await getHeaders(),
    body: JSON.stringify(body)
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url) {
  const requestOptions = {
    method: 'DELETE',
    headers: await getHeaders()
  };
  return fetch(url, requestOptions).then(handleResponse);
}

async function downloadFile(url, body) {
  const requestOptions = {
    method: 'POST',
    headers: await getHeaders(),
    body: JSON.stringify(body)
  };
  return fetch(url, requestOptions);
}

async function handleResponse(response) {
  if (response.ok) {
    return await response.json();
  } else {
    return Promise.reject(response);
  }
}

async function getHeaders() {
  const _token = await authService.getAccessToken();
  const _headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${_token}`
  };

  return _headers;
}
