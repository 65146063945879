import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';

const stylesMyClassLessonLog = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  dialogContent: {
    padding: theme.spacing(2, 2, 1, 2)
  }
});

class MyClassLessonLog extends Component {
  static displayName = MyClassLessonLog.name;

  constructor(...args) {
    super(...args);
    this.state = {
      open: false,
      classLessonId: null,
      lessonLog: null,
      savingLessonLogData: false,
      loading: true,
      data: [],
      title: 'My Lesson Report'
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  populateMyLessonLogData = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const response = await fetch(
      `api/TeacherLessonLog/GetMyLessonLog/${loggedInUser.sub}/${this.state.classLessonId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const lessonLogData = await response.json();
    this.setState({ data: lessonLogData, loading: false });
  };

  saveLessonLogData = async lessonLogDataNew => {
    if (this.state.savingLessonLogData) {
      return;
    }
    this.setState({ savingLessonLogData: true });
    const token = await authService.getAccessToken();
    //save lesson log
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const formBody = JSON.stringify(lessonLogDataNew);
    fetch(`api/TeacherLessonLog/SaveLogDataNote/${lessonLogDataNew.id}`, {
      method: 'PUT',
      body: formBody,
      headers: headers
    });
    this.setState({ savingLessonLogData: false });
  };

  openLessonReport(classLessonId, lessonLog) {
    this.setState({ classLessonId, lessonLog }, this.populateMyLessonLogData);
    this.openDialog();
  }

  setOpen = openState => {
    this.setState({ open: openState });
  };

  openDialog = () => {
    this.setState({ open: true });
  };

  closeDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        open={this.state.open}
        onClose={this.closeDialog}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent className={classes.dialogContent}>
          {this.state.loading ? (
            <Loading />
          ) : (
            <MaterialTable
              title={this.state.title}
              columns={[
                { title: '#', field: 'sortOrder', editable: 'never' },
                { title: 'Procedures', field: 'procedures', editable: 'never' },
                { title: 'Materials', field: 'materials', editable: 'never' },
                { title: 'Time', field: 'time', editable: 'never' },
                { title: 'Note', field: 'note' }
              ]}
              data={this.state.data}
              options={{
                filtering: false,
                pageSize: 10,
                actionsColumnIndex: -1,
                actionsCellStyle: {
                  color: 'rgba(0, 0, 0, 0.38)',
                  fontSize: 16
                }
              }}
              editable={{
                // onRowAdd: newData =>
                //   new Promise(resolve => {
                //     setTimeout(() => {
                //       resolve();
                //       const data = [...this.state.data];
                //       data.push(newData);
                //       this.setState({ ...this.state, data });
                //     }, 600);
                //   }),
                onRowUpdate: (newData, oldData) =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      const data = [...this.state.data];
                      data[data.indexOf(oldData)] = newData;
                      this.setState({ ...this.state, data });
                      this.saveLessonLogData(newData);
                    }, 600);
                  })
                // onRowDelete: oldData =>
                //   new Promise(resolve => {
                //     setTimeout(() => {
                //       resolve();
                //       const data = [...this.state.data];
                //       data.splice(data.indexOf(oldData), 1);
                //       this.setState({ ...this.state, data });
                //     }, 600);
                //   }),
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

MyClassLessonLog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesMyClassLessonLog)(MyClassLessonLog);
