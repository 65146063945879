export class DateTimeUtils {
  static toDateTimeJSON(x) {
    if (x instanceof Date) {
      let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
      // let minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
      x.setHours(hoursDiff);
      // x.setMinutes(minutesDiff)
    }
    return x;
  }
}
