import { ReportPaths } from '../ReportConstants';

export const ClassReportActions = {
  List: 'list',
  MyList: 'mylist',
  View: 'view',
  Edit: 'edit',
  ViewMyReport: 'myreport',
  Create: 'create',
  Delete: 'delete',
  ViewLessonReport: 'view-lesson-report'
};

const prefix = ReportPaths.Class;

export const ClassReportPaths = {
  ClassReportPrefix: prefix,
  List: `${prefix}/${ClassReportActions.List}`,
  MyList: `${prefix}/${ClassReportActions.MyList}`,
  View: `${prefix}/${ClassReportActions.View}`,
  ViewRoute: `${prefix}/${ClassReportActions.View}/:logId/:classLessonId`,
  Edit: `${prefix}/${ClassReportActions.Edit}`,
  EditRoute: `${prefix}/${ClassReportActions.Edit}/:classId/:classLessonId`,
  ViewMyReportRoute: `${prefix}/${ClassReportActions.ViewMyReport}/:logId/:classLessonId`,
  ViewMyReport: `${prefix}/${ClassReportActions.ViewMyReport}`,
  Create: `${prefix}/${ClassReportActions.Create}`,
  Delete: `${prefix}/${ClassReportActions.Delete}`,
  ViewLessonReport: `${prefix}/${ClassReportActions.ViewLessonReport}`,
  ViewLessonReportRoute: `${prefix}/${ClassReportActions.ViewLessonReport}/:logId/:classLessonId/:classId`
};
