import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AdminPage } from '../../ui/page/AdminPage';
import { EventActions } from './EventConstants';
import ListClassEvent from './ListClassEvent';

const stylesClassEvent = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class ClassEvent extends Component {
  static displayName = ClassEvent.name;

  constructor(...args) {
    super(...args);
    this.state = {
      classId: this.props.match.params.classId,
      content: null,
      action: null,
      pageTitle: ''
    };
    switch (this.props.action) {
      case EventActions.List:
        this.state.pageTitle = 'Class Events';
        this.state.content = <ListClassEvent classId={this.state.classId} />;
        break;
      case undefined:
      default:
    }
  }

  render() {
    const { pageTitle, content, action } = this.state;

    return (
      <Fragment>
        <AdminPage title={pageTitle} content={content} actions={action} />
        {this.state.redirect}
      </Fragment>
    );
  }
}

ClassEvent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesClassEvent)(ClassEvent);
