import { ClassReportActions, ClassReportPaths } from './ClassReportConstants';
import {
  MessageBarText,
  MessageBarVariants,
  MessageBars,
  MessageType
} from '../../ui/popup/Messages';
import { MyClassActions, MyClassPaths } from '../../my-class/MyClassConstants';
import React, { Component, Fragment } from 'react';

import AttendanceDialog from '../../attendance/AttendanceDialog';
import ClassReportSearch from './ClassReportSearch';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CommonDataTable from '../../ui/table/CommonDataTable';
import CommonSearchToolbar from '../../ui/table/CommonSearchToolbar';
import Fab from '@material-ui/core/Fab';
import { Loading } from '../../ui/Loading';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Roles } from '../../common/constants/Roles';
import { TeacherLessonPaths } from '../../teacher-lesson/TeacherLessonConstants';
import authService from '../../api-authorization/AuthorizeService';
import { withStyles } from '@material-ui/core/styles';

const stylesListClassLog = theme => ({
  fab: {
    margin: theme.spacing(2, 1)
  }
});

class ListClassLogComp extends Component {
  static displayName = ListClassLogComp.name;

  constructor(...args) {
    super(...args);
    this.attendanceDialog = React.createRef();
    this.messageBar = React.createRef();
    this.state = {
      classLogs: null,
      loading: true,
      classFilter: {},
      redirect: null,
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Success}
          type={MessageType.Upload}
          message={MessageBarText.Success.upload}
          handleClose={this.handleCloseMsgBar}
        />
      )
    };
  }

  componentDidMount() {
    if (this.props.action === ClassReportActions.MyList || this.props.classId) {
      this.populateClassLogData();
    } else {
      this.setState({ loading: false });
    }
  }

  populateClassLogData = async filters => {
    if (filters) {
      this.setState({ classFilter: filters });
    } else {
      filters = this.state.classFilter;
    }
    this.setState({ loading: true });
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    if (this.props.classId) {
      const response = await fetch(
        `api/TeacherLessonLog/GetLessonLogsByClassId/${loggedInUser.sub}/${this.props.classId}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const classLogs = await response.json();
      this.setState({ classLogs, loading: false });
    } else {
      const headers = {
        ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
        ...(!token ? {} : { Authorization: `Bearer ${token}` })
      };
      const response = await fetch(
        `api/TeacherLessonLog/GetLessonLogs/${loggedInUser.sub}`,
        {
          method: 'POST',
          body: JSON.stringify(filters),
          headers: headers
        }
      );
      const classLogs = await response.json();
      this.setState({ classLogs }, () =>
        this.populateUserInfo(loggedInUser, token)
      );
    }
  };

  async populateUserInfo(user, token) {
    const [respUserData] = await Promise.all([
      fetch(`api/AspNetUsers/GetUserRole/${user.sub}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [userRole] = await Promise.all([respUserData.json()]);
    this.setState({ userRole, loading: false });
  }

  handleBackToList = () => {
    if (this.props.classLessonId) {
      this.redirectTo(
        `${TeacherLessonPaths.ViewLessonAdmin}/${this.props.classId}`
      );
      return;
    }
    this.redirectTo(MyClassPaths.Preview);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  handleChangeSearchTerm = filterText => {
    this.setChildData(filterText);
  };

  setChildData = filterText => {
    const { classLogs } = this.state;
    const searchTerm = filterText.toLowerCase();
    let filteredRows = classLogs.filter(e => {
      const itemText = (e.class + e.schedule + e.reportBy).toLowerCase();
      return itemText.indexOf(searchTerm) > -1;
    });
    this.child.setData(filteredRows);
  };

  showAttendanceDialog = rowData => {
    this.attendanceDialog.openAttendanceDialog(
      'attendance',
      rowData.id,
      rowData.classId,
      rowData.classLessonId,
      'ClassListLog'
    );
  };

  showMessageBar = (msgVariant, msgType, msgText) => {
    this.messageBar.showMessage(msgVariant, msgType, msgText);
  };

  handleCloseMsgBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  uploadImportFile = async (name, files, fileInput) => {
    const token = await authService.getAccessToken();
    const actionUrl = `api/TeacherLessonLog/UploadImportFile/${name}`;
    const data = new FormData();
    data.append('files', files[0]);

    fetch(actionUrl, {
      method: 'POST',
      body: data,
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        if (response.ok) {
          this.showMessageBar(
            MessageBarVariants.Success,
            MessageType.Import,
            MessageBarText.Success[MessageType.Import]
          );
          fileInput.value = null;
        } else throw new Error(response.status);
      })
      .catch(error => {
        this.showMessageBar(
          MessageBarVariants.Error,
          MessageType.Import,
          MessageBarText.Error[MessageType.Import]
        );
        console.log(error);
      });
  };

  renderDatagrid() {
    const cols = [
      { name: 'level', header: 'Level', align: 'left' },
      { name: 'class', header: 'Class', align: 'left', width: 100 },
      { name: 'schedule', header: 'Schedule', align: 'left' },
      { name: 'startTimeLocal', header: 'Start time', align: 'left' },
      { name: 'endTimeLocal', header: 'End time', align: 'left' },
      { name: 'lessonDate', header: 'Date', align: 'left' },
      { name: 'lesson', header: 'Lesson', align: 'left' },
      { name: 'subject', header: 'Subject', align: 'left' },
      { name: 'content', header: 'Content', align: 'left' },
      { name: 'tb', header: 'TB', align: 'left', width: 150 },
      { name: 'reportBy', header: 'Report By', align: 'left' },
      { name: 'reportDateTime', header: 'Report Date Time', align: 'left' }
    ];
    const actions = [
      {
        name: 'multi-route',
        tooltip: 'View report',
        url:
          this.props.action === ClassReportActions.MyList
            ? `${ClassReportPaths.ViewMyReport}`
            : this.props.action === MyClassActions.ViewReport
            ? `${MyClassPaths.ViewReportDetail}`
            : `${ClassReportPaths.View}`,
        icon: 'pageview'
      }
    ];
    const { userRole } = this.state;
    if (userRole?.role.name === Roles.Admin) {
      actions.push.apply(actions, [
        {
          name: 'callback',
          tooltip: 'Edit attendance',
          callBackFn: this.showAttendanceDialog,
          icon: 'edit_icon'
        },
        {
          name: 'delete',
          tooltip: 'Delete',
          url: 'TeacherLessonLog/DeleteLessonLog',
          icon: 'delete_icon'
        }
      ]);
    }

    return (
      <Paper>
        {(this.props.action === ClassReportActions.MyList ||
          this.props.classId) && (
          <CommonSearchToolbar handleChange={this.handleChangeSearchTerm} />
        )}
        <CommonDataTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.classLogs}
          cols={cols}
          actions={actions}
          eventReloadData={this.populateClassLogData}
          isPaging={true}
        />
      </Paper>
    );
  }

  render() {
    const { classLogs, loading } = this.state;
    let contents = loading ? (
      <Loading />
    ) : classLogs ? (
      this.renderDatagrid()
    ) : null;
    const { classes, action, classId } = this.props;

    return (
      <Fragment>
        {action !== ClassReportActions.MyList && !classId && (
          <ClassReportSearch
            searchAction={this.populateClassLogData}
            uploadImportFile={this.uploadImportFile}
          />
        )}
        {contents}
        {classId ? (
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        ) : null}
        {this.state.redirect}
        <AttendanceDialog
          onRef={actualChild => (this.attendanceDialog = actualChild)}
        />
        {this.state.messageBar}
      </Fragment>
    );
  }
}

ListClassLogComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListClassLog = withStyles(stylesListClassLog)(ListClassLogComp);
