import { ActionIconButton, NormalTooltip } from '../ButtonStyles';
import { DialogType, Dialogs } from '../popup/Dialogs';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  MessageBarText,
  MessageBarVariants,
  MessageBars,
  MessageType
} from '../popup/Messages';
import React, { Component, Fragment } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CollapseContent from '../table/CollapseContent';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import FormControl from '@material-ui/core/FormControl';
import { HtmlUtils } from '../../common/utils/HtmlUtils';
import Icon from '@material-ui/core/Icon';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import RootRef from '@material-ui/core/RootRef';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import UploadFileBtn from '../../common/UploadFile';
import authService from '../../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

// import {SortableContainer, SortableElement} from 'react-sortable-hoc';

const CustomTableCell = withStyles(theme => ({
  head: {
    fontFamily: 'RalewayBold',
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'uppercase',
    backgroundColor: 'rgba(224, 224, 224, 0.5)',
    color: theme.palette.text.primary,
    padding: theme.spacing(1.75, 2.5),
    paddingLeft: 10,
    paddingRight: 10,
    borderRight: '0.5px solid #ddd',
    '&:nth-child(1)': {
      borderLeft: '0.5px solid #ddd'
    }
  },
  body: {
    fontFamily: 'RalewayMedium',
    fontWeight: 'normal',
    fontSize: 14,
    color: theme.palette.text.secondary,
    minHeight: 41,
    padding: theme.spacing(1, 2.5),
    paddingLeft: 10,
    paddingRight: 10,
    borderRight: '0.5px solid #ddd',
    '&:nth-child(1)': {
      borderLeft: '0.5px solid #ddd'
    }
  }
}))(TableCell);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const stylesCommonDataTable = theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    padding: theme.spacing(0.25),
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  actionCol: {
    width: 195,
    minWidth: 195
  },
  actionPanel: {
    display: 'inline-block',
    whiteSpace: 'nowrap'
  },
  actionIcon: {
    fontSize: 20
  },
  viewMore: {
    fontSize: 12,
    textDecoration: 'underline',
    //cursor: 'pointer',
    paddingLeft: '1px',
    color: '#005cc5'
  },
  tooltip: {
    fontSize: 30,
    backgroundColor: 'green'
  },
  textField: {
    width: '100%'
  },
  centerIcon: {
    textAlign: 'center'
  }
});

// const SortableItem = SortableElement(({value}) => {
//   return (
//     <tr>
//       <td>{value.id}</td>
//       <td>{value.name}</td>
//       <td>{value.desc}</td>
//     </tr>
//   )
// });

// const SortableList = SortableContainer(({items}) => {
//   return (
//     <table>
//       {items.map((value, index) => (
//         <SortableItem key={`item-${value.id}`} index={index} value={value} />
//       ))}
//     </table>
//   );
// });

class DropDragDataTable extends Component {
  constructor(...args) {
    super(...args);
    this.messageBar = React.createRef();
    this.confirmDialog = React.createRef();
    const { rows } = this.props;
    this.onDragEnd = this.onDragEnd.bind(this);
    this.state = {
      selected: [],
      selectAll: 0,
      filteredRows: [],
      data: rows,
      apiUrl: '',
      selectedId: '',
      dataSelectDropdown: [],
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Success}
          type={MessageType.Upload}
          message={MessageBarText.Success}
          handleClose={this.handleCloseMsgBar}
        />
      ),
      closeConfirmDialog: (
        <Button onClick={this.closeConfirmDialog} color="primary">
          Cancel
        </Button>
      ),
      confirmDialog: (
        <Dialogs onRef={actualChild => (this.confirmDialog = actualChild)} />
      ),
      items: [
        { id: 1, name: 'Log1', desc: 'log' },
        { id: 2, name: 'Log2', desc: 'log' },
        { id: 3, name: 'Log3', desc: 'log' },
        { id: 4, name: 'Log4', desc: 'log' },
        { id: 5, name: 'Log5', desc: 'log' }
      ]
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    var dndResizeTable = document.getElementById('GeeO-Dnd-Resizable-Table');
    this.resizableGrid(dndResizeTable);
    // var tables = document.getElementsByTagName('table');
    // for (var i = 0; i < tables.length; i++) {
    //   this.resizableGrid(tables[i]);
    // }
  }

  componentWillUnmount() {}

  setData(filteredRows) {
    this.setState({ filteredRows });
  }

  handleCloseMsgBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  onChangeCheckbox = id => event => {
    var lstSelected = this.state.selected;
    if (event.target.checked) {
      lstSelected.push(id);
    } else {
      var indexRemove = lstSelected.indexOf(id);
      if (indexRemove > -1) {
        lstSelected.splice(indexRemove, 1);
      }
    }
    this.props.callbackFromParent(lstSelected);
  };
  showPopup = (row, nameAct) => {
    this.props.callbackGetIdShowPopup(row, nameAct);
  };

  showConfirmDialog = () => {
    this.confirmDialog.showDialogWithCmds(
      this.state.dlgType,
      this.state.actionBtns
    );
  };
  closeConfirmDialog = () => {
    this.confirmDialog.closeDialog();
  };
  handleApiAction = (row, actionInfo) => {
    switch (actionInfo.name) {
      case 'delete':
        actionInfo.method = 'DELETE';
        actionInfo.msgType = MessageType.Delete;
        actionInfo.dlgType = DialogType.Delete;
        actionInfo.actionBtnText = 'Delete';
        break;
      case 'apiAction':
        break;
      case undefined:
      default:
    }
    const commands = (
      <Fragment>
        {this.state.closeConfirmDialog}
        <Button onClick={this.doApiAction} color="primary">
          {actionInfo.actionBtnText}
        </Button>
      </Fragment>
    );
    this.setState(
      {
        apiUrl: actionInfo.url,
        method: actionInfo.method,
        selectedId:
          actionInfo.selectedId !== undefined ? actionInfo.selectedId : row.id,
        dlgType: actionInfo.dlgType,
        msgType: actionInfo.msgType,
        actionBtns: commands
      },
      this.showConfirmDialog
    );
  };
  doApiAction = async () => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    fetch(`api/${this.state.apiUrl}/${this.state.selectedId}`, {
      method: this.state.method,
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.messageBar.showMessage(
            MessageBarVariants.Success,
            this.state.msgType,
            MessageBarText.Success[this.state.msgType]
          );
          this.props.eventReloadData();
        } else throw new Error(response.status);
      })
      .catch(error => {
        this.messageBar.showMessage(
          MessageBarVariants.Error,
          this.state.msgType,
          MessageBarText.Error[this.state.msgType]
        );
        console.error('Error:', error);
      });
    this.closeConfirmDialog();
  };

  resizableGrid(table) {
    var row = table.getElementsByTagName('tr')[0],
      cols = row ? row.children : undefined;
    if (!cols) return;
    table.style.tableLayout = 'auto';
    for (var i = 0; i < cols.length; i++) {
      var div = createDiv();
      cols[i].appendChild(div);
      cols[i].style.position = 'relative';
      var colWidth = this.props.cols;
      var colActionWidth = this.props.actions;
      if (colWidth.length > i) {
        if (colWidth[i]['width'] !== undefined) {
          cols[i].style.width = colWidth[i]['width'] + 'px';
        }
      } else {
        cols[i].style.width = cols[i].offsetWidth + 'px';
      }
      if (colActionWidth) {
        cols[cols.length - 1].style.width =
          colActionWidth.length < 2
            ? '100px'
            : colActionWidth.length * 40 + 30 + 'px';
      }
      setListeners(div);
    }

    function setListeners(div) {
      var pageX, curCol, nxtCol, curColWidth, nxtColWidth;
      div.addEventListener('mousedown', function(e) {
        //table.style.tableLayout = 'fixed';
        //div.style.height = row.offsetHeight+'px';
        let styleDivVerical = document.querySelectorAll('.divVertical');
        for (let i = 0; i < styleDivVerical.length; i++) {
          //styleDivVerical[i].style.height = row.offsetHeight + 'px';
          styleDivVerical[i].style.height = '100%';
        }

        curCol = e.target.parentElement;
        nxtCol = curCol.nextElementSibling;
        pageX = e.pageX;

        var padding = paddingDiff(curCol);

        curColWidth = curCol.offsetWidth - padding;
        if (nxtCol) {
          nxtColWidth = nxtCol.offsetWidth;
          //nxtColWidth = nxtCol.offsetWidth - padding;
        }
      });

      div.addEventListener('mouseover', function(e) {
        e.target.style.borderRight = '3px solid #ddd';
      });

      div.addEventListener('mouseout', function(e) {
        e.target.style.borderRight = '';
      });

      document.addEventListener('mousemove', function(e) {
        if (curCol) {
          var diffX = e.pageX - pageX;

          if (nxtCol) {
            // nxtCol.style.width = (nxtColWidth - (diffX))+'px';
            nxtCol.style.width = nxtColWidth + 'px';
          }

          curCol.style.width = curColWidth + diffX + 'px';
        }
      });

      document.addEventListener('mouseup', function(e) {
        curCol = undefined;
        nxtCol = undefined;
        pageX = undefined;
        nxtColWidth = undefined;
        curColWidth = undefined;
      });
    }

    function createDiv() {
      var div = document.createElement('div');
      div.style.zIndex = 1;
      div.style.opacity = 0;
      div.style.top = 0;
      div.style.right = '-2.5px';
      div.style.width = '5px';
      div.style.marginRight = '1px';
      div.style.position = 'absolute';
      div.style.cursor = 'col-resize';
      div.style.userSelect = 'none';
      //div.style.height = height + 'px';
      div.style.height = '100%';
      div.classList.add('divVertical');
      return div;
    }

    function paddingDiff(col) {
      if (getStyleVal(col, 'box-sizing') === 'border-box') {
        return 0;
      }

      var padLeft = getStyleVal(col, 'padding-left');
      var padRight = getStyleVal(col, 'padding-right');
      return parseInt(padLeft, 10) + parseInt(padRight, 10);
    }

    function getStyleVal(elm, css) {
      return window.getComputedStyle(elm, null).getPropertyValue(css);
    }
  }

  onChangeDropdown = id => event => {
    var val = event.target.value;
    if (val !== '') {
      var dataSelectDropdown = this.state.dataSelectDropdown;
      var data = this.state.filteredRows;
      var isCheck = false;
      dataSelectDropdown.forEach(e => {
        if (e.id === id) {
          e.val = val;
          isCheck = true;
        }
      });
      data.forEach(e => {
        if (e.id === id) {
          e.valDropdown = val;
        }
      });
      if (!isCheck) {
        dataSelectDropdown.push({ id: id, valDropdown: val });
      }
      this.setState({ dataSelectDropdown: dataSelectDropdown, row: data });
      this.props.callbackFromParentDrowdown(dataSelectDropdown);
    }
  };

  onSelectDropdown = id => event => {
    return event.target.value;
  };
  onDragStart = start => {
    console.log(start);
  };
  onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const { filteredRows } = this.state;
    const dataTemp = reorder(
      filteredRows,
      result.source.index,
      result.destination.index
    );
    this.setState({
      filteredRows: dataTemp
    });
    this.props.callBackGetListSort(dataTemp, result);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    console.log(oldIndex);
    console.log(newIndex);
    const { items } = this.state;
    const dataTemp = reorder(items, oldIndex, newIndex);
    this.setState({
      items: dataTemp
    });
  };

  preventClickCell = () => {
    console.log('d');
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging && 'lightblue',
    ...draggableStyle
  });

  handleOnDragUpdate = update => {
    //const { draggableId, source, destination } = update;
    // const sourceId = source.droppableId;
    // const destinationId = destination.droppableId;
  };

  helperContainer = () => {
    console.log('action');
  };

  onCheckedAllCheckboxChange = event => {
    const { checked } = event.target;
    let selected = [];
    if (checked) {
      selected = this.props.rows.map(x => x.id);
    }
    this.setState({ selected });
    this.props.callbackFromParent(selected);
    console.log('selected', selected);
  };

  render() {
    const {
      classes,
      cols,
      actions,
      actHeadCmd,
      isShowCheckbox,
      isShowDropdown,
      dataDropdown,
      eventDropDown
    } = this.props;
    const { filteredRows: rows, selected } = this.state;
    const isShowPopup = this.props.isShowPopup !== undefined ? true : false;
    return (
      <Paper className={clsx(classes.root, 'GeeO-Resizable-Table')}>
        <Fragment>
          {this.state.messageBar}
          {this.state.confirmDialog}
          <DragDropContext
            onDragEnd={this.onDragEnd}
            onDragStart={this.onDragStart}
            onDragUpdate={this.handleOnDragUpdate}
          >
            <Table id="GeeO-Dnd-Resizable-Table">
              <TableHead>
                <TableRow>
                  <CustomTableCell component="th" style={{ width: 80 }} />
                  {isShowCheckbox ? (
                    // <CustomTableCell component="th" style={{ width: 80 }} />
                    <CustomTableCell style={{ width: 80 }} align={'center'}>
                      <Checkbox onChange={this.onCheckedAllCheckboxChange} />
                    </CustomTableCell>
                  ) : null}
                  {cols.map(col => (
                    <CustomTableCell
                      component="th"
                      style={{ width: col.width }}
                      align={col.align}
                      key={col.name}
                    >
                      {col.header}
                    </CustomTableCell>
                  ))}
                  {isShowDropdown ? (
                    <CustomTableCell
                      component="th"
                      style={{ width: eventDropDown.width }}
                      align={eventDropDown.align}
                      key={eventDropDown.name}
                    >
                      {eventDropDown.name}
                    </CustomTableCell>
                  ) : null}

                  {(actions || actHeadCmd) && (
                    <CustomTableCell
                      className={classes.actionCol}
                      align="center"
                    >
                      <div className={classes.actionPanel}>
                        {actHeadCmd ? (
                          <NormalTooltip title={actHeadCmd.tooltip}>
                            <IconButton
                              color={actHeadCmd.color}
                              aria-label={actHeadCmd.tooltip}
                              onClick={actHeadCmd.cmd}
                            >
                              {actHeadCmd.icon}
                            </IconButton>
                          </NormalTooltip>
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </div>
                    </CustomTableCell>
                  )}
                </TableRow>
              </TableHead>
              <Droppable droppableId="droppable-1">
                {provided => (
                  <RootRef rootRef={provided.innerRef}>
                    <TableBody {...provided.droppableProps}>
                      {rows.map((row, index) => (
                        <Draggable
                          draggableId={row.id}
                          index={index}
                          key={row.id}
                        >
                          {(provided, snapshot) => (
                            <RootRef rootRef={provided.innerRef}>
                              <TableRow
                                {...provided.draggableProps}
                                style={this.getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <CustomTableCell
                                  style={{ width: 80 }}
                                  {...provided.dragHandleProps}
                                >
                                  <Box
                                    id={row.id}
                                    className={classes.centerIcon}
                                  >
                                    <DragIndicatorIcon />
                                  </Box>
                                </CustomTableCell>
                                {isShowCheckbox ? (
                                  <CustomTableCell
                                    align="center"
                                    style={{ width: 80 }}
                                  >
                                    <Checkbox
                                      value="checkedC"
                                      checked={selected.includes(row.id)}
                                      id={row.id}
                                      onChange={this.onChangeCheckbox(row.id)}
                                    />
                                  </CustomTableCell>
                                ) : null}

                                {cols.map(col => (
                                  <CustomTableCell
                                    align={col.align}
                                    key={row.id + col.name}
                                    width={col.width}
                                    onClick={this.preventClickCell}
                                  >
                                    <Typography
                                      variant="body2"
                                      noWrap={
                                        col.noWrap === undefined
                                          ? undefined
                                          : true
                                      }
                                    >
                                      {col.obj && row[col.name] !== null ? (
                                        <span
                                          dangerouslySetInnerHTML={HtmlUtils.createMarkup(
                                            row[col.name][col.objName]
                                          )}
                                        />
                                      ) : col.shorteningContent === true ? (
                                        <CollapseContent
                                          content={row[col.name]}
                                          index={col.indexCut}
                                        />
                                      ) : (
                                        <span
                                          dangerouslySetInnerHTML={HtmlUtils.createMarkup(
                                            row[col.name]
                                          )}
                                        />
                                      )}
                                    </Typography>
                                  </CustomTableCell>
                                ))}

                                {isShowDropdown ? (
                                  <CustomTableCell align="center">
                                    <FormControl
                                      className={clsx(
                                        classes.margin,
                                        classes.textField
                                      )}
                                    >
                                      <Select
                                        id="dropdown"
                                        name="dropdown"
                                        value={row.valDropdown}
                                        onChange={this.onChangeDropdown(row.id)}
                                      >
                                        <MenuItem key="" value="" />
                                        {dataDropdown
                                          ? dataDropdown.map(item => (
                                              <MenuItem
                                                key={item.id}
                                                value={item.id}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            ))
                                          : null}
                                      </Select>
                                    </FormControl>
                                  </CustomTableCell>
                                ) : null}
                                {(actHeadCmd || actions) && (
                                  <CustomTableCell
                                    className={classes.actionCol}
                                    align="center"
                                  >
                                    {actions && (
                                      <div className={classes.actionPanel}>
                                        {actions.map(col =>
                                          col.name !== 'attach' ? (
                                            <NormalTooltip
                                              title={col.tooltip}
                                              key={row.id + col.name}
                                            >
                                              {isShowPopup === true &&
                                              col.url === undefined ? (
                                                <ActionIconButton
                                                  onClick={() =>
                                                    this.showPopup(
                                                      row,
                                                      col.name
                                                    )
                                                  }
                                                >
                                                  <Icon
                                                    className={
                                                      classes.actionIcon
                                                    }
                                                  >
                                                    {col.icon}
                                                  </Icon>
                                                </ActionIconButton>
                                              ) : col.name === 'delete' ||
                                                col.name === 'apiAction' ? (
                                                <ActionIconButton
                                                  onClick={() =>
                                                    this.handleApiAction(
                                                      row,
                                                      col
                                                    )
                                                  }
                                                >
                                                  <Icon
                                                    className={
                                                      classes.actionIcon
                                                    }
                                                  >
                                                    {col.icon}
                                                  </Icon>
                                                </ActionIconButton>
                                              ) : col.name === 'callback' ? (
                                                <ActionIconButton
                                                  onClick={() =>
                                                    this.props.callbackParentAction(
                                                      row
                                                    )
                                                  }
                                                >
                                                  <Icon
                                                    className={
                                                      classes.actionIcon
                                                    }
                                                  >
                                                    {col.icon}
                                                  </Icon>
                                                </ActionIconButton>
                                              ) : (
                                                <ActionIconButton
                                                  component={Link}
                                                  to={
                                                    col.name === 'detail' ||
                                                    col.name === 'subList' ||
                                                    col.name === 'edit' ||
                                                    col.name ===
                                                      'lessonAndTeacher' ||
                                                    col.name === 'view-unit'
                                                      ? col.url +
                                                        '/' +
                                                        (row.subId != null
                                                          ? row.subId
                                                          : row.id)
                                                      : col.name ===
                                                          'multi-route' ||
                                                        col.name ===
                                                          'multi-route1'
                                                      ? col.url +
                                                        '/' +
                                                        row.id +
                                                        '/' +
                                                        row.routeId
                                                      : col.url
                                                  }
                                                >
                                                  <Icon
                                                    className={
                                                      classes.actionIcon
                                                    }
                                                  >
                                                    {col.icon}
                                                  </Icon>
                                                </ActionIconButton>
                                              )}
                                            </NormalTooltip>
                                          ) : (
                                            <UploadFileBtn
                                              key={row.id + col.name}
                                              url={col.url}
                                              targetId={row.id}
                                              tooltip={col.tooltip}
                                              callBackFn={col.callBackFn}
                                              icon={col.icon}
                                            />
                                          )
                                        )}
                                      </div>
                                    )}
                                  </CustomTableCell>
                                )}
                              </TableRow>
                            </RootRef>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  </RootRef>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </Fragment>
        {/* <SortableList items={this.state.items} onSortEnd={this.onSortEnd} helperContainer="dragging-helper-class"/> */}
      </Paper>
    );
  }
}

DropDragDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  callbackParentAction: PropTypes.func,
  rows: PropTypes.array,
  onRef: PropTypes.func,
  actions: PropTypes.array,
  cols: PropTypes.array,
  dataDropdown: PropTypes.array,
  actHeadCmd: PropTypes.object,
  isShowCheckbox: PropTypes.bool,
  isShowDropdown: PropTypes.bool,
  eventDropDown: PropTypes.object,
  isShowPopup: PropTypes.bool,
  callbackFromParent: PropTypes.func,
  callbackGetIdShowPopup: PropTypes.func,
  eventReloadData: PropTypes.func
};

export default withStyles(stylesCommonDataTable)(DropDragDataTable);
