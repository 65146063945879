import { CampusActions, CampusPaths } from './CampusConstants';
import { Fab, List, ListItem, Paper, TextField } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { stylesCommon, stylesEdit } from '../../common/styles/CommonStyles';

import { AdminPage } from '../../ui/page/AdminPage';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { Loading } from '../../ui/Loading';
import PropTypes from 'prop-types';
import { RouteUtils } from '../../common/RouteUtils';
import SaveIcon from '@material-ui/icons/Save';
import authService from '../../api-authorization/AuthorizeService';
import { withStyles } from '@material-ui/core/styles';

const stylesCampusEdit = theme => ({
  ...stylesCommon(theme),
  ...stylesEdit(theme)
});

class CampusEdit extends Component {
  constructor(...args) {
    super(...args);
    this.userForm = React.createRef();
    this.state = {
      campusId: this.props.match.params.campusId,
      campus: {
        name: '',
        address: '',
        fullAddress: ''
      },
      loading: true
    };
    switch (this.props.action) {
      case CampusActions.Create:
        this.state.method = 'POST';
        this.state.action = `api/Campus`;
        this.state.loading = false;
        break;
      case CampusActions.Edit:
        this.state.method = 'PUT';
        this.state.action = `api/Campus/${this.state.campusId}`;
        this.populateCampus();
        break;
      default:
    }
  }

  async populateCampus() {
    const token = await authService.getAccessToken();
    const { campusId } = this.state;
    const response = await fetch(`api/Campus/${campusId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const campus = await response.json();
    this.setState({ campus, loading: false });
  }

  handleChange = event => {
    const attrName = event.target.name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      campus: {
        ...prevState.campus,
        [attrName]: attrValue
      }
    }));
  };

  handleSubmit = async () => {
    const token = await authService.getAccessToken();
    const { campus, method, action } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    fetch(action, {
      method: method,
      body: JSON.stringify(campus),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  backToList = () => {
    RouteUtils.nextPath(this, CampusPaths.List);
  };

  renderForm() {
    const { classes } = this.props;
    const { campus } = this.state;

    return (
      <Paper className={classes.root}>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <TextField
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              label="Short Name"
              placeholder="Short Name"
              name="name"
              value={campus.name}
              onChange={this.handleChange}
              className={classes.margin}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <TextField
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              label="Full Name"
              placeholder="Full Name"
              name="address"
              value={campus.address}
              onChange={this.handleChange}
              className={classes.margin}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <TextField
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              label="Address"
              placeholder="Address"
              name="fullAddress"
              value={campus.fullAddress}
              onChange={this.handleChange}
              className={classes.margin}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <TextField
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              label="Domain"
              placeholder="Domain name"
              name="website"
              value={campus.website}
              onChange={this.handleChange}
              className={classes.margin}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <TextField
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              label="Location"
              placeholder="Location"
              name="location"
              value={campus.location}
              onChange={this.handleChange}
              className={classes.margin}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <TextField
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              label="Ip"
              placeholder="Ip Address"
              name="ipAddress"
              value={campus.ipAddress}
              onChange={this.handleChange}
              className={classes.margin}
            />
          </ListItem>
        </List>
      </Paper>
    );
  }

  render() {
    const { classes } = this.props;
    let contents = this.state.loading ? <Loading /> : this.renderForm();
    const actions = (
      <Fragment>
        <Fab
          aria-label="Save"
          color="primary"
          className={classes.fab}
          onClick={this.handleSubmit}
        >
          <SaveIcon />
        </Fab>
        <Fab
          aria-label="Cancel"
          className={classes.fab}
          onClick={this.backToList}
        >
          <CloseOutlinedIcon color="action" />
        </Fab>
      </Fragment>
    );

    return <AdminPage title="Branch" content={contents} actions={actions} />;
  }
}

CampusEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesCampusEdit)(CampusEdit);
