export const TeacherLessonActions = {
  List: 'list',
  ListAll: 'list-all',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete',
  ViewLesson: 'view-lesson',
  ViewLessonAdmin: 'view-lesson-admin',
  ViewUnitPlan: 'view-unit',
  ViewUnitAdmin: 'view-unit-admin',
  ViewCalendar: 'view-calendar',
  ViewMySchedule: 'view-my-schedule',
  ViewAllClassCalendar: 'all-calendar'
};

const prefix = '/teacher-lesson';

export const TeacherLessonPaths = {
  TeacherLessonPrefix: prefix,
  List: `${prefix}/${TeacherLessonActions.List}`,
  ListAll: `${prefix}/${TeacherLessonActions.ListAll}`,
  Delete: `${prefix}/${TeacherLessonActions.Delete}`,
  ViewLessonRoute: `${prefix}/${TeacherLessonActions.ViewLesson}/:classId`,
  ViewLessonAdminRoute: `${prefix}/${TeacherLessonActions.ViewLessonAdmin}/:classId`,
  ViewLesson: `${prefix}/${TeacherLessonActions.ViewLesson}`,
  ViewLessonAdmin: `${prefix}/${TeacherLessonActions.ViewLessonAdmin}`,
  ViewUnitPlanRoute: `${prefix}/${TeacherLessonActions.ViewUnitPlan}/:classLessonId/:classId`,
  ViewUnitAdminRoute: `${prefix}/${TeacherLessonActions.ViewUnitAdmin}/:classLessonId/:classId`,
  ViewUnitPlan: `${prefix}/${TeacherLessonActions.ViewUnitPlan}`,
  ViewUnitAdmin: `${prefix}/${TeacherLessonActions.ViewUnitAdmin}`,
  ViewCalendarRoute: `${prefix}/${TeacherLessonActions.ViewCalendar}/:classId`,
  ViewCalendar: `${prefix}/${TeacherLessonActions.ViewCalendar}`,
  ViewMyScheduleRoute: `${prefix}/${TeacherLessonActions.ViewMySchedule}/:classId`,
  ViewMySchedule: `${prefix}/${TeacherLessonActions.ViewMySchedule}`,
  ViewAllClassCalendar: `${prefix}/${TeacherLessonActions.ViewAllClassCalendar}`
};
