import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import clsx from 'clsx';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import {
  Popover,
  Card,
  CardMedia,
  CardActions,
  CardContent
} from '@material-ui/core';
import {
  Button,
  Checkbox,
  Grid,
  Typography,
  FormControlLabel,
  List,
  ListItem
} from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import ClassCourseInfo from './ClassCourseInfo';

const stylesSendLessonContent = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  dialogContent: {
    padding: theme.spacing(0, 2, 1, 2)
  },
  header: {
    fontWeight: 600
  },
  center: {
    textAlign: 'center'
  },
  box: {
    border: '1px solid #d3d4d5',
    height: '100%',
    padding: theme.spacing(1)
  },
  emailAddress: {
    height: 58,
    maxHeight: 58,
    overflowY: 'auto'
  },
  card: {
    width: '100%',
    height: '50vh',
    overflowY: 'auto'
  },
  media: {
    minHeight: 300,
    border: 0,
    overflow: 'visible'
  },
  chooseLessonPopup: {
    width: 560
  },
  chooseLessonBox: {
    width: '100%',
    height: 400,
    overflowY: 'auto'
  }
});

class SendLessonContent extends Component {
  static displayName = SendLessonContent.name;

  constructor(...args) {
    super(...args);
    this.choosedLessons = [];
    this.state = {
      classData: {},
      open: false,
      parentEmails: [],
      selectLessons: {},
      content: '<html></html>',
      pdfFilePath: null,
      lessonContentFrameHeight: '400px',
      anchorChooseLessons: null,
      sendEmailStatus: null,
      loading: true
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  populateClassData = async () => {
    const token = await authService.getAccessToken();
    const { classData } = this.state;
    const [respClassInfo, respParentList, respClassLesson] = await Promise.all([
      fetch(`api/ClassCourses/${classData.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/Parents/GetByClass/${classData.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/ClassLesson/${classData.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [classInfo, parentList, classLessons] = await Promise.all([
      respClassInfo.json(),
      respParentList.json(),
      respClassLesson.json()
    ]);
    let parentEmailList = [];
    let sendEmailList = [];
    parentList.forEach(parent => {
      if (parent.email) {
        const parentEmail = `${parent.name} (${parent.email})`;
        if (parentEmailList.indexOf(parentEmail) < 0) {
          parentEmailList.push(parentEmail);
          sendEmailList.push({ name: parent.name, address: parent.email });
        }
      }
    });
    const parentEmails = parentEmailList.join(', ');
    let selectLessons = {};
    classLessons.forEach(lesson => {
      selectLessons[lesson.lessonId] = false;
    });
    this.setState({
      classInfo,
      parentList,
      sendEmailList,
      parentEmails,
      classLessons,
      selectLessons,
      loading: false
    });
  };

  getEmailInfo = async () => {
    this.populateClassData();
  };

  openSendEmail(classData) {
    this.setState({ classData, loading: true }, this.getEmailInfo);
    this.openDialog();
  }

  sendEmail = async () => {
    this.setState({ sendEmailStatus: 'progress' });
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const { sendEmailList, selectLessons, classInfo } = this.state;
    const choosedLessons = _.keys(_.pickBy(selectLessons));
    const bodyData = {
      toAddresses: sendEmailList,
      lessonIds: choosedLessons,
      className: classInfo.name
    };
    fetch('api/Email/SendLessonContent', {
      method: 'POST',
      body: JSON.stringify(bodyData),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.setState({ sendEmailStatus: 'ok' });
          setTimeout(() => {
            this.closeDialog();
            if (this.props.handleParentAction) this.props.handleParentAction();
          }, 2000);
        } else {
          this.setState({ sendEmailStatus: 'error' });
          throw new Error(response.status);
        }
      })
      .catch(error => {
        this.setState({ sendEmailStatus: 'error' });
        console.error('Error:', error);
      });
  };

  openDialog = () => {
    this.setState({ open: true });
  };

  closeDialog = () => {
    this.setState({ open: false, sendEmailStatus: null });
  };

  openChooseLessons = el => {
    //this.choosedLessons.splice(0);
    this.setState({ anchorChooseLessons: el.currentTarget });
  };
  closeChooseLessons = () => {
    this.setState({ anchorChooseLessons: null });
  };

  handleChangeLesson = name => event => {
    const target = event.target;
    const attrName = name;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;

    this.setState(prevState => ({
      selectLessons: {
        ...prevState.selectLessons,
        [attrName]: attrValue
      }
    }));
    // if (target.type === 'checkbox' && target.checked) {
    //   this.choosedLessons.push(target.value);
    // } else {
    //   this.choosedLessons.splice(this.choosedLessons.indexOf(target.value), 1);
    // }
  };

  doPrepareLessons = async () => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const { selectLessons } = this.state;
    const choosedLessons = _.keys(_.pickBy(selectLessons));
    fetch('api/Email/GetLessonContentTemplate', {
      method: 'POST',
      body: JSON.stringify(choosedLessons),
      headers: headers
    })
      .then(response => response.text())
      .then(data => {
        const newIframe = document.getElementById('lessonContentFrame');
        newIframe.contentWindow.document.open('text/html');
        newIframe.contentWindow.document.write(data);
        newIframe.contentWindow.document.close();
        this.resizeLessonContentFrame();
      })
      .catch(error => {
        console.error('Error:', error);
      });
    this.closeChooseLessons();
  };

  resizeLessonContentFrame = () => {
    const obj = document.getElementById('lessonContentFrame');
    const el = obj.contentWindow.document.body;
    const styles = window.getComputedStyle(el, null);
    const margin =
      parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
    const frameHeight = el.offsetHeight + margin;
    this.setState({
      lessonContentFrameHeight: frameHeight + 'px'
    });
  };

  renderChooseLessons() {
    const { classes } = this.props;
    const { classLessons, selectLessons, anchorChooseLessons } = this.state;
    const openChooseLessons = Boolean(anchorChooseLessons);
    return (
      <Popover
        id="popoverSelectClassToCopy"
        anchorEl={anchorChooseLessons}
        open={openChooseLessons}
        onClose={this.closeChooseLessons}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Card className={classes.chooseLessonPopup} variant="outlined">
          <CardContent className={classes.chooseLessonBox}>
            <List>
              {classLessons &&
                classLessons.map(row => (
                  <ListItem key={row.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={row.lessonId}
                          checked={selectLessons[row.lessonId]}
                          onChange={this.handleChangeLesson(row.lessonId)}
                        />
                      }
                      label={
                        <>
                          <Typography
                            variant="body2"
                            display="inline"
                            className={classes.labelLesson}
                          >
                            Lesson {row.lesson} ({row.subject}):{' '}
                          </Typography>
                          <Typography
                            variant="body2"
                            display="inline"
                            className={classes.header}
                          >
                            {row.content}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              onClick={this.doPrepareLessons}
            >
              OK
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={this.closeChooseLessons}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Popover>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      open,
      classInfo,
      parentEmails,
      sendEmailStatus,
      lessonContentFrameHeight
    } = this.state;

    return (
      <Fragment>
        <Dialog open={open} onClose={this.closeDialog} maxWidth="lg" fullWidth>
          <DialogTitle>{'Send Email - Lesson Content'}</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {this.state.loading ? (
              <Loading />
            ) : (
              <Fragment>
                <ClassCourseInfo classCourse={classInfo} />
                <Grid container>
                  <Grid item xs={2}>
                    <div className={clsx(classes.box)}>
                      <Typography variant="body2" className={classes.header}>
                        To
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={10}>
                    <div className={clsx(classes.box, classes.emailAddress)}>
                      {parentEmails}
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={clsx(classes.box)}>
                      <Typography variant="body2" className={classes.header}>
                        Choose Lessons
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={10}>
                    <div className={clsx(classes.box)}>
                      <Button
                        onClick={this.openChooseLessons}
                        size="small"
                        color="primary"
                      >
                        Lessons...
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={clsx(classes.box)}>
                      <Typography variant="body2" className={classes.header}>
                        Preview
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={10}>
                    <Card className={classes.card}>
                      <CardMedia
                        id="lessonContentFrame"
                        style={{ height: lessonContentFrameHeight }}
                        className={classes.media}
                        component="iframe"
                        //src={"data:text/html,"+encodeURIComponent(content)}
                        //src={'api/Email/GetTemplate/LessonContent'}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    {sendEmailStatus === 'progress' ? (
                      <CircularProgress size={24} style={{ marginTop: 8 }} />
                    ) : sendEmailStatus === 'ok' ? (
                      <DoneIcon style={{ marginTop: 8, color: green[500] }} />
                    ) : sendEmailStatus === 'error' ? (
                      <ErrorIcon style={{ marginTop: 8, color: red[500] }} />
                    ) : null}
                  </Grid>
                </Grid>
              </Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Close
            </Button>
            <Button
              onClick={this.sendEmail}
              color="primary"
              disabled={!parentEmails}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
        {this.renderChooseLessons()}
      </Fragment>
    );
  }
}

SendLessonContent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesSendLessonContent)(SendLessonContent);
