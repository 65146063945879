import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Loading } from '../../ui/Loading';
import { ClassRoomPaths, ClassRoomActions } from './ClassRoomConstants';

const stylesEditClassRoom = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  }
});

class EditClassRoomComp extends Component {
  static displayName = EditClassRoomComp.name;
  constructor(...args) {
    super(...args);
    this.classRoomForm = React.createRef();
    this.state = {
      action: this.props.action,
      classRoomlId: this.props.classRoomId,
      method: 'PUT',
      classRoom: {
        name: '',
        roomNumber: '',
        campusId: '',
        roomTypeId: ''
      },
      redirect: null,
      loading: true,
      roomTypeData: [],
      campusData: []
    };

    switch (this.props.action) {
      case ClassRoomActions.Create:
        this.state.method = 'POST';
        this.state.action = `api/ClassRooms`;
        this.state.loading = false;
        break;
      case ClassRoomActions.Edit:
        this.state.action = `api/ClassRooms/${this.state.classRoomlId}`;
        this.populateClassRoomData();
        break;
      case undefined:
      default:
    }
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateRoomType();
    this.populateCampus();
  }

  populateRoomType = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/RoomTypes', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ roomTypeData: data, loading: false });
  };

  populateCampus = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const response = await fetch(
      `api/Campus/GetCampusList/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({ campusData: data, loading: false });
  };

  populateClassRoomData = async () => {
    const token = await authService.getAccessToken();
    const { classRoomlId } = this.state;
    const response = await fetch(`api/ClassRooms/${classRoomlId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ classRoom: data, loading: false });
  };

  backToList() {
    this.redirectTo(ClassRoomPaths.List);
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { classRoom, method, action, token } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    fetch(action, {
      method: method,
      body: JSON.stringify(classRoom),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  async submitData() {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    ReactDOM.findDOMNode(this.classRoomForm.current).dispatchEvent(
      new Event('submit')
    );
  }

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      classRoom: {
        ...prevState.classRoom,
        [attrName]: attrValue
      }
    }));
  };

  renderForm() {
    const { classes } = this.props;
    const { classRoom, roomTypeData, campusData } = this.state;
    const cols = [
      {
        name: 'name',
        header: 'Room Name',
        placeholder: 'Room Name',
        adornment: ''
      },
      {
        name: 'roomNumber',
        header: 'Room No.',
        placeholder: 'Room No.',
        adornment: ''
      },
      {
        name: 'campusId',
        header: 'Branch',
        placeholder: 'Branch',
        adornment: ''
      },
      {
        name: 'roomTypeId',
        header: 'Type',
        placeholder: 'Type',
        adornment: ''
      }
    ];
    return (
      <Paper className={classes.root}>
        <form
          ref={this.classRoomForm}
          className={classes.container}
          onSubmit={this.handleSubmit}
        >
          <Grid container>
            <Grid item xs={12} className={classes.cell}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <FormControl
                    className={classNames(classes.margin, classes.textField)}
                  >
                    <InputLabel htmlFor="campus">{cols[2].header}</InputLabel>
                    <Select
                      id="campus"
                      name={cols[2].name}
                      value={
                        classRoom[cols[2].name] ? classRoom[cols[2].name] : ''
                      }
                      onChange={this.handleChange(cols[2].name)}
                    >
                      <MenuItem key="" value="" />
                      {campusData
                        ? campusData.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))
                        : ''}
                    </Select>
                  </FormControl>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="room-name"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    rows="6"
                    className={classes.margin}
                    label={cols[0].header}
                    placeholder={cols[0].placeholder}
                    name={cols[0].name}
                    value={classRoom[cols[0].name]}
                    onChange={this.handleChange(cols[0].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="room-number"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    rows="6"
                    className={classes.margin}
                    label={cols[1].header}
                    placeholder={cols[1].placeholder}
                    name={cols[1].name}
                    value={classRoom[cols[1].name]}
                    onChange={this.handleChange(cols[1].name)}
                  />
                </ListItem>
                <ListItem>
                  <FormControl
                    className={classNames(classes.margin, classes.textField)}
                  >
                    <InputLabel htmlFor="room-type">
                      {cols[3].header}
                    </InputLabel>
                    <Select
                      id="room-type"
                      name={cols[3].name}
                      value={
                        classRoom[cols[3].name] ? classRoom[cols[3].name] : ''
                      }
                      onChange={this.handleChange(cols[3].name)}
                    >
                      <MenuItem key="" value="" />
                      {roomTypeData
                        ? roomTypeData.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))
                        : ''}
                    </Select>
                  </FormControl>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </form>
        {this.state.redirect}
      </Paper>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderForm();

    return <Fragment>{contents}</Fragment>;
  }
}

EditClassRoomComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const EditClassRoom = withStyles(stylesEditClassRoom)(EditClassRoomComp);
