import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { UnitSearch } from './UnitSearch';
import { UnitSearchPaths, UnitSearchAction } from './UnitSearchConstants';

export default class MaterialRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={UnitSearchPaths.List}
          component={UnitSearch}
          action={UnitSearchAction.List}
        />
      </Fragment>
    );
  }
}
