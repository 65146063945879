import React, { Component } from 'react';
import clsx from 'clsx';
import { Grid, Paper, Typography } from '@material-ui/core';

export class ClassLessonStatistics extends Component {
  render() {
    const {
      title1,
      subtitle1,
      title2,
      subtitle2,
      numberValue1,
      numberValue2,
      numberTitle,
      numberValue,
      classes
    } = this.props;

    return (
      <Paper className={clsx(classes.chartBox, classes.chartBoxEqualHeight)}>
        <Grid container>
          <div className={classes.chartHeadingBox}>
            <Typography variant="h3" className={clsx(classes.heading)}>
              {title1}
            </Typography>
            <Typography variant="body2" className={clsx(classes.subtitle)}>
              {subtitle1}
            </Typography>
          </div>
          <div className={classes.chartNumberBox}>
            <Typography
              variant="h1"
              className={clsx(
                classes.chartSmallNumber,
                classes.chartLabelGreen
              )}
            >
              {numberValue1}
            </Typography>
            <hr
              className={clsx(
                classes.chartNumberLine,
                classes.chartSmallNumberLine,
                classes.chartLabelGreen
              )}
            />
          </div>
        </Grid>
        <Grid container className={classes.chartBoxTopPad}>
          <div className={classes.chartHeadingBox}>
            <Typography variant="h3" className={clsx(classes.heading)}>
              {title2}
            </Typography>
            <Typography variant="body2" className={clsx(classes.subtitle)}>
              {subtitle2}
            </Typography>
          </div>
          <div className={classes.chartNumberBox}>
            <Typography
              variant="h1"
              className={clsx(
                classes.chartSmallNumber,
                classes.chartLabelGreen
              )}
            >
              {numberValue2}
            </Typography>
            <hr
              className={clsx(
                classes.chartNumberLine,
                classes.chartSmallNumberLine,
                classes.chartLabelGreen
              )}
            />
          </div>
        </Grid>

        <div className={classes.center} style={{ marginTop: 30 }}>
          <Typography
            variant="h3"
            className={clsx(classes.subtitle, classes.center)}
          >
            {numberTitle}
          </Typography>
          <div style={{ display: 'flex', marginTop: 26 }}>
            <div className={classes.chartHeadingBox} />
            <div className={classes.chartNumberBox}>
              <Typography
                variant="h1"
                className={clsx(
                  classes.chartLargeNumber,
                  classes.chartLabelLightBlue
                )}
              >
                {numberValue}
              </Typography>
              <hr
                className={clsx(
                  classes.chartNumberLine,
                  classes.chartLargeNumberLine,
                  classes.chartLabelLightBlue
                )}
              />
            </div>
            <div className={classes.chartHeadingBox} />
          </div>
        </div>
      </Paper>
    );
  }
}
