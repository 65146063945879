export const stylesLetsLearnContent = theme => ({
  root: {
    width: '100%',
    height: '100%'
    // height: 'calc(100vh - 2px)'
  },
  stepperRoot: {
    padding: theme.spacing(1, 5, 1.5)
  },
  step: {
    '& $completed': {
      color: theme.palette.secondary.main
    }
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  contentWrap: {
    height: 'calc(100% - 41px)'
  },
  content: {
    height: 'calc(100% - 70px)'
  },
  center: {
    alignItems: 'center',
    textAlign: 'center'
  },
  growStatusBar: {
    flexGrow: 1
  },
  description: {
    display: 'inline-flex',
    maxWidth: 780,
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1000
    },
    maxHeight: 64,
    overflow: 'hidden',
    marginLeft: theme.spacing(2)
  },
  button: {
    minWidth: 125,
    maxHeight: 40,
    height: 40,
    margin: theme.spacing(1)
  },
  margins: {
    margin: theme.spacing(5)
  },
  instructions: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  },
  finishScnActions: {
    margin: '40px auto 15px',
    width: '25%'
  },
  navigation: {
    display: 'flex',
    margin: theme.spacing(0, 3)
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main
    }
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.palette.secondary.main
    }
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: theme.palette.grey[100]
    }
  },
  connectorLine: {
    transition: theme.transitions.create('border-color')
  }
});
