export const UserActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete'
};

const prefix = '/user';

export const UserPaths = {
  UserPrefix: prefix,
  List: `${prefix}/${UserActions.List}`,
  EditRoute: `${prefix}/${UserActions.Edit}/:userId`,
  Edit: `${prefix}/${UserActions.Edit}`,
  Create: `${prefix}/${UserActions.Create}`,
  Delete: `AspNetUsers`
};
