export const CampusActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  Reports: 'reports',
  Delete: 'delete'
};

const prefix = '/campus';

export const CampusPaths = {
  CampusPrefix: prefix,
  List: `${prefix}/${CampusActions.List}`,
  EditRoute: `${prefix}/${CampusActions.Edit}/:campusId`,
  Edit: `${prefix}/${CampusActions.Edit}`,
  Create: `${prefix}/${CampusActions.Create}`,
  Reports: `${prefix}/${CampusActions.Reports}`,
  ReportsRoute: `${prefix}/${CampusActions.Reports}/:campusId`,
  Delete: `AspNetUsers`
};
