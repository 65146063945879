import {
  MessageBarText,
  MessageBarVariants,
  MessageBars,
  MessageType
} from '../../ui/popup/Messages';
import React, { Component, Fragment } from 'react';

import CommonDataTable from '../../ui/table/CommonDataTable';
import { DialogType } from '../../ui/popup/Dialogs';
import Fab from '@material-ui/core/Fab';
import { Grid } from 'semantic-ui-react';
import { LessonPlanPaths } from './LessonPlanConstants';
import { Loading } from '../../ui/Loading';
import { MyClassPaths } from '../../my-class/MyClassConstants';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { StudyLevelPaths } from '../study-level/StudyLevelConstants';
import { Toolbar } from '@material-ui/core';
import { UnitPlanPaths } from '../unit//UnitPlanConstants';
import authService from '../../api-authorization/AuthorizeService';
import { fetchHelper } from './../../../helpers/fetch-helper';
import { withStyles } from '@material-ui/core/styles';

const stylesListLessonPlan = () => ({
  root: {
    width: '100%'
  }
});

class ListLessonPlanComp extends Component {
  static displayName = ListLessonPlanComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.messageBar = React.createRef();
    this.state = {
      lessonPlans: [],
      levelId: this.props.levelId,
      redirect: null,
      loading: true,
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Success}
          type={MessageType.Upload}
          message={MessageBarText.Success.upload}
          handleClose={this.handleCloseMsgBar}
        />
      ),
      selecteds: []
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateLessonPlanData();
  }

  populateLessonPlanData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch(
      `api/LessonPlans/GetByLevel/${this.state.levelId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    const regexp = /(?:\r\n|\r|\n)/g;
    const br = '<br/>';
    const lessonPlans = data.map(item => {
      let text = item.content;
      if (text && text.search(regexp) > -1) {
        item.content = text.replace(regexp, br);
      }
      return item;
    });
    this.setState({ lessonPlans, loading: false });
    this.child.setData(data);
  };

  refreshLessonPlans = async () => {
    this.setState({ loading: true }, this.populateLessonPlanData);
  };

  backToList() {
    this.redirectTo(`${StudyLevelPaths.List}`);
  }

  showLessonPreview = async rowData => {
    window.open(`${MyClassPaths.LetsLearn}/${rowData.id}/0/1/1`);
  };

  responseAction = (refreshList, msgVariant, msgType, msgText) => {
    this.showMessageBar(msgVariant, msgType, msgText);
    if (refreshList) {
      this.refreshLessonPlans();
    }
  };
  showMessageBar = (msgVariant, msgType, msgText) => {
    this.messageBar.showMessage(msgVariant, msgType, msgText);
  };
  handleCloseMsgBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  callBackGetListSort = data => {
    const { levelId, token } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    var lstSortOrderLessonPlan = [];
    data.forEach((x, index) => {
      let objUpdate = {
        id: x.id,
        sortOrder: ++index
      };
      lstSortOrderLessonPlan.push(objUpdate);
    });
    fetch(`api/LessonPlans/UpdateCreatedTime/${levelId}`, {
      method: 'POST',
      body: JSON.stringify(lstSortOrderLessonPlan),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          //this.refreshUnitPlans();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  handleSelect = async selecteds => {
    this.setState({ selecteds });
  };

  deleteSelecteds = async () => {
    const { selecteds, lessonPlans } = this.state;
    if (selecteds.length === 0) return;
    fetchHelper.post(`api/LessonPlans/ids`, selecteds).then(() => {
      const lps = lessonPlans.filter(x => !selecteds.includes(x.id));
      this.setState({ lessonPlans: lps, selecteds: [] });
      this.child.setData(lps);
      this.showMessageBar(
        MessageBarVariants.Success,
        MessageType.Delete,
        MessageBarText.Success.delete
      );
    });
  };

  renderDatagrid() {
    const { classes } = this.props;
    const cols = [
      {
        name: 'level',
        header: 'Level',
        align: 'left',
        width: 100
      },
      { name: 'lesson', header: 'Lesson', align: 'left', width: 100 },
      { name: 'subject', header: 'Subject', align: 'left', width: 180 },
      { name: 'content', header: 'Content', align: 'left', width: 'auto' },
      { name: 'tb', header: 'TB', align: 'left', width: 250 },
      {
        name: 'worksheetFileName',
        header: 'Worksheet',
        align: 'left',
        width: 'auto',
        noWrap: true
      }
    ];
    const actions = [
      {
        name: 'subList',
        tooltip: 'Lesson plan',
        url: `${UnitPlanPaths.List}`,
        icon: 'list_alt_icon'
      },
      {
        name: 'callback',
        callBackFn: this.showLessonPreview,
        tooltip: 'Preview lesson',
        icon: 'play_arrow'
      },
      {
        name: 'attach',
        tooltip: 'Attach Worksheet',
        url: 'api/LessonPlans/UploadFile',
        contextMenu: [
          {
            menuItem: 'Delete Worksheet',
            menuIcon: <></>,
            menuCmdUrl: 'api/LessonPlans/DeleteWorksheet',
            actMethod: 'DELETE',
            actFieldName: 'worksheetFileName',
            confirmType: DialogType.Delete,
            messageType: MessageType.Delete
          }
        ],
        fileExt: '.pdf',
        callBackFn: this.responseAction,
        icon: 'attach_file'
      },
      {
        name: 'edit',
        tooltip: 'Lesson content',
        url: `${LessonPlanPaths.LessonContent}`,
        icon: 'toc'
      },
      {
        name: 'edit',
        tooltip: 'Edit plan',
        url: `${LessonPlanPaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'delete',
        tooltip: 'Delete plan',
        url: `${LessonPlanPaths.Delete}`,
        icon: 'delete_icon'
      }
    ];

    return (
      <Paper className={classes.root}>
        <Toolbar>
          <Grid
            xs={12}
            style={{
              width: '100%',
              height: 'auto',
              padding: 8,
              display: 'flex',
              justifyContent: 'end'
            }}
          >
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              aria-label="add"
              style={{
                width: 130,
                height: 36,
                marginLeft: 16,
                marginRight: 8,
                backgroundColor: '#a93a3a'
              }}
              onClick={this.deleteSelecteds}
            >
              DELETE
            </Fab>
          </Grid>
        </Toolbar>
        <CommonDataTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.lessonPlans}
          cols={cols}
          actions={actions}
          eventReloadData={this.refreshLessonPlans}
          isShowCheckbox={true}
          callbackFromParent={this.handleSelect}
          //callbackParentAction={this.showLessonPreview}
          // callBackGetListSort={this.callBackGetListSort}
        />
      </Paper>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return (
      <Fragment>
        {contents}
        {this.state.messageBar}
        {this.state.redirect}
      </Fragment>
    );
  }
}

ListLessonPlanComp.propTypes = {
  classes: PropTypes.object.isRequired,
  levelId: PropTypes.string,
  onRef: PropTypes.func
};

export const ListLessonPlan = withStyles(stylesListLessonPlan)(
  ListLessonPlanComp
);
