export const ClassDataActions = {
  List: 'list',
  ListSuspended: 'list-suspended',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete'
};

const prefix = '/class-data';

export const ClassDataPaths = {
  ClassDataPrefix: prefix,
  ListRoute: `${prefix}/${ClassDataActions.List}/:classId`,
  List: `${prefix}/${ClassDataActions.List}`,
  ListSuspendedRoute: `${prefix}/${ClassDataActions.ListSuspended}/:classId`,
  ListSuspended: `${prefix}/${ClassDataActions.ListSuspended}`,
  EditRoute: `${prefix}/${ClassDataActions.Edit}/:studentInfoId`,
  Edit: `${prefix}/${ClassDataActions.Edit}`,
  CreateRoute: `${prefix}/${ClassDataActions.Create}/:classId`,
  Create: `${prefix}/${ClassDataActions.Create}`,
  Delete: `ClassData`
};
