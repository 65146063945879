document.fullscreenEnabled =
  document.fullscreenEnabled ||
  document.mozFullScreenEnabled ||
  document.documentElement.webkitRequestFullScreen;

function requestFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
  }
}

function toggleFullScreen(element) {
  if (!document.fullscreenEnabled) {
    return;
  }
  if (!document.fullscreenElement) {
    requestFullscreen(element);
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
}

function isFullScreen() {
  return document.fullscreenElement !== null;
}

export { requestFullscreen, toggleFullScreen, isFullScreen };
