import React, { Component } from 'react';
import clsx from 'clsx';
import { Paper, Typography } from '@material-ui/core';
import { PieArcSeries, PieChart } from 'reaviz';

export class PieChartView extends Component {
  render() {
    const { title, subtitle, chartData, classes } = this.props;

    return (
      <Paper className={clsx(classes.chartBox, classes.chartBoxEqualHeight)}>
        <Typography
          variant="h3"
          className={clsx(classes.heading, classes.center)}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          className={clsx(
            classes.subtitle,
            classes.center,
            classes.subtitleMargin
          )}
        >
          {subtitle}
        </Typography>
        <div className={classes.chartWrap} style={{ marginTop: 25 }}>
          <div
            style={{
              position: 'relative',
              height: '200px',
              width: '200px',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto'
            }}
          >
            <div style={{ position: 'absolute', top: 0, left: 0 }}>
              <PieChart
                disabled={true}
                width={200}
                height={200}
                data={chartData}
                series={
                  <PieArcSeries
                    doughnut={true}
                    label={null}
                    colorScheme={['#42a5f6', '#ced5e1']}
                  />
                }
              />
            </div>
            <h2 style={{ margin: '0 5px', padding: 0 }}>
              {chartData[0].data}
              {'%'}
            </h2>
          </div>
        </div>
      </Paper>
    );
  }
}
