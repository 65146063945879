import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { HtmlUtils } from '../../common/utils/HtmlUtils';

const styleCollapse = theme => ({
  spanMore: {
    fontSize: 12,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontFamily: 'RalewaySemiBold',
    marginLeft: '5px'
  }
});

const CollapseContent = props => {
  const { content, classes, index } = props;
  const [expanded, setExpanded] = React.useState(null);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  const renderText = content ? '' + content : '';
  return content ? (
    renderText.replace('<br/>', '').length > index ? (
      <span>
        <span
          dangerouslySetInnerHTML={HtmlUtils.createMarkup(
            !expanded ? renderText.substring(0, index) : renderText
          )}
        />
        <span className={classes.spanMore} onClick={handleExpandClick}>
          {!expanded ? '...more' : '«less'}
        </span>
      </span>
    ) : (
      <span dangerouslySetInnerHTML={HtmlUtils.createMarkup(renderText)} />
    )
  ) : (
    renderText
  );
};
export default withStyles(styleCollapse)(CollapseContent);
