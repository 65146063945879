import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { SingleLessonActions, SingleLessonPaths } from './SingleLessonConstans';

import { Autocomplete } from '@material-ui/lab';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimeUtils } from '../common/DateTimeUtils';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import SaveIcon from '@material-ui/icons/Save';
import { SingleLessonSelect } from './SingleLessonSelect';
import authService from '../api-authorization/AuthorizeService';
import classNames from 'classnames';
import { fetchHelper } from '../../helpers/fetch-helper';
import { withStyles } from '@material-ui/styles';

const stylesComp = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  },
  dropdownType: {
    marginBottom: '25px'
  },
  fab: {
    margin: '12px 8px 8px 8px'
  },
  formControl: {
    marginLeft: '15px'
  },
  fullWidth: {
    width: '100%'
  }
});

class EditSingleLessonComp extends Component {
  constructor(...args) {
    super(...args);
    this.form = React.createRef();
    this.studentRef = React.createRef();
    this.state = {
      redirect: null,
      method: 'PUT',
      openDialog: false,
      singleLessonId: this.props.singleLessonId,
      singleLesson: {
        name: '',
        type: 1,
        roomId: '',
        branchId: '',
        startTime: new Date(),
        endTime: new Date(),
        studentIds: [],
        teacherIds: []
      },
      actionColName: '',
      selectedStudents: [],
      selectedTeachers: [],
      students: [],
      teachers: [],
      rooms: [],
      branchs: [],
      isApproved: false
    };
    switch (this.props.action) {
      case SingleLessonActions.Create:
        this.state.method = 'POST';
        this.state.action = `api/SingleLessons`;
        this.state.loading = false;
        break;
      case SingleLessonActions.Edit:
        this.state.action = `api/SingleLessons/${this.state.singleLessonId}`;
        this.populateDataInit();
        break;
      case undefined:
      default:
    }
  }

  componentDidMount = async () => {
    await this.getCheckAproved();
    this.getAssignedBranch();
    this.getClassRoomsByBranch();
    this.getStudents();
    this.getTeachers();
  };

  populateDataInit = async () => {
    const { students, teachers } = this.state;
    const res = await fetchHelper.get(
      `api/SingleLessons/${this.state.singleLessonId}`
    );
    this.setState({
      singleLesson: res.body,
      selectedStudents: students.filter(x =>
        res.body.studentIds.includes(x.id)
      ),
      selectedTeachers: teachers.filter(x =>
        res.body.teacherIds.includes(x.id)
      ),
      loading: false
    });
  };

  submitData = async () => {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    this.handleSubmit();
  };

  backToList = () => {
    this.redirectTo(`${SingleLessonPaths.List}`);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  handleSubmit = () => {
    const { singleLesson, method, action, token } = this.state;
    singleLesson.studentIds = this.state.selectedStudents.map(x => x.id);
    singleLesson.teacherIds = this.state.selectedTeachers.map(x => x.id);
    singleLesson.startTime = DateTimeUtils.toDateTimeJSON(
      singleLesson.startTime
    );
    singleLesson.endTime = DateTimeUtils.toDateTimeJSON(singleLesson.endTime);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    fetch(action, {
      method: method,
      body: JSON.stringify(singleLesson),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  getStudents = async () => {
    try {
      const students = await fetchHelper.get(`api/Student/branch`);
      this.setState(prevState => ({
        selectedStudents: students.filter(x =>
          prevState.singleLesson.studentIds.includes(x.id)
        ),
        students
      }));
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  getTeachers = async () => {
    try {
      const teachers = await fetchHelper.get(`api/AspNetUsers/teacher/branch`);
      this.setState({
        selectedTeachers: teachers.filter(x =>
          this.state.singleLesson.teacherIds.includes(x.id)
        ),
        teachers
      });
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  };

  getClassRoomsByBranch = async () => {
    try {
      const rooms = await fetchHelper.get(`api/ClassRooms/campus`);
      this.setState({ rooms });
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  };

  getAssignedBranch = async () => {
    try {
      const branchs = await fetchHelper.get(`api/Campus/assigned`);
      this.setState({ branchs });
    } catch (error) {
      console.error('Error fetching branchs:', error);
    }
  };

  getCheckAproved = async () => {
    try {
      const res = await fetchHelper.get(
        `api/SingleLessons/${this.state.singleLessonId}/check-approved`
      );
      this.setState({ isApproved: res.body });
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  };

  openDialog = () => {
    this.setState({ openDialog: true });
  };

  closeDialog = () => {
    this.setState({ openDialog: false });
  };

  handleDateChange = name => date => {
    const attrName = name;
    const attrValue = date;
    this.setState(prevState => ({
      singleLesson: {
        ...prevState.singleLesson,
        [attrName]: attrValue
      }
    }));
  };

  onUnselectedStudent = student => {
    const { selectedStudents } = this.state;
    const index = selectedStudents.findIndex(x => x.id === student.id);
    if (index !== -1) {
      selectedStudents.splice(index, 1);
    }
    this.setState({ selectedStudents });
  };

  onSelectedStudent = student => {
    const { selectedStudents } = this.state;
    const index = selectedStudents.findIndex(x => x.id === student.id);

    if (index === -1) {
      selectedStudents.push(student);
      this.setState({ selectedStudents });
    }
  };

  onUnselectedTeacher = teacher => {
    const { selectedTeachers } = this.state;
    const index = selectedTeachers.findIndex(x => x.id === teacher.id);
    if (index !== -1) {
      selectedTeachers.splice(index, 1);
    }
    this.setState({ selectedTeachers });
  };

  onSelectedTeacher = teacher => {
    const { selectedTeachers } = this.state;
    const index = selectedTeachers.findIndex(x => x.id === teacher.id);

    if (index === -1) {
      selectedTeachers.push(teacher);
      this.setState({ selectedTeachers });
    }
  };

  onSelectChanged = event => {
    const target = event.target;
    this.setState(prevState => ({
      singleLesson: {
        ...prevState.singleLesson,
        [target.name]:
          target.type === 'checkbox' ? target.checked : target.value
      }
    }));
  };

  onBranchChanged = e => {
    // reset room
    this.setState(prevState => ({
      selectedStudents: [],
      selectedTeachers: [],
      singleLesson: {
        ...prevState.singleLesson,
        roomId: '',
        branchId: e.target.value
      }
    }));
  };

  renderForm = () => {
    const { classes } = this.props;
    const {
      singleLesson,
      students,
      selectedStudents,
      selectedTeachers,
      teachers,
      branchs,
      rooms,
      isApproved
    } = this.state;

    const { branchId } = singleLesson;
    const roomsFiltered = rooms.filter(x => x.campusId === branchId);

    const studentCols = [
      { name: 'studentName', header: 'Name', align: 'right' },
      { name: 'englishName', header: 'English Name', align: 'right' }
    ];

    const teacherCols = [
      { name: 'fullName', header: 'Name', align: 'right' },
      { name: 'englishName', header: 'English Name', align: 'right' }
    ];

    return (
      <Fragment>
        <Paper className={classes.root}>
          <form
            ref={this.form}
            className={classes.container}
            onSubmit={this.handleSubmit}
          >
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Grid item xs={10}>
                  <TextField
                    id="name"
                    name="name"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label="Name of Lesson"
                    value={singleLesson.name}
                    onChange={this.onSelectChanged}
                    disabled={isApproved}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <FormControl
                      className={classNames(
                        classes.margin,
                        classes.fullWidth,
                        classes.dropdownType
                      )}
                    >
                      <InputLabel htmlFor="branch">Select Branch</InputLabel>
                      <Select
                        id="branch"
                        name="branchId"
                        value={singleLesson.branchId}
                        onChange={this.onBranchChanged}
                        disabled={isApproved}
                      >
                        {branchs.map(row => {
                          return (
                            <MenuItem value={row.id} key={row.id}>
                              {row.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl
                      className={classNames(
                        classes.margin,
                        classes.fullWidth,
                        classes.dropdownType
                      )}
                    >
                      <InputLabel htmlFor="roomId">Select Room</InputLabel>
                      <Select
                        id="roomId"
                        name="roomId"
                        value={singleLesson.roomId}
                        onChange={this.onSelectChanged}
                        disabled={isApproved}
                      >
                        {roomsFiltered &&
                          roomsFiltered.map(row => {
                            return (
                              <MenuItem value={row.id} key={row.id}>
                                {row.roomNumber} - {row.name} ({row.campus.name}
                                )
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={10}>
                  {!isApproved && (
                    <FormControlLabel
                      labelPlacement="end"
                      style={{ width: '100%', marginLeft: 6, marginBottom: 10 }}
                      control={
                        <Autocomplete
                          style={{ width: '100%' }}
                          options={
                            students.filter(x =>
                              x.branchs.includes(branchId)
                            ) || []
                          }
                          getOptionLabel={option =>
                            option.englishName && option.studentName
                              ? `${option.englishName} - ${option.studentName}`
                              : option.englishName || option.studentName
                          }
                          onChange={(e, student) =>
                            this.onSelectedStudent(student)
                          }
                          disabled={isApproved}
                          renderInput={params => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                shrink: true
                              }}
                              label="Select Students"
                              margin="normal"
                              variant="outlined"
                            />
                          )}
                        />
                      }
                    />
                  )}
                </Grid>
                {selectedStudents.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    style={{ overflowX: 'auto', maxHeight: 500 }}
                  >
                    <SingleLessonSelect
                      cols={studentCols}
                      onUnselected={this.onUnselectedStudent}
                      data={selectedStudents}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={6}>
                <Grid>
                  <FormControl
                    className={classNames(
                      classes.margin,
                      classes.textField,
                      classes.dropdownType
                    )}
                  >
                    <InputLabel htmlFor="type">Type of Lesson</InputLabel>
                    <Select
                      id="type"
                      name="type"
                      value={singleLesson.type ?? 1}
                      onChange={this.onSelectChanged}
                      disabled={isApproved}
                    >
                      <MenuItem value={1}>CatchUp</MenuItem>
                      <MenuItem value={2}>WorkShop</MenuItem>
                      <MenuItem value={3}>Online</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid xs={10} spacing={1} container>
                      <Grid item xs={6}>
                        <DateTimePicker
                          margin="normal"
                          label="Start time"
                          format="dd/MM/yyyy HH:mm"
                          value={singleLesson.startTime}
                          onChange={this.handleDateChange('startTime')}
                          disabled={isApproved}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DateTimePicker
                          margin="normal"
                          format="dd/MM/yyyy HH:mm"
                          label="End time"
                          value={singleLesson.endTime}
                          onChange={this.handleDateChange('endTime')}
                          disabled={isApproved}
                        />
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={10}>
                  {!isApproved && (
                    <FormControlLabel
                      labelPlacement="end"
                      style={{ width: '100%', marginLeft: 6, marginBottom: 10 }}
                      control={
                        <Autocomplete
                          style={{ width: '100%' }}
                          options={
                            teachers.filter(x =>
                              x.branchs.includes(branchId)
                            ) || []
                          }
                          getOptionLabel={option =>
                            option.englishName && option.fullName
                              ? `${option.englishName} - ${option.fullName}`
                              : option.englishName || option.fullName
                          }
                          onChange={(e, teacher) =>
                            this.onSelectedTeacher(teacher)
                          }
                          disabled={isApproved}
                          renderInput={params => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                shrink: true
                              }}
                              label="Select Teachers"
                              margin="normal"
                              variant="outlined"
                            />
                          )}
                        />
                      }
                    />
                  )}
                </Grid>
                {selectedTeachers.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    style={{ overflowX: 'auto', maxHeight: 500 }}
                  >
                    <SingleLessonSelect
                      cols={teacherCols}
                      onUnselected={this.onUnselectedTeacher}
                      data={selectedTeachers}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Fragment>
    );
  };

  render() {
    let contents = this.renderForm();
    const { classes } = this.props;
    return (
      <Fragment>
        {contents}
        <Fragment>
          {!this.state.isApproved && (
            <Fab
              aria-label="Save"
              color="primary"
              className={classes.fab}
              onClick={this.submitData}
            >
              <SaveIcon />
            </Fab>
          )}
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.backToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
        {this.state.redirect}
      </Fragment>
    );
  }
}

EditSingleLessonComp.propTypes = {
  classes: PropTypes.object.isRequired,
  singleLessonId: PropTypes.string
};

export const EditSingleLesson = withStyles(stylesComp)(EditSingleLessonComp);
