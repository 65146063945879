import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@material-ui/core';
import { Label } from 'semantic-ui-react';
import authService from '../../api-authorization/AuthorizeService';
import { Loading } from '../../ui/Loading';
import { UserPaths, UserActions } from './UserConstants';
import { Roles } from '../../common/constants/Roles';

const stylesEditUser = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  margin: {
    margin: theme.spacing(1)
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  textField: {
    width: 300
  },
  longTextField: {
    width: '100%'
  }
});

export const ROLES_HAVE_BRANCH = [
  'SALE EXECUTIVE',
  'SALE MANAGER',
  'ACAD MANAGER',
  'BRANCH ADMIN',
  Roles.Teacher.toUpperCase()
];

class EditUserComp extends Component {
  static displayName = EditUserComp.name;

  constructor(...args) {
    super(...args);
    this.userForm = React.createRef();
    this.state = {
      action: this.props.action,
      userId: this.props.userId,
      method: 'PUT',
      userObj: {},
      lstRole: [],
      selectedCampus: [],
      redirect: null,
      loading: true
    };
    switch (this.props.action) {
      case UserActions.Create:
        this.state.userObj = {
          titleName: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          employeeCode: '',
          roleId: '',
          campusId: '',
          isExpat: false
        };
        this.state.method = 'POST';
        this.state.action = `api/AspNetUsers`;
        this.populateRelatedData();
        break;
      case UserActions.Edit:
        this.state.action = `api/AspNetUsers/${this.state.userId}`;
        this.populateUserData();
        break;
      case undefined:
      default:
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  async populateRelatedData() {
    const token = await authService.getAccessToken();

    const [respRole, respCampus] = await Promise.all([
      fetch(`api/AspNetRoles`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/Campus/GetCampusList/0`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [dataRole, dataCampus] = await Promise.all([
      respRole.json(),
      respCampus.json()
    ]);

    const roleMap = dataRole.reduce((map, obj) => {
      map[obj.id] = obj;
      return map;
    }, {});
    const { userObj } = this.state;
    const userAssignedCampus = userObj.assignedCampus
      ? JSON.parse(userObj.assignedCampus)
      : [];
    const selectedCampus = dataCampus.map(e => {
      return userAssignedCampus && userAssignedCampus.includes(e.id)
        ? true
        : false;
    });

    this.setState({
      lstRole: dataRole,
      roleMap,
      dataCampus,
      selectedCampus,
      loading: false
    });
  }

  async populateUserData() {
    const token = await authService.getAccessToken();
    const { userId } = this.state;
    const response = await fetch(`api/AspNetUsers/${userId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const userObj = await response.json();
    this.setState({ userObj }, this.populateRelatedData);
  }

  handleChange = name => event => {
    const attrName = name;
    const attrValue =
      name === 'isExpat' ? event.target.checked : event.target.value;
    this.setState(prevState => ({
      userObj: {
        ...prevState.userObj,
        [attrName]: attrValue
      }
    }));
  };

  backToList() {
    this.redirectTo(UserPaths.List);
  }

  async submitData() {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    ReactDOM.findDOMNode(this.userForm.current).dispatchEvent(
      new Event('submit')
    );
  }

  handleSubmit = e => {
    e.preventDefault();

    const {
      userObj,
      roleMap,
      dataCampus,
      selectedCampus,
      method,
      action,
      token
    } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    if (ROLES_HAVE_BRANCH.includes(roleMap[userObj.roleId]?.normalizedName)) {
      let assignedCampus = dataCampus.reduce((map, e, idx) => {
        if (selectedCampus[idx]) {
          map.push(e.id);
        }
        return map;
      }, []);
      userObj.assignedCampus = JSON.stringify(assignedCampus);
      userObj.campusId = '';
    } else {
      userObj.assignedCampus = '';
      userObj.campusId = '';
    }

    fetch(action, {
      method: method,
      body: JSON.stringify(userObj),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  toggleCheckboxValue = index => {
    this.setState(prevState => ({
      selectedCampus: prevState.selectedCampus.map((v, i) =>
        i === index ? !v : v
      )
    }));
  };

  renderForm() {
    const { classes } = this.props;
    const {
      userObj,
      lstRole,
      roleMap,
      dataCampus,
      selectedCampus
    } = this.state;

    const cols = [
      {
        name: 'email',
        header: 'Email',
        placeholder: 'Email',
        adornment: ''
      },
      {
        name: 'employeeCode',
        header: 'Employee Code',
        placeholder: 'Employee Code',
        adornment: ''
      },
      {
        name: 'firstName',
        header: 'First Name',
        placeholder: 'First Name',
        adornment: ''
      },
      {
        name: 'lastName',
        header: 'Last Name',
        placeholder: 'Last Name',
        adornment: ''
      },
      {
        name: 'phoneNumber',
        header: 'Phone Number',
        placeholder: 'Phone Number',
        adornment: ''
      },
      {
        name: 'roleId',
        header: 'Role',
        placeholder: 'Role',
        adornment: ''
      },
      {
        name: 'isExpat',
        header: 'Expat',
        placeholder: 'Expat',
        adornment: ''
      }
    ];

    return (
      <Paper className={classes.root}>
        <form
          ref={this.userForm}
          className={classes.container}
          onSubmit={this.handleSubmit}
        >
          <Grid container>
            <Grid item xs={4}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                  >
                    <InputLabel htmlFor="role-id">{cols[5].header}</InputLabel>
                    <Select
                      id="role-id"
                      name={cols[5].name}
                      value={userObj[cols[5].name]}
                      onChange={this.handleChange(cols[5].name)}
                    >
                      {lstRole.map(row => {
                        return (
                          <MenuItem value={row.id} key={row.id}>
                            {row.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </ListItem>
                {userObj.roleId &&
                ROLES_HAVE_BRANCH.includes(
                  roleMap[userObj.roleId]?.normalizedName
                ) ? (
                  <ListItem className={classes.listItem}>
                    <FormControl
                      className={clsx(classes.margin, classes.textField)}
                    >
                      <Label style={{ marginBottom: 8 }}>
                        Campus Assignment
                      </Label>
                      <ul>
                        {dataCampus.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  key={index}
                                  checked={selectedCampus[index]}
                                  onClick={() =>
                                    this.toggleCheckboxValue(index)
                                  }
                                ></Checkbox>
                              }
                              label={item.address}
                            />
                          </MenuItem>
                        ))}
                      </ul>
                    </FormControl>
                  </ListItem>
                ) : null}

                <ListItem className={classes.listItem}>
                  <TextField
                    id="emloyeeCode"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[1].header}
                    placeholder={cols[1].placeholder}
                    name={cols[1].name}
                    value={userObj[cols[1].name]}
                    onChange={this.handleChange(cols[1].name)}
                  />
                </ListItem>

                {roleMap[userObj.roleId]?.normalizedName === 'TEACHER' && (
                  <ListItem className={classes.listItem}>
                    <FormControl
                      className={clsx(classes.margin, classes.textField)}
                    >
                      <FormControlLabel
                        name={cols[6].name}
                        control={
                          <Checkbox
                            checked={userObj[cols[6].name]}
                            onChange={this.handleChange(cols[6].name)}
                          ></Checkbox>
                        }
                        label={cols[6].header}
                      />
                    </FormControl>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item xs={5}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                  >
                    <InputLabel>Title</InputLabel>
                    <Select
                      name="titleName"
                      value={userObj.titleName}
                      onChange={this.handleChange('titleName')}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="Mrs.">Mrs.</MenuItem>
                      <MenuItem value="Ms.">Ms.</MenuItem>
                      <MenuItem value="Mr.">Mr.</MenuItem>
                    </Select>
                  </FormControl>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="firstName"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[2].header}
                    placeholder={cols[2].placeholder}
                    name={cols[2].name}
                    value={userObj[cols[2].name]}
                    onChange={this.handleChange(cols[2].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="lastName"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[3].header}
                    placeholder={cols[3].placeholder}
                    name={cols[3].name}
                    value={userObj[cols[3].name]}
                    onChange={this.handleChange(cols[3].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="email"
                    fullWidth
                    type="email"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[0].header}
                    placeholder={cols[0].placeholder}
                    name={cols[0].name}
                    value={userObj[cols[0].name]}
                    onChange={this.handleChange(cols[0].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                  >
                    <InputLabel htmlFor="phoneNumber">
                      {cols[4].header}
                    </InputLabel>
                    <Input
                      id="phoneNumber"
                      name={cols[4].name}
                      value={userObj[cols[4].name]}
                      onChange={this.handleChange(cols[4].name)}
                      endAdornment={
                        <InputAdornment position="end">
                          {cols[4].adornment}
                        </InputAdornment>
                      }
                      inputProps={{
                        'aria-label': cols[4].header
                      }}
                    />
                  </FormControl>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </form>
        {this.state.redirect}
      </Paper>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderForm();

    return <Fragment>{contents}</Fragment>;
  }
}

EditUserComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const EditUser = withStyles(stylesEditUser)(EditUserComp);
