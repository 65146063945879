import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

import authService from '../../api-authorization/AuthorizeService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const stylesMenu = theme => ({
  root: {
    marginBottom: theme.spacing(1)
  },
  heading: {
    flexShrink: 0
  }
});

class GeeOMenuComp extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isAuthenticated: false,
      show: false,
      loggedInUser: {},
      userInfo: {},
      userRoles: [],
      expanded: this.props.expanded
    };
  }

  componentDidMount() {
    this.populateUserState();
  }

  async populateUserState() {
    const [isAuthenticated] = await Promise.all([authService.isLoggedIn()]);
    this.setState({
      isAuthenticated
    });
    if (isAuthenticated) {
      const [user, token] = await Promise.all([
        authService.getUser(),
        authService.getAccessToken()
      ]);
      this.setState({
        loggedInUser: user
      });
      this.populateUserRole(user, token);
    }
  }
  async populateUserRole(user, token) {
    const response = await fetch(`api/AspNetUsers/GetUserRole/${user.sub}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const userInfo = await response.json();
    const userRoles = userInfo ? new Array(userInfo.role.name) : [];
    this.setState({ userInfo, userRoles });
    this.populateShowState(userRoles);
  }
  populateShowState(userRoles) {
    const { menuItems } = this.props;
    menuItems.forEach(menuItem => {
      const accessRight = role => menuItem.roles.includes(role);
      const show = userRoles.some(accessRight);
      if (show) {
        this.setState({ show });
        return;
      }
    });
  }

  handleChange = () => {
    this.setState(prevState => ({ expanded: !prevState.expanded }));
  };

  render() {
    const { caption, menuItems, classes } = this.props;
    const { show, expanded, userRoles } = this.state;
    return (
      show && (
        <Fragment>
          <Divider />
          <div className={classes.root}>
            <ListItem button onClick={this.handleChange}>
              <ListItemIcon>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemIcon>
              <ListItemText className={classes.heading}>
                <Typography color="textSecondary" variant="caption">
                  {caption}
                </Typography>
              </ListItemText>
            </ListItem>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {menuItems.map(menuItem => (
                  <GeeOMenuItem
                    key={menuItem.link}
                    content={menuItem}
                    userRoles={userRoles}
                  />
                ))}
              </List>
            </Collapse>
          </div>
        </Fragment>
      )
    );
  }
}

class GeeOMenuItem extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      show: false
    };
  }

  componentDidMount() {
    this.populateShowState();
  }

  populateShowState() {
    const { userRoles, content } = this.props;
    const accessRight = role => content.roles.includes(role);
    const show = userRoles.some(accessRight);
    this.setState({ show });
  }

  render() {
    const { content } = this.props;
    const { show } = this.state;
    return (
      show && (
        <ListItem button component={Link} to={content.link}>
          <ListItemIcon>
            {content.icon_fa ? (
              <FontAwesomeIcon icon={content.icon_fa} size="lg" />
            ) : (
              <Icon>{content.icon}</Icon>
            )}
          </ListItemIcon>
          <ListItemText primary={content.text} />
        </ListItem>
      )
    );
  }
}

GeeOMenuComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const GeeOMenu = withStyles(stylesMenu)(GeeOMenuComp);
