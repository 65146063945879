import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import LiveClass from './LiveClass';
import { LiveClassPaths, LiveClassActions } from './LiveClassConstants';

export default class LiveClassRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={LiveClassPaths.Home}
          component={LiveClass}
          action={LiveClassActions.Home}
        />
        <PropsRoute
          path={LiveClassPaths.RoomRoute}
          component={LiveClass}
          action={LiveClassActions.Room}
        />
      </Fragment>
    );
  }
}
