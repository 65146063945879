import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { AdminPage } from '../../ui/page/AdminPage';
import { ClassReportActions } from './ClassReportConstants';
import { ListClassLog } from './ListClassLog';
import { ViewReport } from './ViewReport';

const stylesClassReport = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class ClassReportComp extends Component {
  static displayName = ClassReportComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      logId: this.props.match.params.logId,
      classId: this.props.match.params.classId,
      classLessonId: this.props.match.params.classLessonId,
      content: null,
      action: null,
      pageTitle: ''
    };
    switch (this.props.action) {
      case ClassReportActions.List:
      case ClassReportActions.MyList:
        this.state.pageTitle =
          this.props.action === ClassReportActions.List
            ? 'Class Reports'
            : 'My Class Reports';
        this.state.content = <ListClassLog action={this.props.action} />;
        break;
      case ClassReportActions.View:
      case ClassReportActions.ViewMyReport:
      case ClassReportActions.ViewLessonReport:
        this.state.pageTitle = 'Class Report';
        let isViewLesson =
          this.props.action === ClassReportActions.ViewLessonReport
            ? true
            : false;
        this.state.content = (
          <ViewReport
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            logId={this.state.logId}
            classId={this.state.classId}
            classLessonId={this.state.classLessonId}
            isViewLesson={isViewLesson}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  handleChildBackToList = () => {
    this.child.backToList();
  };

  render() {
    const { classes, action } = this.props;
    const actions = (action === ClassReportActions.View ||
      action === ClassReportActions.ViewMyReport ||
      action === ClassReportActions.ViewLessonReport) && (
      <Fragment>
        <Fab
          aria-label="Cancel"
          className={classes.fab}
          onClick={this.handleChildBackToList}
        >
          <CloseOutlinedIcon color="action" />
        </Fab>
      </Fragment>
    );

    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
      </Fragment>
    );
  }
}

ClassReportComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ClassReport = withStyles(stylesClassReport)(ClassReportComp);
