import 'date-fns';
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Loading } from '../ui/Loading';
import { ClassCoursePaths, ClassCourseActions } from './ClassCourseConstants';

const stylesEditClass = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'end'
  },
  margin: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(3, 3, 3, 5)
  },
  legend: {
    marginBottom: theme.spacing(2)
  },
  textField: {
    width: 300
  }
});

class EditClassCourseComp extends Component {
  static displayName = EditClassCourseComp.name;
  constructor(...args) {
    super(...args);
    this.levelForm = React.createRef();
    this.state = {
      action: this.props.action,
      classId: this.props.classId,
      method: 'PUT',
      classCourse: {},
      studyLevels: [],
      campuses: [],
      redirect: null,
      loading: true,
      loadingLevels: true
    };
    switch (this.props.action) {
      case ClassCourseActions.Create:
        this.state.classCourse = {
          levelId: '',
          campusId: '',
          name: '',
          description: '',
          userId: '',
          numberOfPupils: ''
        };
        this.state.method = 'POST';
        this.state.action = `api/ClassCourses`;
        this.state.loading = false;
        break;
      case ClassCourseActions.Edit:
        this.state.action = `api/ClassCourses/${this.state.classId}`;
        this.populateClassCourseData();
        break;
      case undefined:
      default:
    }
  }
  componentDidMount() {
    this.props.onRef(this);
    this.populateLevelData();
  }

  async populateLevelData() {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);

    const [respLevels, respCampus] = await Promise.all([
      fetch(`api/StudyLevels`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/Campus/GetCampusList/${loggedInUser.sub}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [studyLevels, campuses] = await Promise.all([
      respLevels.json(),
      respCampus.json()
    ]);

    this.setState({ studyLevels, campuses, loadingLevels: false });
  }

  async populateClassCourseData() {
    const token = await authService.getAccessToken();
    const { classId } = this.state;

    const [respClass] = await Promise.all([
      fetch(`api/ClassCourses/${classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [classCourse] = await Promise.all([respClass.json()]);

    this.setState({ classCourse, loading: false });
  }

  handleChange = name => event => {
    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;
    this.setState(prevState => ({
      classCourse: {
        ...prevState.classCourse,
        [attrName]: attrValue
      }
    }));
  };

  handleDateChange = name => date => {
    const attrName = name;
    const attrValue = date;
    this.setState(prevState => ({
      classCourse: {
        ...prevState.classCourse,
        [attrName]: attrValue
      }
    }));
  };

  backToList() {
    this.redirectTo(ClassCoursePaths.List);
  }

  async submitData() {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    ReactDOM.findDOMNode(this.levelForm.current).dispatchEvent(
      new Event('submit')
    );
  }

  handleSubmit = e => {
    e.preventDefault();

    const { classCourse, method, action, token } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const formBody = JSON.stringify(classCourse);
    fetch(action, {
      method: method,
      body: formBody,
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  renderForm() {
    const { classes } = this.props;
    const { classCourse, studyLevels, campuses, loadingLevels } = this.state;
    const cols = [
      {
        name: 'levelId',
        header: 'Level',
        placeholder: 'Level',
        adornment: ''
      },
      {
        name: 'name',
        header: 'Name',
        placeholder: 'Name',
        adornment: ''
      },
      {
        name: 'description',
        header: 'Description',
        placeholder: 'Description',
        adornment: ''
      }
    ];
    return (
      <Paper className={classes.root}>
        <form
          ref={this.levelForm}
          className={classes.container}
          onSubmit={this.handleSubmit}
        >
          <Grid container justify="space-around">
            <Grid item xs={6}>
              <List className={classes.list}>
                {loadingLevels ? (
                  <Loading />
                ) : (
                  <Fragment>
                    <ListItem className={classes.listItem}>
                      <FormControl
                        className={classNames(
                          classes.margin,
                          classes.textField
                        )}
                      >
                        <InputLabel htmlFor="level-id">
                          {cols[0].header}
                        </InputLabel>
                        <Select
                          id="level-id"
                          name={cols[0].name}
                          value={classCourse[cols[0].name]}
                          onChange={this.handleChange(cols[0].name)}
                        >
                          {studyLevels.map(row => {
                            return (
                              <MenuItem value={row.id} key={row.id}>
                                {row.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <FormControl
                        className={classNames(
                          classes.margin,
                          classes.textField
                        )}
                      >
                        <InputLabel htmlFor="campus-id">Branch</InputLabel>
                        <Select
                          id="campus-id"
                          name="campusId"
                          value={classCourse.campusId}
                          onChange={this.handleChange('campusId')}
                        >
                          {campuses.map(row => {
                            return (
                              <MenuItem value={row.id} key={row.id}>
                                {row.address}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </ListItem>
                  </Fragment>
                )}
                <ListItem className={classes.listItem}>
                  <TextField
                    id="name"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[1].header}
                    placeholder={cols[1].placeholder}
                    name={cols[1].name}
                    value={classCourse[cols[1].name]}
                    onChange={this.handleChange(cols[1].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="description"
                    fullWidth
                    multiline
                    rows="6"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[2].header}
                    placeholder={cols[2].placeholder}
                    name={cols[2].name}
                    value={classCourse[cols[2].name] || ''}
                    onChange={this.handleChange(cols[2].name)}
                  />
                </ListItem>
                {/* <ListItem className={classes.listItem}>
                  <TextField
                    id="numberOfPupils"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[3].header}
                    placeholder={cols[3].placeholder}
                    name={cols[3].name}
                    value={classCourse[cols[3].name]}
                    onChange={this.handleChange(cols[3].name)}
                  />
                </ListItem> */}
              </List>
            </Grid>
            <Grid item xs={6}>
              <List className={classes.list} />
            </Grid>
          </Grid>
        </form>
        {this.state.redirect}
      </Paper>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderForm();
    return <Fragment>{contents}</Fragment>;
  }
}

EditClassCourseComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const EditClassCourse = withStyles(stylesEditClass)(EditClassCourseComp);
