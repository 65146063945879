import React, { Component } from 'react';

import CommonDataTable from '../ui/table/CommonDataTable';
import MaterialFilterToolbar from '../ui/table/MaterialFilterToolbar';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const stylesMaterialDataTable = theme => ({
  root: {
    // marginTop: theme.spacing(1),
  }
});

class MaterialDataTable extends Component {
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    this.props.onRef(this);
    this.child.setData(this.props.rows);
  }

  setTableData = (filterText, cats, all) => {
    const { rows } = this.props;
    const searchTerm = filterText.toLowerCase();
    let filteredRows = rows.filter(e => {
      const itemText = (e.name + e.description).toLowerCase();
      return itemText.indexOf(searchTerm) > -1;
    });
    filteredRows = filteredRows.filter(
      e =>
        all ||
        (cats.math === e.math &&
          cats.science === e.science &&
          cats.music === e.music &&
          cats.phonics === e.phonics &&
          cats.sightwords === e.sightwords &&
          cats.english === e.english &&
          cats.softSkills === e.softSkills &&
          cats.activityBook === e.activityBook &&
          cats.studentBook === e.studentBook &&
          cats.teacherPlanner === e.teacherPlanner &&
          cats.m0 === e.m0 &&
          cats.m1 === e.m1 &&
          cats.m2 === e.m2 &&
          cats.m3 === e.m3 &&
          cats.a === e.a &&
          cats.m === e.m &&
          cats.e === e.e &&
          cats.v === e.v &&
          cats.c === e.c &&
          cats.j === e.j &&
          cats.s === e.s &&
          cats.u === e.u)
    );
    this.child.setData(filteredRows);
  };

  updateChildData = data => {
    this.child.setData(data);
  };

  render() {
    const {
      classes,
      cols,
      actions,
      isPaging,
      isShowCheckbox,
      isShowPopup,
      eventReloadData,
      callbackFromParent,
      callbackGetIdShowPopup
    } = this.props;

    return (
      <Paper className={classes.root}>
        <MaterialFilterToolbar setTableData={this.setTableData} />
        <CommonDataTable
          onRef={actualChild => (this.child = actualChild)}
          cols={cols}
          actions={actions}
          isShowCheckbox={isShowCheckbox}
          isShowPopup={isShowPopup}
          eventReloadData={eventReloadData}
          callbackFromParent={callbackFromParent}
          callbackGetIdShowPopup={callbackGetIdShowPopup}
          isPaging={isPaging}
        />
      </Paper>
    );
  }
}

MaterialDataTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesMaterialDataTable)(MaterialDataTable);
