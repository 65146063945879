import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Fab from '@material-ui/core/Fab';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';
//import { StudentPaths } from './StudentConstans';
import CommonDataTable from '../ui/table/CommonDataTable';
import Paper from '@material-ui/core/Paper';
import ClassReportTable from '../report/class/ClassReportTable';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    overflowX: 'auto'
  },
  cell: {
    padding: theme.spacing(1, 0, 1, 2)
  },
  student: {
    margin: theme.spacing(1, 0, 0, 2)
  },
  fab: {
    margin: theme.spacing(2, 1, 1, 1)
  }
});

class ListViewLearningOutcomeComp extends Component {
  static displayName = ListViewLearningOutcomeComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      data: [],
      loading: true,
      studentId: this.props.studentId,
      student: {},
      dataCatchUpReport: [],
      redirect: null
    };
  }

  backToList = () => {
    //this.redirectTo(StudentPaths.List);
    this.props.history.goBack();
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  componentDidMount() {
    this.populateStudentData();
  }

  populateStudentData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch(
      `api/Student/GetStudentViewLearningOutcomes/${this.state.studentId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    const responseStudent = await fetch(
      `api/Student/GetInfoStudent/${this.state.studentId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const dataStudent = await responseStudent.json();

    const responseCatchUpReport = await fetch(
      `api/Student/GetReportStudentCatchUp/${this.state.studentId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const dataCatchUpReport = await responseCatchUpReport.json();
    this.setState({
      data: data,
      student: dataStudent,
      dataCatchUpReport: dataCatchUpReport,
      loading: false
    });
    this.setChildData();
  };

  setChildData = () => {
    const { dataCatchUpReport } = this.state;
    this.child.setData(dataCatchUpReport);
  };

  refreshStudent = async () => {
    await this.populateStudentData();
  };

  render() {
    const { classes } = this.props;
    const { student } = this.state;
    const colsInfo = [
      { name: 'studentName', header: 'Name', xs: 3 },
      { name: 'englishName', header: 'English Name', xs: 3 },
      { name: 'birthdayFormat', header: 'Birthday', xs: 2 },
      { name: 'fatherName', header: 'Father', xs: 2 },
      { name: 'motherName', header: 'Mother', xs: 2 }
    ];
    const classStudentCols = [
      { field: 'logDateTime', title: 'Date Time', align: 'left' },
      { field: 'level', title: 'Level', align: 'left' },
      { field: 'class', title: 'Class' },
      { field: 'lesson', title: 'Lesson', align: 'left' },
      { field: 'subject', title: 'Subject', align: 'left' },
      { field: 'starScore', title: 'Star Score', align: 'left' },
      {
        title: 'Present',
        field: 'present',
        align: 'center',
        render: rowData => (
          <Checkbox
            checked={rowData.present}
            disabled
            style={{ width: 36, height: 36 }}
            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
            checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
          />
        )
      }
    ];

    const colsReport = [
      { name: 'class', header: 'Class', align: 'left' },
      { name: 'level', header: 'Level', align: 'left' },
      { name: 'numberOfCatchUp', header: 'Number Of CatchUp', align: 'left' },
      {
        name: 'numberOfClassSession',
        header: 'Number Of Class Session',
        align: 'left'
      },
      {
        name: 'numberOfRemainingSession',
        header: 'Number Of Remaining Session',
        align: 'left'
      }
    ];

    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <Paper className={classes.root}>
              <Grid container>
                {colsInfo.map((col, idx) => (
                  <Grid item xs={col.xs} className={classes.cell} key={idx}>
                    <Typography color="textSecondary" variant="caption">
                      {col.header}
                    </Typography>
                    <Typography color="textPrimary" variant="body2">
                      {col.isObj ? student[col.name].name : student[col.name]}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Paper>
            <Paper>
              <CommonDataTable
                onRef={actualChild => (this.child = actualChild)}
                rows={this.state.dataCatchUpReport}
                cols={colsReport}
                actions={null}
                eventReloadData={this.refreshStudent}
              />
            </Paper>
            <Paper>
              <ClassReportTable
                rows={this.state.data}
                cols={classStudentCols}
              />
            </Paper>
            <Fab
              aria-label="Cancel"
              className={classes.fab}
              onClick={this.backToList}
            >
              <CloseOutlinedIcon color="action" />
            </Fab>
          </Fragment>
        )}
        {this.state.redirect}
      </Fragment>
    );
  }
}

ListViewLearningOutcomeComp.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired
};

export const ListViewLearningOutcome = withRouter(
  withStyles(styles)(ListViewLearningOutcomeComp)
);
