import React, { Component } from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import TablePaginationActions from '../ui/table/TablePaginationActions';
import { stylesDashboard } from './stylesDashboard';

class DemoStudents extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      demoStudents: [],
      page: 0,
      rowsPerPage: 10,
      loading: true
    };
  }

  componentDidMount() {
    this.getDemoStudents();
  }

  async getDemoStudents() {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const response = await fetch(
      `api/Student/GetDemoStudents/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const demoStudents = await response.json();

    this.setState({ demoStudents, loading: false });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  render() {
    const { classes } = this.props;
    const { demoStudents, rowsPerPage, page, loading } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, demoStudents.length - page * rowsPerPage);

    const cols = [
      { name: 'studentName', header: 'Name', align: 'left' },
      { name: 'englishName', header: 'English' },
      { name: 'birthdayFormat', header: 'Birthday', align: 'left' },
      { name: 'fatherName', header: 'Father', align: 'left' },
      { name: 'fatherPhone', header: 'Phone', align: 'left' },
      { name: 'motherName', header: 'Mother', align: 'left' },
      { name: 'motherPhone', header: 'Phone', align: 'left' },
      { name: 'beginDateDisplay', header: 'Begin Date', align: 'left' },
      { name: 'class', header: 'Class', align: 'left' }
    ];

    return (
      <Fragment>
        {loading ? (
          <Loading />
        ) : (
          <Paper>
            <div className={classes.toolbar}>
              <Typography
                className={clsx(
                  classes.boldText,
                  classes.margin,
                  classes.title
                )}
              >
                Students In Trial Period
              </Typography>
            </div>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {cols.map(col => (
                      <TableCell
                        key={col.name}
                        align={col.align}
                        className={clsx(classes.tableCell, classes.boldText)}
                      >
                        {col.header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {demoStudents
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <TableRow key={row.id}>
                        {cols.map(col => (
                          <TableCell
                            key={col.name}
                            align={col.align}
                            className={classes.tableCell}
                          >
                            {row[col.name]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 38 * emptyRows }}>
                      <TableCell colSpan={cols.length} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={demoStudents.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        )}
      </Fragment>
    );
  }
}

DemoStudents.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesDashboard)(DemoStudents);
