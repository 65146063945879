import React, { Component } from 'react';
import clsx from 'clsx';
import { Paper, Typography } from '@material-ui/core';
import Chart from 'react-apexcharts';

export class LessonChartView extends Component {
  render() {
    const { title, subtitle, chartOptions, chartSeries, classes } = this.props;

    return (
      <Paper className={classes.chartBox}>
        <Typography variant="h3" className={classes.heading}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          {subtitle}
        </Typography>
        <div style={{ overflowY: 'auto' }}>
          <div
            className={clsx(classes.chartWrap)}
            style={{
              width:
                chartOptions.xaxis.categories.length > 30
                  ? 30 * chartOptions.xaxis.categories.length
                  : '100%',
              height: 'auto',
              padding: '0 40px 10px 10px'
            }}
          >
            <Chart
              type="line"
              options={chartOptions}
              series={chartSeries}
              width="100%"
              height="300"
            />
          </div>
        </div>
      </Paper>
    );
  }
}
