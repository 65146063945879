export const StudyLevelActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete',
  Exam: 'exam'
};

const prefix = '/study-levels';

export const StudyLevelPaths = {
  StudyLevelPrefix: prefix,
  List: `${prefix}/${StudyLevelActions.List}`,
  EditRoute: `${prefix}/${StudyLevelActions.Edit}/:levelId`,
  Edit: `${prefix}/${StudyLevelActions.Edit}`,
  Create: `${prefix}/${StudyLevelActions.Create}`,
  Delete: `StudyLevels`,
  ExamRoute: `${prefix}/${StudyLevelActions.Exam}/:levelId`,
  Exam: `${prefix}/${StudyLevelActions.Exam}`
};
