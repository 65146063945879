import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../../common/route/PropsRoute';
import ClassEvent from './ClassEvent';
import { EventPaths, EventActions } from './EventConstants';

export default class EventRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={EventPaths.ListRoute}
          component={ClassEvent}
          action={EventActions.List}
        />
      </Fragment>
    );
  }
}
