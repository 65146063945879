import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

class ClassCourseInfo extends Component {
  render() {
    const { classes, teachers, classCourse } = this.props;

    if (typeof classCourse.schedule === 'string') {
      classCourse.scheduleFormat = classCourse.schedule;
      classCourse.name = classCourse.class;
    } else {
      classCourse.scheduleFormat = classCourse.schedule?.scheduleFormat ?? '';
      classCourse.startTimeLocal = classCourse.schedule?.startTimeLocal ?? '';
      classCourse.endTimeLocal = classCourse.schedule?.endTimeLocal ?? '';
      classCourse.level = classCourse.level?.name ?? '';
    }
    const cols = [
      {
        name: 'level',
        header: 'Level',
        xs: 2
      },
      {
        name: 'name',
        header: 'Class',
        xs: 3
      },
      {
        name: 'description',
        header: 'Description',
        xs: 3
      },
      { name: 'scheduleFormat', header: 'Schedule', xs: 2 },
      { name: 'startTimeLocal', header: 'Start time', xs: 1 },
      { name: 'endTimeLocal', header: 'End time', xs: 1 }
    ];

    return (
      <Paper className={classes.root}>
        {teachers && (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="baseline"
          >
            <Typography
              color="textSecondary"
              variant="subtitle1"
              className={classes.teachers}
            >
              Teachers:
            </Typography>
            <Typography
              color="textPrimary"
              variant="body2"
              className={classes.teachers}
            >
              {classCourse.teachers}
            </Typography>
          </Grid>
        )}
        <Grid container>
          {cols.map((col, idx) => (
            <Grid item xs={col.xs} className={classes.cell} key={idx}>
              <Typography color="textSecondary" variant="caption">
                {col.header}
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {classCourse[col.name]}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    overflowX: 'auto'
  },
  cell: {
    padding: theme.spacing(1, 0, 1, 2)
  },
  teachers: {
    margin: theme.spacing(1, 0, 0, 2)
  }
});

ClassCourseInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ClassCourseInfo);
