import { Grid, Typography } from '@material-ui/core';
import React, { Component, Fragment } from 'react';

import { BarChartViewStd } from './BarChartViewStd';
import { Loading } from '../../ui/Loading';
import { NumberUtils } from '../../common/NumberUtils';
import { PieChartView } from './PieChartView';
import PropTypes from 'prop-types';
import authService from '../../api-authorization/AuthorizeService';
import { stylesCharting } from '../../class-course/reports/stylesCharting';
import { withStyles } from '@material-ui/core/styles';

class StudentAppInfo extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    //this.populateStudentData();
  }

  populateStudentData = async () => {
    const { student, stdClass } = this.props;
    const token = await authService.getAccessToken();
    const [respExamResults] = await Promise.all([
      fetch(
        `api/AcademicManage/GetExamResults/${student.id}/${stdClass.classId}`,
        { headers: !token ? {} : { Authorization: `Bearer ${token}` } }
      )
    ]);
    const [examResultForms] = await Promise.all([respExamResults.json()]);

    const examResults = examResultForms.map(item => {
      return item;
    });

    this.setState({
      examResults,
      loading: false
    });
  };

  render() {
    const { classesBase, classes } = this.props;
    const { loading } = this.state;

    const ratioRV = NumberUtils.getRandomInt(100);
    const ratioRT = NumberUtils.getRandomInt(100);
    let dataRV = [
      {
        key: 'Review video watched',
        data: ratioRV
      },
      {
        key: '',
        data: 100 - ratioRV
      }
    ];
    let dataRT = [
      {
        key: 'Review test done',
        data: ratioRT
      },
      {
        key: '',
        data: 100 - ratioRT
      }
    ];
    const multiCategory = [];
    for (let i = 0; i < 16; i++) {
      multiCategory.push({
        key: 'Tuần ' + (i + 1),
        weekNum: i + 1,
        data: [
          {
            key: 'RV',
            data: NumberUtils.getRandomInt(100)
          },
          {
            key: 'RT',
            data: NumberUtils.getRandomInt(100)
          }
        ]
      });
    }

    return (
      <Fragment>
        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={2} className={classesBase.stdBox}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classesBase.stdBoxLabel}>
                Thông tin học sinh trên Gee-O eSLL app
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <PieChartView
                title="Review video watched"
                subtitle="Tỷ lệ học sinh xem video theo từng buổi"
                chartData={dataRV}
                classes={classes}
              />
            </Grid>
            <Grid item xs={2}>
              <PieChartView
                title="Review test done"
                subtitle="Tỷ lệ học sinh làm btvn và kết quả theo từng buổi"
                chartData={dataRT}
                classes={classes}
              />
            </Grid>
            <Grid item xs={8}>
              <BarChartViewStd
                title="Summary"
                subtitle="Tổng kết"
                chartData={multiCategory}
                classes={{ ...classes, ...classesBase }}
              />
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

const stylesAppInfo = theme => ({
  ...stylesCharting(theme)
});

StudentAppInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  classesBase: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
  stdClass: PropTypes.object.isRequired
};

export default withStyles(stylesAppInfo)(StudentAppInfo);
