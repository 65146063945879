import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { ListCatchUpSchedule } from './ListCatchUpSchedule';
import { ListStudentCatchUpSchedule } from './ListStudentCatchUp';
import {
  CatchUpScheduleActions,
  CatchUpSchedulePaths
} from './CatchUpScheduleContans';
import { AdminPage } from '../ui/page/AdminPage';
import { EditCatchUpSchedule } from './EditCatchUpSchedule';

const stylesTeacher = theme => ({
  fab: {
    margin: theme.spacing(1)
  }
});

class CatchUpScheduleComp extends Component {
  static displayName = CatchUpScheduleComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null,
      catchUpScheduleId: this.props.match.params.catchUpScheduleId,
      classLessonId: this.props.match.params.classLessonId,
      studentId: this.props.match.params.studentId
    };
    switch (this.props.action) {
      case CatchUpScheduleActions.List:
        this.state.pageTitle = 'Catch-Up';
        this.state.content = <ListCatchUpSchedule />;
        break;
      case CatchUpScheduleActions.ListStudentCatchUp:
        this.state.pageTitle = 'Absence Notice';
        this.state.content = <ListStudentCatchUpSchedule />;
        break;
      case CatchUpScheduleActions.Create:
        this.state.pageTitle = 'Create Catch-Up';
        this.state.content = (
          <EditCatchUpSchedule
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
          />
        );
        break;
      case CatchUpScheduleActions.Edit:
        this.state.pageTitle = 'Edit Catch-Up';
        this.state.content = (
          <EditCatchUpSchedule
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            classId={this.state.classId}
            catchUpScheduleId={this.state.catchUpScheduleId}
          />
        );
        break;
      case CatchUpScheduleActions.CreateCatchUpLesson:
        this.state.pageTitle = 'Create Catch-Up';
        this.state.content = (
          <EditCatchUpSchedule
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            isMySchedule={true}
            classLessonId={this.state.classLessonId}
          />
        );
        break;
      case CatchUpScheduleActions.CreateCatchUpStudent:
        this.state.pageTitle = 'Create Catch-Up';
        this.state.content = (
          <EditCatchUpSchedule
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            isMySchedule={true}
            studentId={this.state.studentId}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  backToList = () => {
    this.redirectTo(CatchUpSchedulePaths.List);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === 'list' ? (
        <Fragment>
          <Fab
            aria-label="Add"
            color="primary"
            className={classes.fab}
            onClick={() => this.redirectTo(`${CatchUpSchedulePaths.Create}`)}
          >
            <AddIcon />
          </Fab>
        </Fragment>
      ) : (
        ''
      );
    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

CatchUpScheduleComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const CatchUpSchedule = withStyles(stylesTeacher)(CatchUpScheduleComp);
