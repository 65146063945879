import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import PinchZoomPan from 'react-responsive-pinch-zoom-pan';

const stylesIframe = theme => ({
  root: {
    height: '100%',
    margin: theme.spacing(0, 0.25)
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  iframeForDownload: {
    height: 1
  },
  instructions: {
    paddingTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  downloadLink: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1),
    textDecoration: 'none'
  },
  imageViewer: {
    width: '100%',
    height: '100%',
    flex: 'auto',
    overflow: 'hidden',
    display: 'flex',
    textAlign: 'initial'
  },
  imageWrap: {
    flex: 'auto',
    overflow: 'hidden',
    position: 'relative'
  },
  zoom: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  iframe: {
    width: '100%',
    height: '100%',
    // position: 'absolute',
    zIndex: 999999,
    scrolling: 'yes',
    overflow: 'scroll',
    backgroundColor: theme.palette.common.white
  }
});

class Iframe extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      random: 0,
      title: '',
      srcUrl: ''
    };
  }

  populateDisplayType() {
    const { lessonUnit } = this.props;
    let title = lessonUnit.procedures;
    let srcUrl = null;
    let displayType = 'empty';
    let fileName = '';
    var imageTypes = ['jpg', 'png'];
    var zipHtmlTypes = ['zip'];
    var openWithAppTypes = [
      'flipchart',
      'ppt',
      'pptx',
      'doc',
      'docx',
      'xls',
      'xlsx'
    ];
    if (lessonUnit.materialFileName) {
      const fileExt = lessonUnit.materialFileName
        .slice(((lessonUnit.materialFileName.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase();
      if (openWithAppTypes.indexOf(fileExt) > -1) {
        displayType = 'file';
        fileName = lessonUnit.materialFileName;
      } else if (imageTypes.indexOf(fileExt) > -1) {
        displayType = 'image';
      } else if (zipHtmlTypes.indexOf(fileExt) > -1) {
        displayType = 'html';
      } else {
        displayType = 'media';
      }
      srcUrl = `/api/Materials/GetForView/${lessonUnit.materialId}/${encodeURI(
        lessonUnit.materialFileName
      )}`;
    } else if (lessonUnit.materialUrl) {
      displayType = 'url';
      const youtubeVId = this.getYoutubeVId(lessonUnit.materialUrl);
      srcUrl =
        youtubeVId === 'error'
          ? lessonUnit.materialUrl
          : `//www.youtube.com/embed/${youtubeVId}`;
    }
    const openNewTab = lessonUnit.openNewTab;
    return { title, displayType, srcUrl, fileName, openNewTab };
  }

  getYoutubeVId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return 'error';
    }
  }

  resetIframe(title, srcUrl) {
    this.setState({ random: this.state.random + 1, title, srcUrl });
  }

  render() {
    const { classes } = this.props;
    const {
      title,
      displayType,
      srcUrl,
      fileName,
      openNewTab
    } = this.populateDisplayType();
    const externalSites = [
      // '//www.kidsa-z.com',
      // '//www.abcmouse.com',
      // '//www.topmarks.co.uk',
      // '//www.splashlearn.com',
      // '//www.abcya.com',
      // '//wordwall.net',
      // '//www.turtlediary.com',
      // '//www.raz-plus.com'
    ];

    return (
      <div className={classes.root}>
        {displayType === 'empty' ? (
          <div />
        ) : displayType === 'file' ? (
          <div>
            <Typography variant="h5" className={classes.instructions}>
              Click on link below to open the file
            </Typography>
            <Link
              className={classes.downloadLink}
              to={'#'}
              onClick={() => {
                this.resetIframe(title, srcUrl);
              }}
            >
              <Typography variant="h6" className={classes.downloadLink}>
                {fileName}
              </Typography>
            </Link>
            <iframe
              className={classNames(classes.hide, classes.iframeForDownload)}
              key={this.state.random}
              title={this.state.title}
              src={this.state.srcUrl}
              frameBorder="0"
            />
          </div>
        ) : displayType === 'image' ? (
          <div className={classes.imageViewer}>
            <div className={classes.imageWrap}>
              <div className={classes.zoom}>
                <PinchZoomPan position="center" maxScale={2}>
                  <img alt={title} src={srcUrl} />
                </PinchZoomPan>
              </div>
            </div>
          </div>
        ) : openNewTab || externalSites.find(el => srcUrl.indexOf(el) > -1) ? (
          <div>
            <Typography variant="h5" className={classes.instructions}>
              Click on link below to open the external site
            </Typography>
            <Link
              className={classes.downloadLink}
              to={'#'}
              onClick={() => {
                window.open(srcUrl);
              }}
            >
              <Typography variant="h6" className={classes.downloadLink}>
                Open External Site
              </Typography>
            </Link>
          </div>
        ) : (
          <iframe
            title={title}
            src={srcUrl}
            className={classes.iframe}
            //allow="autoplay; encrypted-media"
            width={this.props.width}
            height={this.props.height}
            frameBorder="0"
            //allowFullScreen
            // style={{
            //     backgroundColor: '#fff',
            //     width: "100%",
            //     height: "100%"
            //   }}
          />
        )}
      </div>
    );
  }
}
Iframe.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesIframe)(Iframe);
