import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { ListStudent } from './ListStudent';
import { ListViewLearningOutcome } from './ViewLearningOutcome';
import { ListScheduleStudent } from './ListScheduleStudent';
import { StudentActions, StudentPaths } from './StudentConstans';
import { AdminPage } from '../ui/page/AdminPage';
import EditStudent from './EditStudent';
import { ListClassPropose } from './ListClassPropose';

const stylesStudent = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class StudentComp extends Component {
  static displayName = StudentComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      studentId: this.props.match.params.studentId,
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case StudentActions.List:
        this.state.pageTitle = 'Students';
        this.state.content = <ListStudent />;
        break;
      case StudentActions.Create:
        this.state.pageTitle = 'Create Student';
        this.state.content = (
          <EditStudent
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            studentId={this.state.studentId}
          />
        );
        break;
      case StudentActions.Edit:
        this.state.pageTitle = 'Edit Student';
        this.state.content = (
          <EditStudent
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            studentId={this.state.studentId}
          />
        );
        break;
      case StudentActions.ViewLearningOutcome:
        this.state.pageTitle = 'Learning Outcomes';
        this.state.content = (
          <ListViewLearningOutcome
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            studentId={this.state.studentId}
          />
        );
        break;
      case StudentActions.ViewScheduleStudent:
        this.state.pageTitle = 'Student Schedule';
        this.state.content = (
          <ListScheduleStudent
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            studentId={this.state.studentId}
          />
        );
        break;
      case StudentActions.ViewPropose:
        this.state.pageTitle = 'Class Proposal';
        this.state.content = (
          <ListClassPropose
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            studentId={this.state.studentId}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === 'list' ? (
        <Fab
          aria-label="Add"
          color="primary"
          className={classes.fab}
          onClick={() => this.redirectTo(StudentPaths.Create)}
        >
          <AddIcon />
        </Fab>
      ) : (
        ''
      );
    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

StudentComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Students = withStyles(stylesStudent)(StudentComp);
