import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { ListStudentCourse } from './ListStudentCourse';
import EditStudentCourse from './EditStudentCourse';
import {
  StudentCourseActions,
  StudentCoursePaths
} from './StudentCourseConstants';
import { AdminPage } from '../ui/page/AdminPage';

const styles = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class StudentCourseComp extends Component {
  static displayName = StudentCourseComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      content: null,
      pageTitle: '',
      redirect: null
    };
    const studentIdParam = this.props.match.params.studentId;
    const classIdParam = this.props.match.params.classId;
    const studentCourseId = this.props.match.params.studentCourseId;
    const studentId = studentIdParam === '0' ? null : studentIdParam;
    const classId = classIdParam === '0' ? null : classIdParam;
    switch (this.props.action) {
      case StudentCourseActions.List:
        this.state.pageTitle = 'Payment Info';
        this.state.content = <ListStudentCourse />;
        break;
      case StudentCourseActions.Create:
        this.state.pageTitle = 'Renew';
        this.state.content = (
          <EditStudentCourse
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            studentId={studentId}
            classId={classId}
          />
        );
        break;
      case StudentCourseActions.Edit:
        this.state.pageTitle = 'Edit Payment';
        this.state.content = (
          <EditStudentCourse
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            studentCourseId={studentCourseId}
            studentId={studentId}
          />
        );
        break;
      case undefined:
      default:
    }
  }
  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === 'list' ? (
        <Fab
          aria-label="Add"
          color="primary"
          className={classes.fab}
          onClick={() => this.redirectTo(`${StudentCoursePaths.Create}/0/0`)}
        >
          <AddIcon />
        </Fab>
      ) : null;

    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

StudentCourseComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const StudentCourse = withStyles(styles)(StudentCourseComp);
