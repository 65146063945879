import React from 'react';
import clsx from 'clsx';
import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core';
import Chart from 'react-apexcharts';
import { examTypes } from '../../../academic-manage/views/stylesExamResults';
import { SelectControlViewBase } from './SelectControlViewBase';

export class ExamChartView extends SelectControlViewBase {
  render() {
    const {
      title,
      subtitle,
      chartOptions,
      chartSeriesData,
      numCategories,
      barWidth,
      classes
    } = this.props;
    const { examTypeIdx } = this.state;
    const chartSeries = chartSeriesData[examTypeIdx];

    return (
      <Paper className={classes.chartBox}>
        <Grid container>
          <div className={classes.chartHeadingBox}>
            <Typography variant="h3" className={classes.heading}>
              {title}
            </Typography>
            <Typography variant="body2" className={classes.subtitle}>
              {subtitle}
            </Typography>
          </div>
          <div className={classes.chartNumberBox}>
            <FormControl>
              <Select
                name="examTypeIdx"
                value={examTypeIdx}
                onChange={this.handleChange}
                className={classes.chartSelect}
              >
                {examTypes.slice(1).map((examType, idx) => {
                  return (
                    <MenuItem
                      value={idx}
                      key={idx}
                      className={classes.chartMenuItem}
                    >
                      {examType}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <div style={{ overflowY: 'auto' }}>
          <div
            className={clsx(classes.chartWrap)}
            style={{
              width:
                chartOptions.xaxis.categories.length > numCategories
                  ? barWidth * chartOptions.xaxis.categories.length
                  : '100%',
              height: 'auto',
              padding: '0 10px 10px 10px'
            }}
          >
            <Chart
              type="bar"
              options={chartOptions}
              series={chartSeries}
              width="100%"
              height="300"
            />
          </div>
        </div>
      </Paper>
    );
  }
}
