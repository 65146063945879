import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';
import AttendanceDialog from '../../attendance/AttendanceDialog';
import Button from '@material-ui/core/Button';
import { DateTimeUtils } from '../../common/DateTimeUtils';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import LetsLearnContent from './LetsLearnContent';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import authService from '../../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { fetchHelper } from './../../../helpers/fetch-helper';
import { toggleFullScreen } from '../../common/utils/FullScreen';
import { withStyles } from '@material-ui/core/styles';

const useStylesLetsLearnScreen = theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    height: '100%',
    position: 'absolute'
  },
  appBar: {
    position: 'relative'
  },
  toolBar: {
    minHeight: 0,
    height: 0
  },
  fsButton: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.5),
    minWidth: 0,
    marginTop: '35px',
    marginRight: '-20px'
  },
  content: {},
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  grow: {
    flexGrow: 1
  },
  title: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.common.white
  }
});

class LetsLearnScreen extends Component {
  constructor(...args) {
    super(...args);
    this.letsLearnScreen = React.createRef();
    this.letsLearnContent = React.createRef();
    this.attendanceDialog = React.createRef();
    this.state = {
      classLessonId: this.props.match.params.classLessonId,
      classId: this.props.match.params.classId,
      previewMode: parseInt(this.props.match.params.previewMode),
      previewLesson: parseInt(this.props.match.params.previewLesson),
      fsActive: false
    };
  }

  componentDidMount() {
    if (this.state.previewLesson) {
      this.openLessonPlanPreviewScreen();
    } else {
      this.openLetsLearnScreen();
    }
  }

  openLetsLearnScreen() {
    const { classLessonId, previewMode } = this.state;
    this.letsLearnContent.setLesson(classLessonId, previewMode);
    if (!previewMode) {
      this.saveLessonLog().then(() => {
        const { lessonLog, classId } = this.state;
        this.getStudentsByClass(classId).then(res =>
          res.forEach(student => {
            this.addStudentLessonLog(student.subId, lessonLog.id);
          })
        );
      });
    }
  }
  openLessonPlanPreviewScreen() {
    this.letsLearnContent.setLessonPlan(this.state.classLessonId);
  }

  // Init student lesson log with present = 0
  addStudentLessonLog = async (studentId, logId) => {
    const studentLessonLogNew = {
      logId: logId,
      studentInfoId: studentId
    };
    await fetchHelper.post(
      `api/TeacherLessonLog/AddTodayStudentLessonLog`,
      studentLessonLogNew
    );
  };

  getStudentsByClass = async classId => {
    return await fetchHelper.get(
      `api/ClassStudents/GetStudentByClass/${classId}`
    );
  };

  saveLessonLog = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    //save lesson log
    const { classLessonId } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const lessonLogData = {
      teacherId: loggedInUser.sub,
      classLessonId: classLessonId,
      logDateTime: DateTimeUtils.toDateTimeJSON(new Date())
    };
    const response = await fetch(`api/TeacherLessonLog/AddTodayLessonLog`, {
      method: 'POST',
      body: JSON.stringify(lessonLogData),
      headers: headers
    });
    const lessonLog = await response.json();
    this.setState({ lessonLog });
    this.letsLearnContent.setLessonLog(lessonLog);
  };

  handleFinish = () => {
    if (this.state.previewMode) {
      this.handleExit();
    } else {
      const { classLessonId, lessonLog, classId } = this.state;
      // this.classStudentReportDialog.openClassStudentReport(
      //   classLessonId,
      //   lessonLog,
      //   classId
      // );
      this.attendanceDialog.openAttendanceDialog(
        'attendance',
        lessonLog.id,
        classId,
        classLessonId
      );
      this.setState({ showAttendanceDialog: true });
    }
  };

  handleExit = () => {
    const {
      classLessonId,
      lessonLog,
      classId,
      showAttendanceDialog
    } = this.state;
    if (showAttendanceDialog) {
      this.attendanceDialog.openAttendanceDialog(
        'assessment',
        lessonLog.id,
        classId,
        classLessonId
      );
      this.setState({ showAttendanceDialog: false });
    } else window.close();
  };

  handleFullScreen = () => {
    toggleFullScreen(this.letsLearnScreen.current);
    this.setState({ fsActive: !this.state.fsActive });
  };

  render() {
    const { classes } = this.props;
    const { fsActive } = this.state;
    return (
      <div
        id="lets-learn-screen"
        className={classes.root}
        ref={this.letsLearnScreen}
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Typography variant="h6" className={classes.title}>
              {/* Let's Learn{this.state.previewMode && ' - Preview Mode'} */}
            </Typography>
            <div className={classes.grow} />
            <Button
              className={clsx(classes.fsButton)}
              edge="end"
              color="inherit"
              onClick={this.handleFullScreen}
            >
              {fsActive ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </Button>
          </Toolbar>
        </AppBar>
        <LetsLearnContent
          className={classes.content}
          onRef={actualChild => (this.letsLearnContent = actualChild)}
          handleFinish={this.handleFinish}
        />
        <AttendanceDialog
          onRef={actualChild => (this.attendanceDialog = actualChild)}
          exitAction={this.handleExit}
        />
      </div>
    );
  }
}

LetsLearnScreen.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStylesLetsLearnScreen)(LetsLearnScreen);
