import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import { Paper } from '@material-ui/core';
import { Loading } from '../../ui/Loading';
import ClassCourseInfo from '../ClassCourseInfo';
import CommonDataTable from '../../ui/table/CommonDataTable';
import { AdminPage } from '../../ui/page/AdminPage';
import { stylesAcademicInfo } from '../../academic-manage/stylesAcademicInfo';
import ClassSummary from './ClassSummary';
import StudentStatistics from './student/StudentStatistics';
import LessonStatistics from './lesson/LessonStatistics';
import ExamResultReports from './exam/ExamResultReports';

class ClassReports extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      classId: this.props.match.params.classId,
      loading: true
    };
  }

  componentDidMount() {
    this.populateClassData();
  }

  populateClassData = async () => {
    const token = await authService.getAccessToken();
    const { classId } = this.state;

    const [respClassInfo, respTeachers] = await Promise.all([
      fetch(`api/ClassCourses/GetClassInfo/${classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/ClassTeacher/${classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [classInfo, teachers] = await Promise.all([
      respClassInfo.json(),
      respTeachers.json()
    ]);

    this.setState({
      loading: false,
      classInfo,
      teachers
    });
  };

  renderDatagrid() {
    const { classes } = this.props;
    const { classInfo, teachers } = this.state;
    const colsTeacher = [
      { name: 'firstName', header: 'First Name', align: 'left' },
      { name: 'lastName', header: 'Last Name', align: 'left' },
      {
        name: 'userName',
        header: 'User Name',
        align: 'left'
      },
      { name: 'employeeCode', header: 'Employee Code', align: 'left' },
      { name: 'phoneNumber', header: 'Phone', align: 'left' },
      {
        name: 'isPrimary',
        header: 'Main Teacher',
        isBool: true,
        align: 'center'
      }
    ];

    return (
      <Fragment>
        <ClassCourseInfo classCourse={classInfo} />
        <Paper>
          <CommonDataTable
            onRef={actualChild => (this.child = actualChild)}
            rows={teachers}
            cols={colsTeacher}
          />
        </Paper>

        <Paper className={classes.reportMainFrame}>
          <ClassSummary classInfo={classInfo} />
          <div className={classes.reportBlockSpacing} />
          <StudentStatistics classInfo={classInfo} />
          <div className={classes.reportBlockSpacing} />
          <LessonStatistics classInfo={classInfo} />
          <div className={classes.reportBlockSpacing} />
          <ExamResultReports classInfo={classInfo} />
        </Paper>
      </Fragment>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return <AdminPage title="Class Report" content={contents} />;
  }
}

const stylesClassReports = theme => ({
  ...stylesAcademicInfo(theme),
  reportMainFrame: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 0)
  },
  reportBlockSpacing: {
    marginBottom: theme.spacing(2)
  }
});

ClassReports.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesClassReports)(ClassReports);
