export const ClassCourseActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete',
  Reports: 'reports'
};

const prefix = '/class-course';

export const ClassCoursePaths = {
  ClassCoursePrefix: prefix,
  List: `${prefix}/${ClassCourseActions.List}`,
  EditRoute: `${prefix}/${ClassCourseActions.Edit}/:classId`,
  Edit: `${prefix}/${ClassCourseActions.Edit}`,
  Create: `${prefix}/${ClassCourseActions.Create}`,
  Reports: `${prefix}/${ClassCourseActions.Reports}`,
  ReportsRoute: `${prefix}/${ClassCourseActions.Reports}/:classId`,
  Delete: `ClassCourses`
};

export const CLASS_TYPE = {
  Suspended: 4,
  Regular: 1
};
