import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'date-fns';
import authService from '../api-authorization/AuthorizeService';

import { Loading } from '../ui/Loading';
import ClassCourseInfo from '../class-course/ClassCourseInfo';
import EditStudent from '../student/EditStudent';

class AssignStudent extends Component {
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      loading: true,
      classId: this.props.classId,
      classCourse: {}
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateClassData();
  }

  populateClassData = async () => {
    const token = await authService.getAccessToken();
    const { classId } = this.state;
    const response = await fetch(`api/ClassCourses/${classId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const classCourse = await response.json();
    this.setState({ classCourse, loading: false });
  };

  render() {
    const { classId, classCourse, loading } = this.state;

    return (
      <Fragment>
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <ClassCourseInfo classCourse={classCourse} />
            <EditStudent
              onRef={actualChild => (this.child = actualChild)}
              action="create"
              classId={classId}
              classProposal={true}
            />
          </Fragment>
        )}
        {this.state.redirect}
      </Fragment>
    );
  }
}

const stylesEdit = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  }
});

AssignStudent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesEdit)(AssignStudent);
