import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CustomizedTable from '../../ui/table/CustomizedTable';
import authService from '../../api-authorization/AuthorizeService';
import { Loading } from '../../ui/Loading';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    overflowX: 'auto'
  },
  cell: {
    padding: theme.spacing(1, 0, 1, 2)
  }
});

class ParentSelectComp extends Component {
  static displayName = ParentSelectComp.name;
  constructor(...args) {
    super(...args);
    this.state = {
      data: [],
      lstSelected: [],
      parentId: this.props.parentId,
      loading: true
    };
  }

  componentDidMount() {
    this.populateData();
  }

  async populateData() {
    const token = await authService.getAccessToken();
    const response = await fetch(
      `api/StudentParents/list-filter-student/${this.state.parentId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({ data: data, loading: false });
  }

  callbackValueCheckboxTabl = e => {
    this.props.callbackValueCheckboxTabl(e);
  };

  render() {
    const { data } = this.state;
    const cols = [
      { name: 'studentName', header: 'Name', align: 'left' },
      { name: 'englishName', header: 'English Name', align: 'right' },
      { name: 'birthdayFormat', header: 'Birthday', align: 'right' }
    ];
    let contents = this.state.loading ? (
      <Loading />
    ) : (
      <CustomizedTable
        onRef={actualChild => (this.child1 = actualChild)}
        rows={data}
        cols={cols}
        isShowCheckbox={true}
        callbackFromParent={this.callbackValueCheckboxTabl}
      />
    );
    return <Fragment>{contents}</Fragment>;
  }
}

ParentSelectComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ParentSelect = withStyles(styles)(ParentSelectComp);
