import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { ListRole } from './ListRole';
import { EditRole } from './EditRole';
import { RolePaths, RoleActions } from './RoleConstants';
import { AdminPage } from '../../ui/page/AdminPage';

const stylesRole = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class RoleComp extends Component {
  static displayName = RoleComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      roleId: this.props.match.params.roleId,
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case RoleActions.List:
        this.state.pageTitle = 'Roles';
        this.state.content = <ListRole />;
        break;
      case RoleActions.Create:
        this.state.pageTitle = 'Create Role';
        this.state.content = (
          <EditRole
            onRef={actualChild => (this.child = actualChild)}
            action={this.state.action}
          />
        );
        break;
      case RoleActions.Edit:
        this.state.pageTitle = 'Edit Role';
        this.state.content = (
          <EditRole
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            roleId={this.state.roleId}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === 'list' ? (
        <Fab
          aria-label="Add"
          color="primary"
          className={classes.fab}
          onClick={() => this.redirectTo(RolePaths.Create)}
        >
          <AddIcon />
        </Fab>
      ) : (
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.handleChildSubmit}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleChildBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      );

    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

RoleComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Role = withStyles(stylesRole)(RoleComp);
