import { Grid, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import { HtmlUtils } from '../../common/utils/HtmlUtils';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { stylesAcademicInfo } from '../stylesAcademicInfo';
import { withStyles } from '@material-ui/core/styles';

class StudentDetail extends Component {
  render() {
    const { classes, student, stdClassList } = this.props;
    return (
      <Grid
        container
        alignItems="flex-start"
        spacing={1}
        className={classes.stdBox}
      >
        <Grid item container spacing={1} xs={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Fullname:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              English name:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Class:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              EL account:
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={3}>
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>{student.studentName}</span>
          </Grid>
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>{student.englishName}</span>
          </Grid>

          {/* Curent Class */}
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>
              {stdClassList && stdClassList[0]?.className}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>{student.elAccount}</span>
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Birthday:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(classes.stdCell, classes.stdBoxLabel)}
            >
              Previous class:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              className={clsx(
                classes.stdCell,
                classes.stdBoxLabel,
                classes.stdBoxRedText
              )}
            >
              Personality traits:
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={5}>
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>
              {student.birthdayFormat}
            </span>
          </Grid>

          {/* Previous class */}
          <Grid item xs={12}>
            <span className={clsx(classes.stdCell)}>
              {stdClassList[1] && stdClassList[1]?.className}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span
              className={clsx(classes.stdCell)}
              dangerouslySetInnerHTML={HtmlUtils.createMarkup(student.traits)}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

StudentDetail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesAcademicInfo)(StudentDetail);
