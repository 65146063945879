import React, { useEffect, useState } from 'react';
import authService from '../../api-authorization/AuthorizeService';
import { Typography } from '@material-ui/core';

const ResetUsersPwd = props => {
  const [loading, setLoading] = useState('');

  const populateDataFilter = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);

    const [respLevel] = await Promise.all([
      fetch(`api/AspNetUsers/RandomUserPwd/${loggedInUser.sub}/8080`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [dataLevel] = await Promise.all([respLevel.text()]);

    setLoading(dataLevel);
  };

  useEffect(() => {
    populateDataFilter();
  }, []);

  return <Typography>{loading}</Typography>;
};

export default ResetUsersPwd;
