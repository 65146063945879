import React, { Component } from 'react';
import clsx from 'clsx';
import { Paper, Typography } from '@material-ui/core';
import NormalTable from '../../../ui/table/normal/NormalTable';

export class ReportClassView extends Component {
  render() {
    const { title, subtitle, tableData, tableCols, classes } = this.props;

    return (
      <Paper className={classes.chartBox}>
        <Typography variant="h3" className={classes.heading}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          {subtitle}
        </Typography>
        <div className={clsx(classes.chartWrap)} style={{ height: 'auto' }}>
          <NormalTable data={tableData} cols={tableCols} isPaging={true} />
        </div>
      </Paper>
    );
  }
}
