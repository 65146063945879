export const downloadFileFromBuffer = async data => {
  const bufferArr = await data.arrayBuffer();
  // Get content type and filename from response headers
  const contentType = data.headers.get('Content-Type');
  const matches = data.headers
    .get('Content-Disposition')
    .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
  const now = new Date();
  let fileName = `file-${now.toISOString()}.xlsx`;
  if (matches != null && matches[1]) {
    fileName = matches[1].replace(/['"]/g, '');
  }
  // Create a new Blob from the byte array and set its type to the content type
  const blob = new Blob([bufferArr], { type: contentType });

  // Create a temporary URL for the Blob object and create a link to download it
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  // Clean up the temporary URL and the link element
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
};
