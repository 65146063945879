import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import ViewExamResult from './ViewExamResult';

const stylesExamResultDialog = theme => ({
  root: {},
  dialogContent: {
    overflowY: 'auto'
  }
});

class ViewExamResultDialog extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      open: false,
      classInfo: {},
      title: 'Exam Results'
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  openExamResult(classInfo, studentInfo) {
    this.setState({ classInfo, studentInfo });
    this.openDialog();
  }

  openDialog = () => {
    this.setState({ open: true });
  };
  closeDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { title, classInfo, studentInfo } = this.state;

    return (
      <Dialog
        open={this.state.open}
        onClose={this.closeDialog}
        maxWidth="lg"
        className={classes.root}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <ViewExamResult classInfo={classInfo} studentInfo={studentInfo} />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ViewExamResultDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesExamResultDialog)(ViewExamResultDialog);
