import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CommonDataTable from './CommonDataTable';

const stylesCustomizedTable = theme => ({
  root: {
    //padding: 1,
    // marginTop: theme.spacing(1),
  }
});

class CustomizedTable extends Component {
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
  }

  componentDidMount() {
    this.props.onRef(this);
    this.setChildData();
  }

  setChildData = () => {
    this.child.setData(this.props.rows);
  };

  updateChildData = data => {
    this.child.setData(data);
  };

  render() {
    const {
      classes,
      cols,
      actions,
      isPaging,
      isShowCheckbox,
      isShowPopup,
      eventReloadData,
      callbackParentAction,
      callbackFromParent,
      callbackGetIdShowPopup
    } = this.props;
    return (
      <Paper className={classes.root}>
        <CommonDataTable
          onRef={actualChild => (this.child = actualChild)}
          cols={cols}
          actions={actions}
          isPaging={isPaging}
          isShowCheckbox={isShowCheckbox}
          isShowPopup={isShowPopup}
          eventReloadData={eventReloadData}
          callbackParentAction={callbackParentAction}
          callbackFromParent={callbackFromParent}
          callbackGetIdShowPopup={callbackGetIdShowPopup}
        />
      </Paper>
    );
  }
}

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesCustomizedTable)(CustomizedTable);
