import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import Stories from './Stories';
import { StoryPaths, StoryActions } from './StoryConstants';

export default class StoryRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={StoryPaths.HomeRoute}
          component={Stories}
          action={StoryActions.Home}
        />
      </Fragment>
    );
  }
}
