import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import CommonDataTable from '../ui/table/CommonDataTable';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { StudentPaths } from './StudentConstans';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const stylesList = theme => ({
  fab: {
    margin: theme.spacing(2, 1, 1, 1)
  },
  student: {
    margin: theme.spacing(1, 0, 0, 2)
  },
  cell: {
    padding: theme.spacing(1, 0, 1, 2)
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    overflowX: 'auto'
  }
});

class ListClassProposeComp extends Component {
  static displayName = ListClassProposeComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      data: [],
      loading: true,
      filterText: '',
      student: {},
      studentId: this.props.studentId,
      redirect: null
    };
  }

  componentDidMount() {
    this.populateStudentData();
  }

  populateStudentData = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const response = await fetch(
      `api/Student/GetClassPropose/${loggedInUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();

    const responseStudent = await fetch(
      `api/Student/GetInfoStudent/${this.state.studentId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const dataStudent = await responseStudent.json();
    this.setState({ data: data, loading: false, student: dataStudent });
    this.setChildData();
  };

  refreshStudent = async () => {
    await this.populateStudentData();
  };

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(
      {
        [attrName]: attrValue
      },
      this.setChildData
    );
  };

  setChildData = () => {
    const { data } = this.state;
    this.child.setData(data);
  };

  backToList = () => {
    this.redirectTo(StudentPaths.List);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  render() {
    const { classes } = this.props;
    const { student } = this.state;
    const cols = [
      { name: 'level', header: 'Level', align: 'left' },
      { name: 'class', header: 'Class' },
      { name: 'scheduleFormat', header: 'Schedule', align: 'left' },
      { name: 'startTimeLocal', header: 'Start Date', align: 'left' },
      { name: 'endTimeLocal', header: 'End Date', align: 'left' },
      { name: 'room', header: 'Room', align: 'left' },
      { name: 'learningProccess', header: 'Progress (Days)', align: 'left' },
      { name: 'numberOfPupils', header: 'Number Of Pupils', align: 'left' }
    ];
    const colsInfo = [
      { name: 'studentName', header: 'Name', xs: 3 },
      { name: 'englishName', header: 'English Name', xs: 3 },
      { name: 'birthdayFormat', header: 'Birthday', xs: 2 },
      { name: 'fatherName', header: 'Father', xs: 2 },
      { name: 'motherName', header: 'Mother', xs: 2 }
    ];
    const actions = null;

    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <Paper className={classes.root}>
              <Grid container>
                {colsInfo.map((col, idx) => (
                  <Grid item xs={col.xs} className={classes.cell} key={idx}>
                    <Typography color="textSecondary" variant="caption">
                      {col.header}
                    </Typography>
                    <Typography color="textPrimary" variant="body2">
                      {col.isObj ? student[col.name].name : student[col.name]}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Paper>
            <Paper>
              <CommonDataTable
                onRef={actualChild => (this.child = actualChild)}
                rows={this.state.data}
                cols={cols}
                actions={actions}
                eventReloadData={this.refreshStudent}
              />
            </Paper>
            <Fab
              aria-label="Cancel"
              className={classes.fab}
              onClick={this.backToList}
            >
              <CloseOutlinedIcon color="action" />
            </Fab>
          </Fragment>
        )}
        {this.state.redirect}
      </Fragment>
    );
  }
}

ListClassProposeComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListClassPropose = withStyles(stylesList)(ListClassProposeComp);
