import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { ProposalPaths } from './ProposalConstants';
import ClassCourseInfo from '../class-course/ClassCourseInfo';
import SelectStudentDialog from './SelectStudentDialog';
import StudentInfo from '../student/info/StudentInfo';
import StudentPayment from '../student/payment/StudentPayment';
import { CardActions, Fab, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { Dialogs, DialogType } from '../ui/popup/Dialogs';
import { stylesAcademicInfo } from '../academic-manage/stylesAcademicInfo';
import { ClassDataPaths } from '../class-course/class-data/ClassDataConstants';
import { RouteUtils } from '../common/RouteUtils';

class SelectStudentProposal extends Component {
  constructor(...args) {
    super(...args);
    this.selectStudentDialog = React.createRef();
    this.studentPayment = React.createRef();
    this.confirmDialog = React.createRef();
    this.state = {
      classInfo: {},
      student: {},
      studentId: null,
      loading: true,
      filterText: ''
    };
    this.savePaymentCommands = (
      <Fragment>
        <Button onClick={this.actionPayment} color="secondary">
          Accept
        </Button>
        <Button onClick={this.closeConfirmDialog} color="primary">
          Cancel
        </Button>
      </Fragment>
    );
  }

  componentDidMount() {
    this.populateClassData();
    this.showSelectStudentDialog();
  }

  populateClassData = async () => {
    const token = await authService.getAccessToken();
    const { classId } = this.props;
    const [respClassInfo, respClassStudents] = await Promise.all([
      fetch(`api/ClassCourses/${classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/ClassStudents/GetStudentByClass/${classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [classInfo, classStudents] = await Promise.all([
      respClassInfo.json(),
      respClassStudents.json()
    ]);
    this.setState(
      {
        classInfo,
        classStudents
      },
      () => this.populateStudentData()
    );
  };

  populateStudentData = async () => {
    const { studentId } = this.state;
    if (!studentId) {
      this.setState({ loading: false });
      return;
    }
    const token = await authService.getAccessToken();
    const [respStudent, respClass] = await Promise.all([
      fetch(`api/Student/${studentId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/Student/GetClass/${studentId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [student, stdClass] = await Promise.all([
      respStudent.json(),
      respClass.json()
    ]);

    this.setState({ student, stdClass, loading: false });
  };

  showSelectStudentDialog = () => {
    this.selectStudentDialog.openSelectStudent('Select', false);
  };

  handleSelectStudent = selectedItems => {
    this.setState({ studentId: selectedItems[0], loading: true }, () =>
      this.populateStudentData()
    );
  };

  backToList = action => {
    let url = `${ProposalPaths.List}`;
    switch (action) {
      case 'save':
        url = `${ClassDataPaths.List}/${this.props.classId}`;
        break;
      case 'cancel':
      default:
        break;
    }
    RouteUtils.nextPath(this.props.parentEl, url);
  };

  showConfirmDialog = confirmType => {
    this.setState({ confirmType }, () =>
      this.confirmDialog.showDialogWithCmds(
        DialogType.ClassProposalAddStudent,
        this.savePaymentCommands
      )
    );
  };
  closeConfirmDialog = () => {
    this.confirmDialog.closeDialog();
  };

  actionPayment = async () => {
    await this.studentPayment.saveData(this.state.student);
    this.backToList('save');
  };

  renderContent() {
    const { classId, classes } = this.props;
    const { studentId, student, stdClass, classInfo } = this.state;
    const stdClassList = [stdClass];

    return (
      <Fragment>
        <ClassCourseInfo classCourse={classInfo} />
        {studentId && (
          <StudentInfo student={student} stdClassList={stdClassList} />
        )}
        {studentId && (
          <StudentPayment
            onRef={actualChild => (this.studentPayment = actualChild)}
            student={student}
            stdClass={stdClass}
            classId={classId}
            demoStudent={true}
          />
        )}
        <CardActions className={classes.cardActions}>
          <Fragment>
            <Fab
              aria-label="Save"
              color="primary"
              className={classes.fab}
              onClick={() => this.showConfirmDialog('save')}
            >
              <SaveIcon />
            </Fab>
            <Fab
              aria-label="Cancel"
              className={classes.fab}
              onClick={() => this.backToList('cancel')}
            >
              <CloseOutlinedIcon color="action" />
            </Fab>
          </Fragment>
        </CardActions>
      </Fragment>
    );
  }

  render() {
    const { loading } = this.state;
    let contents = loading ? <Loading /> : this.renderContent();

    return (
      <Fragment>
        {contents}
        <Dialogs onRef={actualChild => (this.confirmDialog = actualChild)} />
        <SelectStudentDialog
          onRef={actualChild => (this.selectStudentDialog = actualChild)}
          actionNoSelectedItem={this.backToList}
          handleReturnData={this.handleSelectStudent}
        />
      </Fragment>
    );
  }
}

const stylesList = theme => ({ ...stylesAcademicInfo(theme) });

SelectStudentProposal.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesList)(SelectStudentProposal);
