import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import CustomizedTable from '../ui/table/CustomizedTable';
import { Redirect } from 'react-router-dom';
import { Loading } from '../ui/Loading';
import { CatchUpSchedulePaths } from './CatchUpScheduleContans';

const stylesList = theme => ({
  fab: {
    margin: '12px 8px 8px 8px'
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  }
});

class ListCatchUpScheduleComp extends Component {
  static displayName = ListCatchUpScheduleComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      Data: [],
      loading: true,
      selectedId: '',
      redirect: null,
      catchUpId: '',
      catchUpCurrent: null,
      catchUpTime: ''
    };
  }
  componentDidMount() {
    this.populateDataInit();
  }
  populateDataInit = async () => {
    const token = authService.getAccessToken();
    const currentUser = await authService.getUser();
    const response = await fetch(
      `api/CatchUpSchedules/GetScheduleByTeacher/${currentUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({ Data: data, loading: false });
    this.setChildData();
  };

  setChildData = () => {
    const { Data } = this.state;
    this.child.updateChildData(Data);
  };

  eventReloadData = () => {
    this.populateDataInit();
  };

  backToList = () => {
    this.redirectTo(`${CatchUpSchedulePaths.List}`);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  callbackGetValueRadioBtn = e => {
    this.setState({ selectedId: e });
  };

  callbackGetIdShowPopup = async (e, action) => {
    if (action === 'selectScheduleCatchUp') {
      this.setState({
        actionColName: 'selectScheduleCatchUp',
        catchUpId: e.id,
        catchUpCurrent: e
      });
    }
    if (action === 'selectStudent') {
      this.setState({
        actionColName: 'selectStudent',
        catchUpId: e.id,
        catchUpCurrent: e
      });
    }
    if (action === 'updateTime') {
      this.setState({
        catchUpTime: e.catchUpTime,
        actionColName: 'updateTime',
        catchUpId: e.id,
        catchUpCurrent: e
      });
    }
    this.openDialog();
  };

  callbackValueCathUpTime = async e => {
    this.setState({
      catchUpTime: e
    });
  };

  renderDatagrid() {
    const cols = [
      { name: 'name', header: 'Note', align: 'left' },
      { name: 'typeFormat', header: 'Type', align: 'left' },
      { name: 'level', header: 'Level', align: 'left' },
      { name: 'class', header: 'Class', align: 'left' },
      { name: 'lesson', header: 'Lesson', align: 'left' },
      { name: 'beginDateFormat', header: 'Date', align: 'left' },
      { name: 'studentName', header: 'Student', align: 'left' },
      { name: 'catchUpTime', header: 'Catch Up Time', align: 'left' }
    ];
    const actions = [
      {
        name: 'edit',
        tooltip: 'Edit',
        url: `${CatchUpSchedulePaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'delete',
        tooltip: 'Delete',
        url: `${CatchUpSchedulePaths.Delete}`,
        icon: 'delete_icon'
      }
    ];
    return (
      <Fragment>
        <CustomizedTable
          onRef={actualChild => (this.child = actualChild)}
          rows={this.state.Data}
          cols={cols}
          actions={actions}
          eventReloadData={this.eventReloadData}
          isShowPopup={true}
          callbackGetIdShowPopup={this.callbackGetIdShowPopup}
          isPaging={true}
        />
        {this.state.redirect}
      </Fragment>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();
    return <Fragment>{contents}</Fragment>;
  }
}

ListCatchUpScheduleComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListCatchUpSchedule = withStyles(stylesList)(
  ListCatchUpScheduleComp
);
