import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';

const drawerWidth = 257;
const appBarHeight = 64;
const appBarMobileHeight = 56;

export const StyledMenu = withStyles(theme => ({
  paper: {
    border: '1px solid #d3d4d5'
  },
  list: {
    minWidth: 200
  }
}))(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

export const ProfileMenuItem = withStyles(theme => ({
  root: {
    // '&:focus': {
    //   backgroundColor: theme.palette.common.white
    // },
    '&:hover': {
      backgroundColor: theme.palette.common.white
    },
    color: theme.palette.text.hint,
    cursor: 'default'
  }
}))(MenuItem);

export const StyledMenuItem = withStyles(theme => ({
  root: {
    //color: theme.palette.text.hint
  }
}))(MenuItem);

export const stylesMainLayout = theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    //color: 'rgba(0, 0, 0, 0.35)',
    color: theme.palette.text.hint,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  headerLogo: {
    marginRight: theme.spacing(1),
    width: 168
  },
  menuButton: {
    margin: theme.spacing(0, 5, 0, 1)
  },
  hide: {
    display: 'none'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  grow: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1,
    fontFamily: 'sans-serif',
    lineHeight: 'normal'
  },
  badgeColor: {
    backgroundColor: amber[500]
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: `calc(100vh - ${appBarMobileHeight}px)`,
    marginTop: appBarMobileHeight,
    [theme.breakpoints.up('md')]: {
      height: `calc(100vh - ${appBarHeight}px)`,
      marginTop: appBarHeight
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: theme.spacing(7) - drawerWidth,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(9) - drawerWidth
    },
    overflow: 'auto'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  loggedInUserName: {
    fontFamily: 'RalewayBold',
    fontWeight: 'normal',
    fontSize: 14,
    minWidth: 100,
    padding: theme.spacing(1.5, 2)
  },
  profileUserName: {
    fontFamily: 'RalewayBold',
    fontWeight: 'normal',
    fontSize: 14,
    color: theme.palette.text.primary,
    minWidth: 150
  },
  profileMenuButton: {
    margin: theme.spacing(1, 0)
  },
  menuItemIcon: {
    color: theme.palette.text.hint
  },
  notifyMenu: {
    maxHeight: 350
  }
});
