import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';

const stylesClassReportTable = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    padding: 3,
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  col: {}
});

const ReportTableCell = withStyles(theme => ({
  head: {
    fontFamily: 'RalewayBold',
    fontWeight: 'normal',
    fontSize: 14,
    color: theme.palette.text.primary,
    padding: theme.spacing(1.5, 5, 1.5, 2)
  },
  body: {
    fontFamily: 'RalewayMedium',
    padding: theme.spacing(1.25, 5, 1.25, 2)
  }
}))(TableCell);

class ClassReportTable extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      data: this.props.rows
    };
  }

  render() {
    const { classes, rows, cols } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {cols.map(col => (
                <ReportTableCell
                  className={classes.col}
                  component="th"
                  scope="row"
                  align={col.align}
                  key={col.field}
                >
                  {col.title}
                </ReportTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow className={classes.row} key={idx}>
                {cols.map((col, colIdx) => (
                  <ReportTableCell
                    align={col.align}
                    key={colIdx}
                    className={
                      col.className === undefined
                        ? classes.col
                        : classNames(classes.col, classes[col.className])
                    }
                  >
                    {col.obj && row[col.field] !== null
                      ? row[col.field][col.objName]
                      : col.render
                      ? col.render(row)
                      : col.field === 'index'
                      ? idx + 1
                      : row[col.field]}
                  </ReportTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

ClassReportTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesClassReportTable)(ClassReportTable);
