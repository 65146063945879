import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { PagingTable } from '../ui/table/PagingTable';
import CommonSearchToolbar from '../ui/table/CommonSearchToolbar';

const stylesList = () => ({});

class StudentSelectComp extends Component {
  static displayName = StudentSelectComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      data: [],
      loading: true,
      catchUp: this.props.catchUp
    };
  }

  componentDidMount() {
    this.populateData();
  }

  populateData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch(`api/Student/`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({
      data: data,
      loading: false
    });
    this.setChildDataSelect();
  };

  setChildDataSelect = () => {
    const { data } = this.state;
    this.child.setData(data);
  };

  callbackGetValueRadioBtn = e => {
    this.props.callbackGetValueRadioBtn(e);
  };

  callbackFromParentDrowdown = e => {
    this.props.callbackFromParentDrowdown(e);
  };

  handleChange = filterText => {
    this.setChildData(filterText);
  };

  setChildData = filterText => {
    const { data } = this.state;
    const searchTerm = filterText.toLowerCase();
    let filteredRows = data.filter(item => {
      const itemText = (
        item.studentName +
        item.englishName +
        item.fatherName +
        item.motherName
      ).toLowerCase();
      return itemText.indexOf(searchTerm) !== -1;
    });
    this.child.setData(filteredRows);
  };

  render() {
    const cols = [
      { name: 'studentName', header: 'Name', align: 'right' },
      { name: 'englishName', header: 'English Name', align: 'right' },
      { name: 'fatherName', header: 'Father Name', align: 'right' },
      { name: 'motherName', header: 'Mother Name', align: 'right' }
    ];

    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <CommonSearchToolbar handleChange={this.handleChange} />
            <PagingTable
              onRef={actualChild => (this.child = actualChild)}
              cols={cols}
              isShowRadioButton="true"
              callbackGetValueRadioBtn={this.callbackGetValueRadioBtn}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

StudentSelectComp.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(stylesList)(StudentSelectComp);
