export const stylesCommon = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  },
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

export const stylesEdit = theme => ({
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  textField: {
    width: 300
  }
});
