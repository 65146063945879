import React from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const ControlButton = props => {
  const { btnIcon, btnText, btnHandle } = props;
  const classes = useStylesCtrlBtn();

  return (
    <Box className={classes.root}>
      <Button onClick={btnHandle} className={classes.button}>
        {btnIcon}
      </Button>
      <Box component="span" onClick={btnHandle} className={classes.buttonText}>
        {btnText}
      </Box>
    </Box>
  );
};

const useStylesCtrlBtn = makeStyles(theme => ({
  root: {
    minWidth: 70,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  button: {
    minWidth: 50,
    width: 50,
    height: 34,
    borderRadius: 17
  },
  buttonText: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    minWidth: 60,
    fontSize: 11,
    fontFamily: 'RalewaySemiBold',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export { ControlButton };
