import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../../common/route/PropsRoute';
import { CampusPaths, CampusActions } from './CampusConstants';
import CampusEdit from './CampusEdit';
import CampusList from './CampusList';
import CampusReports from './reports/CampusReports';

export default class CampusRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={CampusPaths.List}
          component={CampusList}
          action={CampusActions.List}
        />
        <PropsRoute
          path={CampusPaths.EditRoute}
          component={CampusEdit}
          action={CampusActions.Edit}
        />
        <PropsRoute
          path={CampusPaths.Create}
          component={CampusEdit}
          action={CampusActions.Create}
        />
        <PropsRoute path={CampusPaths.ReportsRoute} component={CampusReports} />
      </Fragment>
    );
  }
}
