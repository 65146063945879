import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { Proposal } from './Proposal';
import { ProposalPaths, ProposalActions } from './ProposalConstants';

export default class ProposalRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={ProposalPaths.List}
          component={Proposal}
          action={ProposalActions.List}
        />
        <PropsRoute
          path={ProposalPaths.EditRoute}
          component={Proposal}
          action={ProposalActions.Edit}
        />
        <PropsRoute
          path={ProposalPaths.SelectStudentRoute}
          component={Proposal}
          action={ProposalActions.SelectStudent}
        />
      </Fragment>
    );
  }
}
