import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import CustomizedTable from '../ui/table/CustomizedTable';
import CardHeader from '@material-ui/core/CardHeader';
import { Loading } from '../ui/Loading';
import { Redirect } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { TaskPaths } from './TaskConstans';
import DialogTitle from '@material-ui/core/DialogTitle';

const stylesList = theme => ({
  fab: {
    margin: theme.spacing(1)
  },
  titleTb: {
    color: theme.palette.primary.light,
    margin: theme.spacing(1),
    marginLeft: 0,
    paddingLeft: 0
  }
});

class ListTaskComp extends Component {
  static displayName = ListTaskComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.child1 = React.createRef();
    this.state = {
      data: [],
      dataAllTask: [],
      loading: true,
      isTeacher: this.props.isTeacher,
      openDialog: false,
      taskIdApproved: '',
      redirect: null,
      currentUserId: null
    };
  }

  componentDidMount() {
    this.populateDataInit();
  }

  populateDataInit = async () => {
    const token = await authService.getAccessToken();
    const currentUser = await authService.getUser();
    const response = await fetch(
      `api/TaskSys/GetTaskSysAssignTask/${currentUser.sub}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const myData = await response.json();
    if (!this.state.isTeacher) {
      const responseGellAll = await fetch(
        `api/TaskSys/GetAllTaskSysAssignTask/${currentUser.sub}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const dataAllTask = await responseGellAll.json();
      this.setState({
        data: myData,
        dataAllTask: dataAllTask,
        loading: false,
        currentUserId: currentUser.sub
      });
    } else {
      const responseTaskAssigned = await fetch(
        `api/TaskSys/GetTaskSysAssigned/${currentUser.sub}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const dataresponseTaskAssigned = await responseTaskAssigned.json();
      this.setState({
        data: myData,
        dataAllTask: dataresponseTaskAssigned,
        loading: false,
        currentUserId: currentUser.sub
      });
    }
  };

  refreshData = async () => {
    await this.populateDataInit();
    this.child.updateChildData(this.state.data);
  };

  refreshDataAlltask = async () => {
    await this.populateDataInit();
    this.child1.updateChildData(this.state.dataAllTask);
  };

  openDialog = () => {
    this.setState({ openDialog: true });
  };

  closeDialog = () => {
    this.setState({ openDialog: false });
  };

  showPopup = (row, nameAct) => {
    this.openDialog();
    this.setState({ taskIdApproved: row.id });
  };

  saveDataSelected = () => {
    const token = authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    fetch(
      `api/TaskSys/ApproveTask/${this.state.taskIdApproved}/${this.state.currentUserId}`,
      {
        method: 'POST',
        body: '',
        headers: headers
      }
    )
      .then(response => {
        if (response.ok) {
          this.refreshDataAlltask();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    this.closeDialog();
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  render() {
    const { classes } = this.props;
    var cols = [];

    if (this.state.isTeacher) {
      cols = [
        { name: 'createDateFormat', header: 'Date Time', align: 'left' },
        { name: 'name', header: 'Title', align: 'left' },
        { name: 'note', header: 'Content', align: 'left' },
        { name: 'statusFormat', header: 'Status', align: 'left' },
        { name: 'manager', header: 'Manager', align: 'left' }
      ];
    } else {
      cols = [
        { name: 'createDateFormat', header: 'Date Time', align: 'left' },
        { name: 'name', header: 'Title', align: 'left' },
        { name: 'note', header: 'Content', align: 'left' },
        { name: 'assignUserFormat', header: 'Teacher', align: 'left' }
      ];
    }

    var colsAssign = [];
    if (this.state.isTeacher) {
      colsAssign = [
        { name: 'createDateFormat', header: 'Date Time', align: 'left' },
        { name: 'name', header: 'Title', align: 'left' },
        { name: 'note', header: 'Content', align: 'left' },
        { name: 'manager', header: 'Manager', align: 'left' }
      ];
    } else {
      colsAssign = [
        { name: 'createDateFormat', header: 'Date Time', align: 'left' },
        { name: 'name', header: 'Title', align: 'left' },
        { name: 'note', header: 'Content', align: 'left' },
        { name: 'userCurrent', header: 'Teacher', align: 'left' },
        {
          name: 'aspNetUsers',
          objName: 'phoneNumber',
          header: 'Phone',
          align: 'left',
          obj: true
        },
        { name: 'statusFormat', header: 'Status', align: 'left' }
      ];
    }
    const actions = [
      {
        name: 'edit',
        tooltip: 'Edit',
        url: `${this.state.isTeacher ? TaskPaths.Edit : TaskPaths.EditAssign}`,
        icon: 'edit_icon'
      },
      {
        name: 'delete',
        tooltip: 'Delete',
        url: `${TaskPaths.Delete}`,
        icon: 'delete_icon'
      }
    ];
    const actionsAllTask = [
      {
        name: 'edit_assign_task',
        tooltip: 'Edit',
        icon: 'edit_icon'
      }
    ];
    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            {!this.state.isTeacher ? (
              <Fragment>
                <CustomizedTable
                  onRef={actualChild => (this.child1 = actualChild)}
                  rows={this.state.dataAllTask}
                  cols={colsAssign}
                  actions={actionsAllTask}
                  eventReloadData={this.refreshDataAlltask}
                  isShowPopup="true"
                  callbackGetIdShowPopup={this.showPopup}
                />
                <Dialog
                  open={this.state.openDialog}
                  onClose={this.closeDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {'Do you approve this task?'}
                  </DialogTitle>
                  <DialogContent />
                  <DialogActions>
                    <Button onClick={this.closeDialog} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={this.saveDataSelected} color="primary">
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>
              </Fragment>
            ) : (
              <Fragment>
                <CustomizedTable
                  onRef={actualChild => (this.child1 = actualChild)}
                  rows={this.state.dataAllTask}
                  cols={colsAssign}
                  actions={null}
                  eventReloadData={this.refreshDataAlltask}
                  isShowPopup="true"
                  callbackGetIdShowPopup={this.showPopup}
                />
              </Fragment>
            )}
            <CardHeader
              className={classes.titleTb}
              title={
                this.state.isTeacher
                  ? 'My Suggestions'
                  : 'Assign Tasks to Teachers'
              }
            />
            <CustomizedTable
              onRef={actualChild => (this.child = actualChild)}
              rows={this.state.data}
              cols={cols}
              actions={actions}
              eventReloadData={this.refreshData}
            />
            {this.state.redirect}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

ListTaskComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListTask = withStyles(stylesList)(ListTaskComp);
