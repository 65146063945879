import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const NormalTableCell = withStyles(theme => ({
  head: {
    fontFamily: 'RalewayBold',
    fontWeight: 'normal',
    fontSize: 14,
    color: theme.palette.text.primary,
    padding: theme.spacing(1.25, 1.25),
    borderBottom: 0,
    '&:not(:last-child)': {
      borderRight: '0.5px solid #ddd'
    }
  },
  body: {
    fontFamily: 'RalewayMedium',
    fontWeight: 'normal',
    fontSize: 14,
    color: theme.palette.text.secondary,
    maxWidth: 250,
    minHeight: 41,
    padding: theme.spacing(1.25, 1.25),
    borderBottom: 0,
    '&:not(:last-child)': {
      borderRight: '0.5px solid #ddd'
    }
  }
}))(TableCell);
