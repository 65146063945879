import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ListMyClass } from './ListMyClass';
import { MyClassActions } from './MyClassConstants';
import { ListStudentInClass } from './ListStudentInClass';
import { ListClassLog } from '../report/class/ListClassLog';
import { ViewReport } from '../report/class/ViewReport';
import { AdminPage } from '../ui/page/AdminPage';

const stylesMyClass = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class MyClassComp extends Component {
  static displayName = MyClassComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      classLessonId: this.props.match.params.classLessonId,
      classId: this.props.match.params.classId,
      logId: this.props.match.params.logId,
      content: null,
      action: null,
      pageTitle: ''
    };
    switch (this.props.action) {
      case MyClassActions.List:
      case MyClassActions.Preview:
        this.state.pageTitle = this.props.previewMode
          ? 'My Classes'
          : "Let's Learn";
        this.state.content = (
          <ListMyClass previewMode={this.props.previewMode} />
        );
        break;
      case MyClassActions.StudentView:
        this.state.pageTitle = 'Student List';
        this.state.content = (
          <ListStudentInClass classId={this.state.classId} />
        );
        break;
      case MyClassActions.ViewReport:
        this.state.pageTitle = 'My Class Reports';
        this.state.content = (
          <ListClassLog
            action={this.props.action}
            classId={this.state.classId}
          />
        );
        break;
      case MyClassActions.ViewReportDetail:
        this.state.content = (
          <ViewReport
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            isClass={true}
            logId={this.state.logId}
            classLessonId={this.state.classLessonId}
          />
        );
        break;
      // case MyClassActions.Run:
      //   this.state.pageTitle = "Let's Learn";
      //   this.state.action = (
      //     <RunButton
      //       onRef={actualChild => (this.runButton = actualChild)}
      //       handleCallback={this.handleChildProcess}
      //     />
      //   );
      //   this.state.content = (
      //     <RunMyLesson
      //       onRef={actualChild => (this.child = actualChild)}
      //       classLessonId={this.state.classLessonId}
      //       getItemCount={this.getItemCount}
      //     />
      //   );
      //   break;
      case undefined:
      default:
    }
  }

  handleChildProcess = () => {
    this.child.startLesson();
  };

  render() {
    //const { classes } = this.props;

    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={this.state.action}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

MyClassComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const MyClass = withStyles(stylesMyClass)(MyClassComp);
