import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { ListUser } from './ListUser';
import { EditUser } from './EditUser';
import { UserPaths, UserActions } from './UserConstants';
import { AdminPage } from '../../ui/page/AdminPage';

const stylesUser = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class UserComp extends Component {
  static displayName = UserComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      userId: this.props.match.params.userId,
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case UserActions.List:
        this.state.pageTitle = 'Users';
        this.state.content = <ListUser />;
        break;
      case UserActions.Create:
        this.state.pageTitle = 'Create User';
        this.state.content = (
          <EditUser
            onRef={actualChild => (this.child = actualChild)}
            action={this.state.action}
          />
        );
        break;
      case UserActions.Edit:
        this.state.pageTitle = 'Edit User';
        this.state.content = (
          <EditUser
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            userId={this.state.userId}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === UserActions.List ? (
        <Fab
          aria-label="Add"
          color="primary"
          className={classes.fab}
          onClick={() => this.redirectTo(UserPaths.Create)}
        >
          <AddIcon />
        </Fab>
      ) : (
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.handleChildSubmit}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleChildBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      );

    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

UserComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const User = withStyles(stylesUser)(UserComp);
