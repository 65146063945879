import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
//import { blue } from '@material-ui/core/colors';
// import { Container } from 'reactstrap';
// import { NavMenu } from './NavMenu';
//import { defaultTheme } from '../ui/theme/defaultTheme';
import defaultTheme from '../../../ui/theme/defaultTheme.json';
import { AppMainLayout } from './AppMainLayout';
import SnackbarProvider from './../../snackbar/snackbar-provider';

const themeMain = createMuiTheme(defaultTheme);

export class MainLayout extends Component {
  static displayName = MainLayout.name;

  render() {
    return (
      <MuiThemeProvider theme={themeMain}>
        <SnackbarProvider>
          <AppMainLayout children={this.props.children} />
        </SnackbarProvider>
      </MuiThemeProvider>
      // <div>
      //   <NavMenu />
      //   <Container>
      //     {this.props.children}
      //   </Container>
      // </div>
    );
  }
}
