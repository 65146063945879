import React, { Component } from 'react';
import clsx from 'clsx';
import { Paper, Typography } from '@material-ui/core';

export class NumberValueView extends Component {
  render() {
    const { title, subtitle, numberValue, numberColor, classes } = this.props;

    return (
      <Paper className={classes.chartBox}>
        <Typography
          variant="h3"
          className={clsx(classes.heading, classes.center)}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          className={clsx(classes.subtitle, classes.center)}
        >
          {subtitle}
        </Typography>
        <div className={classes.chartWrap} style={{ height: '110px' }}>
          <div className={classes.chartLargeNumberBox}>
            <Typography
              variant="h1"
              className={clsx(classes.chartLargeNumber, numberColor)}
            >
              {numberValue}
            </Typography>
            <hr
              className={clsx(
                classes.chartNumberLine,
                classes.chartLargeNumberLine,
                numberColor
              )}
              style={numberValue > 9 ? { width: '84px' } : { width: '41px' }}
            />
          </div>
        </div>
      </Paper>
    );
  }
}
