import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Redirect } from 'react-router-dom';
import { Loading } from '../ui/Loading';
import MyClassPresentTable from '../my-class/MyClassPresentTable';
import { TeacherLessonPaths } from '../teacher-lesson/TeacherLessonConstants';

const stylesList = () => ({});

class ListMyScheduleComp extends Component {
  static displayName = ListMyScheduleComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      data: [],
      redirect: null,
      loading: true,
      classId: this.props.classId
    };
  }

  componentDidMount() {
    this.populateDataInit();
    this.props.onRef(this);
  }

  populateDataInit = async () => {
    const token = await authService.getAccessToken();
    const currentUser = await authService.getUser();
    const response = await fetch(
      `api/Schedules/GetMySchedule/${currentUser.sub}/${this.state.classId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({ data, loading: false });
  };

  refreshData = async () => {
    await this.populateDataInit();
    this.child.updateChildData(this.state.data);
  };

  backToList() {
    this.redirectTo(TeacherLessonPaths.List);
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  render() {
    const cols = [
      { name: 'beginDateFormat', header: 'Date' },
      { name: 'startTime', header: 'Lesson Start Time' },
      { name: 'endTime', header: 'Lesson End Time' },
      { name: 'classRoom', header: 'Room' },
      { name: 'classCourse', header: 'Class', align: 'left' },
      { name: 'lesson', header: 'Lesson', align: 'left' }
    ];

    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <MyClassPresentTable
              rows={this.state.data}
              cols={cols}
              previewMode={false}
              isMySchedule={true}
            />
          </Fragment>
        )}
        {this.state.redirect}
      </Fragment>
    );
  }
}

ListMyScheduleComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListMySchedule = withStyles(stylesList)(ListMyScheduleComp);
