import { ClassReportActions, ClassReportPaths } from './ClassReportConstants';
import React, { Component, Fragment } from 'react';

import ClassReportTable from './ClassReportTable';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Fab from '@material-ui/core/Fab';
import { Loading } from '../../ui/Loading';
import MyClassLessonInfo from '../../my-class/MyClassLessonInfo';
import { MyClassPaths } from '../../my-class/MyClassConstants';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { TeacherLessonPaths } from '../../teacher-lesson/TeacherLessonConstants';
import Typography from '@material-ui/core/Typography';
import authService from '../../api-authorization/AuthorizeService';
import { withStyles } from '@material-ui/core/styles';

const stylesViewReport = theme => ({
  root: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(3),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  title: {
    width: '100%',
    margin: theme.spacing(3, 0, 1, 0),
    alignItems: 'center',
    textAlign: 'center'
  },
  fab: {
    margin: theme.spacing(1)
  }
});

class ViewReportComp extends Component {
  static displayName = ViewReportComp.name;
  constructor(...args) {
    super(...args);
    this.state = {
      logId: this.props.logId,
      classLessonId: this.props.classLessonId,
      classId: this.props.classId,
      isViewLesson: this.props.isViewLesson,
      classStudentTitle: 'Class Report',
      classLessonTitle: 'Lesson Statistics',
      classStudentData: [],
      classLessonData: [],
      classLesson: {},
      redirect: null,
      loading: true
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateClassReport();
  }

  async populateClassReport() {
    if (this.props.logId !== '0') {
      const token = await authService.getAccessToken();
      var urlClassLessonReport = !this.state.isViewLesson
        ? `api/TeacherLessonLog/GetClassLessonReport/${this.props.logId}`
        : `api/TeacherLessonLog/GetLessonReport/${this.props.logId}`;
      const [
        respClassStudent,
        respClassLessonReport,
        respClassLesson
      ] = await Promise.all([
        fetch(
          `api/TeacherLessonLog/GetClassStudentReport/${this.props.logId}`,
          {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
          }
        ),
        fetch(urlClassLessonReport, {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }),
        fetch(`api/TeacherLessonLog/GetClassLessonInfo/${this.props.logId}`, {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        })
      ]);
      const [
        dataClassStudent,
        dataClassLessonReport,
        dataClassLesson
      ] = await Promise.all([
        respClassStudent.json(),
        respClassLessonReport.json(),
        respClassLesson.json()
      ]);
      this.setState({
        classStudentData: dataClassStudent,
        classLessonData: dataClassLessonReport,
        classLesson: dataClassLesson,
        loading: false
      });
    } else {
      this.setState({
        classStudentData: [],
        classLessonData: [],
        classLesson: {},
        loading: false
      });
    }
  }

  backToList() {
    const { classId } = this.state;
    if (this.props.isViewLesson) {
      this.redirectTo(`${TeacherLessonPaths.ViewLessonAdmin}/${classId}`);
      return;
    }
    if (this.props.action === ClassReportActions.ViewMyReport) {
      this.redirectTo(ClassReportPaths.MyList);
    } else {
      this.redirectTo(ClassReportPaths.List);
    }
  }

  backToListInClass = () => {
    const { classLesson } = this.state;
    this.redirectTo(`${MyClassPaths.ViewReport}/${classLesson.classId}`);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  renderContent() {
    const { classes } = this.props;
    const { classStudentData, classLessonData, classLesson } = this.state;
    const classStudentCols = [
      { title: '#', field: 'index', align: 'left' },
      { title: 'Name', field: 'studentName', editable: 'never' },
      {
        title: 'English Name',
        field: 'englishName',
        editable: 'never'
      },
      {
        title: 'Present',
        field: 'attendance',
        align: 'left'
      },
      { title: 'Star Score', field: 'starScore', align: 'right' },
      { title: 'Note', field: 'note' }
    ];
    const classLessonCols = [
      { title: '#', field: 'sortOrder', align: 'left' },
      { title: 'Plan Time (mins)', field: 'time', editable: 'never' },
      { title: 'Procedures', field: 'procedures', editable: 'never' },
      { title: 'Materials', field: 'materials', editable: 'never' },
      { title: 'Actual Time', field: 'actualTime', editable: 'never' },
      { title: 'Note', field: 'note' }
    ];

    return (
      <Fragment>
        <Paper className={classes.root}>
          <MyClassLessonInfo
            classLesson={classLesson}
            reportDateTime={true}
            teachers={true}
          />
          <Typography
            color="textPrimary"
            variant="h4"
            className={classes.title}
          >
            {this.state.classStudentTitle}
          </Typography>
          <ClassReportTable rows={classStudentData} cols={classStudentCols} />
          <Typography
            color="textPrimary"
            variant="h4"
            className={classes.title}
          >
            {this.state.classLessonTitle}
          </Typography>
          <ClassReportTable rows={classLessonData} cols={classLessonCols} />
        </Paper>
        {this.props.isClass ? (
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.backToListInClass}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        ) : null}
      </Fragment>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderContent();

    return (
      <Fragment>
        {this.state.redirect}
        {contents}
      </Fragment>
    );
  }
}

ViewReportComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ViewReport = withStyles(stylesViewReport)(ViewReportComp);
