import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import authService from '../../api-authorization/AuthorizeService';
import Iframe from '../../common/Iframe';
import RunStatusBar from './RunStatusBar';
import smile from '../../../images/smile-24.jpg';
import { Loading } from '../../ui/Loading';
import { stylesLetsLearnContent } from './stylesLetsLearnContent';

class LetsLearnContent extends Component {
  constructor(...args) {
    super(...args);
    this.timerElapsedId = null;
    this.state = {
      activeStep: 0,
      lessonPlanId: null,
      classLessonId: null,
      lessonLog: null,
      previewMode: true,
      previewModeLessonPlan: false,
      loading: true,
      myLessonData: [],
      runStatusBars: [],
      runMaterials: [],
      runStatusData: []
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.stopTimer();
    if (!this.state.previewMode) {
      this.saveLessonLogData();
    }
  }

  populateMyLessonData = async () => {
    const token = await authService.getAccessToken();
    //get lesson units
    const { previewModeLessonPlan, lessonPlanId, classLessonId } = this.state;
    const apiUrl = previewModeLessonPlan
      ? `api/LessonPlans/GetLessonPlanContent/${lessonPlanId}`
      : `api/MyClasses/GetMyLesson/${classLessonId}`;
    const response = await fetch(apiUrl, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState(
      { myLessonData: data, loading: false },
      this.populateRunStatusData
    );
  };

  saveLessonLogData = async () => {
    const token = await authService.getAccessToken();
    //save lesson log
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const { lessonLog, myLessonData, runStatusData } = this.state;
    for (var i = 0; i < myLessonData.length; i++) {
      const lessonLogDataNew = {
        logId: lessonLog.id,
        unitId: myLessonData[i].unitId,
        duration: runStatusData[i].timeElapsed,
        note: ''
      };
      console.log(myLessonData[i], lessonLogDataNew);
      fetch(`api/TeacherLessonLog/AddTodayLogData`, {
        method: 'POST',
        body: JSON.stringify(lessonLogDataNew),
        headers: headers
      });
    }
  };

  populateRunStatusData = () => {
    let runStatusDataArr = [];
    let runStatusBoxArr = [];
    let runMaterialArr = [];
    this.state.myLessonData.map((lessonUnit, idx) => {
      runStatusDataArr[idx] = {
        started: false,
        timeElapsed: 0,
        child: React.createRef()
      };
      runStatusBoxArr[idx] = (
        <RunStatusBar
          onRef={actualChild => (runStatusDataArr[idx].child = actualChild)}
          timeInMinutes={lessonUnit.time}
        />
      );
      runMaterialArr[idx] = (
        <Iframe lessonUnit={lessonUnit} title={lessonUnit.procedures} />
      );
      return idx;
    });
    this.setState(
      {
        runStatusData: runStatusDataArr,
        runStatusBars: runStatusBoxArr,
        runMaterials: runMaterialArr,
        loading: false
      },
      this.startLesson
    );
  };

  getMaterialUrl(lessonUnit) {
    let openUrl = null;
    if (lessonUnit.materialFileName) {
      openUrl = `api/Materials/GetForView/${lessonUnit.materialId}/${encodeURI(
        lessonUnit.materialFileName
      )}`;
    } else if (lessonUnit.materialUrl) {
      openUrl = lessonUnit.materialUrl;
    }
    return openUrl;
  }

  setLesson(classLessonId, previewMode) {
    this.stopTimer();
    this.setState(
      { classLessonId, previewMode, activeStep: 0, loading: true },
      this.populateMyLessonData
    );
  }
  setLessonLog(lessonLog) {
    this.setState({ lessonLog });
  }

  setLessonPlan(lessonPlanId) {
    this.stopTimer();
    this.setState(
      {
        lessonPlanId,
        previewModeLessonPlan: true,
        previewMode: true,
        activeStep: 0,
        loading: true
      },
      this.populateMyLessonData
    );
  }

  startLesson = () => {
    this.startTimer();
  };

  stopLesson() {
    this.stopTimer();
    this.setState({
      activeStep: 0,
      classLessonId: null,
      myLessonData: [],
      runStatusBars: [],
      runMaterials: [],
      runStatusData: []
    });
  }

  timerElapsed = () => {
    const { activeStep, runStatusData: runStatusDataArr } = this.state;
    if (runStatusDataArr.length === 0) {
      return;
    }
    runStatusDataArr[activeStep].timeElapsed++;
    this.setState({ runStatusData: runStatusDataArr });
    runStatusDataArr[activeStep].child.updateElapsedTime(
      runStatusDataArr[activeStep].timeElapsed
    );
  };

  setProgressDisplay(isShow, item) {
    if (!isShow) {
      this.stopTimer();
    }
    const { runStatusData } = this.state;
    runStatusData[item].child.setStatusDisplay(isShow);
    if (isShow) {
      this.startTimer();
    }
  }

  handleNext = async () => {
    this.setProgressDisplay(false, this.state.activeStep);
    this.setActiveStep(this.state.activeStep + 1);
    if (!this.state.previewMode) {
      //await this.saveLessonLogData();
    }
  };

  handleBack = () => {
    this.setProgressDisplay(false, this.state.activeStep);
    this.setActiveStep(this.state.activeStep - 1);
  };

  handleReset = () => {
    //this.setProgressDisplay(false, this.state.activeStep);
    this.setActiveStep(0);
  };

  handleFinish = async () => {
    if (!this.state.previewMode) {
      await this.saveLessonLogData();
    }
    this.stopLesson();
    this.props.handleFinish();
  };

  setActiveStep(activeStep) {
    this.setState({ activeStep });
    if (activeStep < this.state.myLessonData.length) {
      this.setProgressDisplay(true, activeStep);
    }
    // start
    if (activeStep === 0) {
      this.startLesson();
    }
    // finish
    if (activeStep === this.state.myLessonData.length) {
      this.stopTimer();
      if (!this.state.previewMode) {
        this.saveLessonLogData();
      }
    }
  }

  startTimer() {
    if (!this.timerElapsedId) {
      this.timerElapsedId = setInterval(this.timerElapsed, 1000);
    }
  }

  stopTimer() {
    if (this.timerElapsedId) {
      clearInterval(this.timerElapsedId);
      this.timerElapsedId = null;
    }
  }

  getStepContent(step) {
    const { runMaterials } = this.state;
    if (step < 0 && step >= runMaterials.length) {
      return null;
    }
    return runMaterials[step];
  }

  getStepDescription(step) {
    const { myLessonData } = this.state;
    if (step < 0 && step >= myLessonData.length) {
      return 'Unknown step';
    }
    return myLessonData[step].description;
  }

  getStepStatusBar(step) {
    const { runStatusBars } = this.state;
    if (step < 0 && step >= runStatusBars.length) {
      return null;
    }
    return runStatusBars[step];
  }

  renderFinishScreen() {
    const { classes } = this.props;
    return (
      <div className={classes.center}>
        <div className={classes.margins}>
          <Typography variant="h3" className={classes.instructions}>
            Good bye &amp;
          </Typography>
          <Typography variant="h3" className={classes.instructions}>
            See you again!
          </Typography>
        </div>
        <img src={smile} alt="smile" width="200" />
        <div className={classes.finishScnActions}>
          <div className={classes.navigation}>
            <Button
              onClick={this.handleReset}
              className={classes.button}
              variant="contained"
              color="primary"
            >
              Back
            </Button>
            <div className={classes.growStatusBar} />
            <Button
              onClick={this.handleFinish}
              className={classes.button}
              variant="contained"
              color="secondary"
            >
              End Session
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    const { classes } = this.props;
    const { activeStep, myLessonData: steps, runStatusBars } = this.state;

    const connector = (
      <StepConnector
        classes={{
          active: classes.connectorActive,
          completed: classes.connectorCompleted,
          disabled: classes.connectorDisabled,
          line: classes.connectorLine
        }}
      />
    );

    return (
      <div className={classes.root}>
        <Stepper
          // alternativeLabel
          activeStep={activeStep}
          connector={connector}
          classes={{ root: classes.stepperRoot }}
        >
          {steps.map((unit, idx) => (
            <Step key={idx} className={classes.step}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.step,
                    completed: classes.completed,
                    active: classes.active
                  }
                }}
              >
                {/* {unit.procedures} */}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Fragment>
          {activeStep === steps.length ? (
            this.renderFinishScreen()
          ) : (
            <div className={classes.contentWrap}>
              <Fragment>
                <div className={clsx(classes.content, classes.center)}>
                  {this.getStepContent(activeStep)}
                </div>
                <div className={classes.navigation}>
                  <Button
                    disabled={activeStep === 0}
                    variant="contained"
                    color="secondary"
                    onClick={this.handleBack}
                    className={classes.button}
                  >
                    <Icon>skip_previous</Icon>
                    Back
                  </Button>
                  <div className={clsx(classes.growStatusBar, classes.center)}>
                    {runStatusBars.map((runStatusBar, idx) => (
                      <Fragment key={idx}>{runStatusBar}</Fragment>
                    ))}
                    <div className={classes.description}>
                      {this.getStepDescription(activeStep)}
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? (
                      'Finish'
                    ) : (
                      <Fragment>
                        Next <Icon>skip_next</Icon>
                      </Fragment>
                    )}
                  </Button>
                </div>
              </Fragment>
            </div>
          )}
        </Fragment>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderContent();
    return <Fragment>{contents}</Fragment>;
  }
}

LetsLearnContent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesLetsLearnContent)(LetsLearnContent);
