import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { ListParent } from './ListParent';
import { ParentActions, ParentPaths } from './ParentConstans';
import { AdminPage } from '../../ui/page/AdminPage';
import { EditParent } from './EditParent';

const stylesParent = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class ParentComp extends Component {
  static displayName = ParentComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      parentId: this.props.match.params.parentId,
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case ParentActions.List:
        this.state.pageTitle = 'Parents';
        this.state.content = <ListParent />;
        break;
      case ParentActions.Create:
        this.state.pageTitle = 'Create Parent';
        this.state.content = (
          <EditParent
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            parentId={this.state.parentId}
          />
        );
        break;
      case ParentActions.Edit:
        this.state.pageTitle = 'Edit Parent';
        this.state.content = (
          <EditParent
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            parentId={this.state.parentId}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === 'list' ? (
        <Fab
          aria-label="Add"
          color="primary"
          className={classes.fab}
          onClick={() => this.redirectTo(ParentPaths.Create)}
        >
          <AddIcon />
        </Fab>
      ) : (
        ''
      );
    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

ParentComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Parents = withStyles(stylesParent)(ParentComp);
