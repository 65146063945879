import { Fab, Grid, Icon, Typography } from '@material-ui/core';
import { faArchive, faDonate } from '@fortawesome/free-solid-svg-icons';

import { ActionIconButton } from '../../ui/ButtonStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ReservePaths } from '../reserve/ReserveConstants';
import { RouteUtils } from '../../common/RouteUtils';
import { StudentCoursePaths } from '../../student-course/StudentCourseConstants';
import { StudentPaths } from '../../student/StudentConstans';
import clsx from 'clsx';

export default function StudentInfoSection(
  parentComponent,
  classes,
  studentId,
  stdClass,
  actionAllowedAccess
) {
  return (
    <Grid container className={classes.section}>
      <Grid item className={classes.sectionHeader}>
        <Typography variant="h3" className={classes.sectionTitle}>
          {"Student's Information"}
        </Typography>
      </Grid>
      <Grid item>
        {actionAllowedAccess.EditStudent && (
          <ActionIconButton
            color="inherit"
            className={classes.actionButton}
            onClick={() =>
              RouteUtils.nextPath(
                parentComponent,
                `${StudentPaths.Edit}/${studentId}`
              )
            }
          >
            <Icon className={classes.actionIcon}>edit_icon</Icon>
          </ActionIconButton>
        )}
      </Grid>
      <Grid item xs className={classes.sectionToolBar}>
        <Grid item container justify="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            {actionAllowedAccess.Renew && (
              <Fab
                variant="extended"
                size="small"
                aria-label="Renew"
                className={clsx(classes.extendedFab, classes.yellowBtn)}
                onClick={() =>
                  RouteUtils.nextPath(
                    parentComponent,
                    `${StudentCoursePaths.Create}/${studentId}/${stdClass.classId}`
                  )
                }
              >
                <FontAwesomeIcon
                  className={classes.actionIconFa}
                  icon={faDonate}
                  size="lg"
                />
                <span className={classes.extFabLabel}>RENEW</span>
              </Fab>
            )}
          </Grid>
          <Grid item>
            {actionAllowedAccess.Reserve && (
              <Fab
                variant="extended"
                size="small"
                aria-label="Reserve"
                className={clsx(classes.extendedFab, classes.blueBtn)}
                onClick={() =>
                  RouteUtils.nextPath(
                    parentComponent,
                    `${ReservePaths.Info}/${studentId}`
                  )
                }
              >
                <FontAwesomeIcon
                  className={classes.actionIconFa}
                  icon={faArchive}
                  size="lg"
                />
                <span className={classes.extFabLabel}>RESERVE</span>
              </Fab>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
