export const AcademicActions = {
  Search: 'search',
  Info: 'info',
  Create: 'create',
  Delete: 'delete'
};

const prefix = '/academic-manage';

export const AcademicPaths = {
  AcademicPrefix: prefix,
  Search: `${prefix}/${AcademicActions.Search}`,
  Info: `${prefix}/${AcademicActions.Info}`,
  InfoRoute: `${prefix}/${AcademicActions.Info}/:studentId`,
  Create: `${prefix}/${AcademicActions.Create}`,
  Delete: `Academic`
};
