import { blue, red } from '@material-ui/core/colors';

import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const CustomTableCell = withStyles(theme => ({
  body: {
    padding: theme.spacing(1),
    borderColor: blue[500],
    borderRight: '1px solid',
    borderRightColor: blue[500],
    '&:nth-child(1)': {
      borderTop: '1px solid',
      borderTopColor: blue[500],
      borderLeft: '1px solid',
      borderLeftColor: blue[500]
    }
  }
}))(TableCell);

export const CenterTableCell = withStyles(theme => ({
  body: {
    textAlign: 'center'
  }
}))(CustomTableCell);

export const stylesExamResult = theme => ({
  dialogContent: {
    padding: theme.spacing(1, 3)
  },
  headingText: {
    fontWeight: 'bold',
    fontSize: 12
  },
  italicText: {
    fontFamily: 'RalewayItalic',
    fontWeight: 'bold',
    fontSize: 12
  },
  normalText: {
    fontSize: 12
  },
  blueText: {
    color: blue[500]
  },
  redText: {
    color: red[600]
  },
  textBox: {
    '& .MuiOutlinedInput-inputMultiline': {
      height: '280px !important'
    },
    '& .MuiOutlinedInput-multiline': {
      padding: theme.spacing(1),
      fontSize: 12
    }
  },
  formControl: {
    width: '200px'
  },
  textAreaBox: {
    minHeight: '280px'
  },
  select: {
    minWidth: '200px'
  }
});
