export const ReserveActions = {
  Info: 'info',
  Create: 'create',
  Delete: 'delete'
};

const prefix = '/reserve';

export const ReservePaths = {
  ReservePrefix: prefix,
  Search: `${prefix}/${ReserveActions.Front}`,
  Info: `${prefix}/${ReserveActions.Info}`,
  InfoRoute: `${prefix}/${ReserveActions.Info}/:studentId`,
  Create: `${prefix}/${ReserveActions.Create}`,
  Delete: `ClassChange`
};
