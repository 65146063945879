export const UnitPlanActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete'
};

const prefix = '/unit-plan';

export const UnitPlanPaths = {
  UnitPlanPrefix: prefix,
  ListRoute: `${prefix}/${UnitPlanActions.List}/:lessonId`,
  List: `${prefix}/${UnitPlanActions.List}`,
  EditRoute: `${prefix}/${UnitPlanActions.Edit}/:planId`,
  Edit: `${prefix}/${UnitPlanActions.Edit}`,
  CreateRoute: `${prefix}/${UnitPlanActions.Create}/:lessonId`,
  Create: `${prefix}/${UnitPlanActions.Create}`,
  Delete: `UnitPlans`
};
