import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { ReportPaths } from './ReportConstants';
import ClassReportRoutes from './class/ClassReportRoutes';

export default class ReportRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute path={ReportPaths.Class} component={ClassReportRoutes} />
      </Fragment>
    );
  }
}
