import { Component } from 'react';

export class SelectControlViewBase extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      examTypeIdx: 0
    };
  }

  handleChange = event => {
    const attrName = event.target.name;
    const attrValue = event.target.value;
    this.setState({ [attrName]: attrValue, loading: true }, () => {
      if (this.triggerDataLoad) {
        this.triggerDataLoad();
      }
    });
  };
}
