import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../common/route/PropsRoute';
import { CatchUpSchedule } from './CatchUpSchedule';
import {
  CatchUpSchedulePaths,
  CatchUpScheduleActions
} from './CatchUpScheduleContans';

export default class CatchUpScheduleRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={CatchUpSchedulePaths.List}
          component={CatchUpSchedule}
          action={CatchUpScheduleActions.List}
        />
        <PropsRoute
          path={CatchUpSchedulePaths.ListStudentCatchUp}
          component={CatchUpSchedule}
          action={CatchUpScheduleActions.ListStudentCatchUp}
        />
        <PropsRoute
          path={CatchUpSchedulePaths.EditRoute}
          component={CatchUpSchedule}
          action={CatchUpScheduleActions.Edit}
        />
        <PropsRoute
          path={CatchUpSchedulePaths.Create}
          component={CatchUpSchedule}
          action={CatchUpScheduleActions.Create}
        />
        <PropsRoute
          path={CatchUpSchedulePaths.CreateCatchUpLessonRoute}
          component={CatchUpSchedule}
          action={CatchUpScheduleActions.CreateCatchUpLesson}
        />
        <PropsRoute
          path={CatchUpSchedulePaths.CreateCatchUpStudentRoute}
          component={CatchUpSchedule}
          action={CatchUpScheduleActions.CreateCatchUpStudent}
        />
      </Fragment>
    );
  }
}
