import React, { Fragment } from 'react';
import clsx from 'clsx';
import authService from '../../api-authorization/AuthorizeService';
import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core';
import Chart from 'react-apexcharts';
import { SelectControlViewBase } from '../../class-course/reports/exam/SelectControlViewBase';
import { Loading } from '../../ui/Loading';

export class TeacherLessonChart extends SelectControlViewBase {
  constructor(...args) {
    super(...args);
    this.state = {
      classId: '',
      firstLoading: true
    };
  }

  componentDidMount() {
    if (this.props.classList.length === 0) {
      this.setState({ firstLoading: false });
      return;
    }
    this.setState(
      { classId: this.props.classList[0].classId, firstLoading: true },
      () => {
        this.triggerDataLoad();
      }
    );
  }

  triggerDataLoad = async () => {
    const token = await authService.getAccessToken();
    const { classId } = this.state;

    const [respClassLessonStatistics] = await Promise.all([
      fetch(`api/LessonReport/GetClassLessonStatistics/${classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [lessonStatistics] = await Promise.all([
      respClassLessonStatistics.json()
    ]);

    const lessonStandardTimes = [];
    const lessonStandardTimesLow15m = [];
    const lessonStandardTimesHight15m = [];
    const lessonDurations = [];
    const lessonDates = [];
    lessonStatistics.lessonTimeData.forEach(element => {
      lessonDates.push(element.lessonDateText);
      lessonDurations.push(element.lessonDuration);
      lessonStandardTimes.push(element.standardLessonTime);
      lessonStandardTimesLow15m.push(element.standardLessonTime - 15);
      lessonStandardTimesHight15m.push(element.standardLessonTime + 15);
    });

    this.setState({
      lessonStatistics,
      lessonDates,
      lessonDurations,
      lessonStandardTimes,
      lessonStandardTimesLow15m,
      lessonStandardTimesHight15m,
      firstLoading: false
    });
  };

  render() {
    const { firstLoading } = this.state;
    let content = firstLoading ? (
      <Loading />
    ) : (
      this.props.classList.length > 0 && this.renderUI()
    );

    return <Fragment>{content}</Fragment>;
  }

  renderUI() {
    const { title, subtitle, classList, classes } = this.props;
    const {
      classId,
      lessonDates,
      lessonDurations,
      lessonStandardTimes,
      lessonStandardTimesLow15m,
      lessonStandardTimesHight15m
    } = this.state;

    const chartOptions = {
      chart: {
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      xaxis: {
        categories: lessonDates
      },
      stroke: {
        width: 2,
        curve: 'stepline'
      },
      colors: ['#ea0f0f', '#f2da29', '#e05a5a', '#c0c0c0']
    };
    const chartSeries = [
      {
        name: 'Lesson Standard Time +15m',
        data: lessonStandardTimesHight15m
      },
      {
        name: 'Lesson Standard Time',
        data: lessonStandardTimes
      },
      {
        name: 'Lesson Standard Time -15m',
        data: lessonStandardTimesLow15m
      },
      {
        name: 'Lesson Duration',
        data: lessonDurations
      }
    ];

    return (
      <Paper className={classes.chartBox}>
        <Grid container>
          <div className={classes.chartHeadingBox}>
            <Typography variant="h3" className={classes.heading}>
              {title}
            </Typography>
            <Typography variant="body2" className={classes.subtitle}>
              {subtitle}
            </Typography>
          </div>
          <div className={classes.chartNumberBox}>
            <FormControl>
              <Select
                name="classId"
                value={classId}
                onChange={this.handleChange}
                className={classes.chartSelect}
              >
                {classList.map((cls, idx) => {
                  return (
                    <MenuItem
                      value={cls.classId}
                      className={classes.chartMenuItem}
                      key={idx}
                    >
                      {cls.className}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <div style={{ overflowY: 'auto' }}>
          <div
            className={clsx(classes.chartWrap)}
            style={{
              width:
                chartOptions.xaxis.categories.length > 15
                  ? 30 * chartOptions.xaxis.categories.length
                  : '100%',
              height: 'auto',
              padding: '0 40px 10px 10px'
            }}
          >
            <Chart
              type="line"
              options={chartOptions}
              series={chartSeries}
              width="100%"
              height="300"
            />
          </div>
        </div>
      </Paper>
    );
  }
}
