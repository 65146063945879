export const stylesEditStudent = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowX: 'auto'
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    padding: theme.spacing(1, 2)
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  },
  groupSchedule: {
    marginTop: '20px'
  },
  fab: {
    margin: '12px 8px 8px 8px'
  },
  parentTbl: {
    paddingLeft: '10px;',
    paddingRight: '10px;'
  },
  padding10: {
    padding: '10px'
  },
  formControl: {
    marginBottom: '15px'
  },
  dateControl: {
    padding: theme.spacing(0, 1.5)
  },
  fabSelectClass: {
    marginRight: theme.spacing(1.5)
  }
});
