import {
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import React, { Component } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { DateTimeUtils } from '../../common/DateTimeUtils';
import { Fragment } from 'react';
import { Loading } from '../../ui/Loading';
import PropTypes from 'prop-types';
import { StudentCourseActions } from '../../student-course/StudentCourseConstants';
import authService from '../../api-authorization/AuthorizeService';
import clsx from 'clsx';
import { stylesEditStudent } from '../styleEditStudent';
import { withStyles } from '@material-ui/core/styles';

class StudentPayment extends Component {
  constructor(...args) {
    super(...args);
    this.paymentTypes = {
      1: 'Khóa 4 tháng',
      2: 'Khóa 6 tháng',
      3: 'Khóa 12 tháng',
      4: 'Khóa 24 tháng'
    };
    this.newPayment = {
      studentType: this.props.renew ? '1' : '3',
      transferType: 1,
      name: this.paymentTypes[1],
      studentId: '',
      classId: '',
      startDate: new Date(),
      endDate: new Date(),
      paymentType: 1,
      amount: 0,
      transferTypeDescription: '',
      numberOfSession: 0,
      numberOfDemoSessions: 0
    };
    this.state = {
      loading: false,
      studentCourse: this.newPayment
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    if (this.props.action === StudentCourseActions.Edit) {
      this.setState({ loading: true }, () => this.populatePaymentData());
    }
  }

  populatePaymentData = async () => {
    const token = await authService.getAccessToken();
    const { student, stdClass, studentCourseId } = this.props;
    const [respStudentCourse, respClassStudent] = await Promise.all([
      fetch(`api/StudentCourses/${studentCourseId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/ClassStudents/GetByClass/${stdClass.classId}/${student.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [studentCourse, classStudent] = await Promise.all([
      respStudentCourse.json(),
      respClassStudent.json()
    ]);
    studentCourse.studentType = classStudent.studentType;

    this.setState({ studentCourse, classStudent, loading: false });
  };

  saveData = async student => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    await this.savePayment(student, loggedInUser, headers);
    await this.saveClassStudent(student, headers);
  };

  async saveClassStudent(student, headers) {
    const { classId } = this.props;
    const { studentCourse } = this.state;
    const classStudent = {
      classId,
      studentId: student.id,
      studentType: studentCourse.studentType,
      numberOfDemoSessions: studentCourse.numberOfDemoSessions
    };

    await fetch(`api/ClassStudents`, {
      method: 'POST',
      body: JSON.stringify(classStudent),
      headers: headers
    });
  }

  async savePayment(student, loggedInUser, headers) {
    const { studentCourse } = this.state;

    if (studentCourse.studentType === '3') {
      //Not save payment for Demo
      return;
    }

    studentCourse.studentId = student.id;
    studentCourse.startDate = DateTimeUtils.toDateTimeJSON(
      studentCourse.startDate
    );
    studentCourse.numberOfSession = studentCourse.numberOfSession || 0;

    const url =
      this.props.action === StudentCourseActions.Edit
        ? `api/StudentCourses/${studentCourse.id}/${loggedInUser.sub}`
        : `api/StudentCourses/${loggedInUser.sub}`;
    await fetch(url, {
      method: this.props.action === StudentCourseActions.Edit ? 'PUT' : 'POST',
      body: JSON.stringify(studentCourse),
      headers: headers
    });
  }

  handleDateChange = name => date => {
    const attrName = name;
    const attrValue = date;
    this.setState(prevState => ({
      studentCourse: {
        ...prevState.studentCourse,
        [attrName]: attrValue
      }
    }));
  };

  handleChange = event => {
    const attrName = event.target.name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      studentCourse: {
        ...prevState.studentCourse,
        [attrName]: attrValue
      }
    }));
    this.changeNameByPaymentType(attrName, attrValue);
  };

  changeNameByPaymentType = async (name, value) => {
    if (name === 'paymentType') {
      const paymentName = this.paymentTypes[value];
      this.setState(prevState => ({
        studentCourse: {
          ...prevState.studentCourse,
          name: paymentName
        }
      }));
    }
  };

  render() {
    const { classes, renew, demoStudent } = this.props;
    const { studentCourse, loading } = this.state;

    return (
      <Paper className={classes.root}>
        {!renew && !demoStudent && <CardHeader title="Payment" />}
        {loading ? (
          <Loading />
        ) : (
          <Grid container justify="space-around">
            <Grid item xs={6}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                  >
                    <InputLabel htmlFor="student-type">Student type</InputLabel>
                    <Select
                      name="studentType"
                      value={studentCourse.studentType}
                      onChange={this.handleChange}
                    >
                      <MenuItem value="1">Regular</MenuItem>
                      <MenuItem value="2">Catch Up</MenuItem>
                      <MenuItem value="3">Demo</MenuItem>
                    </Select>
                  </FormControl>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                      container
                      className={clsx(classes.grid, classes.listItem)}
                      justify="flex-start"
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        label="Start date"
                        format="dd/MM/yyyy"
                        value={studentCourse.startDate}
                        onChange={this.handleDateChange('startDate')}
                        KeyboardButtonProps={{
                          'aria-label': 'change start date'
                        }}
                      />
                      {/* <KeyboardDatePicker
                      margin="normal"
                      label="End Date"
                      format="dd/MM/yyyy"
                      value={studentCourse.endDate}
                      onChange={this.handleDateChange('endDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change end date'
                      }}
                    /> */}
                    </Grid>
                  </MuiPickersUtilsProvider>
                </ListItem>

                {studentCourse.studentType === '3' ? (
                  <ListItem className={classes.listItem}>
                    <TextField
                      name="numberOfDemoSessions"
                      style={{ margin: 8 }}
                      fullWidth
                      type="number"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      className={classes.margin}
                      label="Number of demo sessions"
                      value={studentCourse.numberOfDemoSessions}
                      onChange={this.handleChange}
                    />
                  </ListItem>
                ) : (
                  <Fragment>
                    <ListItem className={classes.listItem}>
                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                      >
                        <InputLabel>Payment Type</InputLabel>
                        <Select
                          name="paymentType"
                          value={studentCourse.paymentType}
                          onChange={this.handleChange}
                        >
                          <MenuItem value="1">4 Months</MenuItem>
                          <MenuItem value="2">6 Months</MenuItem>
                          <MenuItem value="3">12 Months</MenuItem>
                          <MenuItem value="4">24 Months</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        name="numberOfSession"
                        style={{ margin: 8 }}
                        fullWidth
                        type="number"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        className={classes.margin}
                        label="Number of sessions"
                        value={studentCourse.numberOfSession}
                        onChange={this.handleChange}
                      />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <TextField
                        name="amount"
                        style={{ margin: 8 }}
                        fullWidth
                        type="number"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        className={classes.margin}
                        label="Amount"
                        value={studentCourse.amount}
                        onChange={this.handleChange}
                      />
                    </ListItem>
                  </Fragment>
                )}
              </List>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={clsx(classes.margin, classes.textField)}>
                <InputLabel>Transfer type</InputLabel>
                <Select
                  name="transferType"
                  value={studentCourse.transferType}
                  onChange={this.handleChange}
                >
                  <MenuItem value="1">Tiền Mặt</MenuItem>
                  <MenuItem value="2">Chuyển Khoản</MenuItem>
                  <MenuItem value="4">Thanh Toán Bằng Thẻ</MenuItem>
                  <MenuItem value="3">Khác</MenuItem>
                </Select>
              </FormControl>

              {studentCourse.transferType?.toString() === '3' && (
                <TextField
                  name="transferTypeDescription"
                  style={{ margin: 8, width: '80%' }}
                  fullWidth
                  type="text"
                  className={classes.margin}
                  label="Transfer Type Description"
                  value={studentCourse.transferTypeDescription}
                  onChange={this.handleChange}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  }
}

StudentPayment.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesEditStudent)(StudentPayment);
