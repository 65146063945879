import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Loading } from '../../ui/Loading';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CardHeader from '@material-ui/core/CardHeader';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import CustomizedTable from '../../ui/table/CustomizedTable';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ParentPaths, ParentActions } from './ParentConstans';
import { StudentPaths } from '../StudentConstans';
import { ParentSelect } from './ParentSelect';
import DialogTitle from '@material-ui/core/DialogTitle';
const stylesEditParent = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  },
  groupSchedule: {
    marginTop: '20px'
  },
  fab: {
    margin: '12px 8px 8px 8px'
  },
  padding10: {
    padding: '10px'
  }
});

class EditParentComp extends Component {
  static displayName = EditParentComp.name;
  constructor(...args) {
    super(...args);
    this.parentForm = React.createRef();
    this.child = React.createRef();
    this.state = {
      action: this.props.action,
      parentId: this.props.parentId,
      method: 'PUT',
      parent: {
        name: '',
        yob: '',
        job: '',
        relation: '',
        email: '',
        phoneNumber: '',
        address: ''
      },
      redirect: null,
      loading: true,
      openDialog: false,
      openDialogLearningOutcome: false,
      lstSelected: []
    };

    switch (this.props.action) {
      case ParentActions.Create:
        this.state.method = 'POST';
        this.state.action = `api/Parents`;
        this.state.loading = false;
        break;
      case ParentActions.Edit:
        this.state.action = `api/Parents/${this.state.parentId}`;
        this.populateParentData();
        break;
      case undefined:
      default:
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  populateParentData = async () => {
    const token = await authService.getAccessToken();
    const { parentId } = this.state;
    const response = await fetch(`api/Parents/${parentId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    const responseStudent = await fetch(
      `api/StudentParents/getStudentByParentIds/` + this.state.parentId,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const dataStudent = await responseStudent.json();
    this.setState({
      parent: data,
      student: dataStudent,
      loading: false
    });
  };

  backToList = () => {
    this.redirectTo(ParentPaths.List);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { parent, method, action, token } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    if (parent.relation === '') {
      parent.relation = 1;
    }
    fetch(action, {
      method: method,
      body: JSON.stringify(parent),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  submitData = async () => {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    ReactDOM.findDOMNode(this.parentForm.current).dispatchEvent(
      new Event('submit')
    );
  };

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      parent: {
        ...prevState.parent,
        [attrName]: attrValue
      }
    }));
    if (name === 'phoneNumber') {
      var cleaned = ('' + attrValue).replace(/\D/g, '');
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = match[1] ? '+1 ' : '',
          number = [
            intlCode,
            '(',
            match[2],
            ') ',
            match[3],
            '-',
            match[4]
          ].join('');
        this.setState(prevState => ({
          parent: {
            ...prevState.parent,
            [attrName]: number
          }
        }));

        return;
      }
      this.setState(prevState => ({
        parent: {
          ...prevState.parent,
          [attrName]: attrValue
        }
      }));
    }
  };

  reloadTbl = async () => {
    await this.populateStudentCompData();
    this.child.updateChildData(this.state.student);
  };

  callbackValueCheckboxTabl = e => {
    this.setState({ lstSelected: e });
  };

  populateStudentCompData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch(
      `api/StudentParents/getStudentByParentIds/` + this.state.parentId,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );
    const data = await response.json();
    this.setState({ student: data });
  };

  openDialog = () => {
    this.setState({ openDialog: true });
  };

  closeDialog = () => {
    this.setState({ openDialog: false });
  };

  openDialogLearningOutcomes = () => {
    this.setState({ openDialogLearningOutcome: true });
  };

  closeDialogLearningOutcomes = () => {
    this.setState({ openDialogLearningOutcome: false });
  };

  saveDataSelected = () => {
    const token = authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    fetch(`api/StudentParents/addStudent/` + this.state.parentId, {
      method: 'POST',
      body: JSON.stringify(this.state.lstSelected),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.reloadTbl();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    this.closeDialog();
  };

  showPopup = (row, nameAct) => {
    this.openDialogLearningOutcomes();
  };

  renderForm() {
    const { classes } = this.props;
    const { parent } = this.state;
    const cols = [
      {
        name: 'name',
        header: 'Name',
        placeholder: 'Name',
        adornment: ''
      },
      {
        name: 'email',
        header: 'Email',
        placeholder: 'Email',
        adornment: ''
      },
      {
        name: 'phoneNumber',
        header: 'Phone Number',
        placeholder: 'Phone Number',
        adornment: ''
      },
      {
        name: 'address',
        header: 'Address',
        placeholder: 'Address',
        adornment: ''
      },
      {
        name: 'yob',
        header: 'Year of Birth',
        placeholder: 'Year of Birth',
        adornment: ''
      },
      {
        name: 'relation',
        header: 'Relation',
        placeholder: 'Relation',
        adornment: ''
      },
      {
        name: 'job',
        header: 'Job',
        placeholder: 'Job',
        adornment: ''
      }
    ];
    const colsStudent = [
      { name: 'studentName', header: 'Name', align: 'left' },
      { name: 'englishName', header: 'English Name' },
      { name: 'birthdayFormat', header: 'Birthday' }
    ];
    const actionsStudent = [
      {
        name: 'edit',
        tooltip: 'View Details',
        icon: 'contacts',
        url: `${StudentPaths.Edit}`
      },
      {
        name: 'learningOutcomes',
        tooltip: 'Learning Outcomes',
        icon: 'assignment_turned_in'
      },
      {
        name: 'delete',
        tooltip: 'Remove Student',
        icon: 'delete_icon',
        url: `StudentParents`
      }
    ];
    return (
      <Fragment>
        <Paper className={classes.root}>
          <form
            ref={this.parentForm}
            className={classes.container}
            onSubmit={this.handleSubmit}
          >
            <Grid container>
              <Grid item xs={6} className={classes.cell}>
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="name"
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      rows="6"
                      className={classes.margin}
                      label={cols[0].header}
                      placeholder={cols[0].placeholder}
                      name={cols[0].name}
                      value={parent[cols[0].name]}
                      onChange={this.handleChange(cols[0].name)}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <FormControl
                      className={classNames(classes.margin, classes.textField)}
                    >
                      <InputLabel htmlFor="phone-number">
                        {cols[2].header}
                      </InputLabel>
                      <Input
                        id="phone-number"
                        name={cols[2].name}
                        value={parent[cols[2].name]}
                        onChange={this.handleChange(cols[2].name)}
                        endAdornment={
                          <InputAdornment position="end">
                            {cols[2].adornment}
                          </InputAdornment>
                        }
                        inputProps={{
                          'aria-label': cols[2].header
                        }}
                      />
                    </FormControl>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <FormControl
                      className={classNames(classes.margin, classes.textField)}
                    >
                      <InputLabel htmlFor="yob">{cols[4].header}</InputLabel>
                      <Input
                        id="yob"
                        name={cols[4].name}
                        value={parent[cols[4].name]}
                        onChange={this.handleChange(cols[4].name)}
                        endAdornment={
                          <InputAdornment position="end">
                            {cols[4].adornment}
                          </InputAdornment>
                        }
                        inputProps={{
                          'aria-label': cols[4].header
                        }}
                      />
                    </FormControl>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <FormControl
                      className={classNames(classes.margin, classes.textField)}
                    >
                      <InputLabel htmlFor="relation">
                        {cols[5].header}
                      </InputLabel>
                      <Select
                        id="relation"
                        name={cols[5].name}
                        value={
                          parent[cols[5].name] === '' ? 1 : parent[cols[5].name]
                        }
                        onChange={this.handleChange(cols[5].name)}
                      >
                        <MenuItem value="1">Father</MenuItem>
                        <MenuItem value="2">Mother</MenuItem>
                      </Select>
                    </FormControl>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} className={classes.cell}>
                <List>
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="email"
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      rows="6"
                      className={classes.margin}
                      label={cols[1].header}
                      placeholder={cols[1].placeholder}
                      name={cols[1].name}
                      value={parent[cols[1].name]}
                      onChange={this.handleChange(cols[1].name)}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="address"
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      rows="6"
                      className={classes.margin}
                      label={cols[3].header}
                      placeholder={cols[3].placeholder}
                      name={cols[3].name}
                      value={parent[cols[3].name]}
                      onChange={this.handleChange(cols[3].name)}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <TextField
                      id="job"
                      style={{ margin: 8 }}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      rows="6"
                      className={classes.margin}
                      label={cols[6].header}
                      placeholder={cols[6].placeholder}
                      name={cols[6].name}
                      value={parent[cols[6].name]}
                      onChange={this.handleChange(cols[6].name)}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.submitData}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.backToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
        {this.props.action !== ParentActions.Create ? (
          <div>
            <Paper className={classes.root}>
              <Fragment>
                <CardHeader title="Student" />
                <Grid className={classes.padding10}>
                  <CustomizedTable
                    onRef={actualChild => (this.child = actualChild)}
                    rows={this.state.student}
                    cols={colsStudent}
                    actions={actionsStudent}
                    isShowPopup="true"
                    callbackGetIdShowPopup={this.showPopup}
                    eventReloadData={this.reloadTbl}
                  />
                </Grid>
                <Dialog
                  open={this.state.openDialog}
                  onClose={this.closeDialog}
                  aria-labelledby="form-dialog-title"
                  maxWidth="lg"
                  fullWidth
                >
                  <DialogContent>
                    <ParentSelect
                      callbackValueCheckboxTabl={this.callbackValueCheckboxTabl}
                      parentId={this.state.parentId}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.closeDialog} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={this.saveDataSelected} color="primary">
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={this.state.openDialogLearningOutcome}
                  onClose={this.closeDialogLearningOutcomes}
                  aria-labelledby="form-dialog-title"
                  maxWidth="lg"
                  fullWidth
                >
                  <DialogTitle id="alert-dialog-slide-title">
                    {'Learning Outcomes'}
                  </DialogTitle>
                  <DialogContent />
                </Dialog>
                <Fab
                  aria-label="Save"
                  color="primary"
                  className={classes.fab}
                  onClick={this.openDialog}
                >
                  <AddIcon />
                </Fab>
              </Fragment>
            </Paper>
          </div>
        ) : (
          ''
        )}
        {this.state.redirect}
      </Fragment>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderForm();
    return <Fragment>{contents}</Fragment>;
  }
}

EditParentComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const EditParent = withStyles(stylesEditParent)(EditParentComp);
