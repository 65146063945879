import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

export const DialogType = {
  ApproveMaterial: 'approveMaterial',
  ApproveAllMaterial: 'approveAllMaterial',
  ReorderLessonPlan: 'reorderLessonPlan',
  Delete: 'delete',
  DeleteApproves: 'deleteApproves',
  Suspend: 'suspend',
  SaveScoreBoard: 'saveScoreBoard',
  SavePayment: 'savePayment',
  DeletePayment: 'deletePayment',
  ClassProposalAddStudent: 'classProposalAddStudent',
  Command: 'command'
};

export const DialogText = {
  Title: {
    approveMaterial: 'Material approval confirmation',
    delete: 'Delete confirmation',
    approveAllMaterial: 'All Material approval confirmation',
    reorderLessonPlan: 'Reorder Lesson Plan',
    deleteApproves: 'Delete confirmation',
    suspend: 'Suspend confirmation',
    saveScoreBoard: 'Save score board',
    savePayment: 'Save payment',
    deletePayment: 'Delete confirmation',
    classProposalAddStudent: 'Action confirmation',
    command: 'Command confirmation'
  },
  Content: {
    approveMaterial: 'Are you sure you want to approve this material?',
    delete: 'Are you sure you want to delete this item?',
    approveAllMaterial: 'Are you sure you want to approve all materials?',
    reorderLessonPlan: 'Are you sure you want to reorder lesson plans?',
    deleteApproves: 'Are you sure you want to delete all materials?',
    suspend: 'Are you sure you want to suspend this student?',
    saveScoreBoard: 'Are you sure to save and close scoreboard today?',
    savePayment: 'Are you sure to save the payment?',
    deletePayment: 'Are you sure to delete the payment?',
    classProposalAddStudent:
      'Are you sure to save the information of this student?',
    command: 'Are you sure you want to do this command?'
  }
};

export class Dialogs extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      open: false,
      title: this.props.title,
      message: this.props.message,
      commands: this.props.commands
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  setOpen = openState => {
    this.setState({ open: openState });
  };

  showDialog = dlgType => {
    this.setState({
      title: DialogText.Title[dlgType],
      message: DialogText.Content[dlgType],
      open: true
    });
  };

  showDialogWithCmds = (dlgType, dlgCommands) => {
    this.setState({
      title: DialogText.Title[dlgType],
      message: DialogText.Content[dlgType],
      commands: dlgCommands,
      open: true
    });
  };

  openDialog = () => {
    this.setState({ open: true });
  };

  closeDialog = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.closeDialog}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        style={{ padding: '15px' }}
      >
        <DialogTitle id="alert-dialog-title">{this.state.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.state.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '15px', justifyContent: 'center' }}>
          {this.state.commands ? (
            this.state.commands
          ) : (
            <Button onClick={this.closeDialog} color="primary">
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}
