import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';

class TeacherInfo extends Component {
  render() {
    const { classes, teacherInfo } = this.props;

    const cols = [
      { name: 'name', header: 'Name', xs: 3 },
      { name: 'englishName', header: 'English Name', xs: 2 },
      { name: 'phone', header: 'Phone', xs: 2 },
      { name: 'email', header: 'Email', xs: 5 }
    ];

    return (
      <Paper className={classes.root}>
        <Grid container>
          {cols.map((col, idx) => (
            <Grid item xs={col.xs} className={classes.cell} key={idx}>
              <Typography color="textSecondary" variant="caption">
                {col.header}
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {teacherInfo[col.name]}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }
}

const stylesTeacherInfo = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    overflowX: 'auto'
  },
  cell: {
    padding: theme.spacing(1, 0, 1, 2)
  }
});

TeacherInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesTeacherInfo)(TeacherInfo);
