import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import { Loading } from '../../ui/Loading';
import { stylesCharting } from '../../class-course/reports/stylesCharting';
import { stylesAcademicInfo } from '../../academic-manage/stylesAcademicInfo';
import NormalTable from '../../ui/table/normal/NormalTable';

class TeacherClasses extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.populateTeacherData();
  }

  populateTeacherData = async () => {
    const tableCols = [
      {
        name: 'className',
        header: 'Class',
        align: 'left'
      },
      {
        name: 'level',
        header: 'Level',
        align: 'left'
      },
      {
        name: 'coTeacher',
        header: 'Co-Teacher',
        align: 'left'
      },
      {
        name: 'time',
        header: 'Time',
        align: 'left'
      },
      {
        name: 'noS',
        header: 'NoS',
        align: 'center'
      },
      {
        name: 'recentLesson',
        header: 'Recent Lesson',
        align: 'left'
      }
    ];

    this.setState({
      tableCols,
      loading: false
    });
  };

  render() {
    const { loading } = this.state;
    let content = loading ? <Loading /> : this.renderUI();

    return <Fragment>{content}</Fragment>;
  }

  renderUI() {
    const { teacherClasses, classes } = this.props;
    const { tableCols } = this.state;

    return (
      <Fragment>
        <Grid container className={classes.stdHeadline}>
          <div className={classes.stdSubtitle}>
            <Typography variant="h3" className={classes.sectionTitle}>
              {"Teacher's Class"}
            </Typography>
          </div>
          <div className={classes.stdSubtitleHr}>
            <span className={clsx(classes.stdLineCell)}></span>
          </div>
        </Grid>

        <Grid container className={classes.stdBox} spacing={2}>
          <Grid item xs={12}>
            <div className={clsx(classes.chartBoxTopPad)}>
              <NormalTable data={teacherClasses} cols={tableCols} />
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const stylesLessonStatistics = theme => ({
  ...stylesCharting(theme),
  ...stylesAcademicInfo(theme)
});

TeacherClasses.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesLessonStatistics)(TeacherClasses);
