import React, { Component } from 'react';
import clsx from 'clsx';
import { Grid, Paper, Typography } from '@material-ui/core';

export class PaymentInfoView extends Component {
  render() {
    const {
      title,
      subtitle,
      totalPayment,
      remainPayment,
      classes
    } = this.props;

    return (
      <Paper
        className={classes.chartBox}
        style={{ padding: '20px 20px 20px 30px' }}
      >
        <Typography variant="h3" className={clsx(classes.heading)}>
          {title}
        </Typography>
        <Typography variant="body2" className={clsx(classes.subtitle)}>
          {subtitle}
        </Typography>
        <div
          className={classes.chartWrap}
          style={{ height: '90px', display: 'flex' }}
        >
          <div style={{ flexGrow: 0 }}>
            <Typography
              variant="h1"
              className={clsx(
                classes.chartMediumNumber,
                classes.chartLabelBlue
              )}
            >
              {Number(totalPayment).toLocaleString('vi-VN')}
            </Typography>
            <hr
              className={clsx(classes.chartNumberLine, classes.chartLabelBlue)}
              style={{ marginTop: -7 }}
            />
          </div>
          <div style={{ flexGrow: 1, maxWidth: '100%', flexBasis: 0 }} />
        </div>
        <Grid container>
          <div style={{ flexGrow: 1, maxWidth: '100%', flexBasis: 0 }}>
            <Typography variant="h3" className={clsx(classes.heading)}>
              {'Total of remaining Payments'}
            </Typography>
            <Typography variant="body2" className={clsx(classes.subtitle)}>
              {'Tổng học phí còn lại của lớp'}
            </Typography>
            <Typography variant="body2" className={clsx(classes.subtitle)}>
              {'(Tổng học phí thu còn lại của từng học sinh)'}
            </Typography>
          </div>
          <div style={{ flexGrow: 0 }}>
            <div className={classes.chartNumberBox}>
              <Typography
                variant="h1"
                className={clsx(
                  classes.chartSmallerNumber,
                  classes.chartLabelOrange
                )}
              >
                {Number(remainPayment).toLocaleString('vi-VN')}
              </Typography>
              <hr
                className={clsx(
                  classes.chartNumberLine,
                  classes.chartLabelOrange
                )}
                style={{ marginTop: -5 }}
              />
            </div>
          </div>
        </Grid>
      </Paper>
    );
  }
}
