export const ParentActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete'
};

const prefix = '/parents';

export const ParentPaths = {
  ParentPrefix: prefix,
  List: `${prefix}/${ParentActions.List}`,
  EditRoute: `${prefix}/${ParentActions.Edit}/:parentId`,
  Edit: `${prefix}/${ParentActions.Edit}`,
  Create: `${prefix}/${ParentActions.Create}`,
  Delete: `Parents`
};
