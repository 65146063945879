import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    overflowX: 'auto'
  },
  cell: {
    padding: theme.spacing(1, 0, 1, 2)
  },
  schedule: {
    margin: theme.spacing(1, 0, 0, 2)
  }
});

class ScheduleInfoComp extends Component {
  static displayName = ScheduleInfoComp.name;

  constructor(...args) {
    super(...args);
    this.state = {
      schedule: this.props.schedule
    };
  }

  render() {
    const { classes, schedule } = this.props;
    var daysOfWeek = [];
    if (schedule.monday) {
      daysOfWeek.push('Monday');
    }
    if (schedule.tuesday) {
      daysOfWeek.push('Tuesday');
    }
    if (schedule.wednesday) {
      daysOfWeek.push('Wednesday');
    }
    if (schedule.thursday) {
      daysOfWeek.push('Thursday');
    }
    if (schedule.friday) {
      daysOfWeek.push('Friday');
    }
    if (schedule.saturday) {
      daysOfWeek.push('Saturday');
    }
    if (schedule.sunday) {
      daysOfWeek.push('Sunday');
    }
    schedule.schedule = daysOfWeek.join(', ');
    const cols = [
      {
        name: 'name',
        header: 'Name',
        xs: 3
      },
      {
        name: 'schedule',
        header: 'Schedule',
        xs: 9
      }
    ];

    return (
      <Paper className={classes.root}>
        <Grid container>
          {cols.map((col, idx) => (
            <Grid item xs={col.xs} className={classes.cell} key={idx}>
              <Typography color="textSecondary" variant="caption">
                {col.header}
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {col.isObj ? schedule[col.name].name : schedule[col.name]}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }
}

ScheduleInfoComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ScheduleInfo = withStyles(styles)(ScheduleInfoComp);
