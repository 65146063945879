import { MaterialActions, MaterialPaths } from './MaterialConstants';
import React, { Component, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputBase } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Loading } from '../ui/Loading';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import authService from '../api-authorization/AuthorizeService';
import classNames from 'classnames';
import clsx from 'clsx';
import { stylesEditMaterial } from './stylesEditMaterial';
import { withStyles } from '@material-ui/core/styles';

class EditMaterialComp extends Component {
  static displayName = EditMaterialComp.name;

  constructor(...args) {
    super(...args);
    this.levelForm = React.createRef();
    this.state = {
      method: 'POST',
      action: 'api/Materials',
      redirect: null,
      gettingTeacher: true,
      loading: true,
      fileUpload: true,
      fileNameUpload: '',
      material: {
        name: '',
        description: '',
        materialFormatStr: '',
        materialCategories: '',
        teacherId: null,
        approved: true,
        openNewTab: false,
        fileName: ''
      }
    };
    switch (this.props.action) {
      case MaterialActions.Create:
        this.state.loading = false;
        this.state.gettingTeacher = false;
        break;
      case MaterialActions.CreateByTeacher:
        this.state.loading = false;
        this.state.material.approved = false;
        this.populateTeacher();
        break;
      case MaterialActions.Edit:
      case MaterialActions.EditByTeacher:
        this.state.method = 'PUT';
        this.state.action = `api/Materials/${this.props.materialId}`;
        this.state.gettingTeacher = false;
        this.populateMaterialData();
        break;
      case undefined:
      default:
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  async populateTeacher() {
    const loggedInUser = await authService.getUser();
    const { material: updMaterial } = this.state;
    updMaterial.teacherId = loggedInUser.sub;
    this.setState({ material: updMaterial, gettingTeacher: false });
  }

  async populateMaterialData() {
    const token = await authService.getAccessToken();
    const response = await fetch(`api/Materials/${this.props.materialId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    data.materialFormatStr = data.materialFormat
      ? data.materialFormat.toString()
      : '';
    this.setState({
      material: data,
      loading: false,
      fileNameUpload: data.fileName
    });
  }

  handleChange = name => event => {
    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;

    this.setState(prevState => ({
      material: {
        ...prevState.material,
        [attrName]: attrValue
      }
    }));
  };

  handleChangeFile = e => {
    const form = e.target.files;
    this.setState({ fileUpload: form, fileNameUpload: form[0].name });
  };

  backToList() {
    if (
      this.props.action === MaterialActions.EditByTeacher ||
      this.props.action === MaterialActions.CreateByTeacher
    ) {
      this.redirectTo(MaterialPaths.ListByTeacher);
    } else {
      this.redirectTo(MaterialPaths.List);
    }
  }

  async submitData() {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    ReactDOM.findDOMNode(this.levelForm.current).dispatchEvent(
      new Event('submit')
    );
  }

  handleSubmit = async e => {
    e.preventDefault();

    const { material, method, action, token, fileUpload } = this.state;
    material.materialFormat = Number(material.materialFormatStr);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    fetch(action, {
      method: method,
      body: JSON.stringify(material),
      headers: headers
    })
      .then(async response => {
        if (response.ok) {
          var marterialId = '';
          if (method === 'POST') {
            const dataReponse = await response.json();
            marterialId = dataReponse.id;
          } else {
            marterialId = this.props.materialId;
          }
          const data = new FormData();
          data.append('files', fileUpload[0]);
          fetch(`api/Materials/UploadFile/${marterialId}`, {
            method: 'POST',
            body: data,
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
          })
            .then(response => {
              if (response.ok) {
                this.backToList();
              } else throw new Error(response.status);
            })
            .catch(error => {
              console.log(error);
            });
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  renderForm() {
    const { classes } = this.props;
    const { material } = this.state;
    const cols = [
      {
        name: 'name',
        header: 'Name',
        placeholder: 'Name',
        adornment: ''
      },
      {
        name: 'description',
        header: 'Description',
        placeholder: 'Description',
        adornment: ''
      },
      {
        name: 'materialFormatStr',
        header: 'Format',
        placeholder: 'Format',
        adornment: ''
      },
      {
        name: 'url',
        header: 'Link',
        placeholder: 'Link',
        adornment: ''
      },
      {
        name: 'materialCategories',
        header: 'Categories',
        placeholder: 'Categories',
        adornment: ''
      }
    ];

    return (
      <Paper className={classes.root}>
        <form
          ref={this.levelForm}
          className={classes.container}
          onSubmit={this.handleSubmit}
        >
          <Grid container>
            <Grid item xs={6}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <TextField
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[0].header}
                    placeholder={cols[0].placeholder}
                    name={cols[0].name}
                    value={material[cols[0].name]}
                    onChange={this.handleChange(cols[0].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    fullWidth
                    multiline
                    rows="4"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[1].header}
                    placeholder={cols[1].placeholder}
                    name={cols[1].name}
                    value={material[cols[1].name]}
                    onChange={this.handleChange(cols[1].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControl
                    className={classNames(classes.margin, classes.textField)}
                  >
                    <InputLabel htmlFor="material-format">
                      {cols[2].header}
                    </InputLabel>
                    <Select
                      id="material-format"
                      name={cols[2].name}
                      value={material[cols[2].name]}
                      onChange={this.handleChange(cols[2].name)}
                    >
                      {/* <MenuItem value="0" /> */}
                      <MenuItem value="1">File hình ảnh</MenuItem>
                      <MenuItem value="2">File Video</MenuItem>
                      <MenuItem value="3">File PDF</MenuItem>
                      <MenuItem value="4">Link Video</MenuItem>
                      <MenuItem value="5">HTML</MenuItem>
                    </Select>
                  </FormControl>
                  {material.materialFormatStr === '4' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={material.openNewTab}
                          onChange={this.handleChange('openNewTab')}
                          value="openNewTab"
                        />
                      }
                      label="Open new tab"
                      className={classes.checkField}
                    />
                  )}
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControl
                    className={classNames(
                      classes.margin,
                      classes.longTextField
                    )}
                  >
                    <InputLabel htmlFor="material-url">
                      {cols[3].header}
                    </InputLabel>
                    <Input
                      id="material-url"
                      name={cols[3].name}
                      value={material[cols[3].name] || ''}
                      onChange={this.handleChange(cols[3].name)}
                      endAdornment={
                        <InputAdornment position="end">
                          {cols[3].adornment}
                        </InputAdornment>
                      }
                      inputProps={{
                        'aria-label': cols[3].header
                      }}
                    />
                  </FormControl>
                </ListItem>
                <ListItem>
                  <InputBase
                    type="file"
                    id="button-file"
                    style={{ display: 'none' }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                    onChange={this.handleChangeFile}
                  />
                  <div className={clsx(classes.inputGroup, classes.margin)}>
                    <label htmlFor="button-file">
                      <Button component="span" className={classes.btnUpload}>
                        <svg
                          className={classes.styleSvg}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="17"
                          viewBox="0 0 20 17"
                        >
                          <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                        </svg>
                        <span className={classes.txtUpload}>CHOOSE FILE</span>
                      </Button>
                    </label>
                    <Input
                      id="material-upload"
                      name="upload"
                      className={classes.inputFile}
                      disabled={true}
                      value={this.state.fileNameUpload || 'Upload file'}
                      inputProps={{
                        'aria-label': 'CHOOSE FILE'
                      }}
                    />
                  </div>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel component="legend" className={classes.legend}>
                      Categories
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.math}
                            onChange={this.handleChange('math')}
                            value="math"
                          />
                        }
                        label="Math"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.science}
                            onChange={this.handleChange('science')}
                            value="science"
                          />
                        }
                        label="Science"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.music}
                            onChange={this.handleChange('music')}
                            value="music"
                          />
                        }
                        label="Music"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.phonics}
                            onChange={this.handleChange('phonics')}
                            value="phonics"
                          />
                        }
                        label="Phonics"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.sightwords}
                            onChange={this.handleChange('sightwords')}
                            value="sightwords"
                          />
                        }
                        label="Sightwords"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.english}
                            onChange={this.handleChange('english')}
                            value="english"
                          />
                        }
                        label="English"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.softSkills}
                            onChange={this.handleChange('softSkills')}
                            value="softSkills"
                          />
                        }
                        label="Soft skills"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.activityBook}
                            onChange={this.handleChange('activityBook')}
                            value="activityBook"
                          />
                        }
                        label="Activity book"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.studentBook}
                            onChange={this.handleChange('studentBook')}
                            value="studentBook"
                          />
                        }
                        label="Student's book"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.teacherPlanner}
                            onChange={this.handleChange('teacherPlanner')}
                            value="teacherPlanner"
                          />
                        }
                        label="Teacher planner"
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel component="legend" className={classes.legend}>
                      &nbsp;
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.m0}
                            onChange={this.handleChange('m0')}
                            value="m0"
                          />
                        }
                        label="M0"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.m1}
                            onChange={this.handleChange('m1')}
                            value="m1"
                          />
                        }
                        label="M1"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.a}
                            onChange={this.handleChange('a')}
                            value="a"
                          />
                        }
                        label="A"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.m}
                            onChange={this.handleChange('m')}
                            value="m"
                          />
                        }
                        label="M"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.e}
                            onChange={this.handleChange('e')}
                            value="e"
                          />
                        }
                        label="E"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.v}
                            onChange={this.handleChange('v')}
                            value="v"
                          />
                        }
                        label="V"
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel component="legend" className={classes.legend}>
                      &nbsp;
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.m2}
                            onChange={this.handleChange('m2')}
                            value="m2"
                          />
                        }
                        label="M2"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.m3}
                            onChange={this.handleChange('m3')}
                            value="m3"
                          />
                        }
                        label="M3"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.c}
                            onChange={this.handleChange('c')}
                            value="c"
                          />
                        }
                        label="C"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.j}
                            onChange={this.handleChange('j')}
                            value="j"
                          />
                        }
                        label="J"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.s}
                            onChange={this.handleChange('s')}
                            value="s"
                          />
                        }
                        label="S"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={material.u}
                            onChange={this.handleChange('u')}
                            value="u"
                          />
                        }
                        label="U"
                      />
                    </FormGroup>
                  </FormControl>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </form>
        {this.state.redirect}
      </Paper>
    );
  }

  render() {
    let contents =
      this.state.loading || this.state.gettingTeacher ? (
        <Loading />
      ) : (
        this.renderForm()
      );

    return <Fragment>{contents}</Fragment>;
  }
}

EditMaterialComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const EditMaterial = withStyles(stylesEditMaterial)(EditMaterialComp);
