export class SortUtils {
  static descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  static localeDescendingComparator = (a, b, orderBy) => {
    const vnCollator = new Intl.Collator('vn');
    return vnCollator.compare(b[orderBy], a[orderBy]);
  };

  static getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => SortUtils.localeDescendingComparator(a, b, orderBy)
      : (a, b) => -SortUtils.localeDescendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  static stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
}
