import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Loading } from '../../ui/Loading';
import { LessonPlanInfo } from '../lessons/LessonPlanInfo';
import { UnitPlanPaths, UnitPlanActions } from './UnitPlanConstants';

const stylesEditUnitPlan = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  }
});

class EditUnitPlanComp extends Component {
  static displayName = EditUnitPlanComp.name;

  constructor(...args) {
    super(...args);
    this.unitForm = React.createRef();
    this.state = {
      action: this.props.action,
      lessonId: this.props.lessonId,
      planId: this.props.planId,
      method: 'PUT',
      formAction: null,
      lessonPlan: {},
      unitPlan: {},
      redirect: null,
      loading: true
    };
    switch (this.props.action) {
      case UnitPlanActions.Create:
        this.state.unitPlan = {
          lessonPlanId: this.state.lessonId,
          timeStr: '',
          procedures: '',
          description: '',
          materials: '',
          teacherActivities: '',
          learningOutcome: '',
          note: ''
        };
        this.state.method = 'POST';
        this.state.formAction = `api/UnitPlans`;
        this.populateLessonData();
        break;
      case UnitPlanActions.Edit:
        this.state.formAction = `api/UnitPlans/${this.state.planId}`;
        this.populateUnitPlanData();
        break;
      case undefined:
      default:
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  async populateLessonData() {
    const token = await authService.getAccessToken();
    const { lessonId } = this.state;
    const response = await fetch(`api/LessonPlans/${lessonId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ lessonPlan: data, loading: false });
  }

  async populateUnitPlanData() {
    const token = await authService.getAccessToken();
    const { planId } = this.state;
    const response = await fetch(`api/UnitPlans/${planId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();

    data.timeStr = data.time ? data.time.toString() : '';
    this.setState({ unitPlan: data, loading: false });
  }

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState(prevState => ({
      unitPlan: {
        ...prevState.unitPlan,
        [attrName]: attrValue
      }
    }));
  };

  backToList() {
    const { unitPlan } = this.state;
    this.redirectTo(`${UnitPlanPaths.List}/${unitPlan.lessonPlanId}`);
  }

  async submitData() {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    ReactDOM.findDOMNode(this.unitForm.current).dispatchEvent(
      new Event('submit')
    );
  }

  handleSubmit = e => {
    e.preventDefault();

    const { unitPlan, method, formAction, token } = this.state;
    unitPlan.time = Number(unitPlan.timeStr);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    fetch(formAction, {
      method: method,
      body: JSON.stringify(unitPlan),
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  renderForm() {
    const { classes } = this.props;
    const { unitPlan } = this.state;
    const cols = [
      {
        name: 'timeStr',
        header: 'Time',
        placeholder: 'Time',
        adornment: 'mins'
      },
      {
        name: 'procedures',
        header: 'Procedures',
        placeholder: 'Procedures',
        adornment: ''
      },
      {
        name: 'description',
        header: 'Description',
        placeholder: 'Description',
        adornment: ''
      },
      {
        name: 'materials',
        header: 'Materials',
        placeholder: 'Materials',
        adornment: ''
      },
      {
        name: 'teacherActivities',
        header: "Teachers' activities",
        placeholder: "Teachers' activities",
        adornment: ''
      },
      {
        name: 'learningOutcome',
        header: "Students' learning outcome",
        placeholder: "Students' learning outcome",
        adornment: ''
      },
      {
        name: 'note',
        header: 'Note',
        placeholder: '',
        adornment: ''
      }
    ];

    return (
      <Paper className={classes.root}>
        <form
          ref={this.unitForm}
          className={classes.container}
          onSubmit={this.handleSubmit}
        >
          <Grid container>
            <Grid item xs={6} className={classes.cell}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <FormControl
                    className={classNames(classes.margin, classes.textField)}
                  >
                    <InputLabel htmlFor="unit-time">
                      {cols[0].header}
                    </InputLabel>
                    <Input
                      id="unit-time"
                      name={cols[0].name}
                      value={unitPlan[cols[0].name]}
                      onChange={this.handleChange(cols[0].name)}
                      endAdornment={
                        <InputAdornment position="end">
                          {cols[0].adornment}
                        </InputAdornment>
                      }
                      inputProps={{
                        'aria-label': cols[0].header
                      }}
                    />
                  </FormControl>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="unit-procedures"
                    fullWidth
                    multiline
                    rows="2"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[1].header}
                    placeholder={cols[1].placeholder}
                    name={cols[1].name}
                    value={unitPlan[cols[1].name]}
                    onChange={this.handleChange(cols[1].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="unit-description"
                    fullWidth
                    multiline
                    rows="5"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[2].header}
                    placeholder={cols[2].placeholder}
                    name={cols[2].name}
                    value={unitPlan[cols[2].name]}
                    onChange={this.handleChange(cols[2].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="unit-materials"
                    fullWidth
                    multiline
                    rows="2"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[3].header}
                    placeholder={cols[3].placeholder}
                    name={cols[3].name}
                    value={unitPlan[cols[3].name]}
                    onChange={this.handleChange(cols[3].name)}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6} className={classes.cell}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="unit-teacher-activities"
                    fullWidth
                    multiline
                    rows="5"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[4].header}
                    placeholder={cols[4].placeholder}
                    name={cols[4].name}
                    value={unitPlan[cols[4].name]}
                    onChange={this.handleChange(cols[4].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="unit-learning-outcome"
                    fullWidth
                    multiline
                    rows="4"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[5].header}
                    placeholder={cols[5].placeholder}
                    name={cols[5].name}
                    value={unitPlan[cols[5].name]}
                    onChange={this.handleChange(cols[5].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="unit-note"
                    fullWidth
                    multiline
                    rows="4"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[6].header}
                    placeholder={cols[6].placeholder}
                    name={cols[6].name}
                    value={unitPlan[cols[6].name]}
                    onChange={this.handleChange(cols[6].name)}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </form>
        {this.state.redirect}
      </Paper>
    );
  }

  render() {
    let lessonPlanInfo = this.state.loading ? (
      <Loading />
    ) : (
      <LessonPlanInfo
        lessonPlan={
          this.state.action === UnitPlanActions.Create
            ? this.state.lessonPlan
            : this.state.unitPlan.lessonPlan
        }
      />
    );
    let contents = this.state.loading ? <Loading /> : this.renderForm();

    return (
      <Fragment>
        {lessonPlanInfo}
        {contents}
      </Fragment>
    );
  }
}

EditUnitPlanComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const EditUnitPlan = withStyles(stylesEditUnitPlan)(EditUnitPlanComp);
