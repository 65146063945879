import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Typography
} from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import clsx from 'clsx';
import authService from '../../api-authorization/AuthorizeService';

class ChangeStudentTypePopup extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      studentType: '1',
      anchorEl: null,
      lessonCopyStatus: null
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  handleChange = name => event => {
    const attrName = name;
    const attrValue = event.target.value;
    this.setState({ [attrName]: attrValue, lessonCopyStatus: null });
  };

  popup = (studentInfo, el) => {
    this.setState({
      studentInfo,
      studentType: studentInfo.studentType ?? '1',
      anchorEl: el.currentTarget
    });
  };
  handleClose = () => {
    this.setState({ anchorEl: null, lessonCopyStatus: null });
  };

  doChangeStudentType = async () => {
    this.setState({ lessonCopyStatus: 'progress' });
    const token = authService.getAccessToken();
    const { studentInfo, studentType } = this.state;

    await fetch(
      `api/ClassStudents/UpdateStudentType/${studentInfo.id}/${studentType}`,
      {
        method: 'PUT',
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    )
      .then(response => {
        if (response.ok) {
          this.setState({ lessonCopyStatus: 'ok' }, () =>
            this.props.refreshList()
          );
        } else this.setState({ lessonCopyStatus: 'error' });
      })
      .catch(error => {
        this.setState({ lessonCopyStatus: 'error' });
        console.error('Error:', error);
      });
  };

  render() {
    const { classes } = this.props;
    const { studentType, lessonCopyStatus, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <Popover
        id="popoverChangeStudentType"
        anchorEl={anchorEl}
        open={open}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Change student type
            </Typography>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="student-type">Student Type</InputLabel>
              <Select
                value={studentType}
                onChange={this.handleChange('studentType')}
              >
                <MenuItem value="1">Regular</MenuItem>
                <MenuItem value="3">Demo</MenuItem>
                <MenuItem value="4">Suspended</MenuItem>
                <MenuItem value="5">Terminated</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              onClick={this.doChangeStudentType}
            >
              Accept
            </Button>
            {lessonCopyStatus === 'progress' ? (
              <CircularProgress size={24} />
            ) : lessonCopyStatus === 'ok' ? (
              <DoneIcon style={{ color: green[500] }} />
            ) : lessonCopyStatus === 'error' ? (
              <ErrorIcon style={{ color: red[500] }} />
            ) : null}
          </CardActions>
        </Card>
      </Popover>
    );
  }
}

ChangeStudentTypePopup.propTypes = {
  classes: PropTypes.object.isRequired
};

const stylesChangeStudentType = theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 160
  }
});

export default withStyles(stylesChangeStudentType)(ChangeStudentTypePopup);
