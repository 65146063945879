import { BrowserRouter, Switch } from 'react-router-dom';
import React, { Component } from 'react';

import { AcademicPaths } from './components/academic-manage/AcademicConstants';
import AcademicRoutes from './components/academic-manage/AcademicRoutes';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AppRoute from './components/common/route/AppRoute';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { AssessmentPaths } from './components/assessment/AssessmentConstants';
import AssessmentRoutes from './components/assessment/AssessmentRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { CampusPaths } from './components/admin/campus/CampusConstants';
import CampusRoutes from './components/admin/campus/CampusRoutes';
import { CatchUpSchedulePaths } from './components/catch-up/CatchUpScheduleContans';
import CatchUpScheduleRoutes from './components/catch-up/CatchUpScheduleRoutes';
import { ClassCoursePaths } from './components/class-course/ClassCourseConstants';
import ClassCourseRoutes from './components/class-course/ClassCourseRoutes';
import { ClassDataPaths } from './components/class-course/class-data/ClassDataConstants';
import ClassDataRoutes from './components/class-course/class-data/ClassDataRoutes';
import { ClassRoomPaths } from './components/admin/class-room/ClassRoomConstants';
import ClassRoomRoutes from './components/admin/class-room/ClassRoomRoutes';
import { Dashboard } from './components/dashboard/Dashboard';
import { EmptyLayout } from './components/common/layout/EmptyLayout';
import { EventPaths } from './components/my-class/event/EventConstants';
import EventRoutes from './components/my-class/event/EventRoutes';
import { LessonAndTeacherPaths } from './components/class-course/lessonAndTeacher/LessonAndTeacherConstants';
import LessonAndTeacherRoutes from './components/class-course/lessonAndTeacher/LessonAndTeacherRoutes';
import { LessonPlanPaths } from './components/lesson-plan/lessons/LessonPlanConstants';
import LessonPlanRoutes from './components/lesson-plan/lessons/LessonPlanRoutes';
import LetsLearnScreen from './components/my-class/lets-learn/LetsLearnScreen';
import { LiveClassPaths } from './components/live-class/LiveClassConstants';
import LiveClassRoutes from './components/live-class/LiveClassRoutes';
import { MainLayout } from './components/common/layout/MainLayout';
import { MaterialPaths } from './components/material/MaterialConstants';
import MaterialRoutes from './components/material/MaterialRoutes';
import { MyClassPaths } from './components/my-class/MyClassConstants';
import MyClassRoutes from './components/my-class/MyClassRoutes';
import { ParentPaths } from './components/student/parent/ParentConstans';
import ParentRoutes from './components/student/parent/ParentRoutes';
import { ProposalPaths } from './components/proposal/ProposalConstants';
import ProposalRoutes from './components/proposal/ProposalRoutes';
import { ReportPaths } from './components/report/ReportConstants';
import ReportRoutes from './components/report/ReportRoutes';
import { ReservePaths } from './components/academic-manage/reserve/ReserveConstants';
import ReserveRoutes from './components/academic-manage/reserve/ReserveRoutes';
import { RolePaths } from './components/admin/role/RoleConstants';
import RoleRoutes from './components/admin/role/RoleRoutes';
import { SchedulePaths } from './components/schedule/ScheduleConstans';
import ScheduleRoutes from './components/schedule/ScheduleRoutes';
import { SettingPaths } from './components/settings/SettingConstants';
import SettingRoutes from './components/settings/SettingRoutes';
import { SingleLessonPaths } from './components/single-lesson/SingleLessonConstans';
import SingleLessonRoutes from './components/single-lesson/SingleLessonRoutes';
import { StoryPaths } from './components/story/StoryConstants';
import StoryRoutes from './components/story/StoryRoutes';
import { StudentCoursePaths } from './components/student-course/StudentCourseConstants';
import StudentCourseRoutes from './components/student-course/StudentCourseRoutes';
import { StudentPaths } from './components/student/StudentConstans';
import StudentRoutes from './components/student/StudentRoutes';
import { StudyLevelPaths } from './components/lesson-plan/study-level/StudyLevelConstants';
import StudyLevelRoutes from './components/lesson-plan/study-level/StudyLevelRoutes';
import { TaskPaths } from './components/task/TaskConstans';
import TaskRoutes from './components/task/TaskRoutes';
import { TeacherLessonPaths } from './components/teacher-lesson/TeacherLessonConstants';
import TeacherLessonRoutes from './components/teacher-lesson/TeacherLessonRoutes';
import { TeacherPaths } from './components/teacher/TeacherConstans';
import TeacherRoutes from './components/teacher/TeacherRoutes';
import { UnitPlanPaths } from './components/lesson-plan/unit/UnitPlanConstants';
import UnitPlanRoutes from './components/lesson-plan/unit/UnitPlanRoutes';
import { UnitSearchPaths } from './components/unit-search/UnitSearchConstants';
import UnitSearchRoutes from './components/unit-search/UnitSearchRoutes';
import { UserPaths } from './components/admin/user/UserConstants';
import UserRoutes from './components/admin/user/UserRoutes';

//import { Router, Route, withRouter } from 'react-router';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <AppRoute
            path={ApplicationPaths.ApiAuthorizationPrefix}
            layout={MainLayout}
            component={ApiAuthorizationRoutes}
          />
          <AppRoute
            path={AssessmentPaths.AssessmentPrefix}
            layout={EmptyLayout}
            component={AssessmentRoutes}
          />
          <AuthorizeRoute
            exact
            path="/"
            layout={MainLayout}
            component={Dashboard}
          />
          <AuthorizeRoute
            path={AcademicPaths.AcademicPrefix}
            layout={MainLayout}
            component={AcademicRoutes}
          />
          <AuthorizeRoute
            path={ReservePaths.ReservePrefix}
            layout={MainLayout}
            component={ReserveRoutes}
          />
          <AuthorizeRoute
            path={StudyLevelPaths.StudyLevelPrefix}
            layout={MainLayout}
            component={StudyLevelRoutes}
          />
          <AuthorizeRoute
            path={LessonPlanPaths.LessonPrefix}
            layout={MainLayout}
            component={LessonPlanRoutes}
          />
          <AuthorizeRoute
            path={UnitPlanPaths.UnitPlanPrefix}
            layout={MainLayout}
            component={UnitPlanRoutes}
          />
          <AuthorizeRoute
            path={MaterialPaths.MaterialPrefix}
            layout={MainLayout}
            component={MaterialRoutes}
          />
          <AuthorizeRoute
            path={MyClassPaths.MyClassPrefix}
            layout={MainLayout}
            component={MyClassRoutes}
          />
          <AuthorizeRoute
            path={ClassCoursePaths.ClassCoursePrefix}
            layout={MainLayout}
            component={ClassCourseRoutes}
          />
          <AuthorizeRoute
            path={ClassDataPaths.ClassDataPrefix}
            layout={MainLayout}
            component={ClassDataRoutes}
          />
          <AuthorizeRoute
            path={TeacherLessonPaths.TeacherLessonPrefix}
            layout={MainLayout}
            component={TeacherLessonRoutes}
          />
          <AuthorizeRoute
            path={LessonAndTeacherPaths.LessonAndTeacherPathsPrefix}
            layout={MainLayout}
            component={LessonAndTeacherRoutes}
          />
          <AuthorizeRoute
            path={ReportPaths.ReportPrefix}
            layout={MainLayout}
            component={ReportRoutes}
          />
          <AuthorizeRoute
            path={ClassRoomPaths.ClassRoomPrefix}
            layout={MainLayout}
            component={ClassRoomRoutes}
          />
          <AuthorizeRoute
            path={TeacherPaths.TeacherPrefix}
            layout={MainLayout}
            component={TeacherRoutes}
          />
          <AuthorizeRoute
            path={ParentPaths.ParentPrefix}
            layout={MainLayout}
            component={ParentRoutes}
          />
          <AuthorizeRoute
            path={StudentPaths.StudentPrefix}
            layout={MainLayout}
            component={StudentRoutes}
          />
          <AuthorizeRoute
            path={TaskPaths.TaskPrefix}
            layout={MainLayout}
            component={TaskRoutes}
          />
          <AuthorizeRoute
            path={SchedulePaths.SchedulePrefix}
            layout={MainLayout}
            component={ScheduleRoutes}
          />
          <AuthorizeRoute
            path={CatchUpSchedulePaths.CatchUpSchedulePrefix}
            layout={MainLayout}
            component={CatchUpScheduleRoutes}
          />
          <AuthorizeRoute
            path={StudentCoursePaths.StudentCoursePrefix}
            layout={MainLayout}
            component={StudentCourseRoutes}
          />
          <AuthorizeRoute
            path={ProposalPaths.ProposalPrefix}
            layout={MainLayout}
            component={ProposalRoutes}
          />
          <AuthorizeRoute
            path={RolePaths.RolePrefix}
            layout={MainLayout}
            component={RoleRoutes}
          />
          <AuthorizeRoute
            path={UserPaths.UserPrefix}
            layout={MainLayout}
            component={UserRoutes}
          />
          <AuthorizeRoute
            path={SettingPaths.SettingPrefix}
            layout={MainLayout}
            component={SettingRoutes}
          />
          <AuthorizeRoute
            path={MyClassPaths.LetsLearnRoute}
            layout={EmptyLayout}
            component={LetsLearnScreen}
          />
          <AuthorizeRoute
            path={UnitSearchPaths.UnitSearchPrefix}
            layout={MainLayout}
            component={UnitSearchRoutes}
          />
          <AuthorizeRoute
            path={EventPaths.EventPrefix}
            layout={MainLayout}
            component={EventRoutes}
          />
          <AuthorizeRoute
            path={StoryPaths.StoryPrefix}
            layout={MainLayout}
            component={StoryRoutes}
          />
          <AuthorizeRoute
            path={CampusPaths.CampusPrefix}
            layout={MainLayout}
            component={CampusRoutes}
          />
          <AppRoute
            path={LiveClassPaths.LiveClassPrefix}
            layout={EmptyLayout}
            component={LiveClassRoutes}
          />
          <AuthorizeRoute
            path={SingleLessonPaths.SingleLessonPrefix}
            layout={MainLayout}
            component={SingleLessonRoutes}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

//export default withRouter(props => <App {...props} />);
