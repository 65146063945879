import React, { Component, Fragment } from 'react';
import { PropsRoute } from '../../common/route/PropsRoute';
import { Parents } from './Parent';
import { ParentPaths, ParentActions } from './ParentConstans';

export default class ParentRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={ParentPaths.List}
          component={Parents}
          action={ParentActions.List}
        />
        <PropsRoute
          path={ParentPaths.EditRoute}
          component={Parents}
          action={ParentActions.Edit}
        />
        <PropsRoute
          path={ParentPaths.Create}
          component={Parents}
          action={ParentActions.Create}
        />
      </Fragment>
    );
  }
}
