import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ListUnitSearch } from './ListUnitSearch';
import { UnitSearchAction } from './UnitSearchConstants';
import { AdminPage } from '../ui/page/AdminPage';

const stylesUnitSearch = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class UnitSearchComp extends Component {
  static displayName = UnitSearchComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      content: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case UnitSearchAction.List:
        this.state.pageTitle = 'Lesson Search';
        this.state.content = (
          <ListUnitSearch
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  render() {
    const actions = [];

    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

UnitSearchComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const UnitSearch = withStyles(stylesUnitSearch)(UnitSearchComp);
