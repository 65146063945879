import 'date-fns';
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import { Loading } from '../../ui/Loading';
import { ClassDataPaths, ClassDataActions } from './ClassDataConstants';
import ClassCourseInfo from '../ClassCourseInfo';

const stylesEditClassData = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'end'
  },
  margin: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1)
  },
  legend: {
    marginBottom: theme.spacing(2)
  },
  textField: {
    width: 300
  }
});

class EditClassDataComp extends Component {
  static displayName = EditClassDataComp.name;
  constructor(...args) {
    super(...args);
    this.levelForm = React.createRef();
    this.state = {
      action: this.props.action,
      classId: this.props.classId,
      studentInfoId: this.props.studentInfoId,
      method: 'PUT',
      formAction: null,
      classData: {},
      redirect: null,
      loading: true
    };
    switch (this.props.action) {
      case ClassDataActions.Create:
        this.state.classData = {
          classId: this.props.classId,
          studentName: '',
          englishName: '',
          yob: 2019,
          fatherName: '',
          fatherPhone: '',
          fatherEmail: '',
          motherName: '',
          motherPhone: '',
          motherEmail: '',
          address: '',
          beginDate: new Date(),
          firstCourse: '',
          renewCourse: '',
          note: ''
        };
        this.state.method = 'POST';
        this.state.formAction = `api/ClassData`;
        this.populateClassCourse();
        break;
      case ClassDataActions.Edit:
        this.state.formAction = `api/ClassData/${this.props.studentInfoId}`;
        this.populateClassData();
        break;
      case undefined:
      default:
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  populateClassCourse = async () => {
    const token = await authService.getAccessToken();
    const { classId } = this.state;
    const response = await fetch(`api/ClassCourses/${classId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const dataClassCourse = await response.json();
    this.setState({ classCourse: dataClassCourse, loading: false });
  };

  async populateClassData() {
    const token = await authService.getAccessToken();
    const { studentInfoId } = this.state;
    const response = await fetch(`api/ClassData/${studentInfoId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ classData: data, loading: false });
  }

  handleChange = name => event => {
    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;
    this.setState(prevState => ({
      classData: {
        ...prevState.classData,
        [attrName]: attrValue
      }
    }));
  };

  handleDateChange = name => date => {
    const attrName = name;
    const attrValue = date;
    this.setState(prevState => ({
      classData: {
        ...prevState.classData,
        [attrName]: attrValue
      }
    }));
  };

  backToList() {
    const { classData } = this.state;
    this.redirectTo(`${ClassDataPaths.List}/${classData.classId}`);
  }

  async submitData() {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    ReactDOM.findDOMNode(this.levelForm.current).dispatchEvent(
      new Event('submit')
    );
  }

  handleSubmit = e => {
    e.preventDefault();

    const { classData, method, formAction, token } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    classData.beginDate = new Date(classData.beginDate);
    const formBody = JSON.stringify(classData);

    fetch(formAction, {
      method: method,
      body: formBody,
      headers: headers
    })
      .then(response => {
        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  renderForm() {
    const { classes } = this.props;
    const { classData } = this.state;
    const cols = [
      {
        name: 'studentName',
        header: 'Name',
        placeholder: 'Name',
        adornment: ''
      },
      {
        name: 'englishName',
        header: 'English name',
        placeholder: 'English name',
        adornment: ''
      },
      {
        name: 'yob',
        header: 'Birth year',
        placeholder: 'Birth year',
        adornment: ''
      },
      {
        name: 'address',
        header: 'Address',
        placeholder: 'Address',
        adornment: ''
      },
      {
        name: 'note',
        header: 'Note',
        placeholder: '',
        adornment: ''
      },
      {
        name: 'fatherName',
        header: 'Father name',
        placeholder: 'Father name',
        adornment: ''
      },
      {
        name: 'fatherPhone',
        header: 'Father phone',
        placeholder: 'Father phone',
        adornment: ''
      },
      {
        name: 'fatherEmail',
        header: 'Father email',
        placeholder: 'Father email',
        adornment: ''
      },
      {
        name: 'motherName',
        header: 'Mother name',
        placeholder: 'Mother name',
        adornment: ''
      },
      {
        name: 'motherPhone',
        header: 'Mother phone',
        placeholder: 'Mother phone',
        adornment: ''
      },
      {
        name: 'motherEmail',
        header: 'Mother email',
        placeholder: 'Mother email',
        adornment: ''
      },
      {
        name: 'beginDate',
        header: 'Date',
        placeholder: 'Date',
        adornment: ''
      },
      {
        name: 'firstCourse',
        header: 'Course',
        placeholder: 'Course',
        adornment: ''
      },
      {
        name: 'renewCourse',
        header: 'Course',
        placeholder: 'Course',
        adornment: ''
      }
    ];

    return (
      <Paper className={classes.root}>
        <form
          ref={this.levelForm}
          className={classes.container}
          onSubmit={this.handleSubmit}
        >
          <Grid container justify="space-around">
            <Grid item xs={6}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="student-name"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[0].header}
                    placeholder={cols[0].placeholder}
                    name={cols[0].name}
                    value={classData[cols[0].name]}
                    onChange={this.handleChange(cols[0].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="english-name"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[1].header}
                    placeholder={cols[1].placeholder}
                    name={cols[1].name}
                    value={classData[cols[1].name]}
                    onChange={this.handleChange(cols[1].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControl
                    className={classNames(classes.margin, classes.textField)}
                  >
                    <InputLabel htmlFor="yob">{cols[2].header}</InputLabel>
                    <Input
                      id="yob"
                      name={cols[2].name}
                      value={classData[cols[2].name]}
                      onChange={this.handleChange(cols[2].name)}
                      endAdornment={
                        <InputAdornment position="end">
                          {cols[2].adornment}
                        </InputAdornment>
                      }
                      inputProps={{
                        'aria-label': cols[2].header
                      }}
                    />
                  </FormControl>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="address"
                    fullWidth
                    multiline
                    rows="3"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[3].header}
                    placeholder={cols[3].placeholder}
                    name={cols[3].name}
                    value={classData[cols[3].name]}
                    onChange={this.handleChange(cols[3].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.formControl}
                      id="mui-pickers-begindate"
                      format="dd/MM/yyyy"
                      label={cols[11].header}
                      value={classData[cols[11].name]}
                      onChange={this.handleDateChange(cols[11].name)}
                      KeyboardButtonProps={{
                        'aria-label': 'change begin date'
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="first-course"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[12].header}
                    placeholder={cols[12].placeholder}
                    name={cols[12].name}
                    value={classData[cols[12].name]}
                    onChange={this.handleChange(cols[12].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="renew-course"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[14].header}
                    placeholder={cols[14].placeholder}
                    name={cols[14].name}
                    value={classData[cols[14].name]}
                    onChange={this.handleChange(cols[14].name)}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="father-name"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[5].header}
                    placeholder={cols[5].placeholder}
                    name={cols[5].name}
                    value={classData[cols[5].name]}
                    onChange={this.handleChange(cols[5].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="father-phone"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[6].header}
                    placeholder={cols[6].placeholder}
                    name={cols[6].name}
                    value={classData[cols[6].name]}
                    onChange={this.handleChange(cols[6].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="father-email"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[7].header}
                    placeholder={cols[7].placeholder}
                    name={cols[7].name}
                    value={classData[cols[7].name]}
                    onChange={this.handleChange(cols[7].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="mother-name"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[8].header}
                    placeholder={cols[8].placeholder}
                    name={cols[8].name}
                    value={classData[cols[8].name]}
                    onChange={this.handleChange(cols[8].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="mother-phone"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[9].header}
                    placeholder={cols[9].placeholder}
                    name={cols[9].name}
                    value={classData[cols[9].name]}
                    onChange={this.handleChange(cols[9].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="mother-email"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[10].header}
                    placeholder={cols[10].placeholder}
                    name={cols[10].name}
                    value={classData[cols[10].name]}
                    onChange={this.handleChange(cols[10].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="note"
                    fullWidth
                    multiline
                    rows="6"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.margin}
                    label={cols[4].header}
                    placeholder={cols[4].placeholder}
                    name={cols[4].name}
                    value={classData[cols[4].name]}
                    onChange={this.handleChange(cols[4].name)}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </form>
        {this.state.redirect}
      </Paper>
    );
  }

  render() {
    let classCourseInfo = this.state.loading ? (
      <Loading />
    ) : (
      <ClassCourseInfo
        classCourse={
          this.state.action === ClassDataActions.Create
            ? this.state.classCourse
            : this.state.classData.classCourse
        }
      />
    );
    let contents = this.state.loading ? <Loading /> : this.renderForm();
    return (
      <Fragment>
        {classCourseInfo}
        {contents}
      </Fragment>
    );
  }
}

EditClassDataComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const EditClassData = withStyles(stylesEditClassData)(EditClassDataComp);
