import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import { red, green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { Grid } from '@material-ui/core';

const stylesClassLessonSettings = () => ({});

class ClassLessonSettings extends Component {
  constructor(...args) {
    super(...args);
    this.state = { actionStatus: null };
  }

  doResetClassLessons = async () => {
    this.setState({ actionStatus: 'progress' });
    const token = await authService.getAccessToken();
    const response = await fetch('api/ClassLesson/ResetClassLessons', {
      method: 'POST',
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    if (response.ok) {
      this.setState({ actionStatus: 'ok' });
    } else this.setState({ actionStatus: 'error' });
  };

  render() {
    const { actionStatus } = this.state;

    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.doResetClassLessons}
          >
            Reset Class Lessons...
          </Button>
        </Grid>
        <Grid item>
          {actionStatus === 'progress' ? (
            <CircularProgress size={24} />
          ) : actionStatus === 'ok' ? (
            <DoneIcon style={{ color: green[500] }} />
          ) : actionStatus === 'error' ? (
            <ErrorIcon style={{ color: red[500] }} />
          ) : null}
        </Grid>
      </Grid>
    );
  }
}

ClassLessonSettings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesClassLessonSettings)(ClassLessonSettings);
