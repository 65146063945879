import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'date-fns';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { Button, CardActions, Fab } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import StudentPayment from '../student/payment/StudentPayment';
import { stylesAcademicInfo } from '../academic-manage/stylesAcademicInfo';
import { Dialogs, DialogType } from '../ui/popup/Dialogs';
import StudentInfo from '../student/info/StudentInfo';
import { AcademicPaths } from '../academic-manage/AcademicConstants';
import { StudentCourseActions } from './StudentCourseConstants';

class EditStudentCourse extends Component {
  static displayName = EditStudentCourse.name;
  constructor(...args) {
    super(...args);
    this.studentPayment = React.createRef();
    this.confirmDialog = React.createRef();
    this.state = {
      studentId: this.props.studentId,
      classId: this.props.classId,
      studentCourseId: this.props.studentCourseId,
      redirect: null,
      loading: true
    };
    this.savePaymentCommands = (
      <Fragment>
        <Button onClick={this.actionPayment} color="secondary">
          Accept
        </Button>
        <Button onClick={this.closeConfirmDialog} color="primary">
          Cancel
        </Button>
      </Fragment>
    );
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateDetailData();
  }

  populateDetailData = async () => {
    const token = await authService.getAccessToken();
    const { studentId } = this.state;
    const [respStudent, respClass] = await Promise.all([
      fetch(`api/Student/${studentId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/Student/GetClass/${studentId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [student, stdClass] = await Promise.all([
      respStudent.json(),
      respClass.json()
    ]);

    this.setState({ student, stdClass, loading: false });
  };

  showConfirmDialog = confirmType => {
    this.setState({ confirmType }, () =>
      this.confirmDialog.showDialogWithCmds(
        confirmType === 'delete'
          ? DialogType.DeletePayment
          : DialogType.SavePayment,
        this.savePaymentCommands
      )
    );
  };
  closeConfirmDialog = () => {
    this.confirmDialog.closeDialog();
  };

  actionPayment = () => {
    this.state.confirmType === 'delete'
      ? this.deletePayment()
      : this.studentPayment.saveData(this.state.student);
    this.backToList();
  };

  deletePayment = async () => {
    const token = await authService.getAccessToken();
    const { studentCourseId } = this.state;
    await fetch(`api/StudentCourses/${studentCourseId}`, {
      method: 'DELETE',
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
  };

  backToList = () => {
    this.redirectTo(`${AcademicPaths.Info}/${this.state.studentId}`);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  render() {
    const { classes, action } = this.props;
    const { student, stdClass, classId, studentCourseId } = this.state;
    const stdClassList = [stdClass];

    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <StudentInfo student={student} stdClassList={stdClassList} />
            <StudentPayment
              onRef={actualChild => (this.studentPayment = actualChild)}
              student={student}
              stdClass={stdClass}
              classId={classId}
              studentCourseId={studentCourseId}
              renew={true}
              action={action}
            />
          </Fragment>
        )}
        <CardActions className={classes.cardActions}>
          <Fragment>
            <Fab
              aria-label="Save"
              color="primary"
              className={classes.fab}
              onClick={() => this.showConfirmDialog('save')}
            >
              <SaveIcon />
            </Fab>
            {action === StudentCourseActions.Edit && (
              <Fab
                aria-label="Delete"
                className={classes.fab}
                onClick={() => this.showConfirmDialog('delete')}
              >
                <DeleteIcon color="action" />
              </Fab>
            )}
            <Fab
              aria-label="Cancel"
              className={classes.fab}
              onClick={this.backToList}
            >
              <CloseOutlinedIcon color="action" />
            </Fab>
          </Fragment>
        </CardActions>
        <Dialogs onRef={actualChild => (this.confirmDialog = actualChild)} />
        {this.state.redirect}
      </Fragment>
    );
  }
}

EditStudentCourse.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesAcademicInfo)(EditStudentCourse);
