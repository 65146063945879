import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import { Typography, Grid } from '@material-ui/core';

import Capa1 from '../../images/assessment/capa_1.svg';
import Capa2 from '../../images/assessment/capa_2.svg';
import Capa3 from '../../images/assessment/capa_3.svg';

const stylesStudentAssessment = theme => ({
  root: {
    flexGrow: 1,
    alignItems: 'stretch'
  },
  contentCol: {
    flexGrow: 1
  },
  navCol: {},
  footer: {},
  studentName: {
    lineHeight: '34px'
  },
  label: {
    fontSize: 14,
    color: '#707070',
    padding: theme.spacing(1)
  },
  labelBold: {
    fontWeight: 'bold'
  },
  box: {
    margin: theme.spacing(4, 0),
    textAlign: 'right'
  },
  button: {
    margin: theme.spacing(0, 1.5),
    borderRadius: theme.spacing(2.25),
    minWidth: 100
  }
});

class StudentAssessmentComp extends Component {
  static displayName = StudentAssessmentComp.name;

  constructor(...args) {
    super(...args);
    this.state = {
      classRooms: [],
      ratingMap: [],
      loading: true
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateStudentRating();
  }

  populateClassRoomData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/ClassRooms/GetClassRoom/0', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ classRooms: data, loading: false });
  };

  populateStudentRating = () => {
    const {
      currentClassStudents,
      currentStudentAssessment,
      studentAssessments
    } = this.props;
    const ratingMap = currentClassStudents.map(student => {
      let studentRating = { studentId: student.id };
      studentAssessments.forEach(assess => {
        studentRating[assess.criteriaNumber] = 0;
      });
      return studentRating;
    });
    if (currentStudentAssessment && currentStudentAssessment.length > 0) {
      currentStudentAssessment.forEach(std => {
        const studentRating = ratingMap.find(
          item => item.studentId === std.studentId
        );
        if (studentRating) {
          studentRating[std.criteriaNumber] = std.score;
        }
        // else {
        //   let newStudentRating = { studentId: std.studentId, [std.criteriaNumber]: std.score };
        //   ratingMap.push(newStudentRating);
        // }
      });
    }
    this.setState({ ratingMap, loading: false });
  };

  udpateStudentRating = currentClassStudents => {
    const { studentAssessments } = this.state;
    const ratingMap = [];
    currentClassStudents.forEach(student => {
      let studentRating = { studentId: student.id };
      studentAssessments.forEach(assess => {
        studentRating[assess.criteriaNumber] = 0;
      });
      ratingMap.push(studentRating);
    });
    this.setState({ currentClassStudents, ratingMap });
  };
  // udpateStudentRating = currentClassStudents => {
  //   const { ratingMap, studentAssessments } = this.state;
  //   currentClassStudents.forEach(student => {
  //     const studentId = student.id;
  //     ratingMap[studentId] = ratingMap[studentId] ?? [];
  //     studentAssessments.forEach(assess => {
  //       ratingMap[studentId][assess.criteriaNumber] =
  //         ratingMap[studentId][assess.criteriaNumber] ?? 0;
  //     });
  //   });
  //   this.setState({ currentClassStudents, ratingMap });
  // };

  changeRatingPerm = (studentId, ratingId, point) => {
    this.setState(prevState => ({
      ratingMap: prevState.ratingMap.map(std =>
        std.studentId === studentId ? { ...std, [ratingId]: point } : std
      )
    }));
    // this.setState({ ratingMap }, this.props.saveStudentAssessment);
  };

  getRatingMap = () => {
    const { ratingMap } = this.state;
    return ratingMap;
  };

  render() {
    const { currentClassStudents, classes } = this.props;
    const { ratingMap } = this.state;

    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Grid container className={classes.root}>
            <Grid item className={classes.contentCol}>
              {currentClassStudents.map((student, idx) => (
                <div className="row-student" key={idx}>
                  <div className="left-side id-tab">
                    <div className="name-id">
                      <Typography variant="h1" className={classes.studentName}>
                        {`${student.englishName} - ${student.studentName}`}
                      </Typography>
                    </div>
                  </div>

                  <div className="sider2 right-side">
                    <div className="perform">
                      <form className="per-form" action="#">
                        <fieldset className="form-group">
                          <div className="form-item">
                            <input
                              id={'perrate1-3-' + student.id}
                              type="radio"
                              value="3"
                              checked={
                                ratingMap.find(
                                  i => i.studentId === student.id
                                )[1] === 3
                              }
                              onChange={() =>
                                this.changeRatingPerm(student.id, 1, 3)
                              }
                            />
                            <label htmlFor={'perrate1-3-' + student.id}>
                              <span className="rating-star">
                                <i className="fa fa-check-circle-o"></i>
                                <i className="fa fa-check-circle"></i>
                              </span>
                            </label>

                            <input
                              id={'perrate1-2-' + student.id}
                              type="radio"
                              value="2"
                              checked={
                                ratingMap.find(
                                  i => i.studentId === student.id
                                )[1] === 2
                              }
                              onChange={() =>
                                this.changeRatingPerm(student.id, 1, 2)
                              }
                            />
                            <label htmlFor={'perrate1-2-' + student.id}>
                              <span className="rating-star">
                                <i className="fa fa-check-circle-o"></i>
                                <i className="fa fa-check-circle"></i>
                              </span>
                            </label>

                            <input
                              id={'perrate1-1-' + student.id}
                              type="radio"
                              value="1"
                              checked={
                                ratingMap.find(
                                  i => i.studentId === student.id
                                )[1] === 1
                              }
                              onChange={() =>
                                this.changeRatingPerm(student.id, 1, 1)
                              }
                            />
                            <label htmlFor={'perrate1-1-' + student.id}>
                              <span className="rating-star">
                                <i className="fa fa-check-circle-o"></i>
                                <i className="fa fa-check-circle"></i>
                              </span>
                            </label>
                            <div className="icon-perform">
                              <div className="icon">
                                <img src={Capa1} alt="capa1" />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </form>
                    </div>

                    <div className="perform">
                      <form className="per-form" action="#">
                        <fieldset className="form-group">
                          <div className="form-item">
                            <input
                              id={'perrate2-3-' + student.id}
                              type="radio"
                              value="3"
                              checked={
                                ratingMap.find(
                                  i => i.studentId === student.id
                                )[2] === 3
                              }
                              onChange={() =>
                                this.changeRatingPerm(student.id, 2, 3)
                              }
                            />
                            <label htmlFor={'perrate2-3-' + student.id}>
                              <span className="rating-star">
                                <i className="fa fa-check-circle-o"></i>
                                <i className="fa fa-check-circle"></i>
                              </span>
                            </label>

                            <input
                              id={'perrate2-2-' + student.id}
                              type="radio"
                              value="2"
                              checked={
                                ratingMap.find(
                                  i => i.studentId === student.id
                                )[2] === 2
                              }
                              onChange={() =>
                                this.changeRatingPerm(student.id, 2, 2)
                              }
                            />
                            <label htmlFor={'perrate2-2-' + student.id}>
                              <span className="rating-star">
                                <i className="fa fa-check-circle-o"></i>
                                <i className="fa fa-check-circle"></i>
                              </span>
                            </label>

                            <input
                              id={'perrate2-1-' + student.id}
                              type="radio"
                              value="1"
                              checked={
                                ratingMap.find(
                                  i => i.studentId === student.id
                                )[2] === 1
                              }
                              onChange={() =>
                                this.changeRatingPerm(student.id, 2, 1)
                              }
                            />
                            <label htmlFor={'perrate2-1-' + student.id}>
                              <span className="rating-star">
                                <i className="fa fa-check-circle-o"></i>
                                <i className="fa fa-check-circle"></i>
                              </span>
                            </label>
                            <div className="icon-perform">
                              <div className="icon">
                                <img src={Capa2} alt="capa2" />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </form>
                    </div>

                    <div className="perform">
                      <form className="per-form" action="#">
                        <fieldset className="form-group">
                          <div className="form-item">
                            <input
                              id={'perrate3-3-' + student.id}
                              type="radio"
                              value="3"
                              checked={
                                ratingMap.find(
                                  i => i.studentId === student.id
                                )[3] === 3
                              }
                              onChange={() =>
                                this.changeRatingPerm(student.id, 3, 3)
                              }
                            />
                            <label htmlFor={'perrate3-3-' + student.id}>
                              <span className="rating-star">
                                <i className="fa fa-check-circle-o"></i>
                                <i className="fa fa-check-circle"></i>
                              </span>
                            </label>

                            <input
                              id={'perrate3-2-' + student.id}
                              type="radio"
                              value="2"
                              checked={
                                ratingMap.find(
                                  i => i.studentId === student.id
                                )[3] === 2
                              }
                              onChange={() =>
                                this.changeRatingPerm(student.id, 3, 2)
                              }
                            />
                            <label htmlFor={'perrate3-2-' + student.id}>
                              <span className="rating-star">
                                <i className="fa fa-check-circle-o"></i>
                                <i className="fa fa-check-circle"></i>
                              </span>
                            </label>

                            <input
                              id={'perrate3-1-' + student.id}
                              type="radio"
                              value="1"
                              checked={
                                ratingMap.find(
                                  i => i.studentId === student.id
                                )[3] === 1
                              }
                              onChange={() =>
                                this.changeRatingPerm(student.id, 3, 1)
                              }
                            />
                            <label htmlFor={'perrate3-1-' + student.id}>
                              <span className="rating-star">
                                <i className="fa fa-check-circle-o"></i>
                                <i className="fa fa-check-circle"></i>
                              </span>
                            </label>
                            <div className="icon-perform">
                              <div className="icon">
                                <img src={Capa3} alt="capa3" />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              ))}
            </Grid>
            <Grid item className={classes.navCol}>
              <div className="swap">
                <i
                  className="fa fa-angle-double-left"
                  onClick={() => this.props.toRatingStarStudent()}
                />
              </div>
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

StudentAssessmentComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const StudentAssessment = withStyles(stylesStudentAssessment)(
  StudentAssessmentComp
);
