import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import React, { Component, Fragment } from 'react';

import { BarChartView } from '../../class-course/reports/student/BarChartView';
import { CLASS_TYPE } from '../../class-course/ClassCourseConstants';
import { Chart } from 'react-charts';
import { Loading } from '../../ui/Loading';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';
import { fetchHelper } from '../../../helpers/fetch-helper';

export default class AcadReports extends Component {
  constructor(...args) {
    super(...args);
    const { stdClassList } = this.props;
    this.state = {
      studentReport: [],
      currentClassId:
        (stdClassList &&
          stdClassList.find(item => item?.studentType === CLASS_TYPE.Regular)
            ?.classId) ||
        (stdClassList && stdClassList[0].classId),
      studentsAtendanceReport: [],
      loading: true
    };
  }

  componentDidMount() {
    this.populateStudentData();
  }

  populateStudentData = async () => {
    const { student } = this.props;
    const { currentClassId } = this.state;
    const requestParam = {
      param: `${student.id}/${currentClassId}`,
      baseEndPoint: 'api/AcademicManage'
    };
    const studentReportData = await fetchHelper.get(
      `${requestParam.baseEndPoint}/GetStudentReport/${requestParam.param}`
    );
    const studentAssessments = await fetchHelper.get(
      `${requestParam.baseEndPoint}/GetStudentAssessment/${requestParam.param}`
    );

    let studentAssessmentData = [];
    studentAssessments.map(item => {
      if (!studentAssessmentData[item.logId]) {
        studentAssessmentData[item.logId] = [];
      }
      studentAssessmentData[item.logId][item.criteriaName] = item.score;
      return studentAssessmentData;
    });
    let checkDate = [];
    let studentReport = studentReportData.filter(item => {
      if (checkDate[item.lessonDate]) {
        return false;
      }
      checkDate[item.lessonDate] = true;
      return true;
    });
    const chartAttending = studentReport
      .map(item => ({
        key: item.lessonDate,
        data: item.present
      }))
      .reduce((result, item) => {
        if (!result.find(i => i.key === item.key)) result.push(item);
        return result;
      }, []);
    const starScoreChartData = studentReport.map(item => ({
      x: item.lessonDate,
      y: item.starScore
    }));
    const interactionScoreChartData = studentReport.map(item => ({
      x: item.lessonDate,
      y:
        item.logId && studentAssessmentData[item.logId]
          ? studentAssessmentData[item.logId]['Interaction']
            ? studentAssessmentData[item.logId]['Interaction']
            : 0
          : 0
    }));
    const attentionScoreChartData = studentReport.map(item => ({
      x: item.lessonDate,
      y:
        item.logId && studentAssessmentData[item.logId]
          ? studentAssessmentData[item.logId]['Attention']
            ? studentAssessmentData[item.logId]['Attention']
            : 0
          : 0
    }));
    const attitudeScoreChartData = studentReport.map(item => ({
      x: item.lessonDate,
      y:
        item.logId && studentAssessmentData[item.logId]
          ? studentAssessmentData[item.logId]['Attitude']
            ? studentAssessmentData[item.logId]['Attitude']
            : 0
          : 0
    }));
    const studentsAtendanceReport = studentReport.filter(x => x.logId);

    this.setState({
      studentReport,
      studentAssessmentData,
      chartAttending,
      starScoreChartData,
      interactionScoreChartData,
      attentionScoreChartData,
      attitudeScoreChartData,
      loading: false,
      studentsAtendanceReport,
      lessonStarScoreId:
        studentsAtendanceReport &&
        studentsAtendanceReport[studentsAtendanceReport.length - 1]
          ?.classLessonId
    });
  };

  handleChange = event => {
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;

    if (target.name === 'classes') {
      this.setState(
        { currentClassId: attrValue, loading: true },
        this.populateStudentData
      );
      this.props.callback(
        this.props.stdClassList.find(x => x.classId === attrValue)
      );
    }

    if (target.name === 'lessonStarScoreId') {
      this.setState({ lessonStarScoreId: attrValue });
    }
  };

  render() {
    const { classes, stdClassList } = this.props;
    const {
      currentClassId,
      studentReport,
      studentAssessmentData,
      chartAttending,
      starScoreChartData,
      interactionScoreChartData,
      attentionScoreChartData,
      attitudeScoreChartData,
      loading,
      lessonStarScoreId,
      studentsAtendanceReport
    } = this.state;

    const _studentAttendanceReport = studentReport.find(
      x => x.classLessonId === lessonStarScoreId
    );
    const maxSteps = studentReport.length;
    const stdPresenceNumber = studentReport.filter(x => x.present === 1).length;
    const stdAbsenceNumber = studentReport.filter(x => x.present === 0).length;
    const stdAttendanceNumber = stdPresenceNumber + stdAbsenceNumber;
    const stdAttendanceRatio =
      stdAttendanceNumber > 0
        ? Math.round((stdPresenceNumber * 100) / stdAttendanceNumber)
        : 0;

    const dataScores = [
      {
        label: 'Star Score',
        data: starScoreChartData
      },
      {
        label: 'Interaction',
        data: interactionScoreChartData
      },
      {
        label: 'Attention',
        data: attentionScoreChartData
      },
      {
        label: 'Attitude',
        data: attitudeScoreChartData
      }
    ];
    const axesScores = [
      { primary: true, type: 'ordinal', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ];

    return (
      <Fragment>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          className={clsx(classes.stdHeadline, classes.sectionMarginTop)}
        >
          <Grid
            item
            xs
            className={clsx(classes.stdSubtitle, classes.reportClassPadding)}
          >
            <Grid container>
              <Grid item xs={5}>
                <Typography
                  variant="h3"
                  className={clsx(classes.stdCell, classes.sectionTitle)}
                >
                  Reports
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <FormControl className={classes.reportClassSelect}>
                  <Select
                    name="classes"
                    value={currentClassId}
                    onChange={this.handleChange}
                  >
                    {stdClassList?.map(row => {
                      return (
                        <MenuItem
                          value={row.classId}
                          key={row.classId}
                          defaultVa
                        >
                          {row.className}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container justify="flex-end" alignItems="center">
              <Grid item xs={12}>
                <span
                  className={clsx(classes.stdLineCell, classes.stdMiddleLine)}
                ></span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <Loading />
        ) : (
          <Grid
            container
            alignItems="flex-start"
            spacing={3}
            className={classes.stdBox}
          >
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.stdBoxLabel}>
                Bảng báo cáo thời gian đi học của học sinh
              </Typography>
              <Typography
                variant="h4"
                className={classes.stdBoxLabel}
                style={{ fontSize: 16, marginLeft: 30 }}
              >
                {`Tỷ lệ học sinh đi học: ${stdAttendanceRatio}%`}
              </Typography>
              <Typography
                variant="h4"
                className={classes.stdBoxLabel}
                style={{ fontSize: 16, marginLeft: 30 }}
              >
                {`Số buổi đã học: ${stdAttendanceNumber}`}
              </Typography>
              <Typography
                variant="h4"
                className={classes.stdBoxLabel}
                style={{ fontSize: 16, marginLeft: 30 }}
              >
                {`Số buổi vắng mặt: ${stdAbsenceNumber}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div
                className={clsx(
                  classes.stdReportBox,
                  classes.stdAttentionReportBox
                )}
              >
                <BarChartView
                  title=""
                  subtitle=""
                  chartData={chartAttending}
                  classes={classes}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.stdBoxLabel}>
                Báo cáo số điểm sao và điểm perform hàng ngày của học sinh
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div
                className={clsx(
                  classes.stdReportBox,
                  classes.stdAttentionReportBox
                )}
              >
                <div
                  className={classes.chartView}
                  style={
                    starScoreChartData.length > 36
                      ? { width: 40 * starScoreChartData.length }
                      : { width: '100%' }
                  }
                >
                  <Chart
                    data={dataScores}
                    axes={axesScores}
                    series={{ type: 'line' }}
                    tooltip
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Box
                border={1}
                borderRadius={5}
                padding={2}
                borderColor="#ddd"
                className={classes.stdReportBox}
              >
                <Grid item container spacing={2}>
                  <Grid item xs={9} className={classes.stdCellFlex}>
                    <Typography
                      variant="h3"
                      className={clsx(
                        classes.stdBoxLabelMargin,
                        classes.stdBoxLabel
                      )}
                      style={{ marginTop: 2 }}
                    >
                      Star score:
                    </Typography>
                    <span>
                      {maxSteps > 0 &&
                        _studentAttendanceReport?.starScore !== 0 &&
                        _studentAttendanceReport?.starScore}
                    </span>
                  </Grid>
                  <Grid item xs={3} className={classes.stdCellFlex}>
                    <div
                      className={clsx(
                        classes.stdCellFlex,
                        classes.stdFloatRight
                      )}
                    >
                      <Select
                        id="select-lesson-date"
                        name="lessonStarScoreId"
                        value={lessonStarScoreId}
                        onChange={this.handleChange}
                      >
                        {studentsAtendanceReport.map(row => {
                          return (
                            <MenuItem
                              value={row.classLessonId}
                              key={row.classLessonId}
                            >
                              {row.lessonDate}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="h3"
                      className={clsx(
                        classes.stdBoxLabelMargin,
                        classes.stdBoxLabel
                      )}
                    >
                      PERFORMANCE
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.stdCellFlex}>
                    <Typography
                      variant="h3"
                      className={clsx(
                        classes.stdBoxLabelMargin,
                        classes.stdBoxLabel
                      )}
                      style={{ marginTop: 2 }}
                    >
                      Interaction
                    </Typography>
                    <span>
                      {maxSteps > 0 &&
                        _studentAttendanceReport?.logId &&
                        studentAssessmentData[
                          _studentAttendanceReport?.logId
                        ] &&
                        studentAssessmentData[_studentAttendanceReport?.logId][
                          'Interaction'
                        ]}
                    </span>
                  </Grid>
                  <Grid item xs={2} className={classes.stdCellFlex}>
                    <Typography
                      variant="h3"
                      className={clsx(
                        classes.stdBoxLabelMargin,
                        classes.stdBoxLabel
                      )}
                      style={{ marginTop: 2 }}
                    >
                      Attention
                    </Typography>
                    <span>
                      {maxSteps > 0 &&
                        _studentAttendanceReport?.logId &&
                        studentAssessmentData[
                          _studentAttendanceReport?.logId
                        ] &&
                        studentAssessmentData[_studentAttendanceReport?.logId][
                          'Attention'
                        ]}
                    </span>
                  </Grid>
                  <Grid item xs={2} className={classes.stdCellFlex}>
                    <Typography
                      variant="h3"
                      className={clsx(
                        classes.stdBoxLabelMargin,
                        classes.stdBoxLabel
                      )}
                      style={{ marginTop: 2 }}
                    >
                      Attitude
                    </Typography>
                    <span>
                      {maxSteps > 0 &&
                        _studentAttendanceReport?.logId &&
                        studentAssessmentData[
                          _studentAttendanceReport?.logId
                        ] &&
                        studentAssessmentData[_studentAttendanceReport?.logId][
                          'Attitude'
                        ]}
                    </span>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="h3"
                      className={clsx(
                        classes.stdBoxLabelMargin,
                        classes.stdBoxLabel
                      )}
                    >
                      Teacher&rsquo; comment:
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    {maxSteps > 0 && _studentAttendanceReport?.teacherComment}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

AcadReports.propTypes = {
  classes: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
  stdClassList: PropTypes.array.isRequired,
  callback: PropTypes.func
};
