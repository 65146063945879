import React, { Component, Fragment } from 'react';
import {
  TeacherLessonActions,
  TeacherLessonPaths
} from './TeacherLessonConstants';

import { AdminPage } from '../ui/page/AdminPage';
import { CalenderSchedule } from '../schedule/CalendarScheduler';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Fab from '@material-ui/core/Fab';
import { LessonComment } from './LessonComment';
import { ListMySchedule } from '../my-schedule/ListMySchedule';
import { ListTeacherClass } from './ListTeacherClass';
import { MyClassPaths } from '../my-class/MyClassConstants';
import { NormalTooltip } from '../ui/ButtonStyles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { ViewTeacherLesson } from './ViewTeacherLesson';
import { ViewTeacherUnitPlan } from './ViewTeacherUnitPlan';
import { withStyles } from '@material-ui/core/styles';

const stylesTeacherLesson = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class TeacherLessonComp extends Component {
  static displayName = TeacherLessonComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      classId: this.props.match.params.classId,
      classLessonId: this.props.match.params.classLessonId,
      action: this.props.action,
      content: null,
      subContent: null,
      pageTitle: '',
      redirect: null
    };
    switch (this.props.action) {
      case TeacherLessonActions.List:
      case TeacherLessonActions.ListAll:
        this.state.pageTitle =
          this.props.action === TeacherLessonActions.List
            ? 'My Lessons'
            : 'Lessons: All Classes';
        this.state.content = (
          <ListTeacherClass
            action={this.props.action}
            isMyLessons={this.props.action === TeacherLessonActions.List}
          />
        );
        break;
      case TeacherLessonActions.ViewLesson:
      case TeacherLessonActions.ViewLessonAdmin:
        this.state.pageTitle =
          this.props.action === TeacherLessonActions.ViewLesson
            ? 'My Lessons'
            : 'Teacher Lessons';
        this.state.content = (
          <Fragment>
            <ViewTeacherLesson
              onRef={actualChild => (this.child = actualChild)}
              action={this.props.action}
              classId={this.state.classId}
            />
          </Fragment>
        );
        break;
      case TeacherLessonActions.ViewUnitPlan:
      case TeacherLessonActions.ViewUnitAdmin:
        this.state.pageTitle =
          this.props.action === TeacherLessonActions.ViewUnitPlan
            ? 'My Lesson: Materials and Notes'
            : 'Teacher Lesson Review';
        this.state.content = (
          <Fragment>
            <ViewTeacherUnitPlan
              onRef={actualChild => (this.child = actualChild)}
              action={this.props.action}
              classId={this.state.classId}
              classLessonId={this.state.classLessonId}
            />
          </Fragment>
        );
        this.state.subContent = (
          <LessonComment classLessonId={this.state.classLessonId} />
        );
        break;
      case TeacherLessonActions.ViewCalendar:
        this.state.pageTitle = 'Calendar';
        this.state.content = (
          <CalenderSchedule
            onRef={actualChild => (this.child = actualChild)}
            classId={this.state.classId}
            isMySchedule={true}
          />
        );
        break;
      case TeacherLessonActions.ViewAllClassCalendar:
        this.state.pageTitle = 'Calendar - All Classes';
        this.state.content = (
          <CalenderSchedule
            onRef={actualChild => (this.child = actualChild)}
            classId={this.state.classId}
            isMySchedule={true}
            viewAllClass={true}
          />
        );
        break;
      case TeacherLessonActions.ViewMySchedule:
        this.state.pageTitle = 'My Schedule';
        this.state.content = (
          <ListMySchedule
            onRef={actualChild => (this.child = actualChild)}
            classId={this.state.classId}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  showLessonPreview = async () => {
    window.open(`${MyClassPaths.LetsLearn}/${this.state.classLessonId}/0/1/0`);
  };

  render() {
    const { classes } = this.props;
    let backToListUrl;
    switch (this.props.action) {
      case TeacherLessonActions.ViewLesson:
        backToListUrl = TeacherLessonPaths.List;
        break;
      case TeacherLessonActions.ViewLessonAdmin:
        backToListUrl = TeacherLessonPaths.ListAll;
        break;
      case TeacherLessonActions.ViewUnitPlan:
        backToListUrl = `${TeacherLessonPaths.ViewLesson}/${this.state.classId}`;
        break;
      case TeacherLessonActions.ViewUnitAdmin:
        backToListUrl = `${TeacherLessonPaths.ViewLessonAdmin}/${this.state.classId}`;
        break;
      case undefined:
      default:
    }
    const actions =
      this.props.action === TeacherLessonActions.ViewLesson ||
      this.props.action === TeacherLessonActions.ViewLessonAdmin ? (
        <Fab
          aria-label="Cancel"
          className={classes.fab}
          onClick={() => this.redirectTo(backToListUrl)}
        >
          <CloseOutlinedIcon color="action" />
        </Fab>
      ) : this.props.action === TeacherLessonActions.ViewUnitPlan ||
        this.props.action === TeacherLessonActions.ViewUnitAdmin ? (
        <Fragment>
          <NormalTooltip title="Preview lesson">
            <Fab
              aria-label="Preview lesson"
              color="secondary"
              className={classes.fab}
              onClick={() => this.showLessonPreview()}
            >
              <PlayArrowIcon />
            </Fab>
          </NormalTooltip>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={() => this.redirectTo(backToListUrl)}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      ) : this.props.action === TeacherLessonActions.ViewMySchedule ||
        this.props.action === TeacherLessonActions.ViewCalendar ||
        this.props.action === TeacherLessonActions.ViewAllClassCalendar ? (
        <Fragment>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={() => this.redirectTo(TeacherLessonPaths.List)}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      ) : (
        ''
      );
    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          subContent={this.state.subContent}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

TeacherLessonComp.propTypes = {
  classes: PropTypes.object.isRequired,
  action: PropTypes.string,
  match: PropTypes.object
};

export const TeacherLesson = withStyles(stylesTeacherLesson)(TeacherLessonComp);
