export const ScheduleActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  ViewCalendar: 'calendar',
  ViewAllClassCalendar: 'all-calendar',
  Delete: 'delete'
};

const prefix = '/schedule';

export const SchedulePaths = {
  SchedulePrefix: prefix,
  List: `${prefix}/${ScheduleActions.List}`,
  EditRoute: `${prefix}/${ScheduleActions.Edit}/:scheduleId`,
  Edit: `${prefix}/${ScheduleActions.Edit}`,
  Create: `${prefix}/${ScheduleActions.Create}`,
  ViewCalendarRoute: `${prefix}/${ScheduleActions.ViewCalendar}/:scheduleId`,
  ViewCalendar: `${prefix}/${ScheduleActions.ViewCalendar}`,
  ViewAllClassCalendar: `${prefix}/${ScheduleActions.ViewAllClassCalendar}`,
  Delete: `Schedules`
};
