export class ExamResultUtils {
  static calcExamResult(scoreTotal) {
    const result =
      scoreTotal < 60.0
        ? 'AVERAGE'
        : scoreTotal < 70.0
        ? 'FAIR'
        : scoreTotal < 80.0
        ? 'GOOD'
        : scoreTotal < 95.0
        ? 'VERY GOOD'
        : 'EXCELLENT';
    return result;
  }
}
