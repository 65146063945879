import React, { Component, Fragment } from 'react';

import CustomizedTable from '../ui/table/CustomizedTable';
import { Loading } from '../ui/Loading';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const stylesList = theme => ({
  fab: {
    margin: '12px 8px 8px 8px'
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 300
  }
});

class SingleLessonSelectComp extends Component {
  static displayName = SingleLessonSelectComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      data: [],
      cols: this.props.cols,
      loading: false,
      redirect: null
    };
  }

  componentDidMount() {
    console.log('SingleLessonSelectComp: ', this.props.data);
    this.setChildData(this.props.data);
  }

  setChildData = data => {
    this.child.updateChildData(data);
  };

  onDelete = item => {
    console.log('Delete: ', item);
    this.props.onUnselected(item);
  };

  renderDatagrid = () => {
    const actions = [
      {
        name: 'callback',
        callBackFn: this.onDelete,
        tooltip: 'Delete',
        icon: 'delete_icon'
      }
    ];

    return (
      <Fragment>
        <CustomizedTable
          onRef={actualChild => (this.child = actualChild)}
          cols={this.state.cols}
          actions={actions}
          isPaging={true}
        />
      </Fragment>
    );
  };

  render() {
    return (
      <Fragment>
        {this.state.loading ? <Loading /> : this.renderDatagrid()}
      </Fragment>
    );
  }
  s;
}

SingleLessonSelectComp.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array,
  onRef: PropTypes.func,
  onUnselected: PropTypes.func,
  cols: PropTypes.array
};

export const SingleLessonSelect = withStyles(stylesList)(
  SingleLessonSelectComp
);
