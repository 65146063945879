import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Box,
  Fab,
  Grid,
  IconButton,
  List,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { red, green } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import authService from '../../api-authorization/AuthorizeService';
import { Loading } from '../../ui/Loading';
import { format, parseISO } from 'date-fns';
import { stylesHolidays } from './stylesHolidays';
import HolidayListItem from './HolidayListItem';

class Holidays extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      generalHolidays: [],
      classHolidays: [],
      classExcludeDays: [],
      newDay: '',
      newDayForClass: '',
      newDayOnForClass: '',
      selectClass: '',
      loading: true
    };
  }

  componentDidMount() {
    this.getHolidaysData();
  }

  saveHolidays = async () => {
    this.setState({ actionStatus: 'progress' });
    const token = await authService.getAccessToken();
    const loggedInUser = await authService.getUser();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    const {
      generalHolidays,
      classHolidays,
      classExcludeDays,
      classList
    } = this.state;
    let allHolidays = [];

    const holidayListStringGeneral = `["${generalHolidays.join('","')}"]`;
    const generalHolidaysBody = {
      holidayList: holidayListStringGeneral,
      createdBy: loggedInUser.sub
    };
    allHolidays.push(generalHolidaysBody);

    classList.map(classObj => {
      if (!classHolidays[classObj.id] && !classExcludeDays[classObj.id])
        return null;
      const holidayListString =
        classHolidays[classObj.id]?.length > 0
          ? `["${classHolidays[classObj.id].join('","')}"]`
          : null;
      const excludeDaysString =
        classExcludeDays[classObj.id]?.length > 0
          ? `["${classExcludeDays[classObj.id].join('","')}"]`
          : null;
      const classHolidaysBody = {
        classId: classObj.id,
        holidayList: holidayListString,
        excludeDays: excludeDaysString,
        createdBy: loggedInUser.sub
      };
      allHolidays.push(classHolidaysBody);
      return classHolidaysBody;
    });

    try {
      await fetch(`api/AcademicManage/SaveHolidays`, {
        method: 'POST',
        body: JSON.stringify(allHolidays),
        headers: headers
      });
      await fetch('api/ClassLesson/ResetClassLessons', {
        method: 'POST',
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      });
      this.setState({ actionStatus: 'ok' });
    } catch (err) {
      this.setState({ actionStatus: 'error' });
      console.error(err);
    }
  };

  getHolidaysData = async () => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const [respHolidays, respClassList] = await Promise.all([
      fetch(`api/AcademicManage/GetHolidays/${loggedInUser.sub}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/ClassCourses/GetClassCourseInfo/${loggedInUser.sub}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [holidays, classList] = await Promise.all([
      respHolidays.json(),
      respClassList.json()
    ]);
    let generalHolidays = [];
    let classHolidays = [];
    let classExcludeDays = [];
    holidays.map((item, idx) => {
      var dataArr = JSON.parse(item.holidayList);
      var excludeDays = JSON.parse(item.excludeDays);
      if (item.classId) {
        classHolidays[item.classId] = dataArr;
        classExcludeDays[item.classId] = excludeDays;
      } else {
        generalHolidays.push(...dataArr);
      }
      return generalHolidays;
    });
    let holidaysMap = this.createHolidaysMap(generalHolidays);
    let years = this.sliceYears(Object.keys(holidaysMap));
    this.setState({
      years,
      holidaysMap,
      generalHolidays,
      classHolidays,
      classExcludeDays,
      holidays,
      classList,
      loading: false
    });
  };

  sliceYears(years) {
    return years.reverse().slice(0, 3);
  }

  createHolidaysMap(generalHolidays) {
    return generalHolidays.reduce(function(result, item) {
      let year = item.substring(0, 4);
      result[year] = result[year] || [];
      result[year].push(item);
      return result;
    }, Object.create(null));
  }

  handleAddDayClick = () => {
    const { newDay, generalHolidays } = this.state;
    let parseDate = null;
    try {
      let newDays = newDay.split(',');
      if (!newDays) return;
      let addDates = [];
      for (let index = 0; index < newDays.length; index++) {
        parseDate = newDays[index].trim();
        let addDate = parseISO(parseDate);
        let addDateString = format(addDate, 'yyyy-MM-dd');
        if (
          generalHolidays.includes(addDateString) ||
          addDates.includes(addDateString)
        )
          throw new Error('Date has already been added');
        addDates.push(addDateString);
      }
      generalHolidays.push(...addDates);
      generalHolidays.sort();
      let holidaysMap = this.createHolidaysMap(generalHolidays);
      let years = this.sliceYears(Object.keys(holidaysMap));
      this.setState({ years, holidaysMap, generalHolidays });
    } catch (err) {
      console.log(err, err.message);
      alert(err.message + ' : ' + parseDate);
    }
  };

  handleRemoveDay = day => {
    const { generalHolidays } = this.state;
    const index = generalHolidays.indexOf(day);
    generalHolidays.splice(index, 1);
    let holidaysMap = this.createHolidaysMap(generalHolidays);
    let years = this.sliceYears(Object.keys(holidaysMap));
    this.setState({ years, holidaysMap, generalHolidays });
  };

  handleAddDayClassClick = setType => {
    const {
      newDayForClass,
      classHolidays,
      newDayOnForClass,
      classExcludeDays,
      selectClass
    } = this.state;
    switch (setType) {
      case 'daysOff':
        this.setDaysOnOff(newDayForClass, classHolidays, selectClass, setType);
        break;
      case 'daysOn':
        this.setDaysOnOff(
          newDayOnForClass,
          classExcludeDays,
          selectClass,
          setType
        );
        break;
      default:
        break;
    }
  };

  setDaysOnOff(newDayForClass, classHolidays, selectClass, setType) {
    try {
      var addDate = parseISO(newDayForClass);
      var addDateString = format(addDate, 'yyyy-MM-dd');
      classHolidays[selectClass] = classHolidays[selectClass] || [];
      if (classHolidays[selectClass].includes(addDateString))
        throw new Error('Date has already been added!');

      classHolidays[selectClass].push(addDateString);
      classHolidays[selectClass].sort();
      const classSetDayName =
        setType === 'daysOff' ? 'classHolidays' : 'classExcludeDays';
      this.setState({ [classSetDayName]: classHolidays });
    } catch (err) {
      console.log(err, err.message);
      alert(err.message);
    }
  }

  handleRemoveDayOffClass = day => {
    this.handleRemoveDayClass('daysOff', day);
  };
  handleRemoveDayOnClass = day => {
    this.handleRemoveDayClass('daysOn', day);
  };
  handleRemoveDayClass = (setType, day) => {
    const { classHolidays, classExcludeDays, selectClass } = this.state;
    switch (setType) {
      case 'daysOff':
        const index1 = classHolidays[selectClass].indexOf(day);
        classHolidays[selectClass].splice(index1, 1);
        this.setState({ classHolidays });
        break;
      case 'daysOn':
        const index2 = classExcludeDays[selectClass].indexOf(day);
        classExcludeDays[selectClass].splice(index2, 1);
        this.setState({ classExcludeDays });
        break;
      default:
        break;
    }
  };

  handleChange = event => {
    var target = event.target;

    this.setState({ [target.name]: target.value });
  };

  onClassChange = (event, value) => {
    this.setState({
      selectClass: value?.id
    });
  };

  render() {
    const { classes } = this.props;
    const {
      years,
      holidaysMap,
      newDay,
      classList,
      classHolidays,
      classExcludeDays,
      selectClass,
      newDayForClass,
      newDayOnForClass,
      actionStatus,
      loading
    } = this.state;

    return (
      <Fragment>
        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs>
              <Paper className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.headerRow}>
                    <Typography
                      variant="h6"
                      className={clsx(classes.sectionTitle)}
                    >
                      GENERAL
                    </Typography>
                    <TextField
                      className={clsx(classes.headControl, classes.inputDay)}
                      name="newDay"
                      value={newDay}
                      onChange={this.handleChange}
                    />
                    <IconButton
                      size="small"
                      color="primary"
                      className={classes.button}
                      onClick={this.handleAddDayClick}
                    >
                      <AddIcon />
                    </IconButton>
                  </Grid>
                  {years.map((year, idx) => {
                    return (
                      <Grid key={idx} container spacing={1} item xs>
                        <Grid item xs={12} className={classes.header}>
                          <p></p>
                          <Typography
                            variant="body2"
                            className={clsx(
                              classes.titleText,
                              classes.stdBoxLabel
                            )}
                          >
                            {year}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            border={1}
                            borderRadius={5}
                            padding={0}
                            borderColor="#ddd"
                            className={classes.gridColumn}
                          >
                            <List className={classes.scrollList}>
                              {holidaysMap[year].map((date, idx) => {
                                return (
                                  <HolidayListItem
                                    key={idx}
                                    itemValue={date}
                                    handleCustomButton={this.handleRemoveDay}
                                  />
                                );
                              })}
                            </List>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.headerRow}>
                    <Typography
                      variant="h6"
                      className={clsx(classes.sectionTitle)}
                    >
                      CLASS
                    </Typography>
                    <div className={classes.headControl}>
                      <Autocomplete
                        options={classList}
                        getOptionLabel={option => option.class}
                        name="selectClassObj"
                        onChange={this.onClassChange}
                        renderInput={params => (
                          <TextField {...params} placeholder="Select class" />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid container spacing={1} item xs>
                    <Grid item xs={12} className={classes.header}>
                      <p></p>
                      <Typography
                        variant="body2"
                        className={clsx(classes.titleText, classes.stdBoxLabel)}
                      >
                        Days Off
                      </Typography>
                      <TextField
                        style={{
                          width: 120
                        }}
                        className={classes.formControl}
                        name="newDayForClass"
                        value={newDayForClass}
                        onChange={this.handleChange}
                      />
                      <IconButton
                        size="small"
                        color="primary"
                        className={classes.button}
                        onClick={() => this.handleAddDayClassClick('daysOff')}
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        border={1}
                        borderRadius={5}
                        padding={0}
                        borderColor="#ddd"
                        className={classes.gridColumn}
                      >
                        <List className={classes.scrollList}>
                          {classHolidays[selectClass] &&
                            classHolidays[selectClass].map((date, idx) => {
                              return (
                                <HolidayListItem
                                  key={idx}
                                  itemValue={date}
                                  handleCustomButton={
                                    this.handleRemoveDayOffClass
                                  }
                                />
                              );
                            })}
                        </List>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} item xs>
                    <Grid item xs={12} className={classes.header}>
                      <p></p>
                      <Typography
                        variant="body2"
                        className={clsx(classes.titleText, classes.stdBoxLabel)}
                      >
                        Days On
                      </Typography>
                      <TextField
                        style={{
                          width: 120
                        }}
                        className={classes.formControl}
                        name="newDayOnForClass"
                        value={newDayOnForClass}
                        onChange={this.handleChange}
                      />
                      <IconButton
                        size="small"
                        color="secondary"
                        className={classes.button}
                        onClick={() => this.handleAddDayClassClick('daysOn')}
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        border={1}
                        borderRadius={5}
                        padding={0}
                        borderColor="#ddd"
                        className={classes.gridColumn}
                      >
                        <List className={classes.scrollList}>
                          {classExcludeDays[selectClass] &&
                            classExcludeDays[selectClass].map((date, idx) => {
                              return (
                                <HolidayListItem
                                  key={idx}
                                  itemValue={date}
                                  handleCustomButton={
                                    this.handleRemoveDayOnClass
                                  }
                                />
                              );
                            })}
                        </List>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                spacing={2}
                item
                xs={12}
                className={classes.buttonBar}
              >
                <Grid item>
                  <Fab
                    style={{
                      width: 120
                    }}
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="Save"
                    onClick={this.saveHolidays}
                    disabled={actionStatus === 'progress'}
                  >
                    Save
                  </Fab>
                </Grid>
                <Grid item>
                  {actionStatus === 'progress' ? (
                    <CircularProgress size={24} />
                  ) : actionStatus === 'ok' ? (
                    <DoneIcon style={{ color: green[500] }} />
                  ) : actionStatus === 'error' ? (
                    <ErrorIcon style={{ color: red[500] }} />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

Holidays.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesHolidays)(Holidays);
