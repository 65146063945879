import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../../api-authorization/AuthorizeService';
import Paper from '@material-ui/core/Paper';
import { Loading } from '../../ui/Loading';
import { ParentPaths } from './ParentConstans';
import CommonDataTable from '../../ui/table/CommonDataTable';
import CommonSearchToolbar from '../../ui/table/CommonSearchToolbar';

const stylesListParnet = () => ({});

class ListParentComp extends Component {
  static displayName = ListParentComp.name;

  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = { parents: [], loading: true };
  }

  componentDidMount() {
    this.populateParentData();
  }

  populateParentData = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/parents', {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ parents: data, loading: false });
  };

  refreshParent = async () => {
    await this.populateParentData();
    this.setChildData('');
  };

  handleChangeSearchTerm = filterText => {
    this.setChildData(filterText);
  };

  setChildData = filterText => {
    const { parents: data } = this.state;
    const searchTerm = filterText.toLowerCase();
    let filteredRows = data.filter(e => {
      const itemText = (e.name + e.email + e.relationFormat).toLowerCase();
      return itemText.indexOf(searchTerm) > -1;
    });
    this.child.setData(filteredRows);
  };

  render() {
    const cols = [
      { name: 'name', header: 'Name', align: 'left' },
      { name: 'email', header: 'Email', align: 'right' },
      { name: 'phoneNumber', header: 'Phone', align: 'right' },
      { name: 'relationFormat', header: 'Relation', align: 'left' }
    ];
    const actions = [
      {
        name: 'edit',
        tooltip: 'Edit',
        url: `${ParentPaths.Edit}`,
        icon: 'edit_icon'
      },
      {
        name: 'delete',
        tooltip: 'Delete',
        url: `${ParentPaths.Delete}`,
        icon: 'delete_icon'
      }
    ];

    return (
      <Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <Paper>
            <CommonSearchToolbar handleChange={this.handleChangeSearchTerm} />
            <CommonDataTable
              onRef={actualChild => (this.child = actualChild)}
              rows={this.state.parents}
              cols={cols}
              actions={actions}
              eventReloadData={this.refreshParent}
              isPaging={true}
            />
          </Paper>
        )}
      </Fragment>
    );
  }
}

ListParentComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListParent = withStyles(stylesListParnet)(ListParentComp);
