import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DateFnsUtils from '@date-io/date-fns';
import { Loading } from '../ui/Loading';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ScheduleTbClassCourseSelect from './ScheduleTbClassCourseSelect';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { SchedulePaths, ScheduleActions } from './ScheduleConstans';
import {
  MessageType,
  MessageBarVariants,
  MessageBars
} from '../ui/popup/Messages';
import { DateTimeUtils } from '../common/DateTimeUtils';

const stylesEdit = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 19
  },
  list: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'end'
  },
  margin: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(3, 3, 3, 5)
  },
  legend: {
    marginBottom: theme.spacing(2)
  },
  textField: {
    width: 300
  }
});

class EditScheduleComp extends Component {
  static displayName = EditScheduleComp.name;
  constructor(...args) {
    super(...args);
    this.form = React.createRef();
    this.messageBar = React.createRef();
    this.state = {
      action: this.props.action,
      scheduleId: this.props.scheduleId,
      method: 'POST',
      schedule: {
        name: '',
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        startTime: new Date(),
        endTime: new Date(),
        startDate: new Date(),
        endDate: new Date(),
        classRoomId: '',
        classCourseId: '',
        classFormat: ''
      },
      redirect: null,
      loading: true,
      openDialog: false,
      selectedId: null,
      messageResponse: '',
      messageBar: (
        <MessageBars
          onRef={actualChild => (this.messageBar = actualChild)}
          variant={MessageBarVariants.Error}
          type={MessageType.error}
          message={''}
          handleClose={this.handleCloseMsgBar}
        />
      )
    };

    switch (this.props.action) {
      case ScheduleActions.Create:
        this.state.action = `api/schedules`;
        this.state.loading = false;
        break;
      case ScheduleActions.Edit:
        this.state.method = 'PUT';
        this.state.action = `api/schedules/${this.state.scheduleId}`;
        this.populateDataInit();
        break;
      case undefined:
      default:
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  populateDataInit = async () => {
    const token = await authService.getAccessToken();
    const { scheduleId } = this.state;
    const response = await fetch(`api/schedules/${scheduleId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    data.classFormat = `${data.classCourses.name} -- Level ${data.classCourses.level.name}`;
    this.setState({ schedule: data, loading: false });
  };

  backToList() {
    this.redirectTo(SchedulePaths.List);
  }

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  handleDateChange = name => date => {
    const attrName = name;
    const attrValue = date;

    this.setState(
      prevState => ({
        schedule: {
          ...prevState.schedule,
          [attrName]: attrValue
        }
      }),
      () => {
        if (attrName === 'startTime' && this.state.schedule.classCourseId) {
          this.getClassCourse(this.state.schedule.classCourseId);
        }
        if (attrName === 'startDate' && this.state.schedule.classCourseId) {
          this.getEndDate(this.state.schedule.classCourseId);
        }
      }
    );
  };

  handleSubmit = e => {
    e.preventDefault();

    const { schedule, method, action, token } = this.state;
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    schedule.startDate = DateTimeUtils.toDateTimeJSON(schedule.startDate);
    schedule.endDate = DateTimeUtils.toDateTimeJSON(schedule.endDate);
    schedule.startTime = DateTimeUtils.toDateTimeJSON(schedule.startTime);
    schedule.endTime = DateTimeUtils.toDateTimeJSON(schedule.endTime);

    fetch(action, {
      method: method,
      body: JSON.stringify(schedule),
      headers: headers
    })
      .then(async response => {
        if (response.status === 400) {
          var messageResponse = await response.json();
          this.messageBar.showMessage('error', 'Error', messageResponse);
          if (messageResponse) {
            this.setOpenSnackbar(true);
          }
        }

        if (response.ok) {
          this.backToList();
        } else throw new Error(response.status);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  async submitData() {
    const token = await authService.getAccessToken();
    this.setState({ token: token });
    ReactDOM.findDOMNode(this.form.current).dispatchEvent(new Event('submit'));
  }

  handleChange = name => event => {
    const { schedule } = this.state;
    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;
    this.setState(
      prevState => ({
        schedule: {
          ...prevState.schedule,
          [attrName]: attrValue
        }
      }),
      () => {
        if (attrName !== 'name' && schedule.classCourseId) {
          this.getEndDate(schedule.classCourseId);
        }
      }
    );
  };

  openDialog = () => {
    this.setState({ openDialog: true });
  };

  closeDialog = () => {
    this.setState({ openDialog: false, selectedId: '' });
  };

  handleClick = async e => {
    this.openDialog();
  };
  callbackGetValueRadioBtn = e => {
    this.setState({ selectedId: e });
  };

  saveDataSelected = async () => {
    const { selectedId, schedule } = this.state;
    schedule.classCourseId = selectedId;
    this.setState({ schedule: schedule });
    this.closeDialog();
    this.getEndDate(selectedId);
    this.getClassCourse(selectedId);
  };

  getEndDate = async classId => {
    const { schedule } = this.state;
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(schedule)
    };
    const response = await fetch(
      `api/Schedules/BuildEndDateSchedule/${classId}`,
      requestOptions
    );
    if (response.ok) {
      const data = await response.json();
      schedule.endDate = new Date(data);
      this.setState({ schedule: schedule });
    }
  };

  getClassCourse = async classId => {
    const { schedule } = this.state;
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const requestOptions = {
      method: 'GET',
      headers: headers
    };
    const response = await fetch(`api/ClassCourses/${classId}`, requestOptions);
    const data = await response.json();
    schedule.classFormat = `${data.name} -- Level ${data.level.name}`;
    if (
      typeof schedule.startTime === 'string' ||
      schedule.startTime instanceof String
    ) {
      schedule.startTime = new Date(schedule.startTime);
      console.log('startTime change: ', schedule.startTime);
    }
    var startTimeTemp = schedule.startTime;
    var time = data.level.lessonTime;
    schedule.endTime = new Date(startTimeTemp.getTime() + time * 60000);
    this.setState({ schedule: schedule });
  };

  handleCloseMsgBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  renderForm() {
    const { classes } = this.props;
    const { schedule } = this.state;
    const cols = [
      {
        name: 'name',
        header: 'Name',
        placeholder: 'Name',
        adornment: ''
      }
    ];
    return (
      <Paper className={classes.root}>
        <form
          ref={this.form}
          className={classes.container}
          onSubmit={this.handleSubmit}
        >
          <Grid container>
            <Grid item xs={6} className={classes.cell}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                      container
                      className={classes.grid}
                      justify="space-around"
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        id="start-date-picker-dialog"
                        label="Start date"
                        format="dd/MM/yyyy"
                        value={schedule.startDate}
                        onChange={this.handleDateChange('startDate')}
                        KeyboardButtonProps={{
                          'aria-label': 'change start date'
                        }}
                      />
                      <KeyboardDatePicker
                        margin="normal"
                        id="end-date-picker-dialog"
                        label="End date"
                        format="dd/MM/yyyy"
                        value={schedule.endDate}
                        onChange={this.handleDateChange('endDate')}
                        KeyboardButtonProps={{
                          'aria-label': 'change end date'
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="name"
                    style={{ margin: 8 }}
                    fullWidth
                    multiline
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    rows="6"
                    className={classes.margin}
                    label={cols[0].header}
                    placeholder={cols[0].placeholder}
                    name={cols[0].name}
                    value={schedule[cols[0].name]}
                    onChange={this.handleChange(cols[0].name)}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <TextField
                    id="class"
                    style={{ margin: 8 }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    rows="6"
                    InputProps={{
                      readOnly: true
                    }}
                    className={classes.margin}
                    label={'Class'}
                    name={'class'}
                    value={schedule.classFormat || ''}
                    onClick={() => this.handleClick('classId')}
                    placeholder=""
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6} className={classes.cell}>
              <List>
                <ListItem className={classes.listItem}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                      container
                      className={classes.grid}
                      justify="space-around"
                    >
                      <KeyboardTimePicker
                        margin="normal"
                        id="mui-pickers-starttime"
                        label="Start time"
                        minutesStep={5}
                        value={schedule.startTime}
                        onChange={this.handleDateChange('startTime')}
                        KeyboardButtonProps={{
                          'aria-label': 'change start time'
                        }}
                      />
                      <KeyboardTimePicker
                        margin="normal"
                        id="mui-pickers-endtime"
                        label="End time"
                        minutesStep={5}
                        value={schedule.endTime}
                        onChange={this.handleDateChange('endTime')}
                        KeyboardButtonProps={{
                          'aria-label': 'change end time'
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel component="legend" className={classes.legend}>
                      Schedule
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={schedule.monday}
                            onChange={this.handleChange('monday')}
                            value="Monday"
                          />
                        }
                        label="Monday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={schedule.tuesday}
                            onChange={this.handleChange('tuesday')}
                            value="Tuesday"
                          />
                        }
                        label="Tuesday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={schedule.wednesday}
                            onChange={this.handleChange('wednesday')}
                            value="Wednesday"
                          />
                        }
                        label="Wednesday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={schedule.thursday}
                            onChange={this.handleChange('thursday')}
                            value="Thursday"
                          />
                        }
                        label="Thursday"
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel component="legend" className={classes.legend}>
                      &nbsp;
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={schedule.friday}
                            onChange={this.handleChange('friday')}
                            value="Friday"
                          />
                        }
                        label="Friday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={schedule.saturday}
                            onChange={this.handleChange('saturday')}
                            value="Saturday"
                          />
                        }
                        label="Saturday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={schedule.sunday}
                            onChange={this.handleChange('sunday')}
                            value="Sunday"
                          />
                        }
                        label="Sunday"
                      />
                    </FormGroup>
                  </FormControl>
                </ListItem>
              </List>
            </Grid>
            <Dialog
              open={this.state.openDialog}
              onClose={this.closeDialog}
              aria-labelledby="form-dialog-title"
              maxWidth="lg"
              fullWidth
            >
              <DialogTitle>Class</DialogTitle>
              <DialogContent>
                <ScheduleTbClassCourseSelect
                  scheduleId={this.state.scheduleId}
                  actionColName={this.state.actionColName}
                  callbackGetValueRadioBtn={this.callbackGetValueRadioBtn}
                  schedule={this.state.schedule}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.saveDataSelected} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </form>
        {this.state.messageBar}
        {this.state.redirect}
      </Paper>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderForm();

    return <Fragment>{contents}</Fragment>;
  }
}

EditScheduleComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const EditSchedule = withStyles(stylesEdit)(EditScheduleComp);
