import React, { Component } from 'react';
import clsx from 'clsx';
import { Grid, Paper, Typography } from '@material-ui/core';

export class InfoBoxView extends Component {
  render() {
    const { title, subtitle, numberValue, infoData, classes } = this.props;

    return (
      <Paper className={clsx(classes.chartBox, classes.chartBoxEqualHeight)}>
        <Grid container>
          <div className={classes.chartHeadingBox}>
            <Typography variant="h3" className={clsx(classes.heading)}>
              {title}
            </Typography>
            <Typography variant="body2" className={clsx(classes.subtitle)}>
              {subtitle}
            </Typography>
          </div>
          <div className={classes.chartNumberBox}>
            <Typography
              variant="h1"
              className={clsx(
                classes.chartSmallNumber,
                classes.chartLabelGreen
              )}
            >
              {numberValue}
            </Typography>
            <hr
              className={clsx(
                classes.chartNumberLine,
                classes.chartSmallNumberLine,
                classes.chartLabelGreen
              )}
            />
          </div>
        </Grid>

        <Grid container style={{ marginTop: 16 }}>
          {infoData.map((row, idx) => (
            <Grid container key={idx} style={{ marginTop: 16 }} item xs={12}>
              <Grid item xs={9}>
                <Typography variant="h3" className={clsx(classes.subtitle)}>
                  {row.title}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="h1"
                  className={clsx(classes.infoNumber, classes.right)}
                >
                  {row.number}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }
}
