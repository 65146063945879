import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import { Keys } from '../../common/constants/GeeOConstants';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { commonToolbarStyles } from './commonToolbarStyles';

const WAIT_INTERVAL = 300;

class CommonSearchToolbar extends Component {
  constructor(...args) {
    super(...args);
    this.timer = null;
    this.state = {
      filterText: '',
      selectedDropdown: '',
      checkField: false,
      clearButtonStyle: { display: 'none' }
    };
  }

  handleChange = name => event => {
    clearTimeout(this.timer);

    const attrName = name;
    const attrValue = event.target.value;
    this.setState(
      {
        [attrName]: attrValue,
        triggerEle: attrName
      },
      this.setTrigger
    );
  };

  handleKeyDown(e) {
    if (e.keyCode === Keys.ENTER_KEY) {
      this.triggerChange();
    }
  }

  setTrigger() {
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
  }
  triggerChange = () => {
    const { triggerEle } = this.state;
    switch (triggerEle) {
      case 'filterText':
        this.props.handleChange(this.state[triggerEle]);
        break;
      case 'selectedDropdown':
        this.props.handleDropdown(this.state[triggerEle]);
        break;
      default:
        break;
    }
  };

  handleChangeCheckbox = event => {
    const checkField = event.target.checked;
    this.setState({ checkField });
    this.props.handleFilters(checkField);
  };

  changeClearButtonStyle = clearButtonStyle => {
    this.setState({ clearButtonStyle });
  };

  clearFilterText = () => {
    if (this.state.filterText === '') return;
    clearTimeout(this.timer);
    this.setState({ filterText: '' }, this.setTrigger);
  };

  render() {
    const {
      classes,
      customToolbarStyle,
      title,
      checkCondition,
      customCommands,
      dropdownOptions
    } = this.props;
    const {
      filterText,
      checkField,
      selectedDropdown,
      clearButtonStyle
    } = this.state;

    return (
      <Toolbar
        className={clsx(classes.root, customToolbarStyle, {
          [classes.highlight]: false
        })}
      >
        <div className={classes.title}>
          {title && <Typography variant="h6">{title}</Typography>}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {checkCondition && (
            <div className={classes.filters}>
              <FormControlLabel
                className={classes.checkCondition}
                control={
                  <Checkbox
                    checked={checkField}
                    onChange={this.handleChangeCheckbox}
                  />
                }
                label={checkCondition}
              />
            </div>
          )}
          {dropdownOptions && (
            <div className={classes.filters}>
              <Select
                value={selectedDropdown}
                onChange={this.handleChange('selectedDropdown')}
                className={classes.dropdown}
              >
                <MenuItem value="" key="">
                  All
                </MenuItem>
                {dropdownOptions.map(row => (
                  <MenuItem value={row.name} key={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          <div
            className={classes.search}
            onMouseEnter={e => {
              this.changeClearButtonStyle({ display: 'block' });
            }}
            onMouseLeave={e => {
              this.changeClearButtonStyle({ display: 'none' });
            }}
          >
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              value={filterText}
              onChange={this.handleChange('filterText')}
              onKeyDown={e => this.handleKeyDown(e)}
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'Search' }}
            />
            <IconButton
              size="small"
              className={classes.clearButton}
              style={clearButtonStyle}
              onClick={this.clearFilterText}
              disableFocusRipple
            >
              <ClearIcon />
            </IconButton>
          </div>
          {customCommands}
        </div>
      </Toolbar>
    );
  }
}

CommonSearchToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(commonToolbarStyles)(CommonSearchToolbar);
