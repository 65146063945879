export const SingleLessonActions = {
  List: 'list',
  Edit: 'edit',
  Create: 'create',
  Delete: 'delete',
  Archive: 'archive'
};
const prefix = '/single-lesson';
export const SingleLessonPaths = {
  SingleLessonPrefix: prefix,
  List: `${prefix}/${SingleLessonActions.List}`,
  //ListSingleLesson: `${prefix}/${SingleLessonActions.ListSingleLesson}`,
  EditRouter: `${prefix}/${SingleLessonActions.Edit}/:singleLessonId`,
  Edit: `${prefix}/${SingleLessonActions.Edit}`,
  Create: `${prefix}/${SingleLessonActions.Create}`,
  Delete: 'SingleLessons'
};
