import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { ListTask } from './ListTask';
import { TaskActions, TaskPaths } from './TaskConstans';
import { AdminPage } from '../ui/page/AdminPage';
import { EditTask } from './EditTask';

const styles = theme => ({
  fab: {
    margin: theme.spacing(0, 1, 1)
  }
});

class TaskComp extends Component {
  static displayName = TaskComp.name;
  constructor(...args) {
    super(...args);
    this.child = React.createRef();
    this.state = {
      taskId: this.props.match.params.taskId,
      action: this.props.action,
      content: null,
      pageTitle: '',
      redirect: null,
      isTeacher:
        this.props.action === TaskActions.List ||
        this.props.action === TaskActions.Create ||
        this.props.action === TaskActions.Edit
          ? true
          : false
    };
    var isTeacherTemp =
      this.props.action === TaskActions.List ||
      this.props.action === TaskActions.Create ||
      this.props.action === TaskActions.Edit
        ? true
        : false;
    switch (this.props.action) {
      case TaskActions.List:
      case TaskActions.ListAll:
        this.state.pageTitle =
          this.props.action === TaskActions.List
            ? 'My Tasks'
            : 'All Suggestions';
        this.state.content = <ListTask isTeacher={isTeacherTemp} />;
        break;
      case TaskActions.Create:
      case TaskActions.AcadManagerCreate:
        this.state.pageTitle = 'Create Task';
        this.state.content = (
          <EditTask
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            taskId={this.state.taskId}
            isTeacher={isTeacherTemp}
          />
        );
        break;
      case TaskActions.Edit:
      case TaskActions.AcadManagerEdit:
        this.state.pageTitle = 'Edit Task';
        this.state.content = (
          <EditTask
            onRef={actualChild => (this.child = actualChild)}
            action={this.props.action}
            taskId={this.state.taskId}
            isTeacher={isTeacherTemp}
          />
        );
        break;
      case undefined:
      default:
    }
  }

  handleChildSubmit = () => {
    this.child.submitData();
  };

  handleChildBackToList = () => {
    this.child.backToList();
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} />
    });
  };

  render() {
    const { classes } = this.props;
    const actions =
      this.props.action === 'list' || this.props.action === 'list-all' ? (
        <Fab
          aria-label="Add"
          color="primary"
          className={classes.fab}
          onClick={() =>
            this.redirectTo(
              this.state.isTeacher ? TaskPaths.Create : TaskPaths.CreateAssign
            )
          }
        >
          <AddIcon />
        </Fab>
      ) : this.props.action === 'create' ||
        this.props.action === 'edit' ||
        this.props.action === 'create-assign' ||
        this.props.action === 'edit-assign' ? (
        <Fragment>
          <Fab
            aria-label="Save"
            color="primary"
            className={classes.fab}
            onClick={this.handleChildSubmit}
          >
            <SaveIcon />
          </Fab>
          <Fab
            aria-label="Cancel"
            className={classes.fab}
            onClick={this.handleChildBackToList}
          >
            <CloseOutlinedIcon color="action" />
          </Fab>
        </Fragment>
      ) : (
        ''
      );
    return (
      <Fragment>
        <AdminPage
          title={this.state.pageTitle}
          content={this.state.content}
          actions={actions}
        />
        {this.state.redirect}
      </Fragment>
    );
  }
}

TaskComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Task = withStyles(styles)(TaskComp);
